// Flags
import FlagEN from '@/assets/images/flags/united-kingdom.svg';
import FlagFI from '@/assets/images/flags/finland.svg';
import FlagSV from '@/assets/images/flags/swedish.svg';

// Application dictionaries
import JSONLocaleEN from '@/localization/locales/BiBook_English.json';
import JSONLocaleFI from '@/localization/locales/BiBook_Finnish.json';
import JSONLocaleSV from '@/localization/locales/BiBook_Swedish.json';

const locales = [
  {
    label: 'English',
    value: 'en',
    img: FlagEN,
    localeApp: JSONLocaleEN,
    localeCode: 'en-GB',
    reportLanguage: 'en-GB',
    dateTimeFormats: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      },
    },
  },
  {
    label: 'Finnish',
    value: 'fi',
    img: FlagFI,
    localeApp: JSONLocaleFI,
    localeCode: 'fi-FI',
    reportLanguage: 'fi',
    dateTimeFormats: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  {
    label: 'Swedish',
    value: 'sv',
    img: FlagSV,
    localeApp: JSONLocaleSV,
    localeCode: 'sv-SE',
    reportLanguage: 'sv',
    dateTimeFormats: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
];

export default locales;
