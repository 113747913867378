import AppBaseModel from './AppBaseModel';

class CompaniesIntegrations extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'companies_integrations',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default CompaniesIntegrations;
