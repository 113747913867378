<template lang="pug">
.ui-tree
  UiTreeItemRecursive(
    v-for="item in tree"
    :key="item.entity.id"
    :item="item"
    :openedHash="openedHash"
    :path="path"
    :activeEntityId="activeEntityId"
    @toggle="onToggle"
    @select="onSelect"
  )
</template>

<script>
import { buildPath } from '@/common/build-path.js';
import { buildTree } from '@/common/build-tree.js';

export default {
  emits: ['toggle', 'select'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeEntityId: {
      type: String,
      default: null,
    },
    rootId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      openedHash: {},
    };
  },
  computed: {
    tree() {
      return buildTree(this.items, this.rootId);
    },
    path() {
      return buildPath(this.items, this.activeEntityId);
    },
  },
  watch: {
    path() {
      this.openPathFolders();
    },
  },
  mounted() {
    this.openPathFolders();
  },
  methods: {
    openPathFolders() {
      this.path.forEach((entity) => {
        this.openedHash[entity.id] = true;
      });
    },
    onToggle(item) {
      this.openedHash[item.entity.id] = !this.openedHash[item.entity.id];
      this.$emit('toggle', item);
    },
    onSelect(item) {
      this.$emit('select', item);
    },
  },
};
</script>
