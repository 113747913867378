const LocalStorage = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key, defaultValue = null) {
    try {
      const response = JSON.parse(localStorage.getItem(key));
      return defaultValue !== null ? response ?? defaultValue : response;
    } catch {
      return defaultValue;
    }
  },
  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch {
      //
    }
  },
  has(key) {
    return localStorage.getItem(key) !== null;
  },
};

export default LocalStorage;
