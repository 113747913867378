<template lang="pug">
.table-container
  RestTable(
    :fetchHandler="fetchData"
    namespace="integration_requests"
    :headers="headers"
    ref="integrationPendingTable"
    filtered
    :initial-filters="initialFilters"
    multiline-filters
  )
    template(v-slot:cel_integration="{ item }")
      span {{ integrationName(item.software) }}
    template(v-slot:cel_requester="{ item }")
      UiStack(align="center" spacing="sm")
        UiStackItem(align="center")
          AvatarBox(
            :src="item.requester.avatar"
            :id="item.requester.email"
            :label="item.requester.name"
            size="sm"
          )
        UiStackItem(grow shrink)
          div {{ item.requester.name }}
          .requester-email {{ item.requester.email }}
    template(v-slot:cel_role="{ item }")
      span {{ $t(`company.role.${item.requester.company_role}`) }}
    template(v-slot:cel_date="{ item }")
      span(data-vi="created_at") {{ $d(item.created_at, 'numeric') }}
    template(v-slot:cel_approvement="{ item }")
      UiStack(justify="end")
        UiButton(
          icon="Check"
          size="sm"
          @click="acceptRequest(item)"
        ) {{ $t('integration_requests.actions.approve') }}
        UiButton(
          icon="Close"
          size="sm"
          variant="danger"
          @click="declineRequest(item)"
        ) {{ $t('integration_requests.actions.decline') }}
    template(v-slot:filter_block="{ filter, updateFilter }")
      UiStack(wrap spacing="lg")
        UiStackItem.filters-search
          UiInput(
            :placeholder="$t('integration_requests.filter_by.search')"
            :modelValue="filter.search"
            @update:modelValue="updateFilter({ search: $event })"
          )
            template(#icon)
              UiIcon(name="SearchIcon")
        UiStack
          UiStackItem
            .filter-by-title {{ $t('integration_requests.filter_by.title') }}
          UiStackItem.filters-block
            UiMultiselect(
              :options="integrationOptions"
              :modelValue="filter.software"
              :placeholder="$t('integration_requests.filter_by.integration')"
              :allow-empty="true"
              @update:modelValue="updateFilter({ software: $event })"
            )
          UiStackItem.filters-block
            UiDatePicker(
              :range="true"
              :modelValue="[filter.time_start, filter.time_end]"
              size="md"
              @update:modelValue="updateFilter({ time_start: $event[0], time_end: $event[1] })"
            )
  ApproveRequestModal(ref="approveRequestModal" @success="handleAcceptRequest()")
  DeclineRequestModal(ref="declineRequestModal" @success="handleDeclineRequest()")
</template>

<script>
import IntegrationRequests from '@/api/models/IntegrationRequests.js';
import IntegrationOptions from '@/helper/integration-options.js';
import ApproveRequestModal from '@/modals/integration-request/approve.vue';
import DeclineRequestModal from '@/modals/integration-request/decline.vue';
import { getIntegrationName } from '@/views/Onboarding/helpers.js';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';

export default {
  components: {
    ApproveRequestModal,
    DeclineRequestModal,
  },
  emits: ['request-accepted', 'request-declined'],
  data() {
    return {
      initialFilters: {
        time_start: new Date(),
        time_end: new Date(),
      },
    };
  },

  computed: {
    headers() {
      return [
        {
          key: 'integration',
          label: this.$t('integration_activity.headers.integration'),
          sortable: true,
          width: '15%',
        },
        {
          key: 'requester',
          label: this.$t('integration_activity.headers.requester'),
          sortable: true,
          width: '30%',
        },
        {
          key: 'role',
          label: this.$t('integration_activity.headers.role'),
          sortable: true,
          width: '15%',
        },
        {
          key: 'date',
          label: this.$t('integration_activity.headers.date'),
          sortable: true,
          width: '15%',
        },
        {
          key: 'approvement',
          label: '',
          sortable: false,
        },
      ];
    },
    integrationOptions() {
      return IntegrationOptions;
    },
  },

  methods: {
    async fetchData(params) {
      try {
        const response = await new IntegrationRequests().fetchAll({
          ...params,
          time_start: startOfDay(params.time_start),
          time_end: endOfDay(params.time_end),
        });
        return {
          meta: response.data.meta,
          items: response.data.integration_requests,
        };
      } catch (error) {
        return {};
      }
    },
    handleAcceptRequest() {
      this.$refs.integrationPendingTable.fetchData();
      this.$emit('request-accepted');
    },
    handleDeclineRequest() {
      this.$refs.integrationPendingTable.fetchData();
      this.$emit('request-declined');
    },
    acceptRequest(item) {
      this.$refs.approveRequestModal.open({
        userName: item.requester.name,
        requestId: item.id,
        integrationName: this.integrationName(item.software),
      });
    },
    declineRequest(item) {
      this.$refs.declineRequestModal.open({
        userName: item.requester.name,
        requestId: item.id,
        integrationName: this.integrationName(item.software),
      });
    },
    integrationName(software) {
      return getIntegrationName(software) ?? software;
    },
  },
};
</script>

<style lang="scss" scoped>
.requester-email {
  color: var(--bb-gray-500);
}

.filters-block {
  min-width: 200px;
}
.filters-search {
  min-width: 250px;
}

.filter-by-title {
  color: var(--bb-gray-500);
  font-weight: 600;
}
</style>
