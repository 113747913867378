function submitPostViaHiddenForm(url, params) {
  const form = document.createElement('form');
  form.style.display = 'none';
  form.setAttribute('target', '_blank');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', url);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.value = params[key];
      form.append(input);
    }
  }

  document.body.append(form);
  form.submit();
  form.remove();
}

export default submitPostViaHiddenForm;
