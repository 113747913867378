<template lang="pug">
.login-bannner-card
  .login-bannner-card__wrapper
    UiCardSection(padding="xl")
      UiStack(
        spacing="2xl"
        vertical
        align="stretch"
      )
        UiStackItem
          UiInputLabel {{ $t('login_banners.banner_title') }}
          UiInput(
            :modelValue="modelValue.title"
            size="lg"
            :placeholder="$t('login_banners.modals.label_title')"
            data-test-id="input-title"
            @update:modelValue="updateForm({ title: $event })"
          )
            template(#postfix)
              div(:class="{ 'login-banner-card__postfix-title': modelValue.title.length > 74 }") {{ postfixTitleLength }}
          template(v-if="v$.modelValue.title?.$errors")
            UiInputFeedback(v-for="$error in v$.modelValue.title?.$errors" error) {{ $error.$message }}
        UiStackItem
          UiInputLabel {{ $t('login_banners.banner_text') }}
          UiEditor(
            ref="editorRef"
            :modelValue="modelValue.description"
            :limit="250"
            size="lg"
            data-test-id="editor-description"
            @update:modelValue="updateForm({ description: $event, descriptionLength: $refs.editorRef.editor.storage.characterCount.characters() })"
          )
          template(v-if="v$.modelValue.descriptionLength?.$errors")
            UiInputFeedback(v-for="$error in v$.modelValue.descriptionLength?.$errors" error) {{ $error.$message }}
  .login-bannner-card__actions
    UiStack(justify="space-between")
      UiButton(
        icon="Trash"
        :round="mobileView"
        variant="ghost-danger"
        data-test-id="action-delete-button"
        @click="$emit('delete')"
      ) {{ $t('login_banners.delete_banner') }}
      UiStack
        UiStackItem
          UiButton(
            variant="smooth-secondary"
            data-test-id="action-cancel-button"
            @click="$emit('cancel')"
          ) {{ $t('actions.cancel') }}
        UiStackItem
          UiButton(data-test-id="action-save-button" @click="save") {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBanners from '@/api/models/login-banners.js';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/common/validators.js';

export default {
  emits: ['save', 'delete', 'cancel', 'toggleEdit', 'update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      inProgress: false,
    };
  },
  validations() {
    const requiredDescription = (value) => value;

    return {
      modelValue: {
        descriptionLength: {
          requiredDescription: helpers.withMessage(
            this.$t('system_banners.validations.required'),
            requiredDescription,
          ),
        },
        title: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'mobileView']),

    postfixTitleLength() {
      return `${this.modelValue.title.length}/75`;
    },
  },
  mounted() {
    this.updateForm({
      descriptionLength: this.$refs.editorRef.editor.storage.characterCount.characters(),
    });
  },
  methods: {
    async save() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      try {
        const params = {
          login_banner: {
            title: this.modelValue.title,
            description: this.modelValue.description,
          },
        };
        await new LoginBanners({ adminApi: this.isAdmin }, { id: this.modelValue.id }).update(
          params,
        );
        this.$emit('save');
        this.$emit('toggleEdit');
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
    updateForm(newValues = {}) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        ...newValues,
        title: (newValues.title ?? this.modelValue.title).slice(0, 75),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-bannner-card {
  &__wrapper {
    background-color: var(--bb-gray-950);
  }

  &__actions {
    margin-top: 1rem;
  }

  &__delete-banner {
    padding: 9px 18px;
    border-radius: 10px;
    color: var(--bb-danger);
    cursor: pointer;

    &:hover {
      background-color: rgba(var(--bb-danger-rgb), 0.2);
    }
  }
}

.login-banner-card__postfix-title {
  color: var(--bb-danger);
}
</style>
