<template>
  <svg
    width="142"
    height="115"
    viewBox="0 0 142 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="66" cy="55" r="55" fill="#FBE3E3" />
    <circle cx="66" cy="55" r="46" fill="#FBE3E3" />
    <rect
      x="49.3893"
      y="38.4996"
      width="32.6111"
      height="32.6111"
      rx="16.3056"
      transform="rotate(-0.0490019 49.3893 38.4996)"
      fill="#ED7575"
      stroke="#ED7575"
    />
    <path
      d="M60.2998 59.283C59.9289 59.6545 59.9118 60.3173 60.3098 60.6969C60.6903 61.0942 61.362 61.076 61.7329 60.7045L66.0161 56.4151L70.3067 60.6972C70.6871 61.0768 71.3412 61.0851 71.7209 60.6871C72.1184 60.3068 72.109 59.6441 71.7286 59.2644L67.438 54.9824L71.7212 50.7018C72.101 50.3127 72.1093 49.6588 71.7112 49.2792C71.3308 48.8819 70.6767 48.8913 70.2969 49.2715L66.0137 53.5609L61.7231 49.2789C61.3516 48.9081 60.6798 48.891 60.3001 49.2889C59.9027 49.6692 59.9209 50.332 60.2925 50.7028L64.583 54.9848L60.2998 59.283Z"
      fill="white"
    />
    <circle cx="3" cy="3" r="3" fill="#ED7575" />
    <circle cx="134" cy="9" r="8" fill="#ED7575" />
    <circle cx="23" cy="110" r="5" fill="#ED7575" />
  </svg>
</template>
