<template lang="pug">
.ui-rest-table(:class="classes")
  .ui-rest-table__header
    UiStack(align="end" wrap)
      UiStackItem(grow shrink)
        .ui-rest-table__filters
          slot(
            v-bind="{ searchValue: searchModel, setSearchValue, filters, updateFilters, loading, error }"
            name="filters"
          )
            .ui-rest-table__filter-field
              UiInput(
                v-model="searchModel"
                icon="Search"
                :placeholder="$t(searchPlaceholder)"
              )
      UiStack(align="center" wrap)
        UiRestTableItemsPerPage(v-model="perPageModel" :perPageOptions="perPageOptions")
        UiPagination(
          v-if="meta.total_count > filters.per_page"
          v-model="pageModel"
          :perPage="filters.per_page"
          :totalItems="meta.total_count"
          size="sm"
        )
  .ui-rest-table__breadcrumbs(v-if="breadcrumbs.length > 1")
    UiBreadcrumbs(
      size="lg"
      :items="breadcrumbs"
      showBack
    )
  .ui-rest-table__wrapper
    UiDataTable(
      v-bind="$attrs"
      :loading="isLoading"
      :headers="headers"
      :items="itemsList"
      :sortOrder="filters.sort_order"
      :sortBy="filters.sort_by"
      hover
      size="md"
      @sort="onSort"
      @rowClick="onRowClick"
      @cellClick="onCellClick"
    )
      template(v-for="col in headers" v-slot:[`header:${col.key}`]="props")
        slot(v-bind="props" :name="`header:${col.key}`")
      template(v-for="col in headers" v-slot:[`cell:${col.key}`]="props")
        slot(v-bind="props" :name="`cell:${col.key}`")
    UiEmptyState(
      v-if="error && !loading"
      style="min-height: 14rem"
      :title="$t('rest-table.error.title')"
      :description="errorDetailsMessage"
    )
      template(#actions)
        UiButton(
          icon="RefreshCw"
          variant="smooth-danger"
          size="sm"
          @click="retry"
        ) {{ $t('actions.retry') }}
    UiEmptyState(
      v-else-if="loading && !items.length"
      style="min-height: 14rem"
      :title="$t('rest-table.loading.title')"
      :description="$t('rest-table.loading.description')"
    )
    UiEmptyState(
      v-else-if="!loading && !items.length"
      style="min-height: 14rem"
      :title="$t('rest-table.empty.title')"
      :description="$t('rest-table.empty.description')"
    )
</template>

<script>
import { mapGetters } from 'vuex';
import { clearDefaultValues } from '@/common/clear-default-values';
import UiRestTableItemsPerPage from './ui-rest-table-items-per-page.vue';

export default {
  components: {
    UiRestTableItemsPerPage,
  },

  emits: ['rowClick', 'cellClick'],

  props: {
    namespace: {
      type: String,
      required: true,
    },
    storePath: {
      type: String,
      default: null,
    },
    showBottomPagination: {
      type: Boolean,
      default: false,
    },
    showTopPagination: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'placeholders.type_to_search',
    },
    headers: {
      type: Array,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: undefined,
    },
    fetchHandler: {
      type: Function,
      default: null,
    },
    initialFilters: {
      type: Object,
      default: null,
    },
    initialItemsPerPage: {
      type: Number,
      default: 10,
    },
    embed: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      filters: {
        // it's initiated from the fetchRouterTableState() method
      },
      meta: {
        total_count: 0,
      },
      path: [],
      currentFolder: {
        id: null,
        parent_id: null,
        name: '',
      },
      items: [],
    };
  },

  computed: {
    ...mapGetters(['mobileView']),
    breadcrumbs() {
      return [...this.path, this.currentFolder].map((item, index) => ({
        label: index ? item.name : 'Root folder',
        onClick: () => this.openFolder(item),
      }));
    },
    tableSize() {
      return !this.mobileView ? 'lg' : 'md';
    },
    isStored() {
      return !!this.storePath;
    },
    totalItems() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/meta`].total_count
        : this.meta.total_count;
    },
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(page) {
        this.updateFilters({
          page,
        });
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.updateFilters({
          per_page: value,
        });
      },
    },
    searchModel: {
      get() {
        return this.filters.search;
      },
      set(search) {
        this.updateFilters({
          search,
        });
      },
    },
    itemsList() {
      return this.isStored ? this.$store.getters[`${this.storePath}/items`] : this.items;
    },
    isLoading() {
      return this.isStored ? this.$store.getters[`${this.storePath}/loading`] : this.loading;
    },
    errorDetailsMessage() {
      if (this.$te(`errors.${this.error?.response?.data?.error}`)) {
        return this.$t(`errors.${this.error.response.data.error}`);
      }
      return this.error?.response?.data?.message ?? this.error?.message;
    },
    classes() {
      return {
        'ui-rest-table--embed': this.embed,
      };
    },
  },

  watch: {
    async $route() {
      if (this.loading) return;
      await this.fetchRouterTableState();
      this.fetchData();
    },
  },

  created() {
    this.fetchRouterTableState();
  },

  mounted() {
    this.fetchData();
  },

  beforeUnmount() {
    this.removeRouterTableState();
  },

  methods: {
    getDefaultFilters() {
      return {
        search: '',
        sort_by: null,
        sort_order: null,
        page: 1,
        per_page: this.initialItemsPerPage,
        ...this.initialFilters,
      };
    },
    onRowClick(event) {
      if (event.item.type === 'Folder') {
        this.openFolder(event.item);
        return;
      }
      this.$emit('rowClick', event);
    },

    onCellClick(event) {
      this.$emit('cellClick', event);
    },

    async fetchRouterTableState() {
      const tableFilters = this.$route.query[`table_${this.namespace}`] || '{}';
      this.filters = {
        ...this.getDefaultFilters(),
        ...this.filters,
        ...JSON.parse(tableFilters),
        folder_id: this.$route.params.folder_id || undefined,
      };
    },
    async updateRouterTableState(shouldReplace) {
      const routeQueryFilters = { ...this.filters };
      delete routeQueryFilters.folder_id;
      const tableQueryFilters = clearDefaultValues(routeQueryFilters, this.getDefaultFilters());
      const updatedRoute = {
        name: this.$route.name,
        query: {
          ...this.$route.query,
          [`table_${this.namespace}`]: JSON.stringify(tableQueryFilters),
        },
        params: {
          ...this.$route.params,
          folder_id: this.filters.folder_id,
        },
      };

      if (shouldReplace) {
        await this.$router.replace(updatedRoute);
        return;
      }
      await this.$router.push(updatedRoute);
    },
    async removeRouterTableState() {
      const query = { ...this.$route.query };
      delete query[`table_${this.namespace}`];
      await this.$router.replace({ query });
    },
    retry() {
      this.fetchRouterTableState();
      this.fetchData();
    },
    fetchData() {
      this.error = null;
      this.loading = true;

      this.fetchHandler(this.filters)
        .then((result) => {
          this.currentFolder = result.currentFolder;
          this.path = result.path;
          this.items = result.items;
          this.meta.total_count = result.meta?.total_count ?? 0;
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            this.error = error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onSort(options) {
      await this.updateFilters({
        page: this.filters.page,
        sort_by: options.sortOrder === null ? null : options.sortBy,
        sort_order: options.sortOrder,
      });
    },
    async updateFilters(filters) {
      this.filters = {
        ...this.filters,
        page: 1,
        ...filters,
      };
      await this.updateRouterTableState();
    },
    async openFolderById(folderId) {
      await this.updateFilters({
        folder_id: folderId,
        search: '',
      });
    },
    async openFolder(folder) {
      await this.openFolderById(folder.id);
    },
    setSearchValue(value) {
      this.searchModel = value;
    },
  },
};
</script>

<style lang="scss">
.ui-rest-table {
  &__breadcrumbs {
    padding: 0 0 0.25rem;

    & .ui-breadcrumbs li:last-child .ui-breadcrumbs-item {
      font-weight: 600;
      color: var(--bb-primary);
    }
  }

  &--embed &__breadcrumbs {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__folder-link:hover {
    cursor: pointer;
    color: var(--bb-primary);
    text-decoration: underline;
  }
}

.ui-rest-table__wrapper {
  @media screen and (max-width: 1000px) {
    overflow-x: auto;
  }
}
</style>
