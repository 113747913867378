<template lang="pug">
.step-actions
  div
    UiButton(
      v-if="attempt.companies.length"
      variant="ghost"
      icon="ArrowLeft"
      data-test-id="button-move-back"
      @click="moveBack"
    ) {{ $t('onboarding.action_select_company') }}
  UiButton(
    variant="ghost"
    icon="CloseIcon"
    data-test-id="button-cancel"
    @click="$emit('cancel')"
  ) {{ $t('actions.cancel') }}
.step-container
  .step-header.step-header--short
    .step-title {{ defaultTitle }}
    .step-description {{ $t('onboarding.create_company.description') }}
  .form-container
    .form-error
      UiAlert(
        v-if="error"
        variant="error"
        data-test-id="alert-error"
        @close="error = null"
      )
        | {{ $t(`onboarding.error.${error}`) }}
    Stack(
      vertical
      spacing="xl"
      align="stretch"
    )
      StackItem
        UiFormInput(
          v-model="form.business_id"
          :disabled="inProgress"
          :placeholder="$t('onboarding.create_company.vat_id_placeholder')"
          :vuelidate-model="v$.form.business_id"
          :label="$t('onboarding.create_company.vat_id_label')"
          data-test-id="input-business-id"
        )
      StackItem
        UiFormInput(
          v-model="form.name"
          :disabled="inProgress"
          :placeholder="$t('onboarding.create_company.company_name_placeholder')"
          :label="$t('onboarding.create_company.company_name_label')"
          :vuelidate-model="v$.form.name"
          data-test-id="input-name"
          @blur="fillSubdomainField"
        )
      StackItem
        UiFormInput(
          v-model="form.email"
          :disabled="inProgress"
          :placeholder="$t('onboarding.create_company.company_email_placeholder')"
          :label="$t('onboarding.create_company.company_email_label')"
          :vuelidate-model="v$.form.email"
          data-test-id="input-email"
        )
      StackItem
        UiInputLabel(size="lg") {{ $t('company.domain.subdomain_input_label') }}
        UiInputGroup
          UiInput(
            v-model="form.company_domain_attributes.domain"
            :error="v$.form.company_domain_attributes.domain.$error"
            data-test-id="input-domain"
            @blur="v$.form.company_domain_attributes.domain.$touch"
          )
          UiInputGroupText .{{ appDomain }}
        UiInputFeedback(
          v-if="!v$.form.company_domain_attributes.domain.$error"
          data-test-id="domain-description"
        ) {{ this.$t('signup.subdomain_name_can_be_changed_later') }}
        UiInputFeedback(
          v-for="$error in v$.form.company_domain_attributes.domain.$errors"
          error
          data-test-id="domain-errors"
        ) {{ $error.$message }}
      StackItem
        UiFormCheckbox(
          v-model="agreeTerms"
          :vuelidate-model="v$.agreeTerms"
          data-test-id="checkbox-terms"
        )
          i18n-t(keypath="onboarding.terms_and_condition_label.label")
            a.link(
              :href="termsLink"
              target="_blank"
              data-test-id="link-terms"
            ) {{ $t('onboarding.terms_and_condition_label.link') }}
      StackItem
        UiButton(
          :loading="inProgress"
          :disabled="inProgress"
          block
          data-test-id="button-submit"
          @click="submit"
        ) {{ $t('onboarding.create_company.action_create_company') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import useVuelidate from '@vuelidate/core';
import { email, required, checked, subdomain } from '@/common/validators.js';
import { mapGetters } from 'vuex';
import { ref } from 'vue';
import { convertToSubdomainName } from '@/common/convert-to-subdomain-name.js';
import OnboardingStep from '../OnboardingStep.js';

export default {
  emits: ['update-attempt', 'switch-company', 'to-step', 'cancel'],
  props: {
    defaultTitle: {
      type: String,
      default: null,
    },
    attempt: {
      type: Object,
      required: true,
    },
    termsLink: {
      type: String,
      required: true,
    },
  },

  setup() {
    const externalResults = ref({});
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },

  data() {
    return {
      inProgress: false,
      agreeTerms: false,
      error: null,
      form: {
        business_id: this.attempt.company_business_id,
        name: this.attempt.company_name,
        email: '',
        company_domain_attributes: {
          domain: '',
          full: false,
        },
      },
    };
  },

  validations() {
    return {
      form: {
        business_id: { required },
        name: { required },
        email: { required, email },
        company_domain_attributes: {
          domain: { required, subdomain },
        },
      },
      agreeTerms: { checked },
    };
  },

  computed: {
    ...mapGetters(['appDomain', 'user']),
  },

  mounted() {
    this.fillSubdomainField();
    this.fillEmailFiled();
  },

  methods: {
    fillSubdomainField() {
      if (this.form.name && !this.form.company_domain_attributes.domain) {
        this.form.company_domain_attributes.domain = convertToSubdomainName(this.form.name);
      }
    },
    fillEmailFiled() {
      this.form.email = this.attempt?.company_email ?? this.user?.email ?? '';
    },
    moveBack() {
      this.$emit('to-step', OnboardingStep.CHOOSE_COMPANY);
    },
    async submit() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      this.inProgress = true;
      let createCompanyResponse;
      try {
        createCompanyResponse = await new IntegrationAttempts(null, {
          id: this.attempt.id,
        }).createCompany(this.form);
        this.$emit('update-attempt', createCompanyResponse.data.integration_attempt);
        this.$emit('switch-company', createCompanyResponse.data.integration_attempt.company_id);
      } catch (error) {
        this.inProgress = false;
        if (error.response.data.error) {
          this.error = error.response.data.error;
        }

        return;
      }

      this.$emit('to-step', OnboardingStep.SUMMARY);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables.scss';

.link {
  text-decoration: underline;
}

.form-container {
  max-width: 380px;
  margin: 0 auto;
}

.form-error {
  margin-bottom: 2em;
}
</style>
