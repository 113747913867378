import store from '../store/index.js';

export function initializeChat() {
  const { chaskiq_url, chaskiq_settings } = store.state.index;

  if (!chaskiq_url || !chaskiq_settings?.app_id) {
    console.warn("Can't init Chaskiq chat, wrong credentials");
    return;
  }

  if (store.state)
    (function (d, t) {
      const g = d.createElement(t);
      const s = d.getElementsByTagName(t)[0];
      g.src = chaskiq_url;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        // eslint-disable-next-line
        new window.ChaskiqMessengerEncrypted(chaskiq_settings);
      };
    })(document, 'script');
}
