<template lang="pug">
teleport(to="body")
  .modal-el(v-if="show" v-bind="$attrs")
    .modal-el__scroll-container
      .modal__overlay(@click="close")
      .modal__rail(:style="{ '--containerWidth': width }")
        .modal__container
          .modal__header(v-if="title || subtitle")
            .modal__header__left
              p.modal__header__title(v-if="title") {{ title }}
              p.modal__header__subtitle(v-if="subtitle") {{ subtitle }}
            .modal__header__right
              UiIcon(
                name="Close"
                size="20"
                @click="close"
              )
          .modal__body
            slot(name="body")
          .modal__footer(v-if="$slots.footer" :style="{ justifyContent: footerPos }")
            slot(name="footer")
</template>

<script>
let modalsCount = 0;

function updateBodyClasses() {
  const { body } = document;
  if (modalsCount) {
    if (!body.classList.contains('overflow-hidden')) {
      body.classList.add('overflow-hidden');
    }
  } else {
    body.classList.remove('overflow-hidden');
  }
}

export default {
  emits: ['action', 'close'],

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    action: {
      type: Function,
    },

    close: {
      type: Function,
    },

    footerPos: {
      type: String,
    },

    width: {
      type: String,
      default: '500px',
    },
  },

  watch: {
    show(val) {
      if (val) {
        modalsCount++;
      } else {
        modalsCount--;
      }
      updateBodyClasses();
    },
  },

  mounted() {
    if (this.show) {
      modalsCount++;
    }
    updateBodyClasses();
  },

  beforeUnmount() {
    if (this.show) {
      modalsCount--;
    }
    updateBodyClasses();
  },
};
</script>

<style lang="scss" scoped>
.modal-el {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1060;
  overflow: auto;

  &__scroll-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
}

.modal {
  &__rail {
    --containerWidth: 100%;
    position: relative;
    max-width: var(--containerWidth);
    width: 100%;
    flex-shrink: 0;
    z-index: 1;
  }

  &__container {
    width: 100%;
    background-color: var(--bb-app-bg-main);
    box-shadow: 0 0 0 1px rgba(var(--bb-shadow-rgb), 0.06),
      0 1px 3px rgba(var(--bb-shadow-rgb), 0.15), 0 5px 10px rgba(var(--bb-shadow-rgb), 0.1);
    border-radius: 5px;
    padding: 26px 28px;
    text-align: left;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;

    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }

    &__subtitle {
      font-size: 16px;
      margin: 0;
    }

    &__title + &__subtitle {
      margin-top: 15px;
    }

    &__right {
      cursor: pointer;
    }
  }

  &__body {
  }

  &__footer {
    display: flex;
    justify-content: end;
    margin-top: 32px;
    gap: 1em;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--bb-shadow-rgb), 0.4);
    z-index: 0;
  }
}
</style>
