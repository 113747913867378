<template lang="pug">
modal(
  :show="show"
  :title="$t('companies.company_modal.title')"
  :close="close"
  width="410px"
)
  template(#body)
    Stack(
      vertical
      align="stretch"
      spacing="lg"
    )
      StackItem(v-for="fieldKey in formFieldsKeys")
        UiInputLabel {{ $t(`companies.company_modal.${fieldKey}_label`) }}
        UiInput(
          v-model="form[fieldKey]"
          :placeholder="$t(`companies.company_modal.${fieldKey}_placeholder`)"
          :error="v$.form[fieldKey].$error"
          :disabled="inProgress"
          @blur="v$.form[fieldKey].$touch"
          @input="clearExternalResult('form.' + fieldKey)"
        )
        UiInputFeedback(v-for="$error in v$.form[fieldKey].$errors" error) {{ $error.$message }}
      StackItem(v-if="errorText")
        UiAlert(variant="error" @close="errorText = null") {{ errorText }}
  template(#footer)
    Stack.flex-grow-1(justify="space-between")
      StackItem
        UiButton(
          variant="outline-primary"
          :disabled="inProgress"
          @click="close"
        ) {{ $t('companies.company_modal.btn_cancel') }}
      StackItem
        UiButton(
          :loading="inProgress"
          :disabled="inProgress"
          @click="save"
        ) {{ $t('companies.company_modal.btn_apply') }}
</template>

<script>
import Companies from '@/api/models/Companies.js';
import useVuelidate from '@vuelidate/core';
import { powerBIGroupUrl, required, email } from '@/common/validators.js';
import { ref } from 'vue';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import _set from 'lodash/set.js';

function getEmptyForm() {
  return {
    name: '',
    business_id: '',
    email: '',
    analyst_email: '',
    power_bi_group_url: '',
  };
}

export default {
  setup() {
    const externalResults = ref();
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },

  data() {
    return {
      show: false,
      inProgress: false,
      form: getEmptyForm(),
      errorText: null,
    };
  },

  validations() {
    return {
      form: {
        name: { required },
        business_id: { required },
        email: { required, email },
        analyst_email: { email },
        power_bi_group_url: { powerBIGroupUrl },
      },
    };
  },

  computed: {
    formFieldsKeys() {
      return Object.keys(this.form);
    },
  },

  methods: {
    open() {
      this.resetForm();
      this.show = true;
    },

    resetForm() {
      this.v$.$reset();
      this.errorText = null;
      this.form = { ...this.form, ...getEmptyForm() };
    },

    close() {
      this.$emit('close');
      this.show = false;
    },

    clearExternalResult(path) {
      _set(this.externalResults, path, null);
    },

    async save() {
      this.errorText = null;
      this.externalResults = {};
      const valid = await this.v$.$validate();
      if (!valid) return;

      this.inProgress = true;

      await new Companies({ adminApi: true })
        .create(this.form)
        .then(() => {
          this.inProgress = false;
          this.show = false;
          this.$emit('wasCreated');
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            const externalResults = { form: parseResponseErrors(error.response.data.errors) };
            Object.assign(this.externalResults, externalResults);
          }
          this.inProgress = false;
          this.errorText = error.response.data.error;
        });
    },
  },
};
</script>
