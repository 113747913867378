<template lang="pug">
.api-keys__tab.tab-container
  .api-keys__section(v-for="section in masterKeys" :key="section.key")
    .api-keys__header
      .api-keys__header__title(data-test-id="title") {{ section.title }}
      .api-keys__header__subtitle(v-if="section.link")
        a(
          :href="section.link.src"
          target="_blank"
          data-test-id="subtitle"
        ) {{ section.link.label }}
    .api-keys__group(v-for="group in section.groups")
      .api-keys__header
        .api-keys__header__subtitle(data-test-id="group-subtitle") {{ group.title }}
      .api-keys__body
        ApiKeyCard(
          v-if="group.header"
          type="header"
          :tokenType="section.key"
          :data="group.header"
          :group="group"
          :title="$t('company.apikeys.groups.header_title')"
          :subtitle="group.header.name"
          refreshKeyType="base"
          data-test-id="api-keys-group-header"
        )
        ApiKeyCard(
          v-if="group.auth"
          type="auth"
          :tokenType="section.key"
          :data="group.auth"
          :group="group"
          :title="$t('company.apikeys.groups.auth_title')"
          :subtitle="$t('company.apikeys.groups.auth_subtitle', { username: group.auth.user_name })"
          data-test-id="api-keys-group-auth"
        )
        ApiKeyCard(
          v-if="group.refresh"
          type="refresh"
          :tokenType="section.key"
          :data="group.refresh"
          :group="group"
          :title="$t('company.apikeys.groups.refresh_title')"
          :subtitle="$t('company.apikeys.groups.refresh_subtitle')"
          refreshKeyType="base"
          data-test-id="api-keys-group-refresh"
        )
</template>

<script>
import { mapGetters } from 'vuex';
import ApiMasterKeys from '@/api/models/MasterKeys.js';
import ApiKeyCard from './components/ApiKeyCard.vue';

export default {
  components: {
    ApiKeyCard,
  },

  data() {
    return {
      loading: false,
      masterKeys: [],
    };
  },

  computed: {
    // TODO: recheck it, unnecessary methods
    ...mapGetters('company', ['company']),
    ...mapGetters(['appDomain']),
    isApiEnabled() {
      return this.company && this.company.api_enabled;
    },
    isDataroomApiEnabled() {
      return this.company && this.company.dataroom_api_enabled;
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const response = await new ApiMasterKeys().fetchAll();
        this.masterKeys = Object.entries(response.data).reduce((acc, [key, section]) => {
          if (section) {
            acc.push({
              key,
              ...section,
              title: this.$t(`company.apikeys.${key}.title`),
              link: {
                label: this.$t(`company.apikeys.${key}.link.label`),
                src: this.$t(`company.apikeys.${key}.link.src`, { appDomain: this.appDomain }),
              },
            });
          }
          return acc;
        }, []);
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.api-keys {
  &__section {
    & + & {
      margin-top: 2.5rem;
    }
  }

  &__group {
    margin-top: 1.25rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 10px;

    &__title {
      font-weight: 700;
      font-size: 18px;
      margin-right: 20px;
    }

    &__subtitle {
      font-size: 16px;

      & a {
        text-decoration: underline;
      }
    }
  }

  &__tab {
    padding: 50px 30px;
    background-color: var(--bb-app-bg-main);
    border-radius: 0 10px 10px 10px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(3, minmax(10px, 1fr));
    gap: 1.5rem;

    @media screen and (max-width: 1440px) {
      grid-template-columns: repeat(2, minmax(10px, 1fr));
      gap: 1rem;
    }

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
      gap: 0.75rem;
    }
  }
}

@media screen and (max-width: 1900px) {
  .api-keys__tab {
    padding: 25px;
  }
}

@media screen and (max-width: 1100px) {
  .api-keys__tab {
    padding: 10px;
  }
}
</style>
