<template lang="pug">
.heading
  .heading__title
    h2 {{ title }}
    .heading__title__right(v-if="$slots.default")
      slot
  slot(name="subtitle")
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.heading {
  margin-bottom: 35px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1000px) {
  .heading {
    margin-bottom: 20px;
  }
  h2 {
    font-size: 20px;
  }
}
</style>
