<template lang="pug">
.dataroom-forms
  Heading(:title="`${$t('dataroom.entries')} - ${form_name}`")
    UiButton(
      v-if="isAdmin"
      icon="TwoUsers"
      @click="openDataRoomAccessListModal"
    ) {{ $t('actions.manage_access') }}
  .dataroom-forms__container(v-if="entry_ids.length")
    .dataroom-forms__card(
      v-for="(entry_id, index) in entry_ids"
      :key="entry_id"
      :set="(entry = entries[entry_id])"
    )
      .dataroom-forms__card__left(:set="(entryUser = entry.user)")
        AvatarBox(
          :src="entryUser.avatar"
          :label="entryUser.name || entryUser.email"
          :style="{ width: '33px', height: '33px', marginRight: '15px' }"
        )
        .dataroom-forms__card__user-data
          p.dataroom-forms__card__user-data__user {{ entryUser.name }} ({{ entryUser.email }})
          p {{ entry.updated_at }}
      .dataroom-forms__card__right
        .dataroom-forms__card__type
          UiButton(:to="`/dataroom/${form_id}/entries/${entry.id}`" :disabled="isViewAsOtherUser") {{ $t('dataroom.fill_view') }}
  EntityAccessListModal(ref="entityAccessListModal")
</template>

<script>
import { mapGetters } from 'vuex';
import Dataroom from '@/api/models/Dataroom.js';
import EntityAccessListModal from '@/modals/UserGroups/entity-access-list/entity-access-list-modal.vue';
import Company from '@/models/Company.js';

export default {
  name: 'DataroomEntries',
  components: { EntityAccessListModal },
  data() {
    return {
      form_id: null,
      form_name: null,
      entry_ids: [],
      entries: {},
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),
    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },
  },

  mounted() {
    this.form_id = this.$route.params.id;
    this.fetchData();
  },

  methods: {
    fetchData() {
      new Dataroom(null, { id: this.form_id })
        .fetch({ entries_list: true })
        .then((resp) => {
          const {
            entry_ids,
            entries,
            multi_entries_visible,
            name: form_name,
          } = resp.data.dataroom_form;
          if (!multi_entries_visible) {
            this.$router.push({ name: 'Dataroom' });
          } else {
            this.form_name = form_name;
            this.entry_ids = entry_ids;
            this.entries = entries;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDataRoomAccessListModal() {
      this.$refs.entityAccessListModal.open({
        type: 'DataroomForm',
        entity: { name: this.form_name, type: 'dataroom_forms', id: this.form_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dataroom-forms__card {
  &__left {
    display: flex;
    align-items: center;
  }
}
</style>
