import ApiUser from '@/api/user.js';
import { store } from '@/store/index.js';

export const SESSION_CHECK_INTERVAL = 60000;

class SessionService {
  constructor() {
    this.pingInterval = null;
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  createInterval() {
    if (store.getters.isAuthenticated && store.getters.adminDomain !== window.location.host) {
      this.pingInterval = setInterval(() => SessionService.handleTimeout(), SESSION_CHECK_INTERVAL);
    }
  }

  static handleTimeout() {
    ApiUser.sendPingSession();
  }

  async handleVisibilityChange() {
    if (
      document.visibilityState === 'visible' &&
      store.getters.isAuthenticated &&
      store.getters.adminDomain !== window.location.host
    ) {
      const res = await ApiUser.sendPingSession();
      if (res.status === 204) {
        this.createInterval();
      }
    } else {
      this.destroyInterval();
    }
  }

  destroyInterval() {
    clearInterval(this.pingInterval);
    this.pingInterval = null;
  }

  createSubscribers() {
    window.document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  init() {
    this.createSubscribers();
    this.createInterval();
  }

  destroy() {
    this.destroyInterval();
    window.document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}

export const createSessionServiceInstance = () => {
  return new SessionService();
};

export default createSessionServiceInstance();
