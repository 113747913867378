function buildTreeRecursive(entities, rootId, options, level = 0) {
  const list = [];
  if (level >= options.maxLevel) {
    return list;
  }
  entities.forEach((entity) => {
    if (entity[options.parentIdKey] === rootId) {
      list.push({
        level,
        entity,
        children: buildTreeRecursive(entities, entity[options.idKey], options, level + 1),
      });
    }
  });
  return list;
}

export function buildTree(entities = [], rootId = null, options = {}) {
  const _options = {
    idKey: 'id',
    parentIdKey: 'parent_id',
    maxLevel: 24,
    ...options,
  };
  return buildTreeRecursive(entities, rootId, _options);
}
