<template lang="pug">
.analytics-report__header
  UiStack
    UiStack
      UiButton(
        v-if="mobileView"
        variant="outline-primary"
        size="sm"
        data-test-id="button-toggle"
        @click="$emit('toggle')"
      ) {{ $t('analytics.reports') }}

    StackItem(fill)

    StackItem(v-if="reportLocale && !mobileView && !editMode")
      LanguageSwitcher.language-switcher(
        reported
        :lang="reportLocale"
        data-test-id="language-switcher"
        @switchLocale="$emit('switchReportLanguage', $event)"
      )

    StackItem(v-if="report || !isViewAsOtherUser")
      .actions-bar(data-test-id="action-bar")
        UiStack(spacing="sm")
          template(v-for="action in actions" :key="action.key")
            //- Themes switcher
            UiPopover(v-if="action.key === 'theme'" placement="bottom")
              template(#activator="{ show }")
                ActionBarButton(
                  :action="action"
                  :disableTooltip="show"
                  :variant="show ? 'smooth-primary' : null"
                  :data-test-id="action.key"
                )
              ReportThemeMenu(
                :activeThemeId="activeReportTheme"
                data-test-id="report-theme-menu"
                @select="$emit('switchUserReportTheme', $event)"
              )
            //- Settings menu
            UiPopover(
              v-else-if="action.key === 'settings'"
              placement="bottom"
              :hideOnClick="false"
              data-test-id="settings-menu"
            )
              template(#activator="{ show }")
                ActionBarButton(
                  :action="action"
                  :disableTooltip="show"
                  :variant="show ? 'smooth-primary' : null"
                  :data-test-id="action.key"
                )
              UiOptionsMenu
                UiOptionsMenuTitle {{ $t('company.reporting_language') }}
                .option-language
                  LanguageSwitcher(
                    reported
                    :lang="reportLocale"
                    data-test-id="settings-language-switch"
                    @switchLocale="$emit('switchReportLanguage', $event)"
                  )
            //- Default action
            ActionBarButton(
              v-else
              :action="action"
              :data-test-id="action.key"
            )
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import SaveIcon from '@/assets/images/analytics/save.svg';
import AnalyticsSidebar from '@/views/Analytics/components/Sidebar/Index.vue';
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';
import ReportThemeMenu from './ReportThemeMenu.vue';
import ActionBarButton from './ActionBarButton.vue';

export default {
  components: {
    LanguageSwitcher,
    AnalyticsSidebar,
    MiniDropdown,
    ReportThemeMenu,
    ActionBarButton,
  },
  emits: [
    'switchReportLanguage',
    'switchUserReportTheme',
    'toggle-bookmarks',
    'delete-bookmark',
    'create-bookmark',
    'toggle',
    'setDefaultReport',
    'editReport',
    'saveReport',
    'exitEditMode',
    'toggleFullscreen',
    'printReport',
    'copyLink',
    'openSetting',
  ],
  props: {
    report: {
      type: Object,
    },
    isDefaultReport: {
      type: Boolean,
    },
    isShowBookmarksPanel: {
      type: Boolean,
      default: false,
    },
    currentBookmark: {
      type: Object,
    },
    currentBookmarkId: {
      type: Number,
    },
    reportLocale: {
      type: Object,
    },
    editMode: {
      type: String,
    },
    editable: {
      type: Boolean,
    },
    activeReportTheme: {
      type: Object,
    },
    recomendedLayoutType: {
      type: Number,
      default: null,
    },
    layoutType: {
      type: Number,
      default: null,
    },
    refreshDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSetting: false,
    };
  },

  computed: {
    ...mapGetters(['mobileView', 'isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),

    icons() {
      return {
        save: SaveIcon,
      };
    },

    actions() {
      return [
        {
          key: 'toggle_layout_type',
          icon: this.layoutType === null ? 'Monitor' : 'Mobile',
          label:
            this.layoutType === null
              ? this.$t('analytics.actions_bar.desktop_view')
              : this.$t('analytics.actions_bar.mobile_view'),
          onClick: () => this.$emit('toggle-layout-type'),
          variant: this.layoutType === null ? null : 'smooth-primary',
          visible: !this.editMode && !this.mobileView,
        },
        {
          key: 'refresh',
          icon: 'RefreshCw',
          label: this.$t('analytics.actions_bar.refresh_view'),
          onClick: () => this.$emit('refresh-view'),
          disabled: this.refreshDisabled,
          visible: true,
        },
        {
          key: 'bookmarks',
          icon: 'Bookmarks',
          label: this.$t('analytics.actions_bar.open_bookmarks'),
          onClick: () => this.$emit('toggle-bookmarks'),
          variant: this.isShowBookmarksPanel ? 'smooth-primary' : null,
          visible: true,
        },
        {
          key: 'edit',
          icon: 'PencilEdit',
          label: this.$t('analytics.actions_bar.enter_to_edit_mode'),
          onClick: () => this.$emit('editReport'),
          visible: !!this.editable && !this.editMode && !!this.report,
          disabled: this.isViewAsOtherUser,
        },
        {
          key: 'save',
          icon: 'Check',
          label: this.$t('analytics.actions_bar.save_report_changes'),
          onClick: () => this.$emit('saveReport'),
          visible: !!this.editable && !!this.editMode && !!this.report,
          disabled: this.isViewAsOtherUser,
        },
        {
          key: 'exit_edit',
          icon: 'LogOut',
          label: this.$t('analytics.actions_bar.exit_from_edit_mode'),
          onClick: () => this.$emit('exitEditMode'),
          visible: !!this.editable && !!this.editMode && !!this.report,
          disabled: this.isViewAsOtherUser,
        },
        {
          key: 'theme',
          icon: 'Brush',
          label: this.$t('analytics.actions_bar.change_report_theme'),
          visible: !this.mobileView,
          disabled: this.isViewAsOtherUser,
        },
        {
          key: 'fullscreen',
          icon: 'Maximize',
          variant: 'primary',
          label: this.$t('analytics.actions_bar.fullscreen_mode'),
          onClick: () => this.$emit('toggleFullscreen'),
          visible: true,
        },
        {
          key: 'print',
          icon: 'Print',
          label: this.$t('analytics.actions_bar.print'),
          onClick: () => this.$emit('printReport'),
          visible: true,
        },
        {
          key: 'export_report',
          icon: 'Download',
          label: this.$t('analytics.actions_bar.export_report'),
          onClick: () => this.$emit('exportReport'),
          visible: this.currentCompany?.export_report_enabled,
        },
        {
          key: 'copy_powerbi_link',
          icon: 'Powerbi',
          label: this.$t('actions.copy_powerbi_report_link'),
          onClick: () => this.$emit('copyPowerbiLink'),
          visible: this.report?.report_group_id_pbi && this.report?.report_id_pbi,
        },
        {
          key: 'copy_link',
          icon: 'Link',
          variant: 'ghost-primary',
          label: this.$t('analytics.actions_bar.copy_report_link'),
          onClick: () => this.$emit('copyLink'),
          visible: true,
        },
        {
          key: 'settings',
          icon: 'Settings',
          label: this.$t('analytics.actions_bar.settings'),
          onClick: this.openSetting,
          visible: this.reportLocale && this.mobileView,
        },
      ].filter((action) => action.visible);
    },
  },

  methods: {
    openSetting() {
      this.showSetting = true;
    },

    closeSetting() {
      this.showSetting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.language-switcher:deep {
  & .ui-multiselect-wrapper {
    border: 1px solid var(--bb-gray-900);
  }
}

.actions-bar {
  padding: 0.25rem 1rem;
  border-radius: 40px;
  background: var(--bb-app-bg-main);
  border: 1px solid var(--bb-gray-900);
  position: relative;
}

.analytics-report {
  &__layout {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0.5rem 15px;
  }
}

.option-language {
  padding: 0 0.5rem;
}
</style>
