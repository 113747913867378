<template lang="pug">
.admin-layout
  Heading(:title="$t('administrators.title')")
    UiButton(
      icon="PlusFilled"
      :round="mobileView"
      @click="open"
    ) {{ $t('administrators.add_admin') }}

  .table-container
    RestTable(
      ref="table"
      :headers="headersList"
      :fetchHandler="fetch"
      searchable
      :searchLabel="$t('administrators.table.search_placeholder')"
    )
      template(v-slot:cel_email="{ item }")
        router-link(:to="{ name: 'EditAdministrator', params: { id: item.id } }") {{ item.email }}
      template(v-slot:cel_user_role="{ item }")
        UserRoleBadge(:role="item.user_role")
      template(v-slot:cel_created_at="{ item }")
        span {{ $d(item.created_at, 'short') }}
      template(v-slot:cel_actions="{ item }")
        .action-container
          UiButton(
            :to="{ name: 'EditAdministrator', params: { id: item.id } }"
            variant="text-primary"
            icon="Edit"
            alt="edit"
          )
          UiButton(
            icon="Trash"
            variant="text-danger"
            @click="deleteUser(item)"
          )
  AddAdminModal(ref="addAdminModal" @done="refreshData")
  DeleteAdminModal(ref="deleteAdminModal" @done="refreshData")
</template>

<script>
import { mapGetters } from 'vuex';
import AddAdminModal from '@/modals/Admin/Administrators/add.vue';
import DeleteAdminModal from '@/modals/Admin/Administrators/delete.vue';
import UserRoleBadge from '@/views/Admin/Administrators/UserRoleBadge.vue';
import Administrators from '@/api/models/Administrators.js';

export default {
  name: 'Administrators',
  components: {
    AddAdminModal,
    DeleteAdminModal,
    UserRoleBadge,
  },
  data() {
    return {
      selectAll: false,
      selectedColumns: [],
      selectedCompaniesIds: [],
      companies: [],
      meta: null,
      hiddenList: [],
      headersList: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          squeeze: true,
        },
        {
          key: 'email',
          label: this.$t('administrators.table.email'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('administrators.table.name'),
          sortable: true,
        },
        {
          key: 'user_role',
          label: this.$t('administrators.table.role'),
          sortable: true,
          centred: true,
        },
        {
          key: 'created_at',
          label: this.$t('administrators.table.created'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('administrators.table.actions'),
          squeeze: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
  },
  methods: {
    async fetch(params) {
      let items;
      let meta;
      await new Administrators()
        .fetchAll(params)
        .then((res) => {
          items = res.data.administrators;
          ({ meta } = res.data);
        })
        .catch((e) => console.log(e));

      return { items, meta };
    },

    refreshData() {
      this.$refs.table.fetchData();
    },

    open() {
      this.$refs.addAdminModal.open();
    },

    deleteUser(user) {
      this.$refs.deleteAdminModal.open(user);
    },

    isSuperAdmin(user) {
      return user.user_role === 'global_admin';
    },
  },
};
</script>

<style scoped lang="scss">
.access__table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.th__checkbox {
  position: absolute;
  left: 20px;
  top: -24px;
  z-index: 200;

  &.th__id {
    transform-origin: left center;
    left: 10px;
    transform: translateX(0);
  }

  span {
    font-weight: 400;
    white-space: nowrap;
  }
}

.action-container {
  width: 100%;
  display: flex;
  gap: 25px;
  text-align: center;
}
</style>
