<template lang="pug">
.ui-form-field.ui-form-field--date-picker
  UiInputLabel(
    v-if="label || $slots.label"
    :shifted="shifted"
    data-vi="ui-label"
  )
    slot(name="label")
      | {{ label }}
  UiDatePicker(v-bind="$attrs" @blur="vuelidateModel?.$touch")
    template(v-for="(_, slot) of $slots" v-slot:[slot]="scope")
      slot(v-bind="scope" :name="slot")
  UiInputFeedback(v-if="description" :shifted="shifted") {{ description }}
    template(v-if="showVuelidateErrors && vuelidateModel?.$errors")
      UiInputFeedback(
        v-for="$error in vuelidateModel?.$errors"
        :shifted="shifted"
        error
      ) {{ $t($error.$message) }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
    showVuelidateErrors: {
      type: Boolean,
      default: true,
    },
    shifted: {
      type: Boolean,
      default: undefined,
    },
  },
});
</script>
