<template lang="pug">
.form-section
  .form-section__main
    ShowView(
      v-if="!isEdit"
      :banner="banner"
      @toggleEdit="toggleEdit"
      @delete="$emit('delete')"
    )
    EditView(
      v-else
      v-model="bannerForm"
      @toggleEdit="toggleEdit"
      @cancel="cancelEdit"
      @delete="$emit('delete')"
      @save="$emit('save')"
    )
  .form-section__details
    .login-banner-card__right-title(v-if="mobileView") {{ $t('login_banners.preview') }}
    LoginBannerPreview.login-banner-card__banner-preview(
      :title="bannerForm.title"
      :description="bannerForm.description"
      :isPreview="true"
    )
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBannerPreview from '@/components/login-banner/login-banner.vue';
import ShowView from '@/components/login-banner/components/show-view.vue';
import EditView from '@/components/login-banner/components/edit-view.vue';

function getForm() {
  return {
    id: null,
    title: '',
    description: '',
    descriptionLength: null,
  };
}

export default {
  components: {
    ShowView,
    EditView,
    LoginBannerPreview,
  },
  emits: ['delete'],
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEdit: false,
      bannerForm: getForm(),
    };
  },
  computed: {
    ...mapGetters(['mobileView']),
  },
  created() {
    this.bannerForm = { ...this.bannerForm, ...this.banner };
  },
  methods: {
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    cancelEdit() {
      this.isEdit = !this.isEdit;
      this.bannerForm = { ...this.bannerForm, ...this.banner };
    },
  },
};
</script>

<style lang="scss" scoped>
.login-banner-card {
  &__container {
    display: flex;
    align-items: flex-start;
  }

  &__item {
    width: 50%;
    margin-right: 40px;
  }

  &__right-title {
    width: 50%;
    font-size: 16px;
    color: var(--bb-gray-600);
  }

  &__wrapper-preview {
    width: 50%;
  }

  &__banner-preview {
    max-width: 450px;
  }
}

@media screen and (max-width: 1000px) {
  .login-banner-card {
    &__right-title {
      width: auto;
      margin-bottom: 10px;
    }
    &__wrapper-preview {
      width: 100%;
      margin-top: 10px;
    }
    &__container {
      flex-direction: column;
    }
    &__item {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
