import { store } from '@/store/index.js';

// Layouts
import ApplicationLayout from '@/layouts/ApplicationLayout.vue';
import LiteLayout from '@/layouts/LiteLayout.vue';

// Pages
import Company from '@/views/Company/Index.vue';
import EditCompany from '@/views/Company/Edit/Index.vue';
import Analytics from '@/views/Analytics/Index.vue';
import ShowView from '@/views/Analytics/ShowView.vue';
import Companies from '@/views/Admin/Companies/Companies.vue';
import CompaniesGroup from '@/views/Admin/CompaniesGroup/CompaniesGroup.vue';
import CompaniesGroupEdit from '@/views/Admin/CompaniesGroup/CompaniesGroupEdit.vue';
import FastAccess from '@/views/Admin/FastAccess/FastAccess.vue';
import Users from '@/views/Admin/Users/Users.vue';
import NoHaveCompany from '@/views/NoHaveCompany/Index.vue';
import Unsubscribed from '@/views/Unsubscribed/Index.vue';
import ViewDataroom from '@/views/Dataroom/view-dataroom.vue';
import DataroomNew from '@/views/Dataroom/New.vue';
import DataroomEntry from '@/views/Dataroom/Entry.vue';
import DataroomEntries from '@/views/Dataroom/List.vue';
import Login from '@/views/Login/Index.vue';
import Registration from '@/views/Registration/Index.vue';
import ResetPassword from '@/views/RecoveryPassword/New.vue';
import RecoveryPassword from '@/views/RecoveryPassword/Edit.vue';
import NetvisorIntegrationEn from '@/views/NetvisorIntegration/En.vue';
import NetvisorIntegrationFi from '@/views/NetvisorIntegration/Fi.vue';
import PipedriveIntegrationEn from '@/views/PipedriveIntegration/En.vue';
import PipedriveIntegrationFi from '@/views/PipedriveIntegration/Fi.vue';
import UserCompanies from '@/views/Admin/Users/Companies.vue';
import MasterKeys from '@/views/Admin/MasterKeys/MasterKeys.vue';
import EditMasterKeys from '@/views/Admin/MasterKeys/EditingKeys.vue';
import Administrators from '@/views/Admin/Administrators/Administrators.vue';
import EditAdministrator from '@/views/Admin/Administrators/Edit.vue';
import OnboardingView from '@/views/Onboarding/OnboardingView.vue';
import PortalManagement from '@/views/Admin/PortalManagement/PortalManagement.vue';
import Logs from '@/views/Admin/Logs/Index.vue';
import ReportEmbeddedLinksView from '@/views/reports/embedded-links-view.vue';
import SelectCompany from '@/views/SelectCompany/Index.vue';
import UserGroupsShowView from '@/views/UserGroups/ShowView.vue';
import UserGroupsEntitiesTreeView from '@/views/UserGroups/entities-tree-view.vue';
import CompanyUsersShowView from '@/views/CompanyUsers/ShowView.vue';
import ServerSettingsView from '@/views/Admin/server-settings/server-settings-view.vue';
import IntegrationActivityView from '@/views/Admin/integration-activity/integration-activity-view.vue';
import ContentIndexView from '@/views/content/content-index-view.vue';
import { GLOBAL_ADMIN_ROLE } from '@/helper/roles.js';

async function storeCompanyMiddleware(to) {
  await store.dispatch('company/fetchCompany', to.params.company_id ?? null);
  return true;
}

export const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Analytics' },
    component: ApplicationLayout,
    meta: {
      requiresAuth: true,
      userRoute: true,
    },
    children: [
      {
        path: '/content/:folder_id?',
        name: 'ContentIndex',
        component: ContentIndexView,
      },
      {
        path: '/company',
        name: 'Company',
        redirect: { name: 'CompanyShow', params: { tab: 'users' } },
        beforeEnter: [storeCompanyMiddleware],
        children: [
          {
            path: 'edit',
            name: 'CompanyEdit',
            component: EditCompany,
          },
          {
            path: ':tab?',
            name: 'CompanyShow',
            component: Company,
          },
          {
            path: 'user_groups/:user_group_id',
            name: 'UserGroup',
            children: [
              {
                path: ':tab?',
                name: 'UserGroupsShow',
                component: UserGroupsShowView,
                children: [
                  {
                    path: 'tree',
                    name: 'UserGroupsEntitiesTree',
                    component: UserGroupsEntitiesTreeView,
                  },
                ],
              },
            ],
          },
          {
            path: 'users/:user_id/:tab?',
            name: 'CompanyUserShow',
            component: CompanyUsersShowView,
          },
        ],
      },
      {
        path: '/analytics/preview',
        name: 'Analytics',
        component: ShowView,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/analytics/report/:id',
        name: 'AnalyticsReport',
        component: Analytics,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/analytics/bookmark/:id',
        name: 'AnalyticsBookmark',
        component: Analytics,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/dataroom',
        name: 'Dataroom',
        component: ViewDataroom,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/dataroom/new',
        name: 'DataroomNew',
        component: DataroomNew,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/dataroom/:id',
        name: 'DataroomView',
        component: DataroomEntry,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/dataroom/:id/entries',
        name: 'DataroomEntries',
        component: DataroomEntries,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: '/dataroom/:id/entries/:entry_id',
        name: 'DataroomViewEntry',
        component: DataroomEntry,
        meta: {
          requiresAccess: true,
        },
      },
      {
        path: 'reports',
        redirect: '/',
        children: [
          {
            path: ':id',
            redirect: '/',
            children: [
              {
                path: 'embedded_links',
                component: ReportEmbeddedLinksView,
                name: 'ReportEmbeddedLinks',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Admin',
    path: '/admin',
    component: ApplicationLayout,
    meta: {
      requiresAuth: true,
      globalAdminRoute: true,
    },
    redirect: '/admin/companies',
    children: [
      {
        path: 'companies',
        name: 'AdminCompanies',
        component: Companies,
      },
      {
        path: 'companies/:company_id',
        name: 'AdminCompany',
        redirect: (to) => {
          return {
            name: 'AdminCompanyShow',
            params: { tab: 'users', company_id: to.params.company_id },
          };
        },
        beforeEnter: [storeCompanyMiddleware],
        children: [
          {
            path: 'edit',
            name: 'AdminCompanyEdit',
            component: EditCompany,
          },
          {
            path: ':tab?',
            name: 'AdminCompanyShow',
            component: Company,
          },
          {
            path: 'user_groups/:user_group_id',
            name: 'AdminUserGroup',
            children: [
              {
                path: ':tab?',
                name: 'AdminUserGroupsShow',
                component: UserGroupsShowView,
                children: [
                  {
                    path: 'tree',
                    name: 'AdminUserGroupsEntitiesTree',
                    component: UserGroupsEntitiesTreeView,
                  },
                ],
              },
            ],
          },
          {
            path: 'users/:user_id',
            redirect: { name: 'AdminCompanyUserShow', params: { tab: 'groups' } },
            children: [
              {
                path: ':tab?',
                name: 'AdminCompanyUserShow',
                component: CompanyUsersShowView,
              },
            ],
          },
        ],
      },
      {
        path: 'groups',
        name: 'Groups',
        component: CompaniesGroup,
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'users/:id/companies',
        name: 'UserCompanies',
        component: UserCompanies,
      },
      {
        path: 'access',
        name: 'Access',
        component: FastAccess,
      },
      {
        path: 'administrators',
        name: 'Administrators',
        component: Administrators,
        meta: {
          availableRoles: [GLOBAL_ADMIN_ROLE],
        },
      },
      {
        path: 'administrators/:id/edit',
        name: 'EditAdministrator',
        component: EditAdministrator,
        meta: {
          availableRoles: [GLOBAL_ADMIN_ROLE],
        },
      },
      {
        path: 'credentials',
        name: 'Credentials',
        component: MasterKeys,
        meta: {
          availableRoles: [GLOBAL_ADMIN_ROLE],
        },
      },
      {
        path: 'credentials/:id/edit',
        name: 'EditMasterKeys',
        component: EditMasterKeys,
        meta: {
          availableRoles: [GLOBAL_ADMIN_ROLE],
        },
      },
      {
        path: 'groups/:id',
        name: 'EditGroups',
        component: CompaniesGroupEdit,
      },
      {
        path: 'portal_management',
        name: 'PortalManagement',
        component: PortalManagement,
      },
      {
        path: 'server_settings',
        name: 'ServerSettings',
        component: ServerSettingsView,
      },
      {
        path: 'integration_activity',
        name: 'IntegrationActivity',
        component: IntegrationActivityView,
      },
    ],
  },
  {
    path: '/',
    name: 'Landing',
    component: LiteLayout,
    redirect: { name: 'Login' },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { noAuthOnly: true },
      },
      {
        path: '/registration',
        name: 'Registration',
        component: Registration,
        meta: { noAuthOnly: true },
      },
      {
        path: '/password',
        name: 'RecoveryPassword',
        component: RecoveryPassword,
        meta: { noAuthOnly: true },
      },
      {
        path: '/password/new',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { noAuthOnly: true },
      },
      {
        path: '/unsubscribed',
        name: 'Unsubscribed',
        component: Unsubscribed,
      },
      {
        path: '/sign-up-via-netvisor/en',
        name: 'NetvisorIntegrationEn',
        component: NetvisorIntegrationEn,
      },
      {
        path: '/sign-up-via-netvisor/sv',
        name: 'NetvisorIntegrationSv',
        component: NetvisorIntegrationEn,
      },
      {
        path: '/sign-up-via-netvisor/fi',
        name: 'NetvisorIntegrationFi',
        component: NetvisorIntegrationFi,
      },
      {
        path: '/sign-up-via-pipedrive/en',
        name: 'PipedriveIntegrationEn',
        component: PipedriveIntegrationEn,
      },
      {
        path: '/sign-up-via-pipedrive/fi',
        name: 'PipedriveIntegrationFi',
        component: PipedriveIntegrationFi,
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'OnboardingLayout',
    component: LiteLayout,
    meta: {
      noRequireCompany: true,
    },
    children: [
      {
        name: 'Onboarding',
        path: '/onboarding/:attempt_id',
        component: OnboardingView,
        meta: {
          noRequireCompany: true,
        },
      },
    ],
  },
  {
    path: '/',
    name: 'LiteLayout',
    redirect: '/',
    component: LiteLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/no_have_company',
        name: 'NoHaveCompany',
        component: NoHaveCompany,
      },
      {
        path: '/select_company',
        name: 'SelectCompany',
        component: SelectCompany,
      },
    ],
  },

  // redirect 404 to default page
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

export default routes;
