<template lang="pug">
.infinite-loading(ref="infiniteLoading")
  slot(v-if="loading" name="loading")
    .infinite-loading__spinner
      UiSpinner
  slot(v-if="error" name="error")
    .infinite-loading__error
      .infinite-loading__error-message {{ $t('errors.something_went_wrong') }}
      UiButton(
        icon="RefreshCcw"
        variant="smooth-secondary"
        size="sm"
        @click="$emit('retry')"
      ) {{ $t('actions.retry') }}
</template>

<script>
export default {
  name: 'InfiniteLoading',

  emits: ['load', 'retry'],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      observer: null,
      isVisible: false,
      loadingTimeout: null,
    };
  },

  watch: {
    loading(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleLoad();
      }
    },
  },

  created() {
    const options = {
      rootMargin: '0px',
      threshold: 1.0,
    };

    const callback = (entries) => {
      const entry = entries[0];
      this.isVisible = entry.isIntersecting;
      this.handleLoad();
    };

    this.observer = new IntersectionObserver(callback, options);
  },

  mounted() {
    this.observer.observe(this.$refs.infiniteLoading);
  },

  methods: {
    handleLoad() {
      if (this.loading || this.error || this.disabled || !this.isVisible) {
        return;
      }
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = setTimeout(() => {
        this.$nextTick(() => {
          this.$emit('load');
        });
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite-loading {
  display: flex;
  align-items: center;
  justify-content: center;

  &__spinner {
    padding: 10px;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  &__error-message {
    margin-bottom: 8px;
  }
}
</style>
