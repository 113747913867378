import axios from 'axios';

const SESSION_CHECK_URL = '/users/session_check';

const fetchInit = () => {
  return axios.get('/api/v1/init.json');
};

const userLogin = (email, password) => {
  return axios.post('/users/sign_in', {
    user: { email, password },
  });
};

const loginAsOtherUser = (userId) => {
  return axios.post('/users/sign_in_as_user', {
    user_id: userId,
  });
};

const logoutAsOtherUser = () => {
  return axios.get('/users/sign_out_as_user');
};

const resetPassword = (email) => {
  return axios.post('/users/password', { user: { email } });
};

const updatePassword = (params) => {
  return axios.put('/users/password', params);
};

const invitedUser = (invitation_token) => {
  return axios.get('/users/invitation', { params: { invitation_token } });
};

const registration = (params) => {
  return axios.put('/users/invitation', { user: params });
};

const removeInvitation = (invitation_token) => {
  return axios.delete('/users/invitation', { params: { invitation_token } });
};

const updateUser = (params) => {
  return axios.put('/users', { user: params });
};

const setDefaultReport = (report_id) => {
  return axios.patch(`/api/v1/power_bi_reports/${report_id}/set_default`);
};

const getAccessReports = (user_id, params = {}) =>
  axios.get(`/api/v1/power_bi_reports/${user_id}/user_accessed_reports`, { params });

const sendPingSession = () => {
  return axios.get(SESSION_CHECK_URL);
};

const ApiUser = {
  fetchInit,
  userLogin,
  resetPassword,
  updatePassword,
  invitedUser,
  registration,
  removeInvitation,
  updateUser,
  setDefaultReport,
  loginAsOtherUser,
  logoutAsOtherUser,
  getAccessReports,
  sendPingSession,
};

export default ApiUser;
