<template lang="pug">
.domain-dns-record-field
  CopyField(:value="value" data-test-id="domain-dns-copy")
  DomainStatusBadge(:status="statusWithAlternative" data-test-id="domain-dns-status")
</template>

<script>
import CopyField from '@/components/CopyField.vue';
import DomainStatusBadge from './DomainStatusBadge.vue';

const AlternativeStatusKeys = {
  failed: 'not_found',
};

export default {
  components: {
    CopyField,
    DomainStatusBadge,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    status: {
      type: String,
      default: 'failed',
    },
  },
  computed: {
    statusWithAlternative() {
      return AlternativeStatusKeys[this.status] ?? this.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.domain-dns-record-field {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 10px;
  background: var(--bb-gray-900);
  border-radius: 0.35em;

  & :deep(.copy-field) {
    flex-shrink: 1;
    flex-grow: 1;
    padding: 14px 20px;
  }
}
</style>
