<template lang="pug">
.notification
  UiButton(
    :icon="this.hasUnreadNotifications ? 'BellNotify' : 'Bell'"
    variant="ghost"
    border="smooth"
    size="lg"
    round
    @click="toggleSidebar"
  )
.notification__container(v-if="sidebarOpen")
  .notification__header
    p Notification feed
    UiIcon.pointer(
      name="Close"
      size="20"
      @click="close"
    )
  .notification__body
    template(v-if="notifications")
      .notification__card(
        v-for="item in notifications"
        :key="`${item.id}`"
        @click="viewNotification(item)"
      )
        img.notification__card__img(:src="icons.info")
        .notification__card__content
          p.notification__card__title {{ item.data.title }}
          p.notification__card__subtitle {{ item.data.subtitle }}
  .notification__footer(v-if="canAllLoad")
    UiButton(width="100%" @click="fetchAllNotifications") {{ $t('actions.loadAll') }}
NotificationDetailsModal(ref="notificationDetailsModal" @delete="hideNotification")
//- ConfirmModal(
//-   v-if="showConfirm"
//-   :show="showConfirm"
//-   :text="confirmText"
//-   :confirm="confirmAction"
//-   :close="closeConfirmModal"
//-   :buttons="confirmModalButtons"
//- )
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '@/modals/common/Confirm.vue';
import info from '@/assets/images/notification/info.svg';
import Notifications from '@/api/models/Notifications.js';
import NotificationDetailsModal from '@/modals/notifications/notification-details-modal.vue';

export default {
  components: {
    ConfirmModal,
    NotificationDetailsModal,
  },
  data() {
    return {
      notifications: [],
      hasNotifications: false,
      hasUnreadNotifications: false,
      canAllLoad: false,
      totalCount: 0,
      sidebarOpen: false,
      showConfirm: false,
      confirmText: '',
      confirmAction: null,
    };
  },

  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters('company', ['currentCompanyId', 'currentCompany']),

    icons() {
      return {
        info,
      };
    },

    confirmModalButtons() {
      return {
        confirmText: this.$t('notifications.dismiss'),
        confirmColor: '#00b07d',
        cancelText: this.$t('notifications.dont_dismiss'),
      };
    },
  },

  watch: {
    currentCompanyId(_val) {
      this.setHasNotifications();
      if (!this.sidebarOpen) return;
      this.fetchNotifications();
    },
  },

  mounted() {
    this.connectToNotificationCable();
    this.setHasNotifications();
  },

  channels: {
    NotificationChannel: {
      connected() {},
      received(data) {
        this.receivedNotification(data);
      },
      disconnected() {},
    },
  },

  methods: {
    toggleSidebar(openStatus) {
      if (this.sidebarOpen) {
        this.close();
      } else {
        this.sidebarOpen = openStatus;
        if (openStatus) {
          this.fetchNotifications();
        } else {
          this.notifications = [];
        }
      }
    },
    fetchNotifications(params = {}) {
      const company_id = this.currentCompanyId;
      new Notifications().fetchAll({ ...params, company_id }).then((resp) => {
        this.notifications = resp.data.notifications;
        const totalCount = resp.data.total_count;
        this.canAllLoad = totalCount > 5 && this.notifications.length !== totalCount;
      });
    },
    fetchAllNotifications() {
      return this.fetchNotifications({ all: true, company_id: this.currentCompanyId });
    },
    viewNotification(item) {
      if (!item.unread) {
        this.notificationDialog(item);
        return;
      }

      new Notifications(null, { id: item.id })
        .read()
        .then((resp) => {
          const { success, company_ids, counts } = resp.data;
          if (!success) return;
          this.storeCounts({ company_ids, counts });
          item.unread = false;
          this.notificationDialog(item);
        })
        .catch((err) => {
          console.log('readNotification error!', err);
        });
    },
    notificationDialog(item) {
      this.$refs.notificationDetailsModal.open({ item });
    },
    hideNotification(item) {
      new Notifications(null, { id: item.id })
        .hide()
        .then((resp) => {
          const { success, company_ids, counts } = resp.data;
          if (!success) return;
          this.storeCounts({ company_ids, counts });
          this.notifications = this.notifications.filter(
            (notification) => notification.id !== item.id,
          );
          this.closeConfirmModal();
        })
        .catch((_err) => {
          this.closeConfirmModal();
        });
    },
    setHasNotifications() {
      if (!this.currentCompany) return;
      this.hasNotifications = this.currentCompany.notifications_count > 0;
      this.hasUnreadNotifications = this.currentCompany.unread_notifications_count > 0;
    },
    storeCounts(data) {
      this.$store.dispatch('company/updateNotificationsCounts', data);
      this.setHasNotifications();
    },
    connectToNotificationCable() {
      if (!this.isAuthenticated || !this.$cable) return;
      this.$cable.subscribe({ channel: 'NotificationChannel' });
    },
    receivedNotification(data) {
      const { item, company_ids, counts } = data;
      this.storeCounts({ company_ids, counts });
      if (
        (item.company_id && item.company_id !== this.currentCompanyId) ||
        !this.sidebarOpen ||
        this.notifications.filter((notification) => notification.id === item.id).length > 0
      ) {
        return;
      }
      this.notifications.unshift(item);
    },

    closeConfirmModal() {
      this.confirmText = null;
      this.showConfirm = false;
      this.confirmAction = null;
      this.showConfirm = false;
    },

    close() {
      this.notifications = [];
      this.hasNotifications = false;
      this.hasUnreadNotifications = false;
      this.canAllLoad = false;
      this.totalCount = 0;
      this.sidebarOpen = false;
      this.showConfirm = false;
      this.confirmText = '';
      this.confirmAction = null;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.notification {
  &__container {
    position: fixed;
    bottom: 0;
    top: 90px;
    right: 0;
    background-color: var(--bb-app-bg-main);
    width: 396px;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
      0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
    padding: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px 0 20px;
    margin-bottom: 25px;

    & p {
      font-weight: 700;
      font-size: 18px;
      margin: 0;
    }
  }

  &__body {
    overflow: auto;
    max-height: 86%;
  }

  &__card {
    display: flex;
    box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
      0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
    border-radius: 5px;
    padding: 15px;
    padding-left: 20px;

    & img {
      margin-right: 10px;
    }

    & p {
      margin: 0;
      font-size: 16px;
    }

    &__title {
      font-weight: 700;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
  }
}
</style>
