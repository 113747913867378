<template lang="pug">
.step-container.step-container--tall
  .step-header
    .step-title {{ $t('onboarding.can_not_find_user_or_company.title') }}
    .step-description {{ $t('onboarding.can_not_find_user_or_company.description') }}
  Stack(justify="center")
    StackItem
      UiButton(data-test-id="button-to-dashboard" @click="toHome") {{ $t('onboarding.can_not_find_user_or_company.action_to_dashboard') }}
</template>

<script>
import LocalStorage from '@/common/localstorage.js';

export default {
  methods: {
    toHome() {
      this.$router.replace('/');
    },
    mounted() {
      LocalStorage.remove('integration_attempt');
    },
  },
};
</script>
