<template lang="pug">
.data-table-row-actions(:class="classes" @click.stop)
  .data-table-row-actions__action(v-for="action in actions")
    UiTooltip(:title="action.label" variant="dark")
      template(#activator)
        UiButton(
          v-bind="action"
          :variant="action.variant ? `action-${action.variant}` : 'action-primary'"
          size="sm"
          border="smooth"
          round
          :data-vi="`action-${action.label}`"
        )
  .data-table-row-actions__action.data-table-row-actions__action--more(
    v-if="moreActions.length || alwaysShowMoreActions"
  )
    UiPopover(
      :show="!moreActions.length ? false : undefined"
      placement="left-start"
      :offset="[-6, 6]"
      closeOnToggle
    )
      template(#activator="{ show }")
        UiButton(
          variant="action-primary"
          :active="show"
          :disabled="!moreActions.length"
          icon="Dotted"
          size="sm"
          ref="activator"
          border="smooth"
          round
        )
      UiOptionsMenu
        UiOptionsMenuItem(
          v-for="action in moreActions"
          v-bind="action"
          :variant="action.variant ? `contrast-${action.variant}` : undefined"
          @click="handleMoreActionClick(action)"
        ) {{ action.label }}
</template>

<script>
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';

export default {
  name: 'DataTableRowActions',
  components: {
    MiniDropdown,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    moreActions: {
      type: Array,
      default: () => [],
    },
    alwaysShowMoreActions: {
      type: Boolean,
      default: false,
    },
    showOnHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'data-table-row-actions--hidden': this.showOnHover,
      };
    },
  },
  methods: {
    handleMoreActionClick(action) {
      if (action.to) {
        this.$router.push(action.to);
      }
    },
  },
};
</script>

<style lang="scss">
.data-table-row-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  &--hidden &__action:not(#{&}__action--more) {
    opacity: 0;
  }

  .data-table__row-main:hover &--hidden &__action,
  .ui-table__row:hover &--hidden &__action {
    opacity: 1;
  }
}

@mixin button-action-variant($variant: primary, $color: #b7b8b9) {
  &--action-primary {
    background: transparent;
    border-color: transparent;
    color: var(--bb-gray-700);

    .data-table__row-main:hover & {
      color: var(--bb-primary);
    }

    &:hover {
      background-color: rgba(var(--bb-primary-rgb), 0.2);
      color: var(--bb-primary);
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &--active#{&}--action-primary {
    color: var(--bb-primary);
    background-color: rgba(var(--bb-primary-rgb), 0.2);
  }

  &--action-danger {
    background: transparent;
    border-color: transparent;
    color: var(--bb-gray-700);

    .data-table__row-main:hover & {
      color: var(--bb-danger);
    }

    &:hover {
      background-color: rgba(var(--bb-danger-rgb), 0.2);
      color: var(--bb-danger);
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &--active#{&}--action-danger {
    color: var(--bb-danger);
    background-color: rgba(var(--bb-danger-rgb), 0.2);
  }
}

.ui-button {
  @include button-action-variant();
}
</style>
