<template lang="pug">
UiBadge(
  v-if="status"
  size="sm"
  :variant="status.variant"
) {{ status.label }}
</template>

<script>
import CompanyFile from '@/models/company-file.js';

export default {
  props: {
    entity: {
      type: CompanyFile,
      required: true,
    },
  },

  computed: {
    status() {
      if (!this.entity.data.validated) {
        return {
          variant: 'warning',
          label: this.$t('files.not_validated'),
        };
      }

      return null;
    },
  },
};
</script>
