// Usage:
// import restVuex from "./rest";
// import uploadsApi from "../api/uploads"; # REST Api
// export const store = new Vuex.Store({
//  modules: {
//    uploads: new restVuex(uploadsApi, "UPLOADS", {}).getStore()
//  }
// });

class RestVuex {
  constructor(restClient, pluralKey, config = {}) {
    this._api = restClient;
    this._entity = config.model || null;
    // this._singular = sungularKey;
    this._plural = pluralKey;

    this.state = {
      items: [],
      meta: {
        total_count: 0,
      },
      inProgress: false,
    };
  }

  _fetchList({ commit }, config) {
    return new Promise((resolve, reject) => {
      commit('SET_PROGRESS', true);
      this._api
        .fetchAll(config.params, config)
        .then((resp) => {
          commit('SET_LIST', resp.data[this._plural]);
          commit('SET_META', resp.data.meta);
          commit('SET_PROGRESS', false);
          resolve(resp);
        })
        .catch((e) => {
          commit('SET_PROGRESS', false);
          reject(e);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _SET_LIST(state, items = []) {
    state.items = items;
  }

  // eslint-disable-next-line class-methods-use-this
  _SET_META(state, meta = {}) {
    state.meta = meta;
  }

  // eslint-disable-next-line class-methods-use-this
  _SET_PROGRESS(state, progress = true) {
    state.inProgress = progress;
  }

  createActions() {
    return {
      fetchList: (context, config) => {
        this._fetchList(context, config);
      },
    };
  }

  createMutations() {
    return {
      SET_LIST: this._SET_LIST,
      SET_META: this._SET_META,
      SET_PROGRESS: this._SET_PROGRESS,
    };
  }

  createGetters() {
    const Model = this._entity;
    return {
      items: (state) => (!Model ? state.items : state.items.map((i) => new Model(i))),
      inProgress: (state) => state.inProgress,
      meta: (state) => state.meta,
    };
  }

  getStore() {
    return {
      namespaced: true,
      state: this.state,
      actions: this.createActions(),
      mutations: this.createMutations(),
      getters: this.createGetters(),
    };
  }
}

export default RestVuex;
