<template lang="pug">
UiModal(
  :title="modalTitle"
  :show="show"
  :rightActions="rightActions"
  @close="close"
)
  UiStack(vertical spacing="lg")
    UiAlert(
      v-if="errorDescription"
      variant="error"
      :closable="false"
    ) {{ errorDescription }}
    UiAlert(
      v-if="uploadDescription"
      variant="success"
      :closable="false"
    )
      template(#icon)
        UiSpinner(v-if="inProgress")
      | {{ uploadDescription }}
    UiStackItem(v-if="mode === Modes.REPLACE") {{ $t('files.replace_file_desciption', { filename: fileOptions.filename }) }}
    UiStackItem(v-if="mode !== Modes.EDIT")
      UiFilePicker(
        v-model="files"
        :multiple="true"
        :maxCount="mode === Modes.REPLACE ? 1 : 20"
        :vuelidateModel="v$.files"
        :disabled="loading || inProgress || !uploadAllowed"
        @update:modelValue="onFileUpload"
      )
    UiStackItem(v-if="mode !== Modes.REPLACE")
      UiFormInput(
        v-if="isSingleFile"
        v-model="fileOptions.filename"
        :label="$t('files.filename')"
        :disabled="loading || inProgress || locked"
        :vuelidateModel="v$.fileOptions.filename"
      )
      UiFormMultiselect(
        v-model="folderId"
        :options="companyFolders"
        :label="$t('files.folder')"
        :disabled="loading || inProgress"
        searchable
        labelKey="text"
      )
      UiFormInput(
        v-if="isSingleFile"
        v-model="fileOptions.description"
        :label="$t('files.description')"
        :disabled="loading || inProgress"
      )

  //- template(#footer)
  //-   template(v-if="inProgressUpload")
  //-     UiButton(@click="close") {{ $t('files.close_notification') }}
</template>

<script>
import CompanyFiles from '@/api/models/CompanyFiles.js';
import Folders from '@/api/models/Folders.js';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@/common/validators.js';
import { mapGetters } from 'vuex';

const Modes = {
  NEW: 'new',
  EDIT: 'edit',
  REPLACE: 'replace',
};

export default {
  emits: ['done'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      loading: false,
      show: false,
      inProgress: false,
      inProgressUpload: false,
      files: [],
      folders: [],
      folderId: null,
      sequential_id: null,
      locked: false,
      replaceAllowed: false,
      uploadDescription: null,
      currentFolderId: null,
      rootFolderId: null,
      uploadTimeout: null,
      fileOptions: {
        filename: null,
        description: null,
      },
      mode: Modes.NEW,
      errorDescription: null,
    };
  },

  validations() {
    return {
      fileOptions: {
        filename: { required },
      },
      files: { fileRequired: requiredIf(this.mode !== Modes.EDIT) },
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),

    Modes() {
      return Modes;
    },

    modalTitle() {
      switch (this.mode) {
        case Modes.EDIT:
          return this.$t('files.edit_file');
        case Modes.REPLACE:
          return this.$t('files.replace_file');
        case Modes.NEW:
        default:
          return this.$t('files.upload_file');
      }
    },

    companyFolders() {
      return this.folders.map((r) => ({ text: r.name, value: r.id }));
    },

    uploadAllowed() {
      if (this.mode === Modes.REPLACE) {
        return !this.locked && this.replaceAllowed;
      }
      return true;
    },

    isSingleFile() {
      return this.files.length <= 1;
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          loading: this.inProgress,
          disabled: this.loading || this.inProgress || this.isViewAsOtherUser,
          onClick: this.submit,
        },
      ];
    },
  },

  methods: {
    open(file, mode = 'new', folderId, rootFolderId) {
      this.mode = mode;
      this.fetchFolders();
      this.uploadDescription = null;
      this.errorDescription = null;
      this.currentFolderId = folderId;
      this.rootFolderId = rootFolderId;

      if (file) {
        this.sequential_id = file.sequential_id;
        this.fileOptions.filename = file.name;
        this.fileOptions.description = file.data.description ?? '';
        this.folderId = file.parent_id;
        this.replaceAllowed = file.extras.replace_allowed;
        this.locked = file.extras.locked;
      } else {
        this.sequential_id = null;
        this.folderId = null;
      }
      this.v$.$reset();
      this.show = true;
    },

    close() {
      this.inProgressUpload = false;
      this.show = false;
      this.files = [];
      this.uploadDescription = null;
      this.errorDescription = null;
      this.fileOptions = {
        filename: null,
        description: null,
      };
    },

    onFileUpload(files) {
      if (!this.fileOptions.filename) {
        this.fileOptions.filename = files[0].name;
      }
    },

    startUpload() {
      // this.inProgressUpload = true;
      this.uploadDescription = this.$t('files.upload_progress_start_text');
      this.uploadTimeout = setTimeout(() => {
        this.uploadDescription = this.$t('files.upload_progress_longer_text');
      }, 20000);
    },

    clearUploadTimeout() {
      clearTimeout(this.uploadTimeout);
      this.uploadTimeout = null;
    },

    async submit() {
      this.errorDescription = null;

      const valid = await this.v$.$validate();
      if (!valid) return;

      this.inProgress = true;

      this.startUpload();

      const formData = new FormData();

      if (this.files.length) {
        if (this.isSingleFile) {
          formData.append('company_file[content]', this.files[0]);
        } else {
          for (let i = 0; i < this.files.length; i++) {
            formData.append('company_file[contents][]', this.files[i]);
          }
        }
      }

      if (this.isSingleFile && this.fileOptions.filename) {
        formData.append('company_file[filename]', this.fileOptions.filename);
      }

      formData.append('company_file[description]', this.fileOptions.description ?? '');

      if (this.folderId) {
        formData.append('company_file[folder_id]', this.folderId);
      } else {
        formData.append('company_file[folder_id]', this.rootFolderId);
      }

      try {
        if (this.mode === Modes.NEW) {
          await new CompanyFiles().create(formData);
        } else {
          await new CompanyFiles(null, { id: this.sequential_id }).update(formData);
        }
        this.$emit('done');
        this.close();
      } catch (error) {
        this.uploadDescription = null;

        let message;
        switch (error.response.status) {
          case 413:
            message = this.$t('errors.uploaded_file_is_too_large');
            break;
          default:
            message = this.$localizeErrorMessage(error);
            break;
        }
        this.$toaster.add({
          type: 'error',
          message,
          timeout: 7000,
        });
        if (error.response.data.error) {
          this.errorDescription = error.response.data.error;
        }
      } finally {
        this.inProgress = false;
        this.clearUploadTimeout();
      }
    },

    fetchFolders() {
      this.loading = true;

      new Folders()
        .fetchAll({ page: 1, per_page: 10000 })
        .then((resp) => {
          const foldersList = resp.data.folders.filter(
            (folder) => folder.is_editable && !folder['locked?'],
          );
          this.folders = foldersList;
          const currentFolder = foldersList.find((f) => f.id === this.currentFolderId);
          if (currentFolder) {
            this.folderId = currentFolder.id;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
