<template lang="pug">
.admin-layout
  Heading(:title="$t('server_settings.title')")
  UiTabs(
    v-if="tabs.length > 1"
    :tabs="tabs"
    :selected="activeTab"
    @select="selectTab"
  )
  UiCard
    component(:is="tabContent")
</template>

<script>
import BannersTab from './tabs/banners-tab.vue';
import ContactsTab from './tabs/contacts-tab.vue';

export default {
  name: 'ServerSettingsView',
  computed: {
    activeTab() {
      return this.$route?.query?.tab || this.tabs[0].id;
    },
    tabs() {
      return [
        {
          id: 'banners',
          label: this.$t('server_settings.tabs.banners'),
          content: BannersTab,
        },
        {
          id: 'contacts',
          label: this.$t('server_settings.tabs.support_contacts'),
          content: ContactsTab,
        },
      ];
    },
    tabContent() {
      const tab = this.tabs.find((item) => item.id === this.activeTab);
      return tab?.content;
    },
  },
  methods: {
    selectTab(tabId) {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: tabId },
      });
    },
  },
};
</script>
