<template lang="pug">
.dataroom-forms
  .dataroom-forms__header
    h2 {{ $t('menu.forms') }}
    //- UiButton.dataroom-forms__header__add-new(
    //-   v-if="currentCompany.can_create_dataroom"
    //-   :to="{ name: 'DataroomNew' }"
    //-   icon="Form"
    //-   :disabled="isViewAsOtherUser"
    //- ) {{ $t('dataroom.add_form') }}
  .dataroom-forms__search
    UiInput(
      v-model="search"
      :placeholder="$t('dataroom.search_form')"
      icon="Search"
      @update:modelValue="fetchData"
    )
  .dataroom-forms__container
    DataroomCardEntry(
      v-for="form in formsList"
      :key="form.id"
      :form="form"
    )
</template>
<script>
import { mapGetters } from 'vuex';
import Dataroom from '@/api/models/Dataroom.js';
import DataroomCardEntry from './components/dataroom-card-entry.vue';

export default {
  name: 'ViewDataroom',
  components: { DataroomCardEntry },
  data() {
    return {
      formsList: [],
      search: '',
      AbortController: null,
    };
  },

  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters(['isViewAsOtherUser']),
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        const response = await new Dataroom().fetchAll(
          { per_page: 100, search: this.search },
          {
            signal: this.abortController.signal,
          },
        );
        this.formsList = response.data.dataroom_forms.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        if (error.response && error.message !== 'canceled') {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        }
      }
    },
  },
};
</script>
