import {
  helpers,
  createI18nMessage,
  required as requiredRule,
  requiredIf as requiredIfRule,
  email as emailRule,
  url as urlRule,
  ipAddress as ipAddressRule,
  minLength as minLengthRule,
} from '@vuelidate/validators';
import { i18n } from '@/localization/index.js';
import { PhoneNumberUtil } from 'google-libphonenumber';

const isNullOrUndefinedOrEmpty = (value) => {
  return value === null || value === undefined || value === '';
};

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const POWER_BI_GROUP_URL_REGEXP =
  /^https:\/\/app\.powerbi\.com\/groups\/((\w{4,12}-){4})(\w{4,12})$/; // after groups should be uuid
const SUBDOMAIN_REGEXP =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))$/;
const DOMAIN_REGEXP =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;

export const required = withI18nMessage(requiredRule);
export const email = withI18nMessage(emailRule);
export const requiredIf = withI18nMessage(requiredIfRule, { withArguments: true });
export const url = withI18nMessage(urlRule, { withArguments: true });
export const ipAddress = withI18nMessage(ipAddressRule, { withArguments: true });
export const minLength = withI18nMessage(minLengthRule, { withArguments: true });

export const isInList = (list) => {
  const $validator = (value) => {
    if (Array.isArray(value)) {
      return value.every((item) => list.includes(item) || isNullOrUndefinedOrEmpty(item));
    }
    return list.includes(value) || isNullOrUndefinedOrEmpty(value);
  };
  return withI18nMessage(
    {
      $validator,
      $params: { list, type: 'isInList' },
    },
    {
      withArguments: true,
    },
  );
};

export const powerBIGroupUrl = withI18nMessage((value) =>
  !isNullOrUndefinedOrEmpty(value) ? value.match(POWER_BI_GROUP_URL_REGEXP) : true,
);

export const strictLettersAndSpaces = withI18nMessage((value) => !value.match(/[^\p{L} ]/u));

export const subdomain = withI18nMessage((value) => value.match(SUBDOMAIN_REGEXP));

export const domain = withI18nMessage((value) => value.match(DOMAIN_REGEXP));

export const fileRequired = helpers.withMessage(
  i18n.global.t('validations.file_required'),
  (file) => (Array.isArray(file) ? file.length : !!file),
);

export const checked = withI18nMessage((value) => value === true);

export const isValidPhoneNumber = (countryCode) =>
  helpers.withMessage(
    i18n.global.t('validations.must_be_valid_phone_number'),
    helpers.withParams({ type: 'countryCode', value: countryCode }, (value) => {
      if (!value) {
        return true;
      }
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryCode), countryCode);
    }),
  );

export function getExternalErrorMessages(obj, path = '') {
  const result = [];

  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        result.push({ $propertyPath: path ? `${path}.${key}` : key, $message: obj[key][i] });
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      const subResult = getExternalErrorMessages(obj[key], path ? `${path}.${key}` : key);
      result.push(...subResult);
    }
  }
  return result;
}

export const firstAndLastNameRequired = withI18nMessage((value) => {
  const words = value.trim().split(' ');

  // Check if there are at least two words
  return words.length >= 2;
});
