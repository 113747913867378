<template lang="pug">
UiMultiselect(
  v-bind="$attrs"
  :options="optionsProp"
  value-prop="id"
  track-by="id"
  label="name"
  :searchable="true"
  :filter-results="false"
  :delay="300"
  :placeholder="$t('placeholders.select_folder')"
)
</template>

<script>
import Folders from '@/api/models/Folders.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ReportGroupsSelect',
  inheritAttrs: true,
  props: {
    options: {
      type: [Array, Object, Function],
      default: null,
    },
    filterByChildrenTypes: {
      type: [Array, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters('company', ['isAdmin']),

    optionsProp() {
      return this.options || this.search;
    },
  },
  methods: {
    async search(search = '') {
      try {
        const response = await new Folders().fetchAll({
          search,
          page: 1,
          per_page: 10,
          filter_by_children_types: this.filterByChildrenTypes || undefined,
        });
        return response.data.folders;
      } catch {
        return [];
      }
    },
  },
};
</script>
