<template lang="pug">
.admin-layout
  Heading(:title="$t('integration_activity.title')")
  .table-container
    RestTable(
      ref="table"
      :headers="headers"
      :fetchHandler="fetch"
      :searchLabel="$t('integration_activity.search')"
      searchable
      namespace="integration_activity_list"
    )
      template(v-slot:cel_company_name="{ item }")
        span {{ item.company_name }}
      template(v-slot:cel_business_id="{ item }")
        span {{ item.company_business_id }}
      template(v-slot:cel_integration_name="{ item }")
        span {{ integrationName(item.integration_name) }}
      template(v-slot:cel_who_added="{ item }")
        UserCard(
          v-if="item.user_avatar || item.user_name"
          :avatar="item?.user_avatar"
          :name="item?.user_name"
        )
      template(v-slot:cel_email="{ item }")
        span {{ item.user_email }}
      template(v-slot:cel_created_at="{ item }")
        span {{ handleFormatDate(item.created_at) }}
      template(v-slot:cel_activated="{ item }")
        IntegrationStatusBadge(:status="handleStatusBadge(item.activated)")
</template>

<script>
import UserCard from '@/components/UserCard.vue';
import { getIntegrationName } from '@/views/Onboarding/helpers.js';
import CompaniesIntegrations from '@/api/models/companies-integrations.js';
import IntegrationStatusBadge from './integration-status-badge.vue';

export default {
  components: {
    IntegrationStatusBadge,
    UserCard,
  },
  computed: {
    headers() {
      return [
        {
          key: 'company_name',
          label: this.$t('integration_activity.headers.company_name'),
          sortable: true,
        },
        {
          key: 'business_id',
          label: this.$t('integration_activity.headers.business_id'),
        },
        {
          key: 'integration_name',
          label: this.$t('integration_activity.headers.integration_name'),
        },
        {
          key: 'who_added',
          label: this.$t('integration_activity.headers.who_added'),
        },
        {
          key: 'email',
          label: this.$t('integration_activity.headers.email'),
        },
        {
          key: 'created_at',
          label: this.$t('integration_activity.headers.date'),
          sortable: true,
        },
        {
          key: 'activated',
          label: this.$t('integration_activity.headers.status'),
          sortable: true,
        },
      ];
    },
  },

  methods: {
    async fetch(params) {
      try {
        const response = await new CompaniesIntegrations({ adminApi: true }).fetchAll(params);
        return {
          items: response.data.companies_integrations,
          meta: response.data.meta,
        };
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
          timeout: 7000,
        });
        return {};
      }
    },
    integrationName(software) {
      return getIntegrationName(software) ?? software;
    },
    handleStatusBadge(activated) {
      return activated ? 'activated' : 'not_activated';
    },
    handleFormatDate(date) {
      return date ? this.$d(date, 'numeric') : null;
    },
  },
};
</script>

<style lang="scss"></style>
