<template lang="pug">
.report-pages-picker
  PowerbiClient.report-pages-picker__powerbi-client(
    v-if="report"
    ref="report"
    :id="report.report_id"
    :embed-url="report.embed_url"
    :access-token="report.access_token"
    :expiration="report.expiration"
    view-mode="view"
    @getPages="onGetPages"
  )
  UiFormMultiselect(
    v-bind="$attrs"
    :key="pagesOptions.length"
    :object="false"
    :label="$t(isMultiplePages ? 'company.reports.pages' : 'company.reports.page')"
    labelKey="label"
    valueProp="value"
    :options="pagesOptions"
    :groups="isMultipleReports"
    :loading="reportsQueueInProgress"
    :disabled="$attrs.disabled || reportsQueueInProgress"
    :hideSelected="false"
    :mode="$attrs.mode ?? 'tags'"
  )
</template>

<script>
import Analytics from '@/api/models/Analytics.js';

export default {
  emits: ['loading', 'loaded', 'error'],

  props: {
    reportId: {
      type: [String, Array],
      default: null,
    },
    allowedPages: {
      type: [Array],
      default: null,
    },
  },

  data() {
    return {
      reportsQueueInProgress: false,
      reportsQueue: [],
      fetchedReports: {},
      fetchedReportsError: {},
      reportPages: {},
      report: null,
    };
  },

  computed: {
    isMultipleReports() {
      return Array.isArray(this.reportId);
    },

    pagesOptions() {
      let pages = [];
      if (this.isMultipleReports) {
        return this.reportId.reduce((acc, reportId) => {
          const report = Object.values(this.fetchedReports).find((r) => r?.id === reportId);
          if (report && this.reportPages[reportId]) {
            let groupPages = this.reportPages[reportId].map((page) => ({
              label: page.label,
              value: `${reportId}|${page.value}`,
            }));
            if (this.allowedPages) {
              groupPages = groupPages.filter((page) => this.allowedPages.includes(page.value));
            }
            if (groupPages.length) {
              acc.push({
                label: report.name,
                options: groupPages,
              });
            }
          }
          return acc;
        }, []);
      }

      if (!this.reportId) {
        return [];
      }

      pages = this.reportPages[this.reportId] ?? [];
      if (this.allowedPages) {
        pages = pages.filter((page) => this.allowedPages.includes(page.value));
      }

      return pages;
    },
  },

  mounted() {
    this.fetchReports();
  },

  methods: {
    onGetPages(pages) {
      this.reportPages[this.report.id] = pages
        .filter((p) => !p.visibility)
        .map((p) => ({
          label: p.displayName,
          value: p.name,
        }));

      this.triggerReportQueue();
    },

    fetchReports() {
      if (this.isMultipleReports) {
        this.reportsQueue = [...this.reportId] || [];
      } else if (this.reportId) {
        this.reportsQueue = [this.reportId];
      }
      this.triggerReportQueue();
    },

    triggerReportQueue() {
      this.report = null;
      if (!this.reportsQueueInProgress) {
        this.$emit('loading');
        this.reportsQueueInProgress = true;
      }
      if (!this.reportsQueue.length) {
        this.$emit('loaded');
        this.reportsQueueInProgress = false;
        return;
      }
      this.fetchReport(this.reportsQueue[0]);
    },

    fetchReport(reportId) {
      this.report = null;
      // this.setReportValidStatus(true);

      if (this.fetchedReports[reportId]) {
        this.reportsQueue.splice(0, 1);
        this.fetchReportsQueue();
        return;
      }

      new Analytics(null, { id: reportId })
        .fetch()
        .then((resp) => {
          const reportData = resp.data.power_bi_report;
          if (reportData.access_token) {
            this.reportsQueue.splice(0, 1);
            this.fetchedReports[reportId] = reportData;
            this.fetchedReportsError[reportId] = false;
            this.report = reportData;
          } else {
            this.fetchedReportsError[reportId] = true;
            this.$emit('error');
            this.reportsQueueInProgress = false;
          }
        })
        .catch(() => {
          this.setReportValidStatus(false);
          this.fetchedReportsError[reportId] = true;
          this.inProgress = false;
          this.reportsQueueInProgress = false;
        });
    },
  },
};
</script>

<style lang="scss">
.report-pages-picker {
  &__powerbi-client {
    display: none;
  }
}
</style>
