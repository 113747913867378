<template lang="pug">
UiCard
  .access-tree
    UiCardSection.access-tree__path-container
      UiBreadcrumbs(
        :items="breadcrumbs"
        :size="mobileView ? 'sm' : 'xl'"
        showBack
      )
    .access-tree__content-layout
      .access-tree__content
        UiCardSection
          UiStack(
            justify="space-between"
            align="end"
            wrap
          )
            UiInput.access-tree__input-search(
              :modelValue="filters.search"
              :placeholder="$t('user_groups.filters.search_entities')"
              inputSize="20"
              icon="Search"
              data-vi="search"
              @update:modelValue="updateFilters({ search: $event })"
            )
            UiStack.column-mobile(align="stretch" justify="stretch")
              UiStack(vertical spacing="xs")
                UiInputLabel &nbsp;
                span.access-tree__filter {{ $t('user_groups.filters.filter_by') }}
                UiIcon.access-tree__filter-icon(name="Filter" size="24")
              UiStackItem.access-tree__filter-column
                UiInputLabel.text-secondary(size="sm") {{ $t('user_groups.filters.label_types') }}
                UiMultiselect.ui-multiselect-tags-scroll(
                  :modelValue="filters.types"
                  :placeholder="$t('user_groups.filters.event_type_input_placeholder')"
                  :options="typesOptionsDynamic"
                  :allow-empty="true"
                  :hide-selected="false"
                  mode="tags"
                  :size="mobileView ? 'sm' : 'md'"
                  data-vi="types"
                  @change="updateFilters({ types: $event })"
                )
              UiStackItem.access-tree__filter-column
                UiInputLabel.text-secondary(size="sm") {{ $t('user_groups.filters.label_date') }}
                UiDatePicker(
                  :modelValue="filters.date"
                  canClear
                  :size="mobileView ? 'sm' : 'md'"
                  data-vi="date"
                  @update:modelValue="updateFilters({ date: $event?.toISOString() })"
                )
        .access-tree__wrapper-table
          UiDataTable(
            :headers="headers"
            :items="entitiesList"
            :loading="loading"
            hover
          )
            template(#cell:type="{ item }")
              EntityTypeIcon(:entity="item")
            template(#cell:name="{ item }")
              strong(v-if="item.id === rootFolderId") {{ item.name }}
            template(#cell:created_at="{ item }")
              | {{ $d(item.created_at) }}
            template(#cell:updated_at="{ item }")
              | {{ $d(item.updated_at) }}
            template(#cell:actions="{ item }")
              UiButton(
                icon="Trash"
                round
                size="sm"
                variant="text-danger"
                @click.stop="deleteEntityAccess(item)"
              )
          InfiniteLoading(
            :disabled="!entities.length || error"
            :loading="loading"
            @load="fetchMoreEntities"
          )
          UiCardSection(v-if="error" padding="2xl")
            UiEmptyState(
              style="min-height: 16rem"
              icon="AlertTriangle"
              :title="$t('user_groups.access_tree.title_can_not_load')"
              :description="error"
            )
              template(#actions)
                UiButton(
                  icon="RefreshCw"
                  variant="smooth-danger"
                  size="sm"
                  @click="fetchTree"
                ) {{ $t('actions.retry') }}
          UiCardSection(v-else-if="!loading && !this.entitiesList.length" padding="2xl")
            UiEmptyState(
              style="min-height: 16rem"
              icon="Account"
              :title="$t('user_groups.access_tree.title_empty')"
              :description="$t('user_groups.access_tree.description_group_dont_provide_access_to_entity')"
            )
          UiCardSection(v-else-if="!loading && !this.entitiesList.length" padding="2xl")
            UiEmptyState(
              style="min-height: 16rem"
              icon="Folder"
              :title="$t('user_groups.access_tree.title_folder_is_empty')"
              :description="$t('user_groups.access_tree.description_group_dont_provide_access_to_entity_in_folder')"
            )
          UiCardSection(v-else)
  RemoveEntityAccessConfirmModal(ref="removeEntityAccessConfirmModal" @done="resetAndRefetch()")
</template>

<script>
import AccessTreeEntities from '@/api/models/AccessTreeEntities.js';
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import RemoveEntityAccessConfirmModal from '@/modals/UserGroups/remove-entity-access-confirm-modal.vue';
import { mapGetters } from 'vuex';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';
import EntityTypeKey from '@/helper/entity-type-key';

const getFilters = (filters = {}) => ({
  search: filters.search || '',
  types: filters.types || [],
  date: filters.date ? new Date(filters.date) : null,
});

export default {
  components: {
    InfiniteLoading,
    RemoveEntityAccessConfirmModal,
  },
  data() {
    return {
      loading: true,
      error: null,
      entities: [],
      folders: [],
      currentPage: 1,
      meta: null,
      abortController: null,
      accessibleTypes: [],
      filters: getFilters(),
    };
  },
  computed: {
    ...mapGetters(['isAdminDomain', 'mobileView']),
    ...mapGetters('company', ['currentCompany', 'company']),
    headers() {
      return [
        {
          key: 'type',
          label: this.$t('user_groups.headers.label_type'),
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('user_groups.headers.label_name'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('user_groups.headers.label_created'),
          sortable: true,
        },
        {
          key: 'updated_at',
          label: this.$t('user_groups.headers.label_last_update'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          squeeze: true,
        },
      ];
    },
    rootFolderId() {
      return this.isAdminDomain ? this.company.root_folder_id : this.currentCompany.root_folder_id;
    },
    typesOptionsDynamic() {
      const options = ['Folder', ...this.accessibleTypes];
      return options.map((key) => ({
        value: key,
        label: this.$t(`user_groups.entity_name.${EntityTypeKey[key] ?? key}`),
      }));
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('user_groups.user_group_view.user_group'),
          to: {
            name: this.isAdminDomain ? 'AdminUserGroupsShow' : 'UserGroupsShow',
            params: {
              user_group_id: this.$route.params.user_group_id,
            },
          },
        },
        {
          label: this.$t('user_groups.group_access'),
        },
      ];
    },
    entitiesList() {
      return this.entities;
    },
  },

  watch: {
    $route() {
      this.resetAndRefetch();
    },
  },

  mounted() {
    this.fetchTree();
  },

  methods: {
    async fetchTree() {
      try {
        await this.fetchEntities();
      } catch (error) {
        this.error = this.$localizeErrorMessage(error);
      }
    },

    async updateFilters(updatedFilters = {}) {
      const filters = JSON.stringify({
        ...JSON.parse(this.$route.query?.filters || '{}'),
        ...updatedFilters,
      });

      await this.$router.replace({
        query: { ...this.$route.query, filters },
        params: this.$route.params,
      });
    },

    deleteEntityAccess(entity) {
      this.$refs.removeEntityAccessConfirmModal.open({
        entity,
        userGroupId: this.$route.params.user_group_id,
      });
    },

    async fetchEntities() {
      try {
        const { query } = this.$route;
        if (query.filters) {
          const filters = JSON.parse(query.filters);
          this.filters = getFilters(filters);
        }
        if (this.abortController) {
          this.abortController.abort();
        }
        this.abortController = new AbortController();

        this.loading = true;
        this.error = null;
        const params = {
          ...this.filters,
          page: this.currentPage,
          start_date: this.filters.date && startOfDay(this.filters.date),
          end_date: this.filters.date && endOfDay(this.filters.date),
        };
        delete params.date;

        const response = await new AccessTreeEntities({
          adminApi: this.isAdminDomain,
          owner: new UserGroups(
            {
              owner: this.isAdminDomain
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_group_id },
          ),
        }).fetchAll(params, {
          signal: this.abortController.signal,
        });
        const { entities, meta, accessible_types } = response.data;

        this.meta = meta;
        this.entities = [...this.entities, ...entities].map((entity) => {
          if (entity.id === this.rootFolderId) {
            return { ...entity, type: 'Root', name: 'Root folder' };
          }
          return entity;
        });
        this.accessibleTypes = accessible_types ?? [];
      } catch (error) {
        if (error.response && error.message !== 'canceled') {
          this.error = this.$localizeErrorMessage(error);
        }
      } finally {
        this.loading = false;
      }
    },

    fetchMoreEntities() {
      if (this.loading || this.entities.length >= this.meta?.total_count) {
        return;
      }
      this.currentPage += 1;
      this.fetchEntities();
    },

    resetAndRefetch() {
      this.currentPage = 1;
      this.entities = [];
      this.fetchEntities();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/stylesheet/tree-table-filters.scss' as filters;

$min-tablet-width: 1065px;
$max-tablet-width: 1250px;

@include filters.media-queries($min-tablet-width, $max-tablet-width);
</style>
