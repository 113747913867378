import AppBaseModel from './AppBaseModel.js';

class Notifications extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'notifications',
      pluralKey: 'notifications',
      singularKey: 'notification',
      ...options,
    };
    super(mergedOptions, item);
  }

  read(...rest) {
    return this.httpClient.put(`${this.entityUrl}/read`, ...rest);
  }

  hide(...rest) {
    return this.httpClient.put(`${this.entityUrl}/hide`, ...rest);
  }
}

export default Notifications;
