<template lang="pug">
template(v-for="field in form.fields")
  template(v-if="field.fieldtype === 'SHORT_TEXT'")
    UiFormInput.dataroom-forms__item(
      v-model="form.results[field.name]"
      :label="field.displayname"
      :readonly="!editableField(field.editable)"
    )
  template(v-else-if="field.fieldtype === 'LONG_TEXT'")
    UiFormInput.dataroom-forms__long-text(
      v-model="form.results[field.name]"
      multiline
      :label="field.displayname"
      :readonly="!editableField(field.editable)"
    )
  template(v-else-if="field.fieldtype === 'DROPDOWN'")
    DataroomFieldDropdown.dataroom-forms__item(
      :results="form.results"
      :field="field"
      :disabled="!editableField(field.editable)"
    )
  template(v-else-if="field.fieldtype === 'RADIO'")
    UiFormRadio(
      v-model="form.results[field.name]"
      :label="field.displayname"
      customLabelKey="text"
      :options="field.attributes.options"
    )
  template(v-else-if="field.fieldtype === 'CHECKBOX'")
    UiFormCheckbox(v-model="form.results[field.name]" :disabled="!editableField(field.editable)") {{ field.displayname }}
  template(v-else-if="field.fieldtype === 'TABLE'")
    UiInputLabel.ui-form-field {{ field.displayname }}
    DataroomTable(
      :displayname="field.displayname"
      :columnsList="field.attributes.columns.list"
      :columnsItems="field.attributes.columns.items"
      :rowsList="field.attributes.rows.list"
      :rowsItems="field.attributes.rows.items"
      :tableSettings="field.attributes.settings || {}"
      :result="form.results[field.name]"
      :isAdmin="isAdmin"
      :formEditable="form.editable"
      :editable="field.editable"
      :fieldEditable="editableField(field.editable)"
    )
</template>

<script>
import DataroomTable from '@/components/Dataroom/Table.vue';
import DataroomFieldDropdown from '@/components/Dataroom/Fields/Dropdown.vue';

export default {
  name: 'DataroomForm',
  components: {
    DataroomTable,
    DataroomFieldDropdown,
  },
  props: {
    form: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editableField(fieldEditable) {
      switch (this.form.editable) {
        case 'ALL':
          if (fieldEditable === 'ALL') {
            return true;
          }
          if (this.isAdmin && fieldEditable === 'ADMIN') {
            return true;
          }
          break;
        case 'ADMIN':
          if (this.isAdmin && ['ADMIN', 'ALL'].includes(fieldEditable)) {
            return true;
          }
          break;
        default:
          return false;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.form-group > legend {
  color: $bb-gray-500 !important;
}

.dataroom-forms__long-text {
  width: 50%;
  .ui-input {
    width: 100%;
  }
}
</style>
