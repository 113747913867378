<template lang="pug">
.admin-layout
  Heading(:title="$t('logs.title')")
  UiTabs(
    :tabs="tabs"
    :selected="selected"
    @select="selectTab"
  )
  transition(name="fade" mode="out-in")
    component(:is="tabComponent")
</template>

<script>
import addAdminModal from '@/modals/Admin/Administrators/add.vue';
import deleteAdminModal from '@/modals/Admin/Administrators/delete.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import UserSelect from '@/components/UserSelect.vue';
import Logs from '@/views/Admin/Logs/Tabs/Logs.vue';
import Logins from '@/views/Admin/Logs/Tabs/Logins.vue';

const ALL_LOGS = 'all_logs';
const LOGIN_LOGS = 'login_logs';

export default {
  name: 'Logs',
  components: {
    Logs,
    UserSelect,
    DataTable,
    addAdminModal,
    deleteAdminModal,
  },
  computed: {
    selected() {
      return this.$route?.query?.tab || ALL_LOGS;
    },
    tabs() {
      return [
        {
          id: ALL_LOGS,
          label: this.$t('logs.tabs.logs_all'),
        },
        {
          id: LOGIN_LOGS,
          label: this.$t('logs.tabs.logs_login'),
        },
      ];
    },
    tabComponent() {
      switch (this.selected) {
        case ALL_LOGS:
          return Logs;
        case LOGIN_LOGS:
          return Logins;
        default:
          return null;
      }
    },
  },
  methods: {
    selectTab(tab_id) {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: tab_id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.table-filters {
  padding: 15px 15px 15px;

  & > .ui-stack > .ui-stack-item {
    @media screen and (min-width: 1241px) {
      min-width: 280px;
    }

    @media screen and (max-width: 1240px) {
      min-width: 240px;
      flex-grow: 1;
    }
  }
}

.tab-container {
  padding: 10px 0;
}
</style>
