import CompanyGroups from '@/api/models/CompanyGroups.js';

const fetchList = ({ commit }, config = { params: { page: 1 } }) => {
  commit('SET_IN_PROGRESS', true);
  return new Promise((resolve, reject) => {
    new CompanyGroups()
      .fetchAll(config.params, config)
      .then((res) => {
        commit('SET_GROUPS', res.data.company_groups);
        commit('SET_META', res.data.meta);
        commit('SET_IN_PROGRESS', false);
        commit('SET_IS_READY', true);
        resolve(res);
      })
      .catch(reject);
  });
};

export default {
  fetchList,
};
