import AppBaseModel from './AppBaseModel.js';

class Analytics extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'analytics',
      pluralKey: 'power_bi_reports',
      singularKey: 'power_bi_report',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchTree(reportGroupId) {
    return this.httpClient.get(`${this.url}/tree`, { params: { report_group_id: reportGroupId } });
  }

  trackView(time) {
    return this.httpClient.post(`${this.entityUrl}/view`, { time, ui_timestamp: Date.now() });
  }

  trackPageView({ time, page_id, page_name }) {
    return this.httpClient.post(`${this.entityUrl}/page_view`, {
      page_id,
      page_name,
      time,
      ui_timestamp: Date.now(),
    });
  }

  trackReportEdit(status) {
    return this.httpClient.post(`${this.entityUrl}/edit`, {
      status: encodeURI(status),
      ui_timestamp: Date.now(),
    });
  }

  trackRendered(payload) {
    return this.httpClient.post(`${this.entityUrl}/rendered`, {
      page_name: payload.name,
      page_id: payload.id,
      time: payload.time,
      reason: payload.reason,
      interrupted: payload.interrupted,
      ui_timestamp: Date.now(),
    });
  }

  trackPageChanged(payload) {
    return this.httpClient.post(`${this.entityUrl}/page_changed`, {
      page_name: payload.name,
      page_id: payload.id,
      ui_timestamp: Date.now(),
    });
  }

  trackLoaded({ time, reason, interrupted }) {
    return this.httpClient.post(`${this.entityUrl}/loaded`, {
      time,
      reason,
      interrupted,
      ui_timestamp: Date.now(),
    });
  }

  trackFailed(details) {
    return this.httpClient.post(`${this.entityUrl}/failed`, { details, ui_timestamp: Date.now() });
  }

  increaseCapacity(errorMessage) {
    return this.httpClient.post(`${this.entityUrl}/increase_capacity`, {
      error_message: errorMessage,
    });
  }

  clickEvent() {
    return this.httpClient.post(`${this.entityUrl}/clicked`);
  }
}

export default Analytics;
