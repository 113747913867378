<template lang="pug">
.no-data
  .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
  .text-secondary {{ subtitleComputed }}
</template>

<script>
export default {
  props: {
    subtitle: {
      type: String,
      default: 'rest.empty',
    },
  },
  computed: {
    subtitleComputed() {
      return this.$t(this.subtitle);
    },
  },
};
</script>

<style scoped lang="scss">
.no-data {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}
</style>
