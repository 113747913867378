export default [
  { value: 'Integrations', label: 'logs.category.integrations' },
  { value: 'Users', label: 'logs.category.users' },
  { value: 'Companies', label: 'logs.category.companies' },
  { value: 'Forms', label: 'logs.category.forms' },
  { value: 'Files', label: 'logs.category.files' },
  { value: 'Reports', label: 'logs.category.reports' },
  { value: 'Bookmarks', label: 'logs.category.bookmarks' },
  { value: 'UiThemes', label: 'logs.category.ui_themes' },
  { value: 'PowerBiEmbeddedLink', label: 'logs.category.powerbi_embedded_link' },
  { value: 'SystemBanners', label: 'logs.category.system_banners' },
  { value: 'IntegrationRequest', label: 'logs.category.integration_request' },
  { value: 'UserGroups', label: 'logs.category.user_groups' },
];
