<template>
  <svg
    width="142"
    height="115"
    viewBox="0 0 142 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.15" cx="66" cy="55" r="55" fill="#7AE7B7" />
    <circle opacity="0.15" cx="66" cy="55" r="46" fill="#7AE7B7" />
    <rect
      x="49.3893"
      y="38.3882"
      width="32.6111"
      height="32.6111"
      rx="16.3056"
      transform="rotate(-0.0490019 49.3893 38.3882)"
      fill="#0FB57C"
      stroke="#0FB57C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M62.1281 55.1394L60.9287 56.5713L64.866 59.8694L70.8625 52.7106L69.4308 51.5113L64.6337 57.2382L62.1281 55.1394Z"
      fill="white"
    />
    <circle cx="3" cy="3" r="3" fill="#0FB57C" />
    <circle cx="134" cy="9" r="8" fill="#0FB57C" />
    <circle cx="23" cy="110" r="5" fill="#0FB57C" />
  </svg>
</template>
