<template lang="pug">
UiStack(vertical align="start")
  div(v-if="list.length <= 3 || expanded")
    | {{ listNames }}
  UiButton(
    v-if="list.length > 3"
    icon="Group"
    :icon-after="expanded ? 'ArrowUp' : 'ArrowDown'"
    variant="outline-secondary"
    size="xs"
    @click.stop="toggleExpandedList"
  ) {{ list.length }} {{ $t('user_groups.expandable_list_title') }}
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    listNames() {
      return this.list.map((e) => e.name).join(', ');
    },
  },

  methods: {
    toggleExpandedList() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
