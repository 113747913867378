<template lang="pug">
.edit-company-page(v-if="!loading" data-test-id="content")
  AppBreadcrumbs(:items="breadcrumbs" showBack)
  Heading(:title="$t('company.edit_company_info')")
    Stack
      StackItem(v-if="!activeTabObject?.customSave")
        UiButton(
          :size="mobileView ? 'sm' : null"
          :disabled="inProgress"
          :loading="inProgress"
          block
          data-test-id="button-save"
          @click="save"
        ) {{ $t('actions.save_changes') }}
      StackItem(v-if="isAdminDomain")
        UiButton(
          variant="outline-danger"
          :disabled="inProgress"
          :round="mobileView"
          block
          icon="Trash"
          data-test-id="button-delete-company"
          @click="handleDelete"
        ) {{ $t('company.action_delete_company') }}
  UiTabs(
    v-if="tabs.length > 1"
    :tabs="tabs"
    :selected="activeTab"
    data-test-id="tabs"
    @select="selectTab"
  )
  UiCard
    transition(name="fade" mode="out-in")
      component(
        :is="tabContent"
        :isAdminRoute="isAdminDomain"
        :isPortalManagementPage="$route.name === 'PortalManagement'"
      )

DeleteCompanyModal(ref="deleteCompanyModal" @wasDeleted="backToCompanies")
</template>

<script>
import TabCompany from '@/views/Company/Edit/Tabs/Company.vue';
import TabAccess from '@/views/Company/Edit/Tabs/Access.vue';
import TabDomain from '@/views/Company/Edit/Tabs/Domain.vue';
import TabNotifications from '@/views/Company/Edit/Tabs/notifications.vue';
import SSOTab from '@/views/Company/Edit/Tabs/sso-tab.vue';
import TabUiTheme from '@/views/Company/Edit/Tabs/UiTheme.vue';
import TabReports from '@/views/Company/Edit/Tabs/reports-tab.vue';
import TabUserSession from '@/views/Company/Edit/Tabs/UserSession.vue';
import SystemBannersTab from '@/views/Admin/PortalManagement/Tabs/system-banners-tab.vue';
import DeleteCompanyModal from '@/modals/Admin/Companies/Delete.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { provide, ref, computed } from 'vue';
import { getExternalErrorMessages, powerBIGroupUrl, email, required } from '@/common/validators.js';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import fileToBase64 from '@/common/fileToBase64.js';
import SSO from '@/api/models/SSO.js';
import { scrollToFirstError } from '@/common/ui-utils.js';
import PowerbiThemeTab from '@/views/Company/Tabs/powerbi-theme-tab.vue';
import DynamicField from '@/models/dynamic-field-model';
import { HTTP_VALIDATION_ERROR } from '@/helper/request-statuses';
import DynamicSettings from '@/api/models/dynamic-settings';

const COMPANY_TAB = 'company';
const ACCESS_TAB = 'access';
const DOMAIN_TAB = 'domain';
const NOTIFICATIONS_TAB = 'notifications';
const SSO_TAB = 'sso';
const UI_THEME_TAB = 'themes';
const POWERBI_THEME_TAB = 'powerbi_theme';
const REPORTS_TAB = 'reports';
const USER_SESSION_TAB = 'session';
const SYSTEM_BANNERS = 'system_banners';

const tabFields = {
  [COMPANY_TAB]: [
    'company.name',
    'company.email',
    'company.analyst_email',
    'company.account_type',
    'company.note',
    'company.invoicing_method',
    'company.invoicing_vat_number',
    'company.netsuite_project_id',
    'company.netvisor_vat_id',
    'company.reviewed',
    'company.revenue_class',
    'company.user_class',
    'company.user_free_count',
    'company.extra_refreshes',
    'company.power_bi_group_url',
    'company.parent_company',
    'company.logo',
  ],
};

const regularFields = [
  'name',
  'email',
  'report_language',
  'report_language_visible',
  'report_data_color', // artefact
  'report_default_theme_visible',
];

const settingsFields = [
  'account_type',
  'note',
  'invoicing_method',
  'invoicing_vat_number',
  'netsuite_project_id',
  'netvisor_vat_id',
  'reviewed',
  'revenue_class',
  'user_class',
  'user_free_count',
  'extra_refreshes',
  'sso_enabled',
  'email_login_enabled',
  'report_refresh_cooldown_time',
  'google_login_enabled',
  'microsoft_login_enabled',
  'private_embedded_links_enabled',
  'public_embedded_links_enabled',
];

const globalFields = [
  'show_api_keys',
  'show_backend_api_key',
  'hidden',
  'analytics_enabled',
  'analytics_premium_enabled',
  'files_enabled',
  'dataroom_enabled',
  'dataroom_api_enabled',
  'extract_api_enabled',
  'analyst_email',
  'power_bi_group_url',
  'sso_enabled',
  'export_report_enabled',
];

export default {
  name: 'EditCompany',
  components: {
    TabCompany,
    TabAccess,
    TabDomain,
    TabUiTheme,
    TabReports,
    TabUserSession,
    DeleteCompanyModal,
  },

  provide() {
    return {
      dynamicSettingFields: computed(() => this.dynamicSettingFields),
    };
  },

  setup() {
    const externalResults = ref({ company: {} });
    const v$ = useVuelidate({ $externalResults: externalResults });

    provide('externalResults', externalResults);
    provide('v$', v$);
    return { v$, externalResults };
  },

  data() {
    return {
      errorToastId: null,
      inProgress: false,
    };
  },

  validations() {
    const external = helpers.regex(/.*/);

    const validations = {
      company: {
        name: { required },
        email: { email },
        note: { external },
        logo: { external },
        account_type: { external },
        invoicing_method: { external },
        invoicing_vat_number: { external },
        netsuite_project_id: { external },
        netvisor_vat_id: { external },
        reviewed: { external },
        revenue_class: { external },
        user_class: { external },
        user_free_count: { external },
        extra_refreshes: { external },
        parent_company: { external },
        power_bi_group_url: { external },
        analyst_email: { external },
      },
    };

    if (this.isAdminDomain) {
      validations.company.analyst_email = { email };
      validations.company.power_bi_group_url = { powerBIGroupUrl };

      this.dynamicSettingFields.forEach((field) => {
        validations.company[field.attr_name] = {
          required: field.value_validations.presence ? required : false,
          email: field.value_validations.email ? email : false,
          external,
        };
      });
    }

    return validations;
  },

  computed: {
    ...mapState({
      dynamicSettings: (state) => state.index.dynamicSettings,
    }),
    ...mapGetters(['isAdmin', 'isAdminDomain', 'mobileView', 'defaultCompany', 'language']),
    ...mapGetters('editCompany', ['company', 'originalCompany', 'loading']),
    ...mapGetters('company', ['currentCompany']),
    breadcrumbs() {
      return [
        {
          label: this.$t('companies.title'),
          to: { name: 'AdminCompanies' },
          visible: this.isAdminDomain,
        },
        {
          label: this.originalCompany?.name,
          to: this.$adminCompanyLinkModifier({
            name: 'CompanyShow',
          }),
          visible: true,
        },
        {
          label: this.$t('edit_company.title'),
          visible: true,
        },
      ].filter((r) => r.visible ?? true);
    },
    companyId() {
      return this.$route.params?.company_id ?? this.currentCompany?.id;
    },
    tabs() {
      return [
        {
          id: COMPANY_TAB,
          label: this.$t('edit_company.company.title'),
          enabled: true,
          content: TabCompany,
        },
        {
          id: SSO_TAB,
          label: this.$t('edit_company.saml.title'),
          enabled: this.originalCompany?.sso_enabled,
          customSave: true,
          content: SSOTab,
        },
        {
          id: DOMAIN_TAB,
          label: this.$t('company.tabs.domain'),
          enabled: !this.defaultCompany,
          customSave: true,
          content: TabDomain,
        },
        {
          id: NOTIFICATIONS_TAB,
          label: this.$t('edit_company.notifications.title'),
          enabled: true,
          customSave: true,
          content: TabNotifications,
        },
        {
          id: ACCESS_TAB,
          label: this.$t('edit_company.access.title'),
          enabled: this.isAdminDomain,
          content: TabAccess,
        },
        {
          id: UI_THEME_TAB,
          label: this.$t('portal_management.tabs.ui_theme'),
          enabled: true,
          customSave: true,
          content: TabUiTheme,
        },
        {
          id: POWERBI_THEME_TAB,
          label: this.$t('edit_company.powerbi_theme.tab_title'),
          enabled: !this.isAdmin,
          customSave: true,
          content: PowerbiThemeTab,
        },
        {
          id: REPORTS_TAB,
          label: this.$t('edit_company.reports.title'),
          enabled: this.isAdmin,
          customSave: true,
          content: TabReports,
        },
        {
          id: USER_SESSION_TAB,
          label: this.$t('edit_company.user_session.title'),
          enabled: this.$store.getters['company/isAdmin'] || this.isAdmin,
          customSave: true,
          content: TabUserSession,
        },
        {
          id: SYSTEM_BANNERS,
          label: this.$t('portal_management.tabs.system_banners'),
          enabled: true,
          customSave: true,
          content: SystemBannersTab,
        },
      ].filter((tab) => tab.enabled);
    },
    activeTab() {
      return this.$route?.query?.tab || this.tabs[0].id;
    },
    activeTabObject() {
      return this.tabs.find((t) => t.id === this.activeTab) ?? null;
    },
    tabContent() {
      return this.activeTabObject?.content;
    },
    dynamicSettingFields() {
      return this.dynamicSettings?.map(this.generateDynamicSettingsField);
    },
  },

  async mounted() {
    try {
      await this.fetchData();
      if (!this.isAdminDomain) {
        await this.fetchSSO();
      } else {
        await this.fetchDynamicSettings();
      }
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    ...mapMutations('editCompany', ['UPDATE_COMPANY', 'SET_ORIGINAL_COMPANY']),
    ...mapMutations(['SET_DYNAMIC_SETTINGS']),
    fetchData() {
      return this.$store
        .dispatch('editCompany/init', this.isAdminDomain ? this.companyId : null)
        .catch(() => {
          this.$router.replace('/');
        });
    },

    async fetchSSO() {
      try {
        const response = await new SSO().fetch();
        const { sso_enabled } = response.data;
        this.UPDATE_COMPANY({ sso_enabled });
        this.SET_ORIGINAL_COMPANY(this.company);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchDynamicSettings() {
      try {
        const response = await new DynamicSettings().fetchAll({
          per_page: 1000,
          sort_order: 'desc',
        });

        const normalized = response.data.dynamic_settings.map((item) =>
          new DynamicField(item).toJSON(),
        );
        this.SET_DYNAMIC_SETTINGS(normalized);
      } catch {
        // do nothing
      }
    },

    openFieldTab(path) {
      // eslint-disable-next-line guard-for-in
      for (const tab in tabFields) {
        const fields = tabFields[tab];
        if (fields.includes(path)) {
          this.selectTab(tab, true);
          break;
        }
      }
      this.selectTab(COMPANY_TAB, true); // for dynamic settings // todo refactor openFieldTab method
    },

    navigateToFirstInvalidField() {
      let [firstError] = this.v$.$errors;
      if (!firstError) {
        [firstError] = getExternalErrorMessages(this.externalResults);
      }

      this.openFieldTab(firstError.$propertyPath);

      this.errorToastId = this.$toaster.add({
        type: 'error',
        title: 'Error',
        message: this.$t('validations.check_all_fields'),
      });
    },

    async save() {
      this.$toaster.close(this.errorToastId);
      this.errorToastId = null;

      const valid = await this.v$.$validate();

      if (!valid) {
        this.navigateToFirstInvalidField();
        return;
      }

      this.inProgress = true;
      const companyData = {};
      if (this.company.logo_file) {
        companyData.logo = await fileToBase64(this.company.logo_file);
      }
      if (this.company.remove_logo) {
        companyData.remove_logo = true;
      }
      for (const key of regularFields) {
        companyData[key] = this.company[key];
      }

      if (this.isAdminDomain) {
        for (const key of globalFields) {
          companyData[key] = this.company[key];
        }

        this.dynamicSettings.forEach(({ attr_name }) => {
          companyData[attr_name] = this.company[attr_name];
        });

        companyData.settings = {};
        for (const key of settingsFields) {
          companyData.settings[key] = this.company[key];
        }

        companyData.company_group_id = this.company.group?.id ?? null;
        companyData.parent_company_id = this.company.parent_company?.id ?? null;
      }

      this.$store
        .dispatch('company/updateCompany', {
          company: companyData,
          companyId: this.isAdminDomain ? this.companyId : null,
        })
        .then((_res) => {
          this.inProgress = false;
          this.$toaster.add({
            title: this.$t('status.success'),
            message: this.$t('edit_company.status.info_updated_success'),
            timeout: 10000,
          });

          this.backToCompany();
        })
        .catch((error) => {
          if (error.response?.status === HTTP_VALIDATION_ERROR && error.response?.data?.errors) {
            const externalResults = { company: parseResponseErrors(error.response.data.errors) };
            Object.assign(this.externalResults, externalResults);
            this.navigateToFirstInvalidField();
          } else {
            this.$toaster.add({
              type: 'error',
              title: this.$t('status.error'),
              message: this.$t('errors.something_went_wrong_try_later'),
            });
          }

          this.inProgress = false;
        });
    },
    async selectTab(tab_id, scrollToError) {
      await this.$router.replace({
        path: this.$route.path,
        query: { tab: tab_id },
      });

      if (scrollToError) {
        scrollToFirstError();
      }
    },
    backToCompany() {
      if (this.$route.params.company_id) {
        this.$router.push({
          name: 'AdminCompanyShow',
          params: { company_id: this.$route.params.company_id },
        });
      } else {
        this.$router.push({ name: 'CompanyShow' });
      }
    },
    backToCompanies() {
      this.$router.replace({ name: 'Companies' });
    },
    handleDelete() {
      this.$refs.deleteCompanyModal.open(this.company.name, this.companyId);
    },
    generateDynamicSettingsField(field) {
      let baseProps = new DynamicField(field).toJSON();
      baseProps = {
        ...baseProps,
        placeholder: baseProps.placeholder[this.language] || baseProps.placeholder.en,
      };

      if (field.attr_type === 'boolean') {
        return {
          ...baseProps,
          component: 'UiCheckbox',
          switch: true,
        };
      }

      if (field.attr_type === 'date') {
        return {
          ...baseProps,
          component: 'UiFormDatePicker',
          canClear: !field.value_validations.presence,
        };
      }

      if (field.attr_type === 'dropdown') {
        return {
          ...baseProps,
          component: 'UiFormMultiselect',
          mode: baseProps.data?.multiselect ? 'tags' : 'single',
          searchable: baseProps.data?.search,
          options: baseProps.data?.options || [],
          canClear: !field.value_validations.presence,
          canDeselect: !field.value_validations.presence,
        };
      }

      return {
        ...baseProps,
        component: 'UiFormInput',
        type: field.attr_type === 'integer' ? 'number' : 'text',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-company-page {
  padding: 30px;
}

.page-card {
  background-color: var(--bb-app-bg-main);
  border-radius: 0 10px 10px 10px;
  padding: 25px;
}
</style>
