import LocalStorage from '@/common/localstorage';
import User from '@/models/User.js';

const SET_INIT_DATA = (state, payload) => {
  /**
   * payload = {
   *    chaskiq_url: null,
   *    chaskiq_settings: null,
   *    app_domain: "bibook.com",
   *    basic_login_enabled: boolean,
   *    google_login_enabled: boolean,
   *    microsoft_login_enabled: boolean,
   *    sso_login_enabled: boolean,
   *    capacity_related_errors: 'CapacityNotActive|CapacityLimitExceeded'
   *    company_roles: {
   *      admin: "admin",
   *      editor: "editor",
   *      reader: "reader",
   *      super_admin: "super admin",
   *    },
   *    supportEmail: null
   *    selected_company_id: null,
   *    selected_report_id: null,
   *    default_company: false,
   *    external_login_page: string,
   *    view_as_token: null,
   *    dynamic_settings?: []
   *  }
   */

  state.chaskiq_url = payload.chaskiq_url;
  state.chaskiq_settings = payload.chaskiq_settings;
  state.email_login_enabled = payload.email_login_enabled;
  state.google_login_enabled = payload.google_login_enabled;
  state.microsoft_login_enabled = payload.microsoft_login_enabled;
  state.sso_login_enabled = payload.sso_login_enabled;
  state.sso_login_method = payload.sso_login_method;
  state.viewAsToken = payload.view_as_token;
  state.externalLoginPage = payload.external_login_page;
  state.selectedCompanyId = payload.selected_company_id;
  state.selectedReportId = payload.selected_report_id;
  state.appDomain = payload.app_domain;
  state.cnameDomain = payload.cname_domain;
  state.defaultCompany = payload.default_company;
  state.capacityRelatedErrors = payload.capacity_related_errors;
  state.terms_page = payload.terms_page;
  state.report_theme_instruction_url = payload.report_theme_instruction_url;
  state.freshChatToken = payload.fresh_chat_token;
  state.companiesCount = payload.companies_count;
  state.dynamicSettings = payload.dynamic_settings;
  state.dynamicSettingsMaxCount = payload.dynamic_settings_max_count;
  state.supportEmail = payload.support_email;
};

const SET_DYNAMIC_SETTINGS = (state, settings) => {
  state.dynamicSettings = settings;
};

const SET_APP_READY = (state, appReady = true) => {
  state.appReady = appReady;
};

const LOGIN = (state, user) => {
  state.user = user && new User(user);
};

const LOGOUT = (state) => {
  state.user = null;
  state.currentCompanyId = null;
};

const SET_LOCALE = (state, value) => {
  state.language = value;
};

const SET_COMPANY_ROLES = (state, roles) => {
  state.company_roles = roles;
};

const SET_USER = (state, user) => {
  state.user = user && new User(user);
};

const UPDATE_USER = (state, data) => {
  state.user = {
    ...state.user,
    ...data,
  };
};

const UPDATE_INTEGRATION_COMPANY = (state, data) => {
  state.integrationCompany = data;
};

const UPDATE_RESTORE_NAVIGATION_STATE = (state, data) => {
  state.restoreNavigation = {
    ...state.restoreNavigation,
    ...data,
  };
};

const RESTORE_NAVIGATION_STATE = (state) => {
  state.headerOpen = state.restoreNavigation.headerOpen;
  state.navOpen = state.restoreNavigation.navOpen;
};

const RECORD_NAVIGATION_STATE = (state) => {
  state.restoreNavigation = {
    headerOpen: state.headerOpen,
    navOpen: state.navOpen,
  };
};

const UPDATE_NAV_OPEN = (state, isOpen) => {
  state.navOpen = isOpen;
  LocalStorage.set('navOpen', isOpen);
};

const UPDATE_NAV_MOBILE_OPEN = (state, isOpen) => {
  state.navMobileOpen = isOpen;
};

const UPDATE_HEADER_OPEN = (state, isOpen) => {
  state.headerOpen = isOpen;
};

const TOGGLE_MOBILE_VIEW = (state, value) => {
  state.mobileView = value;
};

const TOGGLE_LAPTOP_VIEW = (state, value) => {
  state.laptopView = value;
};

export default {
  SET_INIT_DATA,
  SET_DYNAMIC_SETTINGS,
  SET_APP_READY,
  LOGIN,
  LOGOUT,
  SET_LOCALE,
  SET_COMPANY_ROLES,
  SET_USER,
  UPDATE_USER,
  UPDATE_INTEGRATION_COMPANY,
  UPDATE_NAV_OPEN,
  UPDATE_NAV_MOBILE_OPEN,
  UPDATE_HEADER_OPEN,
  TOGGLE_MOBILE_VIEW,
  TOGGLE_LAPTOP_VIEW,
  UPDATE_RESTORE_NAVIGATION_STATE,
  RESTORE_NAVIGATION_STATE,
  RECORD_NAVIGATION_STATE,
};
