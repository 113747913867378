<template lang="pug">
.empty-state(v-bind="$attrs")
  .empty-state__body
    slot(name="body")
      .empty-state__title(v-if="title") {{ title }}
      .empty-state__description(v-if="description") {{ description }}
      .empty-state__actions(v-if="hasActionsSlot")
        slot(name="actions")
  .empty-state__image-container(:class="imageClasses")
    slot
      HikingImage
</template>

<script>
import HikingImage from '@/assets/images/illustrations/hiking.vue';

export default {
  components: { HikingImage },
  props: {
    title: {
      type: String,
      default: null,
    },

    description: {
      type: String,
      default: null,
    },

    flipImage: {
      type: Boolean,
      default: false,
    },

    flopImage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions;
    },

    imageClasses() {
      return {
        'empty-state__image-container--flip': this.flipImage,
        'empty-state__image-container--flop': this.flopImage,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.empty-state {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &__body,
  &__body {
    z-index: 2;

    & > * + * {
      margin-top: 1.35rem;
    }
  }

  &__title {
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bb-primary);
  }

  &__description {
    font-size: 1.5rem;
    color: $bb-gray-600;
  }

  &__actions {
    margin-top: 2rem;
  }

  &__image-container:deep {
    flex-grow: 1;
    width: 50%;

    svg {
      display: block;
      width: 130%;
      margin-left: -30%;
    }

    &--flip {
      transform: scale(-1, 1);
    }

    &--flop {
      transform: scale(1, -1);
    }

    &--flip#{&}--flop {
      transform: scale(-1, -1);
    }
  }
}
</style>
