<template lang="pug">
.ui-form-field.ui-form-field--checkbox
  UiInputLabel(v-if="label || $slots.label")
    slot(name="label")
      | {{ label }}
  UiCheckbox(
    v-bind="$attrs"
    :error="error || vuelidateModel?.$error"
    @blur="vuelidateModel?.$touch"
  )
    template(v-for="(_, slot) of $slots" v-slot:[slot]="scope")
      slot(v-bind="scope" :name="slot")
  template(v-if="vuelidateModel?.$errors")
    UiInputFeedback(v-for="$error in vuelidateModel?.$errors" error) {{ $error.$message }}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
