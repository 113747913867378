import AppBaseModel from './AppBaseModel';

class UserGroups extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'user_groups',
      singularKey: 'user_group',
      ...options,
    };
    super(mergedOptions, item);
  }

  updatePermissions(params, config) {
    return this.httpClient.put(
      `${this.entityUrl}/update_permissions`,
      this.wrapSingularData(params),
      config,
    );
  }
}

export default UserGroups;
