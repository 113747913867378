<template lang="pug">
Modal(
  :show="modelValue"
  :title="title"
  :close="close"
  :width="'440px'"
)
  template(#body)
    UiFilePicker(
      v-model="files"
      :extensions="extensions"
      :maxSize="maxSize"
      multiple
      :maxCount="maxFilesCount"
    )

    UiButton.import__download-example(
      v-if="exampleLinkText && downloadExample"
      variant="text-primary"
      :icon="exampleLinkIcon"
      @click.prevent="downloadExample"
    ) {{ exampleLinkText }}
    Stack.import__actions(justify="space-between")
      StackItem
        UiButton(
          variant="outline-primary"
          data-test="cancel"
          @click="close"
        ) {{ $t('cancel') }}
      StackItem
        UiButton(
          :disabled="!files.length || loading"
          :loading="loading"
          data-test="confirm"
          @click="confirm"
        ) {{ $t('apply') }}
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    downloadExample: {
      type: Function,
      default: () => {},
    },
    modelValue: Boolean,
    loading: Boolean,
    apply: {
      type: Function,
      required: true,
    },
    maxSize: {
      type: Number,
      default: 3900,
    },
    title: {
      type: String,
      default: 'Import',
    },
    extensions: {
      type: Array,
      default: null,
    },
    maxFilesCount: {
      type: Number,
      default: 1,
    },
    exampleLinkText: {
      type: String,
      default: null,
    },
    exampleLinkIcon: {
      type: String,
      default: 'file',
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    close() {
      this.removeFiles();
      this.$emit('update:modelValue', false);
    },
    removeFiles() {
      this.files = [];
    },
    confirm() {
      this.apply(this.files).then(() => this.close());
    },
  },
};
</script>

<style scoped lang="scss">
.import {
  &__download-example {
    margin-top: 0.75rem;
  }

  &__actions {
    margin-top: 0.75rem;
  }
}
</style>
