<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460"
  :allowClose="false"
  :boldTitle="false"
)
  template(#title) 
    p {{ $t('user_groups.modals.delete.title') }}
    p(v-html="$t('user_groups.modals.delete.message', { group_name: group.name })")
</template>

<script>
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import { UiToaster } from '@bi-book/bibook-ui-kit';
import { mapGetters } from 'vuex';

export default {
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      group: null,
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          onClick: this.submit,
          loading: this.inProgress,
          variant: 'danger',
        },
      ];
    },
  },
  methods: {
    open(group) {
      this.group = group;
      this.inProgress = false;
      this.show = true;
    },
    async submit() {
      this.inProgress = true;
      try {
        await new UserGroups(
          {
            adminApi: this.isAdminHost,
            owner: this.isAdminHost
              ? new Companies(null, { id: this.$route.params.company_id })
              : null,
          },
          this.group,
        ).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        UiToaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>
