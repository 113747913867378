<template lang="pug">
UiModal(
  :title="$t('access.modal.title')"
  :subtitle="$t('access.modal.description')"
  :show="show"
  width="600px"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  .columns-layout
    .columns-layout-item(v-for="key in fastAccessOptionsKeys")
      UiCheckbox(:modelValue="isCheckedOption(key)" @change="toggleOption(key)") {{ $t(`access.table.${key}`) }}
</template>

<script>
import { fastAccessOptionsKeys } from '@/views/Admin/FastAccess/index.js';

export default {
  emits: ['done'],

  data() {
    return {
      show: false,
      hiddenKeysList: [],
    };
  },

  computed: {
    fastAccessOptionsKeys() {
      return fastAccessOptionsKeys;
    },

    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          variant: 'smooth-secondary',
          onClick: this.close,
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.apply'),
          onClick: this.save,
        },
      ];
    },
  },

  methods: {
    open(hiddenKeysList = []) {
      this.hiddenKeysList = [...hiddenKeysList];
      this.show = true;
    },

    close() {
      this.show = false;
    },

    isCheckedOption(key) {
      return !this.hiddenKeysList.includes(key);
    },

    toggleOption(key) {
      if (this.hiddenKeysList.includes(key)) {
        this.hiddenKeysList = this.hiddenKeysList.filter((i) => i !== key);
        return;
      }
      this.hiddenKeysList = [...this.hiddenKeysList, key];
    },

    save() {
      this.$emit('done', this.hiddenKeysList);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.columns-layout {
  padding-top: 10px;
  margin-bottom: 25px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;

  &-item {
    max-width: 280px;
    margin-bottom: 18px;
  }
}
</style>
