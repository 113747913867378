import Companies from '@/api/models/Companies.js';

// eslint-disable-next-line arrow-body-style
const fetchCompanies = ({ commit }) => {
  return new Promise((resolve, reject) => {
    new Companies({ adminApi: true })
      .fetchAll()
      .then((res) => {
        commit('SET_COMPANIES', res.data.companies);
        commit('SET_IS_READY', true);
        resolve(res);
      })
      .catch(reject);
  });
};

export default {
  fetchCompanies,
};
