<template lang="pug">
StackItem
  UiFormInput(
    :modelValue="entity_id"
    :label="$t('edit_company.saml.sp_entity_id')"
    size="lg"
    readonly
  )
    template(#icon)
      UiTooltip(variant="dark" :title="$t('actions.copy_to_clipboard')")
        template(#activator)
          UiButton(
            icon="Copy"
            variant="text-secondary"
            block
            @click="copyToClipboard(entity_id)"
          )
StackItem
  UiFormInput(
    :modelValue="acs_url"
    :label="$t('edit_company.saml.assertion_consumer_service_url')"
    size="lg"
    readonly
  )
    template(#icon)
      UiTooltip(variant="dark" :title="$t('actions.copy_to_clipboard')")
        template(#activator)
          UiButton(
            icon="Copy"
            variant="text-secondary"
            block
            @click="copyToClipboard(acs_url)"
          )
StackItem
  UiFormMultiselect(
    :modelValue="saml.saml_setup_method"
    :canClear="false"
    customLabelKey="label"
    valueProp="value"
    :options="settingTypes"
    :disabled="inProgress"
    :label="$t('edit_company.saml.select_setting_type_label')"
    size="lg"
    @update:modelValue="updateSAMLForm({ saml_setup_method: $event })"
    @select="v$.$reset()"
  )
template(v-if="saml.saml_setup_method === 'manifest'")
  StackItem
    FileInputTextarea(
      :modelValue="saml.saml_manifest"
      :label="$t('edit_company.saml.paste_xml_label')"
      accept="text/xml"
      :disabled="inProgress"
      :vuelidateModel="v$.saml.saml_manifest"
      size="lg"
      @update:modelValue="updateSAMLForm({ saml_manifest: $event })"
    )
template(v-if="saml.saml_setup_method === 'manual'")
  StackItem
    UiFormInput(
      :modelValue="saml.sso_service_url"
      :label="$t('edit_company.saml.sso_service_url')"
      placeholder="https://"
      :disabled="inProgress"
      :vuelidateModel="v$.saml.sso_service_url"
      size="lg"
      @update:modelValue="updateSAMLForm({ sso_service_url: $event })"
    )
  StackItem
    UiFormInput(
      :modelValue="saml.entity_id"
      :label="$t('edit_company.saml.entity_id')"
      :disabled="inProgress"
      :vuelidateModel="v$.saml.entity_id"
      size="lg"
      @update:modelValue="updateSAMLForm({ entity_id: $event })"
    )
  StackItem
    FileInputTextarea(
      :modelValue="saml.cert"
      :label="$t('edit_company.saml.paste_certificate_label')"
      :disabled="inProgress"
      :vuelidateModel="v$.saml.cert"
      size="lg"
      @update:modelValue="updateSAMLForm({ cert: $event })"
    )
  UiStack(vertical spacing="xs")
    Stack(justify="space-between")
      StackItem
        UiInputLabel {{ $t('edit_company.saml.fingerprint') }}
      StackItem(fill)
        UiMultiselect(
          :modelValue="saml.cert_fingerprint_algorithm"
          :options="fingerprintOptions"
          :canClear="false"
          :disabled="inProgress"
          size="lg"
          @update:modelValue="updateSAMLForm({ cert_fingerprint_algorithm: $event })"
        )
    StackItem
      UiFormInput(
        :modelValue="saml.cert_fingerprint"
        :disabled="inProgress"
        :vuelidateModel="v$.saml.cert_fingerprint"
        size="lg"
        @update:modelValue="updateSAMLForm({ cert_fingerprint: $event })"
      )
StackItem(align="end")
  UiButton(
    :disabled="inProgress"
    :loading="inProgress"
    size="lg"
    @click="$emit('submit')"
  ) {{ $t('actions.save') }}
</template>

<script>
import copy from 'copy-to-clipboard';
import FileInputTextarea from '@/views/Company/Edit/Tabs/components/FileInputTextarea.vue';
import useVuelidate from '@vuelidate/core';
import { requiredIf, url } from '@/common/validators.js';
import { mapGetters } from 'vuex';

export default {
  name: 'SAMlForm',

  components: {
    FileInputTextarea,
  },

  emits: ['update:saml'],

  props: {
    saml: {
      type: Object,
      required: true,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  validations() {
    const isManifestSetting = this.saml.saml_setup_method === 'manifest';

    return {
      saml: {
        saml_manifest: {
          required: requiredIf(isManifestSetting),
        },
        sso_service_url: {
          url,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'appDomain']),
    ...mapGetters('editCompany', ['company']),

    settingTypes() {
      return [
        {
          value: 'manifest',
          label: this.$t('edit_company.saml.manifest_xml'),
        },
        {
          value: 'manual',
          label: this.$t('edit_company.saml.manual_settings'),
        },
      ];
    },

    fingerprintOptions() {
      return ['SHA1', 'SHA256', 'SHA384', 'SHA512'];
    },

    acs_url() {
      return `https://auth.${this.appDomain}/saml/consume`;
    },

    entity_id() {
      return `https://auth.${this.appDomain}/saml/metadata`;
    },
  },
  methods: {
    copyToClipboard(val) {
      const result = copy(val);
      if (result) {
        this.$toaster.add({
          type: 'info',
          message: this.$t('company.reports.copied'),
        });
      }
    },

    updateSAMLForm(partial) {
      this.$emit('update:saml', {
        ...this.saml,
        ...partial,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper {
  max-width: 510px;
}
</style>
