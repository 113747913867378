<template lang="pug">
.data-table-items-per-page
  UiStack(spacing="sm")
    .data-table-items-per-page__label {{ $t('pagination.per_page') }}
    .data-table-items-per-page__select
      UiMultiselect(
        v-model="perPageModel"
        :options="perPageOptions"
        :allow-empty="false"
        size="sm"
        :canClear="false"
        :canDeselect="false"
      )
</template>

<script>
export default {
  emits: ['update:modelValue'],

  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 15, 25, 50, 100],
    },
  },

  computed: {
    perPageModel: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<style lang="scss">
.data-table-items-per-page {
  position: relative;

  &__select {
    & .ui-multiselect-wrapper {
      padding-left: 0.75rem;
      padding-right: 2.2rem;
    }
  }
}
</style>
