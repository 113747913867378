<template lang="pug">
UiModal(
  :title="$t('user_groups.modals.add_users.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  .ui-form-field
    UiInputLabel {{ $t('user_select.placeholder') }}
    UserSelect(
      v-model="form.user_ids"
      value-prop="user_id"
      track-by="user_id"
      :object="false"
      mode="tags"
      :closeOnSelect="false"
      placeholder=" "
    )
</template>

<script>
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import { UiToaster } from '@bi-book/bibook-ui-kit';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { ref } from 'vue';
import UserSelect from '@/components/UserSelect.vue';
import { mapGetters } from 'vuex';
import Users from '@/api/models/Users.js';

function getForm(form) {
  return {
    id: null,
    user_ids: [],
    ...form,
  };
}

export default {
  components: {
    UserSelect,
  },
  emits: ['done'],
  setup() {
    const externalResults = ref();
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: getForm(),
    };
  },
  validations() {
    return {
      form: {
        user_ids: {
          minLength: minLength(1),
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.apply'),
          onClick: this.submit,
          loading: this.inProgress,
        },
      ];
    },
  },
  methods: {
    open(group) {
      this.v$.$reset();
      this.form = getForm();
      this.form.id = group.id;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async submit() {
      this.externalResults = {};
      const valid = await this.v$.$validate();
      if (!valid) return;
      this.inProgress = true;
      try {
        await new Users({
          adminApi: this.isAdminHost,
          singularKey: null,
          owner: new UserGroups(
            {
              owner: this.isAdminHost
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.form.id },
          ),
        }).create(this.form);
        this.$emit('done');
        this.close();
      } catch (error) {
        UiToaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>
