<template lang="pug">
RestTable(
  ref="domainsHistoryTable"
  namespace="domainHistory"
  :fetchHandler="fetchHandler"
  :headers="headers"
  :initialFilters="{ sort_order: 'asc' }"
)
  template(#cel_domain="{ item }")
    .text-nowrap(data-test-id="domain-name") {{ getDomainName(item) }}
  template(#cel_txt="{ item }")
    CopyField(v-if="item.full" :value="item.dns_txt")
    div(v-else) -
  template(#cel_alias="{ item }")
    CopyField(v-if="item.full" :value="aliasValue")
    div(v-else) -
  template(#cel_status="{ item }")
    DomainStatusBadge(:status="item.state" data-test-id="domain-status-badge")
</template>

<script>
import CopyField from '@/components/CopyField.vue';
import { mapGetters } from 'vuex';
import ApiAdminCompanyDomains from '@/api/admin/company_domains.js';
import ApiCompanyDomains from '@/api/company_domains.js';
import DomainStatusBadge from './DomainStatusBadge.vue';

export default {
  name: 'DomainsHistoryTable',
  components: {
    CopyField,
    DomainStatusBadge,
  },
  computed: {
    ...mapGetters(['isAdmin', 'appDomain']),
    aliasValue() {
      return `app.${this.appDomain}`;
    },
    // TODO: recheck headers in table, now `txt` and `alias` don`t using
    headers() {
      return [
        {
          key: 'domain',
          label: 'Domain',
        },
        {
          key: 'status',
          label: this.$t('company.domain.status_label'),
        },
      ];
    },
  },
  methods: {
    async fetchHandler(params) {
      let domainsResponse = null;
      if (this.isAdmin) {
        domainsResponse = await ApiAdminCompanyDomains.fetchAll(
          this.$route.params.company_id,
          params,
        );
      } else {
        domainsResponse = await ApiCompanyDomains.fetchAll(params);
      }

      return {
        items: domainsResponse.data.company_domains,
        meta: domainsResponse.data.meta,
      };
    },

    getDomainName(domain) {
      return domain.full ? domain.domain : `${domain.domain}.${this.appDomain}`;
    },

    fetchData() {
      this.$refs.domainsHistoryTable.fetchData();
    },
  },
};
</script>
