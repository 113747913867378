<template lang="pug">
.onboarding-container(v-if="ready")
  .onboarding-logo(v-if="attempt?.logo")
    img.onboarding-logo__card(:src="attempt?.logo")
  .step-card
    .step-progress__container
      .step-progress__bar(:style="{ width: `${progress}%` }")
      .step-progress__layout
        span {{ $t('onboarding.completed') }}
        .step-progress__percent {{ progress }}%
    component(
      :is="stepComponent"
      :attemptId="attemptId"
      :attempt="attempt"
      :companyId="companyId"
      :integrationName="integrationName"
      :defaultTitle="defaultTitle"
      :targetCompany="targetCompany"
      :selectedCompany="selectedCompany"
      :termsLink="termsLink"
      @toStep="toStep"
      @updateAttempt="setAttempt"
      @progress="setProgress"
      @switchCompany="setCompanyId"
      @cancel="cancel"
    )
CancelAttemptModal(
  ref="cancelAttemptModal"
  @progress="setProgress"
  @toStep="toStep"
)
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import OnboardingBackgroundImage from '@/assets/images/onboarding_background.vue';
import LocalStorage from '@/common/localstorage.js';
import CancelAttemptModal from './CancelAttemptModal.vue';
import LoginStep from './Steps/LoginStep.vue';
import ChooseCompanyStep from './Steps/ChooseCompanyStep.vue';
import IntegrationExistsStep from './Steps/IntegrationExistsStep.vue';
import CreateCompanyStep from './Steps/CreateCompanyStep.vue';
import SuccessConnectionStep from './Steps/success-connection-step.vue';
import SuccessRequestStep from './Steps/success-request-step.vue';
import CanNotFindUserOrCompanyStep from './Steps/CanNotFindUserOrCompanyStep.vue';
import SummaryStep from './Steps/SummaryStep.vue';
import NoRightsStep from './Steps/no-rights-step.vue';
import CancelStep from './Steps/cancel-step.vue';
import OnboardingStep from './OnboardingStep.js';

export default {
  name: 'OnboardingView',
  components: {
    CancelAttemptModal,
    OnboardingBackgroundImage,
  },
  data() {
    return {
      ready: false,
      companyId: null,
      attemptId: null,
      attempt: null,
      progress: 0,
      step: OnboardingStep.LOGIN,
    };
  },
  computed: {
    OnboardingStep() {
      return OnboardingStep;
    },
    stepComponent() {
      switch (this.step) {
        case OnboardingStep.LOGIN:
          return LoginStep;
        case OnboardingStep.CHOOSE_COMPANY:
          return ChooseCompanyStep;
        case OnboardingStep.CREATE_COMPANY:
          return CreateCompanyStep;
        case OnboardingStep.NO_RIGHTS:
          return NoRightsStep;
        case OnboardingStep.INTEGRATION_EXISTS:
          return IntegrationExistsStep;
        case OnboardingStep.CAN_NOT_FIND_USER_OR_COMPANY:
          return CanNotFindUserOrCompanyStep;
        case OnboardingStep.SUMMARY:
          return SummaryStep;
        case OnboardingStep.SUCCESS_CONNECTION:
          return SuccessConnectionStep;
        case OnboardingStep.SUCCESS_REQUEST:
          return SuccessRequestStep;
        case OnboardingStep.CANCEL:
          return CancelStep;
        default:
          return null;
      }
    },
    integrationName() {
      return this.attempt?.title ?? this.attempt?.software;
    },
    selectedCompany() {
      if (!this.attempt) {
        return null;
      }
      return {
        id: this.attempt.company_id,
        name: this.attempt.company_name,
        business_id: this.attempt.company_business_id,
      };
    },
    targetCompany() {
      return this.attempt?.companies.find((c) => c.id === this.companyId) ?? null;
    },
    defaultTitle() {
      return this.$t('onboarding.default_title', { integration_name: this.integrationName });
    },
    termsLink() {
      return `/api/v1/terms_of_service?attempt_id=${this.attempt?.id}`;
    },
  },
  mounted() {
    this.initFlow();
  },
  methods: {
    cancel() {
      this.$refs.cancelAttemptModal.open(this.attempt?.id, this.integrationName);
    },
    toStep(step) {
      this.step = step;
    },
    setProgress(progress) {
      this.progress = progress;
    },
    setAttempt(newAttempt) {
      this.attempt = newAttempt;
    },
    setCompanyId(companyId) {
      this.companyId = companyId;
    },
    async fetchAttemptData() {
      let attemptResponse;
      try {
        attemptResponse = await new IntegrationAttempts(null, { id: this.attemptId }).fetch();
      } catch (error) {
        LocalStorage.remove('integration_attempt');
        this.$router.replace('/');
        const message = this.$localizeErrorMessage(error);
        this.$toaster.add({
          type: 'error',
          message: 'sd',
          timeout: 7000,
        });
        return;
      }
      this.attempt = attemptResponse.data.integration_attempt;
      this.companyId = attemptResponse.data.integration_attempt.company_id;

      this.toStep(this.determinateStep());
      this.progress = 65;
      this.ready = true;
    },
    initFlow() {
      this.attemptId = this.$route.params.attempt_id;
      LocalStorage.set('integration_attempt', this.attemptId);
      if (!this.$store.getters.isAuthenticated) {
        this.toStep(OnboardingStep.LOGIN);
        this.progress = 35;
        this.ready = true;
        return;
      }
      this.fetchAttemptData();
    },
    determinateStep() {
      if (!this.attempt.companies.length) {
        return OnboardingStep.CREATE_COMPANY;
      }
      return OnboardingStep.CHOOSE_COMPANY;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/mixins';
@import '@/assets/stylesheet/variables.scss';

.onboarding-container {
  margin: auto;
  max-width: calc(882px + (30px * 2));
  width: 100%;
  padding: 30px;
  padding-top: 65px;
  align-self: center;
  min-height: 300px;

  .onboarding-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 65px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }

    &__card {
      max-height: 80px;
      max-width: 275px;
    }
  }

  .timer {
    text-align: right;
    color: $bb-gray-600;
    margin-right: 1em;

    &.red {
      color: var(--bb-danger);
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 30px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.step-card {
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(var(--bb-shadow-rgb), 0.3);
  border-radius: 10px;
  min-height: 600px;

  @media (max-width: 1800px) {
    min-height: 460px;
  }
}

.step-container {
  padding: 60px 20px;

  @media (max-width: 1800px) {
    padding: 40px 20px;
  }

  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  &--tall {
    padding-top: 120px;

    @media (max-width: 1800px) {
      padding-top: 80px;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 60px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 40px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 20px;
    }
  }
}

.step-header {
  margin-bottom: 60px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }

  &--short {
    margin-bottom: 40px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }
}

.step-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.step-actions {
  left: 0;
  width: 100%;
  display: flex;
  padding: 15px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.step-description {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    margin-top: 10px;
  }
}

.step-progress__container {
  position: relative;
  height: 11px;
  background: var(--bb-primary-light-2);
  border-radius: 9px 9px 0px 0px;
  overflow: hidden;
  border: 1px solid white;
  border-bottom: 0;
}

.step-progress__bar {
  position: absolute;
  width: 40%;
  left: 0;
  top: 0;
  bottom: 0;
  background: #7ae7b7;
  background: var(--bb-primary-light);
  border-radius: 0px 10px 10px 0px;
  transition: all 0.5s ease-in-out;
}

.step-progress__layout {
  position: absolute;
  top: -1px;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 8px;
  color: inherit;
  padding-right: 8px;
}

.step-progress__percent {
  font-weight: 600;
  margin-left: 6px;
}
</style>
