import AppBaseModel from '@/api/models/AppBaseModel.js';

class SSO extends AppBaseModel {
  constructor(options, item) {
    const mergedOptions = {
      ...options,
    };
    super(mergedOptions, item);
  }

  fetch(params = {}, config = {}) {
    return this.httpClient.get(`${this.entityUrl}/${this.adminApi ? 'sso' : 'company_sso'}`, {
      params,
      ...config,
    });
  }

  update(data, config) {
    return this.httpClient.patch(
      `${this.entityUrl}/${this.adminApi ? 'sso' : 'company_sso'}`,
      data,
      config,
    );
  }
}
export default SSO;
