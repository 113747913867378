<template lang="pug">
UiTooltip(
  :title="action.label"
  :disabled="disableTooltip"
  data-test-id="ui-tooltip"
)
  template(#activator)
    UiButton(v-bind="buttonProps")
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    action: {
      type: Object,
      required: true,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['mobileView']),
    buttonProps() {
      return {
        round: true,
        block: true,
        size: this.mobileView ? 'sm' : 'md',
        ...this.action,
        ...this.$attrs,
        variant: this.$attrs.variant ?? this.action.variant ?? 'ghost-secondary',
        'data-test-id': `action-${this.action.key}-button`,
      };
    },
  },
};
</script>
