const EntityTypeKey = {
  CompanyFile: 'company_files',
  DataroomForm: 'dataroom_forms',
  Folder: 'folders',
  PowerBiReport: 'power_bi_reports',
  PowerBiReportGroup: 'power_bi_report_groups',
  Root: 'root',
};

export default EntityTypeKey;
