const originalCompany = (state) => state.originalCompany;
const company = (state) => state.company;
const loading = (state) => state.loading;
const inProgress = (state) => state.inProgress;

export default {
  originalCompany,
  company,
  loading,
  inProgress,
};
