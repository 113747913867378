<template lang="pug">
.entity-access-list-item(:class="listItemClasses" @click="onClick")
  UiStack
    UiCheckbox(:modelValue="selected" :disabled="disabled") 
    UiStackItem(shrink)
      UserCard(v-if="listItem.user_id" v-bind="listItem")
      template(v-else) {{ listItem.name }}
    UiStackItem(grow)
      slot(v-bind="{ disabled }" name="after")
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],
  props: {
    listItem: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listItemClasses() {
      return {
        'entity-access-list-item--selected': this.selected,
        'entity-access-list-item--disabled': this.disabled,
      };
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
});
</script>

<style scoped lang="scss">
.entity-access-list-item {
  flex-shrink: 0;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 52px;
  border-left: 3px solid transparent;

  &:not(&--disabled) {
    cursor: pointer;
  }

  & + & {
    margin-top: 1px;
  }

  .ui-checkbox {
    pointer-events: none;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &--selected {
    border-color: var(--bb-primary);
    background: rgba(var(--bb-primary-rgb), 0.2);
  }

  &--disabled {
    opacity: 0.5;
  }
}
</style>
