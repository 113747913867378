import Folder from '@/models/folder.js';
import CompanyFile from '@/models/company-file.js';
import PowerBiReport from '@/models/power-bi-report.js';
import DataroomForm from '@/models/dataroom-form.js';

class ContentEntityService {}

ContentEntityService.contentEntitiesModels = {
  Folder,
  Root: Folder,
  CompanyFile,
  PowerBiReport,
  DataroomForm,
};

ContentEntityService.normalizeContentEntity = (entity) => {
  const ContentEntityModel = ContentEntityService.contentEntitiesModels[entity.type];
  if (!ContentEntityModel) {
    throw new Error('Unknown content entity');
  }
  return new ContentEntityModel(entity);
};

export default ContentEntityService;
