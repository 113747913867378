<template lang="pug">
.password-form
  .password-form__form
    h3 {{ $t('password_form.title') }}
    template(v-if="user")
      template(v-if="errorMessage")
        p.password-form__form__error {{ errorMessage }}
      .password-form__form__fields
        UiFormInput(
          v-model="user.password"
          type="password"
          :placeholder="$t('password_form.password')"
          :label="$t('password_form.password')"
          :vuelidateModel="v$.user.password"
        )
        UiFormInput(
          v-model="user.password_confirmation"
          type="password"
          :placeholder="$t('password_form.password_confirmation')"
          :label="$t('password_form.password_confirmation')"
          :vuelidateModel="v$.user.password_confirmation"
        )
        .password-form__form__submit.ui-form-field
          UiButton(
            :disabled="inProgress"
            :spinner="inProgress"
            @click="confirm"
          ) {{ $t('password_form.submit') }}
    template(v-else-if="failed")
      p.password-form__form__error {{ $t('password_form.error_reset') }}
      .password-form__form__submit.ui-form-field
        UiButton(@click="continueUsage") {{ $t('actions.continue') }}
    template(v-else-if="inProgress")
      UiSpinner(grow)
</template>
<script>
import ApiUsers from '@/api/user.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import { helpers, sameAs } from '@vuelidate/validators';

export default {
  name: 'RecoveryPassword',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      user: null,
      inProgress: false,
      failed: false,
      errorMessage: null,
    };
  },
  validations() {
    return {
      user: {
        password: { required },
        password_confirmation: {
          sameAs: helpers.withMessage(
            this.$t('validations.password_confirmation_does_not_match'),
            sameAs(this.user?.password),
          ),
        },
      },
    };
  },
  mounted() {
    this.initUserWithToken();
  },

  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    initUserWithToken() {
      const { token } = this.$route.query;
      if (!token) {
        this.failed = true;
        return;
      }
      this.user = {
        reset_password_token: token,
        password: '',
        password_confirmation: '',
      };
    },
    confirm() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.inProgress = true;

        ApiUsers.updatePassword({ user: this.user })
          .then((resp) => {
            if (resp.data.success) this.continueUsage();
          })
          .catch((e) => {
            if (e.response) this.errorMessage = e.response.data.errors[0];
            this.inProgress = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.password-form {
  &__form {
    background-color: var(--bb-app-bg-main);
    width: 523px;
    margin: auto;
    padding: 20px 28px;

    & p {
      font-size: 16px;
    }

    & h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__error {
      color: #c9432a;
      margin: 1rem 0;
    }

    &__submit:deep {
      display: flex;
      align-items: center;
      justify-content: center;

      .ui-button {
        width: 165px;
      }
    }
  }
}
</style>
