<template lang="pug">
UiBadge(size="sm") {{ $t(`user.role.${role}`) }}
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: null,
    },
  },
};
</script>
