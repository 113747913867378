<template lang="pug">
div
  TabHeader
    template(#title) {{ $t('user_groups.personal_access') }}
    template(#actions)
      UiStack(align="stretch" justify="stretch")
        UiStackItem(align="center") {{ $t(`user_groups.user_root_access_select_action_description`) }}
        UiMultiselect.w-165(
          v-model="entityAction"
          :options="accessOptions"
          :canClear="false"
          :canDeselect="false"
          :disabled="inProgress || error"
        )
        UiButton(
          :disabled="inProgress || error"
          data-vi="submit-btn"
          @click="submit"
        ) {{ $t('actions.save') }}
  UiCardSection
    UiDataTable(
      :headers="permissionsHeaders"
      :items="permissions"
      size="md"
      :striped="false"
      :loading="inProgress"
    )
      template(
        #[`header:${permission}`]="{ header }"
        v-for="permission in availablePermissionsColumns"
      )
        .text-center {{ header.label }}
      template(
        #[`cell:${permission}`]="{ item }"
        v-for="permission in availablePermissionsColumns"
      )
        UiStack(justify="center")
          UiStatusBoolean(
            v-if="item.availablePermissions.includes(permission) || inProgress"
            :value="getStatusValue(item, permission)"
          ) 
    UiEmptyState(
      v-if="inProgress"
      style="min-height: 14rem"
      :title="$t('rest-table.loading.title')"
      :description="$t('rest-table.loading.description')"
    )
  UiCardSection
    div(
      v-if="entityAction"
      v-html="$t(`user_groups.can_${entityAction}_description`)"
      data-vi="data-description"
    )
</template>

<script>
import EntityTypeKey from '@/helper/entity-type-key.js';
import Entities from '@/api/models/Entities.js';
import Users from '@/api/models/Users.js';
import Companies from '@/api/models/Companies.js';
import { mapGetters } from 'vuex';
import NoData from '@/components/DataTable/NoData.vue';
import CONTENT_ENTITY_ACCESS_ACTIONS from '@/helper/content-entity-access-actions';

const BASE_PERMISSIONS = ['manage', 'read', 'update', 'create', 'destroy'];
const REPORT_ADDITIONAL_PERMISSIONS = ['editable'];
const BASE_ENTITIES = {
  CompanyFile: BASE_PERMISSIONS,
  DataroomForm: BASE_PERMISSIONS,
  Folder: BASE_PERMISSIONS,
  PowerBiReport: [...BASE_PERMISSIONS, ...REPORT_ADDITIONAL_PERMISSIONS],
};

const ALL_PERMISSIONS = [...BASE_PERMISSIONS, ...REPORT_ADDITIONAL_PERMISSIONS];

const ENTITY_ACTION_NONE = 'none';

export default {
  components: {
    NoData,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      inProgress: false,
      privilegesMatrix: {},
      entityAction: ENTITY_ACTION_NONE,
    };
  },

  computed: {
    ...mapGetters('company', ['company', 'currentCompany']),
    ...mapGetters(['isAdminDomain']),

    availablePermissionsColumns() {
      return ALL_PERMISSIONS;
    },

    accessOptions() {
      return this.getNormalizedOptions([
        ...Object.values(CONTENT_ENTITY_ACCESS_ACTIONS),
        ENTITY_ACTION_NONE,
      ]);
    },

    switchColumnWidth() {
      return 70 / this.availablePermissionsColumns.length;
    },

    dynamicPermissionsHeaders() {
      return this.availablePermissionsColumns.map((key) => ({
        key,
        label: this.$te(`user_groups.permissions.${key}`)
          ? this.$t(`user_groups.permissions.${key}`)
          : key.charAt(0).toUpperCase() + key.slice(1),
        width: `${this.switchColumnWidth}%`,
        centred: true,
      }));
    },

    permissionsHeaders() {
      return [
        {
          key: 'name',
          label: '',
        },
        ...this.dynamicPermissionsHeaders,
      ];
    },

    permissions() {
      return Object.entries(BASE_ENTITIES).map(([entity, entityAvailablePermissions]) => ({
        entity,
        name: this.$t(`user_groups.entity_name.${EntityTypeKey[entity]}`),
        availablePermissions: entityAvailablePermissions,
        can:
          entityAvailablePermissions.filter((permissionKey) => {
            return this.privilegesMatrix[this.entityAction]?.[entity]?.includes(permissionKey);
          }) || [],
      }));
    },
    rootFolderId() {
      return this.isAdminDomain ? this.company.root_folder_id : this.currentCompany.root_folder_id;
    },
  },

  created() {
    this.initFetches();
  },

  methods: {
    async initFetches() {
      try {
        this.error = false;
        this.inProgress = true;
        await Promise.all([this.fetchUserPrivilegesMatrix(), this.fetchData()]);
      } catch {
        this.error = true;
      } finally {
        this.inProgress = false;
      }
    },
    getStatusValue(item, permission) {
      if (this.inProgress || this.error) return undefined;
      return (
        item.can.includes(permission) ||
        (item.can.includes('manage') && item.availablePermissions.includes(permission))
      );
    },
    async fetchData() {
      const response = await new Users(
        {
          adminApi: this.isAdminDomain,
          owner: new Entities(
            {
              owner: this.isAdminDomain
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            {
              id: this.rootFolderId,
            },
          ),
        },
        { id: this.user.id },
      ).fetch();
      this.entityAction =
        response.data.accessed_content_entity?.entity_action || ENTITY_ACTION_NONE;
    },
    async fetchUserPrivilegesMatrix() {
      const response = await new Entities(
        {
          owner: this.isAdminDomain
            ? new Companies(null, { id: this.$route.params.company_id })
            : null,

          adminApi: this.isAdminDomain,
        },
        {
          id: this.rootFolderId,
        },
      ).fetchUserPrivilegesMatrix();
      this.privilegesMatrix = response.data;
    },
    async submit() {
      try {
        const usersApiInstance = new Users(
          {
            owner: new Entities(
              {
                owner: this.isAdminDomain
                  ? new Companies(null, { id: this.$route.params.company_id })
                  : null,
              },
              {
                id: this.rootFolderId,
              },
            ),
            adminApi: this.isAdminDomain,

            singularKey: null,
          },
          { id: this.user.id },
        );
        if (this.entityAction === ENTITY_ACTION_NONE) {
          await usersApiInstance.delete();
        } else {
          await usersApiInstance.update({
            entity_action: this.entityAction,
          });
        }
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('user_groups.user_root_access_updated'),
          actions: null,
        });
      } catch (error) {
        this.$toaster.add({
          title: this.$t('status.error'),
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
    getNormalizedOptions(optionsValuesList) {
      return optionsValuesList.map((value) => ({
        value,
        label: this.$t(`user_groups.modals.entity_access_list.entity_actions.${value}`),
      }));
    },
  },
};
</script>
