<template lang="pug">
.admin-layout
  Heading(:title="$t('groups.title')")
    UiButton(:round="mobileView" @click="open")
      template(#icon)
        UiIcon(name="PlusFilled")
      | {{ $t('groups.btn_add_group') }}

  .table-container
    RestTable(
      ref="table"
      store-path="admin/companyGroups"
      namespace="companies"
      :headers="headers"
      :searchLabel="$t('groups.search')"
      searchable
    )
      template(#cel_name="{ item }")
        router-link(:to="{ name: 'EditGroups', params: { id: item.id } }") {{ item.name }}
      template(#cel_inviter="{ item }")
        Stack(
          v-if="item.inviter"
          align="center"
          spacing="sm"
        )
          StackItem
            AvatarBox(
              :src="item.inviter.avatar"
              :id="item.inviter.email"
              :label="item.inviter.name || item.inviter.email"
              size="sm"
            )
          StackItem
            | {{ item.inviter.name || item.inviter.email }}
        template(v-else) {{ item.inviter || '—' }}
      template(#cel_action="{ item }")
        .actions_container
          UiButton(
            :to="{ name: 'EditGroups', params: { id: item.id } }"
            variant="text-primary"
            icon="Edit"
          )
          UiButton.action_btn(
            icon="Trash"
            variant="text-danger"
            @click="deleteGroup(item.name, item.id)"
          )
CreateGroupModal(ref="createGroupModal" @wasCreated="refreshData")
DeleteGroupModal(ref="deleteGroupModal" @wasDeleted="refreshData")
</template>

<script>
import { mapGetters } from 'vuex';
import CreateGroupModal from '@/modals/Admin/Groups/Create.vue';
import DeleteGroupModal from '@/modals/Admin/Groups/Delete.vue';

export default {
  name: 'CompaniesGroup',
  components: {
    CreateGroupModal,
    DeleteGroupModal,
  },
  data() {
    return {
      headers: [
        {
          key: 'id',
          label: 'ID',
          squeeze: true,
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('groups.table.name'),
          sortable: true,
          width: '40%',
        },
        {
          key: 'inviter',
          label: this.$t('groups.table.users'),
        },
        {
          key: 'action',
          label: this.$t('groups.table.action'),
          squeeze: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
  },
  methods: {
    refreshData() {
      this.$refs.table.fetchData();
    },

    deleteGroup(name, id) {
      this.$refs.deleteGroupModal.open(name, id);
    },

    open() {
      this.$refs.createGroupModal.open();
    },
  },
};
</script>

<style scoped lang="scss">
.table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.actions_container {
  display: flex;
  align-items: center;
}

.action_btn {
  cursor: pointer;

  &:last-child {
    margin-left: 25px;
  }
}
</style>
