class InfinitePagination {
  constructor(p = {}) {
    this.sort_order = p.sortOrder || p.sort_order || null;
    this.sort_by = p.sortBy || p.sort_by || null;
    this.search = p.search || null;
    this.filter = p.filter || {};
  }
}

export default InfinitePagination;
