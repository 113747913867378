<template lang="pug">
.step-actions
  div
  UiButton(
    variant="ghost"
    icon="CloseIcon"
    data-test-id="button-cancel"
    @click="$emit('cancel')"
  ) {{ $t('actions.cancel') }}
.step-container
  .step-header
    .step-title {{ defaultTitle }}
    .step-description {{ $t('onboarding.choose_company.description') }}
  .form-container
    Stack(align="stretch" :vertical="mobileView")
      StackItem(fill)
        .company-select-container
          Stack(
            vertical
            align="stretch"
            spacing="xl"
          )
            StackItem
              UiMultiselect(
                v-model="companyId"
                value-prop="id"
                track-by="id"
                label="name"
                :options="companiesOptions"
                :placeholder="$t('onboarding.choose_company.choose_company_placeholder')"
                :disabled="inProgress"
                data-test-id="input-company-choose"
              )
                template(v-slot:option="{ option }")
                  div
                    div #[strong {{ option.name }}]
                    div #[small {{ option.business_id }}]
            StackItem(v-if="companyId" align="center")
              UiCheckbox(v-model="agreeTerms" data-test-id="input-checkbox-agree")
                i18n-t(keypath="onboarding.terms_and_condition_label.label")
                  a.link(
                    :href="termsLink"
                    target="_blank"
                    data-test-id="link-terms"
                  ) {{ $t('onboarding.terms_and_condition_label.link') }}
            StackItem(v-if="companyId")
              UiButton(
                :loading="inProgress"
                :disabled="!agreeTerms"
                block
                data-test-id="button-submit"
                @click="submit"
              ) {{ $t('onboarding.choose_company.action_connect') }}
      StackItem
        UiDivider(v-if="mobileView" data-test-id="ui-divider") {{ $t('onboarding.choose_company.divider_label') }}
        .or-label(v-else data-test-id="label") {{ $t('onboarding.choose_company.divider_label') }}
      StackItem
        UiButton(
          :disabled="inProgress"
          block
          data-test-id="button-create"
          @click="toCreateCompany"
        ) {{ $t('onboarding.choose_company.action_create_new') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import { mapGetters } from 'vuex';
import LocalStorage from '@/common/localstorage.js';
import OnboardingStep from '../OnboardingStep.js';

export default {
  emits: ['switch-company', 'to-step', 'update-attempt', 'progress'],
  props: ['defaultTitle', 'targetCompany', 'termsLink', 'attempt'],
  data() {
    return {
      inProgress: false,
      companyId: this.attempt.company_id,
      agreeTerms: false,
    };
  },
  computed: {
    ...mapGetters(['mobileView']),

    companiesOptions() {
      return this.attempt.companies;
    },
  },
  watch: {
    companyId(newVal) {
      this.$emit('switch-company', newVal);
    },
  },
  methods: {
    toCreateCompany() {
      this.$emit('to-step', OnboardingStep.CREATE_COMPANY);
    },
    toHome() {
      LocalStorage.remove('integration_attempt');
      this.$router.replace('/');
    },
    async submit() {
      if (!this.agreeTerms || this.inProgress) {
        return;
      }
      this.inProgress = true;
      let setCompanyResponse;
      try {
        setCompanyResponse = await new IntegrationAttempts(null, {
          id: this.attempt.id,
        }).setCompany(this.companyId);
        const newAttempt = setCompanyResponse.data.integration_attempt;
        this.$emit('update-attempt', newAttempt);
      } catch (error) {
        if (error.response?.data.error === 'no_have_admin_access') {
          this.$emit('progress', 85);
          this.$emit('to-step', OnboardingStep.NO_RIGHTS);
          return;
        }
        this.inProgress = false;
        return;
      }
      this.$emit('to-step', OnboardingStep.SUMMARY);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables.scss';

.link {
  text-decoration: underline;
}

.form-container {
  max-width: 560px;
  margin: 0 auto;
}

.or-label {
  display: flex;
  align-items: center;
  min-height: 46px;
}

.step-header {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}
</style>
