<template lang="pug">
.app-header(:class="classes")
  .app-header__wrapper
    .app-header__container
      .app-header__left
        RouterLink.app-header__app-logotype(v-if="!isAuthenticated" to="/")
          BibookLogotype
        .header__burger(v-if="mobileView && isAuthenticated" @click="$emit('openMobileMenu')")
          UiIcon(
            name="MenuBurger"
            size="2rem"
            @click="showMobileMenu"
          )
        CompanySwitcher(v-if="!mobileView")
      .app-header__middle
        CompanySwitcher(v-if="mobileView")
        #app-breadcrumbs(v-show="!mobileView")
      .app-header__right
        .app-header__user--return-back(v-if="isViewAsOtherUser")
          UiButton(
            icon="ArrowLongLeft"
            variant="outline-primary"
            @click="returnBack"
          ) {{ !mobileView ? $t('actions.exit-view-mode') : $t('exit') }}
        template(v-if="isAuthenticated")
          Stack(spacing="sm")
            StackItem(v-if="!isViewAsOtherUser")
              Notification
            StackItem
              HeaderProfile
        template(v-else-if="!isAuthenticated && isNotIntegrationPage")
          .app-header__auth--language-switcher.ml-3
            LanguageSwitcher
  transition(name="fade")
    .app-header__toggle(v-if="isAnalyticsPage && !mobileView")
      CollapseButton(
        vertical
        :active="!headerOpen"
        @click="toggle"
      )
</template>

<script>
import CollapseButton from '@/components/CollapseButton.vue';
import oauth2Providers from '@/common/login_providers.js';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import CompanySwitcher from '@/components/CompanySwitcher.vue';
import MiniProfile from '@/components/MiniProfile.vue';
import Notification from '@/components/Notification/Notification.vue';
import ANALYTICS_ROUTES from '@/helper/analiticsRoutes.js';
import { mapGetters } from 'vuex';
import ApiUsers from '@/api/user.js';
import HeaderProfile from './Header/HeaderProfile.vue';

export default {
  components: {
    MiniProfile,
    CompanySwitcher,
    LanguageSwitcher,
    Notification,
    HeaderProfile,
    CollapseButton,
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'user',
      'isViewAsOtherUser',
      'navOpen',
      'headerOpen',
      'mobileView',
      'isAdmin',
    ]),
    ...mapGetters('company', ['currentCompany', 'hasCompanies']),

    classes() {
      return {
        'app-header--collapsed': !this.headerOpen,
      };
    },

    headerWidthClasses() {
      if (!this.isAuthenticated) return 'header--fill';

      return this.navOpen ? 'header--short' : '';
    },

    oauth2Providers() {
      return oauth2Providers;
    },

    userRole() {
      if (this.isAdmin || !this.currentCompany) return '';
      return this.$t(`company.role.${this.currentCompany.role}`);
    },

    isAnalyticsPage() {
      return this.routesNameAnalitics.includes(this.$route.name);
    },

    isNotIntegrationPage() {
      return !this.$route.path.includes('sign-up-via-netvisor' || 'sign-up-via-pipedrive');
    },

    routesNameAnalitics() {
      return ANALYTICS_ROUTES;
    },
  },

  watch: {
    $route(val) {
      if (!this.routesNameAnalitics.includes(val.name) && !this.headerOpen) {
        this.$store.dispatch('updateHeaderOpen', true);
      }
    },
  },

  methods: {
    loginBasic() {
      return this.$router.push({ name: 'Login' });
    },

    login(link) {
      this.$store.dispatch('login', {
        oauth2: link.slug,
      });
    },

    logout() {
      this.$store.dispatch('logout');
    },

    returnBack() {
      ApiUsers.logoutAsOtherUser()
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toggle() {
      this.$store.dispatch('updateHeaderOpen', !this.headerOpen);
    },

    showMobileMenu() {
      this.$store.dispatch('updateNavMobileOpen', !this.navMobileOpen);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.app-header {
  position: relative;

  &__wrapper {
    overflow: hidden;
    background: var(--bb-app-bg-main);
    box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
      0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
  }

  &__container {
    height: var(--header-height);
    display: flex;
    align-items: center;
    transition: 230ms ease-out;
    padding: 0 2rem;
  }

  &--collapsed &__container {
    margin-top: calc(var(--header-height-collapsed) - var(--header-height));
  }

  &__left,
  &__middle,
  &__right {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &__left {
    flex-basis: 45%;
  }

  &__middle {
    flex-basis: 10%;
  }

  &__right {
    flex-basis: 45%;
    justify-content: flex-end;
  }

  &__toggle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
</style>
