<template lang="pug">
.admin-layout
  div(v-if="isReady")
    Heading(:title="$t('groups.edit.title')")
      Stack
        StackItem
          UiButton(
            :disabled="inProgress"
            :loading="inProgress"
            @click="saveGroup"
          ) {{ $t('groups.edit.btn_save') }}
        StackItem
          UiButton(
            :round="mobileView"
            :disabled="inProgress"
            icon="TrashIcon"
            variant="outline-danger"
            @click="handleDelete"
          ) {{ $t('groups.edit.btn_delete') }}

    .admin-layout__breadcrumps-container
      AppBreadcrumbs(:items="breadcrumbs" showBack)
    .groups-layout
      .input-wrap
        UiFormInput(
          v-model="form.name"
          :placeholder="$t('groups.edit.name')"
          :label="$t('groups.edit.name')"
          :vuelidate-model="v$.form.name"
        )
  DeleteGroupModal(ref="deleteGroupModal" @wasDeleted="toGroups")
  OverlayLoader(
    fixed
    :show="!isReady"
    :label="$t('groups.loading')"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import DeleteGroupModal from '@/modals/Admin/Groups/Delete.vue';
import UserSelect from '@/components/UserSelect.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import CompanyGroups from '@/api/models/CompanyGroups.js';

export default {
  name: 'CompaniesGroupEdit',
  components: {
    DeleteGroupModal,
    UserSelect,
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      errorToastId: null,
      isReady: false,
      group: null,
      inProgress: false,
      usersData: [],
      form: {
        name: '',
      },
      breadcrumbs: [
        {
          label: this.$t('groups.title'),
          to: '/admin/groups',
        },
        {
          label: this.$t('groups.edit.title'),
          to: this.$route.path,
        },
      ],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
    this.clearErrorToast();
  },
  methods: {
    clearErrorToast() {
      this.$toaster.close(this.errorToastId);
    },
    async fetchData() {
      this.clearErrorToast();
      try {
        const groupResponse = await new CompanyGroups(null, { id: this.$route.params.id }).fetch();
        this.form.name = groupResponse.data.company_group.name;
        this.isReady = true;
      } catch (error) {
        this.errorToastId = this.$toaster.add({
          type: 'error',
          timeout: null,
          message: this.$localizeErrorMessage(error),
          allowClose: false,
          actions: [
            {
              label: this.$t('actions.retry'),
              onClick: (close) => {
                close();
                this.fetchData();
              },
            },
          ],
        });
      }
    },

    saveGroup() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;

        this.inProgress = true;

        new CompanyGroups(null, { id: this.$route.params.id })
          .update(this.form)
          .then(() => this.toGroups())
          .catch((error) => {
            this.$toaster.add({
              type: 'error',
              message: this.$localizeErrorMessage(error),
            });
          })
          .finally(() => {
            this.inProgress = false;
          });
      });
    },

    toGroups() {
      this.$router.push('/admin/groups');
    },

    handleDelete() {
      this.$refs.deleteGroupModal.open(this.form.name, this.$route.params.id);
    },
  },
};
</script>

<style scoped lang="scss">
.groups-layout {
  background: var(--bb-app-bg-main);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
  display: flex;
}
.input-wrap {
  width: 400px;
  margin-right: 20px;

  h5 {
    padding-left: 10px;
  }
}
</style>
