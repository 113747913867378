import Pagination from '@/models/Pagination.js';
import EventsLog from '@/api/models/EventsLog.js';
import DateTimeService from '@/common/date_time_service.js';
import logAdminCategoriesOptionsRaw from '@/helper/logAdminCategoriesOptionsRaw.js';

export default {
  name: 'TabLogic',
  data() {
    return {
      items: [],
      meta: {
        total_count: 0,
      },
    };
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    updateFilter(options) {
      this.pagination = new Pagination({
        ...this.pagination,
        page: 1,
        filter: {
          ...this.pagination.filter,
          ...options,
        },
      });
      this.updateRouterHistory();
    },
    async fetchData() {
      const table = this.$route.query[`table_${this.namespace}`];
      if (table) {
        this.pagination = new Pagination(JSON.parse(table));
      }

      const { filter: filterOptions, ...paginationOption } = this.pagination;
      const params = { ...paginationOption, ...filterOptions };

      try {
        const response = await new EventsLog({ adminApi: true }).fetchAll({
          search: params.search,
          company_id: params.company?.id || this.defaultCompanyId,
          user_id: params.user?.id ?? null,
          event_category: params.event_category,
          event_name: params.event_name,
          time_start: params.time_start && DateTimeService.startOfDay(params.time_start),
          time_end: params.time_end && DateTimeService.endOfDay(params.time_end),
          page: params.page,
          per_page: params.per_page,
          sort_by: params.sort_by,
          sort_order: params.sort_order,
        });

        const { result, meta } = response.data;
        this.items = result.map((item) => {
          let event_category = logAdminCategoriesOptionsRaw.find(
            (category) => category.value === item.event_category,
          )?.label;
          try {
            event_category = this.$t(event_category);
          } catch {
            event_category = item.event_category.replaceAll('Admin:', '').replaceAll(':', ' ');
          }
          return {
            ...item,
            event_category,
          };
        });
        this.meta = meta;
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: `Error - ${error.response?.status}`,
          message: this.$localizeErrorMessage(error),
        });
      }
    },
    onPaginate(options) {
      this.updateOptions({
        page: options.page,
        per_page: options.perPage,
        search: options.search,
      });
    },
    updateOptions(options) {
      this.pagination = new Pagination({
        ...this.pagination,
        ...options,
      });
      this.updateRouterHistory();
    },
    updateRouterHistory() {
      this.$router.push({
        query: {
          ...this.$route.query,
          [`table_${this.namespace}`]: JSON.stringify({ ...this.pagination }),
        },
      });
    },
  },
};
