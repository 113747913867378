import _get from 'lodash/get.js';

const UserRoles = {
  USER: 'user',
  SERVICE_ADMIN: 'service_admin',
  GLOBAL_ADMIN: 'global_admin',
};

class User {
  constructor(p = {}) {
    this.id = _get(p, 'id', null);
    this.user_id = _get(p, 'user_id', undefined);
    this.email = _get(p, 'email', null);
    this.name = _get(p, 'name', '');
    this.avatar = _get(p, 'avatar', null);
    this.language = _get(p, 'language', 'en');
    this.report_language = _get(p, 'report_language', null);
    this.role = _get(p, 'role', null);
    this.receive_notifications = _get(p, 'receive_notifications', false);
    this.inviter_id = _get(p, 'inviter_id', null);
    this.agreement_sign_required = _get(p, 'agreement_sign_required', null);
    this.integration_required = _get(p, 'integration_required', null);
    if (this.integration_required) {
      this.current_integration_attempt = _get(p, 'current_integration_attempt', null);
    }
    if (this.current_integration_attempt) {
      this.new_user_integration = _get(p, 'new_user_integration', null);
    }
    this.integration_signed = _get(p, 'integration_signed', false);
    this.enable_odata_access = _get(p, 'enable_odata_access', false);
  }
}

User.admin_roles = [UserRoles.SERVICE_ADMIN, UserRoles.GLOBAL_ADMIN];

User.isRegularUser = (role) => role === UserRoles.USER;

User.isServicelAdmin = (role) => role === UserRoles.SERVICE_ADMIN;

User.isGlobalAdmin = (role) => role === UserRoles.GLOBAL_ADMIN;

User.isAnyAdmin = (role) => User.admin_roles.includes(role);

export default User;
