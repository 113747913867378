import locales from '@/localization/locales.js';
import { filteredOauth2Providers } from '@/common/login_providers.js';
import { GLOBAL_ADMIN_ROLE, SERVICE_ADMIN_ROLE } from '@/helper/roles.js';

const appReady = (state) => state.appReady;
const isAuthenticated = (state) => !!state.user;
const user = (state) => state.user;
const language = (state) => state.language;
const currentLanguage = (state) => locales.find((l) => l.value === state.language) || locales[0];
const viewAsToken = (state) => state.viewAsToken;
const isViewAsOtherUser = (state) => !!state.viewAsToken;
const integrationCompany = (state) => state.integrationCompany;
const navOpen = (state) => state.navOpen;
const navMobileOpen = (state) => state.navMobileOpen;
const headerOpen = (state) => state.headerOpen;
const mobileView = (state) => state.mobileView;
const tabletView = (state) => !state.mobileView && !state.laptopView;
const laptopView = (state) => state.laptopView;
const basicLoginEnabled = (state) => state.email_login_enabled;
const oauthGoogleLoginEnabled = (state) => state.google_login_enabled;
const oauthMicrosoftLoginEnabled = (state) => state.microsoft_login_enabled;
const SSOLoginEnabled = (state) => state.sso_login_enabled;
const SSOLoginMethod = (state) => state.sso_login_method;
const userRole = (state) => state.user?.role;
const companyRoles = (state) => state.company_roles;
const isGlobalAdmin = (state) => state.user?.role === GLOBAL_ADMIN_ROLE;
const isServiceAdmin = (state) => state.user?.role === SERVICE_ADMIN_ROLE;
const appDomain = (state) => state.appDomain;
const cnameDomain = (state) => state.cnameDomain;
const adminDomain = (state) => `admin.${state.appDomain}`;
const isAdminDomain = (state) => adminDomain(state) === window.location.host;
const isAdminHost = (state) => {
  console.warn('Getter `isAdminHost` is deprecated, use `isAdminDomain` instead');
  return isAdminDomain(state);
};
const isRootDomain = (state) => appDomain(state) === window.location.host;
const defaultCompany = (state) => state.defaultCompany;
const capacityRelatedErrors = (state) => state.capacityRelatedErrors;
const isAdmin = (state) =>
  state.user?.role === 'service_admin' || state.user?.role === 'global_admin';
const termsPage = (state) => state.terms_page;
const reportThemeInstruction = (state) => state.report_theme_instruction_url;
const externalLoginPage = (state) => state.externalLoginPage;
const freshChatToken = (state) => state.freshChatToken;
const companiesCount = (state) => state.companiesCount;
const supportEmail = (state) => state.supportEmail;
const SSOLoginUrl = (state) =>
  state.sso_login_method && state.sso_login_method !== 'none'
    ? `/auth/${state.sso_login_method}`
    : null;
const oAuthRedirectArr = (state, getters) => {
  const redirectArr = [];

  if (getters.SSOLoginEnabled && getters.SSOLoginUrl) {
    redirectArr.push(getters.SSOLoginUrl);
  }

  redirectArr.push(
    ...filteredOauth2Providers(
      getters.oauthGoogleLoginEnabled,
      getters.oauthMicrosoftLoginEnabled,
    ).map((p) => p.url),
  );

  return redirectArr;
};

const dynamicSettingsMaxCount = (state) => state.dynamicSettingsMaxCount;

export default {
  appReady,
  isAuthenticated,
  user,
  language,
  currentLanguage,
  viewAsToken,
  isViewAsOtherUser,
  integrationCompany,
  navOpen,
  navMobileOpen,
  headerOpen,
  mobileView,
  tabletView,
  laptopView,
  basicLoginEnabled,
  oauthGoogleLoginEnabled,
  oauthMicrosoftLoginEnabled,
  SSOLoginEnabled,
  SSOLoginMethod,
  SSOLoginUrl,
  supportEmail,
  userRole,
  companyRoles,
  isGlobalAdmin,
  isServiceAdmin,
  appDomain,
  isAdminHost,
  isAdmin,
  defaultCompany,
  capacityRelatedErrors,
  termsPage,
  cnameDomain,
  reportThemeInstruction,
  adminDomain,
  externalLoginPage,
  freshChatToken,
  isRootDomain,
  isAdminDomain,
  companiesCount,
  oAuthRedirectArr,
  dynamicSettingsMaxCount,
};
