const SET_USERS = (state, value) => {
  state.items = value;
};

const SET_META = (state, value) => {
  state.meta = value;
};

const SET_IS_READY = (state, value) => {
  state.isReady = value;
};

export default {
  SET_USERS,
  SET_META,
  SET_IS_READY,
};
