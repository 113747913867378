<template lang="pug">
.page-container(v-if="!loading && report")
  AppBreadcrumbs(:items="breadcrumbs")
  .page-header
    Stack(align="end")
      StackItem(grow)
        .page-title {{ report.name }}
        .page-subtitle {{ $t('report_embedded_links.embedded_links') }}
      UiButton(icon="Plus" @click="openAddReportEmbeddedLinkModal") {{ $t('report_embedded_links.action_add_new') }}
  .page-content
    UiCard
      UiCardSection
      UiRestTable(
        ref="table"
        namespace="links"
        :headers="headers"
        :fetchHandler="fetchReportLinks"
        searchable
      )
        template(#cell:bookmark="{ item }")
          UiTooltip(
            v-if="item.target_type === 'Bookmark'"
            :title="$t('report_embedded_links.is_a_bookmark_label')"
          )
            template(#activator)
              UiIcon.is-bookmark(name="Bookmarks")
        template(#cell:publicly_available="{ item }")
          ReportEmbeddedLinkTypeBadge(:item="item")
        template(#cell:shared_by="{ item }")
          UserCard(
            :avatar="item.shared_by?.avatar"
            :name="item.shared_by?.name"
            :email="item.shared_by?.email"
          )
        template(#cell:access_period="{ item }")
          | {{ item.access_period ? $d(item.access_period) : '-' }}
        template(#cell:active="{ item }")
          UiCheckbox(
            switch
            :modelValue="item.active"
            @change="() => openToggleReportEmbeddedLinkModal(item)"
          )
        template(#cell:actions="{ item }")
          Stack
            UiButton(
              variant="smooth-primary"
              size="sm"
              icon="Link"
              @click="openCopyReportEmbeddedLinkModal(item)"
            ) {{ $t('actions.get_link') }}
            DataTableRowActions(:actions="itemActions(item)")
      UiCardSection
  AddReportEmbeddedLinkModal(ref="addReportEmbeddedLinkModal" @done="fetchList")
  ToggleReportEmbeddedLinkModal(ref="toggleReportEmbeddedLinkModal" @done="fetchList")
  DeleteReportEmbeddedLinkModal(ref="deleteReportEmbeddedLinkModal" @done="fetchList")
  CopyReportEmbeddedLinkModal(ref="copyReportEmbeddedLinkModal")
</template>

<script>
import Analytics from '@/api/models/Analytics.js';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import PowerBiEmbeddedLinks from '@/api/models/PowerBiEmbeddedLinks.js';
import UserCard from '@/components/UserCard.vue';
import AddReportEmbeddedLinkModal from '@/modals/Reports/add-report-embedded-link.vue';
import ToggleReportEmbeddedLinkModal from '@/modals/Reports/toggle-report-embedded-link.vue';
import DeleteReportEmbeddedLinkModal from '@/modals/Reports/delete-report-embedded-link.vue';
import CopyReportEmbeddedLinkModal from '@/modals/Reports/copy-report-embedded-link.vue';
import ReportEmbeddedLinkTypeBadge from './components/report-embedded-link-type-badge.vue';

export default {
  name: 'ReportEmbeddedLinksView',
  components: {
    ReportEmbeddedLinkTypeBadge,
    AddReportEmbeddedLinkModal,
    ToggleReportEmbeddedLinkModal,
    DeleteReportEmbeddedLinkModal,
    CopyReportEmbeddedLinkModal,
    UserCard,
  },
  data() {
    return {
      loading: true,
      report: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: this.$t('menu.content'),
          to: { name: 'ContentIndex' },
        },
        {
          label: this.report.name,
          to: { name: 'AnalyticsReport', params: { id: this.report.id } },
        },
        {
          label: this.$t('report_embedded_links.embedded_links'),
        },
      ];
    },
    headers() {
      return [
        {
          key: 'bookmark',
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('report_embedded_links.table.name'),
          sortable: true,
        },
        {
          key: 'publicly_available',
          label: this.$t('report_embedded_links.table.type'),
          sortable: true,
        },
        {
          key: 'shared_by',
          label: this.$t('report_embedded_links.table.shared_by'),
          sortable: true,
        },
        {
          key: 'access_period',
          label: this.$t('report_embedded_links.table.expired_date'),
          sortable: true,
        },
        {
          key: 'active',
          label: this.$t('report_embedded_links.table.active'),
          sortable: true,
        },
        {
          key: 'actions',
          squeeze: true,
        },
      ];
    },
  },

  mounted() {
    this.fetchReport();
  },

  methods: {
    async fetchReport() {
      try {
        this.loading = true;
        const response = await new Analytics(null, { id: this.$route.params.id }).fetch();
        this.report = response.data.power_bi_report;
      } finally {
        this.loading = false;
      }
    },
    async fetchReportLinks(params) {
      const result = {
        items: [],
        meta: null,
      };
      try {
        const response = await new PowerBiEmbeddedLinks({
          owner: new PowerBiReports(null, { id: this.$route.params.id }),
        }).fetchAll(params);
        result.items = response.data.power_bi_embedded_links;
        result.meta = response.data.meta;
        return result;
      } catch {
        return result;
      }
    },
    fetchList() {
      this.$refs.table.fetchData();
    },
    itemActions(item) {
      return [
        {
          label: this.$t('actions.delete'),
          icon: 'Trash',
          variant: 'danger',
          onClick: () => this.openDeleteReportEmbeddedLinkModal(item),
        },
      ];
    },
    openAddReportEmbeddedLinkModal() {
      this.$refs.addReportEmbeddedLinkModal.open({
        powerBiReport: this.report,
        powerBiReportId: this.$route.params.id,
      });
    },
    openToggleReportEmbeddedLinkModal(item) {
      this.$refs.toggleReportEmbeddedLinkModal.open({
        powerBiReportId: this.$route.params.id,
        powerBiReportEmbeddedLink: item,
      });
    },
    openDeleteReportEmbeddedLinkModal(item) {
      this.$refs.deleteReportEmbeddedLinkModal.open({
        powerBiReportId: this.$route.params.id,
        powerBiReportEmbeddedLink: item,
      });
    },
    openEditReportEmbeddedLinkModal(item) {
      this.$refs.addReportEmbeddedLinkModal.open({
        powerBiReportId: this.$route.params.id,
        powerBiReportEmbeddedLink: item,
      });
    },
    openCopyReportEmbeddedLinkModal(item) {
      this.$refs.copyReportEmbeddedLinkModal.open({
        item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  min-width: 260px;
}
.is-bookmark {
  color: var(--bb-gray-500);
}

.page-container {
  padding: 2rem;
  padding-bottom: 10rem;
}

.page-header {
  margin-bottom: 1.25rem;
}

.page-title {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
}

.page-subtitle {
  line-height: 1.2;
  font-size: 1.2rem;
}

.page-title + .page-subtitle {
  margin-top: 0.5rem;
}
</style>
