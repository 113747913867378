<template lang="pug">
UiCardSection(padding="lg")
  Stack(vertical spacing="lg")
    StackItem(v-for="key in basicFields")
      UiCheckbox(
        switch
        :modelValue="!!company[key]"
        data-test-id="access-switcher"
        @change="change({ [key]: !company[key] })"
      )
        .label-title(data-test-id="access-title") {{ $t(`edit_company.access.${key}_title`) }}
  UiCardSection(padding="md")
  Stack
    .label-title {{ $t(`edit_company.access.authentication_title`) }}
    StackItem(v-for="key in authenticationFields")
      UiCheckbox(:modelValue="!!company[key]" @change="change({ [key]: !company[key] })") {{ $t(`edit_company.access.${key}_label`) }}
  UiCardSection(padding="sm")
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('editCompany', ['company']),

    basicFields() {
      return [
        'hidden',
        'show_api_keys',
        'show_backend_api_key',
        'analytics_enabled',
        'analytics_premium_enabled',
        'files_enabled',
        'dataroom_enabled',
        'dataroom_api_enabled',
        'extract_api_enabled',
        'sso_enabled',
        'private_embedded_links_enabled',
        'public_embedded_links_enabled',
        'export_report_enabled',
      ];
    },

    authenticationFields() {
      return ['google_login_enabled', 'microsoft_login_enabled', 'email_login_enabled'];
    },
  },

  methods: {
    ...mapMutations('editCompany', ['UPDATE_COMPANY']),

    change(patch) {
      this.UPDATE_COMPANY(patch);
    },
  },
};
</script>

<style scoped lang="scss">
.label-title {
  font-weight: 600;
}
</style>
