<template lang="pug">
DataTableRowActions(:actions="actions" :moreActions="moreActions")

// TODO: move modals to higher level
EditFileModal(ref="editFileModal" @done="$emit('reload')")
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '@/modals/common/Confirm.vue';
import EditFileModal from '@/modals/Files/EditFile.vue';
import submitPostViaHiddenForm from '@/common/submit-post-via-hidden-form.js';
import Company from '@/models/Company.js';

export default {
  components: {
    ConfirmModal,
    EditFileModal,
  },

  emits: ['manageAccess', 'reload', 'delete'],

  props: {
    entity: {
      type: Object,
      required: true,
    },
    folderRootId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany', 'currentCompanyId']),

    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },

    isEditable() {
      return (
        this.entity.entity_access.update && this.entity.data.validated && !this.isViewAsOtherUser
      );
    },

    isReplaceable() {
      return (
        this.entity.entity_access.update &&
        this.entity.extras.replace_allowed &&
        this.entity.data.validated &&
        !this.isViewAsOtherUser
      );
    },

    isDeletable() {
      return (
        this.entity.entity_access.destroy &&
        !this.entity.extras.locked &&
        this.entity.data.validated &&
        !this.isViewAsOtherUser
      );
    },

    actions() {
      const actions = [];

      if (!this.isViewAsOtherUser && this.entity.data.validated) {
        actions.push({
          icon: 'FileLoadIcon',
          label: this.$t('actions.download_file'),
          onClick: this.downloadFile,
        });
      }

      if (this.isAdmin && !this.isViewAsOtherUser) {
        actions.push({
          icon: 'TwoUsers',
          label: this.$t('actions.manage_access'),
          onClick: () => this.$emit('manageAccess'),
        });
      }

      return actions;
    },

    moreActions() {
      const actions = [];

      if (this.isEditable) {
        actions.push({
          icon: 'PencilCreate',
          label: this.$t('actions.edit'),
          onClick: this.editFile,
        });
      }

      if (this.isReplaceable) {
        actions.push({
          icon: 'FilesReplace',
          label: this.$t('actions.replace'),
          onClick: this.replaceFile,
        });
      }

      if (this.isDeletable) {
        actions.push({
          icon: 'Trash',
          label: this.$t('actions.delete'),
          onClick: () => this.$emit('delete'),
          variant: 'danger',
        });
      }

      return actions;
    },
  },

  methods: {
    downloadFile() {
      submitPostViaHiddenForm('/api/v1/company_files/download', {
        token: this.entity.id,
        company_id: this.currentCompanyId,
      });
    },

    editFile() {
      this.$refs.editFileModal.open(this.entity, 'edit');
    },

    replaceFile() {
      this.$refs.editFileModal.open(this.entity, 'replace');
    },
  },
};
</script>
