<template lang="pug">
UiEmptyState
  template(#icon)
    UiStack(justify="center")
      UiSpinner(v-if="statusInProgress" :speed="spinnerSpeed")
  template(#description)
    i18n-t(:keypath="bodyTextKey")
      template(#support_email)
        a(:href="`mailto:${supportEmail}`") {{ supportEmail }}
  template(#actions)
    UiButton(
      v-if="showRefresh"
      data-vi="refresh-btn"
      @click="refreshPage"
    ) {{ $t('actions.refresh') }}
</template>

<script>
import Analytics from '@/api/models/Analytics';
import { mapGetters } from 'vuex';

const STATE_IN_PROGRESS = 'in_progress';
const STATE_IN_PROGRESS_WAIT = 'in_progress_wait';
const STATE_ERROR = 'error';
const STATE_DONE = 'done';

export const RETRY_DELAY = 5000; // ms
const MAX_TRIAL_RUNS = 10;

export default {
  props: {
    error: { type: Object, required: true },
    reportId: { type: String, required: true },
  },
  emits: ['done'],
  data() {
    return {
      state: STATE_IN_PROGRESS,
      numberTrialRuns: 1,
    };
  },
  computed: {
    ...mapGetters(['supportEmail']),
    showRefresh() {
      return [STATE_ERROR, STATE_DONE].includes(this.state);
    },
    spinnerSpeed() {
      if (this.state === STATE_IN_PROGRESS_WAIT) return 'ultra-slow';
      return 'normal';
    },
    statusInProgress() {
      return [STATE_IN_PROGRESS, STATE_IN_PROGRESS_WAIT].includes(this.state);
    },
    bodyTextKey() {
      return `report-capacity-issue-modal.message.${this.state}`;
    },
  },
  mounted() {
    this.handleFixIssue();
  },
  methods: {
    refreshPage() {
      this.$router.go();
    },
    waitAndRetry() {
      this.state = STATE_IN_PROGRESS_WAIT;
      setTimeout(() => {
        this.numberTrialRuns++;
        this.handleFixIssue();
      }, RETRY_DELAY);
    },
    async handleFixIssue() {
      this.state = STATE_IN_PROGRESS;
      try {
        const result = await new Analytics(null, { id: this.reportId }).increaseCapacity(
          this.error.detail,
        );

        let body = result.data.response?.body;

        if (typeof result.data.response?.body === 'string') {
          try {
            body = JSON.parse(result.data.response.body);
          } catch {
            // do nothing
          }
        }

        if (body?.success) {
          this.state = STATE_DONE;
        } else {
          this.state = STATE_ERROR;
        }
      } catch (error) {
        console.error(error);
        if (this.numberTrialRuns < MAX_TRIAL_RUNS) {
          this.waitAndRetry();
        } else {
          this.state = STATE_ERROR;
        }
      }
    },
  },
};
</script>
