import LocalStorage from '@/common/localstorage.js';
import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

const state = {
  appReady: false,
  language: LocalStorage.get('language', 'en', String),
  user: null,
  viewAsToken: null,
  integrationCompany: JSON.parse(localStorage.getItem('integration_company_data')) || null,
  navOpen: LocalStorage.get('navOpen', true),
  navMobileOpen: false,
  headerOpen: true,
  restoreNavigation: {
    headerOpen: true,
    navOpen: true,
  },
  mobileView: false,
  laptopView: true,
  sso_login_enabled: false,
  sso_login_method: 'none',
  email_login_enabled: false,
  microsoft_login_enabled: false,
  google_login_enabled: false,
  externalLoginPage: '',
  selectedCompanyId: null,
  selectedReportId: null,
  company_roles: null,
  supportEmail: null,
  appDomain: null,
  cnameDomain: null,
  terms_page: '',
  defaultCompany: false,
  capacityRelatedErrors: '',
  companiesCount: null,
  dynamicSettings: [],
  chaskiq_url: null,
  chaskiq_settings: null,
  dynamicSettingsMaxCount: 20,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
