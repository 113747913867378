<template lang="pug">
modal(
  :title="`${currentCompany.name} ${$t('company.integrations.integration_logs')}`"
  :show="show"
  :close="close"
  width="700px"
)
  template(#body)
    template(v-if="!records?.length")
      .py-5.text-center
        .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
        .text-secondary {{ $t('rest.empty') }}
    template(v-else)
      .log-item(v-for="(record, index) in records" :key="index")
        Stack
          StackItem(align="start")
            p.mb-0
              strong {{ new Date(record['Timestamp']).toUTCString() }}
          StackItem(fill)
          StackItem
            p.log-message(v-html="record['Event']")
</template>

<script>
import { mapGetters } from 'vuex';
import Integrations from '@/api/models/Integrations.js';

export default {
  name: 'CompanyIntegrationLogsModal',
  data() {
    return {
      show: false,
      inProgress: false,
      records: [],
    };
  },
  computed: {
    ...mapGetters('company', ['company']),

    currentCompany() {
      return this.company || { name: 'Unset' };
    },
  },
  methods: {
    fetchData() {
      new Integrations()
        .fetchLogs()
        .then((resp) => {
          this.records = resp.data.records;
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        });
    },

    open() {
      this.records = [];
      this.fetchData();
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.log-message {
  overflow-wrap: break-word !important;
}

.log-modal {
  width: 66% !important;
  min-width: 66% !important;
  max-width: 66% !important;
  &.modal-content {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
