<template lang="pug">
UiCardSection(padding="lg")
  .tab-container__row(v-show="!loading")
    .tab-container__column.tab-container__column-form
      .colors-form
        Stack(vertical align="stretch")
          StackItem
            UiText(variant="headingMd" data-test-id="template-title") {{ $t('portal_management.ui_themes.app_color_scheme') }}
          StackItem
            UiTabs(
              :tabs="tabs"
              :selected="activeTab"
              fullWidth
              skin="light"
              data-test-id="template-tabs"
              @select="selectTab"
            )
          StackItem(v-if="activeTab === ThemeTab.TEMPLATE")
            UiInput(
              v-model="search"
              icon="Search"
              :placeholder="$t('portal_management.ui_themes.search_theme_placeholder')"
              data-test-id="template-input-search"
            )
              template(#icon)
                UiIcon(name="Search" data-test-id="template-search-icon")
              template(#iconAfter v-if="search")
                UiIcon(
                  name="Close"
                  data-test-id="template-close-icon"
                  @click="search = ''"
                )
          StackItem(v-if="activeTab === ThemeTab.TEMPLATE")
            .theme-items
              .theme-item-wrapper(
                v-for="theme in filteredThemesList"
                :key="theme.id"
                :class="{ 'theme-item--active': theme.id === selectedThemeId }"
                data-test-id="template-theme-item"
                @click="selectTheme(theme)"
              )
                .theme-item
                  .theme-item__check
                    UiCheckbox(
                      :modelValue="theme.id === selectedThemeId"
                      data-test-id="template-checkbox"
                    )
                  .theme-item__body
                    .theme-item__name(data-test-id="template-theme-name") {{ theme.name }}
                    .theme-item__wrapper-colors
                      .theme-item__colors
                        .color-preview(
                          v-for="(defaultColor, key) in baseColors"
                          :style="{ backgroundColor: theme.colors[key] || defaultColor }"
                          data-test-id="template-base-color-preview"
                        )
                      .theme-item-analytics
                        .theme-item-analytics__title(data-test-id="template-analytics-name") {{ $t('portal_management.ui_themes.analytics_report_colors') }}
                        .theme-item__colors
                          .color-preview(
                            v-for="(analyticColor, key) in analyticsColors"
                            :style="{ backgroundColor: theme.colors[key] || analyticColor }"
                            data-test-id="template-analytics-color-preview"
                          )
          template(v-else)
            StackItem
              UiFormInput(
                v-model="form.name"
                :label="$t('portal_management.ui_themes.theme_name')"
                :vuelidateModel="v$.form.name"
                data-test-id="custom-input-color-name"
              )
              br
              p(data-test-id="custom-description") {{ $t('portal_management.ui_themes.select.custom_description') }}
              .custom-color(v-for="color in customColors")
                .custom-color__swatch
                  ColorPicker(
                    :color="this.form.colors[color.key]"
                    data-test-id="custom-color-picker"
                    @change="setFormColors({ [color.key]: $event })"
                    @cancel="setFormColors({ [color.key]: $event })"
                  )
                    template(#activator="{ color }")
                      .color-preview.color-preview--lg(
                        :style="{ backgroundColor: color }"
                        data-test-id="custom-color-background"
                      )
                .custom-color__details
                  .custom-color__name(data-test-id="custom-color-name") {{ color.name }}
                  .custom-color__description(data-test-id="custom-color-description") {{ color.description }}
            StackItem
              .themes-section-header.themes-section-header__analytics
                UiText(variant="headingMd" data-test-id="custom-title") {{ $t('portal_management.ui_themes.analytics_report_colors') }}
            StackItem
              .custom-color(v-for="color in customAnalyticsColors")
                .custom-color__swatch
                  ColorPicker(
                    :color="this.form.colors[color.key]"
                    data-test-id="custom-analytics-color"
                    @change="setFormColors({ [color.key]: $event })"
                    @cancel="setFormColors({ [color.key]: $event })"
                  )
                    template(#activator="{ color }")
                      .color-preview.color-preview--lg(
                        :style="{ backgroundColor: color }"
                        data-test-id="custom-analytics-color-background"
                      )
                .custom-color__details
                  .custom-color__name(data-test-id="custom-analytics-color-name") {{ color.name }}
                  .custom-color__description(data-test-id="custom-analytics-description") {{ color.description }}
    .tab-container__column.tab-container__column-preview
      .container-preview
        Stack(vertical align="stretch")
          StackItem
            UiText(variant="headingMd" data-test-id="preview-title") {{ $t('portal_management.ui_themes.theme_preview') }}
          StackItem
            UiCard.theme-preview-card
              ThemePreview(ref="themePreview" data-test-id="preview-theme")
              ThemeGenerator(
                v-if="target"
                :target="target"
                :colors="previewColors"
                data-test-id="theme-generator"
              )
          StackItem
            UiText(variant="headingMd" data-test-id="preview-analytics-title") {{ $t('portal_management.ui_themes.analytics_preview') }}
          StackItem
            UiCard.theme-preview-card
              AnalyticsThemePreview(ref="analyticsThemePreview" data-test-id="preview-analytics")
              ThemeGenerator(
                v-if="targetAnalytics"
                :target="targetAnalytics"
                :colors="previewColors"
                data-test-id="theme-analytics-generator"
              )
          StackItem
            UiButton.w-165(
              :disabled="inProgress"
              :loading="inProgress"
              data-test-id="preview-button-save"
              @click="confirmApplyTheme"
            ) {{ $t(activeTab === ThemeTab.TEMPLATE ? 'edit_company.ui_themes.action_apply_theme' : 'edit_company.ui_themes.action_save_theme') }}
  ConfirmApplyThemeModal(ref="confirmApplyThemeModal" @confirm="applyTheme")
</template>

<script>
import ThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/index.vue';
import ThemeGenerator from '@/components/ThemeGenerator/ThemeGenerator.vue';
import ConfirmModal from '@/modals/common/Confirm.vue';
import { DEFAULT_THEME_COLORS, ANALYTICS_COLORS, BASE_COLORS } from '@/helper/ui-themes.js';
import UiThemes from '@/api/models/UiThemes.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import ColorPicker from '@/components/ColorPicker.vue';
import escapeRegExp from '@/common/escape_reg_exp.js';
import ConfirmApplyThemeModal from '@/modals/UiThemes/confirm-apply-theme-modal.vue';
import AnalyticsThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/preview-analytics.vue';

const ThemeTab = {
  TEMPLATE: 'template',
  CUSTOM: 'custom',
};

function getThemeHash(theme) {
  const colors = Object.keys(DEFAULT_THEME_COLORS).map(
    (key) => theme.colors[key] ?? DEFAULT_THEME_COLORS[key],
  );
  return `${theme.name}:${colors.join(',')}`;
}

export default {
  components: {
    AnalyticsThemePreview,
    ThemeGenerator,
    ThemePreview,
    ColorPicker,
    ConfirmModal,
    ConfirmApplyThemeModal,
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      loading: true,
      inProgress: false,
      activeTab: ThemeTab.TEMPLATE,
      themes: [],
      customTheme: null,
      search: '',
      form: {
        name: '',
        colors: { ...DEFAULT_THEME_COLORS },
      },
      selectedThemeId: 'default',
      target: null,
      targetAnalytics: null,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('editCompany', ['company']),
    ...mapGetters('company', ['currentCompany']),

    ThemeTab() {
      return ThemeTab;
    },
    tabs() {
      return [
        {
          id: ThemeTab.TEMPLATE,
          label: this.$t('portal_management.ui_themes.templates'),
        },
        {
          id: ThemeTab.CUSTOM,
          label: this.$t('portal_management.ui_themes.custom'),
        },
      ];
    },
    defaultTheme() {
      return {
        id: 'default',
        name: 'Default',
        colors: DEFAULT_THEME_COLORS,
      };
    },
    filteredThemesList() {
      const regExp = new RegExp(escapeRegExp(this.search), 'i');
      return this.themesList.filter((t) => t.name.match(regExp));
    },
    themesList() {
      const result = [];
      if (this.customTheme && !this.customThemeClone) {
        result.push(this.customTheme);
      }
      result.push(this.defaultTheme, ...this.themes);
      return result;
    },
    selectedTheme() {
      return this.themesList.find((t) => t.id === this.selectedThemeId);
    },
    previewColors() {
      return this.form.colors ?? DEFAULT_THEME_COLORS;
    },
    customColors() {
      return [
        {
          name: this.$t('portal_management.ui_themes.colors.accent_color'),
          description: this.$t('portal_management.ui_themes.colors.accent_color_desc'),
          key: 'primary',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.success_color'),
          description: this.$t('portal_management.ui_themes.colors.success_color_desc'),
          key: 'success',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.warning_color'),
          description: this.$t('portal_management.ui_themes.colors.warning_color_desc'),
          key: 'warning',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.danger_color'),
          description: this.$t('portal_management.ui_themes.colors.danger_color_desc'),
          key: 'danger',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.info_color'),
          description: this.$t('portal_management.ui_themes.colors.info_color_desc'),
          key: 'info',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.text_color'),
          description: this.$t('portal_management.ui_themes.colors.text_color_desc'),
          key: 'text_color',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_1'),
          description: this.$t('portal_management.ui_themes.colors.bg_1_desc'),
          key: 'gray600',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_2'),
          description: this.$t('portal_management.ui_themes.colors.bg_2_desc'),
          key: 'app_bg',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_3'),
          description: this.$t('portal_management.ui_themes.colors.bg_3_desc'),
          key: 'app_bg_main',
        },
      ];
    },
    customAnalyticsColors() {
      return [
        {
          name: this.$t('portal_management.ui_themes.colors.analytic_bg'),
          description: this.$t('portal_management.ui_themes.colors.analytic_bg_desc'),
          key: 'analytic_bg',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.report_color'),
          description: this.$t('portal_management.ui_themes.colors.report_color_desc'),
          key: 'analytic_report',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.analytic_highlight'),
          description: this.$t('portal_management.ui_themes.colors.analytic_highlight_desc'),
          key: 'analytic_highlight',
        },
      ];
    },
    baseColors() {
      return BASE_COLORS;
    },
    analyticsColors() {
      return ANALYTICS_COLORS;
    },
    customThemeClone() {
      if (!this.customTheme) {
        return null;
      }
      const customThemeHash = getThemeHash(this.customTheme);
      return (
        [this.defaultTheme, ...this.themes].find((t) => getThemeHash(t) === customThemeHash) ?? null
      );
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.target = this.$refs.themePreview.$el;
    this.targetAnalytics = this.$refs.analyticsThemePreview.$el;
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        const [themes, customTheme] = await Promise.all([
          this.fetchThemes(),
          this.fetchCurrentTheme(),
        ]);
        this.themes = themes;
        this.customTheme = customTheme;
        this.defineSelectedTheme();
        if (this.selectedTheme) {
          this.setFormTheme(this.selectedTheme);
        }
        if (this.customTheme && !this.customThemeClone) {
          this.activeTab = ThemeTab.CUSTOM;
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchThemes() {
      const response = await new UiThemes({ adminApi: this.isAdmin }).fetchAll();
      return response.data.ui_themes.map((item, index) => ({
        id: item.id ?? index,
        name: item.name,
        colors: item.data.colors,
      }));
    },
    async fetchCurrentTheme() {
      const response = await new UiThemes({
        adminApi: this.isAdmin,
        owner: this.isAdmin ? new Companies({}, { id: this.company.id }) : null,
      }).fetch();
      const { ui_theme } = response.data;
      if (ui_theme) {
        return {
          id: 'custom',
          name: ui_theme.name,
          colors: ui_theme.data.colors,
        };
      }
      return null;
    },
    defineSelectedTheme() {
      this.selectedThemeId = this.customThemeClone?.id ?? this.customTheme?.id ?? 'default';
    },
    setFormColors(colors) {
      Object.keys(colors).forEach((key) => {
        if (key in DEFAULT_THEME_COLORS) {
          this.form.colors[key] = colors[key] || DEFAULT_THEME_COLORS[key];
        }
      });
    },
    setFormTheme(theme) {
      this.form.name = theme.name;
      this.setFormColors(theme.colors);
    },
    selectTheme(theme) {
      this.selectedThemeId = theme.id;
      this.setFormTheme(theme);
    },
    selectTab(tab) {
      if (tab === ThemeTab.CUSTOM) {
        this.form.colors = { ...this.previewColors };
      }
      this.activeTab = tab;
    },

    confirmApplyTheme() {
      if (this.activeTab === ThemeTab.TEMPLATE) {
        this.$refs.confirmApplyThemeModal.open();
      } else {
        this.applyTheme();
      }
    },

    applyTheme() {
      if (this.selectedThemeId === 'default' && this.activeTab === ThemeTab.TEMPLATE) {
        return this.deleteTheme();
      }
      return this.saveTheme();
    },

    async saveTheme() {
      try {
        this.inProgress = true;
        await new UiThemes({
          adminApi: this.isAdmin,
          owner: this.isAdmin ? new Companies({}, { id: this.company.id }) : null,
        }).save({
          name: this.form.name,
          data: {
            colors: this.form.colors,
          },
        });
        this.$toaster.add({
          message: this.$t('portal_management.ui_themes.toasts.successfully_updated'),
        });
        if (this.currentCompany) {
          this.$store.commit('company/UPDATE_COMPANY', {
            id: this.currentCompany.id,
            ui_theme: { colors: { ...this.form.colors } },
          });
        }
        if (this.activeTab === ThemeTab.CUSTOM) {
          this.customTheme = {
            id: 'custom',
            name: '',
            colors: null,
          };
          this.customTheme.name = this.form.name;
          this.customTheme.colors = { ...this.form.colors };
          this.defineSelectedTheme();
        }
      } catch (error) {
        console.log(error);
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },

    async deleteTheme() {
      try {
        this.inProgress = true;
        if (this.customTheme) {
          await new UiThemes({
            adminApi: this.isAdmin,
            owner: this.isAdmin ? new Companies({}, { id: this.company.id }) : null,
          }).delete();
        }
        this.customTheme = null;
        if (this.currentCompany) {
          this.$store.commit('company/UPDATE_COMPANY', {
            id: this.currentCompany.id,
            ui_theme: null,
          });
        }
        this.$toaster.add({
          message: this.$t('portal_management.ui_themes.toasts.successfully_updated'),
        });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables';

.themes-section-header {
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__analytics {
    // margin-top: calc(40px - 16px);

    .ui-text {
      font-weight: 600;
    }
  }
}

.colors-form {
  width: 440px;
}

.container-preview {
  & + & {
    margin-top: 40px;
  }
}

.theme-items {
  // margin-left: -20px;
}

.theme-item-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 1px;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &__check,
  &__actions {
    flex-shrink: 0;
  }
}

.theme-item-analytics {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;

  &__title {
    font-size: 14px;
    margin-right: 36px;
  }
}

.theme-item {
  // TODO: recheck it
  // padding: 15px;
  // border-radius: 10px;
  // cursor: pointer;

  // border-left: 3px solid transparent;
  display: flex;
  gap: 10px;

  // & + & {
  //   margin-top: 1px;
  // }

  // &:hover {
  //   background: rgba(var(--bb-primary-rgb), 0.1);
  // }

  &--active {
    background: rgba(var(--bb-primary-rgb), 0.2);
    border-color: var(--bb-primary);
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  // &__check,
  // &__actions {
  //   flex-shrink: 0;
  // }

  &__wrapper-colors {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__body {
    flex-shrink: 1;
    // flex-grow: 1;
  }
}

.theme-item__colors {
  display: flex;
  gap: 4px;
}

.color-preview {
  box-shadow: inset 0 0 0 1px rgba(black, 0.1), 0 0 0 1px rgba(white, 0.25);
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;

  &--lg {
    width: 4rem;
    height: 4rem;
  }
}

.theme-preview-card {
  overflow: hidden;
}

.btn-actions {
  display: flex;
  gap: 10px;
}

.save-btns {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.tab-container {
  padding: 15px;

  &__row {
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }
  }

  &__column {
    flex-grow: 1;

    &-form {
      flex-shrink: 0;
    }

    &-preview {
      flex-shrink: 1;
    }
  }
}

.custom-color {
  display: flex;
  gap: 20px;
  align-items: center;

  & + & {
    margin-top: 15px;
    line-height: 1.2;
  }

  &__swatch {
    cursor: pointer;
  }

  &__description {
    margin-top: 0.25em;
    font-size: 0.9em;
    line-height: 1.2;
    opacity: 0.5;
  }
}

.colors-demo {
  display: flex;
  gap: 10px;

  &__item {
    border: 1px solid var(--bb-gray-800);
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;

    &--big {
      width: 3rem;
      height: 3rem;
    }
  }
}

.checkbox-description {
  padding-left: 10px;
  margin: 0;
  line-height: 20px;
  font-size: 1rem;
}
</style>
