<template lang="pug">
UiBadge(
  v-if="status"
  size="sm"
  :variant="status.variant"
) {{ status.label }}
</template>

<script>
import Folder from '@/models/folder.js';

export default {
  props: {
    entity: {
      type: Folder,
      required: true,
    },
  },

  computed: {
    status() {
      if (this.entity.data.validate_all_files) {
        return {
          variant: 'info',
          label: this.$t('files.folder_types.validate_all_files'),
        };
      }

      if (this.entity.data.locked) {
        return {
          variant: 'secondary',
          label: this.$t('files.folder_types.locked'),
        };
      }

      return null;
    },
  },
};
</script>
