<template lang="pug">
.table-container
  TableFilter(@updateFilter="updateFilter")
  table.dataroom-table(@focusout="resetSelectedCell")
    thead
      tr
        th.dataroom-table-header(v-for="header in headers" :class="headerClasses(header)")
          .dataroom-table-header__container
            span {{ header.label }}
            UiIcon.pointer.gray-icon-700(
              v-if="header.filtersAllowed"
              :name="getColumnFilterIcon(header.key).name"
              :class="{ 'primary-icon': getColumnFilterIcon(header.key).isPrimary }"
              size="1.8rem"
              @click="openColumnFilter(header.key)"
            )
          ColumnsFilterDropdown(
            v-if="showFilterDropdown === header.key"
            column="rowsNames"
            :values="columnValues[header.key]"
            :filter="columnFilters[header.key]"
            @filter="setColumnFilter(header.key, $event)"
            @close="closeFilterDropdown"
          )
    tbody
      tr.dataroom-table-row(v-for="(row_key, rowIndex) in tableRows")
        template(v-if="rowsItems[row_key]")
          td.dataroom-table-name-cell(
            v-if="rowNameVisible"
            :class="selectedRowClass(row_key, column_index)"
          )
            .dataroom-table-cell {{ rowsItems[row_key].displayname }}
          td(
            v-for="column_index in tableColumns"
            :key="row_key + '_' + column_index"
            :class="`${selectedRowClass(row_key, column_index)}${overflowOn(columnsItems[column_index].datatype)}`"
            :style="columnsItems[column_index].datatype === 'DROPDOWN' ? { minWidth: '200px' } : {}"
          )
            .dataroom-table-cell(
              :style="columnsItems[column_index].datatype !== 'DROPDOWN' ? { overflowX: 'auto' } : {}"
            )
              template(
                v-if="editableTableCell(columnsItems[column_index].editable, rowsItems[row_key].editable)"
              )
                template(v-if="columnsItems[column_index].datatype === 'SHORT_TEXT'")
                  div(@click="selectCell(row_key, column_index)")
                    TableInput(
                      v-model="result[row_key][column_index]"
                      :noBorders="!isSelectedRow(row_key)"
                    )
                template(v-else-if="columnsItems[column_index].datatype === 'DROPDOWN'")
                  DataroomTableDropdown(
                    :result="result"
                    :column="columnsItems[column_index]"
                    :row-key="row_key"
                    :column-index="column_index"
                    :css-class="!isSelectedRow(row_key) ? 'dataroom-table-dropdown' : 'dataroom-table-dropdown dtd-visible'"
                    :on-open="() => selectCell(row_key, column_index)"
                  )
                template(v-else-if="columnsItems[column_index].datatype === 'NUMBER'")
                  div(@click="selectCell(row_key, column_index)")
                    TableInput(
                      v-model="result[row_key][column_index]"
                      type="number"
                      :noBorders="!isSelectedRow(row_key)"
                      @keypress="isNumber($event)"
                      @update:modelValue="() => calcColumnTotal(column_index)"
                    )
              template(v-else)
                | {{ result[row_key][column_index] }}
          td(v-if="customRows")
            .dataroom-table-cell.dataroom-table-row-controller
              .dataroom-table-row-controller__add
                button.plus(@click="rowAdd(row_key)") +
                button.minus(@click="openConfirmModal(row_key)") &ndash;
              .dataroom-table-row-controller__move
                UiIcon.pointer(name="ArrowTriangleUp" @click="moveRow(row_key)")
                UiIcon.pointer(name="ArrowTriangleDown" @click="moveRow(row_key, true)")
      tr.dataroom-table-row(v-if="totalsRowVisible")
        th.dataroom-table-footer
          .dataroom-table-cell {{ $t('dataroom.table.totals') }}
        th.dataroom-table-footer(v-for="column_index in tableColumns")
          .dataroom-table-cell {{ totals[column_index] }}

  ConfirmModal(
    v-if="currentRowKey"
    :show="showConfirm"
    :text="$t('dataroom.table.remove_row_confirm', { row: rowsItems[currentRowKey].displayname })"
    :confirm="rowRemove"
    :buttons="{ confirmText: $t('delete') }"
    :close="closeConfirmModal"
  )

  DataroomAddRowModal(
    ref="addRowModal"
    :isAdmin="isAdmin"
    @createNewRow="createNewRow"
  )
</template>
<script>
import ConfirmModal from '@/modals/common/Confirm.vue';
import DataroomAddRowModal from '@/modals/Forms/AddRowModal.vue';
import DataroomTableDropdown from '@/components/Dataroom/Fields/TableDropdown.vue';
import TableFilter from '@/components/Dataroom/TableFilter.vue';
import { normalizeIntByDecimal } from '@/common/number_helper.js';
import TableInput from '@/components/Dataroom/Fields/TableInput.vue';
import ColumnsFilterDropdown from './ColumnsFilter.vue';

export default {
  name: 'DatarooTable',

  components: {
    TableInput,
    ConfirmModal,
    DataroomTableDropdown,
    DataroomAddRowModal,
    TableFilter,
    ColumnsFilterDropdown,
  },

  props: {
    displayname: {
      type: String,
      default: null,
    },
    columnsList: {
      type: Array,
      default: () => [],
    },
    columnsItems: {
      type: Object,
      default: null,
    },
    rowsList: {
      type: Array,
      default: () => [],
    },
    rowsItems: {
      type: Object,
      default: null,
    },
    tableSettings: {
      type: Object,
      default: null,
    },
    result: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    formEditable: {
      type: String,
      default: 'NONE',
    },
    editable: {
      type: String,
      default: 'NONE',
    },
    fieldEditable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      customRows: false,
      rowsCount: 0,
      currentRowKey: null,
      selected: {
        row: null,
        col: null,
      },
      showConfirm: false,
      addAfterRow: null,
      rows: this.rowsList,
      columns: this.columnsList,
      filter: {
        line: '',
      },
      columnFilters: {},
      filtredColumns: [...this.columnsList],
      filtredRows: [...this.rowsList],
      totals: {},
      showFilterDropdown: null,
    };
  },

  computed: {
    dataHeaders() {
      return this.filtredColumns.map((key) => ({
        key,
        label: this.columnsItems[key].displayname,
        filtersAllowed: true,
      }));
    },

    headers() {
      let headers = [];
      headers.push({
        key: '__names',
        label: '',
        filtersAllowed: true,
      });
      headers = [...headers, ...this.dataHeaders];
      headers.push({
        key: '__row_actions',
        label: '',
        filtersAllowed: false,
        classes: 'auto-width',
      });
      return headers;
    },

    nameColumnValues() {
      const valuesMap = {};
      this.rows.forEach((r) => {
        const value = this.rowsItems[r].displayname ?? null;
        if (!(value in valuesMap)) {
          valuesMap[value] = {
            label: value,
            value,
          };
        }
      });
      return Object.values(valuesMap);
    },

    columnValues() {
      const columnValues = {};

      columnValues.__names = this.nameColumnValues;

      this.columnsList.forEach((columnKey) => {
        const column = this.columnsItems[columnKey];
        const valuesMap = {};
        this.rows.forEach((rowKey) => {
          const columnValue = this.result[rowKey][columnKey] || null;
          if (!(columnValue in valuesMap)) {
            let resultValue = null;
            if (column.datatype === 'DROPDOWN') {
              const option = column.options.find((o) => o.value === columnValue);
              resultValue = {
                label: option?.text ?? columnValue,
                value: option?.value ?? columnValue,
              };
            } else {
              resultValue = {
                label: columnValue,
                value: columnValue,
              };
            }
            valuesMap[columnValue] = resultValue;
          }
        });
        columnValues[columnKey] = Object.values(valuesMap);
      });

      return columnValues;
    },

    rowsVisible() {
      return !!this.rows.find((r) => (this.rowsItems[r].displayname || '').length > 0);
    },

    totalsRowVisible() {
      return this.tableSettings.show_column_totals;
    },

    rowNameVisible() {
      return this.rowsVisible || this.totalsRowVisible;
    },

    tableColumns() {
      return this.filtredColumns;
    },

    tableRows() {
      const columnFiltersKeys = [
        '__names',
        ...Object.keys(this.columnFilters).filter((c) => this.filtredColumns.includes(c)),
      ];
      if (columnFiltersKeys.length) {
        return this.rows.filter(
          (row) =>
            !columnFiltersKeys.some((column) => {
              let value = this.result[row][column];
              if (column === '__names') {
                value = this.rowsItems[row].displayname ?? null;
              }
              return this.columnFilters[column]?.includes(value);
            }),
        );
      }

      return this.rows;
    },
  },

  watch: {
    filter(val) {
      if (val.column && val.column !== '') {
        this.filtredColumns = this.columns.filter((column) =>
          this.columnsItems[column].displayname.toLowerCase().includes(val.column.toLowerCase()),
        );
      } else {
        this.filtredColumns = this.columns;
      }
    },
  },

  mounted() {
    this.columnsInit();
    this.rowsInit();
    this.customRows = this.fieldEditable && this.tableSettings.custom_rows;
    if (this.customRows) this.initCustomTableRows();
    if (this.totalsRowVisible) this.calcAllTotals();
    this.rowsCount = this.rows.length;
  },

  methods: {
    headerClasses(header) {
      return [`dataroom-table-header--cell_${header.key}`, header.classes];
    },

    isColumnFiltred(column) {
      return !!this.columnFilters[column]?.length;
    },

    getColumnFilterIcon(column) {
      return this.isColumnFiltred(column)
        ? { name: 'Filter2Checked', isPrimary: true }
        : { name: 'Filter2' };
    },

    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      }
      // else {
      //   return true;
      // }
    },

    editableTableCell(columnEditable, rowEditable) {
      const allEditable =
        this.editable === 'ALL' && columnEditable === 'ALL' && rowEditable === 'ALL';
      const adminEditable =
        ['ADMIN', 'ALL'].includes(this.editable) &&
        ['ADMIN', 'ALL'].includes(columnEditable) &&
        ['ADMIN', 'ALL'].includes(rowEditable);
      switch (this.formEditable) {
        case 'ALL':
          if (allEditable) return true;
          if (this.isAdmin && adminEditable) return true;
          break;
        case 'ADMIN':
          if (this.isAdmin && adminEditable) return true;
          break;
        default:
          return false;
      }
      return false;
    },
    moveRow(rowKey, down = false) {
      this.initCustomTableRows();
      const from = this.rows.indexOf(rowKey);
      const to = down ? from + 1 : from - 1;
      if (to < 0 || to >= this.rowsCount) return;
      const row = this.rows.splice(from, 1)[0];
      this.rows.splice(to, 0, row);
      this.storeRowsList();
    },
    rowRemove() {
      this.initCustomTableRows();
      const idx = this.rows.indexOf(this.currentRowKey);
      this.rows.splice(idx, 1);
      const newResult = this.result;
      delete newResult[this.currentRowKey];
      // eslint-disable-next-line vue/no-mutating-props
      delete this.rowsItems[this.currentRowKey];
      this.rowsCount = this.rows.length;
      this.storeRowsList();
      this.storeRowsItems();
      this.closeConfirmModal();
    },
    rowAdd(row) {
      this.addAfterRow = row;
      this.initCustomTableRows();
      this.$refs.addRowModal.open();
    },
    createNewRow(rowData) {
      const { displayname, editable, visible } = rowData;
      const row_key = this.initUniqRowKey(displayname);
      const delimiterIndex = this.rows.indexOf(this.addAfterRow);
      this.rows = [
        ...this.rows.slice(0, delimiterIndex + 1),
        row_key,
        ...this.rows.slice(delimiterIndex + 1),
      ];
      this.rowsCount = this.rows.length;
      const newResult = this.result;
      // eslint-disable-next-line vue/no-mutating-props
      this.rowsItems[row_key] = { displayname, editable, visible };
      const newRowResult = {};
      this.columnsList.forEach((column) => {
        newRowResult[column] = null;
      });
      newResult[row_key] = newRowResult;
      this.storeRowsList();
      this.storeRowsItems();
      this.addAfterRow = null;
    },
    initUniqRowKey(rowName) {
      let customRowName = rowName.trim().replace(/[^a-zA-Z0-9 -]/, '');
      if (!customRowName.length) customRowName = 'row';
      const row_key = customRowName.toLowerCase().replace(/\s/g, '-');
      let k = 2;
      let uniqRowKey = row_key;
      while (this.rows.indexOf(uniqRowKey) >= 0) {
        uniqRowKey = `${row_key}-${k}`;
        k++;
      }
      if (uniqRowKey !== row_key) return uniqRowKey;
      return row_key;
    },
    initCustomTableRows() {
      if (((this.result.custom_table_rows || {}).list || []).length) return;
      // eslint-disable-next-line vue/no-mutating-props
      this.result.custom_table_rows = {
        list: this.rows,
        items: this.rowsItems,
      };
    },
    storeRowsList() {
      // eslint-disable-next-line vue/no-mutating-props
      this.result.custom_table_rows.list = this.rows;
    },
    storeRowsItems() {
      // eslint-disable-next-line vue/no-mutating-props
      this.result.custom_table_rows.items = this.rowsItems;
    },

    openConfirmModal(row_key) {
      this.currentRowKey = row_key;
      this.showConfirm = true;
    },

    closeConfirmModal() {
      this.currentRowKey = null;
      this.showConfirm = false;
    },

    selectCell(row, col) {
      this.selected = {
        row,
        col,
      };
    },

    isSelectedCell(r, c) {
      const { row, col } = this.selected;
      return row === r && col === c;
    },

    isSelectedRow(row) {
      return row === this.selected.row;
    },

    selectedRowClass(row, col) {
      const isSelectedRow = row === this.selected.row;
      const isSelectedCol = col === this.selected.col;

      let classNames = ' ';

      if (isSelectedRow && isSelectedCol) {
        classNames += ' dataroom-table-row--selectedCell';
      }

      if (isSelectedRow && !isSelectedCol) {
        classNames += ' dataroom-table-row--selectedRow';
      }

      if (!isSelectedRow && isSelectedCol) {
        classNames += ' dataroom-table-row--selectedCol';
      }

      return classNames;
    },

    resetSelectedCell() {
      this.selected = {
        row: null,
        col: null,
      };
    },

    overflowOn(type) {
      return type === 'DROPDOWN' ? '--overflow-on' : '';
    },

    updateFilter(filterFields) {
      this.filter = filterFields;
    },

    columnsInit() {
      Object.entries(this.columnsItems).forEach(([key, value]) => {
        if (!value.displayname) {
          this.columns = this.columns.filter((col) => col !== key);
        }
      });
    },

    rowsInit() {
      Object.entries(this.rowsItems).forEach(([key, _value]) => {
        if (!key.length) {
          this.rows = this.rows.filter((row) => row !== key);
        }
      });
    },

    calcAllTotals() {
      this.columns.forEach((column) => this.calcColumnTotal(column));
    },

    calcColumnTotal(column) {
      if (!this.totalsRowVisible) return;
      if (this.columnsItems[column].datatype !== 'NUMBER') {
        this.totals[column] = '';
        return;
      }

      const columnResults = this.rows.map((row) => (this.result[row] || {})[column] || 0);
      const totalResult = columnResults.reduce((prev, val) => prev + Number(val), 0);

      this.totals[column] = normalizeIntByDecimal(totalResult, 4);
    },

    openColumnFilter(column) {
      this.showFilterDropdown = column;
    },

    closeFilterDropdown() {
      this.showFilterDropdown = null;
    },

    setColumnFilter(column, filterValues) {
      this.columnFilters[column] = filterValues;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheet/mixins';
@import '@/assets/stylesheet/variables';

$hero-padding: 40px;
$hero-escape: 80px;

.company-form {
  margin-top: 24px;
  padding: 24px;
}

.table-container {
  overflow-x: auto;
  min-height: 600px;
}

.dataroom-table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
  & td,
  & th {
    max-width: 150px;
    border: 1px solid var(--bb-gray-850);
    border-spacing: 0;
    padding: 0 !important;
  }

  & th:first-child {
    border-radius: 10px 0 0 0;
  }

  & th:last-child {
    border-radius: 0 10px 0 0;
  }

  & tr:nth-child(odd) {
    background-color: var(--bb-gray-900);
  }
}

.dataroom-table-name-cell {
  font-weight: bold;
}

.dataroom-table-header {
  position: relative;
  border: 1px solid #b8cad5;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  min-width: 150px;
  height: 52px;
  font-weight: inherit;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  &.auto-width {
    min-width: 0px;
  }

  & > span {
    font-weight: bold;
  }

  .dataroom-table-cell {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    span {
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
.dataroom-table-cell {
  width: 100%;
  padding: 3px 5px;
  // overflow-x: auto !important;
  height: 100% !important;
  white-space: nowrap;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00b07d;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.dataroom-table-row {
  border: 1px solid #b7b8b9;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  font-weight: 400;

  &--selectedRow,
  &--selectedCell {
    background: var(--bb-dataroom-table--selected);
    border: 2px solid var(--bb-primary);

    &--overflow-on {
      background: var(--bb-dataroom-table--selected);
      //overflow-x: visible !important;
      //overflow-y: visible;
    }
  }
  @at-root {
    .dataroom-table &--selectedCell {
      border: 2px solid var(--bb-primary);
    }
  }
}

.corner {
  position: relative;
  border: none;
}

.language-panel {
  display: flex;
}

.language-panel__icon {
  align-self: center;
  width: 20px;
  background-size: cover;
  background-position: center;
  margin-right: 5px;
}

.language-panel__icon:before {
  content: '';
  display: block;
  padding-top: 70%;
}

.uppercase {
  text-transform: uppercase;
}

.lang-label {
  color: var(--bb-app-bg-main);
}

.dataroom-entry {
  padding: 35px;

  &__container {
    display: flex;
  }

  &__navigation {
    min-width: 535px;
  }

  & h2 {
    font-size: 24px;
    margin-bottom: 50px;
  }

  &__left {
    background-color: var(--bb-app-bg-main);
    height: max-content;
  }

  &__right {
    //width: 1075px;
    width: 100%;
    padding: 50px 35px;
    background-color: var(--bb-app-bg-main);
  }

  &__footer {
    display: flex;
    justify-content: end;
  }
}

.forms {
  padding: 50px 100px;
  background-color: var(--bb-gray-900);

  & h2 {
    margin-bottom: 50px;
  }

  &__card {
    background-color: var(--bb-app-bg-main);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    & h3 {
      font-size: 24px;
      margin-bottom: 0;
    }

    &__right {
      display: flex;
      align-items: center;

      & img {
        transform: rotate(180deg);
      }
    }

    &__type {
      display: flex;
      align-items: center;
      margin-right: 20px;
      line-height: 32px;

      & p {
        margin: 0;
        padding: 0;
        font-size: 18px;
      }

      & span {
        background-color: #00b07d;
        border-radius: 50px;
        color: var(--bb-app-bg-main);
        margin-left: 10px;
        font-weight: 700;
        padding: 0 16px;
      }
    }
  }
}

.dataroom-table-row-controller {
  display: flex;
  align-items: center;

  &__add {
    display: flex;
    justify-content: center;
    gap: 1rem;
    & > * {
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      font-size: 1rem;

      &.plus {
        background: var(--bb-dataroom-table--selected);
        color: var(--bb-primary);
      }
      &.minus {
        background: mix($bb-error-status, #fff, 10%);
        color: var(--bb-danger);
      }
    }
  }

  &__move {
    display: flex;
    flex-direction: column;
    background-color: var(--bb-primary);
    border-radius: 20px;
    width: 16px;
    height: 40px;
    margin: 3px 0;
    padding: 1px;
    position: relative;
    color: var(--bb-app-bg-main);

    & :nth-child(2n) {
      position: absolute;
      bottom: 1px;
    }
  }
}

.dataroom-table-dropdown:deep {
  .multiselect__tags {
    font-size: 16px;
    z-index: 10;
    border: none;
    background-color: transparent;
  }

  .multiselect__single {
    font-size: 16px;
    z-index: 10;
    background-color: transparent;
  }
}

.dtd-visible:deep {
  .multiselect__tags {
    font-size: 16px;
    z-index: 10;
    border-radius: 5px;
    background-color: var(--bb-app-bg-main);
    min-height: 37px;
  }

  .multiselect__single {
    font-size: 16px;
    z-index: 10;
    background-color: transparent;
  }

  .multiselect__content-wrapper {
    border-radius: 5px;
  }
}

.relative {
  position: relative;
}
</style>
