<template lang="pug">
.application-layout
  .application-main
    .application-header
      .header-void
        .header
          .header__container
            .header__app-logotype
              BibookLogotype
            .header__right
              .header__user
                HeaderProfile(v-if="isAuthenticated")
                LanguageSwitcher(v-else)
  .application-content
    router-view
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderProfile from '@/components/Header/HeaderProfile.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default {
  name: 'LiteLayout',
  components: {
    HeaderProfile,
    LanguageSwitcher,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user', 'isViewAsOtherUser', 'mobileView', 'isAdmin']),
    ...mapGetters('company', ['currentCompany']),
    userRole() {
      if (this.isAdmin || !this.currentCompany) return '';
      return this.$t(`company.role.${this.currentCompany.role}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.application-content {
  background: var(--bb-app-bg);
}

.header {
  left: 0;

  &__logotype {
    max-width: 112px;
  }
}
</style>
