<template lang="pug">
UiModal(
  :show="show"
  :title="$t('edit_company.ui_themes.confirm_apply_modal.title')"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  | {{ $t('edit_company.ui_themes.confirm_apply_modal.body') }}
</template>

<script>
export default {
  emits: ['confirm'],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.confirm,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.continue'),
          onClick: this.confirm,
        },
      ];
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    confirm() {
      this.$emit('confirm');
      this.show = false;
    },
  },
};
</script>
