<template lang="pug">
.select-page
  .container
    .text-center.select-page__wrapeer-title
      .h3.select-page__title {{ $t('home.hello_user', { name: user.name }) }}
      .h3.text-secondary.select-page__description {{ $t('home.hello_user_description') }}
      .select-page__search
        UiInput(
          v-model="search"
          :placeholder="$t('home.search_company_by_name_or_vat')"
          icon="Search"
        )
          template(#icon)
            UiIcon(name="Search")
          template(slot="iconAfter")
            UiSpinner(size="sm")
          template(#iconAfter v-if="search")
            UiIcon(name="Close" @click="search = ''")
    .select-page__wrapper
      Stack(
        justify="center"
        align="stretch"
        wrap
      )
        CompanyCard.select-page__company-card(
          v-for="company in companies"
          :key="company.id"
          :company="company"
          data-test-id="company-card"
          @click="() => selectCompany(company?.active_company_domains[0])"
        )
      InfiniteLoading(:loading="loading" @load="fetchMoreCompanies")
</template>
<script>
import { mapGetters } from 'vuex';
import UserCompanies from '@/api/models/UserCompanies.js';
import CompanyCard from '@/components/SelectCompany/CompanyCard.vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';

export default {
  name: 'SelectCompany',
  components: {
    CompanyCard,
    InfiniteLoading,
  },
  data() {
    return {
      meta: {},
      search: '',
      currentPage: 1,
      perPage: 10,
      loading: false,
      abortController: null,
      request: null,
    };
  },
  computed: {
    ...mapGetters('company', ['companies']),
    ...mapGetters(['user']),
  },
  watch: {
    search() {
      this.currentPage = 1;
      this.$store.commit('company/UPDATE_COMPANY_LIST', []);
      this.handleFetchCompanies();
    },
  },
  created() {
    this.$store.commit('company/UPDATE_COMPANY_LIST', []);
    this.fetchCompanies();
  },
  methods: {
    selectCompany(domain) {
      window.location = `${window.location.protocol}//${domain}`;
    },
    async handleFetchCompanies() {
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }
      if (this.request) {
        await this.request;
      }
      this.request = this.fetchCompanies();
    },
    async fetchCompanies() {
      this.abortController = new AbortController();
      this.loading = true;
      try {
        const response = await new UserCompanies().fetchAll(
          {
            page: this.currentPage,
            per_page: this.perPage,
            search: this.search,
          },
          {
            signal: this.abortController.signal,
          },
        );
        const { companies, meta } = response.data;
        this.meta = meta;
        this.$store.commit('company/UPDATE_COMPANY_LIST', [...this.companies, ...companies]);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchMoreCompanies() {
      if (this.loading || this.companies.length >= (this.meta?.total_count ?? 0)) {
        return;
      }
      this.currentPage += 1;
      this.handleFetchCompanies();
    },
  },
};
</script>

<style lang="scss" scoped>
.select-page {
  padding: 40px 0;

  &__description {
    line-height: 36px;
    font-size: 24px;
  }

  &__wrapper-title {
    margin-bottom: calc(40px - 15px);
  }

  &__search {
    margin: auto;
    margin-top: calc(40px - 8px);
    margin-bottom: 40px;
    max-width: 460px;
  }

  &__wrapper {
    max-width: 2040px;
    margin: 0 auto;
    padding: 0 40px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__company-card {
    max-width: 445px;
    width: 100%;
    min-height: 167px;
  }
}

@media screen and (max-width: 570px) {
  .select-page {
    &__wrapper {
      padding: 0 30px;
    }
  }
}
</style>
