<template lang="pug">
UiCardSection(padding="lg")
  UiStack(
    vertical
    spacing="xl"
    align="stretch"
  )
    LoginBannerSection
    AmountOfUsersBannerSection
</template>

<script>
import LoginBannerSection from './components/login-banner-section.vue';
import AmountOfUsersBannerSection from './components/amount-of-users-banner-section.vue';

export default {
  components: {
    LoginBannerSection,
    AmountOfUsersBannerSection,
  },
};
</script>

<style lang="scss">
.form-section {
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: stretch;
  }

  &__main {
    width: 50%;
    flex-shrink: 0;

    @media screen and (max-width: 1000px) {
      width: auto;
    }
  }

  &__details {
    flex-grow: 1;
    flex-shrink: 1;

    @media screen and (max-width: 1000px) {
      width: auto;
    }
  }
}
</style>
