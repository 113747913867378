<template lang="pug">
UiModal(
  :title="$t('login_banners.modals.create.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  UiStack(
    spacing="2xl"
    vertical
    align="stretch"
  )
    UiStackItem
      UiInputLabel {{ $t('login_banners.modals.title') }}
      UiInput(
        v-model="form.title"
        :placeholder="$t('login_banners.modals.label_title')"
        @input="handleLimitTitle"
      )
        template(#postfix)
          div(:class="{ 'login-banner__danger': form.title.length > 74 }") {{ postfixTitleLength }}
      template(v-if="v$.form.title?.$errors")
        UiInputFeedback(v-for="$error in v$.form.title?.$errors" error) {{ $error.$message }}
    UiStackItem
      UiInputLabel {{ $t('login_banners.modals.description') }}
      UiEditor(
        v-model="form.description"
        ref="editorRef"
        :limit="250"
        @update:modelValue="updateValue({ descriptionLength: $refs.editorRef.editor.storage.characterCount.characters() })"
      )
      template(v-if="v$.form.descriptionLength?.$errors")
        UiInputFeedback(v-for="$error in v$.form.descriptionLength?.$errors" error) {{ $error.$message }}
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBanners from '@/api/models/login-banners.js';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/common/validators.js';

function getForm() {
  return {
    title: '',
    description: '',
    descriptionLength: null,
  };
}

export default {
  emits: ['done'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: getForm(),
    };
  },
  validations() {
    const requiredDescription = (value) => value;
    return {
      form: {
        descriptionLength: {
          requiredDescription: helpers.withMessage(
            this.$t('system_banners.validations.required'),
            requiredDescription,
          ),
        },
        title: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: () => this.close(),
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          onClick: () => this.submit(),
          loading: this.inProgress,
        },
      ];
    },
    postfixTitleLength() {
      return `${this.form.title.length}/75`;
    },
  },
  watch: {
    form: {
      handler(newValue) {
        if (newValue.title.length > 75) {
          this.form.title = this.form.title.slice(0, 75);
        }
      },
      deep: true,
    },
  },
  methods: {
    open() {
      this.v$.$reset();
      this.form = getForm();
      this.show = true;
    },
    close() {
      this.show = false;
    },
    updateValue(newValue = {}) {
      this.form = {
        ...this.form,
        ...newValue,
      };
    },
    async submit() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      this.inProgress = true;
      try {
        const params = {
          login_banner: {
            title: this.form.title,
            description: this.form.description,
          },
        };

        await new LoginBanners({
          adminApi: this.isAdmin,
        }).create(params);

        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$t('validations.check_all_fields'),
        });
      } finally {
        this.inProgress = false;
      }
    },
    handleLimitTitle() {
      if (this.form.title.length > 75) {
        this.form.title = 's';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-banner__danger {
  color: var(--bb-danger);
}
</style>
