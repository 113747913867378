<template lang="pug">
UiModal(
  :title="$t('company.reports.edit_report')"
  :subtitle="$t('company.reports.edit_report_description')"
  :show="show"
  :rightActions="rightActions"
  width="480"
  @close="close"
)
  ReportForm(:form="form" :v$="v$")
</template>

<script>
import ReportForm from '@/components/Report/Form.vue';
import Report from '@/models/Report.js';
import useVuelidate from '@vuelidate/core';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import { mapGetters } from 'vuex';
import { generatePowerBiReportLink } from '@/common/generate-power-bi-report-link.js';
import reportModalMixin from './report-modal-mixin.js';

export default {
  components: {
    ReportForm,
  },
  mixins: [reportModalMixin],

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      show: false,
      inProgress: false,
      form: new Report(),
      initialReportWithoutUrl: null,
      initialUrl: null,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          onClick: this.submit,
          loading: this.inProgress,
          disabled:
            this.inProgress || (!this.formChanged && !this.urlChanged) || this.isViewAsOtherUser,
        },
      ];
    },
    formChanged() {
      const { report_url, ...formWithoutUrl } = this.form;
      return !(JSON.stringify(formWithoutUrl) === this.initialReportWithoutUrl);
    },
    urlChanged() {
      return !(JSON.stringify(this.form.report_url) === this.initialUrl) && this.form.report_url;
    },
  },
  methods: {
    open({ entity }) {
      this.currentReport = entity;
      this.form = new Report({
        ...entity.data,
        ...entity.extras,
        pages: entity.data.pages.length ? entity.data.pages : entity.extras.pages,
        id: entity.id,
        name: entity.name,
        report_group_id: entity.parent_id,
        report_url:
          entity.data.report_url ??
          (entity.data.report_group_id_pbi && entity.data.report_id_pbi
            ? generatePowerBiReportLink(entity.data.report_group_id_pbi, entity.data.report_id_pbi)
            : ''),
      });
      const { report_url, ...formWithoutUrl } = this.form;
      this.initialReportWithoutUrl = JSON.stringify(formWithoutUrl);
      this.initialUrl = JSON.stringify(report_url);
      this.show = true;
    },

    close() {
      this.show = false;
      this.v$.$reset();
    },

    submit() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.inProgress = true;
        const requests = [];
        if (this.urlChanged) {
          requests.push(this.save(true));
        }
        if (this.formChanged) {
          requests.push(this.save(false));
        }
        Promise.allSettled(requests).finally(() => {
          this.inProgress = false;
          this.$emit('done');
          this.close();
        });
      });
    },

    save(update_url) {
      let params = {};
      let id;
      let name;
      let pages;
      let reports;
      let report_url;
      let tag;
      let visible_in_analytics;
      let personal;
      let editing;
      let report_group_id;
      let row_level_security;
      let rls_rule_name;

      switch (this.form.report_type) {
        case 'link_to_page':
        case 'link_to_pages':
          ({ id, name, pages, visible_in_analytics, personal, report_group_id } = this.form);
          tag = 'update_link_to_page';
          params = { id, name, pages, tag, visible_in_analytics, personal, report_group_id };
          if (this.form.report_type === 'link_to_page') {
            if (typeof pages === 'string') {
              params.pages = [pages];
            }
          }
          break;
        case 'merged_report':
          ({ id, name, pages, reports, visible_in_analytics, report_group_id } = this.form);
          tag = 'update_merged';
          params = { id, pages, reports, name, tag, visible_in_analytics, report_group_id };
          break;
        case 'standard_report':
          if (update_url) {
            tag = 'update_url';
            ({
              id,
              report_url,
              visible_in_analytics,
              row_level_security,
              rls_rule_name,
              report_group_id,
            } = this.form);
            params = {
              id,
              report_url,
              tag,
              visible_in_analytics,
              row_level_security,
              rls_rule_name,
              report_group_id,
            };
          } else {
            tag = 'update_report';
            ({
              id,
              name,
              visible_in_analytics,
              personal,
              editing,
              row_level_security,
              rls_rule_name,
              report_group_id,
            } = this.form);
            params = {
              id,
              name,
              tag,
              visible_in_analytics,
              personal,
              editing,
              row_level_security,
              rls_rule_name,
              report_group_id,
            };
          }
          break;
        default:
          console.error('report_type is not set');
      }

      return new PowerBiReports(null, { id: params.id }).save(params);
    },
  },
};
</script>

<style lang="scss"></style>
