<template lang="pug">
modal(:show="show" :close="close")
  template(#body)
    p.description {{ $t('administrators.modal.delete_user', { user: user.name }) }}
  template(#footer)
    Stack
      StackItem
        UiButton(
          variant="outline-primary"
          width
          @click="close"
        ) {{ $t('administrators.modal.action_no') }}
      StackItem
        UiButton(
          :loading="inProgress"
          :disabled="inProgress"
          width
          @click="submit"
        ) {{ $t('administrators.modal.action_yes') }}
</template>

<script>
import Administrators from '@/api/models/Administrators.js';

export default {
  data() {
    return {
      show: false,
      inProgress: false,
      user: {},
    };
  },
  methods: {
    open(user) {
      this.user = user;
      this.show = true;
    },

    close() {
      this.user = {};
      this.$emit('done');
      this.show = false;
    },

    submit() {
      this.inProgress = true;
      new Administrators(null, { id: this.user.id })
        .delete()
        .then((resp) => {
          this.inProgress = false;
          this.close();
          this.$emit('done');
        })
        .catch((_e) => {
          this.inProgress = false;
          this.$toaster.add({
            type: 'error',
            message: this.$t('errors.server_error_try_later'),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
