<template lang="pug">
.login-form
  .login-form__error-alert(v-if="errorMessageText")
    UiAlert(variant="error" @close="clearErrorMessage") {{ errorMessageText }}
  .login-form__email-form(v-if="basicLoginEnabled")
    Stack(
      vertical
      spacing="lg"
      align="stretch"
    )
      StackItem
        UiFormInput(
          v-model="form.email"
          v-focus
          size="lg"
          placeholder="@mail"
          :disabled="inProgress"
          :label="$t('login_page.email')"
          :vuelidate-model="v$.form.email"
          data-test-id="input-email"
          @keyup.enter="submit"
        )
      StackItem
        UiFormInput(
          v-model="form.password"
          size="lg"
          type="password"
          :label="$t('login_page.password')"
          :placeholder="$t('login_page.password_placeholder')"
          :disabled="inProgress"
          :vuelidate-model="v$.form.password"
          data-test-id="input-password"
          @keyup.enter="submit"
        )
      StackItem
        UiButton(
          block
          :loading="inProgress"
          :disabled="inProgress"
          data-test-id="button-login"
          @click="submit"
        ) {{ $t('actions.login') }}
      StackItem(v-if="showResetPassword")
        router-link(:to="{ name: 'ResetPassword' }") {{ $t('login_page.forgot_password') }}

  .login-form__divider(v-if="basicLoginEnabled && showProviders")
    span {{ $t('login_page.divider_label') }}

  .login-form__oauth-providers(v-if="showProviders")
    Stack(
      vertical
      spacing="lg"
      align="stretch"
    )
      StackItem(v-if="oAuthProviders.length")
        Stack(justify="space-around")
          StackItem(v-for="provider in oAuthProviders" :key="provider.slug")
            a.login-oauth-button(
              v-bind="generateLoginProps(provider)"
              :data-test-id="`oauth-button-${provider.slug}`"
            )
              .login-oauth-button__icon(:style="{ backgroundImage: `url(${provider.icon})` }")
              .login-oauth-button__label
                .login-oauth-button__subtitle {{ $t('login_page.login_with') }}
                .login-oauth-button__title {{ provider.label }}
      StackItem(v-if="showSSOLogin")
        UiButton(
          v-bind="SSOLinkProps"
          block
          variant="outline-primary"
          :loading="requestAuthLinkState.sso"
          data-test-id="button-login-sso"
        ) {{ $t('login_page.login_sso') }}
</template>

<script>
import ApiUsers from '@/api/user.js';
import { mapGetters } from 'vuex';
import { filteredOauth2Providers } from '@/common/login_providers.js';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@/common/validators.js';
import RedirectService from '@/common/redirect-service.js';
import loginService from '@/services/login-service.js';
import emitPostMessageEvent from '@/common/emit-post-message-event.js';
import axios from 'axios';

export default {
  props: {
    showResetPassword: {
      type: Boolean,
      default: true,
    },
    redirectUrl: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      inProgress: false,
      errorMessage: null,
      form: {
        email: '',
        password: '',
      },
      requestAuthLinkState: {
        google: false,
        azure: false,
        sso: false,
      },
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  },
  computed: {
    ...mapGetters([
      'basicLoginEnabled',
      'oauthGoogleLoginEnabled',
      'oauthMicrosoftLoginEnabled',
      'SSOLoginEnabled',
      'SSOLoginMethod',
      'SSOLoginUrl',
      'oAuthRedirectArr',
    ]),
    showSSOLogin() {
      return this.SSOLoginMethod && this.SSOLoginUrl && this.SSOLoginEnabled;
    },
    showProviders() {
      return this.oauthGoogleLoginEnabled || this.oauthMicrosoftLoginEnabled || this.showSSOLogin;
    },
    errorMessageText() {
      if (this.$route?.query?.error) {
        return this.$t(`errors.${this.$route.query.error}`);
      }
      return this.errorMessage;
    },
    SSOProvider() {
      return {
        slug: 'sso',
        label: 'sso',
        url: this.SSOLoginUrl,
        icon: null,
      };
    },
    oAuthProviders() {
      return filteredOauth2Providers(this.oauthGoogleLoginEnabled, this.oauthMicrosoftLoginEnabled);
    },
    SSOLinkProps() {
      return this.generateLoginProps(this.SSOProvider);
    },
  },

  mounted() {
    window.addEventListener('message', this.windowMessageListener);
    this.callAutoLogin();
  },

  beforeUnmount() {
    window.removeEventListener('message', this.windowMessageListener);
  },

  methods: {
    generateLoginProps(provider) {
      if (this.external || this.redirectUrl) {
        return {
          onClick: () => {
            loginService.trackLoginAttempt();
            if (this.redirectUrl) {
              this.handleExternalAuth(provider);
            } else {
              this.openExternalProviderWindow(provider.url);
            }
          },
        };
      }

      return {
        href: RedirectService.generateLoginProviderURL(provider.url, false),
      };
    },

    openExternalProviderWindow(url) {
      window.open(RedirectService.generateLoginProviderURL(url, true), '_blank');
    },

    submit() {
      this.clearErrorMessage();
      this.v$.$validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.inProgress = true;
        ApiUsers.userLogin(this.form.email, this.form.password)
          .then((resp) => {
            this.inProgress = false;
            this.$emit('authorized');
            window.location.reload();
          })
          .catch((e) => {
            if (e.response) {
              this.errorMessage = this.$localizeErrorMessage(e, true);
            }
          })
          .finally(() => {
            this.inProgress = false;
          });
      });
    },

    clearErrorMessage() {
      this.errorMessage = null;
      this.clearQueryError();
    },

    clearQueryError() {
      if (this.$route.query.error) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: { ...this.$route.query, error: undefined },
        });
      }
    },

    windowMessageListener(event) {
      if (event.data === 'auth_success') {
        window.location.reload();
      }
    },

    callAutoLogin() {
      if (
        this.external &&
        !this.redirectUrl &&
        !this.basicLoginEnabled &&
        this.oAuthRedirectArr.length === 1
      ) {
        this.openExternalProviderWindow(this.oAuthRedirectArr[0]);
      }
    },

    async handleExternalAuth(provider) {
      if (this.requestAuthLinkState[provider.slug]) {
        return;
      }

      try {
        this.requestAuthLinkState[provider.slug] = true;
        const response = await axios.post(provider.url, {
          redirect_url: this.redirectUrl,
        });
        emitPostMessageEvent('auth', response.data.attempt_url);
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$t('errors.something_went_wrong_try_later'),
        });
      } finally {
        this.requestAuthLinkState[provider.slug] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables.scss';

.login-form {
  &__error-alert {
    margin-bottom: 2em;
    white-space: pre-line;
  }

  &__divider {
    display: flex;
    align-items: center;
    color: #b8cad5;
    margin: 30px 0;

    &::before,
    &::after {
      content: '';
      flex-grow: 1;
      flex-shrink: 1;
      border-top: 1px solid #b8cad5;
    }

    & span {
      display: block;
      flex-shrink: 0;
      padding: 0 30px;
    }
  }
}

.login-oauth-button {
  display: flex;
  align-items: center;
  min-height: 42px;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
  transition: all 230ms;
  color: inherit;

  &:hover {
    background: rgba(#b7b8b9, 0.15);
    color: inherit;
  }

  &__label {
    flex-shrink: 1;
  }

  &__icon {
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__subtitle {
    color: #b7b8b9;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 5px;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
  }
}
</style>
