<template lang="pug">
StackItem
  UiFormInput(
    :modelValue="callbackUrl"
    :label="$t('sso.oidc.callback_url')"
    readonly
    size="lg"
  )
    template(#icon)
      UiTooltip(variant="dark" :title="$t('actions.copy_to_clipboard')")
        template(#activator)
          UiButton(
            icon="Copy"
            variant="text-secondary"
            block
            @click="copyToClipboard(callbackUrl)"
          )
StackItem
  UiFormURLInput(
    :modelValue="oidc.issuer"
    :disabled="inProgress"
    size="lg"
    :vuelidateModel="v$.oidc.issuer"
    @update:modelValue="updateOIDCForm({ issuer: $event })"
  )
    template(#label)
      | {{ $t('sso.oidc.issuer') }}
      UiInfo
        div(v-html="$t('sso.oidc.issuer_tooltip')")
StackItem
  UiFormInput(
    :modelValue="oidc.client_id"
    :label="$t('sso.oidc.client_id')"
    :disabled="inProgress"
    size="lg"
    :vuelidateModel="v$.oidc.client_id"
    @update:modelValue="updateOIDCForm({ client_id: $event })"
  )
StackItem
  UiFormInput(
    :modelValue="oidc.client_secret"
    :label="$t('sso.oidc.client_secret')"
    :disabled="inProgress"
    size="lg"
    :vuelidateModel="v$.oidc.client_secret"
    @update:modelValue="updateOIDCForm({ client_secret: $event })"
  )
StackItem
  UiFormInput(
    :modelValue="oidc.scope"
    :label="$t('sso.oidc.scope')"
    :disabled="inProgress"
    :description="$t('sso.oidc.scope_tip')"
    :vuelidateModel="v$.oidc.scope"
    size="lg"
    @update:modelValue="updateOIDCForm({ scope: $event })"
  )
StackItem
  UiFormInput(
    :modelValue="oidc.email_key"
    :label="$t('sso.oidc.email_key')"
    :disabled="inProgress"
    :vuelidateModel="v$.oidc.email_key"
    size="lg"
    @update:modelValue="updateOIDCForm({ email_key: $event })"
  )
StackItem
  UiFormInput(
    :modelValue="oidc.provider_name"
    :label="$t('sso.oidc.provider_name')"
    :placeholder="$t('placeholders.type_name')"
    :disabled="inProgress"
    size="lg"
    @update:modelValue="updateOIDCForm({ provider_name: $event })"
  )
UiStack(justify="space-between")
  UiButton(
    :disabled="inProgress || discoveryInProgress"
    :loading="discoveryInProgress"
    variant="smooth-primary"
    size="lg"
    @click="runDiscoveryTest"
  ) {{ $t('sso.oidc.test_oidc_server') }}
  UiButton(
    :disabled="inProgress || discoveryInProgress"
    :loading="inProgress"
    size="lg"
    @click="$emit('submit')"
  ) {{ $t('actions.save') }}
</template>

<script>
import copy from 'copy-to-clipboard';
import { mapGetters } from 'vuex';
import OIDC from '@/api/models/OIDC.js';
import Companies from '@/api/models/Companies.js';
import useVuelidate from '@vuelidate/core';
import { required, url } from '@/common/validators.js';

export default {
  name: 'OIDCForm',

  emits: ['update:oidc'],

  props: {
    oidc: {
      type: Object,
      required: true,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      discoveryInProgress: false,
      discoveryRequest: null,
      discoveryToast: null,
    };
  },

  validations() {
    return {
      oidc: {
        issuer: {
          required,
          url,
        },
        client_id: {
          required,
        },
        client_secret: {
          required,
        },
        scope: {
          required,
        },
        email_key: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapGetters(['appDomain', 'isAdminHost']),

    callbackUrl() {
      return `https://auth.${this.appDomain}/oidc/callback`;
    },
  },

  beforeUnmount() {
    this.$toaster.close(this.discoveryToast);
  },

  methods: {
    copyToClipboard(val) {
      const result = copy(val);
      if (result) {
        this.$toaster.add({
          type: 'info',
          message: this.$t('company.reports.copied'),
        });
      }
    },

    updateOIDCForm(partial) {
      this.$emit('update:oidc', {
        ...this.oidc,
        ...partial,
      });
    },

    async runDiscoveryTest() {
      this.discoveryInProgress = true;
      this.$toaster.close(this.discoveryToast);
      this.discoveryToast = this.$toaster.add({
        type: 'warning',
        loading: true,
        allowClose: false,
        actions: null,
        title: this.$t('sso.oidc.discover_in_progress_title'),
        message: this.$t('sso.oidc.discover_in_progress'),
        timeout: null,
      });

      try {
        this.discoveryRequest = await new OIDC({
          owner: this.isAdminHost ? new Companies(null, { id: this.$route.params.id }) : null,
          adminApi: this.isAdminHost,
        }).discover({
          issuer: this.oidc.issuer,
        });
        this.$toaster.update(this.discoveryToast, {
          type: 'success',
          allowClose: true,
          loading: false,
          title: this.$t('sso.oidc.discover_success_title'),
          message: this.$t('sso.oidc.discover_success'),
          actions: [
            {
              label: this.$t('actions.ok'),
              onClick: (close) => close(),
            },
          ],
        });
      } catch (error) {
        this.$toaster.update(this.discoveryToast, {
          type: 'error',
          allowClose: true,
          loading: false,
          title: this.$t('sso.oidc.discover_failed_title'),
          message:
            error.response?.data?.error === 'wrong_issuer'
              ? this.$t('sso.oidc.discover_failed')
              : this.$t('errors.something_went_wrong_try_later'),
          actions: [
            {
              label: this.$t('actions.retry'),
              onClick: () => this.runDiscoveryTest(),
            },
            {
              label: this.$t('actions.close'),
              onClick: (close) => close(),
            },
          ],
        });
      }
      this.discoveryInProgress = false;
    },
  },
};
</script>
