import GoogleBrandIcon from '../assets/images/google-icon.svg?url';
import MicrosoftBrandIcon from '../assets/images/microsoft-icon.svg?url';

const oauth2Providers = [
  {
    slug: 'google',
    label: 'Google',
    url: '/auth/google_oauth2',
    icon: GoogleBrandIcon,
  },
  {
    slug: 'azure',
    label: 'Microsoft',
    url: '/auth/azure_oauth2',
    icon: MicrosoftBrandIcon,
  },
];

export const filteredOauth2Providers = (oauthGoogleLoginEnabled, oauthMicrosoftLoginEnabled) => {
  return oauth2Providers.filter(
    (provider) =>
      (provider.slug === 'google' && oauthGoogleLoginEnabled) ||
      (provider.slug === 'azure' && oauthMicrosoftLoginEnabled),
  );
};

export default oauth2Providers;
