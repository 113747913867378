<template lang="pug">
.tab-container.user-groups
  TabHeader
    template(#title) {{ $t('user_groups.title') }}
    template(#actions)
      UiButton(icon="Plus" @click="() => openCreateUserGroupModal()") {{ $t('user_groups.action_add_new_group') }}
  UiRestTable(
    ref="dataTable"
    namespace="groups"
    :headers="headers"
    :fetchHandler="fetchGroups"
  )
    template(#cell:name="{ item }")
      RouterLink(:to="getItemLink(item)") {{ item.name }}
    template(#cell:members_count="{ item }")
      Stack(align="center")
        | {{ $t('user_groups.members_count', { count: item.members_count }) }}
        UiTooltip(:title="$t('user_groups.add_users_in_group')" variant="dark")
          template(#activator)
            UiButton(
              variant="outline-primary"
              size="sm"
              round
              icon="Plus"
              @click="openAddUsersUserGroupModal(item)"
            )
    template(#cell:sensitive_info_included="{ item }")
      Stack(justify="center")
        UiStatusBoolean(:value="item.sensitive_info_included")
    template(#cell:actions="{ item, index }")
      DataTableRowActions(:actions="getItemActions(item, index)")
  CreateUserGroupModal(ref="createUserGroupModal" @done="fetchData")
  AddUsersUserGroupModal(ref="addUsersUserGroupModal" @done="fetchData")
  DeleteUserGroupModal(ref="deleteUserGroupModal" @done="fetchData")
</template>

<script>
import TabHeader from '@/components/Tabs/TabHeader.vue';
import CreateUserGroupModal from '@/modals/UserGroups/Create.vue';
import AddUsersUserGroupModal from '@/modals/UserGroups/AddUsers.vue';
import DeleteUserGroupModal from '@/modals/UserGroups/Delete.vue';
import UserGroups from '@/api/models/UserGroups.js';
import Companies from '@/api/models/Companies.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    CreateUserGroupModal,
    AddUsersUserGroupModal,
    DeleteUserGroupModal,
    TabHeader,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    headers() {
      return [
        {
          key: 'name',
          label: this.$t('user_groups.table.name'),
          sortable: true,
        },
        {
          key: 'members_count',
          label: this.$t('user_groups.table.users'),
        },
        {
          key: 'sensitive_info_included',
          label: this.$t('user_groups.table.sensitive_info_included'),
          squeeze: true,
          class: 'text-nowrap',
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  methods: {
    fetchData() {
      this.$refs.dataTable.fetchData();
    },
    async fetchGroups(params) {
      const response = await new UserGroups({
        adminApi: this.isAdminHost,
        owner: this.isAdminHost ? new Companies(null, { id: this.$route.params.company_id }) : null,
      }).fetchAll(params);
      return {
        meta: response.data.meta,
        items: response.data.user_groups,
      };
    },
    getItemLink(item) {
      return this.$adminCompanyLinkModifier({
        name: 'UserGroupsShow',
        params: {
          user_group_id: item.id,
        },
      });
    },
    openCreateUserGroupModal(item) {
      this.$refs.createUserGroupModal.open(item);
    },
    openDeleteUserGroupModal(item) {
      this.$refs.deleteUserGroupModal.open(item);
    },
    openAddUsersUserGroupModal(item) {
      this.$refs.addUsersUserGroupModal.open(item);
    },
    getItemActions(item) {
      return [
        {
          icon: 'PencilCreate',
          label: this.$t('user_groups.action_edit_group'),
          to: this.getItemLink(item),
        },
        {
          icon: 'Trash',
          label: this.$t('actions.delete'),
          onClick: () => this.openDeleteUserGroupModal(item),
          variant: 'danger',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables';
</style>
