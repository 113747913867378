import CompanyFileData from './company-file-data.js';
import CompanyFileExtras from './company-file-extras.js';
import ContentEntity from './content-entity.js';

class CompanyFile extends ContentEntity {
  constructor(entity, options) {
    super(entity, {
      dataModel: CompanyFileData,
      extrasModel: CompanyFileExtras,
      ...options,
    });
  }
}

export default CompanyFile;
