<template lang="pug">
.table-container
  RestTable(
    :fetchHandler="fetchData"
    namespace="integration_history"
    :headers="headers"
    ref="integrationHistoryTable"
    filtered
    :initial-filters="initialFilters"
    multiline-filters
  )
    template(v-slot:cel_integration="{ item }")
      span {{ integrationName(item.software) }}
    template(v-slot:cel_requester="{ item }")
      UserCard(
        :avatar="item.requester.avatar"
        :name="item.requester.name"
        :email="item.requester.email"
      )
    template(v-slot:cel_role="{ item }")
      span {{ $t(`company.role.${item.requester.company_role}`) }}
    template(v-slot:cel_date="{ item }")
      span(data-vi="created_at") {{ $d(item.created_at, 'numeric') }}
    template(v-slot:cel_status="{ item }")
      HistoryStatusBadge(:status="item.status")
    template(v-slot:cel_approver="{ item }")
      UserCard(
        :avatar="item.approver.avatar"
        :name="item.approver.name"
        :email="item.approver.email"
      )
    template(v-slot:filter_block="{ filter, updateFilter }")
      UiStack(wrap spacing="lg")
        UiStackItem.filters-search
          UiInput(
            :placeholder="$t('integration_requests.filter_by.search')"
            :modelValue="filter.search"
            @update:modelValue="updateFilter({ search: $event })"
          )
            template(#icon)
              UiIcon(name="SearchIcon")
        UiStack(wrap)
          UiStackItem
            .filter-by-title {{ $t('integration_requests.filter_by.title') }}
          UiStackItem.filters-block
            UiMultiselect(
              :options="integrationOptions"
              :modelValue="filter.software"
              :placeholder="$t('integration_requests.filter_by.integration')"
              :allow-empty="true"
              @update:modelValue="updateFilter({ software: $event })"
            )
          UiStackItem.filters-block
            UiMultiselect(
              :options="integrationStatusOptions"
              :modelValue="filter.status"
              :placeholder="$t('integration_requests.filter_by.status')"
              :allow-empty="true"
              @update:modelValue="updateFilter({ status: $event })"
            )
          UiStackItem.filters-block
            UiDatePicker(
              :range="true"
              :modelValue="[filter.time_start, filter.time_end]"
              size="md"
              @update:modelValue="updateFilter({ time_start: $event[0], time_end: $event[1] })"
            )
</template>

<script>
import HistoryStatusBadge from '@/views/Company/Tabs/integration-requests/components/history-status-badge.vue';
import IntegrationRequests from '@/api/models/IntegrationRequests.js';
import IntegrationStatusOptions from '@/helper/integration-status-options.js';
import IntegrationOptions from '@/helper/integration-options.js';
import UserCard from '@/components/UserCard.vue';
import { getIntegrationName } from '@/views/Onboarding/helpers.js';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';

export default {
  components: {
    UserCard,
    HistoryStatusBadge,
  },
  data() {
    return {
      initialFilters: {
        time_start: new Date(),
        time_end: new Date(),
      },
    };
  },

  computed: {
    headers() {
      return [
        {
          key: 'integration',
          label: this.$t('integration_activity.headers.integration'),
          sortable: true,
        },
        {
          key: 'requester',
          label: this.$t('integration_activity.headers.requester'),
          sortable: true,
          width: '25%',
        },
        {
          key: 'role',
          label: this.$t('integration_activity.headers.role'),
        },
        {
          key: 'date',
          label: this.$t('integration_activity.headers.date'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('integration_activity.headers.status'),
          sortable: true,
        },
        {
          key: 'approver',
          label: this.$t('integration_activity.headers.approver'),
          sortable: true,
          width: '25%',
        },
      ];
    },
    integrationOptions() {
      return IntegrationOptions;
    },
    integrationStatusOptions() {
      return IntegrationStatusOptions.map(({ value, label }) => ({
        value,
        label: this.$t(label),
      }));
    },
  },

  methods: {
    async fetchData(params) {
      try {
        const response = await new IntegrationRequests().fetchHistory({
          ...params,
          time_start: startOfDay(params.time_start),
          time_end: endOfDay(params.time_end),
        });
        return {
          meta: response.data.meta,
          items: response.data.integration_requests,
        };
      } catch (error) {
        console.log(error);
        return {};
      }
    },
    refreshData() {
      this.$refs.integrationHistoryTable.fetchData();
    },
    integrationName(software) {
      return getIntegrationName(software) ?? software;
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-block {
  min-width: 200px;
}
.filters-search {
  min-width: 250px;
}

.filter-by-title {
  color: var(--bb-gray-500);
  font-weight: 600;
}
</style>
