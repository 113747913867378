<template lang="pug">
.company-page
  div(v-if="company")
    AppBreadcrumbs(:items="breadcrumbs")
    // todo refactor to use HTML once or move out to the separated component
    template(v-if="!mobileView")
      .hero
        .page-name {{ $t('company.title') }}
      .company-page__header
        .company-page__name {{ company.name }}
        .company-page__desc
          .company-page__company-id
            span {{ $t('company.company_id') }}:&nbsp;
            span {{ company.id }}
          .company-page__business-id
            span {{ $t('company.business_id') }}:&nbsp;
            span {{ company.business_id }}
          UiButton(icon="PencilCreate" @click="editCompanyInfo") {{ $t('company.edit_company') }}
    template(v-else)
      .company-page__company
        .company-page__company__info
          .page-name {{ $t('company.title') }}
          .company-page__name {{ company.name }}
        UiButton(
          icon="PencilCreate"
          round
          @click="editCompanyInfo"
        )
      .company-page__header
        .company-page__desc
          .company-page__company-id
            span {{ $t('company.company_id') }}:&nbsp;
            span {{ company.id }}
          .company-page__business-id
            span {{ $t('company.business_id') }}:&nbsp;
            span {{ company.business_id }}
    .company-page__tab-container
      UiTabs.text-nowrap(
        :tabs="tabs"
        :selected="selectedTab"
        @select="selectTab"
      )
      UiCard
        Transition(name="fade" mode="out-in")
          Component(:is="tabContent")
  OverlayLoader(
    fixed
    :show="companyInProgress"
    :label="$t('company.loading_company_info')"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import TabUsers from '@/views/Company/Tabs/Users.vue';
import TabIntegrations from '@/views/Company/Tabs/Integrations.vue';
import TabApiKeys from '@/views/Company/Tabs/ApiKeys.vue';
import TabApiKeysAdmin from '@/views/Company/Tabs/ApiKeysAdmin.vue';
import TabLogs from '@/views/Company/Tabs/Logs.vue';
import TabUserGroups from '@/views/Company/Tabs/UserGroups.vue';
import TabIntegrationRequests from '@/views/Company/Tabs/integration-requests.vue';

const USERS_TAB = 'users';
const INTEGRATIONS_TAB = 'integrations';
const API_KEYS_TAB = 'apikeys';
const LOGS_TAB = 'logs';
const USER_GROUPS_TAB = 'user_groups';
const INTEGRATION_REQUESTS_TAB = 'integration_requests';

export default {
  name: 'Company',
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView', 'isAdminHost']),
    ...mapGetters('company', ['currentCompanyId', 'company', 'companyInProgress']),

    breadcrumbs() {
      return [
        {
          label: this.$t('companies.title'),
          to: '/admin/companies',
          visible: this.isAdminHost,
        },
        {
          label: this.isAdminDomain ? this.company.name : this.$t('company.title'),
        },
      ].filter((i) => i.visible ?? true);
    },

    companyId() {
      return this.$route.params?.company_id || this.currentCompanyId;
    },

    selectedTab() {
      return this.$route?.params?.tab || USERS_TAB;
    },

    tabs() {
      return [
        {
          id: USERS_TAB,
          label: this.$t('company.tabs.users'),
          enabled: true,
          content: TabUsers,
        },
        {
          id: USER_GROUPS_TAB,
          label: this.$t('company.tabs.groups'),
          enabled: true,
          content: TabUserGroups,
        },
        {
          id: INTEGRATIONS_TAB,
          label: this.$t('company.tabs.integrations'),
          enabled: this.company.integrations_enabled && !this.isAdminHost,
          content: TabIntegrations,
        },
        {
          id: API_KEYS_TAB,
          label: this.$t('company.tabs.apikeys'),
          enabled: true,
          content: this.isAdminHost ? TabApiKeysAdmin : TabApiKeys,
        },
        {
          id: LOGS_TAB,
          label: this.$t('company.tabs.logs'),
          enabled: !this.isAdminHost,
          content: TabLogs,
        },
        {
          id: INTEGRATION_REQUESTS_TAB,
          label: this.$t('company.tabs.integration_requests'),
          enabled: !this.isAdminHost,
          content: TabIntegrationRequests,
        },
      ].filter((tab) => tab.enabled);
    },

    tabContent() {
      const tab = this.tabs.find((item) => item.id === this.selectedTab);
      return tab?.content;
    },
  },
  watch: {
    companyId: {
      immediate: true,
      flush: 'post',
      handler() {
        this.fetchCompany();
      },
    },
  },

  methods: {
    fetchCompany() {
      this.$store.dispatch('company/fetchCompany', this.isAdminHost ? this.companyId : null);
    },

    selectTab(tab) {
      this.$router.push(
        this.$adminCompanyLinkModifier({
          name: 'CompanyShow',
          params: { tab },
        }),
      );
    },

    editCompanyInfo() {
      this.$router.push(
        this.$adminCompanyLinkModifier({
          name: 'CompanyEdit',
        }),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

$hero-padding: 40px;
$hero-escape: 80px;

.hero {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.company-name,
.page-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.company-info {
  & > span {
    display: inline-block;
    margin-right: 5px;
    color: rgba($white, 0.5);
  }
}

.company-page {
  padding: 30px;

  &__name {
    font-size: 24px;
  }

  &__desc {
    display: flex;
    margin-top: 15px;
    margin-bottom: 20px;
    gap: 10px 32px;
  }

  :deep(.ui-button) {
    align-self: center;
  }

  &__company-id,
  &__business-id {
    background-color: var(--bb-app-bg-main);
    min-width: 393px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 20px;
    font-size: 18px;
    border-radius: 10px;

    & :last-child {
      font-weight: bold;
      font-size: 24px;
      color: var(--bb-primary);
    }
  }

  &__company {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 1300px) {
  .hero {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .page-name {
    font-size: 21px;
  }

  .company-page {
    &__company-id,
    &__business-id {
      min-width: 300px;

      & span {
        font-size: 16px;
      }

      & :last-child {
        font-size: 18px;
      }
    }

    &__name {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1030px) {
  .company-page {
    padding: 0;

    &__header {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 1128px) {
  .page-name {
    font-size: 18px;
  }

  .company-page {
    &__name {
      font-size: 16px;
    }

    &__company-id,
    &__business-id {
      width: 100%;

      & span {
        font-size: 16px;
      }

      & :last-child {
        font-size: 18px;
      }
    }

    &__business-id {
      margin-left: 0;
    }

    &__desc {
      flex-direction: column;
    }

    &__company {
      padding: 15px;
    }

    &__tab-container {
      padding: 0;
    }
  }
}
</style>
