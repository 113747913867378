import AppBaseModel from './AppBaseModel';

class Administrators extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'administrators',
      singularKey: 'administrator',
      adminApi: true,
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default Administrators;
