class DataroomFormData {
  constructor(data) {
    this.editable = data.editable ?? 'ALL';
    this.visible = data.visible ?? 'ALL';
    this.fields = data.fields ?? [];
    this.form_manager = data.form_manager ?? false;
    this.form_type = data.form_type ?? null;
    this.language = data.language ?? 'en';
    this.lock_after_fill = data.lock_after_fill ?? false;
    this.multi_entries = data.multi_entries ?? false;
    this.multi_entries_result_visibility = data.multi_entries_result_visibility ?? null;
    this.multi_entries_unique_user = data.multi_entries_unique_user ?? null;
    this.public_form = data.public_form ?? null;
    this.discarded_at = data.discarded_at ?? null;
  }
}

export default DataroomFormData;
