<template lang="pug">
.ui-tree-item__wrapper(v-bind="$attrs")
  .ui-tree-item(@click="() => $emit('select')" :class="class")
    .ui-tree-item__toggle-container
      UiButton(
        v-if="collapsable"
        variant="ghost-secondary"
        round
        size="xs"
        block
        @click.stop="() => $emit('toggle')"
      )
        template(#icon)
          UiIcon(name="ChevroneBottom" size="1em")
    .ui-tree-item__label
      slot(name="label") {{ label }}
  .ui-tree-item__children(v-if="opened")
    slot
</template>

<script>
export default {
  emits: ['toggle', 'select'],
  props: {
    label: {
      type: String,
      default: '',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    class() {
      return {
        'ui-tree-item--collapsable': this.collapsable,
        'ui-tree-item--opened': this.opened,
        'ui-tree-item--active': this.active,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-tree-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
  min-height: 1.875rem;

  &:deep(.ui-button .ui-icon) {
    transition: all 230ms ease-in-out;
  }

  &--opened .ui-button .ui-icon {
    transform: rotate(180deg);
  }

  &--active {
    background: rgba(var(--bb-primary-rgb), 0.25);
    font-weight: 600;
  }

  &__toggle-container {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__children {
    padding-left: 1.5rem;
  }

  &__wrapper {
    position: relative;
  }

  .ui-tree-item__children &__wrapper:before {
    content: '';
    position: absolute;
    display: block;
    border-left: 1px solid var(--bb-gray-800);
    top: 0;
    left: -0.75em;
    height: 100%;
  }

  .ui-tree-item__children &__wrapper:last-child:before {
    height: calc(1.875rem / 2);
  }

  .ui-tree-item__children &__wrapper:after {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 1px solid var(--bb-gray-800);
    top: calc(1.875rem / 2);
    left: -0.75em;
    width: 0.35em;
  }
}
</style>
