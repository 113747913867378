<template lang="pug">
.tab-container
  TabHeader
    template(#title) {{ $t('company.tabs.integration_activation_requests') }}
  .tab-container__pending-table
    .tab-container__subtitle {{ $t('integration_requests.pending_requests') }}
    PendingTable(@request-accepted="updateHistoryTable" @request-declined="updateHistoryTable")
  .tab-container__history-table
    .tab-container__subtitle {{ $t('integration_requests.history') }}
    HistoryTable(ref="historyTable")
</template>

<script>
import PendingTable from '@/views/Company/Tabs/integration-requests/tables/pending.vue';
import HistoryTable from '@/views/Company/Tabs/integration-requests/tables/history.vue';

export default {
  components: {
    PendingTable,
    HistoryTable,
  },

  methods: {
    updateHistoryTable() {
      this.$refs.historyTable.refreshData();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-container {
  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    padding: 0.875rem 1.5rem;
  }

  &__history-table {
    padding-top: 2.875rem;
  }
}
</style>
