import locales from '@/localization/locales.js';
import { mapGetters } from 'vuex';
import eventBus from '@/eventBus.js';

const LocaleMixin = {
  computed: {
    ...mapGetters(['currentLanguage']),

    languages() {
      return locales;
    },
  },

  methods: {
    switchLanguage(locale) {
      this.$store.dispatch('switchLanguage', locale);
      eventBus.emit('LocaleChanged', locale);
    },
  },
};

export default LocaleMixin;
