<template lang="pug">
DataTableRowActions(:actions="actions" :moreActions="moreActions")
</template>

<script>
import Company from '@/models/Company.js';
import { mapGetters } from 'vuex';

export default {
  emits: ['edit', 'delete', 'reload', 'manageAccess'],

  props: {
    entity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompanyId', 'currentCompany']),

    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },

    actions() {
      const actions = [];

      if (this.isAdmin && !this.isViewAsOtherUser) {
        actions.push({
          icon: 'TwoUsers',
          label: this.$t('actions.manage_access'),
          onClick: () => this.$emit('manageAccess'),
        });
      }

      return actions;
    },

    moreActions() {
      const actions = [];

      if (this.entity.entity_access.update) {
        actions.push({
          icon: 'PencilCreate',
          label: this.$t('actions.edit'),
          onClick: () => this.$emit('edit'),
        });
      }

      if (!this.entity.data.locked && this.entity.entity_access.destroy) {
        actions.push({
          icon: 'Trash',
          label: this.$t('actions.delete'),
          onClick: () => this.$emit('delete'),
          variant: 'danger',
        });
      }

      return actions;
    },
  },
};
</script>
