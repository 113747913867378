<template lang="pug">
UiBadge(:variant="statusVariant") {{ statusLabel }}
</template>

<script>
const StatusLabel = {
  accepted: 'integration_requests.status.approved',
  declined: 'integration_requests.status.declined',
};

const StatusBadgeVariant = {
  accepted: 'primary',
  declined: 'error',
  unknown: 'secondary',
};

export default {
  props: {
    status: {
      type: String,
      default: 'declined',
    },
  },
  computed: {
    statusLabel() {
      return this.$t(StatusLabel[this.status] ?? this.status);
    },
    statusVariant() {
      return StatusBadgeVariant[this.status] ?? StatusBadgeVariant.unknown;
    },
  },
};
</script>

<style scoped lang="scss"></style>
