<template lang="pug">
.access-tree
  UiCardSection.access-tree__path-container(
    :class="{ 'access-tree__path-container-mobile': mobileView }"
  )
    UiBreadcrumbs(:items="breadcrumbs" :size="mobileView ? 'sm' : 'xl'")
    UiButton(
      v-if="mobileView"
      :icon="expandedTree ? 'Close' : 'MenuBurger'"
      size="lg"
      variant="text-secondary"
      @click="expandedTree = !expandedTree"
    )
  .access-tree__content-layout
    .access-tree__sidebar(v-if="expandedTree")
      UiTree(
        rootId="root"
        :items="foldersList"
        :activeEntityId="activeEntityId"
        :class="{ 'access-tree__sidebar-mobile': mobileView }"
        @select="onSelect"
      )
    .access-tree__content
      UiCardSection
        UiStack(
          justify="space-between"
          align="end"
          wrap
        )
          UiInput.access-tree__input-search(
            :modelValue="filters.search"
            :placeholder="$t('user_groups.filters.search_entities')"
            inputSize="20"
            icon="Search"
            data-vi="search"
            @update:modelValue="updateFilters({ search: $event })"
          )
          UiStack.column-mobile(align="stretch" justify="stretch")
            UiStack(vertical spacing="xs")
              UiInputLabel &nbsp;
              span.access-tree__filter {{ $t('user_groups.filters.filter_by') }}
              UiIcon.access-tree__filter-icon(name="Filter" size="24")
            UiStackItem.access-tree__filter-column
              UiInputLabel.text-secondary(size="sm") {{ $t('user_groups.filters.label_types') }}
              UiMultiselect(
                :modelValue="filters.types"
                :placeholder="$t('user_groups.filters.event_type_input_placeholder')"
                :options="typesOptionsDynamic"
                :allow-empty="true"
                :hide-selected="false"
                :size="mobileView ? 'sm' : 'md'"
                mode="multiple"
                data-vi="types"
                @update:modelValue="updateFilters({ types: $event })"
              )
            UiStackItem.access-tree__filter-column
              UiInputLabel.text-secondary(size="sm") {{ $t('user_groups.filters.label_groups') }}
              UiMultiselect(
                :modelValue="filters.group_ids"
                :placeholder="$t('user_groups.filters.event_groups_input_placeholder')"
                :options="userGroupsOptions"
                :allow-empty="true"
                :hide-selected="false"
                :size="mobileView ? 'sm' : 'md'"
                mode="multiple"
                data-vi="groups"
                @update:modelValue="updateFilters({ group_ids: $event })"
              )
      .access-tree__wrapper-table
        UiDataTable(
          :headers="headers"
          :items="entities"
          :loading="loading"
          hover
          @rowClick="onRowClick"
        )
          template(#cell:type="{ item }")
            EntityTypeIcon(:entity="item")
          template(#cell:created_at="{ item }")
            | {{ $d(item.created_at) }}
          template(#cell:updated_at="{ item }")
            | {{ $d(item.updated_at) }}
          template(#cell:groups="{ item }")
            ExpandableList(:list="item.user_groups")
        InfiniteLoading(
          :disabled="!entities.length || error"
          :loading="loading"
          @load="fetchMoreEntities"
        )
        UiCardSection(v-if="error" padding="2xl")
          UiEmptyState(
            style="min-height: 16rem"
            icon="AlertTriangle"
            :title="$t('user_groups.access_tree.title_can_not_load')"
            :description="error"
          )
            template(#actions)
              UiButton(
                icon="RefreshCw"
                variant="smooth-danger"
                size="sm"
                @click="fetchTree"
              ) {{ $t('actions.retry') }}
        UiCardSection(
          v-else-if="!loading && !this.entities.length && activeEntityId === null"
          padding="2xl"
        )
          UiEmptyState(
            style="min-height: 16rem"
            icon="Account"
            :title="$t('user_groups.access_tree.title_empty')"
            :description="$t('user_groups.access_tree.description_not_access_entity')"
          )
        UiCardSection(v-else-if="!loading && !this.entities.length" padding="2xl")
          UiEmptyState(
            style="min-height: 16rem"
            icon="Folder"
            :title="$t('user_groups.access_tree.title_folder_is_empty')"
            :description="$t('user_groups.access_tree.description_not_access_entity_in_folder')"
          )
        UiCardSection(v-else)
</template>

<script>
import AccessTreeFolders from '@/api/models/AccessTreeFolders.js';
import AccessTreeEntities from '@/api/models/AccessTreeEntities.js';
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import ExpandableList from '@/components/UserGroups/ExpandableList.vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import { buildPath } from '@/common/build-path.js';
import { mapGetters } from 'vuex';
import { clearDefaultValues } from '@/common/clear-default-values.js';
import EntityTypeKey from '@/helper/entity-type-key.js';

function getDefaultFilters() {
  return {
    search: '',
    types: [],
    group_ids: [],
  };
}

export default {
  components: {
    ExpandableList,
    InfiniteLoading,
  },
  data() {
    return {
      loading: true,
      errors: { folders: null, entities: null },
      activeEntityId: null,
      entities: [],
      folders: [],
      userGroups: [],
      currentPage: 1,
      meta: null,
      abortController: null,
      expandedTree: true,
      accessibleTypes: [],
      filters: getDefaultFilters(),
    };
  },
  computed: {
    ...mapGetters(['isAdminDomain', 'mobileView']),

    error() {
      return this.errors.entities || this.errors.folders || null;
    },
    headers() {
      return [
        {
          key: 'type',
          label: this.$t('user_groups.headers.label_type'),
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('user_groups.headers.label_name'),
        },
        {
          key: 'created_at',
          label: this.$t('user_groups.headers.label_created'),
          width: '16%',
        },
        {
          key: 'updated_at',
          label: this.$t('user_groups.headers.label_last_update'),
          width: '16%',
        },
        {
          key: 'groups',
          label: this.$t('user_groups.headers.label_groups'),
          width: '30%',
        },
      ];
    },

    typesOptionsDynamic() {
      const options = ['Folder', ...this.accessibleTypes];
      return options.map((key) => ({
        value: key,
        label: this.$t(`user_groups.entity_name.${EntityTypeKey[key] ?? key}`),
      }));
    },

    rootEntity() {
      return {
        id: null,
        name: this.$t('user_groups.user_access'),
        parent_id: 'root',
        type: 'Folder',
      };
    },

    foldersWithRoot() {
      return [this.rootEntity, ...this.folders];
    },

    path() {
      return buildPath(this.foldersWithRoot, this.activeEntityId);
    },

    breadcrumbs() {
      return this.path.map((folder) => ({
        label: folder.name,
        onClick: () => this.moveToFolder(folder.id),
      }));
    },

    foldersList() {
      return this.foldersWithRoot;
    },

    userGroupsOptions() {
      return this.userGroups.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    },
  },

  watch: {
    filters() {
      this.resetEntitiesWhenFilter();
    },
    mobileView() {
      this.expandedTree = !this.mobileView;
    },
  },

  mounted() {
    this.fetchFilters();
    this.fetchTree();
  },

  methods: {
    async updateFilters(partialFilters) {
      this.filters = {
        ...this.filters,
        ...partialFilters,
      };
      await this.$router.replace({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          filters: JSON.stringify(clearDefaultValues(this.filters, getDefaultFilters())),
        },
      });
    },

    fetchFilters() {
      try {
        this.filters = {
          ...this.filters,
          ...JSON.parse(this.$route.query.filters),
        };
      } catch {
        // Ignore if filters string broken
      }
    },

    async fetchTree() {
      try {
        await this.fetchFolders();
        await this.fetchEntities();
        await this.fetchUserGroups();
      } finally {
        this.activeEntityId = null;
      }
    },

    async fetchFolders() {
      try {
        this.loading = true;
        this.errors.folders = null;
        const response = await new AccessTreeFolders({
          adminApi: this.isAdminDomain,
          owner: new CompanyUsers(
            {
              owner: this.isAdminDomain
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_id },
          ),
        }).fetchAll();
        this.folders = response.data.folders;
      } catch (error) {
        this.errors.folders = this.$localizeErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchEntities() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();

      try {
        this.loading = true;
        this.errors.entities = null;
        const response = await new AccessTreeEntities({
          adminApi: this.isAdminDomain,
          owner: new CompanyUsers(
            {
              owner: this.isAdminDomain
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_id },
          ),
        }).fetchAll(
          {
            parent_id: this.activeEntityId,
            page: this.currentPage,
            per_page: 30,
            ...this.filters,
          },
          {
            signal: this.abortController.signal,
          },
        );
        const { entities, meta } = response.data;

        this.meta = meta;
        this.entities = [...this.entities, ...entities];
        this.accessibleTypes = response.data.accessible_types ?? [];
      } catch (error) {
        if (error.response && error.message !== 'canceled') {
          this.errors.entities = this.$localizeErrorMessage(error);
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchUserGroups() {
      try {
        const response = await new UserGroups({
          adminApi: this.isAdminDomain,
          owner: new CompanyUsers(
            {
              owner: this.isAdminDomain
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_id },
          ),
        }).fetchAll({
          personal: true,
          per_page: 100,
        });
        this.userGroups = response.data.user_groups;
      } catch (error) {
        // TODO: Add exception case
      }
    },

    fetchMoreEntities() {
      if (this.loading || this.entities.length >= this.meta?.total_count) {
        return;
      }
      this.currentPage += 1;
      this.fetchEntities();
    },

    resetEntitiesWhenFilter() {
      this.currentPage = 1;
      this.entities = [];
      this.fetchEntities();
    },

    moveToFolder(entityId) {
      this.activeEntityId = entityId;
      this.currentPage = 1;
      this.entities = [];
      this.fetchEntities();
    },

    onSelect(item) {
      this.moveToFolder(item.entity.id);
    },

    onRowClick({ item }) {
      if (['Folder', 'PowerBiReportGroup'].includes(item.type)) {
        this.moveToFolder(item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/stylesheet/tree-table-filters.scss' as filters;

$min-tablet-width: 1355px;
$max-tablet-width: 1390px;
@include filters.media-queries($min-tablet-width, $max-tablet-width);

@media screen and (max-width: $min-tablet-width) {
  .access-tree {
    &__content-layout {
      flex-direction: column;
    }

    &__sidebar {
      width: 100%;
      padding: 16px;
    }

    &__wrapper-table {
      padding: 16px 0 0 16px;
    }
  }
}
</style>
