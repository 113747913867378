<template lang="pug">
UiCardSection(padding="lg")
  UiStack(
    vertical
    align="stretch"
    spacing="sm"
  )
    UiStackItem
      UiText(variant="headingMd" data-test-id="title") {{ $t('portal_management.integrations.title') }}
  .integration-list
    UiDraggableList(
      :list="integrations"
      :isDraggable="!inProgress"
      keyName="id"
      data-test-id="draggable-list"
      @dropItem="onChangeOrder"
    )
      template(v-slot="{ item, isDragging, isDraggable }")
        IntegrationListItem(
          :integration="item"
          :isDragging="isDragging"
          :isDraggable="isDraggable"
          data-test-id="list-item"
        )
    InfiniteLoading(
      :loading="loading"
      :disabled="inProgress || reachEnd"
      :error="error"
      @load="fetchMore"
      @retry="fetchIntegrations"
    )
</template>

<script>
import { arrayMoveImmutable } from '@/common/array-move.js';
import IntegrationsSoftware from '@/api/models/integrations-software.js';
import IntegrationListItem from '@/views/Admin/PortalManagement/components/integrations/list-item.vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';

const INTEGRATIONS_PER_PAGE = 30;

export default {
  components: {
    IntegrationListItem,
    InfiniteLoading,
  },
  data() {
    return {
      loading: false,
      page: 1,
      reachEnd: false,
      error: null,
      integrations: [],
      integrationsOld: [],
      inProgress: false,
    };
  },

  mounted() {
    this.fetchIntegrations();
  },

  methods: {
    async fetchIntegrations() {
      this.error = null;
      this.loading = true;
      try {
        const result = await new IntegrationsSoftware().fetchAll({
          page: this.page,
          per_page: INTEGRATIONS_PER_PAGE,
        });
        const collection = result.data.integration_softwares;
        if (collection.length < INTEGRATIONS_PER_PAGE) {
          this.reachEnd = true;
        }
        this.integrations = [...this.integrations, ...collection];
      } catch (error) {
        this.error = error;
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.loading = false;
      }
    },

    fetchMore() {
      if (this.error || this.reachEnd || this.loading || this.inProgress) {
        return;
      }
      this.page++;
      this.fetchIntegrations();
    },

    onChangeOrder({ to, from }) {
      this.integrationsOld = this.integrations;
      this.integrations = arrayMoveImmutable(this.integrations, from.index, to.index);
      this.updateIntegrationPosition(from.item, to.index);
    },

    async updateIntegrationPosition(integration, position) {
      const inProgressToast = this.$toaster.add({
        loading: true,
        allowClose: false,
        actions: null,
        timeout: null,
        title: this.$t('status.in_progress'),
        message: this.$t('status.in_progress'),
      });
      this.inProgress = true;
      try {
        await new IntegrationsSoftware(null, { id: integration.id }).updatePosition({
          position: position + 1,
        });
      } catch (error) {
        this.integrations = this.integrationsOld;
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.$toaster.destroy(inProgressToast);
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-list {
  margin-top: 30px;
}
</style>
