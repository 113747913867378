<template lang="pug">
UiModal(
  :show="show"
  :title="`${title} ${$t('company.integrations.integration_details')}`"
  width="520"
  @close="close"
)
  p
    span(v-if="customDetails")
      | {{ customDetails }} #[a(v-if="customDetailsLink" :href="customDetailsLink.link") {{ customDetailsLink.display_name }}]
    span(
      v-else
      v-html="$t(`company.integrations.details.${integration?.software}`)"
      data-vi="software"
    )
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyIntegrationDetailsModal',
  data() {
    return {
      show: false,
      integration: null,
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    title() {
      return (
        this.integration?.integration_details[`title_${this.currentLanguage.value}`] ||
        this.integration?.integration_details.title_en
      );
    },

    customDetails() {
      return this.integration?.integration_details?.helper_text ?? null;
    },

    customDetailsLink() {
      const url = this.integration?.integration_details?.url;
      if (url) {
        return {
          link: url.link,
          display_name: url.display_name,
        };
      }
      return null;
    },
  },

  methods: {
    open(integration) {
      this.integration = integration;
      this.show = true;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/stylesheet/variables';
.details-modal {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
  &.modal-content {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
