const OnboardingErrorType = {
  COMPANY_EXIST: 'company_exist',
  INTEGRATION_NOT_FOUND: 'integration_not_found',
  INTEGRATION_ATTEMPT_NOT_FOUND: 'integration_attempt_not_found',
  CAN_NOT_FIND_USER_OR_COMPANY: 'can_not_find_user_or_company',
  YOU_DO_NOT_BELONG_TO_THIS_COMPANY: 'you_do_not_belong_to_this_company',
  NO_HAVE_ADMIN_ACCESS: 'no_have_admin_access',
};

export default OnboardingErrorType;
