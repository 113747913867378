<template lang="pug">
.ui-info
  UiTooltip(size="lg" interactive)
    .ui-info__content
      slot
    template(#activator)
      slot(name="activator")
        .ui-info__help {{ iconLabel }}
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
      iconLabel: {
        type: String,
        default: '?'
      }
  }
})
</script>

<style lang="scss">
.ui-info {
  display: inline-block;
  vertical-align: middle;

  &__content {
    & h4 {
      font-size: 1.25em;
      font-weight: 600;
    }

    & h5 {
      font-size: 1.1em;
      font-weight: 600;
    }

    & pre,
    & code {
      border: 1px solid var(--bb-input-border);
      background: var(--bb-gray-950);
      font-size: 0.85em;
    }

    & pre {
      padding: 0.65em 1em;
      border-radius: 0.4em;
      word-wrap: break-word;
      white-space: normal;
    }

    & code {
      padding: 0.25em;
      border-radius: 0.25em;
    }
  }

  &__help {
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5em;
    font-size: 0.75em;
    line-height: 1em;
    background: var(--bb-gray-700);
    color: var(--bb-app-bg-main);
    margin-top: -0.2em;
  }
}
</style>
