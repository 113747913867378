import AppBaseModel from './AppBaseModel.js';

class DynamicSettings extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      adminApi: true,
      path: 'dynamic_settings',
      pluralKey: 'dynamic_settings',
      singularKey: 'dynamic_setting',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default DynamicSettings;
