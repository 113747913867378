<template lang="pug">
.admin-layout
  .admin-layout__breadcrumps-container
    AppBreadcrumbs(:items="breadcrumbs" showBack)
  Heading(:title="$t('users.companies.title')")
    template(v-slot:subtitle)
      .subtitle(v-if="user")
        span.subtitle__title {{ $t('users.companies.user') }}
        span {{ user.name || user.email }}
  .table-container
    RestTable(
      ref="table"
      :headers="companiesHeaders"
      :fetchHandler="fetchCompanies"
    )
      template(v-slot:cel_name="{ item }")
        RouterLink(:to="{ name: 'AdminCompanyShow', params: { company_id: item.id } }") {{ item.name }}
      template(v-slot:cel_bussinesId="{ item }")
        | {{ item.business_id }}
      template(v-slot:cel_role="{ item }")
</template>

<script>
import Companies from '@/api/models/Companies.js';
import Users from '@/api/models/Users.js';

export default {
  name: 'UserCompanies',
  data() {
    return {
      user: null,
    };
  },

  computed: {
    companiesHeaders() {
      return [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('users.companies.table.name'),
        },
        {
          key: 'bussinesId',
          label: this.$t('users.companies.table.business_id'),
        },
        {
          key: 'role',
          label: this.$t('users.companies.table.role'),
        },
      ];
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('users.title'),
          to: '/admin/users',
        },
        {
          label: this.user?.name ?? this.user?.email ?? this.$t('users.companies.title'),
          to: this.$route.path,
        },
      ];
    },
  },

  mounted() {
    this.fetchCurrentUser();
  },

  methods: {
    async fetchCompanies() {
      try {
        const companiesResponse = await new Companies({
          adminApi: true,
          owner: new Users(null, { id: this.$route.params.id }),
        }).fetchAll();
        return {
          items: companiesResponse.data.companies,
          meta: companiesResponse.data.meta,
        };
      } catch {
        return {
          items: [],
          meta: null,
        };
      }
    },

    fetchCurrentUser() {
      new Users({ adminApi: true }, { id: this.$route.params.id }).fetch().then((response) => {
        this.user = response.data.user;
      });
    },
  },
};
</script>

<style lang="scss">
.table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.subtitle {
  font-size: 16px;
  &__title {
    margin-right: 10px;
    font-weight: bold;
  }
}

.admin-users__table {
  &__name {
    display: flex;
    align-items: center;

    & img {
      width: 34px;
      height: 34px;
      border-radius: 17px;
      margin-right: 15px;
    }
  }
  &__counter {
    margin-left: 10px;
  }
}

.admin__comapnies-table__company {
  cursor: pointer;
}
</style>
