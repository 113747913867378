<template lang="pug">
.color-picker
  UiPopover(
    :show="show"
    placement="right-start"
    :offset="[0, 10]"
    :hideOnClick="false"
    @hide="hidePopupHandler"
  )
    template(#activator)
      .color-picker__activator(@click="open")
        slot(v-bind="{ color, initialColor }" name="activator")
          div {{ color }}
    UiCard.color-picker__picker
      UiCardSection
        ColorPickerBase(
          :color="color"
          defaultFormat="hex"
          alphaChannel="hide"
          @colorChange="colorChangeHandler"
        )
        .color-picker__footer
          UiButton(
            size="sm"
            variant="smooth-primary"
            @click="cancel"
          ) {{ $t('cancel') }}
          UiButton(size="sm" @click="apply") {{ $t('apply') }}
</template>

<script>
import { ColorPicker as ColorPickerBase } from 'vue-accessible-color-picker';

export default {
  components: {
    ColorPickerBase,
  },
  emits: ['change', 'apply', 'cancel'],
  props: {
    color: {
      type: String,
      default: '#ff0000',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    changeOnApplyOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      initialColor: '#ff0000',
      currentColor: '#ff0000',
    };
  },
  methods: {
    open() {
      if (this.show || this.disabled) {
        return;
      }
      this.initialColor = this.color;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    apply() {
      this.close();
      this.$emit('apply', this.color);
      this.changeColor();
    },
    cancel() {
      this.close();
      this.$emit('cancel', this.initialColor);
    },
    colorChangeHandler(event) {
      this.currentColor = event.colors.hex;
      if (this.changeOnApplyOnly) return;
      this.changeColor();
    },
    changeColor() {
      this.$emit('change', this.currentColor);
    },
    hidePopupHandler() {
      if (this.changeOnApplyOnly) {
        this.close();
      } else {
        this.apply();
      }
    },
  },
};
</script>

<style lang="scss">
.color-picker {
  position: relative;

  &__picker {
    min-width: 336px;
  }

  &__footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.vacp-color-picker {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  background: var(--bb-app-bg-main) !important;
  color: var(--bb-text-color);

  .vacp-color {
    &-input {
      border-radius: 15px;
      border-color: var(--bb-input-border);
      background: var(--bb-app-bg-main);
    }

    &-inputs {
      align-items: flex-end;
    }

    &-space {
      border-radius: 5px;
      overflow: visible;
    }

    &-space-thumb {
      background: hsl(
        calc(var(--vacp-hsl-h) * 360) calc(var(--vacp-hsl-s) * 100%) calc(var(--vacp-hsl-l) * 100%)
      );
    }
  }

  .vacp-format-switch-button {
    background: inherit;
  }

  .vacp-copy-button {
    display: none; // hide copy button
  }
}
</style>
