import AppBaseModel from './AppBaseModel.js';

class Credentials extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      adminApi: true,
      path: 'credentials',
      pluralKey: 'credentials',
      singularKey: 'credential',
      ...options,
    };
    super(mergedOptions, item);
  }

  regenerate() {
    return this.httpClient.post(`${this.entityUrl}/regenerate`);
  }
}

export default Credentials;
