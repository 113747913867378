import _cloneDeep from 'lodash/cloneDeep.js';

const SET_ORIGINAL_COMPANY = (state, value) => {
  state.originalCompany = _cloneDeep(value);
};

const SET_COMPANY = (state, value) => {
  state.company = value;
};

const SET_LOADING = (state, value) => {
  state.loading = value;
};

const SET_IN_PROGRESS = (state, value) => {
  state.inProgress = value;
};

const UPDATE_COMPANY = (state, value) => {
  state.company = {
    ...state.company,
    ...value,
  };
};

export default {
  SET_ORIGINAL_COMPANY,
  SET_COMPANY,
  SET_LOADING,
  UPDATE_COMPANY,
  SET_IN_PROGRESS,
};
