<template lang="pug">
.ui-empty-state(v-bind="$attrs")
  .ui-empty-state__icon(v-if="icon || $slots.icon")
    slot(name="icon")
      UiIcon(:name="icon")
  .ui-empty-state__body
    .ui-empty-state__title(v-if="title || $slots.title")
      slot(name="title") 
        span.ui-empty-state__text {{ title }}
    .ui-empty-state__description(v-if="description || $slots.description")
      slot(name="description")
        span.ui-empty-state__text {{ description }}
    .ui-empty-state__actions(v-if="$slots.actions")
      slot(name="actions")
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.ui-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1rem;

  .ui-empty-state__text {
    white-space: pre-wrap;
  }

  &__icon {
    font-size: 3rem;
    color: var(--bb-gray-800);
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1;
  }

  &__title {
    font-size: 1.2rem;
  }

  &__description {
    color: var(--bb-gray-700);
    margin-top: 0.5rem;
  }

  &__actions {
    margin-top: 1rem;
  }
}
</style>
