<template lang="pug">
.user-select-tag
  UserCard(v-bind="user" avatarSize="xs")
  .user-select-tag__remove(:class="removeClasses" @click="$emit('remove', $event)")
    UiIcon(name="Close")
</template>

<script>
export default {
  emits: ['remove'],

  props: {
    user: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    removeClasses() {
      return {
        'user-select-tag__remove--disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.user-select-tag {
  padding: 0.25rem 0.45rem;
  margin-left: 3px;
  margin-bottom: 3px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  background: var(--bb-app-bg);
  border-left: 2px solid var(--bb-primary);
  font-size: 0.9rem;

  &__remove {
    &:not(:hover) {
      color: var(--bb-gray-700);
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>
