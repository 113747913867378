import BaseModel from './BaseModel.js';
import { pathJoin } from './utils.js';

class AppBaseModel extends BaseModel {
  constructor(options, item, httpClient) {
    super(options, item, httpClient);
    this.apiPrefix = options?.apiPrefix ?? true;
    this.adminApi = options?.adminApi ?? false;
  }

  get basePath() {
    const path = [];
    if (this.apiPrefix) {
      path.push('/api/v1');
    }
    if (this.adminApi) {
      path.push('/admin');
    }
    return path.length ? pathJoin(super.basePath, ...path) : super.basePath;
  }
}

export default AppBaseModel;
