<template lang="pug">
UiModal(
  :show="show"
  :title="isEdit ? $t('company.users.edit_user') : $t('company.users.add_new_user')"
  :subtitle="isEdit ? null : $t('company.invite_user_description')"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :width="540"
  @close="close"
)
  Stack(vertical align="stretch")
    UiAlert(
      v-if="errorText"
      variant="error"
      @close="errorText = null"
    ) {{ errorText }}
    UiStackItem
      UiInputLabel {{ $t('company.users.name') }}
      UiInput(
        v-model="form.name"
        :error="v$.form.name.$error"
        :disabled="inProgress"
        @blur="v$.form.name.$touch"
      )
      UiInputFeedback(v-for="$error in v$.form.name.$errors" error) {{ $error.$message }}
    UiStackItem
      UiInputLabel {{ $t('user.email') }}
      UiInput(
        v-model="form.email"
        :disabled="inProgress"
        :readonly="isEdit"
        :error="v$.form.email.$error"
        @blur="v$.form.email.$touch"
      )
      UiInputFeedback(v-for="$error in v$.form.email.$errors" error) {{ $error.$message }}
    UiStackItem(v-if="company.report_language_visible")
      UiInputLabel {{ $t('company.reporting_language') }}
      LanguageSelect(v-model="form.report_language" :disabled="inProgress")
    UiStackItem
      UiInputLabel {{ $t('company.users.user_role') }}
      UiMultiselect(
        v-model="form.role"
        :canDeselect="false"
        :canClear="false"
        :options="userRoles"
        :disabled="inProgress"
      )
    UiStackItem
      UiInputLabel {{ $t('user_groups.title') }}
      .user-groups-list
        template(v-if="loading")
          .user-groups-list__info-layout
            .state-box.state-box--loading
              .state-box__message
                Stack(align="center")
                  UiSpinner(speed="slow" size="lg")
                  | {{ $t('user_groups.modals.entity_access_list.loading_entity_groups') }}
        template(v-else-if="error")
          .user-groups-list__info-layout
            .state-box.state-box--error
              Stack(vertical align="center")
                .state-box__message {{ $t('user_groups.modals.entity_access_list.filed_fetch_entity_groups') }}
                UiButton(
                  size="sm"
                  icon="Refresh"
                  variant="smooth-danger"
                  @click="fetchState"
                ) {{ $t('actions.retry') }}
        template(v-else)
          .user-groups-list-item(
            v-for="group in groups"
            :class="groupClasses(group)"
            @click="toggleGroup(group)"
          )
            UiCheckbox(:modelValue="groupSelected(group)") {{ group.name }}
    UiCheckbox(
      v-model="form.receive_notifications"
      switch
      :disabled="inProgress"
    ) {{ $t('company.new_receive_notifications') }}
    UiCheckbox(
      v-model="form.enable_odata_access"
      switch
      :disabled="inProgress"
    ) {{ $t('company.new_user_enable_odata_access') }}
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import Companies from '@/api/models/Companies.js';
import Company from '@/models/Company.js';
import LanguageSelect from '@/components/LanguageSelect.vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@/common/validators.js';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import { ref } from 'vue';
import UserGroups from '@/api/models/UserGroups.js';
import { arrayToggle } from '@/common/array-toggle.js';

function getEmptyForm() {
  return {
    id: null,
    name: '',
    email: '',
    role: Company.role.USER,
    report_language: 'en',
    user_group_ids: [],
    receive_notifications: false,
    enable_odata_access: false,
  };
}

export default {
  name: 'EditCompanyUserModal',

  components: {
    LanguageSelect,
  },

  emits: ['done'],

  setup() {
    const externalResults = ref({ form: {} });
    const v$ = useVuelidate({ $externalResults: externalResults, $autoDirty: true });
    return { v$, externalResults };
  },

  data() {
    return {
      loading: true,
      show: false,
      inProgress: false,
      form: getEmptyForm(),
      errorText: null,
      groups: [],
    };
  },

  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser', 'isAdminDomain']),
    ...mapGetters('company', ['company', 'currentCompany']),

    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          variant: 'smooth-secondary',
          disabled: this.inProgress,
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t(this.isEdit ? 'actions.save' : 'company.users.add_user'),
          onClick: this.save,
          loading: this.inProgress,
          disabled: this.isViewAsOtherUser,
        },
      ];
    },

    isEdit() {
      return this.form.id !== null;
    },

    userRoles() {
      return [Company.role.USER, Company.role.ADMIN].map((role) => ({
        label: this.$t(`company.role.${role}`),
        value: role,
      }));
    },
  },

  methods: {
    open({ user = {} } = {}) {
      this.errorText = null;
      this.form = {
        ...getEmptyForm(),
        ...user,
        report_language: user.report_language ?? this.company.report_language,
      };
      this.v$.$reset();
      this.fetchState();
      this.show = true;
    },

    close() {
      this.show = false;
      this.v$.$reset();
    },

    fetchGroups() {
      return new UserGroups({
        adminApi: this.isAdminDomain,
        owner: this.isAdminDomain
          ? new Companies(null, { id: this.$route.params.company_id })
          : null,
      }).fetchAll({
        per_page: 1000,
      });
    },

    async fetchState() {
      try {
        this.loading = true;
        this.error = null;
        const userGroupsResponse = await this.fetchGroups();
        this.groups = userGroupsResponse.data.user_groups;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    toggleGroup(group) {
      this.form.user_group_ids = arrayToggle(this.form.user_group_ids, group.id);
    },

    groupClasses(group) {
      return {
        'user-groups-list-item--selected': this.groupSelected(group),
        'user-groups-list-item--disabled': this.inProgress || this.loading,
      };
    },

    groupSelected(group) {
      return this.form.user_group_ids.includes(group.id);
    },

    deselectAll() {
      this.form.user_group_ids = [];
    },

    selectAll() {
      this.form.user_group_ids = this.groups.map((i) => i.id);
    },

    async save() {
      this.errorText = null;
      const valid = await this.v$.$validate();

      if (!valid) {
        return;
      }

      this.inProgress = true;
      const params = {
        id: this.form.id,
        role: this.form.role,
        name: this.form.name,
        report_language: this.form.report_language,
        receive_notifications: this.form.receive_notifications,
        user_group_ids: this.form.user_group_ids,
        enable_odata_access: this.form.enable_odata_access,
      };
      if (!this.isEdit) {
        params.email = this.form.email;
      }

      try {
        const api = new CompanyUsers(
          {
            adminApi: this.isAdminDomain,
            owner: this.isAdminDomain
              ? new Companies(null, { id: this.$route.params.company_id })
              : null,
          },
          { id: params.id },
        );

        if (this.isEdit) {
          await api.update(params);
        } else {
          await api.create(params);
        }

        if (this.form.user_id === this.user.id) {
          window.location.reload();
        }
      } catch (error) {
        if (error.response?.data?.errors) {
          const externalResults = { form: parseResponseErrors(error.response.data.errors) };
          // temp hack to get user-friendly error.To remove this 'if' after back-end fix (user_id)
          if (externalResults.form.user_id) {
            externalResults.form.email = externalResults.form.user_id;
            delete externalResults.form.user_id;
          }
          Object.assign(this.externalResults, externalResults);
          this.$toaster.add({
            type: 'error',
            title: 'Error',
            message: this.$t('validations.check_all_fields'),
          });
        }
        if (error.response?.data?.error) {
          this.errorText = this.$localizeErrorMessage(error);
        }
        return;
      } finally {
        this.inProgress = false;
      }

      this.$emit('done');
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.user-groups-list {
  border: 1px solid var(--bb-input-border);
  border-radius: 0.4rem;
  min-height: 300px;
  max-height: 50vh;
  overflow: auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__info-layout {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.state-box {
  &--loading &__message {
    color: var(--bb-gray-500);
  }

  &--error &__message {
    color: var(--bb-danger);
  }
}

.user-groups-list-item {
  padding: 0.85rem 1rem;
  border-left: 3px solid transparent;
  cursor: pointer;

  & + & {
    margin-top: 1px;
  }

  &:deep(.ui-checkbox) {
    pointer-events: none;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &--selected {
    border-color: var(--bb-primary);
    background: rgba(var(--bb-primary-rgb), 0.2);
  }

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
