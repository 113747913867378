class ChaskiqService {
  static hideChasqik() {
    document.body.classList.add('hide-chaskiq');
  }

  static showChasqik() {
    document.body.classList.remove('hide-chaskiq');
  }
}

export default ChaskiqService;
