<template lang="pug">
.tab-container
  .table-filters
    Stack(wrap)
      StackItem
        UiInput(
          :placeholder="$t('actions.search')"
          :modelValue="pagination.filter.search"
          @update:modelValue="updateFilter({ search: $event })"
        )
          template(#icon)
            UiIcon(name="SearchIcon")
      StackItem
        UiMultiselect(
          :placeholder="$t('logs.event_category_input_placeholder')"
          :options="logCategoriesOptions"
          :modelValue="pagination.filter.event_category"
          :allow-empty="true"
          @update:modelValue="updateFilter({ event_category: $event })"
        )
      StackItem
        UserSelect(
          :modelValue="pagination.filter.user"
          userType="admin_user"
          @update:modelValue="updateFilter({ user: $event })"
        )
      StackItem
        UiDatePicker(
          v-model="filterDatesModelValue"
          :range="true"
          size="md"
        )
  DataTable(
    :headers="headersList"
    :items="items"
    :sort-by="pagination.sort_by"
    :sort-order="pagination.sort_order"
    :page="pagination.page"
    :per-page="pagination.per_page"
    :search="pagination.search"
    :total-items="meta.total_count"
    @paginate="onPaginate"
  )
    template(#cel_user_id="{ item }")
      Stack(align="center" spacing="sm")
        StackItem
          AvatarBox(:src="item.user_id.avatar" size="sm")
        StackItem
          | {{ item.user_id.email }}
    template(#cel_user_name="{ item }")
      span {{ item.user_id.name }}
    template(#cel_updated_at="{ item }")
      span {{ $dateFormatter(item.updated_at) }}
    template(#cel_event_label_extended="{ item }")
      span(v-if="item.event_category === 'Credential'") {{ $t('admin_logs.master_keys') }}
      span(v-else) {{ item.event_label_extended.company_name || item.event_label_extended.name }}
</template>

<script>
import addAdminModal from '@/modals/Admin/Administrators/add.vue';
import deleteAdminModal from '@/modals/Admin/Administrators/delete.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import Pagination from '@/models/Pagination.js';
import logAdminCategoriesOptionsRaw from '@/helper/logAdminCategoriesOptionsRaw.js';
import UserSelect from '@/components/UserSelect.vue';
import tabLogic from '@/views/Admin/Logs/mixins/tabLogic.js';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';

export default {
  components: {
    UserSelect,
    DataTable,
    addAdminModal,
    deleteAdminModal,
  },
  mixins: [tabLogic],
  data() {
    const newDate = new Date();
    return {
      namespace: 'logs_all',
      pagination: new Pagination({
        search: '',
        sort_by: null,
        sort_order: 'desc',
        page: 1,
        per_page: 10,
        filter: {
          event_category: null,
          user: [],
          time_start: startOfDay(newDate),
          time_end: endOfDay(newDate),
          company: null,
        },
      }),
      headersList: [
        {
          key: 'event_category',
          label: this.$t('admin_logs.table.category'),
        },
        {
          key: 'user_id',
          label: this.$t('admin_logs.table.user'),
        },
        {
          key: 'user_name',
          label: this.$t('admin_logs.table.user_name'),
        },
        {
          key: 'updated_at',
          label: this.$t('admin_logs.table.date'),
        },
        {
          key: 'event_name',
          label: this.$t('admin_logs.table.event_name'),
        },
        {
          key: 'event_label_extended',
          label: this.$t('admin_logs.table.details'),
        },
      ],
      defaultCompanyId: -1,
    };
  },
  computed: {
    logCategoriesOptions() {
      return logAdminCategoriesOptionsRaw.map((i) => ({
        value: i.value,
        label: this.$t(i.label),
      }));
    },

    filterDatesModelValue: {
      get() {
        return [this.pagination.filter.time_start, this.pagination.filter.time_end];
      },
      set(newValue) {
        this.updateFilter({ time_start: newValue[0], time_end: newValue[1] });
      },
    },
  },
};
</script>

<style lang="scss"></style>
