import AppBaseModel from './AppBaseModel';

class IntegrationRequests extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'integration_requests',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchHistory(params = {}, config) {
    return this.httpClient.get(`${this.entityUrl}/history`, {
      params,
      ...config,
    });
  }

  accept(...rest) {
    return this.httpClient.post(`${this.entityUrl}/accept`, ...rest);
  }

  decline(...rest) {
    return this.httpClient.post(`${this.entityUrl}/decline`, ...rest);
  }
}

export default IntegrationRequests;
