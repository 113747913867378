<template lang="pug">
UiCardSection(padding="lg")
  .form-container
    Stack(
      vertical
      align="stretch"
      spacing="lg"
    )
      Stack(
        vertical
        align="stretch"
        spacing="xl"
      )
        UiText(variant="headingMd") {{ $t('labels.support_contacts') }}
        StackItem
          UiFormInput(
            v-model="form.support_email"
            :disabled="loading"
            :label="$t('labels.support_email')"
            placeholder="example@mail.com"
            :vuelidateModel="v$.form.support_email"
            size="lg"
            data-test-id="support_email"
          )
        StackItem
          UiFormInput(
            v-model="form.sales_email"
            :disabled="loading"
            :label="$t('labels.sales_email')"
            placeholder="example@mail.com"
            :vuelidateModel="v$.form.sales_email"
            size="lg"
            data-test-id="sales_email"
          )
        StackItem
          .ui-form-field.ui-form-field--input
            UiInputLabel {{ $t('labels.phone_number') }}
            UiPhoneNumberInput(
              v-model:countryCode="form.country_code"
              v-model="form.phone_number"
              :disabled="loading"
              :error="v$.form.phone_number.$error"
              size="lg"
              data-test-id="phone_number"
            )
            template(v-if="v$.form.phone_number.$error")
              UiInputFeedback(v-for="$error in v$.form.phone_number.$errors" error)
                | {{ $error.$message }}
        StackItem(align="end")
          UiButton(
            :disabled="loading"
            data-test-id="submit_button"
            @click="submit"
          ) {{ $t('actions.save_changes') }}
</template>

<script>
import ServerSettings from '@/api/models/ServerSettings.js';
import { email, required, isValidPhoneNumber } from '@/common/validators.js';
import useVuelidate from '@vuelidate/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

export default {
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      loading: false,
      error: false,
      inProgress: false,
      form: {
        support_email: '',
        sales_email: '',
        country_code: 'FI',
        phone_number: '',
      },
    };
  },

  validations() {
    return {
      form: {
        support_email: {
          required,
          email,
        },
        sales_email: {
          required,
          email,
        },
        phone_number: {
          required,
          isValidPhoneNumber: isValidPhoneNumber(this.form.country_code),
        },
      },
    };
  },

  mounted() {
    this.fetchState();
  },

  methods: {
    async fetchState() {
      try {
        this.error = false;
        this.loading = true;
        const response = await new ServerSettings({ adminApi: true }).fetch();
        const { support_email, sales_email, phone_number } = response.data.server_settings;
        let { country_code } = this.form;

        try {
          const phoneUtil = PhoneNumberUtil.getInstance();
          const number = phoneUtil.parseAndKeepRawInput(phone_number);
          country_code = phoneUtil.getRegionCodeForNumber(number);
        } catch {
          // do nothing
        }

        this.form = {
          ...this.form,
          support_email,
          sales_email,
          phone_number,
          country_code,
        };
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      try {
        this.inProgress = true;
        const { country_code, ...form } = this.form;
        await new ServerSettings({ adminApi: true }).update(form);
        this.$toaster.add({
          message: this.$t('labels.changes_have_been_saved'),
        });
      } catch {
        this.$toaster.add({
          type: 'error',
          message: this.$t('errors.something_went_wrong_try_later'),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss">
.form-container {
  max-width: 380px;
}
</style>
