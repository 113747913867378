<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :boldTitle="false"
  :allowClose="false"
  width="470"
)
  template(#title) 
    div {{ $t('sso.modals.confirm_override_sso.title') }}
    div {{ $t('sso.modals.confirm_override_sso.description') }}
</template>

<script>
export default {
  name: 'ConfirmOverrideSSOModal',

  emits: ['confirm', 'decline'],

  data() {
    return {
      show: false,
      inProgress: false,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: () => this.decline(),
          variant: 'smooth-secondary',
          'data-test-id': 'cancel-button',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.confirm'),
          variant: 'smooth-primary',
          onClick: () => this.confirm(),
          'data-test-id': 'confirm-button',
        },
      ];
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    decline() {
      this.$emit('decline');
      this.close();
    },
  },
};
</script>
