import AppBaseModel from './AppBaseModel.js';

class CompanyFiles extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'company_files',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default CompanyFiles;
