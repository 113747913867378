import { createStore } from 'vuex';

import CompanyModule from './modules/company/index.js';
import EditCompanyModule from './modules/editCompany/index.js';
import IndexModule from './modules/index/index.js';
import adminModule from './modules/admin/index.js';
import reportExportModule from './modules/report-export/index.js';

export function createStoreInstance() {
  return createStore({
    modules: {
      index: IndexModule,
      company: CompanyModule,
      editCompany: EditCompanyModule,
      admin: adminModule,
      reportExport: reportExportModule,
    },
  });
}

export const store = createStoreInstance();

export default store;
