<template lang="pug">
.login-page
  .login-page__wrapper(
    :class="{ 'login-page__wrapper-column': !mobileView && showProviders && basicLoginEnabled }"
  )
    .container
      .text-center
        .h3 {{ $t('home.please_login') }}
        p.text-secondary {{ $t('home.please_login_description') }}
      .login-page__form
        LoginForm
      .login-page__footer
        a(href="https://get.bibook.com") {{ $t('links.about_bi_book') }}
        a(href="https://help.bibook.com") {{ $t('links.support_center') }}
        a(href="https://get.bibook.com/book-a-demo") {{ $t('links.contact_us') }}
        a(:href="TERMS_OF_SERVICES_LINK") {{ $t('links.terms_of_service') }}
    .login-page__wrapper-banner(v-if="loginBanner")
      .login-page__form-banner
        LoginBanner(:title="loginBanner?.title" :description="loginBanner?.description")
</template>
<script>
import LoginForm from '@/components/Login/LoginForm.vue';
import LoginBanner from '@/components/login-banner/login-banner.vue';
import { TERMS_OF_SERVICES } from '@/common/constant-links.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    LoginForm,
    LoginBanner,
  },
  data() {
    return {
      TERMS_OF_SERVICES_LINK: TERMS_OF_SERVICES,
    };
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'basicLoginEnabled',
      'oauthGoogleLoginEnabled',
      'oauthMicrosoftLoginEnabled',
      'SSOLoginEnabled',
      'SSOLoginMethod',
    ]),
    ...mapGetters('company', ['loginBanner']),
    showSSOLogin() {
      return this.SSOLoginMethod && this.SSOLoginMethod !== 'none' && this.SSOLoginEnabled;
    },
    showProviders() {
      return (this.oauthGoogleLoginEnabled && this.oauthMicrosoftLoginEnabled) || this.showSSOLogin;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  padding: 30px 0;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper-column {
    flex-direction: row;
    justify-content: center;
    .login-page__wrapper-banner {
      margin-left: 100px;
    }

    .login-page__form-banner {
      max-width: 450px;
      min-width: 320px;
      margin-top: 0;
      padding: 0;
    }
  }

  &__form-banner {
    max-width: 700px;
    min-width: 430px;
    margin-top: 60px;
    padding: 0 20px;
  }

  &__form {
    background-color: var(--bb-app-bg-main);
    max-width: 450px;
    margin: auto;
    margin-top: 30px;
    padding: 40px 50px;
    border-radius: 12px;
  }

  &__footer {
    margin-top: 3rem;
    gap: 2rem;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 570px) {
  .login-page {
    &__form {
      padding: 30px;
    }
  }
}
</style>
