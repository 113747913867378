import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';
import usersModule from './users/index.js';
import usersGroups from './groups/index.js';
import usersCompanies from './companies/index.js';

const state = {
  company: null,
  appReady: false,
};

export default {
  modules: {
    users: usersModule,
    groups: usersGroups,
    companies: usersCompanies,
  },
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
