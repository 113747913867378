import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store/index.js';
import Company from '@/models/Company.js';
import localStorage from '@/common/localstorage.js';
import SessionService from '@/common/session-service.js';
import axios from 'axios';
import RedirectService from '@/common/redirect-service.js';
import UserCompanies from '@/api/models/UserCompanies.js';
import { initializeChat } from '@/common/chat.js';
import { initDataService } from '@/services/init-data-service.js';
import { routes } from './routes.js';

export const COMPANY_USER_HOME_PAGE = (to) => {
  if (to.query.redirect_to) {
    return to.query.redirect_to;
  }
  return { name: 'Analytics' };
};

function storeShowReport(fullPath, isAuthenticated, externalLoginPage, next) {
  if (isAuthenticated) {
    return next({ name: 'Analytics' });
  }
  localStorage.set('show-report', fullPath, String);
  if (externalLoginPage) {
    window.location.replace(externalLoginPage);
    return next(false);
  }
  return next({ name: 'Analytics' });
}

function nextNoAccessRedirect(toPath, currentCompany) {
  /**
   * Don't allow user navigate to analytics, files, dataroom pages if company feature disabled
   */
  if (
    currentCompany &&
    ((toPath.startsWith('/analytics') && !currentCompany.analytics_enabled) ||
      (toPath.startsWith('/dataroom') && !currentCompany.dataroom_enabled))
  ) {
    return 'ContentIndex';
  }
  return null;
}

export function createAppRouter(appRoutes = routes) {
  const router = createRouter({
    history: createWebHistory(),
    routes: appRoutes,
  });

  /**
   * The variable stores the number of iterations since the last successful processing.
   */
  let routerRedirectCycles = 0;

  router.beforeEach(async (to, from, next) => {
    /**
     * Prevent page freezes if for some reason redirects enter an infinite loop.
     */
    if (routerRedirectCycles > 30) {
      console.error('To many redirects.');
      return;
    }

    /**
     * Registering a new iteration.
     */
    routerRedirectCycles++;

    /**
     * App initialization
     */
    if (!store.getters.appReady) {
      try {
        const initResponse = await initDataService.getData();
        store.commit('SET_INIT_DATA', initResponse.data);
        store.commit('SET_USER', initResponse.data.user);
        store.commit('SET_COMPANY_ROLES', initResponse.data.company_roles);
        store.commit('company/SET_LOGIN_BANNER', initResponse.data.login_banner);

        if (initResponse.data.current_company) {
          store.commit('company/SET_CURRENT_COMPANY_STATE', initResponse.data.current_company);
          store.commit('company/UPDATE_COMPANY', initResponse.data.current_company);
          store.commit(
            'company/SET_SYSTEM_BANNERS',
            initResponse.data.current_company.system_banners,
          );
        }

        if (initResponse.data.companies_count === 1) {
          const response = await new UserCompanies().fetchAll();
          store.commit('company/UPDATE_COMPANY_LIST', [...response.data.companies]);
        }

        axios.defaults.headers.common['BiBook-Locale'] = store.getters.language;
        store.commit('SET_APP_READY', true);

        if (store.getters.isAuthenticated && !store.getters.isAdminHost) {
          initializeChat();
          SessionService.init();
        }
      } catch (error) {
        console.error('Session init error');
        console.error(error);
        return;
      }
    }

    routerRedirectCycles = 0;

    const integrationAttempt = localStorage.get('integration_attempt');
    const {
      isAuthenticated,
      companiesCount,
      isAdmin,
      isViewAsOtherUser,
      oAuthRedirectArr,
      basicLoginEnabled,
      externalLoginPage,
      isRootDomain,
      adminDomain,
      appDomain,
      userRole,
    } = store.getters;
    const currentCompany = store.getters['company/currentCompany'];
    const companies = store.getters['company/companies'];
    const showReportPath = to.fullPath.includes('/show_report/');
    const requiresAccess = to.matched.some((r) => r.meta.requiresAccess);
    const userRoute = to.matched.some((r) => r.meta.userRoute);
    const globalAdminRoute = to.matched.some((r) => r.meta.globalAdminRoute);
    const requiresAuth = to.matched.some((r) => r.meta.requiresAuth);
    const noAuthOnly = to.matched.some((r) => r.meta.noAuthOnly);
    const companyAdmin = Company.isAdmin((currentCompany || {}).role);

    /**
     * Cross domain redirects
     */
    if (
      isAuthenticated &&
      !store.state.index.defaultCompany &&
      !to.meta.noRequireCompany &&
      !integrationAttempt
    ) {
      /**
       * Redirect to admin domain
       */
      if (isAdmin && adminDomain !== window.location.host) {
        await store.dispatch('logout', false);
        window.location = `${window.location.protocol}//${adminDomain}`;
        next(false);
        return;
      }

      /**
       * Redirect to root domain
       */
      if (!isAdmin && adminDomain === window.location.host) {
        await store.dispatch('logout', false);
        window.location = `${window.location.protocol}//${appDomain}`;
        next(false);
        return;
      }
    }

    /**
     * Start new onboarding flow
     */
    if (to.name === 'OnboardingLayout' && to.query.attempt_id) {
      next({
        name: 'Onboarding',
        params: { attempt_id: to.query.attempt_id },
      });
      return;
    }

    /**
     * Checking if there is an incomplete onboarding process and redirect to onboarding
     */
    if (integrationAttempt && to.name !== 'Onboarding') {
      next({
        name: 'Onboarding',
        params: { attempt_id: integrationAttempt },
      });
      return;
    }

    /**
     * Redirect to company company
     */
    if (
      isAuthenticated &&
      appDomain === window.location.host &&
      !store.state.index.defaultCompany &&
      !to.meta.noRequireCompany &&
      companiesCount === 1 &&
      !currentCompany
    ) {
      await store.dispatch('logout', false);
      window.location = `${window.location.protocol}//${companies[0]?.active_company_domains[0]}`;
      next(false);
      return;
    }

    /**
     * Redirect to select company page
     */
    if (
      isAuthenticated &&
      appDomain === window.location.host &&
      !store.state.index.defaultCompany &&
      !to.meta.noRequireCompany &&
      companiesCount > 0 &&
      !currentCompany &&
      to.name !== 'SelectCompany'
    ) {
      next({ name: 'SelectCompany' });
      return;
    }

    /**
     * Logout authenticated user without company access on company domain
     * TODO: cover with tests
     */
    if (
      isAuthenticated &&
      !to.meta.noRequireCompany &&
      !currentCompany &&
      !isRootDomain &&
      !isAdmin
    ) {
      await store.dispatch('logout', false);
      window.location.reload();
      return;
    }

    /**
     * Return user to analytics page
     * TODO: update or remove business logic
     */
    if (showReportPath) {
      storeShowReport(to.fullPath, isAuthenticated, externalLoginPage, next);
      return;
    }

    /**
     * Company features guard
     */
    if (isAuthenticated && requiresAccess) {
      const noAccessRedirect = nextNoAccessRedirect(to.path, currentCompany);
      if (noAccessRedirect) {
        next({ name: noAccessRedirect });
        return;
      }
    }

    /**
     * Redirect to home if not global admin user try to navigate to global admin routes
     */
    if (isAuthenticated && globalAdminRoute && !isAdmin) {
      next(COMPANY_USER_HOME_PAGE(to));
      return;
    }

    /**
     * Redirect to companies page if global admin user try to navigate to company management routes
     */
    if (isAuthenticated && userRoute && isAdmin) {
      next({ name: 'AdminCompanies' });
      return;
    }

    /**
     * Redirect to initial page if a user try to a page without required userRole
     */
    const explicitAvailableRoles = to.matched.find((r) => Array.isArray(r.meta.availableRoles))
      ?.meta.availableRoles;
    if (isAuthenticated && explicitAvailableRoles && !explicitAvailableRoles.includes(userRole)) {
      next({ name: 'Root' });
      return;
    }

    /**
     * Redirect to external login page (home page) for unauthorized users
     */
    if (
      !isAuthenticated &&
      externalLoginPage &&
      (to.path === '/' || to.redirectedFrom?.path === '/') &&
      isRootDomain
    ) {
      window.location.replace(externalLoginPage);
      next(false);
      return;
    }

    /**
     * Auto login for OAuth and SSO
     */
    if (requiresAuth && !isAuthenticated && !basicLoginEnabled && oAuthRedirectArr.length === 1) {
      RedirectService.toLoginProvider(oAuthRedirectArr[0]);
      next(false);
      return;
    }

    /**
     * Redirect to landing page for unauthorized users
     */
    if (requiresAuth && !isAuthenticated) {
      next({
        name: 'Login',
        query: { ...to.query, redirect_to: to.fullPath },
      });
      return;
    }

    /**
     * Redirect to stored report if exist
     * TODO: review this logic
     */
    const showReport = localStorage.get('show-report', null, String);
    if (showReport && requiresAuth) {
      localStorage.remove('show-report');
      window.location.replace(showReport);
    }

    /**
     * Do not allow the user to NoHaveCompany page if user have company
     */
    if (
      !to.meta.noRequireCompany &&
      to.name === 'NoHaveCompany' &&
      isAuthenticated &&
      currentCompany
    ) {
      next(COMPANY_USER_HOME_PAGE(to));
      return;
    }

    /**
     * Navigate user to NoHaveCompany page if user without company
     */
    if (
      !to.meta.noRequireCompany &&
      to.name !== 'NoHaveCompany' &&
      isAuthenticated &&
      !currentCompany &&
      !isAdmin &&
      companiesCount === 0
    ) {
      next({ name: 'NoHaveCompany' });
      return;
    }

    /**
     * Do not pass to Company management page user without rights
     */
    if (
      !to.meta.noRequireCompany &&
      to.matched.some((r) => r.name === 'Company') &&
      currentCompany &&
      !companyAdmin
    ) {
      next(COMPANY_USER_HOME_PAGE(to));
      return;
    }

    /**
     * Do not allow company management in view as mode
     */
    if (to.matched.some((r) => r.name === 'Company') && isViewAsOtherUser) {
      next(COMPANY_USER_HOME_PAGE(to));
      return;
    }

    /**
     * Don't allow navigate authorized user to routes for unauthorized users only (login, registration etc.)
     */
    if (isAuthenticated && noAuthOnly && to.name !== 'SelectCompany') {
      next(COMPANY_USER_HOME_PAGE(to));
    }

    /**
     * Don't allow user navigate to embed links page if company feature disabled
     */
    if (
      to.name === 'ReportEmbeddedLinks' &&
      !currentCompany?.private_embedded_links_enabled &&
      !currentCompany?.public_embedded_links_enabled
    ) {
      next(COMPANY_USER_HOME_PAGE(to));
    }

    next();
  });

  return router;
}

export const router = createAppRouter();

export default router;
