<template lang="pug">
.container.whiteboard
  Stack
    StackItem
      .mt4.text-center
        .h3 Kiitos, että aktivoit bibook.com in Netvisor Storessa!
      .mt4
        | Vielä muutama askel bibook.comin käyttöönottamiseksi:
        ol.instructions
          li Kirjaudu sisään käyttäen sähköpostiosoitettasi. Tämä on sama sähköpostiosoite, jota käytät työsähköpostissasi / Netvisorissa.
          li Hyväksy palvelun käyttöehdot.
          li Tunnuksesi ovat käyttövalmiit noin 10 minuutin kuluttua.
        .contacts
          p Ystävällisin terveisin,
          div
            a(href="https://get.bibook.com" target="_blank") bibook.com
            p puolesta
          p Jonathan Teir
        .redirectToLogin
          a(href="/login") To Login
        hr
        | Siltä varalta, että rekisteröitymisessä ilmenee ongelmia, olemme listanneet alle muutamia yleisimmin kysyttyjä kysymyksiä:
        ul.question.faq
          li.mt4 Millä sähköpostiosoitteella kirjaudun?
            ul.question
              li.question_item Kirjautuminen tapahtuu samalla sähköpostiosoitteella, jolla tunnuksesi on luotu Netvisoriin. Löydät tämän kirjautumalla Netvisoriin ja valitsemalla nimesi kohdalta Omat perusasetukset.
              li.question_item Kirjaudu käyttäen oman sähköpostisi palveluntarjoajaa (Tuetut palveluntarjoajat Google ja Microsoft).
          li Sähköpostiosoite on virheellinen
            ul.question
              li.question_item Mikäli Netvisorissa oleva sähköpostiosoite on virheellinen, pääset korjaamaan sen Netvisorissa valitsemalla oman nimesi takaa Omat perusasetukset. Aloita tämän jälkeen rekisteröityminen uudelleen.
              li.question_item Rekisteröitymisprosessi nollaantuu itsestään tunnin kuluessa, mikäli kirjautuminen ei onnistu.
          li Salasana on virheellinen
            ul.question
              li.question_item BI Book ei säilytä käyttäjätunnuksiasi, olethan yhteydessä omaan IT-tukeesi resetoidaksesi sähköpostitilisi salasanan.
          li Tuleeko minun käyttää Google vai Microsoft kirjautumista?
            ul.question
              li.question_item Kirjautumistapa riippuu käyttämästäsi palveluntarjoajasta.
          li Rekisteröinti kestää liian kauan?
            ul.question
              li.question_item Palvelun ruuhkautuessa, tunnusten luomisessa voi kulua 15 minuuttia.
              li.question_item Mikäli rekisteröinti kestää yli tunnin, prosessi keskeytyy automaattisesti. Rekisteröidythän uudelleen, otathan yhteyttä tukeemme bibook@renance.com tai soittaisithan meille numeroon +358440161288.
</template>

<script>
import eventBus from '@/eventBus.js';

export default {
  name: 'NetvisorIntegrationFi',
  mounted() {
    this.$store.dispatch('switchLanguage', 'fi');
    eventBus.on('LocaleChanged', this.changeLocale);
  },

  beforeUnmount() {
    eventBus.off('LocaleChanged', this.changeLocale);
  },

  methods: {
    changeLocale(locale) {
      if (locale !== 'fi') {
        this.$router.replace(`/sign-up-via-netvisor/${locale}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
}

.instructions {
  list-style-position: inside;
}

.contacts {
  margin-left: 40px;
  text-align: end;

  & > div {
    display: flex;
    justify-content: end;

    & :last-child {
      margin-left: 4px;
    }
  }
}

.redirectToLogin {
  & > a {
    color: white;
    text-decoration: none;
    font-weight: 600;
  }
  width: 150px;
  padding: 10px 15px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  background-color: var(--bb-primary);
}

.question {
  list-style-position: inside;
  list-style-type: disc;
}

.whiteboard {
  word-wrap: break-word !important;
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
}

.faq {
  & > li {
    list-style: none;
    margin-left: 10px;
  }
}

.mt4 {
  margin-top: 16px;
}
</style>
