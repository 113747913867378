<template lang="pug">
UiCardSection(padding="lg")
  component(:is="view")
</template>

<script>
import ThemesList from './UiThemesTab/Index.vue';
import EditTheme from './UiThemesTab/EditTheme.vue';

export default {
  computed: {
    view() {
      if (this.$route.query.theme_id) {
        return EditTheme;
      }
      return ThemesList;
    },
  },
};
</script>
