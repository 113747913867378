import AppBaseModel from './AppBaseModel';

class PowerBiEmbeddedLinks extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'power_bi_embedded_links',
      pluralKey: 'power_bi_embedded_links',
      singularKey: 'power_bi_embedded_link',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default PowerBiEmbeddedLinks;
