import Companies from '@/api/models/Companies.js';

// eslint-disable-next-line arrow-body-style
const fetchCompany = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    commit('users/SET_META', {
      current_page: 1,
    });
    new Companies({ adminApi: true }, { id })
      .fetch()
      .then((res) => {
        commit('SET_COMPANY', res.data.company);
        dispatch('groups/fetchGroups');
        dispatch('companies/fetchCompanies', id);
        dispatch('users/fetchList', id);
        resolve(res);
      })
      .catch(reject);
  });
};

export default {
  fetchCompany,
};
