import AppBaseModel from './AppBaseModel';

class InvitationEmail extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'invitation_email',
      singularKey: null,
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default InvitationEmail;
