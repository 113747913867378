<template lang="pug">
.system-banner(:class="bannerClasses")
  .system-banner__container(:class="{ 'system-banner__container-not_preview': !isPreview }")
    .system-banner__wrapper-content
      .system-banner__left
        img(:src="icons[banner.banner_type]" data-test-id="banner-img")
        span.system-banner__title {{ title[banner.banner_type] }}
      .system-banner__right
        span.system-banner__description(v-html="banner.description")
    UiStack
      UiStackItem
        UiButton(
          v-if="banner.dismissible"
          icon="Close"
          variant="ghost"
          border="smooth"
          size="xs"
          round
          block
          data-test-id="action-dismiss-button"
          @click="dismissBanner"
        )
</template>

<script>
import warningIcon from '@/assets/images/system-banners/warning.svg';
import infoIcon from '@/assets/images/system-banners/info.svg';
import SystemBanners from '@/api/models/system-banners.js';
import ApiUser from '@/api/user.js';

export default {
  components: {},

  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    bannerClasses() {
      return {
        'warning-type': this.banner.banner_type === 'warning',
        'info-type': this.banner.banner_type === 'info',
      };
    },
    icons() {
      return {
        warning: warningIcon,
        info: infoIcon,
      };
    },
    title() {
      return {
        warning: `${this.$t('system_banners.type.warning')}:`,
        info: `${this.$t('system_banners.type.info')}:`,
      };
    },
  },

  methods: {
    async dismissBanner() {
      if (this.isPreview) {
        return;
      }

      try {
        await new SystemBanners({ adminApi: this.isAdmin }, { id: this.banner.id }).dismiss();
        const initResponse = await ApiUser.fetchInit();
        this.$store.commit(
          'company/SET_SYSTEM_BANNERS',
          initResponse.data.current_company.system_banners,
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.system-banner {
  width: 100%;
  padding: 15px 10px;
  color: var(--bb-app-bg-main);

  &__container {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  &__container-not_preview {
    padding-left: 150px;
  }

  &__wrapper-content {
    display: flex;
    gap: 14px;
  }

  &__left {
    display: flex;
    align-items: flex-start;

    img {
      margin-top: 2px;
    }
  }

  &__title {
    font-weight: 700;
    margin-left: 14px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__description {
    p {
      margin: 0;
    }
  }
}

.warning-type {
  background-color: var(--bb-warning);
}

.info-type {
  background-color: var(--bb-info);
}

@media screen and (max-width: 1200px) {
  .system-banner__container-not_preview {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .system-banner__container-not_preview {
    padding-left: 15px;
  }
}
</style>
