<template lang="pug">
Modal(
  :title="$t('company.reports.themes.edit_color_scheme')"
  :show="show"
  :close="close"
  footerPos="space-between"
)
  template(#body)
    UiFormInput(
      v-model.trim="name"
      :label="$t('company.reports.themes.edit_name')"
      :disabled="inProgress"
      :vuelidate-model="v$.name"
    )
  template(#footer)
    UiButton(variant="outline-primary" @click="close") {{ $t('actions.cancel') }}
    UiButton(
      :disabled="inProgress"
      :loading="inProgress"
      @click="submit"
    ) {{ $t('actions.save') }}
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import ApiReportThemes from '@/api/models/ReportThemes.js';
import { mapGetters } from 'vuex';

export default {
  emits: ['update'],
  setup() {
    const v$ = useVuelidate(null, null, { $scope: false });
    return { v$ };
  },
  data() {
    return {
      show: false,
      name: '',
      currentThemeId: null,
      inProgress: false,
    };
  },
  validations() {
    return {
      name: { required },
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    ...mapGetters('company', ['currentCompany']),
  },
  methods: {
    open(id, name) {
      this.currentThemeId = id;
      this.show = true;
      this.name = name;
    },
    close() {
      this.show = false;
      this.v$.$reset();
    },
    async submit() {
      const valid = await this.v$.$validate();

      if (!valid) {
        return;
      }

      this.inProgress = true;
      try {
        await new ApiReportThemes(
          { adminApi: this.isAdminHost },
          { id: this.currentThemeId },
        ).update({
          name: this.name,
        });
        if (this.currentCompany) {
          const { report_themes } = this.currentCompany;
          const index = report_themes.findIndex((theme) => theme.id === this.currentThemeId);
          if (index !== -1) {
            report_themes[index].name = this.name;
          }
          this.$store.dispatch('company/restoreCompany', {
            id: this.currentCompany.id,
            report_themes,
          });
        }
        this.$emit('update');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>
