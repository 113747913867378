import store from '@/store';

export class PowerBIReportErrorsService {
  static isCapacityRelatedError(error) {
    const CAPACITY_ERROR_MESSAGE_MATCH_REGEXP = new RegExp(
      store.getters.capacityRelatedErrors || 'CapacityNotActive|CapacityLimitExceeded',
      'g',
    );

    return (
      error.detail?.technicalDetails?.errorInfo.some((item) =>
        item.value.match(CAPACITY_ERROR_MESSAGE_MATCH_REGEXP),
      ) ?? false
    );
  }
}
