import _get from 'lodash/get.js';

// TODO: rewrite the class. Add missed fields and remove lodash. Use this model in every integration modal.

class Integration {
  constructor(p = {}) {
    this.id = _get(p, 'id', null);
    this.software = _get(p, 'software', null);
    this.name = _get(p, 'name', '');
    this.data = _get(p, 'data', null);
    this.refresh_enabled = _get(p, 'refresh_enabled', false);
    this.refresh_types = _get(p, 'refresh_types', []);
    this.refresh_table_list = _get(p, 'refresh_table_list', []);
    this.refresh_tables = _get(p, 'refresh_tables', []);
    this.refresh_status = _get(p, 'refresh_status', 'Completed');
    this.refresh_type = _get(p, 'refresh_type', 'completed');
    this.refresh_message = _get(p, 'refresh_message', null);
    this.refresh_again = _get(p, 'refresh_again', false);
    this.can_refresh = _get(p, 'can_refresh', false);
    this.refresh_active = _get(p, 'refresh_active', false);
    this.refresh_finished_message = _get(p, 'refresh_finished_message', false);
    this.refresh_timeout = _get(p, 'refresh_timeout', null);
  }
}

export default Integration;
