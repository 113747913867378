import AppBaseModel from '@/api/models/AppBaseModel';

class ApiReportThemes extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'power_bi_report_themes',
      singularKey: null,
      adminApi: false,
      ...options,
    };
    super(mergedOptions, item);
  }

  preview(params = {}, config = {}) {
    return this.httpClient.get(`${this.url}/preview_report`, { params, ...config });
  }
}
export default ApiReportThemes;
