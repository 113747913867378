import CompanyGroups from '@/api/models/CompanyGroups.js';

// eslint-disable-next-line arrow-body-style
const fetchGroups = ({ commit, _state, getters }, id) => {
  return new Promise((resolve, reject) => {
    // commit('SET_PROGRESS', true);
    new CompanyGroups(null, { id })
      .fetchAll()
      .then((res) => {
        commit('SET_GROUPS', res.data.company_groups);
        commit('SET_IS_READY', true);
        // commit('SET_PROGRESS', false);
        resolve(res);
      })
      .catch(reject);
  });
};

export default {
  fetchGroups,
};
