<template lang="pug">
.container.whiteboard
  Stack
    StackItem
      .mt4.text-center
        .h3 Thank you for activating bibook.com via the Netvisor store!
      .mt4
        | Please follow these exact instructions to set bibook.com up for you:
        ol.instructions
          li Log in using your email address. This is the same login you use for your work emails / in Netvisor.
          li Accept the terms of service.
          li Wait about 10 minutes while we set you up.
        .contacts
          p Best regards,
          div
            p Jonathan Teir on behalf of
            a(href="https://get.bibook.com" target="_blank") bibook.com
        .redirectToLogin
          a(href="/login") To Login
        hr
        | Having issues, here are some common questions:
        ul.question.faq
          li.mt4 What is my email?
            ul.question
              li.question_item This is the email address you have set-up in Netvisor. To find this, go to Netvisor, and select user settings behind your name.
              li.question_item Log in using your email provider (Google or Microsoft supported).
          li Email is not correct
            ul.question
              li.question_item No problem, the process will reset itself within one hour if you do now successfully log in.
              li.question_item Change your email by going to user settings in Netvisor (behind your name) and initiate the signup process again! :)
          li Password is not correct
            ul.question
              li.question_item BI Book does not store your credentials, please contact your own IT support to reset your password to your email account.
          li Should I use google or Microsoft login?
            ul.question
              li.question_item Depends on what email provider you use, choose google or microsoft.
          li Signup taking too long?
            ul.question
              li.question_item It can take up to 15 minutes to set you up if there is a lot of traffic.
              li.question_item If the signup process takes longer than 1 hour, the process will automatically be aborted. Please signup again or contact our support at bibook@renance.com or call us at +358440161288.
</template>
<script>
import eventBus from '@/eventBus.js';

export default {
  name: 'NetvisorIntegrationEn',
  mounted() {
    this.$store.dispatch('switchLanguage', 'en');
    eventBus.on('LocaleChanged', this.changeLocale);
  },

  beforeUnmount() {
    eventBus.off('LocaleChanged', this.changeLocale);
  },

  methods: {
    changeLocale(locale) {
      if (locale !== 'en') {
        this.$router.replace(`/sign-up-via-netvisor/${locale}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
}

.instructions {
  list-style-position: inside;
}

.contacts {
  margin-left: 40px;
  text-align: end;

  & > div {
    display: flex;
    justify-content: end;

    & :last-child {
      margin-left: 4px;
    }
  }
}

.redirectToLogin {
  & > a {
    color: white;
    text-decoration: none;
    font-weight: 600;
  }
  width: 150px;
  padding: 10px 15px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  background-color: var(--bb-primary);
}

.question {
  list-style-position: inside;
  list-style-type: disc;
}

.whiteboard {
  word-wrap: break-word !important;
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
}

.faq {
  & > li {
    list-style: none;
    margin-left: 10px;
  }
}

.mt4 {
  margin-top: 16px;
}
</style>
