<template lang="pug">
UiOptionsMenu
  .theme-switcher
    template(v-for="(theme, index) in reportThemes" :key="theme.id")
      //- UiOptionsMenuDivider(v-if="index")
      .theme-switcher__item(
        :class="{ 'theme-switcher__item--active': activeThemeId === theme.id }"
        data-test-id="theme-item"
        @click="select(theme.id)"
      )
        .theme-switcher__name {{ theme.name }}
        .theme-switcher__colors(v-if="theme.colors?.length" data-test-id="theme-colors")
          .theme-switcher__color(
            v-for="color in theme.colors"
            :style="{ 'background-color': color }"
          )
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  emits: ['select'],

  props: {
    activeThemeId: {
      type: String,
      default: 'Default',
    },
  },

  computed: {
    ...mapGetters('company', ['reportThemes']),
  },

  methods: {
    select(theme) {
      this.$emit('select', theme);
    },
  },
};
</script>

<style lang="scss">
.theme-switcher {
  min-width: 200px;

  &__item {
    padding: 0.65rem 1rem;
    // border: 1px solid #eee;
    border-left-width: 0;
    border-right-width: 0;
    cursor: pointer;
    position: relative;
    // margin-top: -1px;

    &:hover {
      background: rgba(var(--bb-primary-rgb), 0.15);
      border-color: rgba(var(--bb-primary-rgb), 0.15);
      z-index: 1;
    }

    &:first-child {
      border-top-color: transparent;
    }

    &:last-child {
      border-bottom-color: transparent;
    }

    &--active,
    &--active:hover,
    &--active:last-child,
    &--active:first-child {
      border-color: rgba(var(--bb-primary-rgb), 0.15);
      background: rgba(var(--bb-primary-rgb), 0.25);
      box-shadow: inset 3px 0 0 0 var(--bb-primary);
      z-index: 2;
    }
  }

  &__name {
    font-size: 0.95em;
    line-height: 1;
  }

  &__colors {
    margin-top: 0.35rem;
    overflow: hidden;
    display: flex;
    border-radius: 3px;
    box-shadow: 0 0 0 2px white;
  }

  &__color {
    flex-grow: 1;
    height: 16px;
  }
}
</style>
