<template lang="pug">
ImportModal(
  v-model="show"
  :apply="save"
  :extensions="['json']"
  :downloadExample="downloadExample"
  :exampleLinkText="$t('company.reports.see_an_example')"
  exampleLinkIcon=""
  :loading="inProgress"
)
</template>

<script>
import ImportModal from '@/modals/common/ImportModal.vue';
import ApiReportThemes from '@/api/models/ReportThemes.js';
import { mapGetters } from 'vuex';

const DEFAULT_REPORT_THEME_INSTRUCTION_LINK =
  'https://help.bibook.com/en/articles/power-bi-report-theme';

export default {
  components: { ImportModal },
  emits: ['update'],
  data() {
    return {
      show: false,
      inProgress: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'reportThemeInstruction']),
    ...mapGetters('company', ['currentCompany']),
    downloadExample() {
      return () => {
        const a = document.createElement('a');
        a.href = this.reportThemeInstruction || DEFAULT_REPORT_THEME_INSTRUCTION_LINK;
        a.target = '_blank';
        a.click();
      };
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    save(files) {
      const file = files[0];
      this.inProgress = true;
      const formData = new FormData();
      formData.append('power_bi_report_theme[file]', file);

      return new ApiReportThemes({ adminApi: this.isAdmin })
        .create(formData)
        .then((resp) => {
          if (!this.isAdmin) {
            const { power_bi_report_theme } = resp.data;
            const { report_themes } = this.currentCompany;
            report_themes.push(power_bi_report_theme);
            this.$store.dispatch('company/restoreCompany', {
              id: this.currentCompany.id,
              report_themes,
            });
          }
          this.$emit('update');
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style scoped></style>
