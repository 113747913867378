<template lang="pug">
UiModal(
  :title="$t('system_banners.modals.create.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  SystemBannerForm(
    v-model="form"
    :vuelidateModel="v$.form.descriptionLength"
    :inProgress="inProgress"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import SystemBanners from '@/api/models/system-banners.js';
import SystemBannerForm from '@/components/system-banner/system-banner-form.vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

function getForm() {
  return {
    user_roles: [],
    banner_type: 'info',
    description: '',
    dismissible: false,
    descriptionLength: null,
  };
}

export default {
  components: {
    SystemBannerForm,
  },
  emits: ['done'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: getForm(),
      isPortalManagementPage: false,
    };
  },
  validations() {
    const required = (value) => value;
    return {
      form: {
        descriptionLength: {
          required: helpers.withMessage(this.$t('system_banners.validations.required'), required),
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: () => this.close(),
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          onClick: () => this.submit(),
          loading: this.inProgress,
        },
      ];
    },
  },
  methods: {
    open({ isPortalManagementPage = false } = {}) {
      this.v$.$reset();
      this.form = getForm();
      this.isPortalManagementPage = isPortalManagementPage;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async submit() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      this.inProgress = true;
      try {
        const params = {
          system_banner: {
            description: this.form.description,
            banner_type: this.form.banner_type,
            user_roles: this.form.user_roles,
            dismissible: this.form.dismissible,
          },
        };
        await new SystemBanners({
          adminApi: this.isAdmin,
          owner:
            this.isAdmin && !this.isPortalManagementPage
              ? new Companies(null, { id: this.$route.params.id })
              : null,
        }).create(params);

        this.$emit('done');
        this.close();
      } catch (error) {
        const errorMessage =
          error?.response?.data?.errors?.banner_type[0] ||
          error?.response?.data?.errors?.user_roles[0];
        const message = errorMessage
          ? this.$t(`system_banners.validations.${errorMessage}`)
          : this.$localizeErrorMessage(error);
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message,
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>
