<template lang="pug">
.data-table
  .data-table__filter-bar(
    v-if="showTopPagination || searchable || filtered"
    :class="filterBarClasses"
  )
    .data-table__filter-bar__left
      UiInput.data-table__search-field(
        v-if="searchable"
        v-model="searchModel"
        :placeholder="searchLabel"
        size="sm"
        data-vi="table-search"
      )
        template(#icon)
          UiIcon(name="Search")
      .data-table__filter-bar__custom-filter(v-if="filtered")
        slot(name="filter_block")
    .data-table__filter-bar__right
      .data-table__filter-bar__per-page(v-if="showTopPagination && !mobileView && itemPerPage")
        DataTableItemsPerPage(v-model="perPageModel" :perPageOptions="perPageOptions")
      template(v-if="showPagination && showTopPagination")
        UiPagination(
          v-model="pagination"
          size="sm"
          :perPage="perPage"
          :totalItems="totalItems"
        )
  .data-table__columns-filter-container(v-if="dynamicHeaders")
    Stack
      StackItem
        UiMultiselect.data-table__columns-filter(
          v-model="selectedHeaders"
          :options="hidableHeaders"
          placeholder="Add Columns"
          size="sm"
          valueProp="key"
          mode="tags"
        )
      StackItem
        UiButton(
          size="sm"
          :disabled="isViewAsOtherUser"
          @click="saveColumns"
        ) {{ $t('files.save_columns') }}

  .breadcrumps-container(v-if="breadcrumps.length")
    Breadcrumbs(:breadcrumps="breadcrumps")

  .table__container
    table.table
      thead
        tr
          th(
            v-for="header in availableHeaders"
            :key="header.key"
            :class="headerClasses(header)"
            :style="headerStyles(header)"
            @click="onHeaderClick($event, header)"
          )
            slot(:name="`head_${header.key}`")
            .data-table__header-layout
            .data-table__header-cel
              .data-table__header-title
                slot(:name="`header_${header.key}`") {{ header.label }}
              .data-table__sort(v-if="header.sortable")
                span.data-table__sort-arrow.data-table__sort-arrow-up
                span.data-table__sort-arrow.data-table__sort-arrow-down
        tr.data-table__progress-row(v-show="progress")
          th(:colspan="headers.length")
            UiProgress
      tbody
        template(v-for="(item, rowIndex) in items" :key="rowIndex")
          tr.data-table__row-main(
            :class=`
              { 'table-not-striped-tr': notStriped,
                selected: selectedIds.includes(item.id),
                'data-table__collapsed': visibleCollapsed.includes(rowIndex)
              }`
            @mouseover="showControllId = rowIndex"
            @mouseleave="showControllId = null"
          )
            td(
              v-if="item._row"
              :colspan="availableHeaders.length"
              @click="item.action"
            )
              slot(
                :name="`row_${item.name}`"
                :index="rowIndex"
                :item="item"
                :showControl="showControllId === rowIndex"
              )
            td(
              v-else
              v-for="(header, celIndex) in availableHeaders"
              :key="`${rowIndex}_${celIndex}_${header.key}`"
              :class="celClasses(header)"
              :title=`header.overflowEllipsis
                && item[header.key]
                && item[header.key].length ? item[header.key] : null`
              @click="itemAction(header.key, item)"
            )
              slot(
                :name="`cel_${header.key}`"
                :index="rowIndex"
                :item="item"
                :showControl="showControllId === rowIndex"
              ) {{ item[header.key] }}
          transition(name="fade-top")
            tr.data-table__collapsed(v-if="visibleCollapsed.includes(rowIndex)")
              td.p-0(:colspan="headers.length")
                slot(v-bind="{ item, rowIndex }" name="collapsed")
        tr.table-info-row(v-if="progress && !items?.length")
          td(:colspan="headers.length")
            NoData(subtitle="rest.fetching_data")
        tr.table-info-row(v-if="!progress && !items?.length")
          td(:colspan="headers.length")
            NoData

template(v-if="showBottomPagination")
  .data-table__pagination-container
    .data-table__filter-bar__per-page
      DataTableItemsPerPage(
        v-model="perPageModel"
        :perPageOptions="perPageOptions"
        hideText
      )
    UiPagination(
      v-if="showPagination"
      v-model="pagination"
      size="sm"
      :perPage="perPage"
      :totalItems="totalItems"
    )
</template>

<script>
import { mapGetters } from 'vuex';
import DataTableItemsPerPage from '@/components/DataTable/DataTableItemsPerPage.vue';
import NoData from '@/components/DataTable/NoData.vue';

const SELECTED_COLUMNS_STORAGE = 'files_additional_columns';

export default {
  components: {
    NoData,
    DataTableItemsPerPage,
  },
  emits: ['headerClick', 'paginate', 'sort'],
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    visibleCollapsed: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    embed: {
      type: Boolean,
      default: false,
    },
    showBottomPagination: {
      type: Boolean,
      default: false,
    },
    showTopPagination: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchLabel: {
      type: String,
      default: 'Search ...',
    },
    search: {
      type: String,
      default: null,
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    dynamicHeaders: {
      type: Boolean,
      default: false,
    },
    notStriped: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 15, 25, 50, 100],
    },
    totalItems: {
      type: Number,
      default: null,
    },
    filtersBottom: {
      type: Boolean,
    },
    isFileTable: {
      type: Boolean,
      default: false,
    },
    breadcrumps: {
      type: Array,
      default: () => [],
    },
    multilineFilters: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedHeaders: [],
      showControllId: null,
    };
  },

  computed: {
    ...mapGetters(['mobileView', 'laptopView', 'isViewAsOtherUser']),

    pagination: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit('paginate', {
          search: this.search,
          perPage: this.perPage,
          page,
        });
      },
    },
    filterBarClasses() {
      return {
        'data-table__filter-bar--multiline': this.multilineFilters,
      };
    },

    totalPages() {
      return Math.ceil((this.totalItems ?? 0) / (this.perPage ?? 10));
    },

    showPagination() {
      return this.totalPages > 1;
    },

    availableHeaders() {
      if (this.dynamicHeaders) {
        return this.dynamicHeadersList;
      }
      return this.headers;
    },

    dataTableClasses() {
      const list = {};
      list['data-table-embed'] = this.embed;
      return list;
    },

    perPageModel: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit('paginate', {
          search: this.search,
          perPage: val,
          page: 1,
        });
      },
    },

    searchModel: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('paginate', {
          perPage: this.perPage,
          page: 1,
          search: val,
        });
      },
    },

    hidableHeaders() {
      return this.headers.filter((header) => header.hidable);
    },

    hidableHeadersHash() {
      return this.hidableHeaders.reduce((acc, header) => {
        acc[header.key] = true;
        return acc;
      }, {});
    },

    dynamicHeadersList() {
      return this.headers.filter((header) => {
        if (this.hidableHeadersHash[header.key]) {
          return this.selectedHeaders.includes(header.key);
        }
        return true;
      });
    },
  },

  mounted() {
    this.fetchColumns();
  },

  methods: {
    itemAction(header, item) {
      if (this.isFileTable) {
        if (header === 'name' && !!item.action) {
          item.action();
        }
        return;
      }

      if (header !== 'actions' && !!item.action) {
        item.action();
      }
    },

    headerClasses(header) {
      return [
        {
          squeeze: !!header.squeeze,
          sortable: !!header.sortable,
          centred: !!header.centred,
          [`sort-order--${this.sortOrder}`]: this.sortBy === header.key,
          'is-sorted': this.sortBy === header.key,
        },
        header.headerClass,
      ];
    },

    headerStyles(header) {
      return {
        width: header.width,
      };
    },

    celClasses(header) {
      const list = {};

      list[`data-table__cel-${header.key}`] = true;
      list.squeeze = !!header.squeeze;
      list.centred = !!header.centred;
      list['is-sorted'] = this.sortBy === header.key;

      return [list, header.cellClass];
    },

    onHeaderClick(e, header) {
      this.$emit('headerClick', header);

      if (header.sortable) {
        const by = this.sortBy;
        const order = this.sortOrder;
        let sortBy = by;
        let sortOrder = order;

        if (header.key === by) {
          if (order === 'desc') {
            sortOrder = 'asc';
          } else {
            sortBy = null;
            sortOrder = 'desc';
          }
        } else {
          sortBy = header.key;
          sortOrder = 'desc';
        }

        this.$emit('sort', { sortBy, sortOrder });
      }
    },

    fetchColumns() {
      this.selectedHeaders = JSON.parse(localStorage.getItem(SELECTED_COLUMNS_STORAGE) || '[]');
    },

    saveColumns() {
      localStorage.setItem(SELECTED_COLUMNS_STORAGE, JSON.stringify(this.selectedHeaders));
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:color';
@import '@/assets/stylesheet/variables';

$embed-padding: 1.5rem;

.p-0 {
  padding: 0;
}

.breadcrumps-container {
  margin-top: 20px;
  padding: 0 20px;
}

.table-info-row {
  &__wrap {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
  }
}

.data-table {
  $border-width: 1.5px;

  &__collapsed {
    $border: $border-width solid var(--bb-primary);
    border-left: $border;
    border-right: $border;

    &:first-child {
      border-top: $border;
    }

    &:nth-child(2) {
      border-bottom: $border;
    }
  }

  .table__container {
    overflow: auto;
  }

  &__filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &--multiline {
      flex-direction: column;
      align-items: flex-start;
    }

    &__right,
    &__left {
      display: flex;
      align-items: center;
      gap: 10px 1rem;
    }

    &__right:first-child {
      // when __left doesn't exist
      justify-content: space-between;
      padding: 0 0.75rem;
      width: 100%;
    }

    &--multiline &__right {
      align-self: flex-end;
      margin-top: 10px;
    }

    &__per-page:deep {
      display: flex;
      align-items: center;
      & span.perpage-label {
        margin-right: 10px;
      }
    }

    &__custom-filter {
      margin: auto;
    }
  }

  &__pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em;
  }

  &__columns-filter-container {
    min-width: 240px;
    width: fit-content;
    margin: 0 13px 20px 16px;
  }

  &__search-field {
    width: 240px;
    display: flex;
  }

  & .table {
    margin: 0;
  }

  .table tr:nth-child(odd) {
    background-color: var(--bb-table-row--odd);
  }

  .table-not-striped-tr:nth-child(odd) {
    background-color: inherit !important;
  }

  & .table th {
    position: relative;
    background: var(--bb-app-bg-main);
    font-weight: 700;
    transition: all $bb-duration;

    &.squeeze {
      width: 0.1%;
    }

    &.centred {
      text-align: center;
    }

    &.centred .data-table__header-cel {
      justify-content: center;
    }

    &.sortable {
      cursor: pointer;

      &:hover {
        background: var(--bb-table-bg--hover);
        border-color: var(--bb-table-sortable-border--hover, color.mix($bb-green, $white, 20%));
      }

      & .data-table__header-layout:hover {
        color: var(--bb-primary);
        border-bottom-color: var(--bb-primary);
      }
    }
  }

  & .table td {
    transition: all $bb-duration;
    vertical-align: middle;

    &.centred {
      text-align: center;
    }
  }

  & .table tr:hover td {
    background: var(--bb-table-bg--hover);
    box-shadow: inset 0 1px 0 0 var(--bb-primary-light-2),
      inset 0 -1px 0 0 var(--bb-primary-light-2);
  }

  & .table tr:nth-child(even):hover td {
    background: var(--bb-table-bg--hover);
  }

  & .table th.is-sorted,
  & .table td.is-sorted {
    background: var(--bb-table-sorted-bg--odd);
  }

  .table tr:nth-child(even) td.is-sorted {
    background: var(--bb-table-sorted-bg--even);
  }

  & .table tr:hover td.is-sorted {
    background: var(--bb-table-bg--hover);
  }

  .table tr:hover:nth-child(even) td.is-sorted {
    background: var(--bb-table-bg--hover);
  }

  & .table tr.selected {
    background: var(--bb-table-bg--hover);
  }

  & .table #{&}__progress-row {
    & :deep(.ui-progress) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    & th {
      position: relative;
      padding: 0;
    }
  }
}

.data-table__header-layout {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.data-table__header-cel {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 0;
  pointer-events: none;
}

.data-table__sort {
  position: relative;
  margin-left: 10px;
  width: 14px;
  height: 14px;

  &-arrow {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--bb-gray-800);
    transform: translate(-50%, -50%);
    transition: all $bb-duration;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-arrow::before,
  &-arrow::after {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 2px;
    background: currentColor;
    border-radius: 2px;
  }

  &-arrow::before {
    transform-origin: 100% 0;
    transform: translateX(-50%) rotate(-42deg);
  }

  &-arrow::after {
    transform-origin: 0 0;
    transform: translateX(50%) rotate(42deg);
  }

  &-arrow-up {
    transform: translate(-50%, -7px);
  }

  &-arrow-down {
    transform: translate(-50%, 7px) rotate(180deg);
  }

  .table th:hover &-arrow {
    color: var(--bb-table-arrow-bg--hover);
  }

  .table th.sort-order--asc &-arrow-down,
  .table th.sort-order--desc &-arrow-up {
    color: var(--bb-primary);
  }
}

.table th.sortable .data-table__sort {
  display: block;
}

.data-table-embed {
  & .table td:first-child,
  & .table th:first-child {
    padding-left: $embed-padding;
  }

  & .table td:last-child,
  & .table th:last-child {
    padding-right: $embed-padding;
    text-align: end;
    width: 10%;
  }
}

.data-table__columns-filter:deep {
  width: 245px;

  .ui-multiselect {
    &-tags {
      display: inline-flex;
      flex-wrap: nowrap;
      max-width: 190px;
      overflow-x: auto;

      //hide scrollbar
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        /* Chrome */
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        z-index: 1;
        background: linear-gradient(-90deg, #fff 0, #fff 30%, rgba(255, 255, 255, 0) 80%);
      }

      & > * {
        flex-shrink: 0;
      }
    }
  }
}

@media screen and (max-width: 1900px) {
  .data-table {
    font-size: 16px;
  }

  .search {
    width: 240px;
    height: 32px;
  }
}

@media screen and (max-width: 1268px) {
  .data-table {
    & .stack.stack-md > .stack-item {
      padding-left: 5px;
    }
  }

  .search {
    font-size: 14px;
  }

  .data-table-embed {
    & .table td:last-child,
    & .table th:last-child {
      width: 10%;
    }
  }

  .data-table__filter-bar {
    align-items: center;
    margin-top: 10px;

    &__right {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .data-table__columns-filter {
    min-height: initial;
  }
}

@media screen and (max-width: 1100px) {
  .data-table {
    padding: 0;
    &__filter-bar {
      &__per-page {
        font-size: 14px;
      }

      &__custom-filter:deep {
        & p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .data-table {
    overflow: auto;

    &__filter-bar {
      flex-direction: column;

      &__left {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      &__left &__per-page {
        font-size: 14px;
      }

      &__custom-filter {
        margin: 0;
      }

      &__custom-filter:deep {
        & p {
          font-size: 14px;
        }
      }
    }

    &__columns-filter-container {
      width: 100%;
      margin-bottom: 20px;
    }

    &__search-field {
      width: 100%;
    }

    &__pagination-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }

  .search {
    width: 100%;
    font-size: 16px;
    height: 40px;
  }

  .users-available_all_reports {
    padding: 0;
    margin: 55px;
    font-size: 16px;
  }

  .table {
    min-width: 100vw;
    width: max-content;
    &__container {
      width: 100%;
    }
  }
}
</style>
