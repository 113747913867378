<template lang="pug">
.unsubscribe
  .unsubscribe__header
    .unsubscribe__header--left
      img(:src="images.logo")
      h3 {{ $t('unsubscribe.title') }}
    .unsubscribe__header--right
      img(:src="images.letter")
  .unsubscribe__body
    template(v-if="failed")
      p {{ $t('unsubscribe.failed') }}
    template(v-else)
      p.description {{ $t('unsubscribe.description') }}
      p.secondary {{ $t('unsubscribe.note') }}
</template>

<script>
import logo from '@/assets/images/unsubscribe/logo.svg';
import letter from '@/assets/images/unsubscribe/letter.svg';

export default {
  name: 'Unsubscribed',
  data() {
    return {
      failed: this.$route.query.failed !== undefined,
    };
  },
  computed: {
    images() {
      return {
        logo,
        letter,
      };
    },
  },
};
</script>

<style lang="scss">
.unsubscribe {
  background-color: var(--bb-app-bg-main);
  width: 563px;
  margin: auto;
  padding: 20px 30px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #b7b8b9;

    &--left :first-child {
      margin-top: 16px;
      margin-bottom: 40px;
    }
  }

  &__body {
    padding-top: 20px;
    text-align: center;
  }
}

.description {
  font-size: 18px;
}

.secondary {
  font-size: 16px;
}
</style>
