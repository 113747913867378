import AppBaseModel from './AppBaseModel';

class IntegrationAttempts extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'integration_attempts',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchCurrent(...rest) {
    return this.httpClient.get(`${this.url}/current`, ...rest);
  }

  setCompany(companyId, ...rest) {
    return this.httpClient.put(this.entityUrl, { company_id: companyId }, ...rest);
  }

  createCompany(company, ...rest) {
    return this.httpClient.post(`${this.entityUrl}/create_company`, { company }, ...rest);
  }

  updateIntegration(...rest) {
    return this.httpClient.put(`${this.entityUrl}/update_integration`, ...rest);
  }

  finalize(software, ...rest) {
    return this.httpClient.put(`${this.entityUrl}/finalize`, { software }, ...rest);
  }

  request(companyId, ...rest) {
    return this.httpClient.post(
      `${this.entityUrl}/integration_request`,
      { company_id: companyId },
      ...rest,
    );
  }
}

export default IntegrationAttempts;
