import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

const state = {
  isReady: false,
  groups: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
