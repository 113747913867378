import companyGroupsModule from './companyGroups/index.js';
import editingCompanyModule from './editingCompany/index.js';

export default {
  modules: {
    companyGroups: companyGroupsModule,
    editingCompany: editingCompanyModule,
  },
  namespaced: true,
};
