import AppBreadcrumbs from '@/components/AppBreadcrumbs.vue';
import AvatarBox from '@/components/Avatar/AvatarBox.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import DataTableRowActions from '@/components/DataTable/DataTableRowActions.vue';
import RestTable from '@/components/RestTable/RestTable.vue';
import Modal from '@/components/elements/modal/Modal.vue';
import EmptyState from '@/components/Empty/EmptyState.vue';
import PowerbiClient from '@/components/PowerbiClient.vue';
import OverlayLoader from '@/components/Loader/Overlay.vue';
import UiFormMultiselect from '@/components/elements/ui-form-multiselect/ui-form-multiselect.vue';
import UiFormCheckbox from '@/components/elements/ui-form-checkbox/ui-form-checkbox.vue';
import UiCheckboxMultiselect from '@/components/elements/ui-checkbox-multiselect/ui-checkbox-multiselect.vue';
import UiText from '@/components/elements/ui-text/ui-text.vue';
import BibookLogotype from '@/components/BibookLogotype.vue';
import Heading from '@/components/Heading/Heading.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import TabHeader from '@/components/Tabs/TabHeader.vue';
import UiURLInput from '@/components/elements/ui-url-input/ui-url-input.vue';
import UiFormURLInput from '@/components/elements/ui-form-url-input/ui-form-url-input.vue';
import UiFormInput from '@/components/elements/ui-form-input/ui-form-input.vue';
import UiProgress from '@/components/elements/ui-progress/ui-progress.vue';
import UiTree from '@/components/elements/ui-tree/ui-tree.vue';
import UiTreeItem from '@/components/elements/ui-tree/ui-tree-item.vue';
import UiTreeItemRecursive from '@/components/elements/ui-tree/ui-tree-item-recursive.vue';
import UiEmptyState from '@/components/elements/ui-empty-state/ui-empty-state.vue';
import UiRestTable from '@/components/elements/ui-rest-table/ui-rest-table.vue';
import UiRestTreeTable from '@/components/elements/ui-rest-table/ui-rest-tree-table.vue';
import UiStatusBoolean from '@/components/elements/ui-status-boolean/ui-status-boolean.vue';
import EntityTypeIcon from '@/components/EntityTypeIcon.vue';
import UserCard from '@/components/UserCard.vue';
import UiInfo from '@/components/elements/ui-info/ui-info.vue';
import UiFormDatePicker from '@/components/elements/ui-form-date-picker/ui-form-date-picker.vue';
import {
  UiDatePicker,
  UiMultiselect,
  UiInput,
  UiEditor,
  UiIcon,
  UiCheckbox,
  UiSkeleton,
  UiInputLabel,
  UiInputFeedback,
  UiDivider,
  UiSpinner,
  UiButton,
  // UiButtonBase,
  UiFilePicker,
  UiAlert,
  UiRadio,
  UiTabs,
  UiCard,
  UiCardSection,
  UiFormRadio,
  UiBadge,
  UiPopover,
  UiBreadcrumbs,
  UiToast,
  UiPagination,
  UiTooltip,
  UiModal,
  UiAvatar,
  UiOptionsMenu,
  UiOptionsMenuItem,
  UiOptionsMenuTitle,
  UiOptionsMenuDivider,
  UiStack,
  UiStackItem,
  UiSidebar,
  UiSidebarNavHeader,
  UiSidebarNavItem,
  UiLoader,
  UiDataTable,
  UiPhoneNumberInput,
  UiInputGroup,
  UiInputGroupText,
  UiDraggableList,
  UiCollapse
} from '@bi-book/bibook-ui-kit';

export default function (app) {
  const Vue = app;

  Vue.component('UserCard', UserCard);
  Vue.component('TabHeader', TabHeader);
  Vue.component('AppBreadcrumbs', AppBreadcrumbs);
  Vue.component('EntityTypeIcon', EntityTypeIcon);
  Vue.component('AvatarBox', AvatarBox);
  Vue.component('Stack', UiStack);
  Vue.component('StackItem', UiStackItem);
  Vue.component('UiTabs', UiTabs);
  Vue.component('UiCard', UiCard);
  Vue.component('UiCardSection', UiCardSection);
  Vue.component('DataTable', DataTable);
  Vue.component('DataTableRowActions', DataTableRowActions);
  Vue.component('RestTable', RestTable);
  Vue.component('Modal', Modal);
  Vue.component('EmptyState', EmptyState);
  Vue.component('PowerbiClient', PowerbiClient);
  Vue.component('OverlayLoader', OverlayLoader);
  Vue.component('UiMultiselect', UiMultiselect);
  Vue.component('UiFormMultiselect', UiFormMultiselect);
  Vue.component('UiInput', UiInput);
  Vue.component('UiEditor', UiEditor);
  Vue.component('UiIcon', UiIcon);
  Vue.component('UiCheckbox', UiCheckbox);
  Vue.component('UiFormCheckbox', UiFormCheckbox);
  Vue.component('UiSkeleton', UiSkeleton);
  Vue.component('UiInputLabel', UiInputLabel);
  Vue.component('UiInputFeedback', UiInputFeedback);
  Vue.component('UiDivider', UiDivider);
  Vue.component('UiSpinner', UiSpinner);
  Vue.component('UiButton', UiButton);
  // Vue.component('UiButtonBase', UiButtonBase);
  Vue.component('UiText', UiText);
  Vue.component('UiFilePicker', UiFilePicker);
  Vue.component('BibookLogotype', BibookLogotype);
  Vue.component('UiPagination', UiPagination);
  Vue.component('Heading', Heading);
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('UiURLInput', UiURLInput);
  Vue.component('UiFormURLInput', UiFormURLInput);
  Vue.component('UiFormInput', UiFormInput);
  Vue.component('UiAlert', UiAlert);
  Vue.component('UiRadio', UiRadio);
  Vue.component('UiFormRadio', UiFormRadio);
  Vue.component('UiProgress', UiProgress);
  Vue.component('UiBadge', UiBadge);
  Vue.component('UiStatusBoolean', UiStatusBoolean);
  Vue.component('UiPopover', UiPopover);
  Vue.component('UiBreadcrumbs', UiBreadcrumbs);
  Vue.component('UiToast', UiToast);
  Vue.component('UiTooltip', UiTooltip);
  Vue.component('UiModal', UiModal);
  Vue.component('UiAvatar', UiAvatar);
  Vue.component('UiOptionsMenu', UiOptionsMenu);
  Vue.component('UiOptionsMenuItem', UiOptionsMenuItem);
  Vue.component('UiOptionsMenuTitle', UiOptionsMenuTitle);
  Vue.component('UiOptionsMenuDivider', UiOptionsMenuDivider);
  Vue.component('UiDatePicker', UiDatePicker);
  Vue.component('UiFormDatePicker', UiFormDatePicker);
  Vue.component('UiStack', UiStack);
  Vue.component('UiStackItem', UiStackItem);
  Vue.component('UiDataTable', UiDataTable);
  Vue.component('UiTree', UiTree);
  Vue.component('UiTreeItem', UiTreeItem);
  Vue.component('UiTreeItemRecursive', UiTreeItemRecursive);
  Vue.component('UiEmptyState', UiEmptyState);
  Vue.component('UiRestTable', UiRestTable);
  Vue.component('UiRestTreeTable', UiRestTreeTable);
  Vue.component('UiSidebar', UiSidebar);
  Vue.component('UiSidebarNavHeader', UiSidebarNavHeader);
  Vue.component('UiSidebarNavItem', UiSidebarNavItem);
  Vue.component('UiCheckboxMultiselect', UiCheckboxMultiselect);
  Vue.component('UiLoader', UiLoader);
  Vue.component('UiPhoneNumberInput', UiPhoneNumberInput);
  Vue.component('UiInputGroup', UiInputGroup);
  Vue.component('UiInputGroupText', UiInputGroupText);
  Vue.component('UiInfo', UiInfo);
  Vue.component('UiDraggableList', UiDraggableList);
  Vue.component('UiCollapse', UiCollapse);
}
