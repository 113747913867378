import ApiUser from '@/api/user.js';

export class InitDataService {
  constructor() {
    this.request = null;
    this.initData = null;
  }

  async fetch() {
    this.initData = await ApiUser.fetchInit();
  }

  async getData() {
    if (this.initData) {
      return this.initData;
    }

    if (this.request === null) {
      this.request = this.fetch();
    }

    if (this.request !== null) {
      await this.request;
    }

    this.request = null;

    return this.initData;
  }
}

export const initDataService = new InitDataService();
