<template lang="pug">
.tab-container
  .table-filters
    Stack(wrap)
      StackItem
        UiInput(
          :placeholder="$t('actions.search')"
          :modelValue="pagination.filter.search"
          @update:modelValue="updateFilter({ search: $event })"
        )
          template(#icon)
            UiIcon(name="SearchIcon")
      StackItem
        UserSelect(
          :modelValue="pagination.filter.user"
          userType="admin_user"
          @update:modelValue="updateFilter({ user: $event })"
        )
      StackItem
        CompanySelect(
          :modelValue="pagination.filter.company"
          :placeholder="$t('logs.category_input_placeholder')"
          @update:modelValue="updateFilter({ company: $event })"
        )
      StackItem
        UiDatePicker(
          v-model="filterDatesModelValue"
          :range="true"
          size="md"
        )
  DataTable(
    :headers="headersList"
    :items="items"
    :page="pagination.page"
    :per-page="pagination.per_page"
    :search="pagination.search"
    :total-items="meta.total_count"
    @paginate="onPaginate"
  )
    template(#cel_user_id="{ item }")
      Stack(align="center" spacing="sm")
        StackItem
          AvatarBox(:src="item.user_id.avatar" size="sm")
        StackItem
          | {{ item.user_id.email }}
    template(#cel_user_name="{ item }")
      span {{ item.user_id.name }}
    template(#cel_updated_at="{ item }")
      span {{ $dateFormatter(item.updated_at) }}
    template(#cel_company_id="{ item }")
      span {{ item.event_label_extended.company_name }}
</template>

<script>
import UserSelect from '@/components/UserSelect.vue';
import tabLogic from '@/views/Admin/Logs/mixins/tabLogic.js';
import CompanySelect from '@/components/CompanySelect.vue';
import Pagination from '@/models/Pagination.js';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';

export default {
  components: { CompanySelect, UserSelect },
  mixins: [tabLogic],
  data() {
    const newDate = new Date();
    return {
      namespace: 'logins',
      pagination: new Pagination({
        search: '',
        sort_by: null,
        sort_order: 'desc',
        page: 1,
        per_page: 10,
        filter: {
          event_category: 'Users',
          event_name: 'Login',
          user: [],
          time_start: startOfDay(newDate),
          time_end: endOfDay(newDate),
          company: null,
        },
      }),
      headersList: [
        {
          key: 'user_id',
          label: this.$t('admin_logs.table.user'),
        },
        {
          key: 'user_name',
          label: this.$t('admin_logs.table.user_name'),
        },
        {
          key: 'company_id',
          label: this.$t('admin_logs.table.company'),
        },
        {
          key: 'updated_at',
          label: this.$t('admin_logs.table.date'),
        },
      ],
      defaultCompanyId: 'all',
    };
  },
  computed: {
    filterDatesModelValue: {
      get() {
        return [this.pagination.filter.time_start, this.pagination.filter.time_end];
      },
      set(newValue) {
        this.updateFilter({ time_start: newValue[0], time_end: newValue[1] });
      },
    },
  },
};
</script>

<style scoped></style>
