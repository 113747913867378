/* eslint-disable no-shadow */
import Company from '@/models/Company.js';

const companies = (state) => state.companies;
const loginBanner = (state) => state.loginBanner;
const systemBanners = (state) => state.systemBanners;
const hasCompanies = (state) => !!state.companies.length;
/** The getter from init.json. It persists only on subdomain */
const currentCompany = (state) => state.currentCompany;
const currentCompanyId = (state) => state.currentCompanyId || null;
const reportThemes = (state, getters) => {
  const company = getters.currentCompany;
  const result = [];
  if (!company) {
    return result;
  }
  if (company.report_default_theme_visible) {
    result.push({ id: null, name: 'Default', is_global: true });
  }
  const themes = ([...getters.currentCompany.report_themes] || []).sort((t) =>
    t.is_global ? 1 : -1,
  );
  result.push(...themes);
  return result;
};
/** The getter from fetch request */
const company = (state) => state.company;
const companyInProgress = (state) => state.inProgress;
const isUser = (state) => {
  const company = currentCompany(state);
  return Company.isUser(company?.role);
};
const isAdmin = (state) => {
  const company = currentCompany(state);
  return Company.isAdmin(company?.role);
};

export default {
  company,
  companies,
  companyInProgress,
  hasCompanies,
  currentCompany,
  currentCompanyId,
  reportThemes,
  isUser,
  isAdmin,
  loginBanner,
  systemBanners,
};
