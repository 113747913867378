class PowerBiReportExtras {
  constructor(extras) {
    this.editable = extras.editable ?? false;
    this.linked_report_ids = extras.linked_report_ids ?? [];
    this.pages = extras.pages ?? [];
    this.contains_embedded_links = extras.contains_embedded_links ?? false;
    this.can_refresh = extras.can_refresh ?? false;
    this.refresh_message = extras.refresh_message ?? false;
    this.refresh_finished_message = extras.refresh_finished_message ?? false;
    this.refresh_status = extras.refresh_status ?? null;
    this.refresh_again = extras.refresh_again ?? false;
    this.refresh_timeout = extras.refresh_timeout ?? 0;
    this.refresh_tables = extras.refresh_tables ?? [];
  }
}

export default PowerBiReportExtras;
