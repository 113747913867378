<template lang="pug">
.header-profile
  UiPopover(
    placement="bottom-end"
    :offset="[0, 6]"
    stopPropagation
  )
    template(#activator="{ show }")
      UiButton.header-profile__button(
        ref="activator"
        variant="ghost"
        border="smooth"
        :round="mobileView"
        :active="show"
        size="lg"
      )
        template(#icon)
          AvatarBox.header-profile__avatar(
            :src="user.avatar"
            :label="user.name || user.email"
            size="sm"
          )
        Stack(align="center")
          StackItem
            .header-profile__label
              .header-profile__name {{ user.name || user.email }}
              .header-profile__role(v-if="userRole") {{ userRole }}
          StackItem
            UiIcon.header-profile__arrow(name="ChevroneBottom" :class="{ active: show }")
    MiniProfile(@startEditUser="$refs.editUserModal.open()")
EditUserModal(ref="editUserModal")
</template>

<script>
import { mapGetters } from 'vuex';
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';
import MiniProfile from '@/components/MiniProfile.vue';
import EditUserModal from '@/modals/User/Edit.vue';

export default {
  components: {
    MiniDropdown,
    MiniProfile,
    EditUserModal,
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'user',
      'isViewAsOtherUser',
      'navOpen',
      'headerOpen',
      'mobileView',
      'isAdmin',
    ]),
    ...mapGetters('company', ['currentCompany', 'hasCompanies']),
    userRole() {
      if (this.isAdmin || !this.currentCompany) return '';
      return this.$t(`company.role.${this.currentCompany.role}`);
    },
  },
  methods: {
    hide() {
      this.showDropdown = false;
    },
    show() {
      this.showDropdown = true;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.header-profile {
  &__avatar {
    box-shadow: 0 0 0 3px var(--bb-app-bg-main);
  }

  &__button {
    padding-right: 10px !important;
  }

  &__label {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    line-height: 1;
  }

  &__role {
    font-size: 0.85em;
    color: var(--bb-gray-600);
  }

  &__button:hover &__role {
    color: var(--bb-primary);
  }

  &__dropdown {
    overflow: visible !important;
    padding: 7px 0;
  }
}
</style>
