<template lang="pug">
div(
  :class="containerClass"
  :style="containerStyle"
  :data-report-id="report.id"
  :data-report-opened="isOpenedReport"
)
  hr(
    v-if="divider"
    :style="isOpenedTree ? {} : { margin: '0' }"
    data-test-id="hr"
  )
  .analytics-sidebar__nav__report
    .analytics-sidebar__nav__report__item(data-test-id="report-item" @click="$emit('setReport')")
      .analytics-sidebar__nav__report__item__left
        UiIcon(
          name="ChevroneRight"
          :class="{ 'active-90': isOpenedReport }"
          data-test-id="icon-right"
        )
        span {{ report.name }}
    template(v-if="report.merged_report")
      AnalyticsSidebarReport(
        v-for="linkedReportId in report.linkedReportsList"
        :report="reports[linkedReportId]"
        :reports="reports"
        :isOpenedGroup="isOpenedTree || isOpenedReport || isOpenedGroup"
        :currentReportId="currentReportId"
        :currentLinkedReportKey="currentLinkedReportKey"
        :currentPageIndex="currentPageIndex"
        divider
        linkedReport
        data-test-id="merged-report"
        @setReport="() => $emit('setLinkedReport', linkedReportId)"
        @setPage="(i) => $emit('setPage', i)"
      )
    .analytics-sidebar__nav__pages(
      v-else-if="isOpenedReport"
      :style="pagesStyle"
      data-test-id="nav-pages"
    )
      template(v-if="report.pages.length")
        AnalyticsSidebarPages(
          :pages="report.pages"
          :currentPageIndex="currentPageIndex"
          @setPage="(index) => $emit('setPage', index)"
        )
      template(v-else-if="report.accessError")
        .analytics-sidebar__nav__pages--error
          span {{ $t('report-crashed') }}
      template(v-else)
        .analytics-sidebar__nav__pages--loading
          UiSpinner(data-test-id="report-spinner")
</template>

<script>
import AnalyticsSidebarPages from '@/views/Analytics/components/Sidebar/Pages.vue';

export default {
  name: 'AnalyticsSidebarReport',
  components: {
    AnalyticsSidebarPages,
  },
  props: {
    report: {
      type: Object,
      default() {
        return {};
      },
    },
    reports: {
      type: Array,
      default() {
        return [];
      },
    },
    currentReportId: {
      type: String,
      default: null,
    },
    currentLinkedReportKey: {
      type: String,
      default: null,
    },
    currentPageIndex: {
      type: [Number, String],
      default: null,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    linkedReport: {
      type: Boolean,
      default: false,
    },
    rootLevel: {
      type: Boolean,
      default: false,
    },
    isOpenedGroup: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: null,
    },
  },

  emits: ['setReport', 'setLinkedReport', 'setPage'],

  computed: {
    containerStyle() {
      if (this.rootLevel) {
        const st = { paddingLeft: '0' };
        if (this.isOpenedReport) return { ...st, background: 'var(--bb-gray-900)' };
        return st;
      } else {
        if (this.isOpenedTree || this.isOpenedReport || this.isOpenedGroup) {
          return { transform: 'scaleY(1)', marginTop: '10px' };
        }
        return { transform: 'scaleY(0)', transformOrigin: 'top', maxHeight: '0' };
      }
    },
    pagesStyle() {
      if (this.rootLevel) {
        if (this.isOpenedReport) return { transform: 'scaleY(1)', marginTop: '10px' };
        return { transform: 'scaleY(0)', transformOrigin: 'top', maxHeight: '0' };
      } else {
        if (this.isOpenedReport) return {};
        return { height: '0' };
      }
    },
    isOpenedReport() {
      if (this.linkedReport) {
        return this.currentLinkedReportKey === this.report.linkedReportKey;
      } else {
        return this.currentReportId === this.report.id;
      }
    },
    isOpenedTree() {
      return this.isOpenedGroup || (this.isOpenedGroup && this.report.merged_report);
    },
  },
};
</script>
