<template lang="pug">
Teleport(v-if="show" to="#app-breadcrumbs")
  UiBreadcrumbs.app-breadcrumbs(v-bind="$attrs" size="sm")
</template>

<script>
export default {
  name: 'AppBreadcrumbs',
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.show = true;
    });
  },
};
</script>

<style lang="scss">
.app-breadcrumbs {
  white-space: nowrap;
}
</style>
