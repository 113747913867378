<template lang="pug">
DataTable(
  :embed="embed"
  :progress="progress"
  :headers="headers"
  :visibleCollapsed="visibleCollapsed"
  :items="itemsList"
  :sort-by="pagination.sort_by"
  :sort-order="pagination.sort_order"
  :page="pagination.page"
  :per-page="pagination.per_page"
  :searchable="searchable"
  :search="pagination.search"
  :search-label="searchLabel"
  :filtered="filtered"
  :filtersBottom="filtersBottom"
  :dynamicHeaders="dynamicHeaders"
  :notStriped="notStriped"
  :per-page-options="perPageOptions"
  :total-items="totalItems"
  :isFileTable="isFileTable"
  :breadcrumps="breadcrumps"
  :selectedIds="selectedIds"
  :showBottomPagination="showBottomPagination"
  :showTopPagination="showTopPagination"
  @sort="onSort"
  @paginate="onPaginate"
)
  template(#collapsed="props")
    slot(v-bind="props" name="collapsed")
  template(v-for="col in headers" v-slot:[`head_${col.key}`]="props")
    slot(v-bind="props" :name="`head_${col.key}`")
  template(v-for="col in headers" v-slot:[`cel_${col.key}`]="props")
    slot(v-bind="props" :name="`cel_${col.key}`")
  template(v-for="row in itemsList" v-slot:[`row_${row.name}`]="props")
    slot(v-bind="props" :name="`row_${row.name}`")
  template(v-if="filtered" v-slot:filter_block="props")
    slot(
      v-bind="{ ...props, pagination, filter: pagination.filter, meta, items, updateOptions, updateFilter }"
      name="filter_block"
    )
</template>

<script>
import _isEqual from 'lodash/isEqual.js';
import Pagination from '@/models/Pagination.js';

export default {
  props: {
    namespace: {
      type: String,
      required: true,
    },

    storePath: {
      type: String,
      default: null,
    },

    embed: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    showBottomPagination: {
      type: Boolean,
      default: false,
    },
    showTopPagination: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchLabel: {
      type: String,
      default: 'Search ...',
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    initialFilters: {
      type: Object,
      default: null,
    },
    dynamicHeaders: {
      type: Boolean,
      default: false,
    },
    notStriped: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    visibleCollapsed: {
      type: Array,
      default: () => [],
    },

    perPageOptions: {
      type: Array,
    },

    fetchHandler: {
      type: Function,
      default: null,
    },

    filtersBottom: {
      type: Boolean,
    },

    isFileTable: {
      type: Boolean,
      default: false,
    },

    breadcrumps: {
      type: Array,
      default: () => [],
    },

    folderId: {
      type: String,
      default: null,
    },

    fetchConfig: {
      type: Object,
      default: () => ({}),
    },

    initItemsPerPage: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      inProgress: false,
      pagination: new Pagination({
        search: '',
        sort_by: null,
        sort_order: 'desc',
        page: 1,
        per_page: this.initItemsPerPage,
        filter: this.initialFilters ? { ...this.initialFilters } : {},
      }),
      items: [],
      meta: {
        total_count: 0,
      },
    };
  },

  computed: {
    isStored() {
      return !!this.storePath;
    },

    totalItems() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/meta`].total_count
        : this.meta.total_count;
    },

    itemsList() {
      return this.isStored ? this.$store.getters[`${this.storePath}/items`] : this.items;
    },

    progress() {
      return this.isStored ? this.$store.getters[`${this.storePath}/inProgress`] : this.inProgress;
    },
  },

  watch: {
    $route(r) {
      const table = r.query[`table_${this.namespace}`];

      if (!table || table === JSON.stringify(this.pagination)) {
        return;
      }

      this.pagination = new Pagination(JSON.parse(table));
      this.handleFetchData();
    },
  },

  created() {
    const table = this.$route?.query[`table_${this.namespace}`];
    if (!table) return;
    this.pagination = new Pagination(JSON.parse(table));
  },

  mounted() {
    this.fetchData();
  },

  beforeUnmount() {
    const query = { ...this.$route.query };
    delete query[`table_${this.namespace}`];
    this.$router.replace({ query });
  },

  methods: {
    fetchData(id, params) {
      this.inProgress = true;

      const { filter: filterOptions, ...paginationOption } = this.pagination;
      let fetchParams = this.filtered
        ? { ...paginationOption, ...filterOptions }
        : paginationOption;

      if (params) {
        fetchParams = { ...fetchParams, ...params };
      }

      if (this.isStored) {
        this.$store.dispatch(`${this.storePath}/fetchList`, {
          params: fetchParams,
          ...this.fetchConfig,
        });
        return;
      }

      this.fetchHandler(fetchParams, id)
        .then((resp) => {
          this.items = resp.items;
          this.meta.total_count = resp.meta.total_count || 0;
          this.inProgress = false;
          if (!this.items.length && this.pagination.page > 1) {
            this.updateOptions({ page: 1 });
          }
        })
        .catch((_e) => {
          this.inProgress = false;
        });
    },

    onSort(options) {
      this.updateOptions({
        sort_by: options.sortBy,
        sort_order: options.sortOrder,
      });
    },

    onPaginate(options) {
      this.updateOptions({
        page: options.page,
        per_page: options.perPage,
        search: options.search,
      });
    },

    updateOptions(options) {
      this.pagination = new Pagination({
        ...this.pagination,
        ...options,
      });
      this.updateRouterHistory();
      this.handleFetchData();
      this.$emit('wasUpdatedOptions');
    },

    updateFilter(options) {
      this.pagination = new Pagination({
        ...this.pagination,
        page: 1,
        filter: {
          ...this.pagination.filter,
          ...options,
        },
      });
      this.updateRouterHistory();
      this.handleFetchData();
    },

    updateRouterHistory() {
      this.$router.push({
        query: {
          ...this.$route.query,
          [`table_${this.namespace}`]: JSON.stringify(this.pagination),
        },
      });
    },

    handleFetchData() {
      if (this.folderId) {
        this.fetchData(this.folderId);
      } else {
        this.fetchData();
      }
    },
  },
};
</script>
