<template lang="pug">
UiMultiselect.language-switcher(
  v-model="currentLocale"
  :options="languages"
  label="label"
  :canClear="false"
  size="lg"
  @change="onSwitchLanguage"
)
  template(v-slot:singlelabel="{ value }")
    .ui-multiselect-single-label
      Stack(align="center" spacing="xs")
        StackItem
          AvatarBox(
            sqare
            :src="value.img"
            :id="value.value"
            :label="value.label"
            size="xs"
          )
        StackItem
          | {{ value.label }}
  template(v-slot:option="{ option }")
    Stack(align="center" spacing="xs")
      StackItem
        AvatarBox(
          sqare
          :src="option.img"
          :id="option.value"
          :label="option.label"
          size="xs"
        )
      StackItem
        | {{ option.label }}
</template>

<script>
import LocaleMixin from '@/mixins/locale.js';

export default {
  mixins: [LocaleMixin],

  emits: ['switchLocale', 'change'],

  props: {
    logged: {
      type: Boolean,
      default: false,
    },
    reported: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      currentLocale: null,
    };
  },

  computed: {
    languageAbbr() {
      const locale = this.currentLocale;
      return locale || '...';
    },

    languageIcon() {
      const locale = this.languages.find((lang) => lang.value === this.currentLocale);
      return locale ? locale.img : '...';
    },
  },

  watch: {
    lang() {
      this.setCurrentLanguage();
    },
  },

  mounted() {
    if (this.lang) {
      this.setCurrentLanguage();
      return;
    }
    this.currentLocale = this.currentLanguage.value;
  },

  methods: {
    setCurrentLanguage() {
      this.currentLocale = this.languages.find((l) => l.value === this.lang)?.value;
    },
    onSwitchLanguage(locale) {
      if (this.reported) {
        this.$emit('change', locale);
        this.$emit('switchLocale', locale);
        return;
      }
      this.switchLanguage(locale);
      this.currentLocale = this.currentLanguage.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.language-switcher:deep {
  min-width: 200px;

  .ui-multiselect-wrapper {
    border: none;
  }
}

.lang-label-not-logged {
  color: #000;
  font-weight: 400;
}

.lang-label-logged {
  color: var(--bb-app-bg-main);
  font-weight: 600;
}

.custom-option {
  display: flex;
  align-items: center;
  width: 100%;

  & img {
    width: 24px;
    margin-right: 10px;
  }
}
</style>
