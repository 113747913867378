// todo move to @/constants directory after merge with upgrade bibook branch
const reportLogsReasonsList = Object.freeze({
  SAVED: 'saved',
  LOADED: 'loaded',
  RENDERED: 'rendered',
  PAGE_CHANGED: 'page-changed',
  CONFIGURATION_UPDATED: 'configuration-updated',
  CONFIGURED: 'powerbi-configured',
});

export default reportLogsReasonsList;
