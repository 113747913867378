export const MAIN_BG_KEY = 'app_bg_main';

export const BASE_COLORS = {
  primary: '#0fb57c',
  success: '#0fb57c',
  warning: '#e9a21a',
  danger: '#ed7575',
  info: '#759bd4',
  text_color: '#2f363b',
  gray600: '#838ca0',
  app_bg: '#f1f3f7',
  [MAIN_BG_KEY]: '#fff',
};

export const ANALYTICS_COLORS = {
  analytic_bg: '#fff',
  analytic_report: '#0fb57c',
  analytic_highlight: '#e7f8f2',
};

export const DEFAULT_THEME_COLORS = {
  ...BASE_COLORS,
  ...ANALYTICS_COLORS,
};
