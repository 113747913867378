import AppBaseModel from './AppBaseModel';

class Users extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'users',
      singularKey: 'user',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default Users;
