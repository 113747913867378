class DateTimeService {
  static startOfDay(date) {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  static endOfDay(date) {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }
}

export default DateTimeService;
