<template lang="pug">
.form-wrapper
  UiCardSection(padding="lg")
    Stack(
      vertical
      align="stretch"
      spacing="xl"
    )
      StackItem
        UiText(variant="headingMd" data-test-id="session-title") {{ $t('edit_company.user_session.header') }}
      StackItem
        Stack(justify="space-between")
          StackItem
            UiInputLabel(:shifted="false" data-test-id="session-label") {{ $t('edit_company.user_session.reset_users_session_every') }}
          StackItem
            UiMultiselect.form-wrapper__session-select(
              v-model="form.users_session_timeout"
              :options="resetUsersSessionOptions"
              :canClear="false"
              :canDeselect="false"
              :disabled="inProgress"
              data-test-id="multiselect-session-timeout"
            )
      StackItem
        Stack(justify="space-between")
          StackItem
            UiInputLabel(:shifted="false" data-test-id="session-label-idle") {{ $t('edit_company.user_session.reset_users_session_if_they_idle') }}
          StackItem
            UiMultiselect.form-wrapper__session-select(
              v-model="form.users_idle_timeout"
              :options="resetUsersSessionIdleOptions"
              :canClear="false"
              :canDeselect="false"
              :disabled="inProgress"
              data-test-id="multiselect-idle-timeout"
            )
      StackItem(align="end")
        UiButton(
          :disabled="inProgress || !canUpdate"
          :loading="inProgress"
          data-test-id="save-button"
          @click="submit"
        ) {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import parseResponseErrors from '@/common/parseResponseErrors.js';

export default {
  data() {
    return {
      form: {
        users_session_timeout: null,
        users_idle_timeout: null,
      },
      inProgress: false,
    };
  },
  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters('editCompany', ['company']),
    ...mapGetters(['isAdmin']),

    resetUsersSessionOptions() {
      return [6, 12, 24].map((value) => ({ value, label: `${value}h` }));
    },

    resetUsersSessionIdleOptions() {
      return [1, 2, 4].map((value) => ({ value, label: `${value}h` }));
    },

    companyId() {
      return this.$route.params?.id ?? this.currentCompany?.id;
    },

    canUpdate() {
      return (this.form.users_session_timeout && this.form.users_idle_timeout) !== null;
    },
  },
  mounted() {
    this.fetchCompanyForm();
  },
  methods: {
    async submit() {
      this.inProgress = true;
      // TODO: recheck it, was rewritten
      try {
        this.$store.dispatch('company/updateCompany', {
          company: { settings: { ...this.form } },
          companyId: this.isAdmin ? this.companyId : null,
        });
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('edit_company.status.info_updated_success'),
          timeout: 10000,
        });
      } catch (error) {
        console.error(error);

        if (error.response.status === 400) {
          const externalResults = { company: parseResponseErrors(error.response.data.errors) };
          Object.assign(this.externalResults, externalResults);
        }
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },

    fetchCompanyForm() {
      this.form.users_idle_timeout = this.company.users_idle_timeout;
      this.form.users_session_timeout = this.company.users_session_timeout;
    },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper {
  max-width: 510px;

  &__session-select {
    width: 142px;
  }
}
</style>
