<template lang="pug">
.ui-status-boolean(:class="classes")
  UiIcon(:name="icon")
</template>

<script>
const StatusIcon = {
  success: 'Check',
  danger: 'Close',
  unknown: 'DottedHorizontal',
};

export default {
  props: {
    value: {
      type: Boolean,
      default: null,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['xs', 'sm', 'md', 'lg'].includes(value);
      },
    },
  },
  computed: {
    status() {
      if (typeof this.value !== 'boolean') {
        return 'unknown';
      }
      return this.value ? 'success' : 'danger';
    },
    icon() {
      return StatusIcon[this.status];
    },
    classes() {
      return [`ui-status-boolean-${this.size}`, `ui-status-boolean--${this.status}`];
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables';

@mixin variant($color, $variant) {
  color: $color;
  background: var(--bb-status-boolean--#{$variant});
}

.ui-status-boolean {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @each $key, $value in $avatar-sizes {
    &-#{$key} {
      width: $value;
      height: $value;
      font-size: calc($value - 14px);
    }
  }

  &--success {
    @include variant(var(--bb-success), success);
  }

  &--danger {
    @include variant(var(--bb-danger), danger);
  }

  &--unknown {
    // when value actually is undefined
    @include variant(#fff, unknown);
  }
}
</style>
