import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      entity: null,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          onClick: this.submit,
          loading: this.inProgress,
          variant: 'smooth-danger',
          'data-vi': 'submit-btn',
        },
      ];
    },
  },
  methods: {
    open(payload) {
      this.entity = payload.entity;
      this.inProgress = false;
      this.show = true;
      this.init(payload);
    },
    close() {
      this.show = false;
    },
    init() {},
  },
});
