<template lang="pug">
.access-tree
  TabHeader(title="Groups membership")
  UiRestTable(
    ref="dataTable"
    namespace="groups"
    :headers="headers"
    :fetchHandler="fetchData"
  )
    template(#cell:name="{ item }")
      RouterLink(:to="getGroupRoute(item)") {{ item.name }}
    template(#cell:actions="{ item }")
      DataTableRowActions(:actions="itemActions(item)")
    template(#cell:members="{ item }")
      | {{ $t('user_groups.members_count', { count: item.members_count }) }}
    template(#cell:sensitive_info_included="{ item }")
      Stack(justify="center")
        StackItem
          UiStatusBoolean(:value="item.sensitive_info_included")
  UiCardSection
  DeleteUserUserGroupsModal(ref="deleteUserUserGroupsModal" @done="refetchTable")
</template>

<script>
import Companies from '@/api/models/Companies.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import UserGroups from '@/api/models/UserGroups.js';
import DeleteUserUserGroupsModal from '@/modals/UserGroups/DeleteUser.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    DeleteUserUserGroupsModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    headers() {
      return [
        {
          key: 'name',
          label: this.$t('user_groups.table.name'),
          sortable: true,
        },
        {
          key: 'members',
          label: this.$t('user_groups.table.users'),
        },
        {
          key: 'sensitive_info_included',
          label: this.$t('user_groups.table.sensitive_info_included'),
          squeeze: true,
          class: 'text-nowrap',
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  methods: {
    async fetchData(filters) {
      const result = {
        items: [],
        meta: {},
      };
      try {
        const response = await new UserGroups({
          adminApi: this.isAdminHost,
          owner: new CompanyUsers(
            {
              owner: this.isAdminHost
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_id },
          ),
        }).fetchAll({
          personal: true,
          ...filters,
        });
        result.items = response.data.user_groups;
        result.meta = response.data.meta;
      } catch (error) {
        // TODO: Add exception case
      }
      return result;
    },

    getGroupRoute(item) {
      return {
        ...this.$route,
        params: {
          ...this.$route.params,
          tab: 'access_tree',
        },
        query: {
          ...this.$route.query,
          filters: JSON.stringify({ group_ids: [item.id] }),
        },
      };
    },

    openDeleteUserUserGroupsModal(group) {
      this.$refs.deleteUserUserGroupsModal.open({
        group,
        user: {
          id: this.user.user_id,
          name: this.user.name,
        },
      });
    },

    itemActions(item) {
      return [
        {
          icon: 'Trash',
          label: this.$t('actions.delete'),
          onClick: () => this.openDeleteUserUserGroupsModal(item),
          variant: 'danger',
          visible: !item.user,
        },
      ].filter((i) => i.visible ?? true);
    },

    refetchTable() {
      this.$refs.dataTable.fetchData();
    },
  },
};
</script>
