<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460"
  :allowClose="false"
)
  template(#title)
    div(v-html="$t('user_groups.confirm_delete', { name: entity.name })")
</template>

<script lang="ts">
import CompanyFiles from '@/api/models/CompanyFiles.js';
import { defineComponent } from 'vue';
import deleteModalMixin from '@/modals/mixins/delete-modal-mixin.js';

export default defineComponent({
  mixins: [deleteModalMixin],
  emits: ['done'],
  methods: {
    async submit() {
      try {
        this.inProgress = true;
        await new CompanyFiles(null, { id: this.entity.sequential_id }).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
});
</script>
