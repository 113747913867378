<template lang="pug">
UiModal(
  :title="$t('user_groups.modals.delete_user.title')"
  :subtitle="$t('user_groups.modals.delete_user.message', { user_name: user?.name, group_name: group?.name })"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="500"
  :allowClose="false"
)
</template>

<script>
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import Users from '@/api/models/Users.js';
import { UiToaster } from '@bi-book/bibook-ui-kit';
import { mapGetters } from 'vuex';

export default {
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      group: null,
      user: null,
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    ...mapGetters('company', ['company']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.remove'),
          onClick: this.submit,
          loading: this.inProgress,
          variant: 'smooth-danger',
          icon: 'Trash',
        },
      ];
    },
  },
  methods: {
    open({ group, user }) {
      this.group = group;
      this.user = user;
      this.inProgress = false;
      this.show = true;
    },
    async submit() {
      this.inProgress = true;
      try {
        await new Users(
          {
            adminApi: this.isAdminHost,
            owner: new UserGroups(
              {
                owner: this.isAdminHost
                  ? new Companies(null, { id: this.$route.params.company_id })
                  : null,
              },
              { id: this.group.id },
            ),
          },
          { id: this.user.id },
        ).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        UiToaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>
