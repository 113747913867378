<template lang="pug">
.analytics-layout
  template(v-if="ready")
    AnalyticsSidebar(
      v-if="navigationTree.groupsList.length || navigationTree.reportsList.length"
      ref="sidebar"
      :loading="!displaySidebar"
      :isOpen="isOpen"
      :isShowBookmarksPanel="isShowBookmarksPanel"
      :navigationTree="navigationTree"
      :currentReportId="currentReportId"
      :currentLinkedReportId="currentLinkedReportId"
      :currentPageIndex="currentPage.index"
      :currentBookmarkId="currentBookmarkId"
      :bookmarks="bookmarks"
      @openSidebar="openSidebar"
      @closeSidebar="closeSidebar"
      @toggle="toggle"
      @setReport="(reportId) => setReport(reportId)"
      @setLinkedReport="(linkedReportId) => setLinkedReport(linkedReportId)"
      @setPage="setPageByIndex"
      @closeBookmarks="isShowBookmarksPanel = false"
      @selectBookmark="selectBookmark"
      @createBookmark="createBookmark"
      @deleteBookmark="deleteBookmark"
    )
    .analytics-report(v-if="navigationTreeReportsExist")
      AnalyticsHeader(
        :report="report"
        :isDefaultReport="isDefaultReport"
        :reportLocale="reportLocale"
        :editMode="editMode"
        :editable="editable"
        :activeReportTheme="activeReportTheme"
        :isShowBookmarksPanel="isShowBookmarksPanel"
        :currentBookmark="currentBookmark"
        :currentBookmarkId="currentBookmarkId"
        :layoutType="layoutType"
        :recomendedLayoutType="recomendedLayoutType"
        :refreshDisabled="refreshDisabled"
        @toggle="toggle"
        @toggle-bookmarks="toggleBookmarks"
        @setDefaultReport="setDefaultReport"
        @switchReportLanguage="switchReportLanguage"
        @switchUserReportTheme="switchUserReportTheme"
        @editReport="editReport"
        @saveReport="saveReport"
        @exitEditMode="exitEditMode"
        @openThemeSwitcher="openThemeSwitcher"
        @toggleFullscreen="toggleFullscreen"
        @printReport="printReport"
        @openSetting="openSetting"
        @copyLink="copyLink"
        @copyPowerbiLink="copyPowerbiLink"
        @createBookmark="createBookmark"
        @deleteBookmark="deleteBookmark"
        @toggleLayoutType="toggleLayoutType"
        @refreshView="refreshView"
        @exportReport="exportReport"
      )
      .analytics-dashboard
        AnalyticsReportCapacityIssue(
          v-if="errors.capacity"
          :error="errors.capacity"
          :reportId="currentReportId"
          data-vi="analytics-report-capacity-issue"
        )
        PowerbiClient(
          v-else-if="report && report.embed_url && themesJsonLoaded && defaultPageInstalled"
          :key="report.linkedReportId ?? report.id"
          ref="report"
          :id="report.report_id"
          :embed-url="report.embed_url"
          :access-token="report.access_token"
          :expiration="report.expiration"
          :view-mode="editMode ? 'edit' : 'view'"
          :report-type="report.report_type"
          :locale="reportLocale || language"
          :layoutType="currentLayoutType"
          :defaultPage="defaultPage"
          @saved="onReportSaved"
          @error="onReportError"
          @rendered="onReportRendered"
          @loaded="onReportLoaded"
          @update-token="onUpdateToken"
          @get-pages="onGetPages"
          @page-changed="onPageChanged"
          @loadingStarted="onLoadingStarted"
          @renderingStarted="onRenderingStarted"
          @clicked="onReportClicked"
        )
        template(v-else)
          .empty-text
            AnalyticsReportCrashed(v-if="errors.access")
            UiStack(v-else)
              UiSpinner
              .mt-3 {{ $t('analytics.fetching_dashboard') }}
    template(v-if="!navigationTreeReportsExist && !inProgress")
      EmptyState(
        :title="$t('analytics.analytics')"
        :description="currentCompany.report_fetching ? $t('analytics.empty_fetching') : $t('analytics.empty')"
      )
        FileSearchingDuff
    OverlayLoader(
      fixed
      :show="inProgress"
      :label="$t('analytics.fetching_reports')"
    )
    ManageNavigation
  CreateBookmarkDialog(ref="createBookmarkDialog" @done="onBookmarkCreated")
  DeleteBookmarkDialog(ref="deleteBookmarkDialog" @done="onBookmarkDeleted")
  ExportReportModal(ref="exportReportModal" @done="onExportReportDone")
</template>

<script>
import ApiReportThemes from '@/api/models/ReportThemes';
import { mapGetters } from 'vuex';
import ApiUser from '@/api/user.js';
import LocalStorage from '@/common/localstorage.js';
import AnalyticsSidebar from '@/views/Analytics/components/Sidebar/Index.vue';
import Preview from '@/views/Analytics/ShowView.vue';
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';
import copy from 'copy-to-clipboard';
import * as pbi from 'powerbi-client';
import Analytics from '@/api/models/Analytics';
import Bookmarks from '@/api/models/Bookmarks';
import FileSearchingDuff from '@/assets/images/illustrations/file_searching_duff.vue';
import { generatePowerBiReportLink } from '@/common/generate-power-bi-report-link.js';
import { isMobileUserAgent } from '@/helper/user-agent';
import ChaskiqService from '@/common/chaskiq-service.js';
import ExportReportModal from '@/modals/Reports/export-report-modal.vue';
import CompanyUser from '@/api/models/CompanyUser';
import AnalyticsReportCapacityIssue from '@/views/Analytics/components/analytics-report-capacity-issue.vue';
import { PowerBIReportErrorsService } from '@/services/power-bi-report-errors-service.js';
import reportLogsReasonsList from '@/common/report-logs-reasons-list';
import throttle from 'lodash/throttle';
import ManageNavigation from './components/manage-navigation.vue';
import AnalyticsHeader from './components/Header.vue';
import CreateBookmarkDialog from './components/CreateBookmarkDialog.vue';
import DeleteBookmarkDialog from './components/DeleteBookmarkDialog.vue';
import AnalyticsReportCrashed from './components/analytics-report-crashed.vue';

const getPage = (index, page) => ({
  index: index ?? null,
  id: page?.name || null,
  name: page?.displayName || null,
});
const setPrevPage = (page) => ({
  name: page?.name || null,
  id: page?.id ?? null,
});

export default {
  name: 'AnalyticsIndex',

  components: {
    AnalyticsSidebar,
    Preview,
    MiniDropdown,
    AnalyticsHeader,
    CreateBookmarkDialog,
    DeleteBookmarkDialog,
    ExportReportModal,
    FileSearchingDuff,
    ManageNavigation,
    AnalyticsReportCapacityIssue,
    AnalyticsReportCrashed,
  },

  data() {
    return {
      ready: false,
      reportLoaded: false,
      isShowBookmarksPanel: false,
      bookmarks: [],
      currentBookmarkId: null,
      displaySidebar: false,
      displaySidebarTimer: null,
      inProgress: false,
      currentReportId: null,
      currentLinkedReportId: null,
      currentPage: getPage(),
      prevPage: setPrevPage(),
      groups: [],
      reports: [],
      report: null,
      errors: {
        access: false,
        capacity: false,
      },
      reportViewStartAt: null,
      reportPageViewStartAt: null,
      linkedReports: {},
      editMode: false,
      pages: [],
      linkedPageOptions: [],
      reportLocale: null,
      activeReportTheme: null,
      isOpen: true,
      reportThemesJson: {},
      themesJsonLoaded: false,
      layoutType: null,
      refreshDisabled: false,
      refreshTimeout: null,
      isMobileDevice: false,
      eventsTimeDiffs: {
        render: { time: null, reason: '' },
        load: { time: null, reason: '' },
      },
    };
  },

  mounted() {
    ChaskiqService.hideChasqik();
    this.fetchThemesJson();
    this.initReportLocale();
    this.fetchData();
  },

  created() {
    // TODO: refactor
    if (window.innerWidth < 1000) {
      this.isOpen = false;
    }

    this.handleMobileDevise();
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView', 'language']),
    ...mapGetters('company', ['currentCompany', 'currentCompanyId', 'reportThemes']),

    recomendedLayoutType() {
      return this.isMobileDevice ? pbi.models.LayoutType.MobilePortrait : null;
    },

    currentLayoutType() {
      if (this.editMode) {
        return null;
      }

      return this.layoutType ?? this.recomendedLayoutType;
    },

    currentBookmark() {
      const bookmark = this.bookmarks.find((b) => String(b?.id) === this.currentBookmarkId);
      return bookmark;
    },

    editable() {
      return (
        !!this.report &&
        this.report.editing === true &&
        !['link_to_page', 'link_to_pages', 'merged_report'].includes(this.report.report_type)
      );
    },

    isDefaultReport() {
      return !!this.report && this.report.is_default === true;
    },

    navigationTree() {
      const tree = {
        groups: {},
        reports: {},
        groupsList: [],
        reportsList: [],
      };
      this.groups.forEach((group) => {
        const { id, name, color, reports: groupReports } = group;
        const [groupReportsList, groupReportsItems] = this.normalizeTreeReports(groupReports, id);
        tree.groupsList.push(id);
        tree.groups[id] = { id, name, color, reportsList: groupReportsList };
        tree.reports = { ...tree.reports, ...groupReportsItems };
      });
      const [reportsList, reportsItems] = this.normalizeTreeReports(this.reports);

      tree.reportsList = reportsList;
      tree.reports = { ...tree.reports, ...reportsItems };

      // === Adding pages in tree for the current report
      const isCurrentTreeReportMerged = tree.reports[this.currentReportId].merged_report;
      const getPageFields = (page) => ({
        isActive: page.isActive,
        name: page.name,
        displayName: page.displayName,
        visibility: page.visibility,
      });
      if (
        isCurrentTreeReportMerged &&
        tree.reports[`${this.currentReportId}|${this.currentLinkedReportId}`]
      ) {
        tree.reports[`${this.currentReportId}|${this.currentLinkedReportId}`].pages = [
          ...this.pages.map(getPageFields),
        ];
      } else if (!isCurrentTreeReportMerged) {
        tree.reports[this.currentReportId].pages = [...this.pages.map(getPageFields)];
      }
      // ===

      return tree;
    },
    navigationTreeReportsExist() {
      return Object.keys(this.navigationTree.reports).length > 0;
    },
    defaultPage() {
      return this.report?.pages[0];
    },
    defaultPageInstalled() {
      if (this.report?.report_type === 'standard_report') return true;
      return this.defaultPage;
    },
  },

  watch: {
    currentReportId() {
      this.exitEditMode();
    },
    currentLinkedReportId(val) {
      this.changeLinkedReport(val);
    },
    $route() {
      this.initView();
    },
  },

  methods: {
    normalizeTreeReports(reports, groupId = null) {
      let items = {};
      let list = [];
      reports.forEach((report) => {
        const { id, merged_report, linked_reports } = report;
        let reportData = { ...report, groupId, pages: [] };

        if (merged_report) {
          let linkedReportsList = [];
          linked_reports.forEach((linked_report) => {
            const { id: linked_report_id } = linked_report;
            const lr_id = `${id}|${linked_report_id}`;
            items[lr_id] = { ...linked_report, linkedReportKey: lr_id, pages: [] };
            linkedReportsList.push(lr_id);
          });
          reportData = { ...reportData, linkedReportsList };
        }
        items[id] = reportData;
        list.push(id);
      });

      return [list, items];
    },
    toggleBookmarks() {
      if (!this.isShowBookmarksPanel) {
        this.isOpen = true;
      }
      this.isShowBookmarksPanel = !this.isShowBookmarksPanel;
    },
    onBookmarkCreated(bookmark) {
      this.bookmarks.push(bookmark);
    },
    onBookmarkDeleted(id) {
      this.currentBookmarkId = null;
      const index = this.bookmarks.findIndex((b) => b.id == id);
      this.bookmarks.splice(index, 1);
    },
    selectBookmark(id) {
      this.$router.push({
        name: 'AnalyticsBookmark',
        params: { id },
      });
    },
    deleteBookmark(id) {
      const bookmark = this.bookmarks.find((b) => String(b.id) === String(id));
      this.$refs.deleteBookmarkDialog.open(bookmark);
    },

    captureReportState() {
      return this.$refs.report.report.bookmarksManager.capture({
        personalizeVisuals: true,
        allPages: true,
      });
    },

    createBookmark() {
      this.$refs.createBookmarkDialog.open(
        {
          reportId: this.currentReportId,
          linkedReportId:
            this.report.report_type === 'merged_report' ? this.currentLinkedReportId : null,
        },
        this.captureReportState,
      );
    },
    clearDisplaySidebarTimer() {
      clearTimeout(this.displaySidebarTimer);
      this.displaySidebarTimer = null;
    },
    setDisplaySidebarTimer() {
      this.clearDisplaySidebarTimer();
      this.displaySidebarTimer = setTimeout(
        () => {
          this.displaySidebar = true;
        },
        this.mobileView ? 1 : 5000,
      );
    },

    refreshReport() {
      this.setReportTheme();
      this.$refs.report?.refresh();
    },

    toggleFullscreen() {
      this.$refs.report.fullscreen();
    },

    printReport() {
      this.$refs.report.print();
    },

    editReport() {
      this.editMode = true;
      new Analytics(null, { id: this.report.id }).trackReportEdit('Started').catch((error) => {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: error.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      });
      this.fetchReport();
    },

    saveReport() {
      this.$refs.report.save();
    },

    async logReportRendered(interrupted) {
      try {
        const { time, reason } = this.getTrackedTime('render');
        this.clearTimeTrack('render');
        if (!reason) {
          /* To not log every re-render i.e click on a report or open select etc.
          Logging only when we explicitly started tracking the rendering */
          return;
        }

        const pageData = interrupted ? this.prevPage : this.currentPage;
        await new Analytics(null, { id: this.report.id }).trackRendered({
          ...pageData,
          time: time / 1000,
          reason,
          interrupted,
        });
      } catch (trackError) {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: trackError.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      }
    },
    async logReportLoaded(interrupted) {
      try {
        const { time, reason } = this.getTrackedTime('load');
        this.clearTimeTrack('load');
        this.clearTimeTrack('render');
        this.reportLoaded = true;
        if (!reason) return;
        await new Analytics(null, { id: this.report.id }).trackLoaded({
          time: time / 1000,
          reason,
          interrupted,
        });
      } catch (trackError) {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: trackError.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      }
    },
    onReportRendered() {
      if (!this.reportLoaded) return;

      if (
        [reportLogsReasonsList.PAGE_CHANGED, reportLogsReasonsList.LOADED].includes(
          this.getTrackedTime('render').reason,
        )
      ) {
        this.startViewPage();
      }
      this.logReportRendered();
    },

    onReportLoaded() {
      this.logReportLoaded();
    },

    setCurrentPage(index, page) {
      this.prevPage = setPrevPage(this.currentPage);
      this.currentPage = getPage(index, page);
    },

    async onPageChanged(page) {
      if (!page || !this.reportLoaded) {
        return;
      }

      const currentPageIndex = this.pages.findIndex(
        (p) => p.displayName === page.displayName && p.name === page.name,
      );
      this.setCurrentPage(currentPageIndex, page);
      this.finishViewPage(this.prevPage);
      try {
        await new Analytics(null, { id: this.report.id }).trackPageChanged(this.currentPage);
      } catch (trackError) {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: trackError.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      }
    },

    onLoadingStarted(reason) {
      this.reportLoaded = false;
      this.startTimeTrack('load', reason);
    },
    onRenderingStarted(reason) {
      if (!this.reportLoaded) {
        this.clearTimeTrack('render');
        return;
      }
      this.startTimeTrack('render', reason);
    },

    /**
     * @param {('render' | 'load')} key
     */
    startTimeTrack(key, reason) {
      if (this.eventsTimeDiffs[key].time) {
        if (key === 'load') {
          this.logReportLoaded(true);
        } else if (key === 'render') {
          this.logReportRendered(true);
        }
      }
      this.eventsTimeDiffs[key].time = performance.now();
      this.eventsTimeDiffs[key].reason = reason;
    },
    /**
     * @param {('render' | 'load')} key
     */
    clearTimeTrack(key) {
      this.eventsTimeDiffs[key].time = null;
      this.eventsTimeDiffs[key].reason = '';
    },
    /**
     * @param {('render' | 'load')} key
     */
    getTrackedTime(key) {
      return {
        time: performance.now() - this.eventsTimeDiffs[key].time,
        reason: this.eventsTimeDiffs[key].reason,
      };
    },

    changeCurrentReportId(reportId) {
      this.updateRoute(reportId);
    },

    changeLinkedReport(val) {
      if (val < 0) return;
      const linkedReport = this.linkedReports[`report|${val}`];
      if (!linkedReport) return;
      this.pages = [];
      this.report = linkedReport;
    },

    exitEditMode() {
      this.editMode = false;
    },

    onReportSaved() {
      this.editMode = false;
      new Analytics(null, { id: this.report.id }).trackReportEdit('Done').catch((error) => {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: error.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      });
    },

    onUpdateToken() {
      const mode = this.editMode ? 'edit' : 'view';
      new Analytics(null, { id: this.currentReportId })
        .fetch({ mode })
        .then((resp) => {
          const report = resp.data.power_bi_report;
          this.setAccessToken(report.access_token, report.expiration);
        })
        .catch((_err) => {});
    },

    onGetPages(pages) {
      pages = pages || [];
      let validPages = pages.filter((p) => !p.visibility);
      if (!validPages.length) {
        validPages = pages;
      }
      if (['link_to_page', 'link_to_pages', 'merged_report'].includes(this.report.report_type)) {
        const filteredPages = this.report.pages
          .map((sp) => validPages.find((p) => sp === p.name || sp === p.displayName))
          .filter((p) => p !== undefined);

        this.pages = filteredPages;
      } else {
        this.pages = validPages;
      }
      this.$refs.sidebar.scrollToActive();
      let activePageIndex = this.pages.findIndex((p) => p.isActive);
      activePageIndex = activePageIndex >= 0 ? activePageIndex : 0;
      this.setCurrentPage(activePageIndex, this.pages[activePageIndex]);

      if (!this.pages[this.currentPage.index]?.isActive) {
        // to set page if it's not active
        this.setPageByIndex(this.currentPage.index);
      }
      if (this.currentBookmarkId) {
        this.applyBookmark(this.currentBookmarkId);
      }
    },

    setAccessToken(token, expiration) {
      this.$refs.report.setAccessToken(token, expiration);
    },

    setReportTheme() {
      let selectedTheme;
      const reportsThemeUUID = LocalStorage.get('activeReportThemeId', null);
      if (reportsThemeUUID) {
        selectedTheme = this.reportThemes.find((t) => t.id === reportsThemeUUID);
      }
      this.activeReportTheme = selectedTheme?.id || null;

      this.switchUserReportTheme(this.activeReportTheme);
    },

    initReportLocale() {
      if (this.currentCompany.report_language_visible) {
        this.reportLocale =
          this.currentCompany.user_report_language || this.currentCompany.report_language;
      }
    },

    switchReportLanguage(locale) {
      new CompanyUser().update({ report_language: locale }).then(() => {
        this.$store.dispatch('company/restoreCompany', { user_report_language: locale });
        this.reportLocale = locale;
        this.refreshReport();
      });
    },

    switchUserReportTheme(themeId) {
      const selectedTheme = this.reportThemes.find((t) => t.id === themeId);
      if (!selectedTheme) return;
      this.activeReportTheme = themeId;
      LocalStorage.set('activeReportThemeId', this.activeReportTheme);
      if (this.$refs.report) {
        this.$refs.report.applyTheme(this.reportThemesJson[this.activeReportTheme]);
      }
    },

    fetchReport() {
      this.finishViewReport();
      this.clearDisplaySidebarTimer();
      this.displaySidebar = false;
      this.setDisplaySidebarTimer();
      this.setReportTheme();
      this.report = null;
      this.errors.access = false;
      this.reportLoaded = false;
      this.errors.capacity = false;
      this.pages = [];
      this.linkedReports = {};
      const mode = this.editMode ? 'edit' : 'view';
      new Analytics(null, { id: this.currentReportId })
        .fetch({ mode })
        .then((resp) => {
          let reportData = resp.data.power_bi_report;
          if (!reportData.access_token) {
            this.errors.access = true;
            this.$refs.sidebar.accessError();
            this.clearDisplaySidebarTimer();
            this.displaySidebar = true;
            return;
          }

          if (
            !this.reports.find((r) => r.id === reportData.id) &&
            !this.groups.some((g) => g.reports.find((r) => r.id === reportData.id))
          ) {
            // add to the  bottom sidebar the current report if it was not fetched with the tree
            this.reports.push(reportData);
          }

          if (reportData.merged_report) {
            const { linked_reports, ...report } = reportData;

            if (!linked_reports.length) return;
            let linkedReportIds = [];
            linked_reports.forEach((linkedReport) => {
              const {
                linked_report_id: linkedReportId,
                pages,
                name,
                access_token,
                embed_url,
                report_id,
                expiration,
              } = linkedReport;
              this.linkedReports[`report|${linkedReportId}`] = {
                ...report,
                linkedReportId,
                pages,
                name,
                access_token,
                embed_url,
                report_id,
                expiration,
              };
              linkedReportIds.push(linkedReportId);
            });

            if (
              !this.currentLinkedReportId ||
              !linkedReportIds.includes(this.currentLinkedReportId)
            ) {
              this.currentLinkedReportId = linkedReportIds[0];
            } else {
              this.changeLinkedReport(this.currentLinkedReportId);
            }
          } else {
            this.report = reportData;
            this.startViewReport();
          }

          window.selected_company_id = null;
          window.selected_report_id = null;
        })
        .catch(() => {
          this.$toaster.add({
            type: 'error',
            message: this.$t('analytics.report_not_found'),
            timeout: null,
          });

          this.$router.replace({ name: 'Analytics' });
        });
    },

    setDefaultReport() {
      ApiUser.setDefaultReport(this.currentReportId).then((_resp) => {
        if (this.report) this.report.is_default = true;
        this.changeCurrentReportId(this.currentReportId);
      });
    },

    toggle() {
      if (this.isOpen) {
        this.isShowBookmarksPanel = false;
      }
      this.isOpen = !this.isOpen;
    },

    async fetchThemesJson() {
      const reportThemes = this.reportThemes.filter((theme) => theme.id);
      const themesRequests = reportThemes.map(async (theme) => {
        try {
          const response = await new ApiReportThemes(null, { id: theme.id }).fetch();
          this.reportThemesJson[theme.id] = response.data;
          return response;
        } catch (error) {
          return error;
        }
      });
      await Promise.allSettled(themesRequests);
      this.themesJsonLoaded = true;
    },

    async fetchData() {
      await new Analytics().fetchTree().then((res) => {
        const { reports, report_groups } = res.data;
        this.reports = reports;
        this.groups = report_groups;
      });

      await new Bookmarks().fetchAll().then((response) => {
        this.bookmarks = response.data.bookmarks || response.data || [];
      });

      this.ready = true;
      this.initView();
    },

    initView() {
      if (this.$route.name === 'AnalyticsBookmark') {
        this.initBookmark();
      } else if (this.$route.name === 'AnalyticsReport') {
        this.initReport();
      }
    },

    initReport() {
      const reportId = this.$route.params.id;
      if (reportId && this.currentReportId !== reportId) {
        this.currentReportId = reportId;
        this.fetchReport();
      }
    },

    initBookmark() {
      const bookmarkId = this.$route.params.id;
      const bookmark = this.bookmarks.find((b) => b.id == bookmarkId);
      if (bookmark && this.currentBookmarkId != bookmarkId) {
        this.currentBookmarkId = bookmarkId;
        if (
          this.currentReportId != bookmark.power_bi_report_id ||
          this.currentLinkedReportId != bookmark.linked_report_id
        ) {
          this.currentReportId = bookmark.power_bi_report_id;
          this.currentLinkedReportId = bookmark.linked_report_id;
          this.fetchReport();
        } else {
          this.applyBookmark(bookmarkId);
        }
      }
    },

    applyBookmark(id) {
      const bookmark = this.bookmarks.find((b) => b.id == id);
      this.$refs.report.report.bookmarksManager.applyState(bookmark.base64_state);
      // todo: applyBookmarkState this.$refs.report.applyBookmarkState(bookmark.base64_state);
    },

    setReport(reportId) {
      this.currentBookmarkId = null;
      this.updateRoute(reportId);
      if (this.report?.id === reportId) return;
      this.clearDisplaySidebarTimer();
      this.displaySidebar = false;
      this.setDisplaySidebarTimer();
      this.changeCurrentReportId(reportId);
    },

    updateRoute(reportId) {
      this.$router.replace({ name: 'AnalyticsReport', params: { id: reportId } });
    },

    setLinkedReport(val) {
      const [reportId, linkedReportId] = val.toString().split('|');
      this.currentLinkedReportId = linkedReportId;

      if (this.report?.id === reportId) return;
      this.changeCurrentReportId(reportId);
    },

    setPageByIndex(index) {
      if (index < 0) return;
      const page = this.pages[index];
      if (!page) return;
      this.$refs.report.changePage(page);
    },

    openSidebar() {
      if (!this.dynamic) return;
      this.isOpen = true;
    },

    closeSidebar() {
      if (!this.dynamic) return;
      this.isOpen = false;
    },

    copyLink() {
      let reportUrl = `${window.location.origin}/analytics/report/${this.currentReportId}`;
      const result = copy(reportUrl);
      if (!result) return;

      this.$toaster.add({
        message: this.$t('company.reports.copied'),
        timeout: 5000,
      });
    },

    copyPowerbiLink() {
      const reportUrl = generatePowerBiReportLink(
        this.report.report_group_id_pbi,
        this.report.report_id_pbi,
      );
      const result = copy(reportUrl);
      if (!result) return;

      this.$toaster.add({
        message: this.$t('company.reports.copied'),
        timeout: 5000,
      });
    },

    openSetting() {
      this.showSetting = true;
    },

    closeSetting() {
      this.showSetting = false;
    },

    startViewReport() {
      if (!this.report) return;
      this.reportViewStartAt = new Date();
    },

    startViewPage() {
      this.reportPageViewStartAt = performance.now();
    },

    finishViewReport() {
      if (!this.report || !this.reportViewStartAt) return;
      this.finishViewPage(this.currentPage);
      const endViewAt = new Date();
      const viewSeconds = (endViewAt.getTime() - this.reportViewStartAt.getTime()) / 1000;
      new Analytics(null, { id: this.report.id }).trackView(viewSeconds).then(() => {
        this.reportViewStartAt = null;
      });
    },

    async finishViewPage(page) {
      if (!this.reportPageViewStartAt) return;
      const viewPageSeconds = (performance.now() - this.reportPageViewStartAt) / 1000;
      await new Analytics(null, { id: this.report.id }).trackPageView({
        page_id: page.id,
        page_name: page.name,
        time: viewPageSeconds,
      });
      this.reportPageViewStartAt = null;
    },

    toggleLayoutType() {
      this.layoutType = this.layoutType === null ? pbi.models.LayoutType.MobilePortrait : null;
    },

    clearRefreshTimeout() {
      this.refreshDisabled = false;
      if (this.refreshTimeout !== null) {
        clearTimeout(this.refreshTimeout);
      }
    },

    startRefreshTimeout() {
      this.clearRefreshTimeout();
      this.refreshDisabled = true;
      this.refreshTimeout = setTimeout(() => {
        this.refreshDisabled = false;
      }, 15000);
    },

    async refreshView() {
      this.startRefreshTimeout();
      try {
        await this.$refs.report.report.refresh();
        this.$toaster.add({
          message: this.$t('analytics.report_refreshed'),
        });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: error.detailedMessage,
        });
      }
    },

    handleMobileDevise() {
      if (isMobileUserAgent()) {
        this.isMobileDevice = true;
      }
    },

    exportReport() {
      this.$refs.exportReportModal.open(
        {
          reportId: this.currentReportId,
          linkedReportId:
            this.report.report_type === 'merged_report' ? this.currentLinkedReportId : null,
          pages: this.pages,
          currentPage: this.pages[this.currentPage.index],
        },
        this.captureReportState,
      );
    },

    onExportReportDone({ exportReportId }) {
      this.$store.dispatch('reportExport/run', { exportReportId });
    },

    onReportClicked: throttle(
      // eslint-disable-next-line func-names
      function () {
        new Analytics(null, { id: this.report.id }).clickEvent();
      },
      500,
      { trailing: false },
    ),

    onReportError(error) {
      new Analytics(null, { id: this.report.id }).trackFailed(error.detail).catch((trackError) => {
        this.$toaster.add({
          type: 'error',
          title: 'Logs error',
          message: trackError.response?.data?.error || this.$t('errors.something_went_wrong'),
        });
      });

      if (PowerBIReportErrorsService.isCapacityRelatedError(error)) {
        this.errors.capacity = error;
      }
    },

    onCapacityIssueFixed() {
      window.location.reload();
    },
  },

  beforeUnmount() {
    ChaskiqService.showChasqik();
    this.finishViewReport();
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/mixins';
@import '@/assets/stylesheet/variables';

.analytics-layout {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  align-items: stretch;
}

.h40 {
  height: 40px;
}

.analytics-report {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 0.5rem 1rem;
  }
}

.analytics-dashboard {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  width: 100%;
  background: var(--bb-app-bg-main);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.2);
  font-size: 20px;

  & .empty-text {
    position: absolute;
    text-align: center;
    .error-text {
      color: var(--bb-danger);
    }
  }

  & .powerbi-client {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .ml20 {
    margin-left: 20px;
  }

  .ui-empty-state {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
    max-width: 900px;
    white-space: pre-wrap;

    &__body {
      line-height: inherit;
    }
  }
}

.analytics-pin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bb-primary);
  color: white;
  height: 40px;
  width: 40px;
  border: 1px solid var(--bb-primary);
  border-radius: 30px;
  outline: none;
  padding: 0;
}

@include media-breakpoint-down(sm) {
  .analytics-dashboard {
    min-height: 60vh;
  }
}

@media screen and (max-width: 1300px) {
  .analytics-pin-button {
    height: 40px;
    width: 40px;

    & > img {
      width: 20px;
    }
  }

  .default-report-button {
    margin: 0 10px;
  }
}

@media screen and (max-width: 1000px) {
  .analytics-report__header {
    justify-content: space-between;
    text-align: center;
  }

  .analytics-report__layout {
    background-color: var(--bb-app-bg-main);
  }

  .analytics-dropdown-setting {
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .default-report-button {
    margin: 0;
    padding: 0 5px;
  }
}
</style>
