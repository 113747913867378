/**
 * Usage
 *
 * Create client instance:
 * const ApiTrackers = new RestClient('/api/v1/trackers/', 'tracker', 'trackers');
 *
 * Update existed record:
 * const existTracker = { id: 1, event: 'login' }; // with `id` param
 * existTracker.event = 'logout'
 * ApiTrackers.save(existTracker).then(response => {
 *  console.log(response.data.tracker) #=> { id: 1, event: 'logout'}
 * });
 *
 * Create new record:
 * const newTracker = { event: 'login' }; // without `id` param
 * ApiTrackers.save(newTracker).then(response => {
 *  console.log(response.data.tracker) #=> { id: 2, event: 'login'}
 * });
 *
 * Fetch single by id:
 * ApiTrackers.fetch(2).then(response => {
 *  console.log(response.data.tracker) #=> { id: 2, event: 'login' }
 * });
 *
 * Fetch list:
 * ApiTrackers.fetchAll().then(response => {
 *  console.log(response.data.trackers) #=> [{ id: 1, event: 'logout' }, { id: 2, event: 'login' }, ... ]
 * });
 */

import axios from 'axios';

const POST = 'post';
const PATCH = 'patch';

class RestClient {
  /**
   * Generate new Rest API client
   * @param {String} url
   * @param {String} prular
   * @param {String} singular
   */
  constructor(url, prular = null, singular = null) {
    this._url = url;
    this._prular = prular;
    this._singular = singular;
  }

  get url() {
    return this._url.replace(/\/$/g, '');
  }

  fetchAll(params, config = {}) {
    return axios.get(this.url, {
      params,
      ...config,
    });
  }

  fetch(id, params = {}, config = {}) {
    return axios.get(`${this.url}/${id}`, {
      params,
      ...config,
    });
  }

  save(data = {}, params = {}, config = {}) {
    const isUpdate = !!data.id;
    return axios({
      method: isUpdate ? PATCH : POST,
      url: isUpdate ? `${this.url}/${data.id}` : this.url,
      data: {
        [this._singular]: data,
      },
      params,
      ...config,
    });
  }

  destroy(id, config = {}) {
    return axios.delete(`${this.url}/${id}`, config);
  }
}

export default RestClient;
