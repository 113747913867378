export const MAIN_REPORT_COLORS = Object.freeze({
  shape: '#284440',
  slicer: '#ADCFCA',
  header: '#284440',
});

export const TEXT_AND_NOTIFICATION_COLORS = Object.freeze({
  headerFontColor: '#f6e6e8',
  bodyFontColor: '#000000',
  neutral: '#adc2ce',
  good: '#548f87',
  bad: '#985B84',
});

export const DATA_REPORT_COLORS = Object.freeze([
  '#548f87',
  '#355954',
  '#6DA9A0',
  '#284440',
  '#416F68',
  '#CDE2DF',
  '#8DBCB5',
  '#ADCFCA',
]);
