<template lang="pug">
modal(
  :show="show"
  :title="$t('administrators.modal.title')"
  :close="close"
  :width="'410px'"
)
  template(#body)
    Stack(
      vertical
      align="stretch"
      spacing="lg"
    )
      StackItem
        UiFormInput(
          v-model="form.name"
          :label="$t('administrators.modal.name_label')"
          :placeholder="$t('administrators.modal.name_placeholder')"
          :vuelidateModel="v$.form.name"
        )
      StackItem
        UiInputLabel {{ $t('administrators.modal.email_label') }}
        UiInput(
          v-model="form.email"
          :placeholder="$t('administrators.modal.email_placeholder')"
          :error="v$.form.email.$error"
          autocomplete="email"
          @blur="v$.form.email.$touch"
        )
        UiInputFeedback(v-for="$error in v$.form.email.$errors" error) {{ $error.$message }}
      StackItem
        UiCheckbox(
          v-model="form.user_role"
          checked-value="global_admin"
          unchecked-value="service_admin"
        ) {{ $t(`administrators.modal.global_admin`) }}
      StackItem(v-if="errorText")
        UiAlert(variant="error" @close="errorText = null") {{ errorText }}
  template(#footer)
    Stack
      StackItem
        UiButton(
          variant="outline-primary"
          :disabled="inProgress"
          @click="close"
        ) {{ $t('cancel') }}
      StackItem
        UiButton(
          :loading="inProgress"
          :disabled="inProgress"
          @click="save"
        ) {{ $t('apply') }}
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { strictLettersAndSpaces, required, email } from '@/common/validators.js';
import Administrators from '@/api/models/Administrators.js';

function getEmptyForm() {
  return {
    email: '',
    name: '',
    user_role: 'service_admin',
  };
}

export default {
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      show: false,
      inProgress: false,
      form: getEmptyForm(),
      errorText: null,
    };
  },

  validations() {
    return {
      form: {
        name: { required, strictLettersAndSpaces },
        email: { required, email },
      },
    };
  },

  methods: {
    open() {
      this.resetForm();
      this.show = true;
    },

    resetForm() {
      this.v$.$reset();
      this.errorText = null;
      this.form = { ...this.form, ...getEmptyForm() };
    },

    close() {
      this.$emit('close');
      this.show = false;
    },

    async save() {
      const valid = await this.v$.$validate();
      if (!valid) return;
      this.inProgress = true;
      new Administrators({ singularKey: 'user' })
        .create(this.form)
        .then((resp) => {
          this.inProgress = false;
          this.show = false;
          this.$emit('done');
        })
        .catch((error) => {
          if (error.response?.data?.error) {
            this.errorText = this.$localizeErrorMessage(error);
          }
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.err-msg {
  color: #ed7575;
}
</style>
