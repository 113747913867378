<template lang="pug">
UiBadge(:variant="statusVariant") {{ statusLabel }}
</template>

<script>
const StatusLabel = {
  activated: 'integration_activity.status.activated',
  not_activated: 'integration_activity.status.not_activated',
};

const StatusBadgeVariant = {
  activated: 'primary',
  not_activated: 'error',
};

export default {
  props: {
    status: {
      type: String,
      default: 'not_activated',
    },
  },
  computed: {
    statusLabel() {
      return this.$t(StatusLabel[this.status]);
    },
    statusVariant() {
      return StatusBadgeVariant[this.status];
    },
  },
};
</script>

<style scoped lang="scss"></style>
