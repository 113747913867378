<template lang="pug">
UiModal(
  title="Folder"
  :show="show"
  :rightActions="rightActions"
  @close="close"
)
  UiFormInput(
    v-model="form.name"
    :label="$t('files.folder_name')"
    :disabled="inProgress || locked"
    :vuelidateModel="v$.form.name"
  )
  UiFormMultiselect(
    v-if="isReplaceMode"
    v-model="parentFolderId"
    :label="$t('files.folder')"
    :options="folders"
    labelKey="name"
  )
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import Folders from '@/api/models/Folders';
import { mapGetters } from 'vuex';

function getForm() {
  return {
    name: '',
  };
}

export default {
  emits: ['done'],

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      show: false,
      isReplaceMode: false,
      inProgress: false,
      errorMessage: '',
      showErrorMessage: false,
      form: getForm(),
      edit: false,
      locked: false,
      folders: null,
      parentFolderId: null,
    };
  },

  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),

    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          onClick: this.save,
          loading: this.inProgress,
          disabled: this.inProgress || this.isViewAsOtherUser,
        },
      ];
    },
  },

  methods: {
    open({ entity, replace, parentFolderId } = {}) {
      this.isReplaceMode = replace;
      this.parentFolderId = parentFolderId;
      this.form = getForm();
      if (entity) {
        this.form.name = entity.name;
        this.form.id = entity.id;
        this.edit = true;
        this.locked = entity.data.locked;
        this.fetchFolders();
      }
      this.v$.$reset();
      this.show = true;
    },

    close() {
      this.show = false;
      this.form = getForm();
      this.v$.$reset();
    },

    save() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;

        this.inProgress = true;
        let params = this.form;

        this.request(params)
          .then(() => {
            this.inProgress = false;
            this.$emit('done');
            this.close();
          })
          .catch((e) => {
            this.inProgress = false;
            this.errorMessage = e;
            this.showErrorMessage = true;
          });
        this.show = false;
        this.edit = false;
      });
    },

    request(params) {
      let requestParams = params;
      if (this.parentFolderId) {
        requestParams = { ...params, parent_folder_id: this.parentFolderId };
      }
      if (this.edit || this.isReplaceMode) {
        return new Folders(null, { id: requestParams.id }).update(requestParams);
      } else {
        return new Folders().create(requestParams);
      }
    },

    fetchFolders() {
      this.inProgress = true;

      new Folders()
        .fetchAll()
        .then((resp) => {
          const foldersList = resp.data.folders.filter(
            (folder) => folder.is_editable && !folder['locked?'],
          );
          this.folders = foldersList;
          const currentFolder = this.parentFolderId
            ? foldersList.filter((f) => f.id === this.parentFolderId)[0]
            : null;
          this.parentFolderId = currentFolder?.id;
          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>
