<template lang="pug">
UiCard
  UiCardSection(padding="lg")
    UiStack(
      vertical
      spacing="3xl"
      align="stretch"
    )
      UiStack(
        vertical
        spacing="sm"
        align="stretch"
      )
        UiText(variant="headingMd") {{ $t('api_keys.company_credentials') }}
        UiDataTable(
          size="md"
          :headers="companyCredentialsHeaders"
          :items="company.company_credentials"
          :itemPerPage="false"
        )
          template(#cell:token="{ item }")
            TokenField(:value="item.token" data-test-id="api-keys-admin-token-field")
          template(#cell:refresh_token="{ item }")
            TokenField(
              :value="item.refresh_token"
              data-test-id="api-keys-admin-refresh-token-field"
            )
      UiStack(
        vertical
        spacing="sm"
        align="stretch"
      )
        UiText(variant="headingMd") {{ $t('api_keys.integrations_credentials') }}
        UiDataTable(
          size="md"
          :headers="integrationCredentialsHeaders"
          :items="company.integrations_credentials"
          :itemPerPage="false"
        )
          template(#cell:token="{ item }")
            TokenField(:value="item.token" data-test-id="api-keys-admin-integration-token-field")
</template>

<script>
import { mapGetters } from 'vuex';
import TokenField from './components/TokenField.vue';

export default {
  components: {
    TokenField,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('company', ['company']),
    companyCredentialsHeaders() {
      return [
        {
          key: 'access_type',
          label: this.$t('edit_company.keys.type'),
          squeeze: true,
        },
        {
          key: 'access_role',
          label: this.$t('edit_company.keys.role'),
          squeeze: true,
        },
        {
          key: 'username',
          label: this.$t('edit_company.keys.username'),
          squeeze: true,
        },
        {
          key: 'token',
          label: this.$t('edit_company.keys.token'),
          squeeze: true,
        },
        {
          key: 'refresh_token',
          label: this.$t('edit_company.keys.refresh_token'),
          squeeze: true,
        },
      ];
    },
    integrationCredentialsHeaders() {
      return [
        {
          key: 'title',
          label: this.$t('integrations.software'),
          squeeze: true,
        },
        {
          key: 'token',
          label: this.$t('edit_company.keys.token'),
          squeeze: true,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.tab-container {
  padding: 15px;
}
</style>
