<template lang="pug">
UiCardSection.integration-list-item(
  padding="lg"
  :class="classes"
  data-test-id="card"
)
  UiStack(align="center" spacing="2xl")
    UiStackItem
      .integration-list-item__drag
        UiIcon(name="Drag" data-test-id="drag-icon")
    UiStackItem
      UiStack(align="center")
        template(v-if="integration?.logo")
          img.integration-list-item__logo(:src="integration?.logo" data-test-id="logo")
          .integration-list-item__line(data-test-id="line")
        UiText.integration-list-item__title(data-test-id="title") {{ integration?.title }}
</template>

<script>
export default {
  name: 'IntegrationListItem',
  props: {
    integration: {
      type: Object,
      default: () => {},
    },
    isDragging: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'integration-list-item--active': this.isDragging,
        'integration-list-item--disabled': !this.isDraggable,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.integration-list-item {
  &__title {
    font-size: 18px;
  }

  &__drag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    cursor: grab;
    color: var(--bb-gray-600);
  }

  &__line {
    height: 34px;
    width: 1px;
    background-color: var(--bb-gray-800);
  }

  &__logo {
    height: 30px;
    width: 105px;
    object-fit: contain;
  }

  &--active {
    background-color: var(--bb-list-active-bg);
    border: 1px solid var(--bb-list-active-border);
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
