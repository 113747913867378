<template lang="pug">
.ui-multiselect-tag(
  :class="classes"
  tabindex="-1"
  @keyup.enter="$emit('remove', $event)"
)
  .ui-multiselect-tag__label
    slot
  span.ui-multiselect-tag-remove(v-if="!disabled" @click.stop="$emit('remove', $event)")
    span.ui-multiselect-tag-remove-icon
</template>

<script>
export default {
  emits: ['remove'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'is-disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables.scss';

.ui-multiselect-tag {
}
</style>
