<template lang="pug">
.login-banner-card
  .login-banner-card__wrapper
    UiCardSection(padding="xl")
      .login-banner-card__top-actions
        .login-banner-card__title {{ banner.title }}
        UiStack(spacing="xs")
          UiTooltip(:title="$t('actions.edit')" variant="dark")
            template(#activator)
              UiButton(
                size="xs"
                variant="ghost-secondary"
                icon="PencilEdit"
                round
                block
                data-test-id="action-edit-button"
                @click="$emit('toggleEdit')"
              )
          UiTooltip(:title="$t('actions.delete')" variant="dark")
            template(#activator)
              UiButton(
                size="xs"
                variant="ghost-danger"
                icon="Trash"
                round
                block
                data-test-id="action-delete-button"
                @click="$emit('delete')"
              )
      .login-banner-card__description(v-html="banner.description")
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.login-banner-card {
  &__wrapper {
    background-color: var(--bb-gray-950);
  }

  &__top-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__actions {
    margin-top: 20px;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
  }

  &__type {
    display: flex;
    margin-bottom: 20px;
  }

  &__delete-banner {
    padding: 9px 18px;
    border-radius: 10px;
    color: rgb(var(--bb-danger-rgb));
    cursor: pointer;

    &:hover {
      background-color: rgba(var(--bb-danger-rgb), 0.2);
    }
  }

  &__description p {
    margin: 0;
  }
}
.login-banner-card__edit:hover {
  background-color: rgba(var(--bb-primary-rgb), 0.2);
  color: var(--bb-primary);
}

.login-banner-card__trash:hover {
  background-color: rgba(var(--bb-danger-rgb), 0.2);
  color: var(--bb-danger);
}
</style>
