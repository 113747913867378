<template lang="pug">
.ui-form-field.ui-form-field--multiselect
  UiInputLabel(v-if="label || $slots.label" data-vi="ui-label")
    slot(name="label")
      | {{ label }}
  UiMultiselect(
    v-bind="$attrs"
    :error="error || vuelidateModel?.$error"
    :placeholder="placeholder"
    :label="labelKey"
    data-vi="ui-multiselect"
    @blur="vuelidateModel?.$touch"
  )
    template(v-for="(_, slot) of $slots" v-slot:[slot]="scope")
      slot(v-bind="scope" :name="slot")
  UiInputFeedback(
    v-if="error && error !== true"
    error
    data-vi="ui-input-feedback"
  ) {{ error }}
  template(v-if="vuelidateModel?.$errors")
    UiInputFeedback(
      v-for="$error in vuelidateModel.$errors"
      error
      data-vi="ui-input-feedback"
    ) {{ $t($error.$message) }}
</template>

<script>
import { i18n } from '@/localization/index.js';

export default {
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: () => i18n.global.t('select_option'),
    },
    labelKey: {
      type: String,
      default: undefined,
    },
  },
};
</script>
