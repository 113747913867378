import AppBaseModel from './AppBaseModel.js';

class CompanyDomains extends AppBaseModel {
  constructor(options, item) {
    const mergedOptions = {
      singularKey: 'company_domain',
      ...options,
    };
    super(mergedOptions, item);
  }

  save(data, config) {
    return this.httpClient.patch(
      `${this.entityUrl}/${this.adminApi ? 'domain' : 'company_domain'}`,
      this.wrapSingularData(data),
      config,
    );
  }
}

export default CompanyDomains;
