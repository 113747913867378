<template lang="pug">
.page-container(v-if="!loading || companyUser")
  AppBreadcrumbs(:items="breadcrumbs")
  .page-header
    Stack(align="end")
      StackItem(grow)
        .page-label Company user
        .page-title {{ companyUser.name }}
      Stack
        UiButton(
          v-if="!mobileView"
          icon="PencilEdit"
          @click="openEditCompanyUserModal"
        ) {{ $t('actions.edit') }}
        UiButton(
          v-else
          icon="PencilEdit"
          @click="openEditCompanyUserModal"
        )
    .page-stats
      UiCard.page-definition(:class="{ 'page-definition--vertical': !mobileView }" shadow="never")
        UiCardSection.page-definition__body
          .page-definition__label Email
          .page-definition__value {{ companyUser.email }}
      UiCard.page-definition(:class="{ 'page-definition--vertical': !mobileView }" shadow="never")
        UiCardSection.page-definition__body
          .page-definition__label User role
          .page-definition__value {{ $t(`company.role.${companyUser.role}`) }}

  .page-content
    UiTabs(
      :tabs="tabs"
      :selected="activeTabId"
      :fullWidth="mobileView"
      @select="setActiveTabId"
    )
    UiCard
      Transition(name="fade" mode="out-in")
        Component(
          :key="activeTab.id"
          :is="activeTab.component"
          :user="companyUser"
        )
  EditCompanyUserModal(ref="editCompanyUserModal" @done="fetchUser")
</template>

<script>
import CompanyUsers from '@/api/models/CompanyUsers.js';
import EditCompanyUserModal from '@/modals/CompanyUsers/Edit.vue';
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import Company from '@/models/Company';
import GroupsTab from './ShowView/groups-tab.vue';
import AccessTreeTab from './ShowView/access-tree-tab.vue';
import UserRootAccessTab from './ShowView/user-root-access-tab.vue';

export default {
  components: {
    EditCompanyUserModal,
  },

  data() {
    return {
      loading: true,
      companyUser: null,
    };
  },

  computed: {
    ...mapGetters(['isAdminHost', 'mobileView']),
    ...mapGetters('company', ['company']),

    breadcrumbs() {
      return [
        {
          label: this.$t('companies.title'),
          to: '/admin/companies',
          visible: this.isAdminHost,
        },
        {
          label: this.company.name,
          to: this.$adminCompanyLinkModifier({ name: 'CompanyShow' }),
          visible: this.isAdminHost,
        },
        {
          label: 'Users',
          to: this.$adminCompanyLinkModifier({ name: 'CompanyShow', params: { tab: 'users' } }),
          visible: true,
        },
        {
          label: this.companyUser.name,
          visible: true,
        },
      ].filter((i) => i.visible);
    },
    tabs() {
      return [
        {
          id: 'groups',
          label: 'Groups',
          component: GroupsTab,
        },
        {
          id: 'root_access',
          label: this.$t('user_groups.personal_access'),
          component: UserRootAccessTab,
          visible: !Company.isAdmin(this.companyUser.role),
        },
        {
          id: 'access_tree',
          label: 'Access tree',
          component: AccessTreeTab,
        },
      ].filter((i) => i.visible ?? true);
    },
    activeTabId() {
      return this.tabs.some((tab) => tab.id === this.$route.params.tab)
        ? this.$route.params.tab
        : this.tabs[0].id;
    },
    activeTab() {
      return this.tabs.find((tab) => tab.id === this.activeTabId);
    },
  },

  mounted() {
    this.fetchUser();
  },

  methods: {
    async fetchUser() {
      try {
        this.loading = true;
        const response = await new CompanyUsers(
          {
            adminApi: this.isAdminHost,
            owner: this.isAdminHost
              ? new Companies(null, { id: this.$route.params.company_id })
              : null,
          },
          { id: this.$route.params.user_id },
        ).fetch();
        this.companyUser = response.data.company_user;
      } catch (error) {
        // todo
      } finally {
        this.loading = false;
      }
    },
    setActiveTabId(tab) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          tab,
        },
      });
    },
    openEditCompanyUserModal() {
      this.$refs.editCompanyUserModal.open({
        user: this.companyUser,
      });
    },
  },
};
</script>
