import ContentEntity from './content-entity.js';
import FolderData from './folder-data.js';

class Folder extends ContentEntity {
  constructor(entity, options) {
    super(entity, {
      dataModel: FolderData,
      ...options,
    });
  }
}

export default Folder;
