<template lang="pug">
UiCardSection(padding="lg")
  Stack(
    vertical
    align="stretch"
    spacing="lg"
  )
    ThemesUpload
</template>

<script>
import ThemesUpload from '@/components/ThemesUpload/ThemesUpload.vue';

export default {
  components: { ThemesUpload },
};
</script>

<style scoped lang="scss">
.tab-container {
  padding: 15px;
}
</style>
