<template lang="pug">
.ui-checkbox-multiselect-option(v-bind="$attrs" :class="classes")
  UiCheckbox.ui-checkbox-multiselect-option__checkbox(:modelValue="active")
    slot
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return {
        'ui-checkbox-multiselect-option--active': this.active,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-checkbox-multiselect-option {
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 0.75rem;
  border-left: 1px solid transparent;

  &__checkbox {
    pointer-events: none;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &--active {
    background: rgba(var(--bb-primary-rgb), 0.2);
    border-color: var(--bb-primary);
  }
}
</style>
