<template lang="pug">
UiModal(
  :show="show"
  :allowClose="false"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460px"
  @close="close"
)
  template(#title)
    .title(
      v-html="$t('integration_requests.modals.decline_description', { user_name: userName, integration_name: integrationName })"
    )
</template>

<script>
import IntegrationRequests from '@/api/models/IntegrationRequests.js';

export default {
  emits: ['success'],
  data() {
    return {
      show: false,
      userName: null,
      requestId: null,
      integrationName: null,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.decline'),
          onClick: this.decline,
          variant: 'danger',
          icon: 'Close',
        },
      ];
    },
  },
  methods: {
    open({ userName = null, integrationName = null, requestId = null } = {}) {
      this.userName = userName;
      this.requestId = requestId;
      this.integrationName = integrationName;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async decline() {
      try {
        await new IntegrationRequests(null, { id: this.requestId }).decline();
        this.$emit('success');
        this.show = false;
      } catch (error) {
        const integrationErrorPath = `integration_requests.error.${error?.response?.data?.error}`;
        const message = this.$te(integrationErrorPath)
          ? this.$t(integrationErrorPath)
          : this.$localizeErrorMessage(error);
        this.$toaster.add({
          type: 'error',
          message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400;
}
</style>
