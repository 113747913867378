const OnboardingStep = {
  UNAUTHORIZED: 'unauthorized',
  LOGIN: 'login',
  SIGNUP: 'sugnup',
  CHOOSE_COMPANY: 'choose_company',
  CREATE_COMPANY: 'create_company',
  NO_RIGHTS: 'no_rights',
  INTEGRATION_EXISTS: 'integration_exists',
  CAN_NOT_FIND_USER_OR_COMPANY: 'can_not_find_user_or_company',
  SUCCESS_CONNECTION: 'success_connection',
  SUCCESS_REQUEST: 'success_request',
  CANCEL: 'cancel',
  CONFIRM: 'confirm',
  SUMMARY: 'summary',
};

export default OnboardingStep;
