<template lang="pug">
.system-banner-edit
  .system-banner-edit__wrapper
    UiCardSection(padding="xl")
      SystemBannerForm(
        :inProgress="inProgress"
        :modelValue="modelValue"
        :vuelidateModel="v$.modelValue?.descriptionLength"
        @update:modelValue="updateForm"
      )
  .system-banner-edit__actions
    UiStack(justify="space-between")
      UiButton(
        icon="Trash"
        variant="ghost-danger"
        :round="mobileView"
        @click="$emit('delete')"
      ) {{ $t('system_banners.delete_banner') }}
      UiStack(spacing="sm")
        UiStackItem
          UiButton(
            variant="smooth-secondary"
            data-test-id="action-cancel-button"
            @click="$emit('cancel')"
          ) {{ $t('actions.cancel') }}
        UiStackItem
          UiButton(data-test-id="action-save-button" @click="save") {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import SystemBanners from '@/api/models/system-banners.js';
import SystemBannerForm from '@/components/system-banner/system-banner-form.vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

export default {
  components: {
    SystemBannerForm,
  },
  emits: ['save', 'cancel', 'delete', 'toggleEdit', 'update:modelValue'],
  props: {
    isPortalManagementPage: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      inProgress: false,
    };
  },
  validations() {
    const required = (value) => value;

    return {
      modelValue: {
        descriptionLength: {
          required: helpers.withMessage(this.$t('system_banners.validations.required'), required),
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'mobileView']),
  },
  methods: {
    async save() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      try {
        const params = {
          system_banner: {
            description: this.modelValue.description,
            banner_type: this.modelValue.banner_type,
            user_roles: this.modelValue.user_roles,
            dismissible: this.modelValue.dismissible,
          },
        };
        await new SystemBanners(
          {
            adminApi: this.isAdmin,
            owner:
              this.isAdmin && !this.isPortalManagementPage
                ? new Companies(null, { id: this.$route.params.id })
                : null,
          },
          { id: this.modelValue.id },
        ).update(params);

        this.$emit('save');
        this.$emit('toggleEdit');
      } catch (error) {
        const errorMessage =
          error?.response?.data?.errors?.banner_type[0] ||
          error?.response?.data?.errors?.user_roles[0];
        const message = errorMessage
          ? this.$t(`system_banners.validations.${errorMessage}`)
          : this.$localizeErrorMessage(error);
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message,
        });
      }
    },
    updateForm(newValues = {}) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        ...newValues,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.system-banner-edit {
  &__wrapper {
    background-color: var(--bb-gray-900);
  }

  &__actions {
    margin-top: 0.75rem;
  }
}
</style>
