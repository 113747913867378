<template lang="pug">
UiModal(
  :title="title"
  :subtitle="message"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :width="710"
  @close="close"
)
  template(#subtitle)
    div(v-html="message")
  Stack(
    vertical
    align="stretch"
    spacing="lg"
  )
    Stack(vertical align="stretch")
      Stack(justify="space-between")
        UiButton(
          icon="CheckedCheckboxes"
          variant="text-secondary"
          :disabled="inProgress"
          @click="selectAll"
        ) {{ $t('actions.choose_all') }}
        UiButton(
          icon="Trash"
          variant="text-danger"
          :disabled="inProgress"
          @click="deselectAll"
        ) {{ $t('actions.deselect_all') }}
      StackItem
        UiInput(
          v-model="search"
          :placeholder="this.$t('user_groups.modals.entity_access_list.search_placeholder')"
          icon="Search"
        )
      UiStackItem
        UiTabs(
          :tabs="tabs"
          skin="light"
          :selected="activeTab"
          @select="activeTab = $event"
        )
        Component(
          v-for="tab in tabs"
          v-show="activeTab === tab.id"
          v-model:inProgress="inProgress"
          ref="tabComponent"
          :id="tab.id"
          :is="tab.content"
          :entity="entity"
          :search="search"
          @dirty="onDirty(tab.id, $event)"
          @done="onDone()"
        )
RootAccessConfirmation(ref="rootAccessConfirmation" @confirm="confirm")
</template>

<script>
import EntityTypeKey from '@/helper/entity-type-key.js';
import UserGroupsListTab from './user-groups-list-tab.vue';
import UserListTab from './user-list-tab.vue';
import RootAccessConfirmation from './root-access-confirmation.vue';

export const ITEMS_PER_PAGE = 30;
const USER_GROUPS_LIST_TAB = 'user_groups_list';
const USER_LIST_TAB = 'users_list';

export default {
  name: 'EntityAccessListModal',
  components: {
    UserGroupsListTab,
    UserListTab,
    RootAccessConfirmation,
  },
  inheritAttrs: false,

  emits: ['done'],

  data() {
    return {
      show: false,
      inProgress: false,
      entityType: null,
      entity: null,
      activeTab: USER_LIST_TAB,
      search: '',
      isRootFolder: false,
      tabsSelectDirty: {
        USER_LIST_TAB: false,
        USER_GROUPS_LIST_TAB: false,
      },
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.apply'),
          onClick: this.submit,
          disabled: this.inProgress || Object.values(this.tabsSelectDirty).every((value) => !value),
          loading: this.inProgress,
          'data-vi': 'confirm',
        },
      ];
    },

    tabs() {
      return [
        {
          id: USER_LIST_TAB,
          label: this.$t('user_groups.modals.entity_access_list.tabs.users'),
          content: UserListTab,
          disabled: this.inProgress,
        },
        {
          id: USER_GROUPS_LIST_TAB,
          label: this.$t('user_groups.modals.entity_access_list.tabs.user_groups'),
          content: UserGroupsListTab,
          disabled: this.inProgress,
        },
      ];
    },

    title() {
      return this.isRootFolder
        ? this.$t('user_groups.modals.entity_access_list.root_title')
        : this.$t('user_groups.modals.entity_access_list.title');
    },

    message() {
      return this.$t(
        `user_groups.modals.entity_access_list.message_${EntityTypeKey[this.entityType]}`,
        {
          name: this.entity?.name ?? this.entity?.filename,
        },
      );
    },
    activeTabComponent() {
      if (!this.show) return null;
      return this.$refs?.tabComponent?.find((tab) => tab.id === this.activeTab);
    },
  },

  methods: {
    open({ type, entity, isRootFolder = false }) {
      this.entityType = type;
      this.entity = entity;
      this.show = true;
      this.search = '';
      this.isRootFolder = isRootFolder;
    },

    onDirty(tabId, isDirty) {
      this.tabsSelectDirty[tabId] = isDirty;
    },

    onDone() {
      this.close();
      this.$emit('done');
    },

    close() {
      this.show = false;
    },

    deselectAll() {
      this.activeTabComponent.deselectAll();
    },

    selectAll() {
      this.activeTabComponent.selectAll();
    },

    confirmSubmit() {
      this.$refs.rootAccessConfirmation.open();
    },

    async confirm() {
      const requests = [];
      this.tabs.forEach((tab) => {
        if (this.tabsSelectDirty[tab.id]) {
          const tabComponent = this.$refs.tabComponent.find((component) => component.id === tab.id);
          requests.push(tabComponent.submit());
        }
      });

      await Promise.all(requests);
      this.$toaster.add({
        title: this.$t('status.success'),
        message: this.$t('user_groups.access_to_entity_has_been_updated', {
          name: this.entity.name ?? this.entity.filename,
        }),
        actions: null,
      });
    },

    submit() {
      if (this.isRootFolder) {
        this.confirmSubmit();
      } else {
        this.confirm();
      }
    },
  },
};
</script>

<style lang="scss">
.list-tab {
  border: 1px solid var(--bb-input-border);
  border-radius: 0.4rem;
  min-height: 300px;
  max-height: 50vh;
  overflow: auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__info-layout {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.state-box {
  &--loading &__message {
    color: var(--bb-gray-500);
  }

  &--error &__message {
    color: var(--bb-danger);
  }
}
</style>
