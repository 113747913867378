<template lang="pug">
UiModal(
  :title="title"
  :show="show"
  width="520"
  :rightActions="rightActions"
  @close="close"
)
  template(v-if="integration")
    div(v-if="isNetvisor")
      p(data-vi="netvisor-integration") {{ $t('company.integrations.netvisor_integration') }}
    div(v-else)
      p
        span(v-if="customDetails" data-vi="custom-details") {{ customDetails }} #[a(v-if="customDetailsLink" :href="customDetailsLink.link") {{ customDetailsLink.display_name }}]
        span(
          v-else-if="$te(`company.integrations.details.${formData.software}`)"
          v-html="$t(`company.integrations.details.${formData.software}`)"
        )
      template(v-for="field in integration.fields" :key="field.key")
        div(v-if="field.key === manualEnable")
          p(data-vi="manual-enabled") {{ field.label }}
        UiFormInput(
          v-else
          v-model="formData[field.key]"
          :label="field.label"
          :vuelidate-model="v$.formData[field.key]"
          data-vi="not-manual-input"
        )
</template>

<script>
import Integrations from '@/api/models/Integrations.js';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import _isEmpty from 'lodash/isEmpty.js';

export const MANUAL_ENABLE = 'manual_enable';
const NETVISOR = 'netvisor';

export default {
  name: 'CreateCompanyIntegrationsModal',
  emits: ['done'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      integration: null,
      formData: {},
      manualEnable: MANUAL_ENABLE,
    };
  },
  validations() {
    const validation = {
      formData: {},
    };

    for (const key in this.formData) {
      if (key !== this.manualEnable) {
        validation.formData[key] = { required };
      }
    }

    return validation;
  },

  computed: {
    ...mapGetters(['currentLanguage']),
    ...mapGetters('company', ['currentCompanyId']),

    isNetvisor() {
      return this.formData?.software === NETVISOR;
    },

    isOmniauth() {
      return _isEmpty(this.integration?.fields);
    },

    title() {
      return (
        this.integration?.integration_details[`title_${this.currentLanguage.value}`] ||
        this.integration?.integration_details.title_en
      );
    },

    rightActions() {
      if (this.isNetvisor) {
        return null;
      }

      return [
        {
          label: this.integration?.id
            ? this.$t('actions.save')
            : this.$t('company.integrations.connect_service'),
          loading: this.inProgress,
          onClick: this.save,
          'data-vi': 'save-button',
        },
      ];
    },

    customDetails() {
      return this.integration?.integration_details?.helper_text ?? null;
    },

    customDetailsLink() {
      const url = this.integration?.integration_details?.url;
      if (url) {
        return {
          link: url.link,
          display_name: url.display_name,
        };
      }
      return null;
    },
  },

  methods: {
    open(name, integration) {
      this.integration = integration;
      this.formData.software = name;

      if (this.isNetvisor) {
        this.show = true;
        return;
      }

      if (this.isOmniauth) {
        window.location.href = `/integrations/${this.currentCompanyId}/${name}`;
        return;
      }

      integration.fields.forEach((item) => {
        if (item.key !== this.manualEnable) {
          this.formData[item.key] = this.integration.data?.[item.key] || '';
        }
      });

      this.show = true;
    },

    close() {
      this.show = false;
      this.formData = {};
      this.v$.$reset();
    },

    save() {
      return this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.request();
      });
    },

    request() {
      this.inProgress = true;

      const integration = {
        data: this.formData,
        software: this.formData.software,
      };
      delete integration.data.software;

      let request;

      if (this.integration.id) {
        request = new Integrations(null, { id: this.integration.id }).update(integration);
      } else {
        request = new Integrations().create(integration);
      }

      request
        .then((resp) => {
          const { oauth_path } = resp.data.integration;
          if (oauth_path) {
            window.location.href = oauth_path;
          } else {
            this.$emit('done');
          }
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
            timeout: 7000,
          });
        })
        .finally(() => {
          this.close();
          this.inProgress = false;
        });
    },
  },
};
</script>
