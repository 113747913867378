<template lang="pug">
.dataroom-forms__card
  .dataroom-forms__card__left
    h3(data-vi="name") {{ form.name }}
    .dataroom-forms__card__left__type
      p {{ $t('dataroom.form_type') }}
      UiBadge(
        :variant="form.form_type === 'single_entry' ? 'primary' : 'warning'"
        size="sm"
        data-vi="form-type-badge"
      ) {{ $t(`dataroom.form_types.${form.form_type}`) }}
  .dataroom-forms__card__right
    .dataroom-forms__card__right__type
      template(v-if="form.form_type === 'single_entry'")
        UiButton(
          :to="`/dataroom/${form.id}`"
          :disabled="isViewAsOtherUser"
          data-vi="redirect-button"
        ) {{ $t('dataroom.fill_view') }}
      template(v-else-if="form.form_type === 'multi_entries' && form.multi_entries_visible")
        UiButton.dataroom-forms__card__new-entry(
          :disabled="isViewAsOtherUser"
          data-vi="new-entry-btn"
          @click="newFormEntry(form.id)"
        ) {{ $t('dataroom.new_entry') }}
        UiButton(
          :to="`/dataroom/${form.id}/entries`"
          :disabled="isViewAsOtherUser"
          data-vi="view-all-btn"
        ) {{ $t('dataroom.view_all_entries') }}
</template>

<script setup>
import { defineProps, computed } from 'vue';
import Dataroom from '@/api/models/Dataroom.js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
defineProps({
  form: { type: Object, required: true },
});

const isViewAsOtherUser = computed(() => {
  return store.getters.isViewAsOtherUser;
});

function newFormEntry(formId) {
  new Dataroom(null, { id: formId })
    .fetch({ new_entry: true })
    .then((resp) => {
      router.push({
        name: 'DataroomViewEntry',
        params: { id: formId, entry_id: resp.data.dataroom_form.entry_id },
      });
    })
}
</script>
