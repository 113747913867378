<template lang="pug">
modal(
  :show="show"
  :close="close"
  :width="'500px'"
)
  template(#body)
    p {{ $t('access.confirm', payload.ids.length) }}
  template(#footer)
    Stack(justify="end" spacing="sm")
      StackItem
        UiButton.action-button(
          variant="outline-primary"
          :disabled="inProgress"
          @click="close"
        ) {{ $t('groups.delete_modal.btn_no') }}
      StackItem
        UiButton.action-button(
          :loading="inProgress"
          :disabled="inProgress"
          @click="columnUpdate"
        ) {{ $t('groups.delete_modal.btn_yes') }}
</template>

<script>
import FastAccess from '@/api/models/FastAccess.js';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      inProgress: false,
      column: null,
      payload: {
        ids: [],
        state: false,
      },
    };
  },
  methods: {
    open(column, payload) {
      this.show = true;
      this.column = column;
      this.payload = { ...this.payload, ...payload };
    },

    close() {
      this.show = false;
    },

    columnUpdate() {
      this.inProgress = true;
      new FastAccess()
        .updateBatch(this.column, this.payload)
        .then((res) => {
          this.inProgress = false;
          this.show = false;
          this.$emit('wasUpdated');
        })
        .catch((e) => {
          this.inProgress = false;
          this.$toaster.add({
            type: 'error',
            message: this.$t('errors.server_error_try_later'),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 80px;
}
p {
  font-size: 16px;
}
</style>
