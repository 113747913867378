<template lang="pug">
UiModal(
  :title="$t('report_embedded_links.modals.create.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  Stack(
    vertical
    align="stretch"
    spacing="xl"
  )
    UiFormInput(
      v-model="form.name"
      label="Name"
      :vuelidateModel="v$.form.name"
    )
    KeepAlive
      UiFormMultiselect(
        v-model="form.bookmark_id"
        :label="$t('report_embedded_links.modals.create.select_bookmark_label')"
        :options="fetchBookmarks"
        labelKey="name"
        valueProp="id"
        :vuelidateModel="v$.form.bookmark_id"
      )
    UiStack(align="end" spacing="xs")
      UiStackItem(grow shrink)
        UiInputLabel {{ $t('report_embedded_links.modals.create.link_expiration_date_label') }}
        UiDatePicker(
          v-model="form.access_period"
          :pressets="datePickerPressets"
          :placeholder="$t('periods.unlimited_period')"
        )
      UiButton(
        variant="smooth-primary"
        :disabled="!form.access_period"
        @click="form.access_period = null"
      ) {{ $t('actions.clear') }}
    UiAlert(
      v-if="form.publicly_available && (!!report.rls_rule_name || haveLinkedReportsRlsRule)"
      variant="warning"
      :closable="false"
      :title="$t('company.domain.pay_attention_alert.title')"
    ) {{ $t('report_embedded_links.modals.create.warning_public_link_if_shared_link') }}
    UiCheckbox(
      v-if="currentCompany?.public_embedded_links_enabled && currentCompany?.private_embedded_links_enabled"
      v-model="form.publicly_available"
      switch
    ) {{ $t('report_embedded_links.modals.create.make_report_link_public_label') }}
    UiStackItem(v-if="form.publicly_available")
      UiInputLabel {{ $t('report_embedded_links.modals.create.allowed_ip_address_label') }}
      Stack(
        vertical
        align="stretch"
        spacing="md"
      )
        template(v-for="(address, index) in form.ip")
          IpAddressInput(
            v-model="form.ip[index]"
            :disableDelete="form.ip.length <= 1"
            @delete="() => removeIPAddress(index)"
          )
        UiStackItem
          UiButton(
            icon="Plus"
            size="xs"
            variant="smooth-primary"
            :disabled="!allowAddIPAddress"
            @click="addIPAddress"
          ) {{ $t('report_embedded_links.modals.create.action_add_ip_address') }}
</template>

<script>
import PowerBiEmbeddedLinks from '@/api/models/PowerBiEmbeddedLinks.js';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import { addDays, addMonths } from '@bi-book/bibook-ui-kit';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@/common/validators.js';
import { mapGetters } from 'vuex';
import ReportPagesPicker from '@/components/Report/ReportPagesPicker.vue';
import Bookmarks from '@/api/models/Bookmarks.js';
import IpAddressInput from './components/ip-address-input.vue';

function getForm() {
  return {
    name: '',
    active: true,
    target_type: 'PowerBiReport',
    access_period: addMonths(new Date(), 6),
    publicly_available: false,
    bookmark_id: null,
    ip: [''],
  };
}

export default {
  components: {
    ReportPagesPicker,
    IpAddressInput,
  },

  emits: ['done'],

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      show: false,
      inProgress: false,
      report: null,
      reportId: null,
      haveLinkedReportsRlsRule: false,
      form: getForm(),
      isValidReports: false,
      error: null,
    };
  },

  validations() {
    return {
      form: {
        name: {
          required,
        },
        bookmark_id: {
          required: requiredIf(this.form.target_type === 'Bookmark'),
        },
      },
    };
  },

  computed: {
    ...mapGetters('company', ['currentCompany']),

    allowAddIPAddress() {
      return this.form.ip.length < 15 && !this.form.ip.some((a) => !a);
    },

    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          disabled: this.inProgress,
          onClick: this.close,
          variant: 'smooth-secondary',
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.add'),
          loading: this.inProgress,
          onClick: this.submit,
        },
      ];
    },

    datePickerPressets() {
      return [
        {
          label: this.$t('periods.unlimited_period'),
          value: null,
        },
        {
          label: this.$t('periods.1_day'),
          value: addDays(new Date(), 1),
        },
        {
          label: this.$t('periods.1_week'),
          value: addDays(new Date(), 7),
        },
        {
          label: this.$t('periods.1_month'),
          value: addMonths(new Date(), 1),
        },
        {
          label: this.$t('periods.3_months'),
          value: addMonths(new Date(), 3),
        },
        {
          label: this.$t('periods.6_months'),
          value: addMonths(new Date(), 6),
        },
      ];
    },
  },

  methods: {
    open({ powerBiReportId, powerBiReport }) {
      this.v$.$reset();
      this.error = null;
      this.report = powerBiReport;
      this.reportId = powerBiReportId;
      this.form = getForm();
      if (
        !this.currentCompany?.private_embedded_links_enabled ||
        !this.currentCompany?.public_embedded_links_enabled
      ) {
        this.form.publicly_available = this.currentCompany?.public_embedded_links_enabled;
      }

      if (powerBiReport.linked_reports) {
        this.haveLinkedReportsRlsRule = powerBiReport.linked_reports.some((r) => r.rls_rule_name);
      }

      this.show = true;
    },

    close() {
      this.show = false;
    },

    async submit() {
      this.v$.$reset();
      this.error = null;
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }

      try {
        this.inProgress = true;
        const form = { ...this.form };

        if (!form.publicly_available) {
          form.ip = [];
        } else {
          form.ip = [...form.ip].filter((ip) => !!ip);
        }

        if (form.bookmark_id) {
          form.target_type = 'Bookmark';
        }

        if (form.target_type !== 'Bookmark') {
          form.bookmark_id = null;
        }

        await new PowerBiEmbeddedLinks({
          owner: new PowerBiReports(null, { id: this.reportId }),
        }).create(form);
        this.close();
        this.$emit('done');
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },

    addIPAddress() {
      this.form.ip.push('');
    },

    removeIPAddress(index) {
      this.form.ip.splice(index, 1);
    },

    async fetchBookmarks() {
      try {
        const response = await new Bookmarks().fetchAll();
        return response.data.bookmarks.filter(
          (bookmark) => bookmark.power_bi_report_id === this.reportId,
        );
      } catch {
        // do nothing
      }

      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-form-field {
  & + & {
    margin-top: 0;
  }
}
</style>
