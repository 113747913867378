<template lang="pug">
.analytics-sidebar__bookmarks-panel
  .analytics-sidebar__header
    .analytics-sidebar__header-wrapper-title
      .analytics-sidebar__title {{ $t('analytics.bookmarks') }}
      .analytics-sidebar__close(data-test-id="button-close" @click="$emit('close')")
    .analytics-sidebar__btn-add-bookmark
      UiButton(
        icon="BookmarkAdd"
        variant="smooth-primary"
        size="md"
        data-test-id="button-create"
        @click="$emit('create')"
      ) {{ $t('analytics.create_bookmark.add_bookmark') }}
  .analytics-sidebar__body-scroll-wrapper
    .analytics-sidebar__body
      template(v-if="!loading")
        SidebarGroup(
          label="Private"
          :active="isPathOpen('private')"
          data-test-id="sidebar-group-private"
          @toggle="togglePath('private')"
        )
          template(#icon)
            UiIcon.primary-icon(name="User" size="18")
          SidebarGroupItem(
            v-for="bookmark in privateBookmarks"
            :key="bookmark.id"
            :active="currentBookmarkId === String(bookmark.id)"
            data-test-id="private-bookmarks-item"
            @click="$emit('select', bookmark.id)"
            @deleteBookmark="$emit('delete', bookmark.id)"
          )
            template(#icon)
              UiIcon.gray-icon-700(name="Bookmarks" size="1.2rem")
            | {{ bookmark.name }}
        SidebarGroup(
          label="Public"
          :active="isPathOpen('public')"
          data-test-id="sidebar-group-public"
          @toggle="togglePath('public')"
        )
          template(#icon)
            UiIcon.primary-icon(name="Users" size="25")
          SidebarGroupItem(
            v-for="bookmark in publicBookmarks"
            :key="bookmark.id"
            :active="currentBookmarkId === String(bookmark.id)"
            :isPublicBookmark="true"
            data-test-id="public-bookmarks-item"
            @click="$emit('select', bookmark.id)"
            @deleteBookmark="$emit('delete', bookmark.id)"
          )
            template(#icon)
              UiIcon.gray-icon-700(name="Bookmarks" size="1.2rem")
            | {{ bookmark.name }}
      .skeleton(v-else)
        Stack(vertical align="stretch")
          StackItem(v-for="i in 7")
            Stack
              StackItem
                UiSkeleton(
                  rounded
                  width="40px"
                  height="40px"
                  data-test-id="skeleton"
                )
              StackItem(fill)
                UiSkeleton(width="128" height="18px")
</template>

<script>
import SidebarGroup from './SidebarGroup.vue';
import SidebarGroupItem from './SidebarGroupItem.vue';

function isPrivateBookmark(bookmark) {
  return bookmark?.user_id !== null;
}

export default {
  components: {
    SidebarGroup,
    SidebarGroupItem,
  },
  emits: ['create', 'delete'],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    currentBookmarkId: {
      type: [Number, String],
      default: null,
    },
    bookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      openPaths: {
        public: true,
      },
    };
  },
  mounted() {
    this.initActivePaths();
  },
  watch: {
    currentBookmarkId() {
      this.initActivePaths();
    },
  },
  computed: {
    privateBookmarks() {
      return this.bookmarks.filter((b) => isPrivateBookmark(b));
    },
    publicBookmarks() {
      return this.bookmarks.filter((b) => !isPrivateBookmark(b));
    },
  },
  methods: {
    isPathOpen(path) {
      return this.openPaths[path];
    },
    togglePath(path) {
      this.openPaths[path] = !this.openPaths[path];
    },
    initActivePaths() {
      const bookmark = this.bookmarks.find((b) => b.id == this.currentBookmarkId);
      if (bookmark) {
        if (isPrivateBookmark(bookmark)) {
          this.openPaths.private = true;
        } else {
          this.openPaths.public = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  padding: 10px;
}

.analytics-sidebar__bookmarks-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bb-app-bg-main);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .analytics-sidebar__header {
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 26px 20px;
  }

  .analytics-sidebar__header-wrapper-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
