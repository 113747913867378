import ContentEntity from './content-entity.js';
import DataroomFormData from './dataroom-form-data.js';
import DataroomFormExtras from './dataroom-form-extras.js';

class DataroomForm extends ContentEntity {
  constructor(entity, options) {
    super(entity, {
      dataModel: DataroomFormData,
      extrasModel: DataroomFormExtras,
      ...options,
    });
  }
}

export default DataroomForm;
