<template lang="pug">
modal(
  :title="withError ? $t('dataroom.refresh_error') : $t('dataroom.refresh_form')"
  :subtitle="withError ? $t('dataroom.refresh_error_description') : null"
  :show="show"
  :close="close"
)
  template(#body)
    div(:style="{ padding: '0 20px' }")
      template(v-if="withError && !refreshAgainActive")
        p {{ dataroomForm.refresh_message }}
        .refresh-footer-buttons
          UiButton(
            variant="text-primary"
            :disabled="inProgress || isViewAsOtherUser"
            width
            @click="refreshReset"
          ) {{ $t('dataroom.refresh_reset') }}

          template(v-if="dataroomForm.refresh_again")
            UiButton(
              variant="text-primary"
              :disabled="inProgress || isViewAsOtherUser"
              width
              @click="refreshAgain"
            ) {{ $t('dataroom.refresh_again') }}
        template(v-if="!dataroomForm.refresh_again")
          p {{ $t('dataroom.refresh_again_timeout') }}
      template(v-else-if="dataroomForm.refresh_finished_message")
        p {{ $t('dataroom.refresh_success') }}
        p(v-if="refreshTimeoutTime") {{ $t('dataroom.refresh_timeout_time', { time: refreshTimeoutTime }) }}
      template(v-else)
        p.data-refresh-modal__error(v-if="refreshError") {{ refreshError }}
        UiButton.data-refresh-modal__refresh-button(
          v-for="(refresh_type, key) in refresh_types"
          :key="key"
          :loading="refreshTypeInProgress(refresh_type)"
          :disabled="inProgress || isViewAsOtherUser"
          width="100%"
          @click="refresh(refresh_type)"
        ) {{ $t(`dataroom.refresh_types.${refresh_type}`) }}
</template>
<script>
import Dataroom from '@/api/models/Dataroom.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      show: false,
      dataroomForm: null,
      entryId: null,
      withError: false,
      refreshError: null,
      refreshAgainActive: false,
      inProgress: false,
      refresh_types: ['3_months', '12_months', '36_months'],
      form: {
        refresh_type: null,
      },
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser']),

    refreshTypeInProgress() {
      return (refresh_type) => this.inProgress && refresh_type === this.form.refresh_type;
    },
    refreshTimeoutTime() {
      let { refresh_timeout: totalSeconds } = this.report;
      if (!totalSeconds) return false;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
  },
  methods: {
    open(dataroomForm, entryId = null) {
      this.dataroomForm = dataroomForm;
      this.entryId = entryId;
      this.withError = dataroomForm.refresh_status === 'error';
      this.form.refresh_type = null;
      this.show = true;
    },
    close() {
      this.show = false;
      this.withError = false;
      this.refreshError = null;
    },
    refreshAgain() {
      const { refresh_type } = this.dataroomForm;
      this.form.refresh_type = refresh_type;
      this.refreshAgainActive = true;
    },
    refreshReset() {
      if (this.inProgress) return;
      this.inProgress = true;
      new Dataroom(null, { id: this.dataroomForm.id })
        .resetRefresh()
        .then((resp) => {
          this.inProgress = false;
          if (resp.status === 200) {
            this.$emit('done');
            this.show = false;
          }
        })
        .catch((_e) => {
          this.inProgress = false;
        });
    },
    refresh(refresh_type) {
      if (this.inProgress || !this.refresh_types.includes(refresh_type)) return;
      this.form.refresh_type = refresh_type;
      this.sendRefresh();
    },
    sendRefresh() {
      if (this.form.refresh_type === null || this.inProgress) return;

      this.inProgress = true;
      this.refreshError = null;
      const params = {};
      if (this.entryId) params.entry_id = this.entryId;
      new Dataroom(null, { id: this.dataroomForm.id })
        .refresh({ ...this.form, ...params })
        .then((resp) => {
          this.inProgress = false;
          if (resp.status === 200) {
            this.$emit('done');
            this.show = false;
          }
        })
        .catch((error) => {
          this.refreshError = error.response?.data?.error;
          this.inProgress = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/stylesheet/variables';

.refresh-footer-buttons {
  display: flex;
  justify-content: space-around;
}

.data-refresh-modal {
  &__error {
    color: #c9432a;
    margin: 1rem 0;
  }

  &__refresh-button {
    margin-top: 15px;
  }
}
</style>
