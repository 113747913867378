<template lang="pug">
.theme-preview
  .theme-preview__scale-container
    ThemePreviewNavigation
    ThemePreviewHeader
    .application-content
      .dashboard
        .dashboard-reports
          .dashboard-reports__container
          UiRestTable(
            :headers="headersList"
            :fetchHandler="fakeFetch"
            :searchPlaceholder="$t('administrators.table.search_placeholder')"
          )
            template(#cell:actions="{ item }")
              .actions
                UiButton.copy(
                  icon="Copy"
                  size="sm"
                  round
                  variant="text-primary"
                )
                UiButton(
                  icon="Edit"
                  size="sm"
                  round
                  variant="text-primary"
                )
                UiButton(
                  icon="Trash"
                  size="sm"
                  round
                  variant="text-danger"
                )
            template(#extraFilters)
              UiCheckbox(v-model="showSwitch" switch) Show
    .theme-preview__alerts
      UiAlert(
        v-for="alert in alerts"
        :key="alert"
        :variant="alert"
      )
        template(#title) {{ $t(`status.${alert}`) }}
        | {{ $t('ui_theme_preview.alert_message') }}
    .theme-preview__toasts
      UiToast(
        v-for="toast in toasts"
        :key="toast"
        :type="toast"
        :actions="[{ label: 'OK' }]"
        showClose
      )
        template(#title) {{ $t(`status.${toast}`) }}
        | {{ $t('ui_theme_preview.toast_message') }}
</template>

<script>
import ThemePreviewNavigation from '@/views/Admin/PortalManagement/components/theme-preview/preview-navigation.vue';
import ThemePreviewHeader from '@/views/Admin/PortalManagement/components/theme-preview/preview-header.vue';

export default {
  name: 'ThemePreview',
  components: {
    ThemePreviewHeader,
    ThemePreviewNavigation,
  },
  data() {
    return {
      showSwitch: true,
      headersList: [
        {
          key: 'id',
          label: 'ID',
          squeeze: true,
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('administrators.table.name'),
        },
        {
          key: 'actions',
          label: this.$t('administrators.table.actions'),
          squeeze: true,
        },
      ],
    };
  },
  computed: {
    alerts() {
      return ['success', 'warning', 'error', 'info'];
    },
    toasts() {
      return ['success', 'warning', 'error'];
    },
  },
  methods: {
    async fakeFetch() {
      return {
        meta: {
          total_pages: 1,
        },
        items: [
          {
            id: 0,
            name: 'Jan Sharp',
          },
          {
            id: 1,
            name: 'Penelope Skinner',
          },
          {
            id: 2,
            name: 'Michael Cameron',
          },
          {
            id: 3,
            name: 'Matt Fisher',
          },
          {
            id: 4,
            name: 'Diane Ball',
          },
          {
            id: 4,
            name: 'Diane Ball',
          },
          {
            id: 5,
            name: 'Jan Sharp',
          },
          {
            id: 6,
            name: 'Penelope Skinner',
          },
          {
            id: 7,
            name: 'Michael Cameron',
          },
          {
            id: 8,
            name: 'Matt Fisher',
          },
          {
            id: 9,
            name: 'Diane Ball',
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
$height: 700px;

.theme-preview {
  overflow: hidden;
  position: relative;
  min-width: 560px;
  z-index: 0;
  color: var(--bb-text-color);

  @media (max-width: 1340px) {
    min-width: 420px;
  }

  &:before {
    content: '';
    padding-top: 70%;
    display: block;
  }

  &__alerts,
  &__toasts {
    width: 360px;
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    transform: scale(0.7);
    transform-origin: 100% 100%;
    z-index: 1000;
  }

  &__alerts {
    top: 10px;
  }

  &__toasts {
    bottom: 10px;
  }

  &__scale-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    height: 125%;
    transform-origin: 0 0;
    transform: scale(0.8);

    @media (max-width: 1800px) {
      width: 150%;
      height: 150%;
      transform: scale(0.6666);
    }

    @media (max-width: 1580px) {
      width: 200%;
      height: 200%;
      transform: scale(0.5);
    }

    @media (max-width: 1366px) {
      width: 222%;
      height: 222%;
      transform: scale(0.45);
    }
  }

  .application-content {
    margin-left: 80px;
    padding-bottom: 1rem;
    height: 100%;
    overflow: hidden;
    padding-right: 260px;
    background: var(--bb-app-bg-main);
  }

  .dashboard__groups__card {
    width: 300px;
  }

  .plus-icon {
    transform: rotate(45deg);
  }

  .actions {
    display: flex;

    .copy {
      color: var(--bb-gray-850);
    }
  }
}
</style>
