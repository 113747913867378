<template lang="pug">
.theme-preview
  .theme-preview__scale-container
    PreviewNavigation
    PreviewHeader
    .analytics-content
      .analytics-layout
        AnalyticsSidebar(
          ref="sidebar"
          :loading="false"
          :isOpen="true"
          :currentPageIndex="0"
          :isShowBookmarksPanel="false"
          :navigationTree="navigationTree"
          :scroll-to-active-el="false"
          currentReportId="report_1"
        )
        .analytics-report
          AnalyticsHeader(
            :editMode="false"
            :editable="false"
            :isEditingAllowed="false"
            :isShowBookmarksPanel="false"
          )
          .analytics-dashboard
            UiSkeleton(width="100%" height="100%")
</template>

<script>
import PreviewNavigation from '@/views/Admin/PortalManagement/components/theme-preview/preview-navigation.vue';
import PreviewHeader from '@/views/Admin/PortalManagement/components/theme-preview/preview-header.vue';
import AnalyticsSidebar from '@/views/Analytics/components/Sidebar/Index.vue';
import AnalyticsHeader from '@/views/Analytics/components/Header.vue';

export default {
  name: 'PreviewAnalytics',
  components: {
    PreviewHeader,
    PreviewNavigation,
    AnalyticsSidebar,
    AnalyticsHeader,
  },
  data() {
    return {};
  },
  computed: {
    navigationTree() {
      return {
        groups: {
          group_1: {
            id: 'group_1',
            name: 'Complex',
            color: '#E9A21A',
            reportsList: [],
          },
          group_2: {
            id: 'group_2',
            name: 'Marketing',
            color: '#29BB89',
            reportsList: ['report_1'],
          },
        },
        reports: {
          report_1: {
            id: 'report_1',
            name: 'Tables',
            editing: false,
            order_num: 1,
            merged_report: false,
            is_default: false,
            groupId: 'group_2',
            pages: [
              {
                displayName: 'Trial',
                isActive: true,
                name: 'Trial',
                visibility: 0,
              },
              {
                displayName: 'Analytics',
                isActive: false,
                name: 'Analytics',
                visibility: 0,
              },
            ],
          },
          report_2: {
            id: 'report_2',
            name: 'Finance',
            editing: false,
            order_num: 2,
            merged_report: false,
            is_default: false,
            groupId: 'group_1',
            pages: [],
          },
        },
        groupsList: ['group_1', 'group_2'],
        reportsList: ['report_2'],
      };
    },
  },
};
</script>

<style scoped lang="scss">
$height: 700px;

.theme-preview {
  overflow: hidden;
  position: relative;
  min-width: 560px;
  z-index: 0;
  color: var(--bb-text-color);

  @media (max-width: 1340px) {
    min-width: 420px;
  }

  &:before {
    content: '';
    padding-top: 70%;
    display: block;
  }

  &__scale-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    height: 125%;
    transform-origin: 0 0;
    transform: scale(0.8);

    @media (max-width: 1800px) {
      width: 150%;
      height: 150%;
      transform: scale(0.6666);
    }

    @media (max-width: 1580px) {
      width: 200%;
      height: 200%;
      transform: scale(0.5);
    }

    @media (max-width: 1366px) {
      width: 222%;
      height: 222%;
      transform: scale(0.45);
    }
  }

  .analytics-content {
    margin-left: 80px;
    padding-bottom: 1rem;
    height: 100%;
    overflow: hidden;
    background: var(--bb-app-bg-main);
  }

  .analytics-layout {
    min-height: 100%;
  }

  .analytics-sidebar__wrapper {
    transform-origin: 0 0;
    transform: scale(1.5);
  }

  .analytics-dashboard {
    margin: 120px 0 0 220px;
  }
}
</style>
