<template lang="pug">
.ui-form-field.ui-form-field--input
  UiInputLabel(v-if="label || $slots.label")
    slot(name="label")
      | {{ label }}
  UiURLInput(
    v-bind="$attrs"
    :error="error || vuelidateModel?.$error"
    @blur="vuelidateModel?.$touch"
  )
    template(#icon v-if="$slots.icon")
      slot(name="icon")
    template(#iconAfter v-if="$slots.iconAfter")
      slot(name="iconAfter")
  UiInputFeedback(v-if="description") {{ description }}
  template(v-if="vuelidateModel?.$errors")
    UiInputFeedback(v-for="$error in vuelidateModel?.$errors" error) {{ $error.$message }}
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    labelSize: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>
