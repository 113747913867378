<template lang="pug">
.navigation.minimal
  .navigation__header
    .navigation__toggle-wrap
      CollapseButton(active variant="white")
    a.logotype.logotype-miniature(href="#")
  .navigation__menu__list
    a(
      v-for="link in navItems"
      :key="link.id"
      href="#"
    )
      .navigation__menu__container--minimal(
        :class="{ 'current-nav__container': link.active }"
        @click="$emit('openMobileMenu')"
      )
        .navigation__menu__item--minimal(:class="{ 'current-nav__item--minimal': link.active }")
          .icon-wrap
            UiIcon(:name="link.iconName" size="2.5rem")
  .navigation__footer
    UiButton.navigation__footer-support-icon(round icon="HelpSupport")
</template>

<script>
import CollapseButton from '@/components/CollapseButton.vue';

export default {
  components: { CollapseButton },
  data() {
    return {
      navItems: [
        {
          id: 'analytics',
          label: this.$t('menu.analytics'),
          iconName: 'Bars',
          active: true,
        },
        {
          id: 'company',
          label: this.$t('menu.company_management'),
          iconName: 'UsersThree',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.navigation {
  position: absolute;
}
</style>
