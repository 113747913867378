<template lang="pug">
UiInputGroup
  UiInputGroupText(:size="$attrs.size") {{ prefix }}
  UiInput(v-bind="$attrs" v-model="inputModelValue")
    template(#icon v-if="$slots.icon")
      slot(name="icon")
    template(#iconAfter v-if="$slots.iconAfter")
      slot(name="iconAfter")
</template>

<script>
export default {
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String,
      default: '',
    },

    prefix: {
      type: [String, Number],
      default: 'https://',
    },
  },

  computed: {
    inputModelValue: {
      get() {
        return (this.modelValue || '').replace(this.prefix, '');
      },
      set(value) {
        const filtredValue = value.replace(this.prefix, '');
        const newValue = filtredValue ? `${this.prefix}${filtredValue}` : '';
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>
