<template lang="pug">
UiFormMultiselect(
  v-model="value"
  :canClear="false"
  :canDeselect="false"
  :label="field.displayname"
  :options="fieldOptions"
  :disabled="disabled"
  labelKey="text"
  @select="updateValueAction"
)
</template>
<script>
export default {
  name: 'DataroomFieldDropdown',
  props: {
    field: {
      type: Object,
    },
    results: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fieldOptions: [],
      value: null,
    };
  },

  mounted() {
    this.fieldOptions = this.field.attributes.options;
    const resultValue = this.results[this.field.name] || this.field.default_value;
    this.value =
      this.fieldOptions.find((fieldOption) => fieldOption.value === resultValue) ||
      this.fieldOptions[0];
    this.value = this.value.value;
    // eslint-disable-next-line vue/no-mutating-props
    this.results[this.field.name] = this.value;
  },
  methods: {
    updateValueAction(option) {
      // eslint-disable-next-line vue/no-mutating-props
      this.results[this.field.name] = option;
    },
  },
};
</script>
