<template lang="pug">
.analytics-sidebar__nav__pages__item(
  v-for="(page, index) in pages"
  :key="index"
  :class="pageClasses(index)"
  data-test-id="sidebar-page"
  @click="$emit('setPage', index)"
)
  UiIcon.gray-icon-700(name="FileEmpty" size="1.7rem")
  span {{ page.displayName }}
</template>

<script>
export default {
  name: 'AnalyticsSidebarPage',
  props: {
    pages: {
      type: Array,
      default() {
        return [];
      },
    },
    currentPageIndex: {
      type: [Number, String],
      default: null,
    },
  },

  emits: ['setPage'],

  methods: {
    pageClasses(index) {
      return {
        active: this.currentPageIndex === index,
      };
    },
  },
};
</script>
