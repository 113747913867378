<template lang="pug">
.container.whiteboard
  Stack
    StackItem
      .text-center
        .h3 Thank you for activating bibook.com via the Pipedrive store!
      .mb24
        | Please follow these exact instructions to set bibook.com up for you:
        ol.instructions
          li Log in using your email address.
          li Accept the terms of service.
          li Wait about 10 minutes while we set you up.
        .contacts
          p Best regards,
          p Jonathan Teir on behalf of bibook.com
          p +358 44 272 3224
  .container(v-if="isAuthenticated")
    Stack(justify="center")
      StackItem
        UiButton(@click="continueUsage") {{ $t('actions.continue') }}
  .container(v-else)
    Stack
      StackItem(v-for="link in oauth2Providers" :key="`pipedrive-login-link-${link.slug}`")
        UiButton(@click="login(link)") {{ `${$t('user.login_with')} ${link.label}` }}

      StackItem.ml-auto
        UiButton(variant="text-primary" @click="cancelUsage") {{ $t('actions.cancel') }}
</template>
<script>
import { mapGetters } from 'vuex';
import eventBus from '@/eventBus.js';
import oauth2Providers from '@/common/login_providers.js';

export default {
  name: 'PipedriveIntegrationEn',
  computed: {
    ...mapGetters(['isAuthenticated', 'integrationCompany']),

    oauth2Providers() {
      return oauth2Providers;
    },
  },

  mounted() {
    this.$store.dispatch('switchLanguage', 'en');
    eventBus.on('LocaleChanged', this.changeLocale);
  },

  beforeUnmount() {
    eventBus.off('LocaleChanged', this.changeLocale);
  },

  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    cancelUsage() {
      let cancel_url = '/pipedrive_cancel';
      const { attempt_id } = this.integrationCompany || {};

      if (attempt_id) {
        cancel_url = `${cancel_url}?attempt_id=${attempt_id}`;
      }
      window.location.href = cancel_url;
    },
    changeLocale(locale) {
      if (locale !== 'en') {
        this.$router.replace(`/sign-up-via-pipedrive/${locale}`);
      }
    },
    login(link) {
      this.$store.dispatch('login', {
        oauth2: link.slug,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 700px;
}

.instructions {
  list-style-position: inside;
}

.contacts {
  margin-left: 40px;
  text-align: end;
}

.question {
  list-style-position: inside;
  list-style-type: disc;
}

.whiteboard {
  word-wrap: break-word !important;
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
  font-size: 18px;
}

.ml-auto {
  margin-left: auto;
}

.mb24 {
  margin-bottom: 24px;
}
</style>
