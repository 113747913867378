<template lang="pug">
UiModal(
  :title="$t('modals.reports.export_report_modal.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  template(#subtitle)
    template(v-if="loading")
      UiSpinner
    template(v-else)
      div(v-html="subtitle")
  UiStack(vertical spacing="xl")
    UiAlert(
      v-if="!loading && alertMessage"
      variant="warning"
      :closable="false"
      data-vi="warning-alert"
    ) {{ alertMessage }}
    UiStackItem
      UiFormMultiselect(
        v-model="fileFormatModelValue"
        :label="$t('modals.reports.export_report_modal.file_format')"
        :disabled="isFormDisabled"
        :options="fileFormatOptions"
        :canDeselect="false"
        :canClear="false"
        data-vi="file-format-select"
      )
      UiFormMultiselect(
        v-model="form.filter"
        :label="$t('modals.reports.export_report_modal.filter_select')"
        :disabled="isFormDisabled"
        :options="filterOptions"
        :canDeselect="false"
        :canClear="false"
        data-vi="filter-select"
      )
      UiFormMultiselect(
        v-model="form.pages_for_export"
        :label="$t('modals.reports.export_report_modal.pages_for_export')"
        :disabled="isFormDisabled || isPNGExport"
        :options="pagesForExportOptions"
        :canDeselect="false"
        :canClear="false"
        data-vi="pages-for-exports-select"
      )
      UiFormMultiselect(
        v-if="form.pages_for_export === 'selected'"
        v-model="form.pages"
        :label="$t('modals.reports.export_report_modal.selected_pages_select')"
        :disabled="isFormDisabled"
        :options="pages"
        mode="tags"
        valueProp="name"
        labelKey="displayName"
        :canDeselect="false"
        :canClear="false"
        :vuelidateModel="v$.form.pages"
        data-vi="selected-pages-select"
      )
</template>

<script>
import PowerBiReports from '@/api/models/PowerBiReports.js';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import ReportPagesPicker from '@/components/Report/ReportPagesPicker.vue';
import { required } from '@/common/validators.js';
import AppInitData from '@/api/models/AppInitData.js';

function getForm(params) {
  return {
    file_format: 'PDF',
    filter: 'current',
    pages_for_export: 'current',
    pages: [],
    ...params,
  };
}

export default {
  components: {
    ReportPagesPicker,
  },

  emits: ['done'],

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      loading: false,
      show: false,
      reportId: null,
      linkedReportId: null,
      currentPage: null,
      pages: [],
      captureReportStateHandler: null,
      error: null,
      inProgress: false,
      form: getForm(),
    };
  },

  validations() {
    const validations = {
      form: {},
    };

    if (this.form.pages_for_export === 'selected') {
      validations.form.pages = {
        required,
      };
    }

    return validations;
  },

  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters(['language']),

    companyExportsLimit() {
      return this.currentCompany?.report_exports_limit ?? 0;
    },

    companyExportsRemaining() {
      return this.currentCompany?.report_exports_remaining ?? 0;
    },

    haveActiveExports() {
      return this.currentCompany?.report_export_in_progress ?? false;
    },

    alertMessage() {
      if (this.haveActiveExports) {
        return this.$t('analytics.export.parallel_export_limit_alert');
      }

      if (!this.companyExportsRemaining) {
        return this.$t('analytics.export.daily_export_limit_alert', {
          limit: this.companyExportsLimit,
        });
      }

      return null;
    },

    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          disabled: this.inProgress,
          onClick: this.close,
          variant: 'smooth-secondary',
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.export'),
          disabled: this.isFormDisabled,
          loading: this.inProgress,
          onClick: this.submit,
        },
      ];
    },

    subtitle() {
      return this.$t('modals.reports.export_report_modal.exports_left_details', {
        count: this.companyExportsRemaining,
      });
    },

    isFormDisabled() {
      return (
        this.loading || this.inProgress || this.haveActiveExports || !this.companyExportsRemaining
      );
    },

    fileFormatOptions() {
      return [
        {
          label: this.$t('modals.reports.export_report_modal.file_format_options.PDF'),
          value: 'PDF',
        },
        {
          label: this.$t('modals.reports.export_report_modal.file_format_options.PNG'),
          value: 'PNG',
        },
        {
          label: this.$t('modals.reports.export_report_modal.file_format_options.PPTX'),
          value: 'PPTX',
        },
      ];
    },

    filterOptions() {
      return [
        {
          label: this.$t('modals.reports.export_report_modal.filter_select_options.current'),
          value: 'current',
        },
        {
          label: this.$t('modals.reports.export_report_modal.filter_select_options.default'),
          value: 'default',
        },
      ];
    },

    pagesForExportOptions() {
      return [
        {
          label: this.$t('modals.reports.export_report_modal.pages_for_export_options.current'),
          value: 'current',
        },
        {
          label: this.$t('modals.reports.export_report_modal.pages_for_export_options.selected'),
          value: 'selected',
        },
        {
          label: this.$t('modals.reports.export_report_modal.pages_for_export_options.all'),
          value: 'all',
        },
      ];
    },

    isPNGExport() {
      return this.form.file_format === 'PNG';
    },

    fileFormatModelValue: {
      get() {
        return this.form.file_format;
      },
      set(value) {
        this.form.file_format = value;
        if (this.isPNGExport) {
          this.form.pages_for_export = 'current';
        }
      },
    },
  },

  methods: {
    async open({ reportId, linkedReportId, currentPage, pages }, captureReportStateHandler) {
      this.v$.$reset();
      this.inProgress = false;
      this.error = null;
      this.reportId = reportId;
      this.linkedReportId = linkedReportId;
      this.form = getForm({
        pages: [currentPage.name],
      });
      this.captureReportStateHandler = captureReportStateHandler;
      this.currentPage = currentPage;
      this.pages = pages;
      this.fetchCompanyState();
      this.show = true;
    },

    close() {
      this.show = false;
    },

    async fetchCompanyState() {
      this.loading = true;
      const companyResponse = await new AppInitData().fetchInitData();
      this.$store.commit('company/UPDATE_COMPANY', companyResponse.data.current_company);
      this.loading = false;
    },

    async getFormData() {
      const form = {
        file_format: this.form.file_format,
        bookmark_state: null,
        pages: null,
        locale: this.language,
      };

      if (this.form.filter === 'current') {
        const bookmark = await this.captureReportStateHandler();
        form.bookmark_state = bookmark.state;
      }

      if (this.form.pages_for_export === 'current') {
        form.pages = [this.currentPage.name];
      } else if (this.form.pages_for_export === 'selected') {
        form.pages = this.form.pages;
      }

      return form;
    },

    async submit() {
      this.inProgress = true;
      this.v$.$reset();
      this.error = null;
      const valid = await this.v$.$validate();
      if (!valid) {
        this.inProgress = false;
        return;
      }
      try {
        const formData = await this.getFormData();
        const exportResponse = await new PowerBiReports(null, { id: this.reportId }).export({
          export_settings: formData,
        });
        this.close();
        this.$emit('done', { exportReportId: exportResponse.data.id });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>
