<template lang="pug">
Stack(justify="space-between")
  StackItem
    UiInputLabel(data-test-id="label") {{ label }}
  StackItem
    UiButton.upload-file(
      variant="outline-primary"
      icon="Export"
      size="sm"
      :disabled="disabled"
      data-test-id="button-upload-file"
      @click="$refs.inputFile.click()"
    ) {{ $t('company.files.upload_file') }}
    input(
      type="file"
      ref="inputFile"
      hidden
      :accept="accept"
      data-test-id="input-upload-file"
      @input="fileUploaded"
    )
UiFormInput(
  v-bind="$attrs"
  v-model="fileText"
  multiline
  :disabled="disabled"
  data-test-id="form-input"
)
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: '*',
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileText: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    fileUploaded(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.fileText = reader.result;
      };
      reader.readAsText(file);
    },
  },
};
</script>

<style scoped lang="scss">
.upload-file {
  margin-bottom: 10px;
}
</style>
