<template lang="pug">
.app-sidebar(:class="classes")
  UiSidebar(:collapsed="collapsed")
    template(#header)
      .app-sidebar__logotype
    template(v-if="!isAdminHost")
      UiTooltip(
        v-for="navItem in navItems"
        :key="navItem.key"
        placement="right"
        :title="navItem.label"
        :disabled="!collapsed"
        :offset=[0, -10]
      )
        template(#activator)
          UiSidebarNavItem(
            v-bind="navItem"
            :active="isActive(navItem)"
            :data-test-id="`nav-item-${navItem.key}`"
          ) {{ navItem.label }}
    template(v-if="isAdminHost")
      UiSidebarNavHeader {{ $t('menu.admin_panel') }}
      UiTooltip(
        v-for="navItem in navAdminItems"
        :key="navItem.key"
        placement="right"
        :title="navItem.label"
        :disabled="!collapsed"
        :offset=[0, -10]
      )
        template(#activator)
          UiSidebarNavItem(
            v-bind="navItem"
            :active="isActive(navItem)"
            :data-test-id="`nav-item-${navItem.key}`"
          ) {{ navItem.label }}
      UiSidebarNavHeader(v-if="navAdminConfigItems.length") {{ $t('menu.admin_panel_config') }}
      UiTooltip(
        v-for="navItem in navAdminConfigItems"
        :key="navItem.key"
        placement="right"
        :title="navItem.label"
        :disabled="!collapsed"
        :offset=[0, -10]
      )
        template(#activator)
          UiSidebarNavItem(
            v-bind="navItem"
            :active="isActive(navItem)"
            :data-test-id="`nav-item-${navItem.key}`"
          ) {{ navItem.label }}
    template(#footer)
      .app-sidebar__support
        UiTooltip(
          placement="right"
          :title="$t('menu.help_link_title')"
          :disabled="!collapsed"
          :offset=[0, -10]
        )
          template(#activator)
            .app-sidebar__support-container
              UiButton.app-sidebar__support-icon(
                round
                icon="HelpSupport"
                :href="helpLink"
                target="_blank"
              )
              .app-sidebar__support-label
                div {{ $t('menu.find_help_at') }}&nbsp;
                a(:href="helpLink" target="_blank")
                  strong {{ $t('menu.help_link_title') }}
  .app-sidebar__toggler
    CollapseButton(
      v-if="!mobileView"
      :active="!navOpen"
      variant="white"
      @click="toggleSidebar"
    )
transition(name="fade")
  .app-sidebar__overlay(v-if="mobileView && navMobileOpen" @click="toggleMobileNav")
</template>

<script>
import { mapGetters } from 'vuex';
import Company from '@/models/Company.js';
import CollapseButton from '@/components/CollapseButton.vue';

export default {
  components: {
    CollapseButton,
  },
  props: {
    isOpenMobileMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: true,
    };
  },

  computed: {
    ...mapGetters([
      'isViewAsOtherUser',
      'mobileView',
      'isAdminHost',
      'navOpen',
      'navMobileOpen',
      'isGlobalAdmin',
    ]),
    ...mapGetters('company', ['currentCompany']),

    collapsed() {
      return !this.navOpen && !this.mobileView;
    },

    navItems() {
      return [
        {
          key: 'analytics',
          to: { name: 'Analytics' },
          label: this.$t('menu.analytics'),
          icon: 'BarChart',
          visible: !!this.currentCompany?.analytics_enabled,
          childrenRoutesName: ['AnalyticsReport', 'AnalyticsBookmark'],
        },
        {
          key: 'dataroom',
          to: { name: 'Dataroom' },
          label: this.$t('menu.forms'),
          icon: 'Form',
          childrenRoutesName: [
            'DataroomNew',
            'DataroomView',
            'DataroomEntries',
            'DataroomViewEntry',
          ],
          visible: this.currentCompany?.dataroom_enabled,
        },
        {
          key: 'content',
          to: { name: 'ContentIndex' },
          label: this.$t('menu.content'),
          icon: 'Category',
        },
        {
          key: 'company',
          to: { name: 'Company' },
          label: this.$t('menu.company_management'),
          icon: 'Group',
          childrenRoutesName: ['EditCompany'],
          visible: Company.isAdmin(this.currentCompany?.role) && !this.isViewAsOtherUser,
        },
      ].filter((item) => item.visible ?? true);
    },

    navAdminItems() {
      return [
        {
          key: 'companies',
          to: { name: 'AdminCompanies' },
          label: this.$t('menu.companies'),
          icon: 'Building',
          childrenRoutesName: ['AdminCompanyShow', 'AdminCompanyEdit'],
        },
        {
          key: 'groups',
          to: { name: 'Groups' },
          label: this.$t('menu.companies_group'),
          icon: 'Buildings',
          childrenRoutesName: ['EditGroups'],
        },
        {
          key: 'users',
          to: { name: 'Users' },
          label: this.$t('menu.users'),
          icon: 'Account',
          childrenRoutesName: ['UserCompanies'],
        },
        {
          key: 'logs',
          to: { name: 'Logs' },
          label: this.$t('menu.logs'),
          icon: 'TimeHistory',
        },
        {
          key: 'access',
          to: { name: 'Access' },
          label: this.$t('menu.fast_access'),
          icon: 'Lock',
        },
        {
          key: 'portal_management',
          to: { name: 'PortalManagement' },
          label: this.$t('menu.portal_management'),
          icon: 'Settings',
        },
        {
          key: 'server_settings',
          to: { name: 'ServerSettings' },
          label: this.$t('menu.server_settings'),
          icon: 'FileSettings',
        },
        {
          key: 'integration_activity',
          to: { name: 'IntegrationActivity' },
          label: this.$t('menu.integration_activity'),
          icon: 'Category',
        },
      ];
    },

    navAdminConfigItems() {
      return [
        {
          key: 'administrators',
          to: { name: 'Administrators' },
          label: this.$t('menu.administrators'),
          icon: 'Crown',
          childrenRoutesName: ['EditAdministrator'],
          visible: this.isGlobalAdmin,
        },
        {
          key: 'credentials',
          to: { name: 'Credentials' },
          label: this.$t('menu.credentials'),
          icon: 'Key',
          childrenRoutesName: ['EditMasterKeys'],
          visible: this.isGlobalAdmin,
        },
      ].filter((item) => item.visible);
    },

    classes() {
      return {
        'app-sidebar--open': this.navMobileOpen,
      };
    },

    helpLink() {
      return this.$t('menu.help_link');
    },
  },

  methods: {
    toggleSidebar() {
      this.$store.dispatch('updateNavOpen', !this.navOpen);
    },
    toggleMobileNav() {
      this.$store.dispatch('updateNavMobileOpen', !this.navMobileOpen);
    },
    isActive(routeItem) {
      return (
        routeItem.to.name === this.$route.name ||
        this.$route.matched.some((route) => route.name === routeItem.to.name) ||
        routeItem.childrenRoutesName?.includes(String(this.$route.name))
      );
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.app-sidebar {
  position: relative;
  display: flex;
  align-items: stretch;

  &__scroll-container {
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  &__scroll-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.5);
    z-index: 1000;
  }

  @media (max-width: 1000px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    z-index: 1001;
    transition: all 230ms ease-out;

    &--open {
      transform: translateX(0%);
    }
  }

  &__toggler {
    position: absolute;
    top: 45px;
    right: 0;
    transform: translate(50%, -50%);
  }

  &__logotype {
    background: transparent url('@/assets/images/bibook_logo_white.svg') center left no-repeat;
    background-size: auto 100%;
    width: 120px;
    height: 20px;
    transition: all 0.23s ease-out;
  }

  .ui-sidebar--collapsed &__logotype {
    width: 36px;
    height: 18px;
  }

  &__support {
    overflow: hidden;
    width: var(--ui-sidebar--width);
    transition: all 0.23s ease-out;
  }

  .ui-sidebar--collapsed &__support {
    width: var(--ui-sidebar--collapsed-width);
  }

  &__support-container {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.23s ease-out;
  }

  .ui-sidebar--collapsed &__support-container {
    padding: 1rem 18px;
    gap: 2rem;
  }

  &__support-icon.ui-button {
    flex-shrink: 0;
    background: white;
    color: var(--bb-primary);
    border-color: white;
    transition: none;

    &:hover {
      background: white;
      box-shadow: 0 0 0 3px rgba(white, 0.5);
    }
  }

  &__support-label {
    transition: all 0.23s ease-out;

    a,
    a:hover {
      color: inherit;
    }
  }

  .ui-sidebar--collapsed &__support-label {
    opacity: 0;
  }
}
</style>
