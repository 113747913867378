<template lang="pug">
.system-banner-card
  .system-banner-card__col-settings
    ShowView(
      v-if="!isEdit"
      :banner="banner"
      @toggleEdit="toggleEdit"
      @delete="$emit('delete')"
    )
    EditView(
      v-else
      v-model="bannerForm"
      :isPortalManagementPage="isPortalManagementPage"
      @toggleEdit="toggleEdit"
      @cancel="cancelEdit"
      @delete="$emit('delete')"
      @save="$emit('save')"
    )
  .system-banner-card__col-preview
    .system-banner-card__preview-title(v-if="mobileView") {{ $t('system_banners.preview') }}
    SystemBannerPreview(:banner="bannerForm" :isPreview="true")
</template>

<script>
import { mapGetters } from 'vuex';
import ShowView from '@/components/system-banner/components/show-view.vue';
import EditView from '@/components/system-banner/components/edit-view.vue';
import SystemBannerPreview from '@/components/system-banner/system-banner.vue';

function getForm() {
  return {
    user_roles: [],
    banner_type: null,
    description: null,
    dismissible: false,
    descriptionLength: null,
  };
}

export default {
  components: {
    ShowView,
    EditView,
    SystemBannerPreview,
  },
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    isPortalManagementPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
      bannerForm: getForm(),
    };
  },
  computed: {
    ...mapGetters(['mobileView']),
  },
  created() {
    this.bannerForm = { ...this.bannerForm, ...this.banner };
  },
  methods: {
    listRoles(roles) {
      return roles.map((e) => e).join(', ');
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    cancelEdit() {
      this.isEdit = !this.isEdit;
      this.bannerForm = { ...this.bannerForm, ...this.banner };
    },
  },
};
</script>

<style lang="scss" scoped>
.system-banner-card {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  &__col-settings {
    width: 50%;
    margin-right: 40px;
    flex-shrink: 0;
  }

  &__col-preview {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__preview-title {
    color: var(--bb-gray-600);
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .system-banner-card {
    flex-direction: column;
    align-items: stretch;

    &__right-title {
      width: auto;
      margin-bottom: 10px;
    }

    &__col-settings {
      width: auto;
      margin: 0;
    }

    &__col-preview {
      margin-top: 1rem;
    }
  }
}
</style>
