export default class DynamicField {
  constructor(field = {}) {
    this.id = field.id;
    this.attr_type = field.attr_type || 'string';

    this.label = {
      en: field.label?.en || '',
      fi: field.label?.fi || '',
      sv: field.label?.sv || '',
    };
    this.placeholder = {
      en: field.placeholder?.en || '',
      fi: field.placeholder?.fi || '',
      sv: field.placeholder?.sv || '',
    };
    this.attr_default = this.getAttrDefault(field.attr_default);
    this.value_validations = field.value_validations || { presence: false, email: false };
    this.attr_name = field.attr_name || this.label.en;

    this._data = {
      options: DynamicField.normalizeOptions(field.data?.options),
      search: field.data?.search || false,
      multiselect: field.data?.multiselect || false,
    };
  }

  get data() {
    if (this.attr_type === 'dropdown') {
      return this._data;
    }
    return null;
  }

  // todo make it private on ts
  static normalizeOptions(options) {
    if (!options) {
      return [DynamicField.createOption()];
    }
    return options.map(DynamicField.createOption);
  }

  // todo make it private on ts
  getAttrDefault(attrDefault) {
    switch (this.attr_type) {
      case 'boolean':
        return typeof attrDefault === 'boolean' ? attrDefault : false;
      case 'integer':
        return typeof attrDefault === 'number' ? attrDefault : 0;
      default:
        return attrDefault;
    }
  }

  // todo make it private on ts
  getTrimmedData() {
    if (!this.data) return undefined;
    return {
      ...this.data,
      options: DynamicField.normalizeOptions(
        this.data.options?.filter((option) => option.value && option.label.en),
      ),
    };
  }

  static createOption(option = {}) {
    return {
      label: {
        en: option.label?.en || '',
        fi: option.label?.fi || '',
        sv: option.label?.sv || '',
      },
      value: option.value || '',
    };
  }

  addEmptyOption() {
    if (this.data?.options) {
      this.data.options.push(DynamicField.createOption());
    }
  }

  toJSON() {
    return {
      id: this.id,
      attr_name: this.attr_name,
      attr_type: this.attr_type,
      label: this.label,
      attr_default: this.attr_default,
      value_validations: this.value_validations,
      placeholder: this.placeholder,
      data: this.getTrimmedData(),
    };
  }
}
