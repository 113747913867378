<template lang="pug">
UiModal(
  :title="$t('actions.add_report')"
  :show="show"
  :rightActions="rightActions"
  width="480"
  @close="close"
)
  Stack(vertical align="stretch")
    StackItem(v-if="error")
      UiAlert(variant="error" @close="error = null") {{ error }}
    StackItem
      ReportForm(
        v-if="form"
        :form="form"
        :v$="v$"
        @setReportValidStatus="(status) => setReportValidStatus(status)"
      )
</template>

<script>
import Report from '@/models/Report.js';
import ReportForm from '@/components/Report/Form.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import reportModalMixin from './report-modal-mixin.js';

export default {
  name: 'AddReportModal',
  components: {
    ReportForm,
  },
  mixins: [reportModalMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: null,
      isValidReports: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser']),

    rightActions() {
      return [
        {
          label: this.$t('actions.add'),
          onClick: this.submit,
          disabled: this.inProgress || !this.isValidReports || this.isViewAsOtherUser,
          loading: this.inProgress,
        },
      ];
    },
  },
  methods: {
    open({ parentFolderId } = {}) {
      this.v$.$reset();
      this.error = null;
      this.form = new Report({ report_group_id: parentFolderId });
      this.show = true;
    },

    close() {
      this.show = false;
    },

    async submit() {
      this.error = null;
      const valid = await this.v$.$validate();

      if (!valid) {
        return;
      }

      this.inProgress = true;
      const {
        name,
        report_url,
        visible_in_analytics,
        row_level_security,
        rls_rule_name,
        personal,
        editing,
        report_type,
        report_id,
        report_group_id,
        reports,
        pages,
      } = this.form;

      const params = {
        report_url,
        visible_in_analytics,
        row_level_security,
        rls_rule_name,
        personal,
        editing,
        report_type,
        report_id,
        report_group_id,
        reports,
        pages,
        report_name: name,
      };

      if (params.report_type === 'link_to_page') {
        params.pages = [pages];
      }

      new PowerBiReports()
        .addReport({ power_bi_report: params })
        .then(() => {
          this.$emit('done');
          this.close();
        })
        .catch((error) => {
          this.error = error.response?.data?.error;
        })
        .finally(() => {
          this.inProgress = false;
        });
    },

    setReportValidStatus(status) {
      this.isValidReports = status;
    },
  },
};
</script>
