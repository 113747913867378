import { i18n } from '@/localization';
import isErrorCodeString from './is-error-code-string';

export default {
  install: (app, options) => {
    app.config.globalProperties.$localizeErrorMessage = (error, forceFallback = true) => {
      const errorMessage = error?.response?.data?.error ?? error;

      if (!errorMessage && !forceFallback) {
        return null;
      }

      if (typeof errorMessage === 'string' && !isErrorCodeString(errorMessage)) {
        return errorMessage;
      }

      return (
        (i18n.global.te(`errors.${errorMessage}`) && i18n.global.t(`errors.${errorMessage}`)) ||
        i18n.global.t('errors.something_went_wrong')
      );
    };
  },
};
