import AppBaseModel from './AppBaseModel';

class PowerBiReports extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'power_bi_reports',
      pluralKey: 'power_bi_reports',
      singularKey: 'power_bi_report',
      ...options,
    };
    super(mergedOptions, item);
  }

  refresh(data, config) {
    return this.httpClient.put(`${this.entityUrl}/refresh`, data, config);
  }

  resetRefresh(data, config) {
    return this.httpClient.put(`${this.entityUrl}/reset_refresh`, data, config);
  }

  addReport(params) {
    return this.httpClient.post(`${this.entityUrl}/add_report`, params);
  }

  export(data, config) {
    return this.httpClient.post(`${this.entityUrl}/export`, data, config);
  }
}

export default PowerBiReports;
