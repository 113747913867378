<template lang="pug">
div
  UiBreadcrumbs(:items="breadcrumbs")
.tab-container__row
  .tab-container__column.tab-container__column-form
    .colors-form
      Stack(vertical align="stretch")
        StackItem
          .themes-section-header
            UiText(variant="headingMd") {{ $t('portal_management.ui_themes.add_new_theme') }}
        StackItem
          UiFormInput(
            v-model="v$.form.name.$model"
            :label="$t('portal_management.ui_themes.theme_name')"
            :vuelidateModel="v$.form.name"
          )
          br
          // todo use the CustomColorPicker component
          p {{ $t('portal_management.ui_themes.select.custom_description') }}
          .custom-color(v-for="color in customColors")
            .custom-color__swatch
              ColorPicker(
                :color="this.form.colors[color.key]"
                @change="setFormColors({ [color.key]: $event })"
                @cancel="setFormColors({ [color.key]: $event })"
              )
                template(#activator="{ color }")
                  .color-preview.color-preview--lg(:style="{ backgroundColor: color }")
            .custom-color__details
              .custom-color__name {{ color.name }}
              .custom-color__description {{ color.description }}
        StackItem
          .themes-section-header.themes-section-header__analytics
            UiText(variant="headingMd") {{ $t('portal_management.ui_themes.analytics_report_colors') }}
        StackItem
          // todo use the CustomColorPicker component
          .custom-color(v-for="color in customAnalyticsColors")
            .custom-color__swatch
              ColorPicker(
                :color="this.form.colors[color.key]"
                @change="setFormColors({ [color.key]: $event })"
                @cancel="setFormColors({ [color.key]: $event })"
              )
                template(#activator="{ color }")
                  .color-preview.color-preview--lg(:style="{ backgroundColor: color }")
            .custom-color__details
              .custom-color__name {{ color.name }}
              .custom-color__description {{ color.description }}
        StackItem
          UiButton.w-165(
            :disabled="inProgress"
            :loading="inProgress"
            @click="save"
          ) {{ $t('actions.save') }}
  .tab-container__column.tab-container__column-preview
    .container-preview
      .themes-section-header
        UiText(variant="headingMd") {{ $t('portal_management.ui_themes.theme_preview') }}
        UiText {{ form.name }}
      UiCard.theme-preview-card
        ThemePreview(ref="themePreview")
        ThemeGenerator(
          v-if="target"
          :target="target"
          :colors="previewColors"
        )
    .container-preview
      .themes-section-header
        UiText(variant="headingMd") {{ $t('portal_management.ui_themes.analytics_preview') }}
      UiCard.theme-preview-card
        AnalyticsThemePreview(ref="analyticsThemePreview")
        ThemeGenerator(
          v-if="targetAnalytics"
          :target="targetAnalytics"
          :colors="previewColors"
        )
</template>

<script>
import ThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/index.vue';
import AnalyticsThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/preview-analytics.vue';
import ThemeGenerator from '@/components/ThemeGenerator/ThemeGenerator.vue';
import ConfirmModal from '@/modals/common/Confirm.vue';
import { DEFAULT_THEME_COLORS } from '@/helper/ui-themes.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import ColorPicker from '@/components/ColorPicker.vue';
import UiThemes from '@/api/models/UiThemes.js';
import { ref } from 'vue';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import { HTTP_VALIDATION_ERROR } from '@/helper/request-statuses.js';

export default {
  components: { ThemeGenerator, ThemePreview, AnalyticsThemePreview, ColorPicker, ConfirmModal },
  setup() {
    const externalResults = ref({ form: {} });
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },
  data() {
    return {
      loading: false,
      inProgress: false,
      target: null,
      targetAnalytics: null,
      form: {
        name: '',
        colors: { ...DEFAULT_THEME_COLORS },
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  computed: {
    backRoute() {
      return {
        name: this.$route.name,
        query: {
          tab: this.$route.query.tab,
        },
      };
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('portal_management.ui_themes.app_color_scheme'),
          to: this.backRoute,
        },
        {
          label: this.$t('portal_management.ui_themes.add_new_theme'),
        },
      ];
    },
    customColors() {
      return [
        {
          name: this.$t('portal_management.ui_themes.colors.accent_color'),
          description: this.$t('portal_management.ui_themes.colors.accent_color_desc'),
          key: 'primary',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.success_color'),
          description: this.$t('portal_management.ui_themes.colors.success_color_desc'),
          key: 'success',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.warning_color'),
          description: this.$t('portal_management.ui_themes.colors.warning_color_desc'),
          key: 'warning',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.danger_color'),
          description: this.$t('portal_management.ui_themes.colors.danger_color_desc'),
          key: 'danger',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.info_color'),
          description: this.$t('portal_management.ui_themes.colors.info_color_desc'),
          key: 'info',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.text_color'),
          description: this.$t('portal_management.ui_themes.colors.text_color_desc'),
          key: 'text_color',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_1'),
          description: this.$t('portal_management.ui_themes.colors.bg_1_desc'),
          key: 'gray600',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_2'),
          description: this.$t('portal_management.ui_themes.colors.bg_2_desc'),
          key: 'app_bg',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.bg_3'),
          description: this.$t('portal_management.ui_themes.colors.bg_3_desc'),
          key: 'app_bg_main',
        },
      ];
    },
    customAnalyticsColors() {
      return [
        {
          name: this.$t('portal_management.ui_themes.colors.analytic_bg'),
          description: this.$t('portal_management.ui_themes.colors.analytic_bg_desc'),
          key: 'analytic_bg',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.report_color'),
          description: this.$t('portal_management.ui_themes.colors.report_color_desc'),
          key: 'analytic_report',
        },
        {
          name: this.$t('portal_management.ui_themes.colors.analytic_highlight'),
          description: this.$t('portal_management.ui_themes.colors.analytic_highlight_desc'),
          key: 'analytic_highlight',
        },
      ];
    },
    previewColors() {
      return this.form.colors ?? { ...DEFAULT_THEME_COLORS };
    },
  },
  mounted() {
    this.target = this.$refs.themePreview.$el;
    this.targetAnalytics = this.$refs.analyticsThemePreview.$el;
  },
  methods: {
    setFormColors(colors) {
      Object.keys(colors).forEach((key) => {
        if (key in DEFAULT_THEME_COLORS) {
          this.form.colors[key] = colors[key] || DEFAULT_THEME_COLORS[key];
        }
      });
    },
    async save() {
      const valid = await this.v$.$validate();
      if (!valid) {
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$t('validations.check_all_fields'),
        });
        return;
      }
      try {
        this.inProgress = true;
        await new UiThemes({ adminApi: true }).create({
          name: this.form.name,
          data: {
            colors: this.form.colors,
          },
        });
        this.$toaster.add({
          message: this.$t('portal_management.ui_themes.toasts.successfully_created'),
        });
        this.$router.push(this.backRoute);
      } catch (error) {
        if (error.response?.data?.errors && error.response.status === HTTP_VALIDATION_ERROR) {
          this.externalResults = { form: parseResponseErrors(error.response.data.errors) };
          this.$toaster.add({
            type: 'error',
            title: 'Error',
            message: this.$t('validations.check_all_fields'),
          });
        } else {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        }
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables';

.themes-section-header {
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__analytics {
    .ui-text {
      font-weight: 600;
    }
  }
}

.colors-form {
  width: 440px;
}

.container-preview {
  & + & {
    margin-top: 40px;
  }
}

.theme-item-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 1px;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &__check,
  &__actions {
    flex-shrink: 0;
  }
}

.theme-item-analytics {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;

  &__title {
    font-size: 14px;
    margin-right: 36px;
  }
}

.theme-item {
  display: flex;
  gap: 10px;

  &--active {
    background: rgba(var(--bb-primary-rgb), 0.2);
    border-color: var(--bb-primary);
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__wrapper-colors {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__body {
    flex-shrink: 1;
  }
}

.theme-item__colors {
  display: flex;
  gap: 4px;
}

.color-preview {
  box-shadow: inset 0 0 0 1px rgba(black, 0.1), 0 0 0 1px rgba(white, 0.25);
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;

  &--lg {
    width: 4rem;
    height: 4rem;
  }
}

.theme-preview-card {
  overflow: hidden;
}

.btn-actions {
  display: flex;
  gap: 10px;
}

.save-btns {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.tab-container {
  padding: 15px;

  &__row {
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }
  }

  &__column {
    flex-grow: 1;

    &-form {
      flex-shrink: 0;
    }

    &-preview {
      flex-shrink: 1;
    }
  }
}

.custom-color {
  display: flex;
  gap: 20px;
  align-items: center;

  & + & {
    margin-top: 15px;
    line-height: 1.2;
  }

  &__swatch {
    cursor: pointer;
  }

  &__description {
    margin-top: 0.25em;
    font-size: 0.9em;
    line-height: 1.2;
    opacity: 0.5;
  }
}

.colors-demo {
  display: flex;
  gap: 10px;

  &__item {
    border: 1px solid var(--bb-gray-800);
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;

    &--big {
      width: 3rem;
      height: 3rem;
    }
  }
}

.checkbox-description {
  padding-left: 10px;
  margin: 0;
  line-height: 20px;
  font-size: 1rem;
}
</style>
