class RedirectService {
  static generateLoginProviderURL(path, external) {
    const url = new URL(`${window.location.protocol}//${window.location.host}${path}`);
    if (external) {
      url.searchParams.set('redirect', 'false');
    }
    return url.href;
  }

  static toLoginProvider(path, external) {
    window.location = RedirectService.generateLoginProviderURL(path, external);
  }

  // TODO: recheck it
  static toLogin(errorCode) {
    const url = new URL(`${window.location.protocol}//${window.location.host}/login`);
    if (errorCode) {
      url.searchParams.set('error', errorCode);
    }
    window.location = url.href;
  }
}

export default RedirectService;
