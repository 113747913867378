<template lang="pug">
.step-actions
  div
  UiButton(
    variant="ghost"
    icon="CloseIcon"
    data-test-id="button-cancel"
    @click="$emit('cancel')"
  ) {{ $t('actions.cancel') }}
.step-container
  .step-header
    .step-title {{ $t('onboarding.login.title') }}
  .login-form-container
    LoginForm(@authorized="removeLoginAttempt")
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
  props: {
    attemptId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form-container {
  max-width: 330px;
  margin: 0 auto;
}
</style>
