<template lang="pug">
Modal(
  :show="show"
  :title="$t('groups.create_modal.title')"
  :close="close"
  :width="'410px'"
  footer-pos="space-between"
)
  template(#body)
    UiFormInput(
      v-model="form.name"
      :label="$t('groups.create_modal.name')"
      :placeholder="$t('groups.create_modal.name_placeholder')"
      :vuelidate-model="v$.form.name"
    )
  template(#footer)
    UiButton(variant="outline-primary" @click="close") {{ $t('groups.create_modal.btn_cancel') }}
    UiButton(
      :loading="inProgress"
      :disabled="inProgress"
      @click="save"
    ) {{ $t('groups.create_modal.btn_create') }}
</template>

<script>
import CompanyGroups from '@/api/models/CompanyGroups.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';

export default {
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: {
        name: '',
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.form = {
        name: '',
      };
      this.show = false;
      this.$emit('wasClose');
      this.v$.$reset();
    },

    save() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.inProgress = true;
        new CompanyGroups()
          .create(this.form)
          .then(() => {
            this.close();
            this.$emit('wasCreated');
          })
          .catch((error) => {
            this.$toaster.add({
              type: 'error',
              message: this.$localizeErrorMessage(error),
            });
          })
          .finally(() => {
            this.inProgress = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
}
.input-wrap {
  margin-bottom: 20px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
}
</style>
