<template lang="pug">
modal(
  :title="$t('company.integrations.integration_errors')"
  :subtitle="$t('company.integrations.integration_errors_description')"
  :show="show"
  :close="close"
)
  template(#body)
    template(v-if="!errors.length")
      .py-5.text-center
        .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
        .text-secondary {{ $t('rest.empty') }}
    template(v-else)
      #errors-list-start
      .pb-3
        UiStack
          UiStackItem
            UiPagination(
              v-model="pagination.page"
              :per-page="pagination.per_page"
              :total-items="filteredItems.length"
            )
          UiStackItem(fill)
          UiStackItem
            UiMultiselect(
              v-model="pagination.per_page"
              :options="perPageOptions"
              :canClear="false"
              :canDeselect="false"
              size="xs"
            )

      .error-block(v-for="(error, index) in items" :key="error.id")
        .error-block__title
          UiStack.align-items-center
            UiStackItem(fill)
              h6 \#{{ error.id }} {{ error.software }}
            UiStackItem
              UiBadge(variant="error") {{ $d(new Date(error.created_at), 'long') }}
        .error-block__body {{ this.renderErrorMessage(error) }}

      .pt-3
        UiStack
          UiStackItem
            UiPagination(
              v-model="pagination.page"
              :per-page="pagination.per_page"
              :total-items="filteredItems.length"
            )
          UiStackItem(fill)
          UiStackItem
            UiMultiselect(
              v-model="pagination.per_page"
              :options="perPageOptions"
              :canClear="false"
              :canDeselect="false"
              size="xs"
            )
</template>

<script>
import Pagination from '@/models/Pagination.js';

function form() {
  return {
    software: 'netvisor',
  };
}

export default {
  name: 'CompanyIntegrationErrorsModal',

  props: {
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      show: false,
      inProgress: false,
      pagination: new Pagination({
        page: 1,
        per_page: 10,
      }),
      perPageOptions: [5, 10, 15, 25, 50, 100],
      startDate: null,
      endDate: null,
      options: {
        format: 'DD/MM/YYYY',
        showClear: true,
      },
    };
  },

  computed: {
    pageOptions() {
      const pages = [];
      const pagesCount = Math.ceil(this.filteredItems.length / this.pagination.per_page);
      for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
      }
      if (this.pagination.page > pages[pages.length - 1]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.page = pages[pages.length - 1]; // todo refactor side-effects-in-computed-properties
      }
      return pages;
    },

    filteredItems() {
      return this.errors.filter((d) => {
        const moreThen = this.startDate
          ? new Date(this.startDate).getTime() <= d.created_at.getTime()
          : true;
        const lessThen = this.endDate
          ? new Date(this.endDate).getTime() >= d.created_at.getTime()
          : true;
        return moreThen && lessThen;
      });
    },

    paginateItems() {
      const { page, per_page } = this.pagination;
      const startIndex = (page - 1) * per_page;
      const endIndex = startIndex + per_page;
      return this.filteredItems.slice(startIndex, endIndex);
    },

    items() {
      return this.paginateItems;
    },
  },

  watch: {
    pagination: {
      deep: true,
      handler() {
        this.toListStart();
      },
    },
  },

  methods: {
    open() {
      this.form = form();
      this.show = true;
    },

    close() {
      this.show = false;
    },

    toListStart() {
      setTimeout(() => {
        document.getElementById('errors-list-start').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, 100);
    },

    renderErrorMessage(error) {
      const key = `errors.${error.error}`;
      return key !== this.$t(`errors.${error.error}`)
        ? this.$t(`errors.${error.error}`)
        : error.error;
    },
  },
};
</script>

<style lang="scss">
@use 'sass:color';
@import '@/assets/stylesheet/variables';

.pb-3 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.error-block {
  $color: color.mix($white, $color-error, 80%);
  font-size: 0.75rem;

  & + & {
    margin-top: 10px;
  }

  &__title {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    background: $color;

    h6 {
      color: $color-error;
      margin: 0;
      line-height: 1;
    }
  }

  &__body {
    padding: 0.75rem;
    overflow-wrap: break-word;
    border: 1px solid $color;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace;
  }
}
</style>
