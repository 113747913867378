<template lang="pug">
.step-container.step-container--tall
  .step-header
    .step-title {{ $t('onboarding.integration_exist.title', { integration_name: integrationName, company_name: targetCompany.name }) }}
  Stack(justify="center")
    StackItem
      UiButton(
        variant="outline-primary"
        :disabled="inProgress"
        data-test-id="button-cancel"
        @click="$emit('cancel')"
      ) {{ $t('onboarding.integration_exist.action_cancel') }}
    StackItem
      UiButton(
        :disabled="inProgress"
        data-test-id="button-submit"
        @click="submit"
      ) {{ $t('onboarding.integration_exist.action_override_it') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import OnboardingErrorType from '../OnboardingErrorType.js';
import OnboardingStep from '../OnboardingStep.js';

export default {
  props: {
    attempt: {
      type: Object,
      required: true,
    },
    integrationName: {
      type: String,
      default: null,
    },
    targetCompany: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    async submit() {
      try {
        await new IntegrationAttempts(null, {
          id: this.attempt.id,
        }).updateIntegration();
      } catch (e) {
        if (e.response.data?.error === OnboardingErrorType.CAN_NOT_FIND_USER_OR_COMPANY) {
          this.$emit('to-step', OnboardingStep.CAN_NOT_FIND_USER_OR_COMPANY);
        }
        return;
      }
      this.$emit('to-step', OnboardingStep.SUCCESS_CONNECTION);
      this.$emit('progress', 100);
    },
  },
};
</script>
