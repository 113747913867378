import _set from 'lodash/set.js';
import isErrorCodeString from './is-error-code-string';

function parseResponseErrors(errorsObj) {
  const result = {};
  for (const key in errorsObj) {
    if (errorsObj.hasOwnProperty(key)) {
      const parsedKey = key.split('.');
      const normalizedErrors = [errorsObj[key]].flat().map((str) => {
        const suspect = str.toLowerCase();
        if (isErrorCodeString(suspect)) {
          return `errors.${suspect}`;
        }
        return str;
      });
      _set(result, parsedKey, normalizedErrors);
    }
  }
  return result;
}

export default parseResponseErrors;
