<template lang="pug">
.company-switcher(v-if="!isAdmin && currentCompany")
  UiPopover(
    :placement="mobileView ? 'bottom' : 'bottom-start'"
    :offset="mobileView ? [0, 6] : [-20, 6]"
    :hideOnClick="false"
    closeOnToggle
  )
    template(#activator="{ show }")
      UiButton.company-switcher__activator(
        ref="activator"
        variant="ghost"
        border="smooth"
        size="lg"
        :active="show"
        @click.once="handleFetchCompanies"
      )
        Stack(align="center")
          template(v-if="currentCompany.logo && !mobileView")
            StackItem
              img.company-switcher__logo(:src="currentCompany.logo")
            StackItem
              .company-switcher__dot
          StackItem {{ currentCompany.name }}
          StackItem
            UiIcon.company-switcher__arrow(
              v-if="companiesCount !== 0"
              name="ChevroneBottom"
              :class="{ active: show }"
            )
    UiOptionsMenu.company-switcher__dropdown
      .company-switcher__search-container
        UiInput(
          v-model="search"
          icon="Search"
          :placeholder="$t('company.search_company')"
        )
      UiOptionsMenuDivider
      .company-switcher__list
        UiOptionsMenuItem(
          v-for="(company, index) in companiesList"
          :key="index"
          @click="() => switchCompany(company.id)"
        )
          .switch-company-item
            .switch-company-item__left
              img(v-if="company.mother_company" :src="icons.mother")
              span {{ company.name }}
            .switch-company-item__right
              UiBadge {{ $t(`company.role.${company.role}`) }}
        InfiniteLoading(:loading="loading" @load="fetchMoreCompanies")
</template>

<script>
import { mapGetters } from 'vuex';
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import UserCompanies from '@/api/models/UserCompanies.js';
import motherIcon from '@/assets/images/mother-company.svg';
import mergeCollections from '@/common/merge-collections.js';

export default {
  components: {
    MiniDropdown,
    InfiniteLoading,
  },

  data() {
    return {
      showDropdown: false,
      search: null,
      meta: null,
      currentPage: 1,
      perPage: 10,
      loading: false,
      abortController: null,
      request: null,
    };
  },

  computed: {
    ...mapGetters('company', ['companies', 'currentCompany']),
    ...mapGetters(['mobileView', 'isAdmin', 'companiesCount']),

    icons() {
      return {
        mother: motherIcon,
      };
    },

    companiesList() {
      return this.companies.map((company) => {
        if (company.id === this.currentCompany.id) {
          return {
            ...company,
            ...this.currentCompany,
          };
        }
        return company;
      });
    },
  },

  watch: {
    search() {
      this.currentPage = 1;
      this.meta = null;
      this.$store.commit('company/UPDATE_COMPANY_LIST', []);
      this.handleFetchCompanies();
    },
  },

  methods: {
    switchCompany(id) {
      localStorage.setItem('files_folder_id', null);
      localStorage.setItem('report_folder_id', null);
      this.$store.dispatch('company/switchCompany', id);
      this.search = null;
      this.showDropdown = false;
    },

    closeDropdown() {
      this.showDropdown = false;
    },

    show() {
      this.showDropdown = true;
    },

    async handleFetchCompanies() {
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }
      if (this.request) {
        await this.request;
      }
      this.request = this.fetchCompanies();
    },

    async fetchCompanies() {
      this.abortController = new AbortController();

      this.loading = true;
      try {
        const response = await new UserCompanies().fetchAll(
          {
            page: this.currentPage,
            per_page: this.perPage,
            search: this.search,
          },
          {
            signal: this.abortController.signal,
          },
        );
        const { companies, meta } = response.data;
        this.meta = meta;
        this.$store.commit(
          'company/UPDATE_COMPANY_LIST',
          mergeCollections(this.companies, companies),
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    fetchMoreCompanies() {
      if (this.loading || this.companies.length >= (this.meta?.total_count ?? 0)) {
        return;
      }
      this.currentPage += 1;
      this.handleFetchCompanies();
    },
  },
};
</script>

<style lang="scss">
.company-switcher {
  align-self: center;
  font-weight: bold;
  cursor: pointer;

  &__logo {
    max-height: 44px;
  }

  &__dot {
    width: 4px;
    height: 4px;
    background-color: #0fb57c;
    border-radius: 2px;
  }

  &__arrow {
    margin-right: -10px;
  }

  &__activator {
    & .ui-button__label {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__dropdown {
    padding-bottom: 0 !important;
    min-width: 400px !important;
  }

  &__search-container {
    padding: 0.5rem 1rem;
  }

  &__list {
    max-height: 300px;
    overflow: auto;
    padding-bottom: 10px;
  }
}

.current-company {
  display: flex;
  align-items: center;

  &__dotted {
    width: 4px;
    height: 4px;
    background-color: var(--bb-primary);
    border-radius: 2px;
    margin: 0 20px;
  }
}

.switch-company-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
  gap: 25px;

  .search {
    width: 100%;
  }

  &__left {
    display: flex;
    align-items: center;

    & img {
      margin-right: 10px;
    }

    & span {
      font-weight: 400;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 500px) {
  .current-company__name {
    font-size: 15px;
  }
}

@media screen and (min-width: 1000px) {
  .company-switcher__dropdown {
    min-width: 430px !important;
  }
}
</style>
