import AppBaseModel from './AppBaseModel';

class AccessTreeEntities extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'access_entities',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default AccessTreeEntities;
