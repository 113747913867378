<template lang="pug">
.sidebar-group-item(
  :class="{ 'sidebar-group-item--active': active }"
  data-test-id="sidebar-group-item"
)
  .sidebar-group-item__icon-container
    slot(name="icon")
  .sidebar-group-item__label
    slot {{ label }}
  .sidebar-group-item__trash(v-if="(isPublicBookmark && isAdmin) || !isPublicBookmark")
    UiTooltip(variant="dark" :title="$t('analytics.action_delete_bookmark')")
      template(#activator)
        UiButton(
          variant="smooth-danger"
          icon="Trash"
          round
          size="sm"
          data-test-id="delete-bookmark-button"
          @click.stop="$emit('deleteBookmark')"
        )
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inheritAttrs: true,
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isPublicBookmark: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['deleteBookmark'],
  computed: {
    ...mapGetters('company', ['isAdmin']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.sidebar-group-item {
  border-radius: 1.4rem;
  cursor: pointer;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 2.8rem;

  & + & {
    margin-top: 1px;
  }

  &--active,
  &:hover {
    background: var(--bb-app-bg-main);
  }

  &--active:hover {
    &--active::after {
      display: none !important;
    }
  }

  &__icon-container {
    width: 24px;
    margin-left: 8px;
    flex-shrink: 0;
  }

  &__label {
    font-size: 16px;
    margin-left: 8px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &--active::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--bb-primary);
    flex-shrink: 0;
    margin: 0 6px;
  }

  &__trash {
    display: none;
    pointer-events: none;
  }

  &:hover &__trash {
    display: block;
    pointer-events: initial;
  }
}

.sidebar-group-item--active:hover {
  &::after {
    display: none !important;
  }
}
</style>
