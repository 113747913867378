<template lang="pug">
UiEmptyState.ui-empty-state(:description="$t(messageKey)" icon="Error")
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    messageKey: {
      type: String,
      default: 'report-crashed',
    },
  },
});
</script>

<style lang="scss" scoped>
.ui-empty-state {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  max-width: 900px;
  white-space: pre-wrap;

  :deep(.ui-empty-state__body) {
    line-height: inherit
  }
}
</style>
