<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460"
  :allowClose="false"
)
  template(#title)
    div(v-html="$t('power_bi_reports.modals.delete.title', { report_name: entity?.name })")
</template>

<script>
import PowerBiReports from '@/api/models/PowerBiReports.js';
import { defineComponent } from 'vue';
import deleteModalMixin from '@/modals/mixins/delete-modal-mixin.js';

export default defineComponent({
  mixins: [deleteModalMixin],
  emits: ['done'],

  data() {
    return {
      show: false,
      inProgress: false,
      entity: null,
    };
  },

  methods: {
    async submit() {
      this.inProgress = true;
      try {
        await new PowerBiReports(null, this.entity).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
});
</script>
