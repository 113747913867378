<template lang="pug">
.admin-layout
  Heading(:title="$t('companies.title')")
    Stack
      StackItem
        UiButton(:round="mobileView" @click="open")
          template(#icon)
            UiIcon(name="PlusFilled")
          | {{ $t('companies.btn_add_company') }}
      StackItem
        UiButton(:round="mobileView" @click="openImportModal")
          template(#icon)
            UiIcon(name="Export")
          | {{ $t('companies.btn_import') }}
  Stack(justify="end")
    StackItem
      UiButton(variant="text-primary" @click="downloadExample")
        template(#icon)
          UiIcon(name="Import" size="16")
        | {{ $t('companies.download_companies_header') }}
  .table-container
    RestTable(
      ref="table"
      namespace="companies"
      :headers="headers"
      :fetchHandler="fetch"
      :searchLabel="$t('companies.search_placeholder')"
      searchable
    )
      template(#cel_name="{ item }")
        router-link(:to="{ name: 'AdminCompany', params: { company_id: item.id } }") {{ item.name }}
      template(v-slot:cel_parent_company="{ item }")
        | {{ item.parent_company?.name || '—' }}
      template(v-slot:cel_group="{ item }")
        | {{ item.group?.name || '—' }}
      template(v-slot:cel_action="{ item }")
        .actions_container
          UiButton(
            :to="{ name: 'AdminCompanyEdit', params: { company_id: item.id } }"
            variant="text-primary"
            icon="Edit"
          )
          UiButton.action_btn(
            icon="Trash"
            variant="text-danger"
            @click="deleteCompany(item.name, item.id)"
          )
ImportCompanyModal(
  ref="importCompanyModal"
  :downloadExample="downloadExample"
  @wasCreated="refreshData"
)
CreateCompanyModal(ref="createCompanyModal" @wasCreated="refreshData")
DeleteCompanyModal(ref="deleteCompanyModal" @wasDeleted="refreshData")
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import CreateCompanyModal from '@/modals/Admin/Companies/Create.vue';
import ImportCompanyModal from '@/modals/Admin/Companies/Import.vue';
import DeleteCompanyModal from '@/modals/Admin/Companies/Delete.vue';
import { generateAndDownloadFile } from '@/common/utils.js';

export default {
  name: 'Companies',
  components: {
    CreateCompanyModal,
    DeleteCompanyModal,
    ImportCompanyModal,
  },

  data() {
    return {
      headers: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('companies.headers.name'),
          sortable: true,
        },
        {
          key: 'parent_company',
          label: this.$t('companies.headers.parent'),
          sortable: true,
        },
        {
          key: 'group',
          label: this.$t('companies.headers.group'),
          sortable: true,
        },
        {
          key: 'business_id',
          label: this.$t('companies.headers.business_id'),
          sortable: true,
        },
        {
          key: 'action',
          label: this.$t('companies.headers.actions'),
          squeeze: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
    ...mapGetters('company', ['company']),
  },
  methods: {
    async fetch(params) {
      try {
        const response = await new Companies({ adminApi: true }).fetchAll({
          search: params.search,
          page: params.page,
          per_page: params.per_page,
          sort_by: params.sort_by,
          sort_order: params.sort_order,
        });
        return {
          meta: response.data.meta,
          items: response.data.companies,
        };
      } catch (error) {
        console.log(error);
        return {};
      }
    },
    deleteCompany(name, id) {
      this.$refs.deleteCompanyModal.open(name, id);
    },
    refreshData() {
      this.$refs.table.fetchData();
    },
    open() {
      this.$refs.createCompanyModal.open();
    },
    openImportModal() {
      this.$refs.importCompanyModal.open();
    },
    downloadExample() {
      generateAndDownloadFile('name,business_id,email', 'text/csv', 'example_companies.csv');
    },
  },
};
</script>

<style scoped lang="scss">
.table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.actions_container {
  display: flex;
  align-items: center;
}

.action_btn {
  cursor: pointer;

  &:last-child {
    margin-left: 25px;
  }
}
</style>
