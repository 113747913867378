<template lang="pug">
.form-wrapper
  UiCardSection(padding="lg")
    Stack(
      vertical
      align="stretch"
      spacing="3xl"
    )
      StackItem
        Stack(
          vertical
          align="stretch"
          spacing="lg"
        )
          StackItem
            UiText(variant="headingMd") {{ $t('edit_company.company.header') }}
          StackItem
            UiInputLabel(size="lg") {{ $t('company.business_id') }}
            UiInput(
              :modelValue="company.business_id"
              placeholder="_ _ _ _ _ _ - _"
              readonly
              data-vi="company.business_id"
              @update:modelValue="change({ business_id: $event })"
            )
          StackItem
            UiInputLabel(size="lg") {{ $t('company.company_name') }}
            UiInput(
              :modelValue="company.name"
              :placeholder="$t('company.company_name')"
              :error="v$.company.name.$error"
              data-vi="company.name"
              @update:modelValue="change({ name: $event })"
              @blur="v$.company.name.$touch"
            )
            UiInputFeedback(v-for="$error in v$.company.name.$errors" error) {{ $error.$message }}
          StackItem
            UiInputLabel(size="lg") {{ $t('edit_company.company.email_label') }}
            UiInput(
              :modelValue="company.email"
              :placeholder="$t('edit_company.company.email_placeholder')"
              :error="v$.company.email.$error"
              data-vi="company.email"
              @update:modelValue="change({ email: $event })"
              @blur="v$.company.email.$touch"
            )
            UiInputFeedback(v-for="$error in v$.company.email.$errors" error) {{ $error.$message }}
          template(v-if="isAdminRoute")
            StackItem(v-for="field in settingFields")
              component(
                :is="field.component"
                :label="$t(`edit_company.company.${field.label || field.fieldName}`)"
                :modelValue="field.modelValue ?? company[field.fieldName]"
                :options="field.options"
                :multiline="field.multiselect"
                :readonly="field.readonly"
                :labelKey="field.options && 'label'"
                :data-vi="`company.${field.fieldName}`"
                @update:modelValue="change({ [field.fieldName]: $event })"
              ) {{ $t(`edit_company.company.${field.label || field.fieldName}`) }}
              UiInputFeedback(v-for="$error in v$.company[field.fieldName]?.$errors" error) {{ $error?.$message }}
            StackItem
              UiInputLabel(size="lg") {{ $t('edit_company.company.analyst_email_label') }}
              UiInput(
                :modelValue="company.analyst_email"
                :placeholder="$t('edit_company.company.analyst_email_placeholder')"
                :error="v$.company.analyst_email.$error"
                data-vi="company.analyst_email"
                @update:modelValue="change({ analyst_email: $event })"
                @blur="v$.company.analyst_email.$touch"
              )
              UiInputFeedback(v-for="$error in v$.company.analyst_email.$errors" error) {{ $error.$message }}
            StackItem
              UiInputLabel(size="lg") {{ $t('edit_company.company.power_bi_group_url_label') }}
              UiInput(
                :modelValue="company.power_bi_group_url"
                :placeholder="$t('edit_company.company.power_bi_group_url_placeholder')"
                :error="v$.company.power_bi_group_url.$error"
                data-vi="company.power_bi_group_url"
                @update:modelValue="change({ power_bi_group_url: $event })"
                @blur="v$.company.power_bi_group_url.$touch"
              )
              UiInputFeedback(v-for="$error in v$.company.power_bi_group_url.$errors" error) {{ $error.$message }}
            StackItem
              UiInputLabel(size="lg") {{ $t('edit_company.company.group') }}
              CompanyGroupsSelect(
                :modelValue="company.group"
                :placeholder="$t('edit_company.company.group_placeholder')"
                data-vi="company.group"
                @update:modelValue="change({ group: $event })"
              )
            StackItem
              UiInputLabel(size="lg") {{ $t('edit_company.company.parent_company') }}
              CompanySelect(
                :modelValue="company.parent_company"
                :placeholder="$t('edit_company.company.parent_company_placeholder')"
                :error="v$.company.parent_company.$error"
                data-vi="company.parent_company"
                @update:modelValue="change({ parent_company: $event })"
              )
      StackItem(v-if="isAdminDomain && dynamicSettingFields.length")
        Stack(
          vertical
          align="stretch"
          spacing="lg"
        )
          StackItem
            UiText(variant="headingMd") {{ $t('edit_company.company.dynamic_settings') }}
          StackItem(v-for="field in dynamicSettingFields")
            component(
              v-bind="field"
              :key="field.attr_name"
              :name="field.attr_name"
              :label="field.label[language]"
              :is="field.component"
              :error="v$.company[field.attr_name]?.$error"
              :modelValue="company[field.attr_name]"
              :data-vi="`company.${field.attr_name}`"
              @update:modelValue="change({ [field.attr_name]: $event })"
              @blur="v$.company[field.attr_name]?.$touch"
            ) {{ field.label[language] }}
            UiInputFeedback(
              v-for="$error in v$.company[field.attr_name]?.$errors"
              error
              data-vi="dynamic-field-error"
            ) {{ $t($error?.$message) }}
      StackItem
        Stack(
          vertical
          align="stretch"
          spacing="lg"
        )
          StackItem
            UiText(variant="headingMd") {{ $t('edit_company.company.ui_settings') }}
          StackItem
            UiInputLabel(size="lg") {{ $t('company.logo') }}
            .company-logo-preview
              input(
                ref="logoSelect"
                :type="'file'"
                name="file"
                accept=".png, .jpg, .jpeg"
                data-vi="company.logo"
                @change="onFileChange"
              )
              .company-logo-preview__stub
                template(v-if="logoPreview")
                  img.logo-preview(:src="logoPreview")
                template(v-else)
                  UiIcon.primary-icon(name="FileLoad" size="50")
                  p {{ $t('company.files.accept_files', { extensions: 'jpg, jpeg, png.' }) }}
                  p {{ $t('company.files.max_file_size', { size: '4MB' }) }}
              UiButton(
                v-if="logoPreview"
                size="sm"
                :style="{ marginTop: '10px' }"
                @click="removeLogo"
              ) {{ $t('company.delete_logo') }}
              UiButton(
                v-else
                size="sm"
                :style="{ marginTop: '10px' }"
                @click="selectLogo"
              ) {{ $t('company.action_change_logo') }}
            UiInputFeedback(v-for="$error in v$.company.logo.$errors" error) {{ $error?.$message }}
          StackItem
            UiCheckbox(
              :modelValue="company.report_language_visible"
              switch
              data-vi="company.report_language_visible"
              @change="change({ report_language_visible: $event })"
            ) {{ $t('company.show_language_selector') }}
          StackItem(v-if="company.report_language_visible")
            UiInputLabel(size="lg") {{ $t('company.reporting_language') }}
            LanguageSelect(
              :modelValue="company.report_language"
              data-vi="company.report_language"
              @change="change({ report_language: $event })"
            )
      StackItem
        Stack(
          vertical
          align="stretch"
          spacing="lg"
        )
          ThemesUpload(v-if="!isAdminRoute" data-vi="company.theme")
          StackItem
            UiCheckbox(
              switch
              :modelValue="company.report_default_theme_visible"
              data-vi="company.report_default_theme_visible"
              @change="change({ report_default_theme_visible: $event })"
            ) {{ $t('company.default_theme_visibility') }}
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect.vue';
import CompanyGroupsSelect from '@/components/CompanyGroupsSelect.vue';
import CompanySelect from '@/components/CompanySelect.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { inject } from 'vue';
import accountTypesOptions from '@/helper/accountTypesOptions.js';
import invoicingMethodsOptions from '@/helper/invoicingMethodsOptions.js';
import ThemesUpload from '@/components/ThemesUpload/ThemesUpload.vue';

export default {
  name: 'TabCompany',
  components: {
    ThemesUpload,
    LanguageSelect,
    CompanyGroupsSelect,
    CompanySelect,
  },
  props: {
    isAdminRoute: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const v$ = inject('v$');
    const dynamicSettingFields = inject('dynamicSettingFields', []);

    const externalResults = inject('externalResults');
    return {
      v$,
      externalResults,
      dynamicSettingFields,
    };
  },

  data() {
    return {
      file: null,
      logoPreview: null,
      showThemeSwitcher: false,
    };
  },

  computed: {
    ...mapState({
      dynamicSettings: (state) => state.index.dynamicSettings,
    }),
    ...mapGetters(['isAdminDomain', 'language']),
    ...mapGetters('editCompany', ['company']),

    settingFields() {
      return [
        {
          component: 'UiFormMultiselect',
          fieldName: 'account_type',
          options: accountTypesOptions.map((item) => ({
            label: this.$t(`account_types.${item}`),
            value: item,
          })),
        },
        {
          component: 'UiFormInput',
          fieldName: 'note',
          label: 'notes',
          multiselect: true,
        },
        {
          component: 'UiFormMultiselect',
          fieldName: 'invoicing_method',
          label: 'main_invoicing_method',
          options: invoicingMethodsOptions.map((item) => ({
            label: this.$t(`invoicing_methods.${item}`),
            value: item,
          })),
        },
        {
          component: 'UiFormInput',
          fieldName: 'invoicing_vat_number',
        },
        {
          component: 'UiFormInput',
          fieldName: 'netsuite_project_id',
        },
        {
          component: 'UiFormInput',
          fieldName: 'netvisor_vat_id',
        },
        {
          component: 'UiCheckbox',
          fieldName: 'reviewed',
        },
        {
          component: 'UiFormMultiselect',
          fieldName: 'revenue_class',
          options: this.revenueClasses,
        },
        {
          component: 'UiFormMultiselect',
          fieldName: 'user_class',
          options: this.userClasses,
        },
        {
          component: 'UiFormInput',
          fieldName: 'user_free_count',
        },
        {
          component: 'UiFormInput',
          fieldName: 'total_allowed_users',
          readonly: true,
          modelValue: (+this.company.user_class || 0) + (+this.company.user_free_count || 0),
        },
        {
          component: 'UiFormInput',
          fieldName: 'user_count',
          readonly: true,
        },
        {
          component: 'UiFormInput',
          fieldName: 'extra_refreshes',
        },
        {
          component: 'UiFormInput',
          fieldName: 'dbuser',
          readonly: true,
        },
      ];
    },
    revenueClasses() {
      return [100, 200, 500, 1000, 2000, 5000, 10000, 25000, 50000, 100000, 1000000];
    },
    userClasses() {
      return [5, 10, 20, 40, 50, 75, 100, 200, 500, 750, 1000, 10000];
    },
  },

  watch: {
    dynamicSettings() {
      this.initDynamicFields();
    },
  },

  created() {
    this.initDynamicFields();
  },

  mounted() {
    this.fetchLogoPreview();
  },

  methods: {
    ...mapMutations('editCompany', ['UPDATE_COMPANY']),

    initDynamicFields() {
      this.dynamicSettings?.forEach(this.initDynamicFieldValue);
    },

    initDynamicFieldValue(field) {
      if (field.attr_type === 'date') {
        let value = null;
        if (this.company[field.attr_name]) {
          value = new Date(this.company[field.attr_name]);
        } else if (field.attr_default) {
          value = new Date(field.attr_default);
        }
        this.change({ [field.attr_name]: value }, true);
      } else if (field.attr_type === 'dropdown' && field.data?.options.multiselect) {
        let value;
        if (this.company[field.attr_name]) {
          value = Array.isArray(this.company[field.attr_name])
            ? this.company[field.attr_name]
            : [this.company[field.attr_name]];
        } else {
          value = field.attr_default ? [field.attr_default] : [];
        }
        this.change({ [field.attr_name]: value }, true);
      } else {
        this.change(
          { [field.attr_name]: this.company[field.attr_name] ?? field.attr_default },
          true,
        );
      }
    },

    change(value, saveErrors = false) {
      this.UPDATE_COMPANY(value);
      if (saveErrors) return;
      for (const key in value) {
        if (this.externalResults?.company?.[key]) {
          this.externalResults.company[key] = [];
        }
      }
    },

    openThemeSwitcher() {
      this.showThemeSwitcher = true;
    },
    closeThemeSwitcher() {
      this.showThemeSwitcher = false;
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.change({
        remove_logo: false,
        logo_file: file,
      });
      this.logoPreview = URL.createObjectURL(file);
    },
    removeLogo() {
      this.change({
        remove_logo: true,
        logo: null,
        logo_file: null,
      });
      this.logoPreview = null;
    },
    selectLogo() {
      this.$refs.logoSelect.click();
    },

    fetchLogoPreview() {
      this.logoPreview = this.company.logo_file
        ? URL.createObjectURL(this.company.logo_file)
        : this.company.logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  max-width: 510px;
}

.company-logo-preview {
  position: relative;

  & input {
    display: none;
  }

  &__stub {
    width: 100%;
    border: 1px solid var(--bb-primary);
    border-radius: 5px;
    background: rgba(41, 187, 137, 0.1);
    background: var(--bb-primary-light-2);
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & p {
      margin: 0;
      font-weight: 400;
    }
  }

  .logo-preview {
    border-radius: 4px;
    max-width: calc(100% - 4px);
    max-height: calc(100% - 4px);
  }
}

.company-info__report-switcher {
  position: relative;
}
</style>
