<template lang="pug">
UiBadge(:variant="variant" size="sm") {{ label }}
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    variant() {
      return this.item.publicly_available ? 'primary' : 'info';
    },
    label() {
      return this.item.publicly_available ? this.$t('status.public') : this.$t('status.private');
    },
  },
};
</script>
