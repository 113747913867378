<template lang="pug">
UiModal(
  :title="$t('report_embedded_links.modals.delete.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460"
  :allowClose="false"
)
  | {{ $t('report_embedded_links.modals.delete.message', { link_name: entity.name }) }}
</template>

<script>
import PowerBiEmbeddedLinks from '@/api/models/PowerBiEmbeddedLinks.js';
import PowerBiReports from '@/api/models/PowerBiReports.js';

export default {
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      reportId: null,
      entity: null,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          onClick: this.submit,
          loading: this.inProgress,
          variant: 'smooth-danger',
        },
      ];
    },
  },
  methods: {
    open({ powerBiReportId, powerBiReportEmbeddedLink }) {
      this.reportId = powerBiReportId;
      this.entity = powerBiReportEmbeddedLink;
      this.inProgress = false;
      this.show = true;
    },
    async submit() {
      this.inProgress = true;
      try {
        await new PowerBiEmbeddedLinks(
          { owner: new PowerBiReports(null, { id: this.reportId }) },
          this.entity,
        ).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>
