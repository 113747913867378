<template lang="pug">
.form-wrapper
  UiCardSection(padding="lg")
    Stack(
      vertical
      align="stretch"
      spacing="2xl"
    )
      StackItem
        UiText(variant="headingMd") 
          | {{ $t('sso.header') }}
      StackItem
        UiFormMultiselect(
          :modelValue="sso_method"
          :canClear="false"
          :canDeselect="false"
          :label="$t('sso.sso_method')"
          customLabelKey="label"
          valueProp="value"
          :options="ssoMethodsOptions"
          :disabled="loading || inProgress"
          size="lg"
          @update:modelValue="onSSOMethodChange"
        )
      component(
        v-model:saml="saml"
        v-model:oidc="oidc"
        :is="methodFormComponent"
        :inProgress="inProgress"
        @submit="submitWithConfirm"
      )
  ConfirmDisableSSOModal(
    ref="confirmDisableSSOModal"
    @confirm="onConfirmDisableSSO"
    @decline="onDeclineDisableSSO"
  )
  ConfirmOverrideSSOModal(ref="confirmOverrideSSOModal" @confirm="onConfirmOverrideSSO")
</template>

<script>
import Companies from '@/api/models/Companies.js';
import SSO from '@/api/models/SSO.js';
import useVuelidate from '@vuelidate/core';
import { mapGetters, mapMutations } from 'vuex';
import ConfirmDisableSSOModal from '@/modals/SSO/confirm-disable-sso-modal.vue';
import ConfirmOverrideSSOModal from '@/modals/SSO/confirm-override-sso-modal.vue';
import SAMLForm from './sso-tab/saml-form.vue';
import OIDCForm from './sso-tab/oidc-form.vue';

const SSOMethod = {
  NONE: 'none',
  SAML: 'saml',
  OIDC: 'oidc',
};

const SSOMethodFormComponent = {
  saml: SAMLForm,
  oidc: OIDCForm,
};

export default {
  name: 'SSOTab',

  components: {
    ConfirmDisableSSOModal,
    ConfirmOverrideSSOModal,
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      loading: false,
      inProgress: false,
      active_sso_method: null,
      previous_sso_method: null,
      sso_method: null,
      saml_manifest: '',
      saml: {
        saml_setup_method: 'manifest',
        saml_manifest: '',
        entity_id: '',
        sso_service_url: '',
        cert: '',
        cert_fingerprint_algorithm: 'SHA1',
        cert_fingerprint: '',
      },
      oidc: {
        endpoint: '',
        client_id: '',
        client_secret: '',
        scope: '',
        email: '',
        provider_name: '',
      },
    };
  },

  validations() {
    return {};
  },

  computed: {
    ...mapGetters(['isAdmin', 'appDomain']),
    ...mapGetters('editCompany', ['company']),

    ssoMethodsOptions() {
      return [
        {
          value: SSOMethod.NONE,
          label: this.$t('sso.method.none'),
        },
        {
          value: SSOMethod.SAML,
          label: this.$t('sso.method.saml'),
        },
        {
          value: SSOMethod.OIDC,
          label: this.$t('sso.method.oidc'),
        },
      ];
    },

    isSSOMethodSelected() {
      return this.sso_method !== SSOMethod.NONE;
    },

    SSOMethod() {
      return SSOMethod;
    },

    methodFormComponent() {
      return SSOMethodFormComponent[this.sso_method] ?? null;
    },
  },

  created() {
    this.fetchSSOState();
  },

  methods: {
    ...mapMutations('editCompany', ['UPDATE_COMPANY']),

    onSSOMethodChange(methodName) {
      this.previous_sso_method = this.sso_method;
      this.sso_method = methodName;
      if (methodName === SSOMethod.NONE && this.active_sso_method !== SSOMethod.NONE) {
        this.$refs.confirmDisableSSOModal.open();
      }
    },

    onConfirmDisableSSO() {
      this.submit();
    },

    onDeclineDisableSSO() {
      this.revertSSOMethod();
    },

    onConfirmOverrideSSO() {
      this.submit();
    },

    revertSSOMethod() {
      this.sso_method = this.previous_sso_method;
    },

    setInitData(data) {
      const { sso_method, saml, oidc } = data;
      this.saml = {
        ...this.saml,
        saml_setup_method: saml ? 'manual' : 'manifest',
        ...saml,
      };
      this.oidc = {
        ...this.oidc,
        ...oidc,
      };
      this.active_sso_method = sso_method;
      this.sso_method = sso_method;
    },

    async fetchSSOState() {
      try {
        this.loading = true;
        const response = await new SSO({
          adminApi: this.isAdmin,
          owner: this.isAdmin && new Companies(null, { id: this.company.id }),
        }).fetch();
        this.setInitData(response.data);
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.loading = false;
      }
    },

    async submitWithConfirm() {
      const validate = await this.v$.$validate();
      if (!validate) return;

      if (this.active_sso_method !== this.sso_method && this.active_sso_method !== SSOMethod.NONE) {
        this.$refs.confirmOverrideSSOModal.open();
      } else {
        this.submit();
      }
    },

    async submit() {
      const payload = {
        sso_method: this.sso_method,
      };

      if (this.sso_method === SSOMethod.SAML) {
        if (this.saml.saml_setup_method === 'manifest') {
          payload.saml_manifest = `data:text/xml;base64,${btoa(this.saml.saml_manifest)}`;
        } else {
          payload.saml = this.saml;
        }
      }

      if (this.sso_method === SSOMethod.OIDC) {
        payload.oidc = this.oidc;
      }

      try {
        this.inProgress = true;
        const response = await new SSO({
          adminApi: this.isAdmin,
          owner: this.isAdmin && new Companies(null, { id: this.company.id }),
        }).update(payload);
        this.setInitData(response.data);
        this.$toaster.add({
          message: this.$t('sso.sso_settings_successfully_updated'),
        });
      } catch (error) {
        if (this.sso_method === SSOMethod.NONE) {
          this.revertSSOMethod();
        }
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper {
  max-width: 826px;
}
</style>
