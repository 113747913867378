import AppBaseModel from './AppBaseModel';

class Dataroom extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'dataroom',
      ...options,
    };
    super(mergedOptions, item);
  }

  refresh(params) {
    return this.httpClient.put(`${this.entityUrl}/refresh`, params);
  }

  resetRefresh(params) {
    return this.httpClient.put(`${this.entityUrl}/reset_refresh`, params);
  }

  fetchTree(params, config) {
    return this.httpClient.get(`${this.url}/tree`, { params, ...config });
  }
}

export default Dataroom;
