<template lang="pug">
.sidebar-group(:class="{ 'sidebar-group--active': active }" data-test-id="sidebar-group")
  .sidebar-group__activator(data-test-id="sidebar-activator" @click="$emit('toggle')")
    .sidebar-group__arrow-container
      UiIcon.sidebar-group__arrow(
        name="ChevroneRight"
        :class="{ 'sidebar-group__arrow--active': active }"
        data-test-id="sidebar-group-icon"
      )
    .sidebar-group__icon-container
      slot(name="icon")
    .sidebar-group__label(
      :class="{ 'sidebar-group__label--active': active }"
      data-test-id="sidebar-group-label"
    )
      slot(name="label") {{ label }}
  .sidebar-group__content(v-if="active" data-test-id="sidebar-group-content")
    slot
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-group {
  border-radius: 20px;

  & + & {
    margin-top: 5px;
  }

  &--active,
  &:hover {
    background: var(--bb-analytic-highlight);
  }

  &__activator {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__arrow-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    transition: transform 230ms;
  }

  &__arrow--active {
    transform: rotate(90deg);
  }

  &__label--active {
    font-weight: 700;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  &__label {
    font-size: 16px;
    margin-left: 8px;
  }

  &__content {
    padding: 0 5px 5px 24px;
  }
}
</style>
