<template lang="pug">
.step-container.step-container--tall
  .step-header
    .step-header__wrapper
      OnboardingSuccessImage.step-header__image
      .step-title {{ $t('onboarding.success.title', { integration_name: integrationName, company_name: targetCompany.name }) }}
      .step-description {{ $t('onboarding.success.description') }}
  Stack(justify="center")
    StackItem
      UiButton(@click="toHome") {{ $t('onboarding.success.action_to_dashboard') }}
</template>

<script>
import OnboardingSuccessImage from '@/assets/images/onboarding-success.vue';
import LocalStorage from '@/common/localstorage.js';

export default {
  components: {
    OnboardingSuccessImage,
  },
  props: {
    integrationName: {
      type: String,
      default: null,
    },
    targetCompany: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    LocalStorage.remove('integration_attempt');
    this.timer = setTimeout(() => this.toHome(), 5000);
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    toHome() {
      window.location = `${window.location.protocol}//${this.targetCompany.company_domain}/`;
    },
  },
};
</script>

<style scoped lang="scss">
.step-header {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    margin-bottom: 42px;
  }
}
</style>
