<template lang="pug">
.dataroom-entry.dataroom-forms
  .dataroom-forms__header
    h2 {{ $t('dataroom.add_form') }}
  .dataroom-entry__container
    .dataroom-entry__right(v-if="form")
      DataroomForm(:form="form" :isAdmin="isAdmin")
      .dataroom-entry__footer
        UiButton(
          :disabled="inProgress || isViewAsOtherUser"
          :loading="inProgress"
          @click="save"
        ) {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import DataroomForm from '@/components/Dataroom/Form.vue';
import Company from '@/models/Company.js';
import DataroomForms from '@/api/models/DataroomForms.js';

export default {
  name: 'DataroomNew',
  components: {
    DataroomForm,
  },

  data() {
    return {
      form: null,
      isAdmin: false,
      inProgress: false,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),
  },

  mounted() {
    this.isAdmin = Company.isAdmin(this.currentCompany.role);
    this.fetchData();
  },

  methods: {
    fetchData() {
      new DataroomForms()
        .getManager()
        .then((resp) => {
          this.form = resp.data.dataroom_form;
        })
        .catch(() => this.$router.push({ name: 'Dataroom' }));
    },

    getData() {
      const res = {};
      this.form.fields.forEach((value) => {
        res[value.name] = this.form.results[value.name];
      });
      return res;
    },

    save() {
      this.inProgress = true;
      new DataroomForms()
        .create(this.getData())
        .then((resp) => {
          let messageData = this.parseSaveMessage(resp.data);
          if (Array.isArray(messageData)) {
            // eslint-disable-next-line prefer-destructuring
            messageData = messageData[0];
          }
          const { success, redirect_url, form_id, error_message } = messageData;
          if (success) {
            if (form_id) {
              this.$router.push({
                name: 'DataroomView',
                params: { id: form_id },
              });
            } else if (redirect_url) {
              window.location.href = redirect_url;
            } else {
              this.$router.push({ name: 'Dataroom' });
            }
          } else {
            this.$toaster.add({
              type: 'error',
              message: error_message,
            });
          }
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },

    parseSaveMessage(data) {
      const { message, success } = data;
      if (typeof message === 'object') return message;

      if (message.length) {
        try {
          return JSON.parse(message);
        } catch {
          return { success };
        }
      }
      return { success };
    },
  },
};
</script>
