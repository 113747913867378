import AppBaseModel from '@/api/models/AppBaseModel';

class ServerSettings extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'server_settings',
      singularKey: 'server_settings',
      ...options,
    };
    super(mergedOptions, item);
  }
}
export default ServerSettings;
