<template lang="pug">
modal(
  :title="withError ? $t('company.integrations.refresh_error') : $t('company.integrations.data_refresh')"
  :subtitle="withError ? $t('company.integrations.refresh_error_description') : null"
  :show="show"
  :close="close"
)
  template(#body)
    div(:style="{ padding: '0 20px' }")
      template(v-if="withError && !refreshAgainActive")
        p {{ integration.refresh_message }}
        .refresh-footer-buttons
          UiButton.w-165(
            variant="text-primary"
            :disabled="inProgress || isViewAsOtherUser"
            data-vi="refresh-reset-btn"
            @click="refreshReset"
          ) {{ $t('company.integrations.refresh_reset') }}
          template(v-if="integration.refresh_again")
            UiButton.w-165(
              variant="text-primary"
              :disabled="inProgress || isViewAsOtherUser"
              data-vi="refresh-again-btn"
              @click="refreshAgain"
            ) {{ $t('company.integrations.refresh_again') }}
        template(v-if="!integration.refresh_again")
          p(data-vi="refresh-again-timeout") {{ $t('company.integrations.refresh_again_timeout') }}
      template(v-else-if="integration.refresh_finished_message || refreshTimeoutTime")
        p(data-vi="refresh-finished-message") {{ $t('company.integrations.refresh_success') }}
        p(v-if="refreshTimeoutTime") {{ $t('company.integrations.refresh_timeout_time', { time: refreshTimeoutTime }) }}
      template(v-else-if="integration.can_refresh")
        UiButton.ui-form-field(
          v-for="(refresh_type, key) in refresh_types"
          :key="key"
          block
          :loading="inProgress"
          :disabled="inProgress || isViewAsOtherUser"
          data-vi="refresh-btn"
          @click="refresh(refresh_type)"
        ) {{ $t(`company.integrations.${refresh_type}_refresh`) }}
</template>

<script>
import Integrations from '@/api/models/Integrations.js';
import Integration from '@/models/Integration.js';
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyIntegrationDataRefreshModal',
  emits: ['done'],
  data() {
    return {
      show: false,
      integration: new Integration(),
      withError: false,
      refreshAgainActive: false,
      software: null,
      inProgress: false,
      refresh_types: [],
      form: {
        refresh_type: null,
      },
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser']),

    details() {
      return this.software ? this.$t(`company.integrations.details.${this.software}`) : '';
    },
    refreshTimeoutTime() {
      let { refresh_timeout: totalSeconds } = this.integration;
      if (!totalSeconds) return false;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
  },
  methods: {
    open(integration) {
      this.integration = new Integration(integration);
      this.withError = integration.refresh_type === 'error';
      this.refresh_types = integration.refresh_types;
      this.form.refresh_type = null;
      this.software = integration.software;
      this.show = true;
    },
    close() {
      this.show = false;
      this.withError = false;
    },
    refreshAgain() {
      const { refresh_type } = this.integration;
      this.form.refresh_type = refresh_type;
      this.refreshAgainActive = true;
    },
    refreshReset() {
      if (this.inProgress) return;
      this.inProgress = true;
      new Integrations(null, { id: this.integration.id })
        .resetRefresh()
        .then((resp) => {
          this.inProgress = false;
          if (resp.status === 200) {
            this.$emit('done');
            this.show = false;
          }
        })
        .catch((_e) => {
          this.inProgress = false;
        });
    },
    refresh(refresh_type) {
      if (!this.refresh_types.includes(refresh_type)) return;
      this.form.refresh_type = refresh_type;
      this.sendRefresh();
    },
    sendRefresh() {
      if (this.form.refresh_type === null || this.inProgress) return;

      this.inProgress = true;
      new Integrations(null, { id: this.integration.id })
        .refresh(this.form)
        .then((resp) => {
          if (resp.status === 200) {
            this.$emit('done');
            this.show = false;
          }
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';
.refresh-footer-buttons {
  display: flex;
  justify-content: space-around;
}

.data-refresh-modal__button {
  width: 100%;
}

.data-refresh-modal__error {
  color: #c9432a;
  margin: 1rem 0;
}

.mb10 {
  margin-bottom: 10px;
}
</style>
