<template lang="pug">
.tab-container__header
  .tab-container__header--left
    h3
      slot(name="title") {{ title }}
  .tab-container__header--right(v-if="$slots.actions")
    Stack
      slot(name="actions")
</template>

<script>
export default {
  props: {
    title: {
      type: [Number, String],
      default: '',
    },
  },
};
</script>

<style scoped lang="scss"></style>
