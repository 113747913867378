<template lang="pug">
UiFormMultiselect(
  v-model="value"
  :options="columnOptions"
  :disabled="disabled"
  :class="cssClass"
  labelKey="text"
  @open="onOpen"
  @select="updateValueAction"
)
</template>
<script>
export default {
  name: 'DataroomTableDropdown',
  props: {
    column: {
      type: Object,
    },
    result: {
      type: Object,
    },
    rowKey: {
      type: String,
    },
    columnIndex: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
    },
    onOpen: {
      type: Function,
    },
  },

  data() {
    return {
      columnOptions: [],
      value: null,
    };
  },

  mounted() {
    this.columnOptions = this.column.options;
    const resultValue = this.result[this.rowKey][this.columnIndex] || this.column.default_value;
    const option =
      this.columnOptions.find((columnOption) => columnOption.value === resultValue) ||
      this.columnOptions[0];
    this.value = option.value;
  },
  methods: {
    updateValueAction(option) {
      // eslint-disable-next-line vue/no-mutating-props
      this.result[this.rowKey][this.columnIndex] = option;
    },
  },
};
</script>
