<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  allowOutclick
  width="460"
  :title="$t('confirmation')"
  @close="close"
)
</template>

<script>
import DynamicSettings from '@/api/models/dynamic-settings';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['done'],
  data() {
    return {
      show: false,
      id: null,
      inProgress: false,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          variant: 'smooth-secondary',
          onClick: this.close,
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          variant: 'danger',
          onClick: this.delete,
          disabled: this.inProgress,
          loading: this.inProgress,
        },
      ];
    },
  },
  methods: {
    open(id) {
      this.inProgress = false;
      this.show = true;
      this.id = id;
    },
    close() {
      this.show = false;
    },
    async delete() {
      try {
        this.inProgress = true;
        await new DynamicSettings(null, { id: this.id }).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
});
</script>
