import RestVuex from '@/common/rest_vuex.js';
import Report from '@/models/Report.js';
import ApiPowerBiReports from '@/api/power_bi_reports.js';
import mutations from './mutations.js';
// eslint-disable-next-line import/no-cycle
import actions from './actions.js';
import getters from './getters.js';

const state = {
  companies: [],
  systemBanners: [],
  loginBanner: null,
  currentCompany: null,
  currentCompanyId: null,
  company: null,
  inProgress: false,
};

export default {
  modules: {
    reports: new RestVuex(ApiPowerBiReports, 'power_bi_reports', { model: Report }).getStore(),
  },
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
