<template lang="pug">
.admin-layout(v-if="!loading")
  Heading(:title="this.$t('credentials.edit.title')")
    Stack
      StackItem
        UiButton(
          :disabled="inProgress || generateInProgress"
          :loading="inProgress"
          @click="save"
        ) {{ this.$t('credentials.save') }}
      StackItem
        UiButton(
          :round="mobileView"
          icon="RefreshIcon"
          variant="outline-primary"
          :loading="generateInProgress"
          :disabled="inProgress || generateInProgress"
          @click="refresh"
        ) {{ this.$t('credentials.regenerate') }}

  .admin-layout__breadcrumps-container
    AppBreadcrumbs(:items="breadcrumbs" showBack)
  .page-card
    .form-container
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiFormInput(
            v-model="form.username"
            :label="$t('credentials.edit.username')"
            :disabled="inProgress || generateInProgress"
            :vuelidate-model="v$.form.username"
          )
        StackItem
          UiFormInput(
            v-model="form.token"
            :label="$t('credentials.edit.token')"
            readonly
          )
            template(#iconAfter)
              UiIcon(
                name="Copy"
                size="24"
                @click="copyToClipboard(form.token)"
              )
        StackItem
          UiFormInput(
            v-model="form.refresh_token"
            :label="$t('credentials.edit.refresh_token')"
            readonly
          )
            template(#iconAfter)
              UiIcon(
                name="Copy"
                size="24"
                @click="copyToClipboard(form.refresh_token)"
              )
</template>

<script>
import copy from 'copy-to-clipboard';
import Credentials from '@/api/models/Credentials.js';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';

export default {
  name: 'EditMasterKeys',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      loading: true,
      inProgress: false,
      generateInProgress: false,
      form: {
        username: '',
        token: '',
        refresh_token: '',
      },
    };
  },
  validations() {
    return {
      form: {
        username: { required },
      },
    };
  },

  computed: {
    ...mapGetters(['mobileView']),
    breadcrumbs() {
      return [
        {
          label: this.$t('credentials.title'),
          to: '/admin/credentials',
        },
        {
          label: this.$t('credentials.edit.title'),
          to: this.$route.path,
        },
      ];
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      await new Credentials(null, { id: this.$route.params.id })
        .fetch()
        .then((res) => {
          this.form = res.data.credential;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    copyToClipboard(val) {
      const result = copy(val);
      if (result) {
        this.$toaster.add({
          message: this.$t('company.reports.copied'),
        });
      }
    },

    save() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;

        this.inProgress = true;

        new Credentials(null, { id: this.$route.params.id })
          .update(this.form)
          .then(() => {
            this.$router.push('/admin/credentials');
          })
          .catch((error) => {
            this.$toaster.add({
              type: 'error',
              message: this.$localizeErrorMessage(error),
            });
          })
          .finally(() => {
            this.inProgress = false;
          });
      });
    },

    async refresh() {
      this.generateInProgress = true;
      await new Credentials(null, { id: this.$route.params.id })
        .regenerate()
        .then((res) => {
          this.form.token = res.data.credential.token;
          this.form.refresh_token = res.data.credential.refresh_token;
          this.generateInProgress = false;
          this.$toaster.add({
            message: this.$t('credentials.refresh.success'),
          });
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.generateInProgress = false;
        });
    },

    toPage(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-card {
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.form-container {
  max-width: 900px;
}

.mb25 {
  margin-bottom: 20px;
}
</style>
