<template lang="pug">
.analytics-sidebar__nav__container(
  :class="{ active: isOpened }"
  :style="{ '--group-color-rgb': groupColorRgb }"
  data-test-id="sidebar-container"
)
  .analytics-sidebar__nav__group
    .analytics-sidebar__nav__group__item(data-test-id="sidebar-group-item" @click="toggle")
      UiIcon(
        name="ChevroneRight"
        :class="{ 'active-90': isOpened }"
        data-test-id="sidebar-arrow-right"
      )
      .analytics-sidebar__nav__group__folder
        svg(
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="22px"
          height="22px"
        )
          path(
            fill="rgb(var(--group-color-rgb))"
            d="M26 25H4c-1.105 0-2-.895-2-2V11c0-.552.448-1 1-1h24c.552 0 1 .448 1 1v12C28 24.105 27.105 25 26 25zM4 3h4.868c.703 0 1.354.369 1.715.971L13 8H2V5C2 3.895 2.895 3 4 3z"
          )
          path(fill="rgb(var(--group-color-rgb))" d="M26,5H2v3h26V7C28,5.895,27.105,5,26,5z")
      span {{ group.name }}
    template(v-if="group.reportsList.length > 0")
      AnalyticsSidebarReport(
        v-for="reportId in group.reportsList"
        :report="reports[reportId]"
        :reports="reports"
        :isOpenedGroup="isOpened"
        :currentReportId="currentReportId"
        :currentLinkedReportKey="currentLinkedReportKey"
        :currentPageIndex="currentPageIndex"
        divider
        data-test-id="sidebar-report"
        @setReport="() => $emit('setReport', reportId)"
        @setLinkedReport="(linkedReportId) => $emit('setLinkedReport', linkedReportId)"
        @setPage="(index) => $emit('setPage', index)"
      )
    template(v-else)
      .analytics-sidebar__nav__group--no-reports(v-if="isOpened" data-test-id="sidebar-no-reports")
        span {{ $t('analytics.no_reports') }}
</template>

<script>
import AnalyticsSidebarReport from '@/views/Analytics/components/Sidebar/Report.vue';
import { hexToRgb } from '@/common/color.js';

export default {
  name: 'AnalyticsSidebarGroup',
  components: {
    AnalyticsSidebarReport,
  },
  props: {
    group: {
      type: Object,
      default() {
        return {};
      },
    },
    reports: {
      type: Array,
      default() {
        return [];
      },
    },
    isOpened: {
      type: Number,
      default: null,
    },
    currentReportId: {
      type: String,
      default: null,
    },
    currentLinkedReportKey: {
      type: String,
      default: null,
    },
    currentPageIndex: {
      type: String,
      default: null,
    },
  },

  emits: ['toggleGroup', 'setReport', 'setLinkedReport', 'setPage'],

  computed: {
    groupColorRgb() {
      const c = hexToRgb(this.group?.color ?? '#000000');
      return [c.r, c.g, c.b].join(', ');
    },
  },

  methods: {
    toggle() {
      const isOpenedReportInGroup = this.group.reportsList.includes(this.currentReportId);
      if (isOpenedReportInGroup && this.isOpened) {
        return;
      }
      this.$emit('toggleGroup', this.group.id);
    },
  },
};
</script>
