import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['dirty'],
  props: {
    search: {
      type: String,
      default: '',
    },
    entity: {
      type: Object,
      required: true,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      items: [],
      selectedItemsIds: {},
      initialSelectedItemsIds: {},
      parentLockedIds: {},
      reachEnd: false,
      filters: {
        page: 1,
      },
      abortControllerListItems: null,
    };
  },
  computed: {
    isDirty() {
      return JSON.stringify(this.selectedItemsIds) !== this.initialSelectedItemsIds;
    },
  },
  watch: {
    search: {
      handler() {
        this.resetFilters();
        this.fetchTabsListItems();
      },
    },
    isDirty(isDirty) {
      this.$emit('dirty', isDirty);
    },
  },
  created() {
    this.resetFilters();
    this.selectedItemsIds = {};
    this.parentLockedIds = {};
    this.fetchState();
  },
  methods: {
    resetFilters() {
      this.filters = {
        page: 1,
      };
      this.reachEnd = false;
      this.items = [];
    },
    setInitialSelectedItemsIds() {
      this.initialSelectedItemsIds = JSON.stringify(this.selectedItemsIds);
    },
  },
});
