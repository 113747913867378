<template lang="pug">
UiModal(
  :show="show"
  :allowClose="false"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :title="titleText"
  width="500"
)
  p {{ bodyText }}
</template>

<script>
export default {
  emits: ['delete'],
  data() {
    return {
      show: false,
      item: null,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          variant: 'smooth-secondary',
          onClick: this.close,
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          variant: 'smooth-danger',
          onClick: this.delete,
          icon: 'Trash',
        },
      ];
    },
    titleText() {
      return this.item?.data?.title || this.$t('notifications.notification_details');
    },
    bodyText() {
      return this.item?.data?.body || this.item?.data?.subtitle;
    },
  },
  methods: {
    open({ item } = {}) {
      this.item = item;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    delete() {
      this.$emit('delete', this.item);
      this.close();
    },
  },
};
</script>
