<template lang="pug">
.analytics-sidebar__wrapper(:class="{ open: isOpen }" data-test-id="sidebar-wrapper")
  .analytics-sidebar__content-container
    .analytics-sidebar
      .analytics-sidebar__header
        .analytics-sidebar__title {{ $t('company.reports.reports') }}
        .analytics-sidebar__close(
          v-if="mobileView"
          data-test-id="sidebar-close"
          @click="$emit('toggle')"
        )
      .analytics-sidebar__header__nav
        .analytics-sidebar__nav(v-if="!loading")
          AnalyticsSidebarGroup(
            v-for="groupId in navigationTree.groupsList"
            :key="`group-${groupId}`"
            :group="navigationTree.groups[groupId]"
            :isOpened="openedGroupIds.includes(groupId)"
            :currentReportId="currentReportId"
            :currentLinkedReportKey="currentLinkedReportKey"
            :currentPageIndex="currentPageIndex"
            :reports="navigationTree.reports"
            data-test-id="sidebar-group"
            @toggleGroup="(groupId) => toggleGroup(groupId)"
            @setReport="(reportId) => $emit('setReport', reportId)"
            @setLinkedReport="(linkedReportId) => $emit('setLinkedReport', linkedReportId)"
            @setPage="(index) => $emit('setPage', index)"
          )
          AnalyticsSidebarReport(
            v-for="reportId in navigationTree.reportsList"
            :report="navigationTree.reports[reportId]"
            :reports="navigationTree.reports"
            :currentReportId="currentReportId"
            :currentLinkedReportKey="currentLinkedReportKey"
            :currentPageIndex="currentPageIndex"
            containerClass="analytics-sidebar__nav__container"
            rootLevel
            data-test-id="sidebar-report"
            @setReport="() => $emit('setReport', reportId)"
            @setLinkedReport="(linkedReportId) => $emit('setLinkedReport', linkedReportId)"
            @setPage="(index) => $emit('setPage', index)"
          ) 
        .skeleton(v-else)
          Stack(vertical align="stretch")
            StackItem(v-for="i in 7")
              Stack
                StackItem
                  UiSkeleton(
                    rounded
                    width="40px"
                    height="40px"
                  )
                StackItem(fill)
                  UiSkeleton(
                    width="128"
                    height="18px"
                    data-test-id="sidebar-skeleton"
                  )
      Transition(name="fade-left")
        BookmarksPanel(
          v-if="isShowBookmarksPanel"
          :bookmarks="bookmarks"
          :currentBookmarkId="currentBookmarkId"
          :loading="loading"
          data-test-id="sidebar-bookmarks"
          @close="$emit('close-bookmarks')"
          @select="$emit('select-bookmark', $event)"
          @create="$emit('createBookmark')"
          @delete="$emit('deleteBookmark', $event)"
        )
  CollapseButton.analytics-sidebar__toggle(
    v-if="!mobileView"
    :active="!isOpen"
    variant="primary"
    data-test-id="collapse-button"
    @click="$emit('toggle')"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import AnalyticsSidebarGroup from '@/views/Analytics/components/Sidebar/Group.vue';
import AnalyticsSidebarReport from '@/views/Analytics/components/Sidebar/Report.vue';
import CollapseButton from '@/components/CollapseButton.vue';
import BookmarksPanel from './BookmarksPanel.vue';

export default {
  components: {
    AnalyticsSidebarGroup,
    AnalyticsSidebarReport,
    BookmarksPanel,
    CollapseButton,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    bookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
    isShowBookmarksPanel: {
      type: Boolean,
      default: false,
    },
    navigationTree: {
      type: Object,
      default() {
        return {};
      },
    },
    currentReportId: {
      type: String,
      default: undefined,
    },
    currentLinkedReportId: {
      type: String,
      default: undefined,
    },
    currentPageIndex: {
      type: [Number],
      default: undefined,
    },
    currentBookmarkId: {
      type: Number,
      default: undefined,
    },
    scrollToActiveEl: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'openSidebar',
    'closeSidebar',
    'toggle',
    'setReport',
    'setLinkedReport',
    'setPage',
    'createBookmark',
    'deleteBookmark',
  ],

  data() {
    return {
      openedGroupIds: [],
    };
  },

  watch: {
    loading(newVal) {
      if (!newVal) {
        this.scrollToActive();
      }
    },
    currentReportId() {
      this.openActiveTree();
    },
    currentPageIndex(newVal, oldVal) {
      if (oldVal === null) {
        this.scrollToActive();
      }
    },
  },

  computed: {
    ...mapGetters(['mobileView']),

    isRolled() {
      return { width: this.isOpen ? '320px' : '0px', minWidth: this.isOpen ? '320px' : '0px' };
    },

    defaultReport() {
      const defaultReportEntry = Object.entries(this.navigationTree.reports).find(
        ([_key, report]) => !!report.is_default,
      );
      if (!defaultReportEntry) return;
      return defaultReportEntry[1];
    },

    currentReport() {
      return this.navigationTree.reports[this.currentReportId];
    },

    currentLinkedReportKey() {
      return `${this.currentReportId}|${this.currentLinkedReportId}`;
    },

    currentLinkedReport() {
      return this.navigationTree.reports[this.currentLinkedReportKey];
    },
  },

  mounted() {
    this.openActiveTree();
    if (this.scrollToActiveEl) {
      this.scrollToActive();
    }
  },

  methods: {
    scrollToActive() {
      this.$nextTick(() => {
        setTimeout(() => {
          const activeEl =
            document.querySelector('.analytics-sidebar__nav__pages__item.active') ||
            document.querySelector(`[data-report-id="${this.currentReportId}"]`);
          if (activeEl) {
            activeEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
          }
        }, 100);
      });
    },

    openActiveTree() {
      if (!this.currentReportId) {
        if (window.selected_report_id) {
          const selectedReport = this.navigationTree.reports[window.selected_report_id];
          if (!selectedReport) return;
          if (selectedReport.groupId) {
            this.openedGroupIds.push(selectedReport.groupId);
          }
          this.$emit('setReport', window.selected_report_id);
        } else if (this.defaultReport) {
          if (this.defaultReport.groupId) {
            this.openedGroupIds.push(this.defaultReport.groupId);
          }
          this.$emit('setReport', this.defaultReport.id);
        } else {
          const groupIdsWithReports = this.navigationTree.groupsList.filter(
            (groupId) => this.navigationTree.groups[groupId].reportsList.length > 0,
          );
          if (groupIdsWithReports.length) {
            const group_id = groupIdsWithReports[0];
            this.openedGroupIds.push(group_id);
            this.$emit('setReport', this.navigationTree.groups[group_id].reportsList[0]);
          } else if (this.navigationTree.reportsList.length) {
            this.$emit('setReport', this.navigationTree.reportsList[0]);
          }
        }
      } else {
        const groups = Object.values(this.navigationTree.groups);
        const currentGroupId = groups?.filter((group) =>
          group.reportsList.includes(this.currentReportId),
        )[0]?.id;

        if (currentGroupId) {
          this.openedGroupIds.push(currentGroupId);
        }
      }
    },

    accessError() {
      this.updateTreeReport({ accessError: true });
      this.$forceUpdate();
    },

    updateTreeReport(data) {
      this.navigationTree.reports[this.currentReportId] = { ...this.currentReport, ...data };
      this.scrollToActive();
    },

    toggleGroup(id) {
      if (this.openedGroupIds.includes(id)) {
        this.openedGroupIds = this.openedGroupIds.filter((groupId) => groupId !== id);
        return;
      }

      this.openedGroupIds.push(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  padding: 10px;
}

.analytics-sidebar__wrapper {
  position: relative;
  transition: all 230ms ease-in-out;
  width: 20px;
  flex-shrink: 0;
  z-index: 2;
  background: var(--bb-analytic-bg);
  box-shadow: 0 0 0 1px rgba(black, 0.02), 0 0 30px -3px rgba(black, 0.04);

  @media screen and (max-width: 1000px) {
    width: 0px;
  }

  &.open {
    width: 320px;
  }
}

.analytics-sidebar__content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  transition: all 230ms ease-in-out;
}

.analytics-sidebar__wrapper.open > .analytics-sidebar__content-container {
  opacity: 1;
}

.analytics-sidebar__toggle {
  position: absolute;
  top: 61px;
  right: -12px;
  z-index: 2;
}

.analytics-sidebar {
  width: 320px;
  position: relative;
}
</style>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.analytics-sidebar {
  height: 100%;
  position: relative;
  transition: 0.4s;
  z-index: 2;
  background: var(--bb-analytic-bg);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 74px;
    padding: 0 26px;
    position: relative;
    border-bottom: 1px solid var(--bb-analytic-gray-900);
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    flex-shrink: 1;
  }

  &__close {
    flex-shrink: 0;
    background: transparent url('@/assets/images/analytics/cross.svg') center center no-repeat;
    width: 32px;
    height: 32px;
    margin-right: -6px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: var(--bb-analytic-gray-900);
    }
  }

  &__body-scroll-wrapper {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: auto;
  }

  &__body {
    padding: 10px;
  }

  &__header {
    overflow: hidden;
    &__nav {
      position: absolute;
      padding: 10px;
      top: 74px;
      bottom: 0;
      overflow-y: auto;
      transition: 0.4s;
      width: 100%;
    }

    & h2 {
      font-size: 24px;
      line-height: 32px;
      padding: 7px 0 0px 37px;
      margin: 0;
      color: #283040;
      font-weight: 600;
    }

    &__toggle {
      width: 24px;
      height: 24px;
      border: 1px solid var(--bb-analytic-bg);
      border-radius: 12px;
      background-color: var(--bb-analytic-bg);
      position: absolute;
      top: 20px;
      right: -10px;
      box-shadow: 0px 2px 4px rgba(var(--bb-shadow-rgb), 0.35);
      cursor: pointer;

      &__arrow {
        position: relative;
        left: 8px;
        bottom: 2px;
        transition: 0.2s;
        transform: rotate(0deg);

        &--rotate {
          position: relative;
          left: 10px;
          transition: 0.2s;
          transform: rotate(180deg);
        }
      }

      &:hover {
        border: 1px solid;
        border-color: #00b07d;
      }
    }
  }

  &__nav {
    &__container {
      border-radius: 20px;
      padding: 10px 5px 0 15px;
      margin-top: 10px;
      transition: 0.2s;

      &.active {
        background: var(--bb-analytic-highlight);
      }
    }

    &__group {
      &__folder {
        margin: 0 10px 0 14px;
      }

      &__item {
        .ui-icon {
          margin-left: 5px;
          flex-shrink: 0;
        }
      }

      &--no-reports {
        text-align: center;
        padding: 5px 0;
      }
    }

    &__group,
    &__report,
    &__pages {
      & span {
        line-height: 24px;
      }

      &__item {
        scroll-margin: 20px;

        & > span {
          flex-grow: 1;
          flex-shrink: 1;
        }

        &.active {
          background: var(--bb-analytic-bg);
          color: var(--bb-analytic-report);

          &::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: var(--bb-analytic-report);
            border-radius: 10px;
          }
        }

        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 24px;
      }

      padding-top: 10px;
      margin-top: -10px;
      border-radius: 20px;
      overflow-y: hidden;
      transition: 0.26s;
    }

    &__report {
      padding: 10px 5px 10px 20px;

      .ui-icon {
        margin-right: 14px;
        flex-shrink: 0;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__left {
          display: flex;
          align-items: center;
        }

        &__right {
          & div {
            background-color: #00b07d;
            width: 8px;
            height: 8px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }
    }

    &__pages {
      transition: 0.2s;

      &--loading {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }

      &--error {
        text-align: center;
        font-size: 14px;
        color: rgb(237, 117, 117);
      }

      &__item {
        padding: 10px 20px;
        margin-top: 0.35rem;
      }

      & :hover {
        background: var(--bb-analytic-bg);
        border-radius: 20px;
        transition: 0.2s;
      }

      .ui-icon {
        margin-right: 10px;
        flex-shrink: 0;
      }
    }
  }

  & hr {
    border-color: var(--bb-analytic-bg);
  }
}
</style>
