<template lang="pug">
.admin-layout
  Heading(:title="$t('portal_management.title')")
  UiTabs(
    v-if="tabs.length > 1"
    :tabs="tabs"
    :selected="activeTab"
    @select="selectTab"
  )
  UiCard
    transition(name="fade" mode="out-in")
      component(:is="tabContent" :isPortalManagementPage="$route.name === 'PortalManagement'")
</template>

<script>
import ReportsTab from '@/views/Admin/PortalManagement/Tabs/ReportsTab.vue';
import UiThemesTab from '@/views/Admin/PortalManagement/Tabs/UiThemesTab.vue';
import SystemBannersTab from '@/views/Admin/PortalManagement/Tabs/system-banners-tab.vue';
import IntegrationsTab from '@/views/Admin/PortalManagement/Tabs/integrations.vue';
import DynamicFieldsTab from '@/views/Admin/PortalManagement/Tabs/dynamic-fields-tab.vue';

const REPORTS_TAB = 'reports';
const UI_THEMES_TAB = 'ui_themes';
const SYSTEM_BANNERS = 'system_banners';
const INTEGRATIONS = 'integrations';
const DYNAMIC_FIELDS = 'dynamic_fields';

export default {
  name: 'PortalManagement',
  computed: {
    activeTab() {
      return this.$route?.query?.tab || this.tabs[0].id;
    },
    tabs() {
      return [
        {
          id: REPORTS_TAB,
          label: this.$t('portal_management.tabs.reports'),
          content: ReportsTab,
        },
        {
          id: INTEGRATIONS,
          label: this.$t('portal_management.tabs.integrations'),
          content: IntegrationsTab,
        },
        {
          id: UI_THEMES_TAB,
          label: this.$t('portal_management.tabs.ui_theme'),
          content: UiThemesTab,
        },
        {
          id: SYSTEM_BANNERS,
          label: this.$t('portal_management.tabs.system_banners'),
          content: SystemBannersTab,
        },
        {
          id: DYNAMIC_FIELDS,
          label: this.$t('portal_management.tabs.dynamic_fields'),
          content: DynamicFieldsTab,
        },
      ];
    },
    tabContent() {
      const tab = this.tabs.find((item) => item.id === this.activeTab);
      return tab?.content;
    },
  },
  methods: {
    selectTab(tab_id) {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: tab_id },
      });
    },
  },
};
</script>
