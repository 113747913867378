<template lang="pug">
.registration
  .registration__form
    h3 {{ $t('registration.title') }}
    template(v-if="user")
      template(v-if="errorMessage")
        p.registration__form__error {{ errorMessage }}
      .registration__form__fields
        Stack(vertical align="stretch")
          StackItem
            UiFormInput(
              v-model="user.name"
              :placeholder="$t('registration.name')"
              :label="$t('registration.name')"
              :vuelidateModel="v$.user.name"
            )
          StackItem
            UiFormInput(
              v-model="user.email"
              placeholder="@mail"
              :label="$t('registration.email')"
              :vuelidateModel="v$.user.email"
              readonly
            )
          StackItem
            UiFormInput(
              v-model="user.password"
              :placeholder="$t('registration.password')"
              :label="$t('registration.password')"
              :vuelidateModel="v$.user.password"
            )
          StackItem
            UiFormInput(
              v-model="user.password_confirmation"
              :placeholder="$t('registration.password_confirmation')"
              :label="$t('registration.password_confirmation')"
              :vuelidateModel="v$.user.password_confirmation"
            )
        .registration__form__footer
          UiButton.registration__submit(
            :disabled="inProgress"
            :loading="inProgress"
            @click="confirmRegistration"
          ) {{ $t('registration.submit') }}

    template(v-else-if="failed || fetchError")
      p.registration__form__error {{ fetchError || $t('registration.error_invitation') }}
      Stack(justify="center")
        StackItem
          UiButton(width @click="continueUsage") {{ $t('actions.continue') }}
    template(v-else-if="inProgress")
      UiSpinner(grow)
</template>
<script>
import ApiUsers from '@/api/user.js';
import useVuelidate from '@vuelidate/core';
import { sameAs, helpers } from '@vuelidate/validators';
import { email, required } from '@/common/validators.js';

export default {
  name: 'Registration',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      user: null,
      inProgress: false,
      failed: false,
      fetchError: null,
      errorMessage: null,
    };
  },
  validations() {
    return {
      user: {
        name: { required },
        email: { required, email },
        password: { required },
        password_confirmation: {
          sameAs: helpers.withMessage(
            this.$t('validations.password_confirmation_does_not_match'),
            sameAs(this.user?.password),
          ),
        },
      },
    };
  },
  mounted() {
    this.fetchUserForToken();
  },

  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    fetchUserForToken() {
      this.inProgress = true;
      const { token } = this.$route.query;
      if (!token) {
        this.failed = true;
        return;
      }
      ApiUsers.invitedUser(token)
        .then((resp) => {
          this.user = resp.data.user;
          this.inProgress = false;
        })
        .catch((e) => {
          if (e.response) this.fetchError = e.response.data.error;
          this.inProgress = false;
        });
    },
    confirmRegistration() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.inProgress = true;

        ApiUsers.registration(this.user)
          .then((resp) => {
            if (resp.data.success) this.continueUsage();
          })
          .catch((err) => {
            this.failed = true;
            this.errorMessage = err.response.data.errors[0];
            this.inProgress = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.registration {
  &__submit {
    margin-top: 15px;
    width: 165px;
    margin-left: auto;
  }

  &__form {
    background-color: var(--bb-app-bg-main);
    width: 523px;
    margin: auto;
    padding: 28px;

    & p {
      font-size: 16px;
    }

    & h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__error {
      color: #c9432a;
      margin: 1rem 0;
    }

    &__footer {
      display: flex;
      align-items: center;
    }
  }
}
</style>
