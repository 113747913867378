<template lang="pug">
.system-banner-show
  UiCardSection(padding="xl")
    .system-banner-show__header
      .system-banner-show__details
        .system-banner-show__details-row
          .system-banner-show__details-title {{ $t('system_banners.title_user_role') }}
          .system-banner-show__details-description {{ rolesList }}
        .system-banner-show__details-row
          .system-banner-show__details-title {{ $t('system_banners.title_type') }}
          .system-banner-show__details-description {{ $t(`system_banners.type.${banner.banner_type}`) }}
      .system-banner-show__actions
        UiStack(spacing="lg" align="center")
          UiBadge.system-banner-show__dismissible-badge(
            :variant="banner.dismissible ? 'primary' : 'error'"
            size="xs"
          )
            | {{ dismissibleLabel }}
          UiStack(spacing="xs")
            UiTooltip(:title="$t('actions.edit')" variant="dark")
              template(#activator)
                UiButton(
                  size="xs"
                  variant="ghost-secondary"
                  icon="PencilEdit"
                  round
                  block
                  data-test-id="edit-banner"
                  @click="$emit('toggleEdit')"
                )
            UiTooltip(:title="$t('actions.delete')" variant="dark")
              template(#activator)
                UiButton(
                  size="xs"
                  variant="ghost-danger"
                  icon="Trash"
                  round
                  block
                  data-test-id="delete-banner"
                  @click="$emit('delete')"
                )
    .system-banner-show__description(v-html="banner.description")
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      inProgress: false,
    };
  },

  computed: {
    dismissibleLabel() {
      return this.$t(
        `system_banners.${this.banner.dismissible ? 'dismissable' : 'not_dismissable'}`,
      );
    },
    rolesList() {
      return this.banner.user_roles.map((role) => this.$t(`company.role.${role}`)).join(', ');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.system-banner-show {
  background-color: var(--bb-gray-900);

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__details-row {
    display: flex;

    & + & {
      margin-top: 1rem;
    }
  }

  &__details-title {
    width: 100px;
    color: var(--bb-gray-600);
  }

  &__description {
    margin-top: 1rem;

    & > * {
      margin: 0;
    }
  }

  &__dismissible-badge {
    &.ui-badge--error {
      background: rgba(var(--bb-danger-rgb), 0.1) !important;
    }

    &.ui-badge--primary {
      background: rgba(var(--bb-primary-rgb), 0.1) !important;
    }
  }
}
</style>
