import AppBaseModel from './AppBaseModel.js';

class Companies extends AppBaseModel {
  constructor(options, item) {
    const mergedOptions = {
      path: 'companies',
      singularKey: 'company',
      ...options,
    };
    super(mergedOptions, item);
  }

  setCurrent(companyId) {
    return this.httpClient.patch(`${this.entityUrl}/current`, null, {
      headers: { 'BiBook-Company-Id': companyId },
    });
  }

  generateApiKey({ id, api_type }) {
    return this.httpClient.patch(`${this.entityUrl}/generate_api_key`, {
      id,
      api_type,
    });
  }

  import(data) {
    return this.httpClient.put(`${this.url}/import`, data);
  }
}

export default Companies;
