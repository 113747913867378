<template lang="pug">
UiMultiselect(
  v-bind="$attrs"
  :options="optionsProp"
  value-prop="id"
  track-by="id"
  label="name"
  :object="object"
  :searchable="true"
  :filter-results="false"
  :delay="300"
)
</template>

<script>
import Companies from '@/api/models/Companies.js';

export default {
  inheritAttrs: true,
  props: {
    object: {
      type: Boolean,
      default: true,
    },
    options: {
      type: [Array, Object, Function],
      default: null,
    },
  },
  computed: {
    optionsProp() {
      return this.options || this.search;
    },
  },
  methods: {
    async search(query = '') {
      try {
        const response = await new Companies({ adminApi: true }).fetchAll({
          search: query,
          page: 1,
          per_page: 30,
        });
        return response.data.companies.map(({ group, ...r }) => r);
      } catch {
        return [];
      }
    },
  },
};
</script>
