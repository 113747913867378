import AppBaseModel from './AppBaseModel.js';

class CompanyGroups extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      adminApi: true,
      path: 'company_groups',
      singularKey: 'company_group',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default CompanyGroups;
