<template lang="pug">
.ui-rest-table(:class="classes")
  .ui-rest-table__header
    UiStack(align="center")
      UiStackItem(grow shrink)
        .ui-rest-table__filters
          slot(name="filters")
            .ui-rest-table__filter-field(v-if="searchable")
              UiInput(
                v-model="searchModel"
                icon="Search"
                :placeholder="$t(searchPlaceholder)"
              )
            UiStack(v-if="$slots.extraFilters")
              slot(name="extraFilters")
      UiStackItem(align="end")
        UiStack(align="center")
          UiRestTableItemsPerPage(v-model="perPageModel" :perPageOptions="perPageOptions")
          UiPagination(
            v-if="meta.total_count > filters.per_page"
            v-model="pageModel"
            :perPage="filters.per_page"
            :totalItems="meta.total_count"
            size="sm"
          )
  UiDataTable(
    v-bind="$attrs"
    :loading="isLoading"
    :headers="headers"
    :items="itemsList"
    :sortOrder="filters.sort_order"
    :sortBy="filters.sort_by"
    size="md"
    @sort="onSort"
  )
    template(v-for="col in headers" v-slot:[`header:${col.key}`]="props")
      slot(v-bind="props" :name="`header:${col.key}`")
    template(v-for="col in headers" v-slot:[`cell:${col.key}`]="props")
      slot(v-bind="props" :name="`cell:${col.key}`")
  UiEmptyState(
    v-if="error && !loading"
    style="min-height: 14rem"
    :title="$t('rest-table.error.title')"
    :description="errorDetailsMessage"
  )
    template(#actions)
      UiButton(
        icon="RefreshCw"
        variant="smooth-danger"
        size="sm"
        @click="fetchData"
      ) {{ $t('actions.retry') }}
  UiEmptyState(
    v-else-if="loading && !items.length"
    style="min-height: 14rem"
    :title="$t('rest-table.loading.title')"
    :description="$t('rest-table.loading.description')"
  )
  UiEmptyState(
    v-else-if="!loading && !items.length"
    style="min-height: 14rem"
    :title="$t('rest-table.empty.title')"
    :description="$t('rest-table.empty.description')"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import UiRestTableItemsPerPage from './ui-rest-table-items-per-page.vue';

export default {
  components: {
    UiRestTableItemsPerPage,
  },

  props: {
    namespace: {
      type: String,
      required: true,
    },
    storePath: {
      type: String,
      default: null,
    },
    showBottomPagination: {
      type: Boolean,
      default: false,
    },
    showTopPagination: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    headers: {
      type: Array,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: undefined,
    },
    fetchHandler: {
      type: Function,
      default: null,
    },
    initialFilters: {
      type: Object,
      default: null,
    },
    initialItemsPerPage: {
      type: Number,
      default: 10,
    },
    embed: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      filters: {
        search: '',
        sort_by: null,
        sort_order: null,
        page: 1,
        per_page: this.initialItemsPerPage,
        ...this.initialFilters,
      },
      items: [],
      meta: {
        total_count: 0,
      },
    };
  },

  computed: {
    ...mapGetters(['mobileView']),
    tableSize() {
      return !this.mobileView ? 'lg' : 'md';
    },
    isStored() {
      return !!this.storePath;
    },
    totalItems() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/meta`].total_count
        : this.meta.total_count;
    },
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(page) {
        this.updateFilters({ page });
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.updateFilters({
          per_page: value,
          page: 1,
        });
      },
    },
    searchModel: {
      get() {
        return this.filters.search;
      },
      set(search) {
        this.updateFilters({ search });
      },
    },
    itemsList() {
      return this.isStored ? this.$store.getters[`${this.storePath}/items`] : this.items;
    },
    isLoading() {
      return this.isStored ? this.$store.getters[`${this.storePath}/loading`] : this.loading;
    },
    errorDetailsMessage() {
      return this.error?.response?.data?.message ?? this.error?.message;
    },
    classes() {
      return {
        'ui-rest-table--embed': this.embed,
      };
    },
  },

  watch: {
    $route() {
      this.fetchRouterTableState();
      this.fetchData();
    },
  },

  created() {
    this.fetchRouterTableState();
  },

  mounted() {
    this.fetchData();
  },

  beforeUnmount() {
    this.removeRouterTableState();
  },

  methods: {
    fetchRouterTableState() {
      const tableFilters = this.$route.query[`table_${this.namespace}`];
      if (!tableFilters) {
        return;
      }
      this.filters = {
        ...this.filters,
        ...JSON.parse(tableFilters),
      };
    },
    updateRouterTableState() {
      this.$router.push({
        query: {
          ...this.$route.query,
          [`table_${this.namespace}`]: JSON.stringify(this.filters),
        },
      });
    },
    removeRouterTableState() {
      const query = { ...this.$route.query };
      delete query[`table_${this.namespace}`];
      this.$router.replace({ query });
    },
    fetchData() {
      this.error = null;
      this.loading = true;
      if (this.isStored) {
        this.$store.dispatch(`${this.storePath}/fetchList`, {
          params: this.filters,
        });
        return;
      }
      this.fetchHandler(this.filters)
        .then((result) => {
          this.items = result.items;
          this.meta.total_count = result.meta?.total_count ?? 0;
          this.loading = false;
          if (!this.items.length && this.filters.page > 1) {
            this.updateFilters({ page: 1 });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
    },
    onSort(options) {
      this.updateFilters({
        sort_by: options.sortOrder === null ? null : options.sortBy,
        sort_order: options.sortOrder,
      });
    },
    updateFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.updateRouterTableState();
    },
    setFilters(filters) {
      this.filters = filters;
      this.updateRouterTableState();
    },
  },
};
</script>

<style lang="scss">
.ui-rest-table {
  &__header {
    padding: 0 0 0.75rem;
  }

  &--embed &__header {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__filters {
    display: flex;
    gap: 1rem;
  }

  &__filter-field {
    width: 100%;
    max-width: 240px;
  }
}
</style>
