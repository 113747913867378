<template lang="pug">
Modal(
  :show="show"
  :close="close"
  :width="'500px'"
)
  template(#body)
    p {{ $t('groups.delete_modal.title', { group }) }}
    .buttons-container
      UiButton(variant="outline-primary" @click="close") {{ $t('groups.delete_modal.btn_no') }}
      UiButton(
        :loading="inProgress"
        :disabled="inProgress"
        @click="deleteGroup"
      ) {{ $t('groups.delete_modal.btn_yes') }}
</template>

<script>
import CompanyGroups from '@/api/models/CompanyGroups.js';

export default {
  data() {
    return {
      show: false,
      inProgress: false,
      id: null,
      name: null,
      group: '',
    };
  },
  methods: {
    open(name, id) {
      this.show = true;
      this.group = name;
      this.id = id;
    },

    close() {
      this.show = false;
    },

    deleteGroup() {
      this.inProgress = true;

      new CompanyGroups(null, { id: this.id })
        .delete()
        .then(() => {
          this.inProgress = false;
          this.show = false;
          this.$emit('wasDeleted');
        })
        .catch((_e) => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
</style>
