import _cloneDeep from 'lodash/cloneDeep.js';
import Companies from '@/api/models/Companies.js';

function normalizeCompany(company) {
  company.subdomain = company.company_domains.find((d) => !d.full) ?? null;
  company.domain = {
    domain: '',
    full: true,
    ...company.company_domains.find((d) => d.full),
  };

  return company;
}

// eslint-disable-next-line arrow-body-style
const init = ({ commit }, companyId) => {
  return new Promise((resolve, reject) => {
    commit('SET_ORIGINAL_COMPANY', null);
    commit('SET_COMPANY', null);
    commit('SET_LOADING', true);

    const companyRequest = new Companies(
      { adminApi: !!companyId, singular: !companyId },
      { id: companyId },
    ).fetch();

    companyRequest
      .then((res) => {
        const company = normalizeCompany(res.data.company);
        commit('SET_ORIGINAL_COMPANY', _cloneDeep(company));
        commit('SET_COMPANY', company);
        resolve(res);
      })
      .catch(reject)
      .finally(() => {
        commit('SET_LOADING', false);
      });
  });
};

const updateCompany = ({ commit, rootGetters, state }, company) => {
  commit('SET_IN_PROGRESS', true);

  return new Promise((resolve, reject) => {
    const updateCompanyRequest = new Companies(
      { adminApi: rootGetters.isAdmin, singular: !rootGetters.isAdmin },
      { id: state.originalCompany.id },
    ).update(company);

    updateCompanyRequest
      .then((res) => {
        // eslint-disable-next-line no-shadow
        const company = normalizeCompany(_cloneDeep(res.data.company));
        commit('SET_ORIGINAL_COMPANY', _cloneDeep(company));
        commit('SET_COMPANY', company);
        commit('company/UPDATE_COMPANY', res.data.company, { root: true });
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        commit('SET_IN_PROGRESS', false);
      });
  });
};

export default {
  init,
  updateCompany,
};
