import { defineComponent } from 'vue';
import { required, requiredIf, url } from '@/common/validators.js';

export default defineComponent({
  validations() {
    const validations = {
      form: {
        report_url: {},
        name: {
          required,
        },
        rls_rule_name: {
          required: requiredIf(this.form?.row_level_security),
        },
      },
    };
    if (this.form?.report_type === 'standard_report') {
      validations.form.report_url.url = url;
      validations.form.report_url.required = required;
    }
    return validations;
  },
});
