<template lang="pug">
div
  .tab-container
    TabHeader
      template(#title) {{ $t('company.company_integrations') }}
      template(#actions)
        UiButton(
          variant="smooth-primary"
          icon="File"
          :round="mobileView"
          data-test-id="button-show-log"
          @click="showLog"
        ) {{ $t('company.integrations.show_log') }}
        UiButton(
          variant="smooth-primary"
          icon="BugIcon"
          :round="mobileView"
          data-test-id="button-integration-errors"
          @click="showIntegrationsErrors"
        ) {{ $t('company.integrations.integration_errors') }}
    .intagrations__container
      .integration-col(v-for="integration in integrations" :key="integration.software")
        IntegrationItem(
          :integrationName="integration.software"
          :integration="integration"
          :inProgress="inProgressElemIndex === integration.software"
          data-test-id="integration-item"
          @connect="onConnect(integration)"
          @disconnect="disconnect(integration)"
          @edit="openSetupIntegrationModal(integration)"
          @details="showDetails(integration)"
          @refresh="showDataRefresh(integration)"
        )
      InfiniteLoading(
        :loading="loading"
        :disabled="reachEnd"
        :error="error"
        @load="fetchMore"
        @retry="fetchData"
      )
  createCompanyIntegrationsModal(
    v-if="!active"
    ref="createCompanyIntegrationsModal"
    @done="refetchData"
  )
  CompanyIntegrationErrorsModal(ref="integrationErrorsModal" :errors="integration_errors")
  CompanyIntegrationLogsModal(ref="integrationLogsModal")
  CompanyIntegrationDetailsModal(ref="integrationDetailsModal")
  CompanyIntegrationDataRefreshModal(ref="integrationDataRefreshModal" @done="refetchData")
</template>

<script>
import { mapGetters } from 'vuex';
import Integrations from '@/api/models/Integrations.js';
import CreateCompanyIntegrationsModal from '@/modals/CompanyIntegrations/Create.vue';
import CompanyIntegrationErrorsModal from '@/modals/CompanyIntegrations/Errors.vue';
import CompanyIntegrationLogsModal from '@/modals/CompanyIntegrations/Log.vue';
import CompanyIntegrationDetailsModal from '@/modals/CompanyIntegrations/Details.vue';
import CompanyIntegrationDataRefreshModal from '@/modals/CompanyIntegrations/DataRefresh.vue';
import TabHeader from '@/components/Tabs/TabHeader.vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import IntegrationItem from './Integrations/IntegrationItem.vue';

const INTEGRATIONS_PER_PAGE = 30;

export default {
  components: {
    TabHeader,
    IntegrationItem,
    CreateCompanyIntegrationsModal,
    CompanyIntegrationErrorsModal,
    CompanyIntegrationLogsModal,
    CompanyIntegrationDetailsModal,
    CompanyIntegrationDataRefreshModal,
    InfiniteLoading,
  },
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      reachEnd: false,
      page: 1,
      integration_errors: [],
      integrations: [],
      inProgressElemIndex: null,
    };
  },

  computed: {
    ...mapGetters(['mobileView']),
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    showIntegrationsErrors() {
      this.$refs.integrationErrorsModal.open();
    },

    fetchData() {
      this.error = null;
      this.loading = true;
      new Integrations()
        .fetchAll({ page: this.page, per_page: INTEGRATIONS_PER_PAGE })
        .then((resp) => {
          const collection = resp.data.integrations;
          if (collection.length < INTEGRATIONS_PER_PAGE) {
            this.reachEnd = true;
          }
          this.integrations = [...this.integrations, ...collection];
          this.integration_errors = resp.data.errors;
        })
        .catch((error) => {
          this.error = error;
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
          this.inProgressElemIndex = null;
        });
    },

    fetchMore() {
      if (this.error || this.reachEnd || this.loading) {
        return;
      }
      this.page++;
      this.fetchData();
    },

    refetchData() {
      this.page = 1;
      this.integrations = [];
      this.integration_errors = [];
      this.fetchData();
    },

    openSetupIntegrationModal(integration) {
      this.$refs.createCompanyIntegrationsModal.open(integration.software, integration);
    },

    onConnect(integration) {
      if (integration.type === 'internal') {
        this.connect(integration);
        return;
      }
      this.openSetupIntegrationModal(integration);
    },

    connect(integration) {
      this.inProgressElemIndex = integration.software;
      new Integrations(null, { id: integration.id })
        .create({ software: integration.software })
        .then(() => {
          this.refetchData();
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.inProgressElemIndex = null;
        });
    },

    disconnect(integration) {
      this.inProgressElemIndex = integration.software;
      new Integrations(null, { id: integration.id })
        .delete()
        .then(() => {
          this.refetchData();
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        })
        .finally(() => {
          this.inProgressElemIndex = null;
        });
    },

    showLog() {
      this.$refs.integrationLogsModal.open();
    },

    showDetails(integration) {
      this.$refs.integrationDetailsModal.open(integration);
    },

    showDataRefresh(integration) {
      this.$refs.integrationDataRefreshModal.open(integration);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';
.integration-tab {
  padding: 1.5rem;
  background-color: var(--bb-app-bg-main);
  border-radius: 0 10px 10px 10px;
}

.intagrations__container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 20px;
}

.integration {
  &-col {
    align-self: stretch;
  }
}

@media screen and (max-width: 1000px) {
  h3,
  .h3 {
    font-size: 20px !important;
  }

  .integration {
    &-col {
      width: 100%;
      margin-right: 0;
    }
  }

  .integration-tab__logs {
    display: flex;
    align-items: center;

    & :nth-child(2n) {
      margin-left: 20px;
    }
  }
}
</style>
