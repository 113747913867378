<template lang="pug">
UiCard.card-company
  UiCardSection.text-center(padding="xl")
    div
      img.card-company__logo(v-if="company.logo" :src="company.logo")
      AvatarBox.card-company__logo-box(
        v-else
        :id="company.id"
        :label="company.name"
        size="md"
        data-test-id="avatarbox-company-card"
      )
    h5.card-company__title {{ company.name }}
    p.card-company__subtitle {{ company.business_id }}
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables.scss';

.card-company {
  cursor: pointer;

  &__logo {
    margin: auto;
    max-height: 44px;
    max-width: 250px;
  }

  &__logo-box {
    height: 44px;
    width: 44px;
    margin: auto;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
  }

  &__subtitle {
    color: var(--bb-gray-700);
    font-size: 14px;
    margin: 0;
  }
}
</style>
