<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :allowClose="false"
)
  .confirm-logo(v-if="!this.hideIcon")
    .confirm-icon
      UiIcon(name="Trash" size="30")
  p.confirm-text {{ text || $t('delete_confirm_question', { entity: $t('delete_confirm_question_placeholder') }) }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
    },

    hideIcon: {
      type: Boolean,
      default: false,
    },

    close: {
      type: Function,
      default: null,
    },

    confirm: {
      type: Function,
      default: null,
    },

    text: {
      type: String,
      default: null,
    },

    buttons: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      inProgress: false,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    leftActions() {
      return [
        {
          label: this.buttons?.cancelText ?? this.$t('actions.cancel'),
          variant: this.buttons?.cancelVariant ?? 'smooth-secondary',
          onClick: this.close,
          disabled: this.inProgress,
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.buttons?.confirmText ?? this.$t('actions.delete'),
          variant: this.buttons?.confirmVariant ?? 'smooth-danger',
          onClick: this.confirmAction,
          disabled: this.isViewAsOtherUser,
          loading: this.inProgress,
        },
      ];
    },
  },

  watch: {
    show(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.inProgress = false;
      }
    },
  },

  methods: {
    async confirmAction() {
      this.inProgress = true;
      try {
        await this.confirm();
      } catch (error) {
        this.$store.dispatch('toasts/add', {
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.confirm-logo {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 15px;
}

.confirm-icon {
  background-color: var(--bb-danger);
  padding: 0.75rem;
  border-radius: 50%;
  color: white;
}

.confirm-text {
  color: var(--bb-text-color);
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin: 0;
}
</style>
