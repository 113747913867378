import AppBaseModel from '@/api/models/AppBaseModel.js';

class AppInitData extends AppBaseModel {
  constructor(options, item) {
    const mergedOptions = {
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchInitData(params = {}, config = {}) {
    return this.httpClient.get(`${this.url}/init.json`, params, config);
  }
}

export default AppInitData;
