<template lang="pug">
UiStack(
  vertical
  spacing="lg"
  align="stretch"
)
  .form-section
    .form-section__main
      UiStack(align="center")
        UiStackItem(grow shrink)
          UiText(variant="headingMd") {{ $t('server_settings.amount_of_users_banner.title') }}
        UiButton(
          variant="outline-secondary"
          :icon="amountOfUsersBanner?.enabled ? 'EyeSlash' : 'Eye'"
          data-test-id="action-enable-banner"
          @click="toggleEnabledBanner"
        ) {{ bannerEnabledTitle }}
    .form-section__details.align-self-center(v-if="!mobileView")
      .text-secondary {{ $t('system_banners.preview') }}
  .form-section(data-test-id="section")
    .form-section__main
      UiStack(vertical align="stretch")
        .gray-card
          UiStack(v-if="!isEdit" align="start")
            UiStackItem(
              v-html="amountOfUsersBanner?.description"
              grow
              shrink
              data-test-id="amount-banner"
            )
            UiStackItem
              UiTooltip(:title="$t('actions.edit')" variant="dark")
                template(#activator)
                  UiButton(
                    size="xs"
                    variant="ghost-secondary"
                    icon="PencilEdit"
                    round
                    block
                    data-test-id="edit-button"
                    @click="edit"
                  )
          div(v-else)
            UiInputLabel {{ $t('server_settings.amount_of_users_banner.banner_text_input_label') }}
            UiEditor(v-model="amountOfUsersBannerValue" data-test-id="editor")
        UiStack(v-if="isEdit" justify="end")
          UiButton(
            variant="smooth-secondary"
            data-test-id="cancel-button"
            @click="cancel"
          ) {{ $t('actions.cancel') }}
          UiButton(data-test-id="save-button" @click="save") {{ $t('actions.save') }}
    .form-section__details
      SystemBanner(:banner="bannerPreview" isPreview)
</template>

<script>
import { mapGetters } from 'vuex';
import SystemBanner from '@/components/system-banner/system-banner.vue';
import UserLimitBanners from '@/api/models/user-limit-banners.js';

export default {
  components: {
    SystemBanner,
  },
  data() {
    return {
      loading: false,
      inProgress: false,
      isEdit: false,
      amountOfUsersBanner: null,
      amountOfUsersBannerValue: '',
    };
  },
  computed: {
    ...mapGetters(['mobileView']),
    bannerPreview() {
      return {
        banner_type: this.amountOfUsersBanner?.banner_type,
        description: this.isEdit
          ? this.amountOfUsersBannerValue
          : this.amountOfUsersBanner?.description,
      };
    },
    bannerEnabledTitle() {
      return this.amountOfUsersBanner?.enabled
        ? this.$t('server_settings.amount_of_users_banner.hide_banner')
        : this.$t('server_settings.amount_of_users_banner.show_banner');
    },
  },
  created() {
    this.fetchLimitBanners();
  },
  methods: {
    async fetchLimitBanners() {
      try {
        const response = await new UserLimitBanners({
          adminApi: true,
        }).fetchAll();

        this.amountOfUsersBanner = response.data.system_banners[0];
      } catch (error) {
        console.log(error);
      }
    },
    edit() {
      this.amountOfUsersBannerValue = this.amountOfUsersBanner?.description;
      this.isEdit = true;
    },
    cancel() {
      this.isEdit = false;
    },
    async save() {
      try {
        const params = {
          system_banner: {
            description: this.amountOfUsersBannerValue,
          },
        };
        await new UserLimitBanners(
          {
            adminApi: true,
          },
          { id: this.amountOfUsersBanner?.id },
        ).update(params);
        await this.fetchLimitBanners();
        this.isEdit = false;
      } catch (error) {
        console.log(error);
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
    async toggleEnabledBanner() {
      try {
        this.amountOfUsersBanner.enabled = !this.amountOfUsersBanner?.enabled;

        const params = {
          system_banner: {
            enabled: this.amountOfUsersBanner?.enabled,
          },
        };
        await new UserLimitBanners(
          {
            adminApi: true,
          },
          { id: this.amountOfUsersBanner?.id },
        ).update(params);
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-card {
  background: var(--bb-gray-950);
  padding: 1.5rem;
}
</style>
