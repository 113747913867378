import AppBaseModel from './AppBaseModel';

class ReportExportHistory extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'report_export_history',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default ReportExportHistory;
