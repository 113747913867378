import AppBaseModel from './AppBaseModel.js';

class CompanyUser extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'company_user',
      singularKey: null,
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default CompanyUser;
