<template lang="pug">
UiCardSection(padding="lg")
  .theme-tab
    .theme-tab-configs
      UiText(variant="headingMd") {{ $t('edit_company.powerbi_theme.title') }}
      UiAlert(
        v-if="!canUpload"
        variant="warning"
        :closable="false"
        data-vi="alert"
      ) {{ $t('errors.company_power_bi_reports_theme_max_count') }}
      UiFormInput(
        v-model.trim.lazy="v$.form.name.$model"
        :label="$t('edit_company.powerbi_theme.theme_name_label')"
        :vuelidateModel="v$.form.name"
        size="lg"
      )
      UiStack(justify="space-between" wrap)
        UiButton(
          :disabled="inProgress"
          :loading="loadings.copyTheme"
          data-vi="match-ui-btn"
          @click="copyCurrentThemeColors"
        ) {{ $t('edit_company.powerbi_theme.match_btn') }}
        UiButton(
          variant="secondary"
          data-vi="reset-btn"
          @click="resetColors"
        ) {{ $t('edit_company.powerbi_theme.reset_btn') }}
      UiText {{ $t('edit_company.powerbi_theme.instruction') }}
      UiCollapse(:label="$t('edit_company.powerbi_theme.main_colors')")
        CustomColorPicker(
          v-for="color in mainColors"
          :key="color.key"
          :disabled="inProgress"
          :color="this.form.mainColors[color.key]"
          :name="color.name"
          changeOnApplyOnly
          :description="color.description"
          @cancel="setFormMainColors({ [color.key]: $event })"
          @change="setFormMainColors({ [color.key]: $event })"
        )
      UiCollapse(:label="$t('edit_company.powerbi_theme.data_colors')")
        CustomColorPicker(
          v-for="(color, index) in form.dataColors"
          :disabled="inProgress"
          :color="color"
          :name="$t('edit_company.powerbi_theme.data_colors_name', { index: index + 1 })"
          changeOnApplyOnly
          @cancel="setFormDataColor(index, $event)"
          @change="setFormDataColor(index, $event)"
        )
      UiCollapse(:label="$t('edit_company.powerbi_theme.text_and_notification_colors')")
        CustomColorPicker(
          v-for="color in textColors"
          :disabled="inProgress"
          :color="this.form.textColors[color.key]"
          :name="color.name"
          changeOnApplyOnly
          @cancel="setTextColors({ [color.key]: $event })"
          @change="setTextColors({ [color.key]: $event })"
        )
      UiCollapse(:label="$t('edit_company.powerbi_theme.fonts')")
        .theme-tab-font-selectors.ui-form-field
          UiFormMultiselect(
            v-model="form.fonts.header.fontFamily"
            :disabled="inProgress"
            :label="$t('edit_company.powerbi_theme.header_font_label')"
            :canDeselect="false"
            :canClear="false"
            :options="fontOptions"
          )
          UiFormMultiselect(
            v-model="form.fonts.header.fontSize"
            :disabled="inProgress"
            :options="fontSizes"
            :label="$t('edit_company.powerbi_theme.header_font_size_label')"
            :canDeselect="false"
            :canClear="false"
            :createOption="true"
            :searchable="true"
            :onCreate="handleInputFontSize"
            data-vi="header-font-size"
          )
            template(#option="{ option }")
              span {{ option.value }} pt
            template(#singlelabel="{ value }")
              span {{ value.value }} pt
        .theme-tab-font-selectors.ui-form-field
          UiStackItem(grow)
            UiFormMultiselect(
              v-model="form.fonts.body.fontFamily"
              :disabled="inProgress"
              :label="$t('edit_company.powerbi_theme.body_font_label')"
              :canDeselect="false"
              :canClear="false"
              :options="fontOptions"
            )
          UiFormMultiselect(
            v-model="form.fonts.body.fontSize"
            :disabled="inProgress"
            :options="fontSizes"
            :label="$t('edit_company.powerbi_theme.body_font_size_label')"
            :canDeselect="false"
            :canClear="false"
            :createOption="true"
            :searchable="true"
            :onCreate="handleInputFontSize"
            data-vi="body-font-size"
          )
            template(#option="{ option }")
              span {{ option.value }} pt
            template(#singlelabel="{ value }")
              span {{ value.value }} pt

    .theme-tab-preview
      UiStack(justify="space-between")
        UiText {{ $t('edit_company.powerbi_theme.preview') }}
        .text-secondary {{ form.name }}
      PowerbiClient.w-100.h-100(
        v-if="preview && preview.embed_url"
        :key="preview.linkedReportId ?? preview.id"
        ref="preview"
        :id="preview.report_id"
        :embedUrl="preview.embed_url"
        :accessToken="preview.access_token"
        :expiration="preview.expiration"
        :reportType="preview.report_type"
        data-vi="powerbi-client"
      )
      template(v-else)
        .empty-text.h-100
          AnalyticsReportCrashed(v-if="accessError")
          template(v-else)
            UiStack(justify="center")
              UiSpinner(size="lg" grow)
    UiStack.w-100
      UiButton.w-165(
        :disabled="inProgress || accessError"
        :loading="loadings.saveTheme"
        @click="save"
      ) {{ $t('actions.save') }}
</template>

<script>
import {
  MAIN_REPORT_COLORS,
  DATA_REPORT_COLORS,
  TEXT_AND_NOTIFICATION_COLORS,
} from '@/helper/powerbi-themes.js';
import { defineComponent, ref } from 'vue';
import ColorPicker from '@/components/ColorPicker.vue';
import AnalyticsReportCrashed from '@/views/Analytics/components/analytics-report-crashed.vue';
import _cloneDeep from 'lodash/cloneDeep.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import UiThemes from '@/api/models/UiThemes.js';
import { DEFAULT_THEME_COLORS } from '@/helper/ui-themes';
import CustomColorPicker from '@/components/custom-color-picker.vue';
import { getAnalogousPalette } from '@/common/color';
import ApiReportThemes from '@/api/models/ReportThemes.js';
import parseResponseErrors from '@/common/parseResponseErrors';
import reportThemeDefault from '@/helper/powerbi-theme-template.json';
import { mapGetters } from 'vuex';

const MAX_AVAILABLE_THEME = 3;

export default defineComponent({
  components: { ColorPicker, CustomColorPicker, AnalyticsReportCrashed },
  setup() {
    const externalResults = ref({ form: {} });
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },
  data() {
    return {
      themesCount: 0,
      preview: null,
      accessError: false,
      loadings: {
        copyTheme: false,
        saveTheme: false,
      },
      form: {
        name: '',
        mainColors: { ...MAIN_REPORT_COLORS },
        dataColors: [...DATA_REPORT_COLORS],
        textColors: { ...TEXT_AND_NOTIFICATION_COLORS },
        fonts: {
          header: {
            fontSize: 12,
            fontFamily: 'Segoe UI',
          },
          body: {
            fontSize: 9,
            fontFamily: 'Segoe UI',
          },
        },
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters('company', ['currentCompany']),
    inProgress() {
      return this.loadings.copyTheme || this.loadings.saveTheme;
    },
    canUpload() {
      return this.themesCount < MAX_AVAILABLE_THEME;
    },
    mainColors() {
      return [
        {
          name: this.$t('edit_company.powerbi_theme.colors.shape.name'),
          description: this.$t('edit_company.powerbi_theme.colors.shape.description'),
          key: 'shape',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.slicer.name'),
          description: this.$t('edit_company.powerbi_theme.colors.slicer.description'),
          key: 'slicer',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.header.name'),
          description: this.$t('edit_company.powerbi_theme.colors.header.description'),
          key: 'header',
        },
      ];
    },
    textColors() {
      return [
        {
          name: this.$t('edit_company.powerbi_theme.colors.headerFontColor.name'),
          key: 'headerFontColor',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.bodyFontColor.name'),
          key: 'bodyFontColor',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.good.name'),
          key: 'good',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.bad.name'),
          key: 'bad',
        },
        {
          name: this.$t('edit_company.powerbi_theme.colors.neutral.name'),
          key: 'neutral',
        },
      ];
    },
    fontOptions() {
      return [
        'Arial',
        'Arial Black',
        'Arial Unicode MS',
        'Calibri',
        'Cambria',
        'Cambria Math',
        'Candara',
        'Consolas',
        'Constantia',
        'Corbel',
        'Courier New',
        'Georgia',
        'Lucida Sans Unicode',
        'Segoe UI',
        'Segoe UI Light',
        'Tahoma',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana',
      ];
    },
    reportTheme() {
      const { bodyFontColor, headerFontColor, good, bad, neutral } = this.form.textColors;
      const { shape, slicer, header } = this.form.mainColors;
      const { header: headerFont, body: bodyFont } = this.form.fonts;
      const { dataColors } = this.form;

      const reportTheme = _cloneDeep(reportThemeDefault);
      reportTheme.visualStyles.shape['*'].background[0].color.solid.color = shape;
      reportTheme.visualStyles.slicer['*'].items[0].background.solid.color = slicer;

      reportTheme.dataColors = dataColors;
      reportTheme.maximum = dataColors[0];
      reportTheme.center = dataColors[3];
      reportTheme.visualStyles.tableEx['*'].columnHeaders[0].backColor.solid.color = header;
      reportTheme.visualStyles.tableEx['*'].total[0].backColor.solid.color = header;
      reportTheme.visualStyles.pivotTable['*'].columnHeaders[0].backColor.solid.color = header;

      reportTheme.good = good;
      reportTheme.bad = bad;
      reportTheme.neutral = neutral;
      reportTheme.minimum = dataColors[7];
      reportTheme.visualStyles['*']['*'].title[0].fontColor.solid.color = bodyFontColor;
      reportTheme.visualStyles.slicer['*'].items[0].fontColor.solid.color = bodyFontColor;
      reportTheme.visualStyles.slicer['*'].header[0].fontColor.solid.color = headerFontColor;
      reportTheme.visualStyles.tableEx['*'].values[0].fontColorPrimary.solid.color = bodyFontColor;
      reportTheme.visualStyles.tableEx['*'].values[0].fontColorSecondary.solid.color =
        bodyFontColor;
      reportTheme.visualStyles.tableEx['*'].columnHeaders[0].fontColor.solid.color =
        headerFontColor;
      reportTheme.visualStyles.tableEx['*'].total[0].fontColor.solid.color = headerFontColor;
      reportTheme.visualStyles.pivotTable['*'].values[0].fontColorPrimary.solid.color =
        bodyFontColor;
      reportTheme.visualStyles.pivotTable['*'].values[0].fontColorSecondary.solid.color =
        bodyFontColor;
      reportTheme.visualStyles.pivotTable['*'].columnHeaders[0].fontColor.solid.color =
        headerFontColor;
      reportTheme.visualStyles.shape['*'].title[0].fontColor.solid.color = headerFontColor;

      reportTheme.visualStyles.shape['*'].title[0].fontFamily = headerFont.fontFamily;
      reportTheme.visualStyles['*']['*'].title.fontFamily = headerFont.fontFamily;

      reportTheme.visualStyles['*']['*'].title[0].fontSize = headerFont.fontSize;
      reportTheme.visualStyles.slicer['*'].header[0].textSize = bodyFont.fontSize;
      reportTheme.visualStyles.tableEx['*'].values[0].fontSize = bodyFont.fontSize;
      reportTheme.visualStyles.tableEx['*'].columnHeaders[0].fontSize = bodyFont.fontSize;
      reportTheme.visualStyles.pivotTable['*'].values[0].fontSize = bodyFont.fontSize;
      reportTheme.visualStyles.pivotTable['*'].columnHeaders[0].fontSize = bodyFont.fontSize;
      reportTheme.visualStyles.pivotTable['*'].rowHeaders[0].fontSize = bodyFont.fontSize;
      reportTheme.textClasses.label.fontSize = bodyFont.fontSize;

      reportTheme.textClasses.label.fontFace = bodyFont.fontFamily;
      reportTheme.textClasses.title.fontFace = headerFont.fontFamily;
      reportTheme.visualStyles.gauge['*'].title[0].fontFamily = headerFont.fontFamily;

      reportTheme.name = this.form.name || 'Custom';
      return reportTheme;
    },
    fontSizes() {
      return Array.from({ length: 20 }, (_, index) => index + 8);
    },
  },
  watch: {
    reportTheme: {
      handler(reportTheme) {
        try {
          const report = this.$refs.preview;
          if (report) {
            report.applyTheme(reportTheme);
          }
        } catch (error) {
          console.log(6262);
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchPreview();
    this.getThemesNumber();
  },
  methods: {
    async fetchPreview() {
      try {
        this.accessError = false;
        const response = await new ApiReportThemes().preview();
        const powerBiReport = response.data.power_bi_report;

        if (!powerBiReport.access_token) {
          this.accessError = true;
          return;
        }
        this.preview = powerBiReport;
      } catch (error) {
        this.accessError = true;
      }
    },
    /**
     * The method gets theme numbers that were created in the current company
     */
    async getThemesNumber() {
      try {
        const result = await new ApiReportThemes().fetchAll();
        this.themesCount = result.data.power_bi_report_themes.length;
      } catch {
        // do nothing
      }
    },
    async copyCurrentThemeColors() {
      this.loadings.copyTheme = true;
      try {
        const colors = await this.fetchCurrentTheme();
        this.setColorsFromUiTheme(colors);
      } catch (error) {
        console.log(87);

        try {
          this.$toaster.add({
            type: 'error',
            title: this.$t('status.error'),
            message: this.$localizeErrorMessage(error),
          });
        } catch (_error) {
          console.log(678);
        }
      } finally {
        this.loadings.copyTheme = false;
      }
    },
    resetColors() {
      this.form.mainColors = {
        ...MAIN_REPORT_COLORS,
      };
      this.form.dataColors = [...DATA_REPORT_COLORS];
      this.form.textColors = { ...TEXT_AND_NOTIFICATION_COLORS };
    },
    setColorsFromUiTheme(colors) {
      const primary = this.getPureHexValue(colors?.primary) || DEFAULT_THEME_COLORS.primary;
      const app_bg = this.getPureHexValue(colors?.app_bg) || DEFAULT_THEME_COLORS.app_bg;
      const text_color =
        this.getPureHexValue(colors?.text_color) || DEFAULT_THEME_COLORS.text_color;
      const info_color = this.getPureHexValue(colors?.info) || DEFAULT_THEME_COLORS.info;
      const good_color = this.getPureHexValue(colors?.success) || DEFAULT_THEME_COLORS.success;
      const danger_color = this.getPureHexValue(colors?.danger) || DEFAULT_THEME_COLORS.danger;
      this.form.mainColors = {
        shape: primary,
        slicer: app_bg,
        header: primary,
      };
      this.form.dataColors = [primary, ...getAnalogousPalette(primary)];

      this.form.textColors = {
        headerFontColor: '#ffffff',
        bodyFontColor: text_color,
        neutral: info_color,
        good: good_color,
        bad: danger_color,
      };
    },
    handleInputFontSize(option) {
      const intValue = parseInt(option.value, 10);
      if (Number.isNaN(intValue) || intValue > 40 || intValue < 1) {
        return false;
      }
      return { value: intValue, label: intValue };
    },
    /**
     * The method removes the alpha value from the hex color string, if it exists
     */
    getPureHexValue(hexWithAlpha) {
      if (hexWithAlpha?.[0] !== '#') return null;
      switch (hexWithAlpha.length) {
        case 4:
        case 7:
          return hexWithAlpha;
        case 5:
          return hexWithAlpha.slice(0, 4);
        case 9:
          return hexWithAlpha.slice(0, 7);
        default:
          return null;
      }
    },
    setFormMainColors(colors) {
      Object.keys(colors).forEach((key) => {
        if (key in MAIN_REPORT_COLORS) {
          this.form.mainColors[key] = this.getPureHexValue(colors[key]);
        }
      });
    },
    setFormDataColor(index, colorHex) {
      this.form.dataColors[index] = this.getPureHexValue(colorHex);
    },
    setTextColors(colors) {
      Object.keys(colors).forEach((key) => {
        if (key in TEXT_AND_NOTIFICATION_COLORS) {
          this.form.textColors[key] = this.getPureHexValue(colors[key]);
        }
      });
    },
    async save() {
      const valid = await this.v$.$validate();

      if (!valid) return;
      this.loadings.saveTheme = true;

      try {
        const formData = new FormData();
        const reportJson = JSON.stringify(this.reportTheme);
        const reportFileJson = new Blob([reportJson], { type: 'application/json' });
        formData.append('power_bi_report_theme[file]', reportFileJson);

        const response = await new ApiReportThemes().create(formData);
        this.$toaster.add({
          type: 'success',
          message: this.$t('edit_company.powerbi_theme.save_success'),
        });

        const { power_bi_report_theme } = response.data;
        const { report_themes } = this.currentCompany;
        report_themes.push(power_bi_report_theme);
        // adding themes to all themes available for the company
        this.$store.dispatch('company/restoreCompany', {
          id: this.currentCompany.id,
          report_themes,
        });

        await this.getThemesNumber();
      } catch (error) {
        const parsedResponseErrors = parseResponseErrors(error.response?.data?.errors);
        Object.assign(this.externalResults, {
          form: parsedResponseErrors,
        });
        const baseErrors = parsedResponseErrors?.base;
        const fileErrors = parsedResponseErrors?.file;

        if (baseErrors || fileErrors) {
          baseErrors?.forEach((baseErr) => {
            this.$toaster.add({
              type: 'error',
              title: this.$t('status.error'),
              message: this.$t(baseErr),
              timeout: 10000,
            });
          });
          fileErrors?.forEach((fileErr) => {
            this.$toaster.add({
              type: 'error',
              title: this.$t('status.error'),
              message: this.$t(fileErr),
              timeout: 10000,
            });
          });
        } else {
          this.$toaster.add({
            type: 'error',
            title: this.$t('status.error'),
            message: this.$localizeErrorMessage(error),
          });
        }
      } finally {
        this.loadings.saveTheme = false;
      }
    },
    async fetchCurrentTheme() {
      const response = await new UiThemes().fetch();
      const { ui_theme } = response.data;
      if (ui_theme) {
        return ui_theme.data.colors;
      }
      return null;
    },
  },
});
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.theme-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1rem;

  .theme-tab-configs {
    flex-basis: 40%;
    max-width: 500px;
    & > * {
      margin-top: 1.5rem;
    }
  }

  .theme-tab-preview {
    flex-basis: 60%;
    height: 800px;
    max-height: 85vh;
    width: 100%;
    flex-grow: 1;
  }

  .theme-tab-font-selectors {
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 1rem;

    .ui-form-field + .ui-form-field {
      margin: 0;
    }
  }
}

.empty-text {
  display: grid;
  place-content: center;
  text-align: center;
  white-space: pre-wrap;
}
</style>
