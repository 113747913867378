<template lang="pug">
UiBadge.domain-status-badge(:variant="statusVariant" data-test-id="domain-status-badge")
  Stack(spacing="sm")
    StackItem(v-if="statusIcon")
      UiSpinner(
        v-if="statusIcon === 'spinner'"
        speed="normal"
        data-test-id="status-icon-spinner"
      )
      UiIcon(
        v-else
        :name="statusIcon"
        data-test-id="status-icon"
      )
    StackItem(data-test-id="status-label") {{ statusLabel }}
</template>

<script>
const StatusLabel = {
  confirmed: 'company.domain.status_verified',
  failed: 'company.domain.status_failed',
  not_found: 'company.domain.status_not_found',
  waiting: 'company.domain.status_in_progress',
  validated: 'company.domain.status_generating_ssl',
  stored: 'company.domain.status_stored',
  unknown: 'company.domain.status_unknown',
};

const StatusBadgeVariant = {
  confirmed: 'success',
  failed: 'error',
  not_found: 'error',
  waiting: 'warning',
  validated: 'warning',
  unknown: 'secondary',
};

const StatusIcon = {
  confirmed: 'Check',
  failed: 'Error',
  not_found: 'Error',
  waiting: 'spinner',
  validated: 'spinner',
  stored: 'File',
};

export default {
  props: {
    status: {
      type: String,
      default: 'failed',
    },
  },
  computed: {
    statusLabel() {
      return this.$t(StatusLabel[this.status] ?? StatusLabel.unknown);
    },
    statusVariant() {
      return StatusBadgeVariant[this.status] ?? StatusBadgeVariant.unknown;
    },
    statusIcon() {
      return StatusIcon[this.status];
    },
  },
};
</script>

<style scoped>
.domain-status-badge {
  display: inline-block;
}
</style>
