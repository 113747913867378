<template lang="pug">
.step-actions
  div
    UiButton(
      v-if="attempt.software === 'netvisor'"
      variant="ghost"
      icon="ArrowLeft"
      data-test-id="button-move-back"
      @click="moveBack"
    ) {{ $t('onboarding.action_back') }}
  UiButton(
    variant="ghost"
    icon="CloseIcon"
    data-test-id="button-cancel"
    @click="$emit('cancel')"
  ) {{ $t('actions.cancel') }}
.step-container
  .step-header
    .step-title {{ $t('onboarding.summary.title', { integration_name: integrationName }) }}
    .step-description {{ $t('onboarding.summary.description', { company_name: targetCompany.name }) }}
  .step-content-container
    table.summary-table
      tbody
        tr
          td {{ $t('onboarding.create_company.vat_id_label') }}
          td {{ targetCompany.business_id }}
        tr
          td {{ $t('onboarding.create_company.company_name_label') }}
          td {{ targetCompany.name }}
    Stack(justify="space-between")
      StackItem
      StackItem
        UiButton(data-test-id="button-submit" @click="submit") {{ $t('onboarding.summary.action_confirm_connect') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import OnboardingStep from '../OnboardingStep.js';

export default {
  props: {
    attempt: {
      type: Object,
      required: true,
    },
    integrationName: {
      type: String,
      default: null,
    },
    targetCompany: {
      type: Object,
      default: null,
    },
  },
  data() {
    return { inProgress: true };
  },
  methods: {
    moveBack() {
      this.$emit('to-step', OnboardingStep.CHOOSE_COMPANY);
    },
    async submit() {
      this.inProgress = true;

      if (this.attempt.existed_integration) {
        this.$emit('to-step', OnboardingStep.INTEGRATION_EXISTS);
        this.$emit('progress', 85);
        return;
      }

      try {
        await new IntegrationAttempts(null, { id: this.attempt.id }).finalize(
          this.attempt.software,
        );
        this.$emit('progress', 100);
        this.$emit('to-step', OnboardingStep.SUCCESS_CONNECTION);
      } catch (e) {
        console.log(e);
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-content-container {
  max-width: 600px;
  margin: 0 auto;
}

.summary-table {
  width: 100%;
  margin-bottom: 30px;

  & > tbody > tr:nth-child(odd) {
    background: var(--bb-gray-900);
  }

  & > tbody > tr > td {
    padding: 15px 20px;
  }
}
</style>
