import AppBaseModel from './AppBaseModel.js';

class MasterKeys extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'master_keys',
      pluralKey: 'master_keys',
      singularKey: 'master_key',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default MasterKeys;
