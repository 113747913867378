<template lang="pug">
.integration-card
  .integration-card__header
    img.integration-logo(:src="integration.logo" data-test-id="integration-logo")
    UiSpinner(v-if="inProgress" data-test-id="integration-spinner")
    UiCheckbox(
      v-else
      v-model="checked"
      :disabled="isViewAsOtherUser"
      switch
      data-test-id="integration-checkbox"
      @change="connect"
    )
  .integration-card__body
    h3.card-title(data-test-id="integration-title") {{ title }}
    p(data-test-id="integration-description") {{ description }}
  .integration-card__footer
    UiBadge.pointer(
      v-if="integration.refresh_finished_message"
      variant="primary"
      data-test-id="integration-data-refresh-finished"
      @click="showDataRefresh"
    ) {{ $t('company.integrations.data_refresh_finished') }}
    UiBadge.pointer(
      v-else-if="integration.refresh_type === 'completed' && integration.can_refresh"
      variant="primary"
      data-test-id="integration-data-refresh-completed"
      @click="showDataRefresh"
    ) {{ $t('company.integrations.data_refresh') }}
    UiBadge.pointer(
      v-else-if="integration.refresh_type === 'error'"
      variant="error"
      data-test-id="integration-data-refresh-error"
      @click="showDataRefresh"
    ) {{ $t('company.integrations.refresh_error') }}
    UiBadge(
      v-else-if="integration.refresh_active"
      variant="warning"
      data-test-id="integration-data-refresh-error"
    ) {{ $t('company.integrations.data_refresh_active') }}
    span(v-else)
    UiPopover(
      v-if="actions.length"
      placement="bottom-end"
      :offset="[0, 6]"
    )
      template(#activator="{ show }")
        UiButton(
          :disabled="!actions.length"
          variant="ghost"
          :active="show"
          icon="DottedHorizontalIcon"
          border="smooth"
          round
          data-test-id="button-integration-item"
        )
      UiOptionsMenu
        UiOptionsMenuItem(
          v-for="action in actions"
          v-bind="action"
          data-test-id="menu-integration-item"
        ) {{ action.label }}
  ConfirmModal(
    v-if="showConfirm"
    :show="showConfirm"
    :text="$t('company.integrations.confirm_deactivate')"
    :confirm="confirmAction"
    :buttons="{ confirmText: $t('actions.deactivate') }"
    :close="closeConfirmModal"
    :hideIcon="true"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import MiniDropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';
import ConfirmModal from '@/modals/common/Confirm.vue';
import _isEmpty from 'lodash/isEmpty.js';

export default {
  components: {
    MiniDropdown,
    ConfirmModal,
  },

  emits: ['connect', 'disconnect', 'edit', 'details', 'refresh'],

  props: {
    integrationName: {
      type: String,
      default: null,
    },
    integration: {
      type: Object,
      required: true,
    },
    inProgress: {
      type: Boolean,
    },
  },

  data() {
    return {
      checked: !!this.integration.id,
      isOpen: false,
      showConfirm: false,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser', 'currentLanguage']),

    title() {
      return (
        this.integration.integration_details[`title_${this.currentLanguage.value}`] ||
        this.integration.integration_details.title_en
      );
    },

    description() {
      return (
        this.integration.integration_details[`desc_${this.currentLanguage.value}`] ||
        this.integration.integration_details.desc_en
      );
    },

    isActive() {
      return !!this.integration.id;
    },

    editable() {
      return !_isEmpty(this.integration.fields);
    },

    connected() {
      const connectStatus = !!this.integration.data;
      return connectStatus;
    },

    itemClasses() {
      const list = {};
      list.connected = this.connected;
      return list;
    },

    badge() {
      return {
        label: this.$t(`company.integrations.${this.connected ? 'connected' : 'not_connected'}`),
        variant: this.connected ? 'primary' : 'flat-secondary',
      };
    },

    actions() {
      const actions = [];
      const { refresh_types, refresh_type, refresh_enabled, id, integration_details } =
        this.integration;
      const showRefresh = refresh_enabled && !refresh_types.includes(refresh_type);

      if (showRefresh) {
        actions.push({
          icon: 'RefreshCcw',
          label: 'Refresh data',
          onClick: () => this.showDataRefresh(),
        });
      }

      if (this.editable && id) {
        actions.push({
          icon: 'PencilCreate',
          label: 'Edit',
          onClick: () => this.edit(),
        });
      }

      if (
        this.$te(`company.integrations.details.${this.integration.software}`) ||
        integration_details?.helper_text
      ) {
        actions.push({
          icon: 'FileText',
          label: 'Details',
          onClick: () => this.showDetails(),
        });
      }

      return actions;
    },
  },

  updated() {
    this.checked = this.isActive;
  },

  methods: {
    connect(val) {
      if (val) this.$emit('connect');
      else this.openConfirmModal();
    },

    edit() {
      this.$emit('edit');
    },

    showDetails() {
      this.$emit('details');
    },

    showDataRefresh() {
      this.$emit('refresh');
    },

    dropToggle() {
      this.isOpen = true;
    },

    dropClose() {
      this.isOpen = false;
    },

    openConfirmModal() {
      this.showConfirm = true;
    },

    closeConfirmModal() {
      this.showConfirm = false;
    },

    confirmAction() {
      this.$emit('disconnect');
      this.showConfirm = false;
    },
  },
};
</script>

<style lang="scss">
@use 'sass:color';
@import '@/assets/stylesheet/variables';

.integration-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px 12px 20px;
  box-shadow: 0px 0px 0px 1px rgba(var(--bb-shadow-rgb), 0.1),
    0px 2px 4px rgba(var(--bb-shadow-rgb), 0.3), 0px 5px 15px -5px rgba(var(--bb-shadow-rgb), 0.3);
  border-radius: 8px;
}

.integration-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.integration-card__body {
  flex: 1 0 auto;
}

.integration-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--bb-gray-900);
  padding-top: 12px;
  flex: 0 0 auto;
  position: relative;
}

.integration-card__footer button {
  align-items: center;

  &:focus {
    outline: none;
  }
}

.card-title {
  font-size: 18px;
  font-weight: 600;
}

.integration {
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 48px;
    color: $bb-gray-800;
    border-radius: 50%;
    background: color.mix($white, $bb-gray-900, 50%);
  }

  &-actions {
    margin-top: 30px;
  }

  &-item.card:not([class*='connected']) {
    box-shadow: none;
    border: 2px dashed $bb-gray-900;
  }

  &-item.connected.card {
    border: 2px dashed transparent;
  }

  &-item.connected & {
    &-icon {
      color: var(--bb-primary);
      background: color.mix($white, $primary, 95%);
    }
  }
}

.integration-logo {
  height: 42px;
  max-width: 80%;
}
</style>
