import AppBaseModel from './AppBaseModel.js';

class FastAccess extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      adminApi: true,
      path: 'fast_access',
      ...options,
    };
    super(mergedOptions, item);
  }

  updateBatch(key, params) {
    return this.httpClient.post(`${this.url}/${key}/update_batch`, params);
  }
}

export default FastAccess;
