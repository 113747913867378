<template lang="pug">
Modal(
  :show="show"
  :title="$t('companies.import_modal.title')"
  :close="close"
  :width="'440px'"
)
  template(#body)
    Stack(vertical align="stretch")
      StackItem
        UiFilePicker(
          v-model="files"
          :extensions="['csv', 'xls', 'xlsx']"
          :maxSize="3900"
          multiple
          :maxCount="1"
        )
      StackItem
        UiButton(variant="text-primary" @click.prevent="downloadExample")
          template(#icon)
            UiIcon(name="File" size="18")
          | {{ $t('companies.import_modal.see_an_example') }}
      StackItem
        .heads-up
          UiIcon.heads-up-icon(name="Warning" size="16")
          UiText {{ $t('companies.company_modal.import_warning') }}
      StackItem.import__actions
        Stack(justify="space-between")
          StackItem
            UiButton(variant="outline-primary" @click="close") {{ $t('companies.company_modal.btn_cancel') }}
          StackItem
            UiButton(:disabled="!files.length" @click="apply") {{ $t('companies.company_modal.btn_apply') }}
</template>

<script>
import Companies from '@/api/models/Companies.js';

export default {
  // todo redo this component as report themes
  name: 'Import',
  props: {
    downloadExample: Function,
  },
  data() {
    return {
      show: false,
      files: [],
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.removeFiles();
      this.show = false;
    },
    removeFiles() {
      this.files = [];
    },

    async apply() {
      const formData = new FormData();
      formData.append('file', this.files[0]);
      try {
        await new Companies({ adminApi: true }).import(formData);

        this.$toaster.add({
          title: 'Success',
          message: 'Companies were successfully updated',
        });

        this.$emit('wasCreated');

        this.close();
      } catch (error) {
        console.error(error);

        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables.scss';

.import__actions {
  margin-top: 1rem;
}
.heads-up {
  display: flex;
  gap: 15px;

  &-icon {
    color: $bb-warning-status;
    flex-shrink: 0;
    margin-top: 3px;
  }
}
</style>
