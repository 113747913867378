class CompanyFileData {
  constructor(data) {
    this.description = data.description ?? '';
    this.file_size = data.file_size ?? 0;
    this.identifier = data.identifier ?? null;
    this.uploaded_at = data.uploaded_at ?? null;
    this.validated = data.validated ?? false;
    this.validation_enabled = data.validation_enabled ?? false;
    this.hidden = data.hidden ?? false;
  }
}

export default CompanyFileData;
