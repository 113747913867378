import AppBaseModel from './AppBaseModel';

class AccessTreeFolders extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'access_tree_folders',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default AccessTreeFolders;
