import AppBaseModel from './AppBaseModel';

class PowerBiReportGroups extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'power_bi_report_groups',
      pluralKey: 'power_bi_report_groups',
      singularKey: 'report_group',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchTree(params, config) {
    return this.httpClient.get(`${this.url}/tree`, { params, ...config });
  }
}

export default PowerBiReportGroups;
