<template lang="pug">
UiModal(
  :title="$t('report_embedded_links.modals.copy.title')"
  :show="show"
  :width="540"
  @close="close"
)
  UiStack(
    vertical
    align="stretch"
    spacing="2xl"
  )
    UiStack(
      vertical
      align="stretch"
      spacing="xl"
    )
      UiStackItem
        UiText(variant="headingMd") {{ $t('report_embedded_links.embed_link_settings') }}
      UiStackItem
        UiInputLabel {{ $t('report_embedded_links.report_reload_interval') }}
        UiMultiselect(
          v-model="reportRefreshInterval"
          :options="reportRefreshIntervalOptions"
          :canClear="false"
        )
        UiInputFeedback(size="lg") {{ $t('report_embedded_links.report_reload_interval_description') }}
      UiStackItem
        UiInputLabel {{ $t('report_embedded_links.report_url_filter_param') }}
        UiInput(v-model="reportUrlFilterParam" multiline)
        UiInputFeedback(size="lg")
          span(v-html="$t('report_embedded_links.report_url_filter_param_description')")
    UiStack(
      vertical
      align="stretch"
      spacing="xl"
    )
      UiStackItem
        UiText(variant="headingMd") {{ $t('report_embedded_links.get_result') }}
      UiStackItem
        UiInputLabel {{ $t('report_embedded_links.modals.copy.label_embedded_link') }}
        .copy-embedded-link__input
          UiInput(v-model="embeddedLinkUrlWithParams" readonly)
        UiButton(
          variant="smooth-primary"
          size="sm"
          icon="CopyLink"
          @click="copyItemToClipboard(embeddedLinkUrlWithParams)"
        ) {{ $t('actions.copy_link') }}
      UiStackItem
        UiInputLabel {{ $t('report_embedded_links.modals.copy.label_embedded_link_code') }}
        .copy-embedded-link__input
          UiInput(
            v-model="iframeCode"
            size="sm"
            multiline
            readonly
            rows="9"
          )
        UiButton(
          variant="smooth-primary"
          size="sm"
          icon="Copy"
          @click="copyItemToClipboard(iframeCode)"
        ) {{ $t('actions.copy_code') }}
      UiStackItem
        UiInputLabel {{ $t('report_embedded_links.modals.copy.label_embedded_script_code') }}
        .copy-embedded-link__input
          UiInput(
            v-model="scriptCode"
            size="sm"
            multiline
            readonly
            rows="9"
          )
        UiButton(
          variant="smooth-primary"
          size="sm"
          icon="Copy"
          @click="copyItemToClipboard(scriptCode)"
        ) {{ $t('actions.copy_code') }}
</template>

<script>
import copyToClipboard from 'copy-to-clipboard';
import { ReportRefreshInterval } from '@/configs/report-refresh-interval.js';

export default {
  data() {
    return {
      reportRefreshInterval: 'off',
      reportUrlFilterParam: '',
      item: null,
      show: false,
      inProgress: false,
    };
  },

  computed: {
    embeddedLinkUrlWithParams() {
      const url = new URL('/embed/', window.location.origin);
      this.setupUrlParams(url);
      return url.href;
    },

    embeddedScriptUrlWithParams() {
      const url = new URL('/embed.js', window.location.origin);
      this.setupUrlParams(url);
      return url.href;
    },

    iframeCode() {
      return `<iframe src="${this.embeddedLinkUrlWithParams}" width="100%" height="540"></iframe>`;
    },

    scriptCode() {
      // This variable resolved vue single file component issue
      const tag = 'script';
      return `<${tag} src="${this.embeddedScriptUrlWithParams}" data-width="100%" data-height="540px"></${tag}>`;
    },

    reportRefreshIntervalOptions() {
      return ['off', ...Object.keys(ReportRefreshInterval)].map((key) => ({
        label: this.$t(`report_reload_interval.${key}`),
        value: key,
      }));
    },
  },

  methods: {
    setupUrlParams(url) {
      url.searchParams.set('token', this.item.token);
      if (this.reportRefreshInterval !== 'off') {
        url.searchParams.set('refresh', this.reportRefreshInterval);
      }
      if (this.reportUrlFilterParam) {
        url.searchParams.set('filter', this.reportUrlFilterParam);
      }
      return url;
    },

    open({ item }) {
      this.item = item;
      this.reportRefreshInterval = 'off';
      this.reportUrlFilterParam = '';
      this.show = true;
    },

    close() {
      this.show = false;
    },

    copyItemToClipboard(item) {
      const result = copyToClipboard(item);

      if (result) {
        this.$toaster.add({
          title: this.$t('copy_to_clipboard.success_title'),
          message: this.$t('copy_to_clipboard.success_description'),
          actions: null,
        });
      } else {
        this.$toaster.add({
          title: this.$t('copy_to_clipboard.error_title'),
          message: this.$t('copy_to_clipboard.error_description'),
          actions: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-embedded-link__input {
  margin-bottom: 12px;
}
</style>
