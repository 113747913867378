class PowerBiReportData {
  constructor(data) {
    this.editing = data.editing ?? false;
    this.order_num = data.order_num ?? null;
    this.pages = data.pages ?? [];
    this.personal = data.personal ?? false;
    this.report_group_id_pbi = data.report_group_id_pbi ?? null;
    this.report_id_pbi = data.report_id_pbi ?? null;
    this.report_type = data.report_type ?? 'standard_report';
    this.rls_rule_name = data.rls_rule_name ?? '';
    this.row_level_security = data.row_level_security ?? false;
    this.visible_in_analytics = data.visible_in_analytics ?? false;
    this.gs_id = data.gs_id ?? null;
    this.linked_report_id = data.linked_report_id ?? null;
  }
}

export default PowerBiReportData;
