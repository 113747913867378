const items = (state) => state.items;
const meta = (state) => state.meta;
const inProgress = (state) => state.inProgress;
const groupsIsReady = (state) => state.isReady;

export default {
  items,
  meta,
  inProgress,
  groupsIsReady,
};
