import AppBaseModel from './AppBaseModel';

class EventsLog extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'events_log',
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default EventsLog;
