<template lang="pug">
div(ref="target")
.mini-dropdown(
  v-bind="$attrs"
  ref="popper"
  :style="style"
)
  div(v-if="buttons")
    UiOptionsMenu
      UiOptionsMenuItem(
        v-for="el in buttons"
        :disabled="el.disabled"
        :data-vi="`button-${el.key}`"
        @click="isButtonAction(el)"
      )
        .mini-dropdown__item
          UiIcon(
            v-if="el.iconName"
            :name="el.iconName"
            size="20"
            :data-vi="`icon-name-${el.key}`"
          )
          img(
            v-else-if="el.icon"
            :src="el.icon"
            :data-vi="`icon-${el.key}`"
          )
          span {{ el.text }}
        img.mini-dropdown__img--after(
          v-if="el.iconAfter"
          :src="el.iconAfter"
          :data-vi="`icon-after-${el.key}`"
          @click="el.actionAfterIcon"
        )
        UiIcon.mini-dropdown__img--after(
          v-else-if="el.iconAfterName"
          :name="el.iconAfterName"
          :class="el.iconClass"
          size="20"
          :data-vi="`icon-after-name-${el.key}`"
        )
  slot(name="search")
  slot(name="list")
  slot(name="content")
Overlay(@close="this.$emit('close')")
</template>

<script>
import Overlay from '@/components/elements/overlay/Overlay.vue';
import { createPopper } from '@popperjs/core';
import { UiOptionsMenu, UiOptionsMenuItem } from '@bi-book/bibook-ui-kit';

const PLACEMENT_TYPES = [
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'right',
  'right-start',
  'right-end',
  'left',
  'left-start',
  'left-end',
];

export default {
  components: {
    UiOptionsMenu,
    UiOptionsMenuItem,
    Overlay,
  },

  emits: ['close'],

  props: {
    target: {
      type: Object, // node element
      default: null,
    },
    placement: {
      type: String,
      default: 'auto',
      validator(val) {
        return PLACEMENT_TYPES.includes(val);
      },
    },
    buttons: {
      type: Array,
      default: null,
    },
    style: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      popperInstance: null,
    };
  },
  mounted() {
    this.popperInstance = createPopper(this.target || this.$refs.target, this.$refs.popper, {
      placement: this.placement,
      strategy: 'fixed',
    });
  },
  beforeUnmount() {
    this.popperInstance.destroy();
  },
  methods: {
    action(func) {
      this.$emit('close');
      try {
        func();
      } catch {
        // do nothing
      }
    },

    isButtonAction(el) {
      if (el.buttonAction && !el.actionAfterIcon) {
        this.action(el.buttonAction);
      }

      if (el.action && !el.actionAfterIcon) {
        this.action(el.action);
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.mini-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  background-color: var(--bb-app-bg-main);
  box-shadow: 0 0 0 1px rgba(var(--bb-shadow-rgb), 0.15),
    0px 2px 4px rgba(var(--bb-shadow-rgb), 0.15), 0px 14px 24px rgba(var(--bb-shadow-rgb), 0.1);
  border-radius: 10px;
  z-index: 1000;
  height: fit-content;
  overflow: hidden;

  &__item:deep {
    display: flex;
    flex-grow: 1;

    .ui-icon {
      margin-right: 10px;
      color: var(--bb-primary);
    }
  }

  & button {
    display: flex;
    padding: 10px 15px;
    background-color: var(--bb-app-bg-main);
    text-align: left;
    border: none;
    align-items: center;
    width: 100%;
    border-radius: 0;

    & img {
      flex: 1;
      max-width: 20px;
      margin-left: 10px;
    }

    & span {
      flex: 2;
      margin: 0 10px;
    }

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: #f2f4f5;
    }
  }
}
</style>
