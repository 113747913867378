<template lang="pug">
UiModal(
  :title="$t('user_groups.modals.entity_access_list.root_access_apply_confirmation')"
  :show="show"
  :subtitle="$t('user_groups.modals.entity_access_list.root_access_apply_confirmation_subtitle')"
  :leftActions="leftActions"
  :rightActions="rightActions"
  :width="730"
  @close="close"
)
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['confirm'],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          variant: 'smooth-secondary',
        },
      ];
    },

    rightActions() {
      return [
        {
          label: this.$t('actions.confirm'),
          onClick: this.submit,
        },
      ];
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    submit() {
      this.$emit('confirm');
      this.close();
    },
  },
});
</script>
