<template lang="pug">
.no-have-company
  OnboardingBackgroundImage.bg-image
  form.no-have-company__form(data-test-id="form" @submit.prevent="submit")
    h3 {{ $t('no_have_company.header') }}
    p {{ $t('no_have_company.main_text') }}
    .no-have-company__form__fields
      UiAlert.no-have-company__alert(
        v-if="errorMessage"
        variant="error"
        :message="errorMessage"
        data-test-id="error-alert"
        @close="error = null"
      )
      UiFormInput(
        v-model="v$.company.business_id.$model"
        :label="$t('signup.business_id')"
        placeholder="123 456 789"
        :vuelidateModel="v$.company.business_id"
        data-test-id="input-business-id"
      )
      UiFormInput(
        v-model="v$.company.name.$model"
        :label="$t('signup.company_name')"
        :placeholder="'Name'"
        :vuelidateModel="v$.company.name"
        data-test-id="input-company-name"
        @blur="fillSubdomainField"
      )
      UiFormInput(
        v-model="company.email"
        :label="$t('signup.email')"
        :placeholder="'@mail'"
        :vuelidateModel="v$.company.email"
        data-test-id="input-company-email"
      )
      .ui-form-input
        UiInputLabel(size="lg") {{ $t('company.domain.subdomain_input_label') }}
        UiInputGroup
          UiInput(
            v-model="v$.company.company_domain_attributes.domain.$model"
            :error="v$.company.company_domain_attributes.domain.$error"
            data-test-id="input-company-domain"
            @blur="v$.company.company_domain_attributes.domain.$touch"
          )
          UiInputGroupText .{{ appDomain }}
        UiInputFeedback(v-if="!v$.company.company_domain_attributes.domain.$error") {{ $t('signup.subdomain_name_can_be_changed_later') }}
        UiInputFeedback(
          v-for="$error in v$.company.company_domain_attributes.domain.$errors"
          error
        ) {{ $t($error.$message) }}
      .no-have-company__form__terms
        UiFormCheckbox(
          v-model="confirmed"
          :vuelidateModel="v$.confirmed"
          data-test-id="checkbox-confirmed"
        )
          span {{ $t('signup.confirm_start') }}
          | &nbsp;
          a(
            :href="TERMS_OF_SERVICES_LINK"
            target="_blank"
            data-test-id="link-terms-page"
          ) {{ $t('signup.confirm_end') }}

      .no-have-company__form__footer
        UiButton(
          block
          :disabled="isViewAsOtherUser || inProgress"
          :loading="inProgress"
          data-test-id="btn-signup"
        ) {{ $t('signup.submit') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import OnboardingBackgroundImage from '@/assets/images/onboarding_background.vue';
import useVuelidate from '@vuelidate/core';
import { email, required, subdomain, checked } from '@/common/validators.js';
import { convertToSubdomainName } from '@/common/convert-to-subdomain-name.js';
import { TERMS_OF_SERVICES } from '@/common/constant-links.js';
import { reactive } from 'vue';
import parseResponseErrors from '@/common/parseResponseErrors.js';

export default {
  name: 'NoHaveCompany',
  components: { OnboardingBackgroundImage },
  setup() {
    const externalResults = reactive({ company: {} });
    const v$ = useVuelidate({ $externalResults: externalResults });
    return {
      v$,
      externalResults,
    };
  },
  data() {
    return {
      error: null,
      inProgress: false,
      confirmed: false,
      company: {
        business_id: '',
        name: '',
        email: '',
        company_domain_attributes: {
          domain: '',
          full: false,
        },
      },
      TERMS_OF_SERVICES_LINK: TERMS_OF_SERVICES,
    };
  },
  validations() {
    return {
      company: {
        business_id: { required },
        name: { required },
        email: { required, email },
        company_domain_attributes: {
          domain: { required, subdomain },
        },
      },
      confirmed: { checked },
    };
  },
  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser', 'appDomain']),

    errorMessage() {
      if (!this.error) {
        return null;
      }
      return this.$localizeErrorMessage(this.error);
    },
  },
  mounted() {
    if (this.user.current_integration_attempt) {
      this.company.name = this.user.current_integration_attempt.company_name || null;
    }
    this.fillSubdomainField();
  },

  methods: {
    fillSubdomainField() {
      if (this.company.name && !this.company.company_domain_attributes.domain) {
        this.company.company_domain_attributes.domain = convertToSubdomainName(this.company.name);
      }
    },

    async submit() {
      this.inProgress = true;
      const valid = await this.v$.$validate();
      if (!valid) {
        this.inProgress = false;
        return;
      }
      try {
        const response = await new Companies().create(this.company);
        if (response) {
          window.location.href = '/';
        }
      } catch (error) {
        if (error.response?.data?.errors) {
          const parsedErrors = parseResponseErrors(error.response.data.errors);
          Object.assign(this.externalResults, {
            company: {
              ...parsedErrors,
              company_domain_attributes: {
                domain: parsedErrors.domain,
              },
            },
          });
        } else {
          this.error = error;
        }
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-have-company {
  flex-grow: 1;
  flex-shrink: 0;
  background: var(--bb-gray-900);
  background-size: auto 200px;
  padding-top: 200px;

  .bg-image {
    display: block;
    margin: -210px auto 0;
    transform: translateY(10%);
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__form {
    position: relative;
    background-color: var(--bb-app-bg-main);
    max-width: 880px;
    width: 100%;
    margin: auto;
    padding: 50px;

    & p {
      font-size: 16px;
    }

    & > p {
      text-align: center;
    }

    & h3 {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__fields:deep {
      max-width: 430px;
      margin: 0 auto;

      .ui-form-input {
        margin-top: 2rem;
      }
    }
    &__terms {
      margin: 25px 0;

      & p {
        margin: 0;
      }

      & a {
        text-decoration: underline;
      }

      &__link {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-decoration: underline;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
    }
  }
}
</style>
