<template lang="pug">
.avatar-box(:class="avatarClasses")
  img(v-if="src" :src="src")
  NoAvatar(
    v-else
    :label="label"
    :color="this.color"
    :labelFormat="labelFormat"
  )
</template>

<script>
import NoAvatar from './NoAvatar.vue';

const colors = ['#6493FE', '#00B07D', '#E9A21A', '#ED7575'];
const colorsLibrary = {};

export default {
  name: 'AvatarBox',

  components: {
    NoAvatar,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    sqare: {
      type: Boolean,
      default: false,
    },
    labelFormat: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    color() {
      if (this.id === null) {
        return null;
      }
      if (this.id in colorsLibrary) {
        return colorsLibrary[this.id];
      }
      const color = colors[Math.floor(Math.random() * colors.length)];
      colorsLibrary[this.id] = color;
      return color;
    },
    avatarClasses() {
      return {
        [`avatar-box-${this.size}`]: !!this.size,
        'avatar-box--sqare': this.sqare,
        'avatar-box--bordered': this.bordered,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

.avatar-box {
  display: block;
  width: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--sqare {
    border-radius: 0;
  }

  &--bordered {
    border: 3px solid var(--bb-app-bg-main);
    box-shadow: rgba(var(--bb-shadow-rgb), 0.08) 0px 1px 3px,
      rgba(var(--bb-shadow-rgb), 0.04) 0px 5px 10px;
  }

  @each $key, $value, $index in $avatar-sizes {
    &-#{$key} {
      width: $value;
    }

    &-#{$key}#{&}--bordered {
      $pow: pow(1.012, strip-units($value)) * 1px;

      @if ($pow < 2) {
        $pow: 2px;
      }

      border: $pow solid var(--bb-app-bg-main);
    }
  }
}
</style>
