<template lang="pug">
.breadcrumps(v-if="breadcrumps.length")
  UiIcon.action_btn(
    name="ArrowLongLeftIcon"
    size="25"
    @click="back"
  )
  template(v-for="(crump, index) in breadcrumps" :key="index")
    span(v-if="index") /
    span(:class="isLastCrump(index)" @click="action(crump)") {{ crump.name }}
</template>

<script>
export default {
  emits: ['click'],
  props: {
    breadcrumps: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isLastCrump(index) {
      return {
        'last-crump': index + 1 === this.breadcrumps.length,
      };
    },

    action(crump) {
      if (!crump.action) {
        return this.$emit('click', crump.id);
      }

      return crump.action();
    },

    back() {
      const preLastCrumpIndex = this.breadcrumps.length - 2;
      if (preLastCrumpIndex > -1) {
        const backCrump = this.breadcrumps[preLastCrumpIndex];
        this.action(backCrump);
      } else {
        this.action(this.breadcrumps[0]);
      }
    },
  },
};
</script>

<style lang="scss">
.breadcrumps {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  & span {
    color: var(--bb-text-color);
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
  }

  & .last-crump {
    font-weight: 700;
    color: var(--bb-primary);
  }
}

.action_btn {
  cursor: pointer;
}
</style>
