<template lang="pug">
UiOptionsMenu.user-profile(v-if="user")
  UiOptionsMenuItem(
    v-if="!isViewAsOtherUser"
    icon="PencilEdit"
    @click="editUser"
  ) {{ $t('user.edit_profile') }}
  UiOptionsMenuDivider(v-if="!isViewAsOtherUser")
  UiOptionsMenuItem.user-profile-item__lang(@click.stop)
    LanguageSwitcher.user-profile__lang
  UiOptionsMenuDivider(v-if="!isViewAsOtherUser")
  UiOptionsMenuItem(
    v-if="!isViewAsOtherUser"
    icon="LogOut"
    @click="logout"
  ) {{ $t('user.logout') }}
  UiOptionsMenuDivider
  a(:href="TERMS_OF_SERVICES_LINK" target="_blank")
    UiOptionsMenuItem(icon="ClipboardText") {{ $t('user.terms_of_service') }}
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { TERMS_OF_SERVICES } from '@/common/constant-links.js';

export default {
  components: {
    LanguageSwitcher,
  },
  emits: ['startEditUser'],
  props: {
    showDropdown: {
      type: Boolean,
    },
  },
  data() {
    return {
      TERMS_OF_SERVICES_LINK: TERMS_OF_SERVICES,
    };
  },
  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    editUser() {
      this.$emit('startEditUser');
    },
  },
};
</script>

<style lang="scss">
.user-profile {
  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

.user-profile-item__lang {
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.user-profile__lang {
  padding: 0.5rem 1.25rem !important;

  & .multiselect__tags {
    border: none;
    border-radius: 0;
  }

  & .multiselect__content-wrapper {
    border-radius: 0;
  }

  & .multiselect__single {
    font-size: 14px;
  }

  & .ui-multiselect-wrapper {
    padding: 0;
    min-height: inherit;
    background: inherit;
    font-size: inherit;
    border-radius: 0;
  }

  & .ui-multiselect-dropdown {
    box-shadow: none;
    border: 0;
  }
}
</style>
