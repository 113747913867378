<template lang="pug">
UiCardSection(padding="lg")
  Stack(
    vertical
    align="stretch"
    spacing="3xl"
  )
    StackItem
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiAlert(
            variant="warning"
            :closable="false"
            :title="$t('company.domain.pay_attention_alert.title')"
            data-test-id="attention-alert"
          ) {{ $t('company.domain.pay_attention_alert.description') }}
    StackItem
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiText(variant="headingMd") {{ $t('company.domain.subdomain_section_title') }}
        StackItem
          .domain-form
            UiInputLabel {{ $t('company.domain.subdomain_input_label') }}
            Stack(align="start")
              StackItem(:fill="true" :shrink="true")
                UiInputGroup
                  UiInput(
                    :modelValue="company?.subdomain.domain"
                    :error="v$.company.subdomain.domain.$error"
                    data-test-id="subdomain-input"
                    @update:modelValue="changeSubdomain({ domain: $event })"
                    @blur="v$.company.subdomain.domain.$touch()"
                  )
                  UiInputGroupText(data-test-id="app-domain-input-text") .{{ appDomain }}
                UiInputFeedback(
                  v-for="$error in v$.company.subdomain.domain.$errors"
                  error
                  data-test-id="error-message"
                ) {{ $t($error.$message) }}
              StackItem
                UiButton(
                  :disabled="!company?.subdomain.domain || originalCompany.subdomain.domain === company.subdomain.domain || inProgress"
                  :loading="inProgress && saveStrategy === 'subdomain'"
                  data-test-id="subdomain-save-button"
                  @click="save('subdomain')"
                ) {{ $t('actions.save') }}
    StackItem
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiText(variant="headingMd" data-test-id="domain-title") {{ $t('company.domain.domain_section_title') }}
        StackItem
          UiCheckbox(
            :modelValue="company?.custom_domain_enabled"
            :disabled="inProgress"
            data-test-id="checkbox-custom-domain"
            @change="toggleCustomDomain"
          ) {{ $t('company.domain.checkbox_use_own_domain') }}
        Transition(name="fade")
          StackItem(v-if="company?.custom_domain_enabled")
            .domain-form
              UiInputLabel(data-test-id="custom-domain-label") {{ $t('company.domain.domain_input_label') }}
              Stack(align="start")
                StackItem(:fill="true" :shrink="true")
                  UiInput(
                    :modelValue="company.domain.domain"
                    :placeholder="$t('company.domain.domain_input_placeholder')"
                    :error="v$.company.domain.domain.$error"
                    data-test-id="custom-domain-input"
                    @update:modelValue="changeDomain({ domain: $event })"
                    @blur="v$.company.domain.domain.$touch()"
                  )
                  UiInputFeedback(v-for="$error in v$.company.domain.domain.$errors" error) {{ $t($error.$message) }}
                StackItem
                  UiButton(
                    :disabled="!company.domain.domain || originalCompany.domain.domain === company.domain.domain || inProgress"
                    :loading="inProgress && saveStrategy === 'domain'"
                    data-test-id="custom-domain-save-button"
                    @click="save('domain')"
                  ) {{ $t('actions.save') }}
        Transition(name="fade")
          StackItem(
            v-if="company?.custom_domain_enabled && originalCompany.domain.state && originalCompany.domain.domain === company.domain.domain"
          )
            Stack(
              v-if="![CompanyDomainStatus.CONFIRMED, CompanyDomainStatus.VALIDATED].includes(company.domain.state)"
              justify="end"
            )
              StackItem
                UiButton(
                  :disabled="inProgress || company.domain.state === CompanyDomainStatus.FAILED"
                  :loading="validateDNSInProgress"
                  data-test-id="verify-dns-button"
                  @click="validateDNS"
                ) {{ $t('company.domain.action_verify_dns') }}
            UiInputLabel(size="sm" data-test-id="record-label") {{ $t('company.domain.cname_record_input_label') }}
            DomainDNSRecordField(
              :value="cnameDomain"
              :status="company.domain.state"
              data-test-id="domain-dns-record"
            )
    StackItem
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiText(variant="headingMd" data-test-id="domain-history-title") {{ $t('company.domain.domain_history') }}
        StackItem
          DomainsHistoryTable(ref="domainsHistoryTable" data-test-id="domain-history-table")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required , domain, subdomain } from '@/common/validators.js';
import { ref } from 'vue';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import ApiAdminCompanyDomains from '@/api/admin/company_domains.js';
import ApiCompanyDomains from '@/api/company_domains.js';
import CompanyDomainStatus from '@/helper/CompanyDomainStatus.js';
import CompanyDomains from '@/api/models/CompanyDomains.js';
import Companies from '@/api/models/Companies.js';
import DomainsHistoryTable from '../DomainsHistoryTable.vue';
import DomainDNSRecordField from '../DomainDNSRecordField.vue';

export default {
  name: 'TabDomain',

  components: {
    DomainsHistoryTable,
    DomainDNSRecordField,
  },

  setup() {
    const externalResults = ref({});
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },

  data() {
    return {
      validateDNSInProgress: false,
      saveStrategy: null,
    };
  },

  validations() {
    return {
      company: {
        subdomain: {
          domain: { required, subdomain },
        },
        domain: {
          domain: { required, domain },
        },
      },
    };
  },

  computed: {
    CompanyDomainStatus() {
      return CompanyDomainStatus;
    },
    ...mapGetters(['isAdmin', 'mobileView', 'appDomain', 'cnameDomain', 'isAdminHost']),
    ...mapGetters('editCompany', ['company', 'loading', 'originalCompany', 'inProgress']),
    ...mapGetters('company', ['currentCompany']),
  },
  methods: {
    ...mapActions('editCompany', ['updateCompany']),
    ...mapMutations('editCompany', ['UPDATE_COMPANY', 'SET_ORIGINAL_COMPANY']),

    changeDomain(values) {
      this.UPDATE_COMPANY({
        ...this.company,
        domain: {
          ...this.company.domain,
          ...values,
        },
      });
    },

    changeOriginalDomain(values) {
      this.SET_ORIGINAL_COMPANY({
        ...this.originalCompany,
        domain: {
          ...this.originalCompany.domain,
          ...values,
        },
      });
    },

    changeSubdomain(values) {
      this.UPDATE_COMPANY({
        ...this.company,
        subdomain: {
          ...this.company.subdomain,
          ...values,
        },
      });
    },

    async validateDNS() {
      this.validateDNSInProgress = true;
      try {
        let validateDNSResponse;
        if (this.isAdmin) {
          validateDNSResponse = await ApiAdminCompanyDomains.validateDNS(
            this.$route.params.company_id,
          );
        } else {
          validateDNSResponse = await ApiCompanyDomains.validateDNS();
        }
        this.changeDomain(validateDNSResponse.data.company_domain);
        this.validateDNSInProgress = false;
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('company.domain.validate_dns_running'),
        });
      } catch (error) {
        this.validateDNSInProgress = false;
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$t('errors.server_error_try_later'),
        });
      }
    },

    async toggleCustomDomain() {
      this.saveStrategy = null;
      try {
        await this.updateCompany({ custom_domain_enabled: !this.company.custom_domain_enabled });
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t(
            !this.company.custom_domain_enabled
              ? 'company.domain.custom_domain_disabled'
              : 'company.domain.custom_domain_enabled',
          ),
        });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$t('errors.server_error_try_later'),
        });
      }
    },

    async save(strategy) {
      this.saveStrategy = strategy;
      const isFull = strategy === 'domain';
      const validator = isFull ? this.v$.company.domain : this.v$.company.subdomain;
      delete this.externalResults.company;
      const valid = await validator.$validate();
      if (!valid) {
        return;
      }
      try {
        const results = await new CompanyDomains({
          owner: this.isAdmin ? new Companies({}, { id: this.company.id }) : null,
          adminApi: this.isAdmin,
        }).save({
          domain: isFull ? this.company.domain.domain : this.company.subdomain.domain,
          full: isFull,
        });

        if (!this.isAdmin && !isFull && window.location.host.includes(this.appDomain)) {
          window.location = `${window.location.protocol}//${this.company.subdomain.domain}.${this.appDomain}`;
        }

        if (isFull) {
          this.changeDomain(results.data.company_domain);
          this.changeOriginalDomain(results.data.company_domain);
        }

        this.$refs.domainsHistoryTable.fetchData();
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t(
            isFull
              ? 'company.domain.domain_has_been_saved'
              : 'company.domain.subdomain_has_been_saved',
          ),
        });
      } catch (error) {
        if (error.response.data.error) {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        }
        if (error.response.data.errors) {
          const errors = parseResponseErrors(error.response.data.errors);
          const externalResults = { company: {} };
          if (errors.domain) {
            externalResults.company[isFull ? 'domain' : 'subdomain'] = errors;
          }
          this.externalResults.company = externalResults.company;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.domain-form {
  max-width: 510px;
}

.overlap-button {
  margin-left: -40px;
  z-index: 2;
}

.overlap-input {
  padding-right: 30px;
}
</style>
