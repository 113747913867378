import AppBaseModel from './AppBaseModel';

class Folders extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'folders',
      singularKey: 'folder',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchTree(params, config) {
    return this.httpClient.get(`${this.url}/tree`, { params, ...config });
  }
}

export default Folders;
