import _get from 'lodash/get.js';

export const roles = Object.freeze({
  ADMIN: 'admin',
  USER: 'user',
});

class Company {
  constructor(p = {}) {
    this.id = _get(p, 'id', null);
    this.name = _get(p, 'name', null);
    this.email = _get(p, 'email', null);
    this.business_id = _get(p, 'business_id', null);
    this.role = _get(p, 'role', null);
    this.dataroom_enabled = _get(p, 'dataroom_enabled', null);
    this.files_enabled = _get(p, 'files_enabled', null);
    this.can_create_dataroom = _get(p, 'can_create_dataroom', false);
    this.logo = _get(p, 'logo', null);
    this.reportDataColor = _get(p, 'report_data_color', 'Default'); // artefact
    this.reportDarkTheme = _get(p, 'report_dark_theme', false);
    this.reportLanguage = _get(p, 'report_language', null);
    this.userReportLanguage = _get(p, 'user_report_language', null);
    this.notificationsCount = _get(p, 'notifications_count', 0);
    this.unreadNotificationsCount = _get(p, 'unread_notifications_count', 0);
    this.reports_enabled = _get(p, 'reports_enabled', true);
    this.integrations_enabled = _get(p, 'integrations_enabled', true);
  }
}
Company.role = roles;
Company.isUser = (role) => role === roles.USER;
Company.isAdmin = (role) => role === roles.ADMIN;

export default Company;
