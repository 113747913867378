const SET_COMPANY = (state, value) => {
  state.company = value;
};
const SET_APP_READY = (state, value) => {
  state.appReady = value;
};

const UPDATE_COMPANY = (state, value) => {
  state.company = {
    ...state.company,
    ...value,
  };
};

export default {
  SET_COMPANY,
  SET_APP_READY,
  UPDATE_COMPANY,
};
