<template lang="pug">
Modal(
  v-if="show"
  :title="$t('dataroom.table.adding_row')"
  :show="show"
  :close="close"
)
  template(#body)
    UiFormInput(v-model="form.displayname" :label="$t('dataroom.table.row.displayname')")
    UiFormMultiselect(
      v-if="isAdmin"
      v-model="form.visible"
      :options="visibleOptions"
      custom-label-key="text"
      :label="$t('dataroom.table.row.visible')"
      :disabled="inProgress"
    )
    UiFormMultiselect(
      v-if="isAdmin"
      v-model="form.editable"
      :label="$t('dataroom.table.row.editable')"
      :options="editableOptions"
      custom-label-key="text"
      :disabled="inProgress"
    )
  template(#footer)
    UiButton(
      :disabled="inProgress"
      :loading="inProgress"
      width
      @click="save"
    ) {{ $t('actions.save') }}
</template>
<script>
function form() {
  return {
    displayname: '',
    visible: 'ALL',
    editable: 'ALL',
  };
}
export default {
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
      form: form(),
      inProgress: false,
    };
  },
  computed: {
    visibleOptions() {
      return [
        { label: this.$t('dataroom.table.row.visibleOptions.all'), value: 'ALL' },
        { label: this.$t('dataroom.table.row.visibleOptions.admin'), value: 'ADMIN' },
        { label: this.$t('dataroom.table.row.visibleOptions.none'), value: 'NONE' },
      ];
    },
    editableOptions() {
      return [
        { label: this.$t('dataroom.table.row.editableOptions.all'), value: 'ALL' },
        { label: this.$t('dataroom.table.row.editableOptions.admin'), value: 'ADMIN' },
        { label: this.$t('dataroom.table.row.editableOptions.none'), value: 'NONE' },
      ];
    },
  },
  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
      this.form = form();
      this.inProgress = false;
    },
    save() {
      this.inProgress = true;
      this.$emit('createNewRow', this.form);
      this.inProgress = false;
      this.close();
    },
  },
};
</script>
