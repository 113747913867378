<template lang="pug">
UiMultiselect(
  v-bind="$attrs"
  :options="optionsComputed"
  :canDeselect="canDeselect"
  :canClear="canClear"
)
  template(v-slot:singlelabel="{ value }")
    .ui-multiselect-single-label
      Stack(align="center" spacing="xs")
        StackItem
          AvatarBox(
            sqare
            :src="value.img"
            :id="value.value"
            :label="value.label"
            size="xs"
          )
        StackItem
          | {{ value.label }}
  template(v-slot:option="{ option }")
    Stack(align="center" spacing="xs")
      StackItem
        AvatarBox(
          sqare
          :src="option.img"
          :id="option.value"
          :label="option.label"
          size="xs"
        )
      StackItem
        | {{ option.label }}
</template>

<script>
import locales from '@/localization/locales.js';

export default {
  inheritAttrs: true,
  props: {
    options: {
      type: [Array, Object, Function],
      default: null,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    optionsComputed() {
      return this.options || locales;
    },
  },
};
</script>
