<template lang="pug">
UiModal(
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="460"
  :allowClose="false"
)
  template(#title)
    div(
      v-html="$t('user_groups.modals.entity_access_list.remove_entity_access_confirmation', { name })"
    )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Entities from '@/api/models/Entities.js';
import UserGroups from '@/api/models/UserGroups.js';
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies';

export default defineComponent({
  emits: ['done'],
  data() {
    return {
      show: false,
      type: '',
      name: '',
      id: null,
      userGroupId: null,
      inProgress: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('confirm'),
          onClick: this.submit,
          loading: this.inProgress,
          disabled: this.inProgress,
          'data-vi': 'submit-btn',
        },
      ];
    },
  },
  methods: {
    open({ entity, userGroupId }) {
      this.show = true;
      this.type = entity.type;
      this.name = entity.name;
      this.id = entity.id;
      this.userGroupId = userGroupId;
    },
    close() {
      this.show = false;
    },
    async submit() {
      try {
        this.inProgress = true;
        await new Entities(
          {
            owner: this.isAdmin
              ? new UserGroups(
                  { owner: new Companies(null, { id: this.$route.params.company_id }) },
                  { id: this.userGroupId },
                )
              : new UserGroups(null, { id: this.userGroupId }),
            adminApi: this.isAdmin,
          },
          { id: this.id },
        ).delete();
        this.close();
        this.$emit('done');
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
});
</script>
