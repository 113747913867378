<template lang="pug">
.dataroom-entry.dataroom-forms
  .dataroom-forms__header
    h2 {{ $t('menu.forms') }}
    StackItem(v-if="isAdmin && form && form.refresh_enabled")
      UiBadge.cursor-pointer(
        v-if="form.refresh_finished_message"
        variant="primary"
        size="lg"
        @click="openRefreshModal"
      ) {{ $t('dataroom.refresh_form_finished') }}
      UiBadge.cursor-pointer(
        v-else-if="form.refresh_status === 'error'"
        variant="error"
        @click="openRefreshModal"
      ) {{ $t('dataroom.refresh_error') }}
      UiBadge(v-else-if="form.refresh_active" variant="warning") {{ $t('dataroom.refresh_form_active') }}
      UiBadge.cursor-pointer(v-else-if="form.can_refresh" @click="openRefreshModal") {{ $t('dataroom.refresh_form') }}

    Stack(v-if="canRemoveForm")
      StackItem
        UiButton(
          v-if="!isViewAsOtherUser"
          variant="smooth-primary"
          icon="Users"
          @click="openDataRoomAccessListModal"
        ) {{ $t('actions.manage_access') }}
      StackItem
        UiButton(
          :disabled="isViewAsOtherUser || inRemoveProgress"
          :loading="inRemoveProgress"
          variant="smooth-danger"
          icon="Trash"
          @click="openRemoveConfirmModal"
        ) {{ $t('dataroom.remove_form') }}

  .dataroom-entry__container(v-if="form")
    .dataroom-entry__right
      Stack
        StackItem(fill)
          h3 {{ form.name }}
        StackItem(v-if="formWithTable")
          UiButton(
            :disabled="inProgress || !isActiveSaveBtn() || isViewAsOtherUser"
            :loading="inProgress"
            width
            @click="save"
          ) {{ $t('actions.save_changes') }}
      DataroomForm(:form="form" :isAdmin="isAdmin")
      .dataroom-entry__footer
        UiButton(
          :disabled="inProgress || !isActiveSaveBtn() || isViewAsOtherUser"
          :loading="inProgress"
          @click="save"
        ) {{ $t('actions.save_changes') }}
  UiEmptyState(
    v-else-if="!inProgress"
    style="min-height: 14rem"
    :title="error"
  )
  ConfirmModal(
    v-if="canRemoveForm"
    :show="showRemoveConfirm"
    :text="this.$t('dataroom.remove_confirm')"
    :confirm="remove"
    :buttons="{ confirmText: $t('delete') }"
    :close="closeRemoveConfirmModal"
  )
  DataroomRefreshModal(ref="dataroomRefreshModal" @done="fetchData")
  EntityAccessListModal(ref="entityAccessListModal")
</template>

<script>
import { mapGetters } from 'vuex';
import EntityAccessListModal from '@/modals/UserGroups/entity-access-list/entity-access-list-modal.vue';
import locales from '@/localization/locales.js';
import Company from '@/models/Company.js';
import ConfirmModal from '@/modals/common/Confirm.vue';
import DataroomRefreshModal from '@/modals/Dataroom/DataRefresh.vue';
import DataroomForm from '@/components/Dataroom/Form.vue';
import Dataroom from '@/api/models/Dataroom.js';
import DataroomForms from '@/api/models/DataroomForms.js';

export default {
  name: 'DataroomEntry',
  components: {
    ConfirmModal,
    DataroomRefreshModal,
    DataroomForm,
    EntityAccessListModal,
  },

  data() {
    return {
      form: null,
      inProgress: false,
      formLanguage: {},
      isAdmin: false,
      canRemoveForm: false,
      showRemoveConfirm: false,
      inRemoveProgress: false,
      result: {},
      error: null,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),

    languageAbbr() {
      const locale = this.formLanguage;
      return locale ? locale.value : '...';
    },

    languageIcon() {
      const locale = this.formLanguage;
      return locale ? locale.img : '...';
    },

    formWithTable() {
      return this.form && !!this.form.fields.find((field) => field.fieldtype === 'TABLE');
    },
  },

  mounted() {
    this.isAdmin = Company.isAdmin(this.currentCompany.role);
    this.fetchData();
  },

  methods: {
    openDataRoomAccessListModal() {
      this.$refs.entityAccessListModal.open({
        type: 'DataroomForm',
        entity: this.form,
      });
    },

    fetchData() {
      const formId = this.$route.params.id;
      const entryId = this.$route.params.entry_id;
      const params = {};
      this.inProgress = true;
      if (entryId) params.entry_id = entryId;
      new Dataroom(null, { id: formId })
        .fetch(params)
        .then((resp) => {
          this.form = resp.data.dataroom_form;
          this.checkSingleType();
          this.formLanguage = locales.find((l) => l.value === this.form.language);
        })
        .catch((error) => {
          this.error = this.$localizeErrorMessage(error);
        })
        .finally(() => {
          this.inProgress = false;
        });
    },

    checkSingleType() {
      if (this.form.single_entry) {
        this.canRemoveForm = this.isAdmin;
      } else if (!this.$route.params.entry_id || !this.form.multi_entries_visible) {
        this.$router.push({ name: 'Dataroom' });
      }
    },

    getResult() {
      const result = {};
      const res = {};
      this.form.fields.forEach((value) => {
        res[value.name] = this.form.results[value.name];
      });
      result.data = res;
      const { entry_id } = this.$route.params;
      if (entry_id) result.entry_id = entry_id;
      return result;
    },

    save() {
      this.inProgress = true;
      new Dataroom(null, { id: this.form.id })
        .update(this.getResult())
        .then((_resp) => {
          this.inProgress = false;
          this.$router.push({ name: 'Dataroom' });
        })
        .catch(() => {
          this.inProgress = false;
        });
    },

    isActiveSaveBtn() {
      if (this.form.editable === 'ALL') return true;
      return this.isAdmin && this.form.editable === 'ADMIN';
    },
    openRemoveConfirmModal() {
      // TODO: use src/modals/Dataroom/delete-dataroom-modal.vue instead of confirm modal
      this.showRemoveConfirm = true;
    },

    closeRemoveConfirmModal() {
      this.showRemoveConfirm = false;
    },

    openRefreshModal() {
      this.$refs.dataroomRefreshModal.open(this.form, this.$route.params.entry_id);
    },
    remove() {
      this.closeRemoveConfirmModal();
      this.inRemoveProgress = true;
      new DataroomForms(null, { id: this.form.id })
        .delete()
        .then((_resp) => {
          this.$router.push({ name: 'Dataroom' });
        })
        .finally(() => {
          this.inRemoveProgress = false;
        });
    },
  },
};
</script>
