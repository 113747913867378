<template lang="pug">
.overlay(:style="{ background: backgroundColor }" @click="this.$emit('close')")
</template>

<script>
export default {
  emits: ['close'],
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  //background: rgba($color: #283040, $alpha: 0.2);
  z-index: 920;
}
</style>
