<template lang="pug">
.application-layout(:class="classes")
  .application-layout__banners
    SystemBanner.application-layout__banner(
      v-for="banner in systemBanners"
      :key="banner.id"
      :banner="banner"
    )
  .application-layout__wrapper
    .application-layout__sidebar
      AppSidebar
    .application-layout__main
      .application-layout__header
        AppHeader
      .application-layout__content
        .application-layout__scroll-container
          RouterView
</template>

<script>
import { mapGetters } from 'vuex';
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import SystemBanner from '@/components/system-banner/system-banner.vue';

export default {
  name: 'ApplicationLayout',
  components: {
    AppSidebar,
    AppHeader,
    SystemBanner,
  },

  data() {
    return {
      isOpenMobileMenu: false,
    };
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'currentLanguage',
      'navOpen',
      'headerOpen',
      'mobileView',
      'laptopView',
    ]),
    ...mapGetters('company', ['currentCompanyId', 'systemBanners']),

    classes() {
      return {
        'application-layout--has-sidebar': this.isAuthenticated,
        'application-layout--sidebar-open': this.isAuthenticated && this.navOpen,
        'application-layout--header-open': this.headerOpen,
      };
    },
  },

  mounted() {
    this.showSelectedReport();
  },

  methods: {
    showSelectedReport() {
      if (window.selected_company_id && window.selected_report_id) {
        this.$store.dispatch('company/switchCompany', window.selected_company_id);
        window.selected_company_id = null;
        this.$router.push({ name: 'Analytics' });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.application-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__banners {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__banner + &__banner {
    margin-top: 2px;
  }

  &__wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: stretch;
  }

  &__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: stretch;
    z-index: 901;
  }

  &__main {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__header {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 900;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    position: relative;
  }

  &__scroll-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
