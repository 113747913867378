import ReportExportHistory from '@/api/models/ReportExportHistory.js';
import downloadFileByUri from '@/common/download-file-by-uri.js';
import i18n from '@/localization/index.js';
import { UiToaster } from '@bi-book/bibook-ui-kit';

export default {
  namespaced: true,

  state: {
    exportReportId: null,
    toastId: null,
    pingRequest: null,
  },

  actions: {
    run(store, { exportReportId }) {
      store.commit('SET_EXPORT_REPORT_ID', exportReportId);
      store.commit('SET_PING_REQUEST');
      store.dispatch('createInProgressToast');
      store.dispatch('createPingRequest');
    },

    createPingRequest(store) {
      const request = new ReportExportHistory(null, { id: store.state.exportReportId })
        .fetch()
        .then((response) => {
          const result = response.data?.report_export_history;
          if (!result) {
            store.dispatch('handleError', { reason: 'wrong_response' });
            return;
          }
          switch (result.status) {
            case 'completed':
              store.dispatch('handleCompleted', result);
              break;

            case 'failed':
              store.dispatch('handleError', { reason: 'export_failed' });
              break;

            default:
              setTimeout(() => {
                store.dispatch('createPingRequest');
              }, 5000);
              break;
          }
        })
        .catch(() => {
          store.dispatch('handleError', { reason: 'server_error' });
        });
      store.commit('SET_PING_REQUEST', request);
    },

    handleCompleted(store, payload) {
      store.dispatch('destroyToast');
      store.dispatch('createSuccessToast');
      downloadFileByUri(payload.link);
    },

    handleError(store) {
      store.dispatch('destroyToast');
      store.dispatch('createErrorToast');
    },

    createInProgressToast(store) {
      const toastId = UiToaster.add({
        type: 'warning',
        loading: true,
        allowClose: false,
        timeout: null,
        actions: [],
        title: i18n.global.t('status.in_progress'),
        message: i18n.global.t('export_report.toast.in_progress'),
      });
      store.commit('SET_TOAST_ID', toastId);
    },

    createSuccessToast(store) {
      const toastId = UiToaster.add({
        type: 'success',
        title: i18n.global.t('status.success'),
        message: i18n.global.t('export_report.toast.success'),
        timeout: null,
      });
      store.commit('SET_TOAST_ID', toastId);
    },

    createErrorToast(store) {
      const toastId = UiToaster.add({
        type: 'error',
        title: i18n.global.t('status.error'),
        message: i18n.global.t('export_report.toast.error'),
        timeout: null,
      });
      store.commit('SET_TOAST_ID', toastId);
    },

    destroyToast(store) {
      UiToaster.destroy(store.state.toastId);
      store.commit('SET_TOAST_ID', null);
    },
  },

  mutations: {
    SET_EXPORT_REPORT_ID(state, payload) {
      state.exportReportId = payload;
    },
    SET_PING_REQUEST(state, payload) {
      state.pingRequest = payload;
    },
    SET_TOAST_ID(state, payload) {
      state.toastId = payload;
    },
  },
};
