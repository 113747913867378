export function arrayToggle(array, value) {
  const newArray = [...array];
  const index = newArray.findIndex((i) => i === value);
  if (index >= 0) {
    newArray.splice(index, 1);
  } else {
    newArray.push(value);
  }
  return newArray;
}
