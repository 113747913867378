<template lang="pug">
.list-tab__info-layout
  .state-box.state-box--error
    Stack(vertical align="center")
      .state-box__message {{ $t('user_groups.modals.entity_access_list.filed_fetch_entity_groups') }}
        div {{ error }}
      UiButton(
        size="sm"
        icon="Refresh"
        variant="smooth-danger"
        @click="$emit('retry')"
      ) {{ $t('actions.retry') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['retry'],
  props: {
    error: {
      type: String,
      default: '',
    },
  },
});
</script>
