<template lang="pug">
UiModal(
  :title="withError ? $t('company.reports.refresh_error') : $t('company.reports.refresh_pbix')"
  :subtitle="withError ? $t('company.reports.refresh_error_description') : null"
  :show="show"
  @close="close"
)
  template(v-if="withError && !refreshAgainActive")
    p {{ report.extras.refresh_message }}
    template(v-if="report.extras.refresh_again")
      UiStack(justify="center")
        UiButton(
          variant="ghost-primary"
          icon="RefreshCcw"
          :disabled="inProgress || isViewAsOtherUser"
          width
          @click="refreshAgain"
        ) {{ $t('company.reports.refresh_again') }}
    template(v-else)
      p {{ $t('company.reports.refresh_again_timeout', { hours: refreshTimeoutTime }) }}

  template(v-else-if="report.extras.refresh_finished_message")
    p {{ $t('company.reports.refresh_success') }}
    p(v-if="refreshTimeoutTime") {{ $t('company.reports.refresh_timeout_time', { hours: refreshTimeoutTime, time: refreshTimeoutTimeLeft }) }}

  template(v-else)
    p.data-refresh-modal__error(v-if="refreshError") {{ refreshError }}
    .refresh-footer-buttons(v-if="form.full_refresh === null || form.full_refresh === true")
      UiButton(
        variant="ghost-primary"
        icon="RefreshCcw"
        :disabled="(form.full_refresh === false && inProgress) || isViewAsOtherUser"
        :loading="inProgress"
        @click="refreshAll"
      ) {{ $t('company.reports.refresh_all') }}
      UiButton(
        variant="ghost-primary"
        icon="Table"
        :disabled="inProgress || isViewAsOtherUser"
        @click="refreshTable"
      ) {{ $t('company.reports.refresh_table') }}

  template(v-if="form.full_refresh === false")
    .full-refresh
      p.fs16 {{ $t('company.reports.refresh_table_description') }}
      UiFormCheckbox(
        v-for="item in tableOptions"
        :modelValue="isChecked(item)"
        @update:modelValue="toRefreshList(item)"
      ) {{ item }}
      .refresh-footer-buttons
        UiButton(
          variant="ghost-primary"
          :disabled="inProgress"
          width
          @click="refreshVariantCancel"
        ) {{ $t('actions.cancel') }}
        UiButton(
          :disabled="form.refresh_tables.length === 0 || inProgress || isViewAsOtherUser"
          :loading="inProgress"
          width
          @click="sendRefresh"
        ) {{ $t('company.reports.refresh_send') }}
</template>

<script>
import { mapGetters } from 'vuex';
import refreshTableIcon from '@/assets/images/refresh/refresh_table.svg';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import PowerBiReport from '@/models/power-bi-report.js';

export default {
  data() {
    return {
      show: false,
      report: new PowerBiReport(),
      withError: false,
      refreshError: null,
      refreshAgainActive: false,
      fullRefreshVariant: 'flat-danger',
      tableRefreshVariant: 'flat-primary',
      tableOptions: ['Custom mapping', 'Budget', 'Forecast', 'RLS', 'Custom'],
      form: {
        full_refresh: null,
        refresh_tables: [],
      },
      inProgress: false,
    };
  },

  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters(['isViewAsOtherUser']),

    icons() {
      return {
        table: refreshTableIcon,
      };
    },
    refreshTimeoutTimeLeft() {
      let { refresh_timeout: totalSeconds } = this.report.extras;
      if (!totalSeconds) return false;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    refreshTimeoutTime() {
      let companyReportRefreshTime = this.currentCompany.report_refresh_cooldown_time;
      let hours = Math.floor(companyReportRefreshTime / 60);
      companyReportRefreshTime %= 60;
      let minutes = Math.floor(companyReportRefreshTime);
      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
  },

  methods: {
    open(report) {
      this.report = new PowerBiReport(report);
      this.withError = report.extras.refresh_status === 'error';
      this.form.full_refresh = null;
      this.form.refresh_tables = [];
      this.fullRefreshVariant = 'flat-danger';
      this.tableRefreshVariant = 'flat-primary';
      this.show = true;
    },

    close() {
      this.show = false;
      this.withError = false;
      this.refreshError = null;
    },
    refreshAgain() {
      const { refresh_tables } = this.report.extras;
      this.form.full_refresh = (refresh_tables || []).length === 0;
      this.form.refresh_tables = refresh_tables || [];
      this.refreshAgainActive = true;
    },
    refreshAll() {
      this.form.full_refresh = true;
      this.fullRefreshVariant = 'danger';
      this.sendRefresh();
    },
    refreshTable() {
      this.form.full_refresh = false;
      this.fullRefreshVariant = 'flat-danger';
      this.tableRefreshVariant = 'primary';
    },
    refreshVariantCancel() {
      this.form.full_refresh = null;
      this.tableRefreshVariant = 'flat-primary';
    },
    sendRefresh() {
      if (this.form.full_refresh === null || this.inProgress) return;

      this.inProgress = true;
      this.refreshError = null;
      new PowerBiReports(null, { id: this.report.id })
        .refresh(this.form)
        .then((resp) => {
          if (resp.status === 200) {
            this.$emit('done');
            this.show = false;
          }
          this.inProgress = false;
        })
        .catch((error) => {
          this.refreshError = error.response?.data?.error;
          this.inProgress = false;
        });
    },
    toRefreshList(item) {
      if (this.form.refresh_tables.includes(item)) {
        this.form.refresh_tables = this.form.refresh_tables.filter((i) => i !== item);
        return;
      }
      this.form.refresh_tables.push(item);
    },

    isChecked(item) {
      return this.form.refresh_tables.includes(item);
    },
  },
};
</script>

<style lang="scss">
.refresh-header-buttons,
.refresh-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.full-refresh,
.refresh-footer-buttons {
  margin-top: 2rem;
}

.data-refresh-modal__error {
  color: #c9432a;
  margin: 1rem 0;
}

.fs16 {
  font-size: 16px;
}
</style>
