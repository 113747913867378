<template lang="pug">
.copy-field
  .copy-field__action(v-if="actionPosition === 'before'" @click="copy")
    UiIcon(name="Copy")
  .copy-field__label
    slot {{ value }}
  .copy-field__action(v-if="actionPosition === 'after'" @click="copy")
    UiIcon(name="Copy")
</template>

<script>
import copyToClipboard from 'copy-to-clipboard';

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    actionPosition: {
      type: String,
      default: 'before',
    },
  },
  methods: {
    copy() {
      const result = copyToClipboard(this.value);
      if (result) {
        this.$toaster.add({
          title: this.$t('copy_to_clipboard.success_title'),
          message: this.$t('copy_to_clipboard.success_description'),
          actions: null,
        });
      } else {
        this.$toaster.add({
          title: this.$t('copy_to_clipboard.error_title'),
          message: this.$t('copy_to_clipboard.error_description'),
          actions: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables.scss';

.copy-field {
  background: var(--bb-gray-900);
  padding: 0.35em 0.5em;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  gap: 1em;
  font-size: 16px;
  border-radius: 0.35em;

  &__action {
    font-size: 1.3335em;
    color: var(--bb-gray-850);
    cursor: pointer;

    &:hover {
      color: var(--bb-primary);
    }
  }
}
</style>
