<template lang="pug">
.powerbi-client(ref="reportContainer")
</template>

<script>
import * as pbi from 'powerbi-client';
import { ReportLocaleCodes, ReportLanguages } from '@/localization';
import reportLogsReasonsList from '@/common/report-logs-reasons-list';

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

export default {
  name: 'PowerbiClient',
  emits: [
    'error',
    'get-pages',
    'saved',
    'rendered',
    'loaded',
    'renderingStarted',
    'loadingStarted',
    'clicked',
  ],
  props: {
    id: {
      type: String,
      required: true,
    },

    embedUrl: {
      type: String,
      required: true,
    },

    accessToken: {
      type: String,
      required: true,
    },

    expiration: {
      type: Number,
      required: true,
    },

    reportType: {
      type: String,
      default: 'standard_report',
    },

    type: {
      type: String,
      default: 'report',
    },

    tokenType: {
      type: String,
      default: 'Embed',
    },

    viewMode: {
      type: String,
      default: 'view',
    },

    // don't use it with applyTheme. It causes reloading a report
    themeJson: {
      type: Object,
      default: null,
    },

    locale: {
      type: String,
      default: 'en',
    },

    layoutType: {
      type: Number,
      default: null,
    },

    displayPageNavigation: {
      type: Boolean,
      default: true,
    },
    defaultPage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      report: null,
      storedAccessToken: this.accessToken,
      tokenUpdated: false,
    };
  },

  computed: {
    configuration() {
      let config = {
        type: this.type,
        id: this.id,
        embedUrl: this.embedUrl,
        tokenType: pbi.models.TokenType[this.tokenType],
        accessToken: this.storedAccessToken,
        permissions: pbi.models.Permissions.All,
        viewMode: this.viewMode === 'edit' ? pbi.models.ViewMode.Edit : pbi.models.ViewMode.View,
        settings: {
          layoutType: this.layoutType,
          filterPaneEnabled: this.viewMode === 'edit',
          panes: {
            filters: {
              visible: this.viewMode === 'edit',
            },
            pageNavigation: {
              visible:
                this.displayPageNavigation &&
                !['link_to_page', 'link_to_pages', 'merged_report'].includes(this.reportType),
            },
          },
          localeSettings: {
            language: ReportLanguages[this.locale],
            formatLocale: ReportLocaleCodes[this.locale],
          },
        },
        pageName: this.defaultPage,
      };
      if (this.themeJson) {
        config.theme = { themeJson: this.reportThemeJson(this.themeJson) };
      }
      return config;
    },
  },

  watch: {
    configuration: {
      deep: true,
      handler(val) {
        this.update(val, reportLogsReasonsList.CONFIGURATION_UPDATED);
      },
    },

    accessToken(newValue) {
      this.setAccessToken(newValue, this.expiration);
    },

    embedUrl() {
      this.fetchStoredAccessToken();
    },
  },

  mounted() {
    this.createInstance();
  },

  beforeUnmount() {
    this.destroy();
  },

  methods: {
    createInstance() {
      try {
        this.report = powerbi.embed(this.$refs.reportContainer, this.configuration);

        this.setEvents();
        console.info('Powerbi configured');
        this.loadingStartedEmit(reportLogsReasonsList.CONFIGURED);
      } catch (e) {
        this.destroy();
      }
    },

    setEvents() {
      this.report.off('loaded');
      this.report.off('rendered');
      this.report.off('pageChanged');
      this.report.off('error');
      this.report.off('saved');
      this.report.off('buttonClicked');
      this.report.off('visualClicked');
      this.report.off('dataSelected');

      this.report.on('loaded', this.onReportLoaded);
      this.report.on('rendered', this.onReportRendered);
      this.report.on('pageChanged', this.onPageChanged);
      this.report.on('error', this.onReportError);
      this.report.on('saved', this.emitSaved);
      this.report.on('buttonClicked', this.onClickHandler);
      this.report.on('visualClicked', this.onClickHandler);
      this.report.on('dataSelected', this.onClickHandler);
    },

    renderingStartedEmit(reason) {
      this.$emit('renderingStarted', reason);
    },

    loadingStartedEmit(reason) {
      this.$emit('loadingStarted', reason);
    },

    destroy() {
      if (this.report) {
        this.report.off('saved', this.emitSaved);
        this.report.off('loaded');
        this.report.off('pageChanged');
        this.report.off('error');
      }
      powerbi.reset(this.$refs.reportContainer);
      this.report = null;
    },

    fullscreen() {
      this.report.fullscreen();
    },

    print() {
      this.report.print();
    },

    save() {
      return this.report.save();
    },

    changePage(page) {
      return new Promise((resolve, reject) => {
        page
          .setActive()
          .then(() => {
            resolve();
            console.log(`Active page was set to: ${page.displayName}`);
          })
          .catch((error) => {
            reject();
            console.log(error);
          });
      });
    },

    update(config, reason) {
      if (!this.report) return;
      this.report = powerbi.embedNew(this.$refs.reportContainer, config ?? this.configuration);
      this.loadingStartedEmit(reason);
    },

    emitSaved() {
      this.$emit('saved');
      this.renderingStartedEmit(reportLogsReasonsList.SAVED);
    },

    refresh() {
      return this.report.refresh().catch((error) => {
        console.log(error);
        // todo: helper message error description
      });
    },

    reload() {
      return this.report.reload();
    },

    setAccessToken(token, expiration) {
      this.report
        .setAccessToken(token)
        .then(() => {
          this.setTokenExpirationListener(expiration);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setTokenExpirationListener(tokenExpiration) {
      // get current time
      let timeout = 0;
      const minutesToRefresh = 2;
      const currentTime = Date.now();
      const expiration = parseInt(tokenExpiration, 10);
      const safetyInterval = minutesToRefresh * 60 * 1000;

      // time until token refresh in milliseconds
      timeout = expiration - currentTime - safetyInterval;
      // if token already expired, generate new token and set the access token

      if (timeout <= 0 && this.tokenUpdated) {
        timeout = 25 * 60 * 1000;
      }
      if (timeout <= 0) {
        this.tokenUpdated = true;
        this.$emit('update-token');
      }
      // set timeout so minutesToRefresh minutes before token expires, token will be updated
      else {
        console.log(`Report Embed Token will be updated in ${timeout} milliseconds.`);
        setTimeout(() => {
          this.$emit('update-token');
        }, timeout);
      }
    },

    async fetchPages() {
      try {
        const pages = await this.report.getPages();
        this.$emit('get-pages', pages);
      } catch (error) {
        console.log(error);
      }
    },

    onReportLoaded() {
      console.info('Report loaded');
      this.$emit('loaded');
      this.renderingStartedEmit(reportLogsReasonsList.LOADED);
      this.setTokenExpirationListener(this.expiration);
      this.fetchPages();
    },

    onReportRendered() {
      this.$emit('rendered');
      console.info('Report rendered');
    },

    onPageChanged(event) {
      const page = event.detail.newPage;
      if (!page) return;
      this.$emit('page-changed', page);
      this.$emit('set-dropdown-page', page);
      this.renderingStartedEmit(reportLogsReasonsList.PAGE_CHANGED);
    },

    applyTheme(themeJson = null) {
      if (!this.report) return;
      if (themeJson) {
        return this.report.applyTheme({ themeJson: this.reportThemeJson(themeJson) });
      }

      this.report.resetTheme();
    },

    onReportError(error) {
      this.$emit('error', error);
      // eslint-disable-next-line no-console
      console.log(error);
    },

    reportThemeJson(themeJson) {
      return JSON.parse(JSON.stringify(themeJson));
    },

    applyBookmarkState(bookmark) {
      this.report.bookmarksManager.applyState(bookmark?.base64_state);
    },

    fetchStoredAccessToken() {
      this.storedAccessToken = this.accessToken;
    },

    onClickHandler() {
      this.$emit('clicked');
    },
    // applyBookmarkState: todo
    // this.$refs.report.report.bookmarksManager.applyState(bookmark.base64_state);
  },
};
</script>

<style lang="scss">
.powerbi-client > iframe {
  display: block;
  border: 0 !important;
}
</style>
