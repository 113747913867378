<template lang="pug">
.container.whiteboard
  Stack(justify="center")
    StackItem
      .text-center
        .h3 Kiitos, että aktivoit bibook.comin Pipedrive Storessa!
      | Vielä muutama askel bibook.comin käyttöönottamiseksi:
      ol.instructions
        li Kirjaudu sisään käyttäen sähköpostiosoitettasi.
        li Hyväksy palvelun käyttöehdot.
        li Tunnuksesi ovat käyttövalmiit noin 10 minuutin kuluttua.
      .contacts
        p Ystävällisin terveisin,
        p bibook.com puolesta
        p Jonathan Teir
        p +358 44 272 3224
  Stack(justify="center")
    StackItem.ml-auto
      UiButton(width @click="continueUsage") {{ $t('actions.continue') }}
</template>

<script>
import eventBus from '@/eventBus.js';

export default {
  name: 'PipedriveIntegrationFi',
  mounted() {
    this.$store.dispatch('switchLanguage', 'fi');
    eventBus.on('LocaleChanged', this.changeLocale);
  },

  beforeUnmount() {
    eventBus.off('LocaleChanged', this.changeLocale);
  },

  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    changeLocale(locale) {
      if (locale !== 'fi') {
        this.$router.replace(`/sign-up-via-pipedrive/${locale}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 700px;
}

.instructions {
  list-style-position: inside;
  margin-top: 15px;
}

.contacts {
  margin-left: 40px;
  text-align: end;
}

.question {
  list-style-position: inside;
  list-style-type: disc;
}

.whiteboard {
  word-wrap: break-word !important;
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
  font-size: 18px;
}

.ml-auto {
  margin-left: auto;
}

.mb24 {
  margin-bottom: 24px;
}
</style>
