<template lang="pug">
.report-form
  UiFormMultiselect(
    v-if="!form.id"
    v-model="form.report_type"
    :label="$t('company.reports.report_type')"
    :options="reportTypeOptions"
    :object="false"
    :canClear="false"
    :canDeselect="false"
    :disabled="inProgress"
    @select="changeReportType"
  )
  UiFormInput(
    v-model="form.name"
    :placeholder="$t('company.reports.report_name_placeholder')"
    :disabled="inProgress"
    :label="$t('company.reports.report_name')"
    :vuelidateModel="v$.form.name"
  )
  UiFormMultiselect(
    v-model="form.report_group_id"
    :label="$t('labels.folder')"
    :disabled="inProgress || reportGroupsInProgress"
    :options="reportGroups"
    :loading="reportGroupsInProgress"
    valueProp="id"
    labelKey="name"
  )
  template(v-if="isLinkedReport")
    template(v-if="report")
      powerbi-client.ui-form-field(
        v-show="false"
        ref="report"
        :id="report.report_id"
        :embed-url="report.embed_url"
        :access-token="report.access_token"
        :expiration="report.expiration"
        view-mode="view"
        @getPages="onGetPages"
      )
    UiFormMultiselect(
      :key="isMultipleReports ? 'reports' : 'report'"
      :modelValue="isMultipleReports ? form.reports : form.report_id"
      :label="$t(isMultipleReports ? 'company.reports.reports' : 'company.reports.report')"
      :options="reports"
      :mode="isMultipleReports ? 'tags' : 'single'"
      valueProp="id"
      labelKey="name"
      :object="false"
      :disabled="inProgress || reportsInProgress || reportsQueueInProgress"
      :loading="reportsInProgress"
      :error="isValidReport ? null : $t('report-crashed')"
      @update:modelValue="changeReport"
    )
      template(v-slot:tag="{ option, disabled, handleTagRemove }")
        UiMultiselectTag(
          :disabled="disabled"
          :class="reportTagClasses(option)"
          @remove="handleTagRemove(option, $event)"
        ) {{ option.name }}
    UiFormMultiselect(
      v-if="isMultipleReports ? form.reports.length : form.report_id && isValidReport"
      v-model="form.pages"
      :key="(isMultipleReports ? 'reports_pages' : 'report_pages') + pagesOptions.length"
      :object="false"
      :label="$t(isMultiplePages ? 'company.reports.pages' : 'company.reports.page')"
      labelKey="label"
      valueProp="value"
      :options="pagesOptions"
      :groups="isMultipleReports"
      :mode="isMultiplePages ? 'tags' : 'single'"
      :loading="reportsQueueInProgress"
      :disabled="reportsQueueInProgress"
      :hideSelected="false"
    )

  template(v-else)
    UiFormInput(
      v-model="form.report_url"
      :label="$t('company.reports.report_url')"
      placeholder="https://"
      :disabled="inProgress"
      :vuelidateModel="v$.form.report_url"
    )
    UiFormMultiselect(
      v-model="form.editing"
      :label="$t('company.reports.editing')"
      labelKey="label"
      :disabled="inProgress"
      :options="editOptions"
      :canClear="false"
      :canDeselect="false"
      :object="false"
    )
  UiFormCheckbox(
    v-if="!isLinkedReport"
    v-model="form.row_level_security"
    switch
  ) {{ $t('company.reports.row_level_security') }}
  UiFormInput(
    v-if="form.row_level_security === 'true' || form.row_level_security === true"
    v-model="form.rls_rule_name"
    :label="$t('company.reports.rls_rule_name')"
    :placeholder="$t('company.reports.rls_rule_name_placeholder')"
    :disabled="inProgress"
    :vuelidateModel="v$.form.rls_rule_name"
  )
  UiFormCheckbox(v-model="form.visible_in_analytics" switch) {{ $t('company.reports.visible_in_analytics') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Company from '@/models/Company.js';
import Analytics from '@/api/models/Analytics.js';
import PowerBiReportGroups from '@/api/models/PowerBiReportGroups.js';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import UiMultiselectTag from '@/components/elements/ui-multiselect/ui-multiselect-tag.vue';

export default {
  name: 'ReportForm',
  components: {
    UiMultiselectTag,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inProgress: false,

      reportGroupsInProgress: false,
      reportGroups: [],

      reportsInProgress: false,
      reports: [],

      reportsQueueInProgress: false,
      reportsQueue: [],
      fetchedReports: {},
      fetchedReportsError: {},
      reportPages: {},
      report: null,

      isValidReport: true,
    };
  },

  mounted() {
    if (!this.form.report_type) {
      this.form.report_type = 'standard_report';
    }
    this.$emit('setReportValidStatus', !this.isLinkedReport);
    this.form.editing = !!this.form.editing;
    this.fetchReportGroups();
    this.fetchReports();
  },
  computed: {
    ...mapGetters('company', ['currentCompany']),
    isLinkedReport() {
      return ['link_to_page', 'link_to_pages', 'merged_report'].includes(this.form.report_type);
    },
    isMultipleReports() {
      return this.form.report_type === 'merged_report';
    },
    isMultiplePages() {
      return ['link_to_pages', 'merged_report'].includes(this.form.report_type);
    },
    reportTypes() {
      return ['standard_report', 'link_to_page', 'link_to_pages', 'merged_report'];
    },
    reportTypeOptions() {
      return this.reportTypes.map((type) => ({
        value: type,
        label: this.$t(`company.report_types.${type}`),
      }));
    },
    editOptions() {
      return [
        {
          label: 'Enabled',
          value: true,
        },
        {
          label: 'Disabled',
          value: false,
        },
      ];
    },

    pagesOptions() {
      if (this.isMultipleReports) {
        return this.form.reports.reduce((acc, reportId) => {
          const report = this.reports.find((r) => r.id === reportId);
          if (report && this.reportPages[reportId]) {
            acc.push({
              label: report.name,
              options: this.reportPages[reportId].map((page) => ({
                label: page.label,
                value: `${reportId}|${page.value}`,
              })),
            });
          }
          return acc;
        }, []);
      }

      if (!this.form.report_id) {
        return [];
      }

      return this.reportPages[this.form.report_id] ?? [];
    },
    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },
  },
  methods: {
    reportTagClasses(report) {
      return {
        'is-error': this.fetchedReportsError[report.id],
      };
    },
    fetchReportGroups() {
      this.reportGroupsInProgress = true;
      new PowerBiReportGroups()
        .fetchAll({ per_page: 9999 })
        .then((res) => {
          this.reportGroups = res.data.report_groups || [];
          this.reportGroupsInProgress = false;
        })
        .catch((e) => {
          this.reportGroupsInProgress = false;
          console.error(e);
        });
    },

    fetchReports() {
      this.reportsInProgress = true;
      new PowerBiReports()
        .fetchAll({ report_type: 'standard_report', per_page: 9999 })
        .then((res) => {
          this.reports = res.data.power_bi_reports || [];
          this.reportsInProgress = false;
          this.fetchSelectedReports();
        })
        .catch((e) => {
          this.reportsInProgress = false;
          console.error(e);
        });
    },

    fetchSelectedReports() {
      if (this.isMultipleReports) {
        this.reportsQueue = [...this.form.reports] || [];
      } else if (this.form.report_id) {
        this.reportsQueue = [this.form.report_id];
      }
      this.fetchReportsQueue();
    },

    fetchReportsQueue() {
      this.reportsQueueInProgress = true;
      if (!this.reportsQueue.length) {
        this.reportsQueueInProgress = false;
        return;
      }
      this.fetchReport(this.reportsQueue[0]);
    },

    setReportValidStatus(status) {
      this.$emit('setReportValidStatus', status);
      this.isValidReport = status;
    },

    fetchReport(reportId) {
      this.report = null;
      this.setReportValidStatus(true);

      if (this.fetchedReports[reportId]) {
        this.reportsQueue.splice(0, 1);
        this.fetchReportsQueue();
        return;
      }

      new Analytics(null, { id: reportId })
        .fetch()
        .then((resp) => {
          const reportData = resp.data.power_bi_report;
          if (reportData.access_token) {
            this.reportsQueue.splice(0, 1);
            this.fetchedReports[reportId] = reportData;
            this.fetchedReportsError[reportId] = false;
            this.report = reportData;
          } else {
            this.fetchedReportsError[reportId] = true;
            this.setReportValidStatus(false);
            this.reportsQueueInProgress = false;
          }
        })
        .catch(() => {
          this.setReportValidStatus(false);
          this.fetchedReportsError[reportId] = true;
          this.inProgress = false;
          this.reportsQueueInProgress = false;
        });
    },

    changeReportType(reportType) {
      this.$emit('setReportValidStatus', reportType === 'standard_report');
      this.fetchedReportsError = {};
      this.form.row_level_security = false;
      this.form.reports = [];
      this.form.report_id = null;
      this.form.pages = [];
    },

    changeReport(value) {
      if (this.isMultipleReports) {
        this.form.reports = value;
        this.form.pages = this.form.pages.filter((page) => {
          return this.form.reports.includes(page.split('|')[0]);
        });
      } else {
        this.form.report_id = value;
        this.form.pages = [];
      }
      this.fetchSelectedReports();
    },

    onGetPages(pages) {
      this.reportPages[this.report.id] = pages
        .filter((p) => !p.visibility)
        .map((p) => ({
          label: p.displayName,
          value: p.name,
        }));
      this.fetchReportsQueue();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.report-error {
  color: var(--bb-danger);
}
</style>

<style lang="scss">
@import '@/assets/stylesheet/variables';

.ui-multiselect-tag.is-error {
  background: $bb-error-status;
}
</style>
