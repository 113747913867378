<template lang="pug">
Modal(
  :show="show"
  :close="close"
  :title="$t('portal_management.ui_themes.delete_theme')"
  width="500px"
)
  template(#body)
    p {{ $t('portal_management.ui_themes.confirm_delete_theme', { name: theme.name }) }}
  template(#footer)
    UiButton(
      variant="outline-primary"
      :disabled="inProgress"
      @click="close"
    ) {{ $t('actions.cancel') }}
    UiButton(
      variant="danger"
      :loading="inProgress"
      :disabled="inProgress"
      @click="deleteCompany"
    ) {{ $t('actions.delete') }}
</template>

<script>
import UiThemes from '@/api/models/UiThemes.js';

export default {
  data() {
    return {
      show: false,
      inProgress: false,
      theme: null,
    };
  },

  methods: {
    open(theme) {
      this.theme = theme;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    deleteCompany() {
      this.inProgress = true;
      new UiThemes({ adminApi: true }, { id: this.theme.id })
        .delete()
        .then(() => {
          this.show = false;
          this.inProgress = false;
          this.$emit('done');
          this.$toaster.add({
            message: this.$t('portal_management.ui_themes.toasts.successfully_deleted'),
          });
        })
        .catch((_e) => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.action-button {
  min-width: 80px;

  & + & {
    margin-left: 15px;
  }
}
</style>
