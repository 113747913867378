<template lang="pug">
.admin-layout.fast-access
  Heading(:title="$t('access.title')")
    UiButton(
      variant="outline-primary"
      :round="mobileView"
      icon="FilterIcon"
      @click="open"
    ) {{ $t('access.btn_column') }}
  .access__table-container
    RestTable(
      :selectedIds="selectedCompaniesIds"
      ref="accessTable"
      namespace="companies"
      :headers="headers"
      :fetchHandler="fetch"
      :searchLabel="$t('companies.search_placeholder')"
      searchable
      @wasUpdatedOptions="listUpdated"
    )
      template(v-slot:head_id="{ item }")
        .th__checkbox.th__id(@click.stop)
          UiCheckbox(v-model="selectAll" @change="changeSelecAll($event)")
            span {{ selectAllLabel }}
      template(v-for="column in columns" v-slot:[`head_${column}`]="{ item }")
        .th__checkbox(v-if="selectedCompaniesIds.length" @click.stop)
          UiCheckbox(:modelValue="columnsState[column]" @change="confirmChanges(column)")
      template(v-slot:cel_id="{ item }")
        UiCheckbox(:modelValue="isChecked(item.id)" @change="change(item.id)")
          span {{ item.id }}

      template(v-for="column in columns" v-slot:[`cel_${column}`]="{ item }")
        UiStatusBoolean.pointer(:value="!!item[column]" @click="changeAccess(item, column)")
EditColumnModal(ref="editColumnModal" @done="updateHiddenList")
ConfirmationModal(ref="confirmationModal" @wasUpdated="columnUpdate")
</template>

<script>
import { mapGetters } from 'vuex';
import FastAccess from '@/api/models/FastAccess.js';
import EditColumnModal from '@/modals/Admin/FastAccess/EditColumn.vue';
import ConfirmationModal from '@/modals/Admin/FastAccess/Confirmation.vue';
import { fastAccessOptionsKeys } from './index.js';

export default {
  name: 'FastAccess',
  components: {
    EditColumnModal,
    ConfirmationModal,
  },
  data() {
    return {
      selectAll: false,
      selectedColumns: [],
      selectedCompaniesIds: [],
      companies: [],
      meta: null,
      hiddenList: [],
    };
  },
  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),

    basicHeaders() {
      return [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('companies.headers.name'),
          width: '20%',
        },
        {
          key: 'business_id',
          label: this.$t('companies.headers.business_id'),
        },
      ];
    },

    optionsHeaders() {
      return fastAccessOptionsKeys.map((key) => ({
        key,
        label: this.$t(`access.table.${key}`),
        squeeze: true,
        optionHeader: true,
      }));
    },

    headersList() {
      return [...this.basicHeaders, ...this.optionsHeaders];
    },

    companiesIds() {
      return this.companies.map((c) => c.id);
    },

    columns() {
      return fastAccessOptionsKeys;
    },

    headers() {
      return this.headersList.filter((i) => !this.hiddenList.includes(i.key));
    },

    selectAllLabel() {
      return this.selectAll
        ? this.$t('access.checkbox_selected', this.companiesIds.length)
        : this.$t('access.checkbox_not_selected');
    },

    columnsState() {
      const result = {};
      this.companies
        .filter((c) => this.selectedCompaniesIds.includes(c.id))
        .forEach((company, index) => {
          fastAccessOptionsKeys.forEach((column) => {
            if (!index) {
              result[column] = 0;
            }
            if (company[column]) {
              result[column]++;
            }
            if (this.selectedCompaniesIds.length === index + 1) {
              result[column] = this.selectedCompaniesIds.length === result[column];
            }
          });
        });
      return result;
    },
  },
  watch: {
    selectedCompaniesIds(val) {
      this.selectAll = val.length === this.companiesIds.length;
    },
  },
  async mounted() {
    try {
      const hiddenColumns = JSON.parse(localStorage.getItem('hiddenAccessColumns'));
      if (!hiddenColumns) {
        const emptyArray = [];
        localStorage.setItem('hiddenAccessColumns', JSON.stringify(emptyArray));
      } else {
        this.hiddenList = hiddenColumns;
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async fetch(params) {
      try {
        const response = await new FastAccess().fetchAll({
          search: params.search,
          page: params.page,
          per_page: params.per_page,
          sort_by: params.sort_by,
          sort_order: params.sort_order,
        });
        this.companies = response.data.companies;
        this.meta = response.data.meta;
        return {
          meta: response.data.meta,
          items: response.data.companies,
        };
      } catch (error) {
        console.log(error);
        return {};
      }
    },

    refreshData() {
      this.$refs.accessTable.fetchData();
    },

    changeAccess(item, column) {
      const data = { ids: [item.id], status: !item[column] };
      new FastAccess()
        .updateBatch(column, data)
        .then((res) => {
          this.refreshData();
        })
        .catch((e) => console.log(e));
    },

    open() {
      this.$refs.editColumnModal.open(this.hiddenList);
    },

    updateHiddenList(hiddenKeysList) {
      this.hiddenList = hiddenKeysList;
      localStorage.setItem('hiddenAccessColumns', JSON.stringify(hiddenKeysList));
    },

    isChecked(id) {
      return this.selectedCompaniesIds.includes(id);
    },

    change(id) {
      if (this.selectedCompaniesIds.includes(id)) {
        this.selectedCompaniesIds = this.selectedCompaniesIds.filter((i) => i !== id);
      } else {
        this.selectedCompaniesIds = [...this.selectedCompaniesIds, id];
      }
    },

    changeSelecAll(val) {
      if (val) {
        this.selectedCompaniesIds = this.companiesIds;
      } else {
        this.selectedCompaniesIds = [];
      }
    },

    isColumnChecked(item) {
      return this.selectedColumns.includes(item);
    },

    confirmChanges(column) {
      const data = { ids: this.selectedCompaniesIds, status: !this.columnsState[column] };
      this.$refs.confirmationModal.open(column, data);
    },

    columnUpdate() {
      this.refreshData();
    },

    listUpdated() {
      this.selectedCompaniesIds = [];
    },
  },
};
</script>

<style scoped lang="scss">
.access__table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.admin-layout.fast-access {
  &:deep(.table__container) {
    padding-top: 25px;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom: 20px;
  }

  &:deep(.data-table .table th) {
    min-width: 120px;
  }
}

.th__checkbox {
  position: absolute;
  left: 20px;
  top: -24px;
  z-index: 200;

  &.th__id {
    transform-origin: left center;
    left: 10px;
    transform: translateX(0);
  }

  span {
    font-weight: 400;
    white-space: nowrap;
  }
}

.action_btn {
  cursor: pointer;
}
</style>
