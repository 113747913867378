<template lang="pug">
.header-void
  .header
    .header__container
      .company-switcher
        .current-company
          span.current-company__name Company Name
          UiIcon.current-company__arrow(name="ChevroneBottom")
      .header__right
        .notification
          UiButton(
            variant="ghost"
            border="smooth"
            icon="BellNotify"
            size="lg"
            round
          )
        .header__user
          .header-dropdown.header-dropdown--right
            button.header-button
              .header-button__prepend
                .avatar-box.avatar-box-sm
                  NoAvatar(:label="user.name")
              .header-button__content
                .header-button__content_container
                  .header-button__label {{ user.name }}
                  .header-button__subtitle Admin
              .header-button__icon
                UiIcon(name="ChevroneBottom" size="0.6em")
</template>

<script>
import NoAvatar from '@/components/Avatar/NoAvatar.vue';
import { mapGetters } from 'vuex';

export default {
  components: { NoAvatar },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>

<style scoped lang="scss">
.header {
  position: absolute;
}
</style>
