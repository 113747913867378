<template lang="pug">
Dropdown(
  :position="dropdownPosition"
  :style="dropdownStyle"
  @close="$emit('close')"
)
  template(#search)
    .column-filter__search
      UiInput(
        v-model="search"
        icon="Search"
        :placeholder="$t('company.search_company')"
        size="xs"
      )
  template(#content)
    .column-filter__mass-selectors
      span(@click="selectAll") {{ $t('dataroom.table.filter.selectAll') }}
      span(@click="deselectAll") {{ $t('dataroom.table.filter.deselectAll') }}
    .column-filter__content
      UiFormCheckbox(
        v-for="option in options"
        :modelValue="!inFilter(option.value)"
        @update:modelValue="toggleValue(option.value)"
      ) {{ option.label || this.$t('dataroom.table.filter.no_value') }}
</template>

<script>
import Dropdown from '@/components/elements/mini-dropdown/MiniDropdown.vue';

export default {
  components: {
    Dropdown,
  },
  props: {
    column: {
      type: String,
      default: null,
    },
    values: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    options() {
      if (this.search) {
        const search = this.search.toLowerCase();
        return this.values.filter(
          (v) =>
            v.label?.toLowerCase().includes(search) ||
            v.value?.toLowerCase(search).includes(search),
        );
      }
      return this.values;
    },
    dropdownStyle() {
      return {
        width: 'max-content',
      };
    },
    dropdownPosition() {
      return {
        top: 40,
      };
    },
  },
  methods: {
    inFilter(value) {
      return this.filter.includes(value);
    },
    toggleValue(value) {
      const newFilter = [...this.filter];
      const index = newFilter.indexOf(value);
      if (index >= 0) {
        newFilter.splice(index, 1);
      } else {
        newFilter.push(value);
      }
      this.$emit('filter', newFilter);
    },
    selectAll() {
      const filtredValues = this.options.map((v) => v.value);
      const newFilter = [...this.filter].filter((v) => !filtredValues.includes(v));
      this.$emit('filter', newFilter);
    },
    deselectAll() {
      const filtredValues = this.options.map((v) => v.value);
      const newFilter = [...this.filter, ...filtredValues].filter((v, i, a) => a.indexOf(v) === i);
      this.$emit('filter', newFilter);
    },
  },
};
</script>
<style lang="scss">
$padding: 10px;
.column-filter__content,
.column-filter__search {
  padding: $padding $padding 0;
}
.column-filter__content {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  padding: $padding;
}
.column-filter__mass-selectors {
  display: flex;
  padding: $padding;
  gap: 10px;

  & span {
    text-decoration: underline;
    color: #00b07d;
    cursor: pointer;
    font-size: 14px;
  }
}
</style>
