<template lang="pug">
ApiKeyCardBase(
  :title="title"
  :subtitle="subtitle"
  :actions="actions"
)
  template(#icon)
    component(:is="icon" data-test-id="card-icon-type")
  template(#subtitle v-if="isAuth")
    UiStack(
      vertical
      align="start"
      spacing="xs"
    )
      UiStack(align="center")
        UiStackItem {{ $t('company.apikeys.groups.auth_subtitle') }}
        UiStackItem {{ data.user_name }}
      UiStack(align="center")
        UiStackItem {{ $t('password') }}:
        UiStackItem
          UiButton(
            block
            icon="FilesIcon"
            variant="text-primary"
            data-test-id="copy-password"
            @click="copyToClipboard"
          ) {{ $t('company.api_keys.copy_password') }}
</template>

<script>
import copy from 'copy-to-clipboard';
import Companies from '@/api/models/Companies.js';
import MasterKeys from '@/api/models/MasterKeys.js';
import AuthIcon from '@/assets/images/apikeys/auth-icon.vue';
import CloudApiIcon from '@/assets/images/apikeys/cloud-api-icon.vue';
import CloudKeyIcon from '@/assets/images/apikeys/cloud-key-icon.vue';
import ApiKeyCardBase from './ApiKeyCardBase.vue';

const CardTypeIcon = {
  header: CloudKeyIcon,
  auth: AuthIcon,
  refresh: CloudApiIcon,
};

const CardTypeAccessValue = {
  header: 'password',
  auth: 'password',
  refresh: 'refresh_token',
};

export default {
  name: 'ApiKey',

  components: {
    ApiKeyCardBase,
  },

  props: {
    type: {
      type: String,
      default: 'header',
    },
    data: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    tokenType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    clipboardText: {
      type: String,
      default: null,
    },
    clipboardPasswordText: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      inProgress: false,
    };
  },

  computed: {
    isAuth() {
      return this.type === 'auth';
    },

    actions() {
      const actions = [];

      if (['header', 'refresh'].includes(this.type)) {
        if (this.data.update) {
          actions.push({
            label: this.$t('company.api_keys.update'),
            icon: 'Refresh',
            loading: this.inProgress,
            onClick: this.refreshApiKeys,
          });
        }

        actions.push({
          label: this.$t('company.api_keys.copy'),
          icon: 'FilesIcon',
          variant: 'primary',
          onClick: this.copyToClipboard,
        });
      }

      return actions;
    },

    icon() {
      return CardTypeIcon[this.type];
    },
  },
  methods: {
    async copyToClipboard() {
      try {
        const response = await new MasterKeys(null, { id: this.group.id }).fetch({
          access_type: this.tokenType.replace('_credentials', ''),
          value: CardTypeAccessValue[this.type],
        });

        const result = copy(response.data.token);
        if (result) {
          this.$toaster.add({
            message: this.$t(
              this.isAuth ? 'company.api_keys.password_copied' : 'company.api_keys.token_copied',
            ),
          });
        }
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$t(
            this.isAuth
              ? 'company.api_keys.password_copy_error'
              : 'company.api_keys.token_copy_error',
          ),
        });
      }
    },

    async refreshApiKeys() {
      this.inProgress = true;
      try {
        await new Companies().generateApiKey(this.group);
        this.$toaster.add({
          message: this.$t('company.api_keys.token_updated'),
        });
      } catch {
        this.$toaster.add({
          type: 'error',
          message: this.$t('company.api_keys.token_update_error'),
        });
      }
      this.inProgress = false;
    },
  },
};
</script>
