class FolderData {
  constructor(data) {
    this.locked = data.locked ?? false;
    this.replace_allowed = data.replace_allowed ?? false;
    this.validate_all_files = data.validate_all_files ?? false;
    this.gs_id = data.gs_id ?? false;
  }
}

export default FolderData;
