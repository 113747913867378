import AppBaseModel from '@/api/models/AppBaseModel.js';

class OIDC extends AppBaseModel {
  constructor(options, item) {
    const mergedOptions = {
      ...options,
      path: 'oidc',
    };
    super(mergedOptions, item);
  }

  discover(params = {}, config = {}) {
    return this.httpClient.post(`${this.entityUrl}/discover`, params, config);
  }
}

export default OIDC;
