<template lang="pug">
svg.no-avatar(
  viewBox="0 0 100 100"
  style="overflow: visible"
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  :style="this.color ? { backgroundColor: this.color } : null"
)
  text.no-avatar__text(
    v-if="label"
    :style="this.color ? { fill: 'white' } : null"
    text-anchor="middle"
    x="50"
    y="64"
  ) {{ formattedLabel }}
</template>

<script>
const NoAvatarLabelFormat = {
  ABBREVIATION: 'abbreviation',
  NUMBER: 'number',
  NONE: 'none',
};

export default {
  name: 'NoAvatar',

  props: {
    color: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelFormat: {
      type: String,
      default: NoAvatarLabelFormat.ABBREVIATION,
      validate(value) {
        return Object.values(NoAvatarLabelFormat).includes(value);
      },
    },
  },

  computed: {
    formattedLabel() {
      switch (this.labelFormat) {
        case NoAvatarLabelFormat.ABBREVIATION: {
          const label = this.label.trim();
          const match = label.match(/\b([A-Z])/g);
          return match ? match.join('') : label.slice(0, 1).toUpperCase();
        }
        case NoAvatarLabelFormat.NUMBER: {
          return `+${Math.min(this.label, 99)}`;
        }
        default: {
          return this.label || '';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.no-avatar {
  background: $bb-gray-900;

  &__text {
    fill: $bb-gray-600;
    user-select: none;
    font-size: 40px;
    line-height: 1;
  }
}
</style>
