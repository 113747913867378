<template lang="pug">
UiStack(
  spacing="2xl"
  vertical
  align="stretch"
)
  UiStackItem
    UiInputLabel {{ $t('system_banners.select_user_role') }}
    UiCheckboxMultiselect(
      :modelValue="modelValue.user_roles"
      :placeholder="$t('system_banners.modals.label_select_role')"
      :options="userRoles"
      :disabled="inProgress"
      :allOptionsLabel="$t('system_banners.modals.for_all_user_roles')"
      data-test-id="multiselect-user-roles"
      @update:modelValue="updateValue({ user_roles: $event })"
    )
  UiStackItem
    UiInputLabel {{ $t('system_banners.select_banner_type') }}
    UiMultiselect(
      :modelValue="modelValue.banner_type"
      :placeholder="$t('system_banners.modals.label_select_type')"
      :canDeselect="false"
      :canClear="false"
      :options="bannerTypes"
      :disabled="inProgress"
      data-test-id="multiselect-banner-type"
      @update:modelValue="updateValue({ banner_type: $event })"
    )
  UiStackItem
    UiInputLabel {{ $t('system_banners.modals.banner_text') }}
    UiEditor(
      ref="editorRef"
      :limit="250"
      :modelValue="modelValue.description"
      data-test-id="editor-description"
      @update:modelValue="updateValue({ description: $event, descriptionLength: $refs.editorRef.editor.storage.characterCount.characters() })"
    )
    template(v-if="vuelidateModel?.$errors")
      UiInputFeedback(v-for="$error in vuelidateModel?.$errors" error) {{ $error.$message }}
  UiStackItem
    UiCheckbox(
      :modelValue="modelValue.dismissible"
      switch
      :checked-value="true"
      :unchecked-value="false"
      data-test-id="checkbox-dismissible"
      @update:modelValue="updateValue({ dismissible: $event })"
    ) {{ $t('system_banners.modals.label_can_be_dismissed') }}
</template>

<script>
import { mapGetters } from 'vuex';

const bannerTypes = ['info', 'warning'];

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['companyRoles']),
    userRoles() {
      return Object.values(this.companyRoles).map((role) => ({
        label: this.$t(`company.role.${role}`),
        value: role,
      }));
    },
    bannerTypes() {
      return bannerTypes.map((type) => ({
        label: this.$t(`system_banners.type.${type}`),
        value: type,
      }));
    },
  },
  mounted() {
    this.updateValue({
      descriptionLength: this.$refs.editorRef.editor.storage.characterCount.characters(),
    });
  },
  methods: {
    updateValue(newValue = {}) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        ...newValue,
      });
    },
  },
};
</script>
