<template lang="pug">
modal(
  :show="show"
  :close="close"
  :title="$t('company.users.import')"
)
  template(v-slot:body)
    .import-user__body
      p {{ $t('company.users.import_description.instruction') }}
      .import-user__description
        .import-user__description__item
          UiIcon.primary-icon(name="Rename" size="25px")
          p(v-html="$t('company.users.import_description.rename')")
        .import-user__description__item
          UiIcon.primary-icon(name="FileDownload" size="25px")
          p(v-html="$t('company.users.import_description.save')")
        .import-user__description__item
          UiIcon.primary-icon(name="BurgerDelete" size="25px")
          p(v-html="$t('company.users.import_description.deleted')")
        .import-user__description__item
          UiIcon.primary-icon(name="FileLightPlus" size="25px")
          p(v-html="$t('company.users.import_description.new')")

    .import-user__as-template
      p {{ $t('company.users.export_template') }}
      a.import-user__as-template__link(:href="`/company_users/${currentCompanyId}/export.xlsx`") {{ $t('actions.export') }}

    .import-user__download
      UiFilePicker(
        v-model="file"
        :extensions="['xlsx']"
        :maxSize="3900"
      )

    .import-user__footer
      UiButton(
        :loading="inProgress"
        :disabled="!Boolean(file) || inProgress"
        width="230"
        @click="Boolean(file) && !inProgress ? upload() : null"
      ) {{ $t('actions.upload') }}
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyUsers from '@/api/models/CompanyUsers.js';

export default {
  emits: ['done'],

  data() {
    return {
      show: false,
      inProgress: false,
      file: null,
    };
  },
  computed: {
    ...mapGetters('company', ['currentCompanyId']),
    ...mapGetters(['mobileView']),
  },
  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
      this.file = null;
    },

    upload() {
      const formData = new FormData();
      formData.append('file', this.file);
      this.inProgress = true;
      new CompanyUsers()
        .import(formData)
        .then(() => {
          this.inProgress = false;
          this.$emit('done');
          this.close();
        })
        .catch((error) => {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
            timeout: 7000,
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss">
.import-user {
  &__body {
    font-size: 16px;
    margin-bottom: 15px;
  }

  &__description {
    padding: 25px 30px;
    background: var(--bb-gray-900);
    border-radius: 10px;

    &__item {
      display: grid;
      grid-template-columns: 25px auto;
      margin: 20px 0;

      & p {
        margin: 0;
        margin-left: 20px;
      }
    }
  }

  &__as-template {
    display: flex;
    font-size: 18px;

    &__button {
      margin-bottom: 48px;
      cursor: pointer;
    }

    &__link,
    &__button {
      font-size: 18px;
      margin-left: 20px;
      color: var(--bb-primary);
    }
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .ui-file-picker {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: end;
  }
}
</style>
