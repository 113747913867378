<template lang="pug">
UiCardSection(padding="lg")
  .system-banner-tab(v-if="!inProgress")
    .system-banner-tab__header
      .system-banner-tab__header-main-col
        UiStack(align="center")
          UiStackItem(grow shrink)
            UiText(variant="headingMd") {{ $t('system_banners.title') }}
          UiButton(icon="Plus" @click="addSystemBanner")
            | {{ $t('system_banners.action_add_banner') }}
      .system-banner-tab__header-preview-col(v-if="systemBannersList.length && !mobileView")
        | {{ $t('system_banners.preview') }}
    .system-banner-tab__card-container(v-for="banner in systemBannersList" :key="banner.id")
      SystemBannerCard(
        :banner="banner"
        :isPortalManagementPage="isPortalManagementPage"
        @edit="editSystemBanner(banner)"
        @delete="deleteSystemBanner(banner)"
        @save="fetchSystemBanners"
      )
    UiCardSection(v-if="!inProgress && !this.systemBannersList.length" padding="sm")
      .system-banner-tab__empty-state
        UiEmptyState(:description="$t('system_banners.empty_banners')")
  CreateSystemBannerModal(ref="createSystemBannerModal" @done="fetchSystemBanners")
  DeleteSystemBannerModal(ref="deleteSystemBannerModal" @done="fetchSystemBanners")
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import SystemBanners from '@/api/models/system-banners.js';
import CreateSystemBannerModal from '@/modals/system-banner/create.vue';
import DeleteSystemBannerModal from '@/modals/system-banner/delete.vue';
import SystemBannerCard from '@/components/system-banner/system-banner-card.vue';

export default {
  name: 'SystemBannersTab',
  components: {
    CreateSystemBannerModal,
    DeleteSystemBannerModal,
    SystemBannerCard,
  },
  props: {
    isPortalManagementPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inProgress: true,
      systemBannersList: [],
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'mobileView']),
  },
  created() {
    this.fetchSystemBanners();
  },
  methods: {
    async fetchSystemBanners() {
      this.inProgress = true;
      try {
        const response = await new SystemBanners({
          adminApi: this.isAdmin,
          owner:
            this.isAdmin && !this.isPortalManagementPage
              ? new Companies(null, { id: this.$route.params.id })
              : null,
        }).fetchAll();
        this.systemBannersList = response.data.system_banners;
      } catch (error) {
        console.log(error);
      } finally {
        this.inProgress = false;
      }
    },
    addSystemBanner() {
      this.$refs.createSystemBannerModal.open({
        isPortalManagementPage: this.isPortalManagementPage,
      });
    },
    deleteSystemBanner(item) {
      this.$refs.deleteSystemBannerModal.open({
        banner: item,
        isPortalManagementPage: this.isPortalManagementPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.system-banner-tab {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__header-main-col {
    width: 50%;
    margin-right: 40px;
    flex-shrink: 0;
  }

  &__header-preview-col {
    flex-grow: 1;
    flex-shrink: 1;
    color: var(--bb-gray-600);
  }

  &__card-container {
    & + & {
      margin-top: 20px;
    }
  }

  &__empty-state {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .system-banner-tab {
    &__left-title {
      margin: 0;
      width: 100%;
    }

    &__empty-state {
      width: 100%;
    }

    &__right-title {
      width: auto;
    }
  }
}
</style>
