import AppBaseModel from './AppBaseModel.js';

class CompanyUsers extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'company_users',
      singularKey: 'company_user',
      ...options,
    };
    super(mergedOptions, item);
  }

  import(data, config) {
    return this.httpClient.post(`${this.url}/import`, data, config);
  }
}

export default CompanyUsers;
