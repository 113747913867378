<template lang="pug">
.ui-form-field.ui-form-field--input
  UiInputLabel(v-if="label || $slots.label" :shifted="shifted")
    slot(name="label")
      | {{ label }}
  UiInput(
    v-bind="$attrs"
    :error="error || vuelidateModel?.$error"
    @blur="vuelidateModel?.$touch"
  )
    template(#icon v-if="$slots.icon")
      slot(name="icon")
    template(#iconAfter v-if="$slots.iconAfter")
      slot(name="iconAfter")
  UiInputFeedback(v-if="description" :shifted="shifted") {{ description }}
  template(v-if="showVuelidateErrors && vuelidateModel?.$errors")
    UiInputFeedback(
      v-for="$error in vuelidateModel?.$errors"
      :shifted="shifted"
      error
    ) {{ $t($error.$message) }}
</template>

<script>
export default {
  name: 'UiFormInput',
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    labelSize: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    vuelidateModel: {
      type: Object,
      default: null,
    },
    showVuelidateErrors: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: null,
    },
    shifted: {
      type: Boolean,
      default: undefined,
    },
  },
};
</script>
