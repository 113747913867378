import axios from 'axios';

const ApiAdminCompanyDomains = {
  fetchAll(companyId, params = { page: 1 }) {
    return axios.get(`/api/v1/admin/companies/${companyId}/domains`, { params });
  },

  destroy(companyId, id, params = {}) {
    return axios.delete(`/api/v1/admin/companies/${companyId}/domains/${id}`, params);
  },

  validateDNS(companyId, params = {}) {
    return axios.put(`/api/v1/admin/companies/${companyId}/domain/validate_dns`, params);
  },
};

export default ApiAdminCompanyDomains;
