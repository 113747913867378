import axios from 'axios';
import { pathJoin } from './utils';

class BaseModel {
  constructor(options, item, httpClient) {
    const _options = {
      owner: null,
      singular: false,
      path: null,
      singularKey: null,
      pluralKey: null,
      ...options,
    };
    this.item = item ?? {};
    this.id = this.item?.id ?? null;
    this.path = _options.path;
    this.owner = _options.owner;
    this.singular = _options.singular;
    this.singularKey = _options.singularKey;
    this.pluralKey = _options.pluralKey;
    this.httpClient = httpClient ?? axios;
  }

  // eslint-disable-next-line
  get basePath() {
    return '/';
  }

  get entityPath() {
    const path = ['/', this.path];
    if (!this.singular && this.id !== null) {
      path.push(this.id);
    }
    return pathJoin(...path);
  }

  get ownerPath() {
    const path = ['/'];
    if (this.owner) {
      path.push(this.owner.ownerPath, this.owner.entityPath);
    }
    return pathJoin(...path);
  }

  get url() {
    return pathJoin(this.basePath, this.ownerPath, this.path);
  }

  get entityUrl() {
    return pathJoin(this.basePath, this.ownerPath, this.entityPath);
  }

  wrapSingularData(data) {
    if (this.singularKey) {
      return {
        [this.singularKey]: data,
      };
    }

    return data;
  }

  wrapPluralData(data) {
    if (this.pluralKey) {
      return {
        [this.pluralKey]: data,
      };
    }

    return data;
  }

  create(data, config) {
    return this.httpClient.post(this.url, this.wrapSingularData(data), config);
  }

  update(data, config) {
    return this.httpClient.patch(this.entityUrl, this.wrapSingularData(data), config);
  }

  updateBatch(data, config) {
    return this.httpClient.post(`${this.entityUrl}/update_batch`, this.wrapSingularData(data), config);
  }

  save(data, config) {
    if (this.id !== null && this.id !== undefined) {
      return this.update(data, config);
    }
    return this.create(data, config);
  }

  fetch(params = {}, config = {}) {
    return this.httpClient.get(this.entityUrl, {
      params,
      ...config,
    });
  }

  fetchAll(params = {}, config = {}) {
    return this.httpClient.get(this.url, {
      params,
      ...config,
    });
  }

  delete() {
    return this.httpClient.delete(this.entityUrl);
  }
}

export default BaseModel;
