<template lang="pug">
.token-field
  .token-field__value
    | {{ value }}
  .token-field__copy
    UiIcon.gray-icon-700(
      name="Copy"
      size="24"
      data-test-id="copy-icon"
      @click="copyToClipboard"
    )
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  methods: {
    copyToClipboard() {
      const result = copy(this.value);
      if (result) {
        this.$toaster.add({
          message: this.$t('company.reports.copied'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.token-field {
  display: flex;
  align-items: center;

  &__value {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;

    @media screen and (max-width: 1100px) {
      max-width: 150px;
    }

    @media screen and (max-width: 1000px) {
      max-width: 120px;
    }
  }

  &__copy {
    width: 24px;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
