import axios from 'axios';

const ApiCompanyDomains = {
  fetchAll(params = { page: 1 }) {
    return axios.get(`/api/v1/company_domains`, { params });
  },

  validateDNS(params = {}) {
    return axios.put(`/api/v1/company_domain/validate_dns`, params);
  },
};

export default ApiCompanyDomains;
