<template lang="pug">
UiMultiselect.ui-checkbox-multiselect(
  v-bind="$attrs"
  :modelValue="modelValue"
  :options="options"
  :hideSelected="false"
  :closeOnSelect="false"
  mode="multiple"
)
  template(#beforelist)
    .ui-checkbox-multiselect__all-options-container(v-if="showSelectAllOption")
      UiCheckboxMultiselectOption(:active="isAllSelected" @click="toggleAllOptions") {{ $t(allOptionsLabel) }}
      .ui-checkbox-multiselect__divider
  template(#option="{ option, isSelected }")
    UiCheckboxMultiselectOption(:active="isSelected(option)") {{ option.label }}
</template>

<script>
import UiCheckboxMultiselectOption from './ui-checkbox-multiselect-option.vue';

export default {
  components: {
    UiCheckboxMultiselectOption,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    showSelectAllOption: {
      type: Boolean,
      default: true,
    },
    allOptionsLabel: {
      type: String,
      default: 'ui_checkbox_multiselect.all_options',
    },
  },
  computed: {
    isAllSelected() {
      return this.modelValue.length && this.modelValue.length === this.options.length;
    },
  },
  methods: {
    getOptionValue(option) {
      return option.value;
    },
    toggleAllOptions() {
      if (this.isAllSelected) {
        this.$emit('update:modelValue', []);
      } else {
        this.$emit('update:modelValue', this.options.map(this.getOptionValue));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-checkbox-multiselect {
  &__all-options-container {
    padding-top: 6px;
  }

  &__divider {
    border-top: 1px solid #eee;
    margin: 5px 0;
  }

  &:deep(.ui-multiselect-option) {
    padding: 0;
    margin: 0;
    display: block;

    &.is-selected,
    &:hover {
      background: transparent;
      color: inherit;
    }
  }

  &:deep(.ui-multiselect-dropdown) {
    overflow: hidden;
  }
}
</style>
