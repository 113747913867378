import Companies from '@/api/models/Companies.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';

// eslint-disable-next-line arrow-body-style
const fetchList = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    new CompanyUsers({ adminApi: true, owner: new Companies(null, { id }) })
      .fetchAll()
      .then((res) => {
        commit('SET_USERS', res.data.users);
        commit('SET_META', res.data.meta);
        commit('SET_IS_READY', true);
        resolve(res);
      })
      .catch(reject);
  });
};

export default {
  fetchList,
};
