<template lang="pug">
div
  TabHeader
    template(#title) {{ $t('user_groups.group_members') }}
    template(#actions)
      UiButton(icon="UserPlus" @click="openAddUsersUserGroupModal") {{ $t('user_groups.action_add_users') }}
  .table-container
    UiRestTable(
      ref="usersTable"
      namespace="users"
      :headers="usersHeaders"
      :fetchHandler="fetchUsers"
      searchable
    )
      template(#cell:name="{ item }")
        Stack(spacing="sm")
          AvatarBox(
            size="sm"
            :src="item.avatar"
            :id="item.email"
            :label="item.name"
          )
          | {{ item.name }}
      template(#cell:actions="{ item }")
        DataTableRowActions(:actions="getItemActions(item)")
    UiCardSection
  AddUsersUserGroupModal(ref="addUsersUserGroupModal" @done="refetchUsers")
  DeleteUserUserGroupsModal(ref="deleteUserUserGroupsModal" @done="refetchUsers")
</template>

<script>
import UserGroups from '@/api/models/UserGroups.js';
import Users from '@/api/models/Users.js';
import AddUsersUserGroupModal from '@/modals/UserGroups/AddUsers.vue';
import DeleteUserUserGroupsModal from '@/modals/UserGroups/DeleteUser.vue';
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';

export default {
  components: {
    AddUsersUserGroupModal,
    DeleteUserUserGroupsModal,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    ...mapGetters('company', ['company']),
    usersHeaders() {
      return [
        {
          key: 'name',
          label: this.$t('company.users.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('user.email'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          squeeze: true,
        },
      ];
    },
  },

  methods: {
    async fetchUsers(params) {
      try {
        const response = await new Users({
          adminApi: this.isAdminHost,
          owner: new UserGroups(
            {
              owner: this.isAdminHost
                ? new Companies(null, { id: this.$route.params.company_id })
                : null,
            },
            { id: this.$route.params.user_group_id },
          ),
        }).fetchAll(params);
        return {
          meta: response.data.meta,
          items: response.data.users,
        };
      } catch {
        return {
          meta: {},
          items: [],
        };
      }
    },
    refetchUsers() {
      this.$refs.usersTable.fetchData();
    },
    openAddUsersUserGroupModal() {
      this.$refs.addUsersUserGroupModal.open(this.group);
    },
    openDeleteUserUserGroupsModal(user) {
      this.$refs.deleteUserUserGroupsModal.open({
        group: this.group,
        user,
      });
    },
    getItemActions(user) {
      return [
        {
          icon: 'Trash',
          label: this.$t('user_groups.action_remove_from_group'),
          variant: 'danger',
          onClick: () => this.openDeleteUserUserGroupsModal(user),
        },
      ];
    },
  },
};
</script>
