import AppBaseModel from './AppBaseModel';

class LoginBanners extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'login_banners',
      singularKey: null,
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default LoginBanners;
