import AppBaseModel from './AppBaseModel';

class SystemBanners extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'system_banners',
      singularKey: null,
      ...options,
    };
    super(mergedOptions, item);
  }

  dismiss() {
    return this.httpClient.post(`${this.entityUrl}/dismiss`);
  }
}

export default SystemBanners;
