export const fastAccessOptionsKeys = [
  'hidden',
  'show_api_keys',
  'show_backend_api_key',
  'analytics_enabled',
  'analytics_premium_enabled',
  'dataroom_enabled',
  'dataroom_api_enabled',
  'files_enabled',
  'extract_api_enabled',
  'sso_enabled',
  'email_login_enabled',
  'google_login_enabled',
  'microsoft_login_enabled',
  'private_embedded_links_enabled',
  'public_embedded_links_enabled',
  'export_report_enabled',
];
