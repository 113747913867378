<template lang="pug">
UiCardSection(padding="lg")
  .form-wrapper
    UiStack(vertical spacing="3xl")
      //- Report refresh settings section
      UiStack(vertical)
        UiText(variant="headingMd") {{ $t('edit_company.reports.report_refresh_cooldown_time_title') }}
        div
          div
            i18n-t(keypath="edit_company.reports.report_refresh_cooldown_time_label")
              template(#value)
                .form__number-input
                  UiFormInput(
                    v-model.number="form.report_refresh_cooldown_time"
                    type="number"
                    :vuelidateModel="v$.form.report_refresh_cooldown_time"
                    :showVuelidateErrors="false"
                    data-test-id="form-cooldown"
                  )
          UiInputFeedback(
            v-for="$error in v$.form.report_refresh_cooldown_time?.$errors"
            :shifted="false"
            error
          ) {{ $error.$message }}

      //- Export settings section
      UiStack(vertical)
        UiText(variant="headingMd") {{ $t('edit_company.reports.report_export_limit_title') }}
        div
          div
            i18n-t(keypath="edit_company.reports.report_export_limit_label")
              template(#value)
                .form__number-input
                  UiFormInput(
                    v-model.number="form.report_exports_limit"
                    type="number"
                    :vuelidateModel="v$.form.report_exports_limit"
                    :showVuelidateErrors="false"
                  )
          UiInputFeedback(
            v-for="$error in v$.form.report_exports_limit?.$errors"
            :shifted="false"
            error
          ) {{ $error.$message }}

      UiStackItem(align="end")
        UiButton(
          :disabled="inProgress"
          :loading="inProgress"
          data-test-id="button-save"
          @click="submit"
        ) {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import { helpers } from '@vuelidate/validators';
import { ref } from 'vue';

const positiveInt = helpers.regex(/^\d*[1-9]\d*$/);

export default {
  setup() {
    const externalResults = ref({});
    const v$ = useVuelidate({
      $externalResults: externalResults,
    });
    return { v$, externalResults };
  },
  data() {
    return {
      form: {
        report_refresh_cooldown_time: 0,
        report_exports_limit: 0,
      },
      inProgress: false,
    };
  },

  validations() {
    const positiveIntWithLocale = helpers.withMessage(
      this.$t('edit_company.reports.validations.this_value_must_be_greater_than_zero'),
      positiveInt,
    );
    return {
      form: {
        report_refresh_cooldown_time: {
          required,
          positiveInt: positiveIntWithLocale,
        },
        report_exports_limit: {
          required,
          positiveInt: positiveIntWithLocale,
        },
      },
    };
  },
  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters('editCompany', ['company']),
    ...mapGetters(['isAdmin']),

    companyId() {
      return this.$route.params?.company_id ?? this.currentCompany?.id;
    },
  },

  mounted() {
    this.fetchForm();
  },

  methods: {
    async submit() {
      this.inProgress = true;
      const valid = await this.v$.$validate();

      if (!valid) {
        this.inProgress = false;
        return;
      }

      try {
        await this.$store.dispatch('company/updateCompany', {
          company: { settings: { ...this.form } },
          companyId: this.isAdmin ? this.companyId : null,
        });
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('edit_company.status.info_updated_success'),
          timeout: 10000,
        });
      } catch (error) {
        if (error.response.status === 400) {
          const externalResults = { company: parseResponseErrors(error.response.data.errors) };
          Object.assign(this.externalResults, externalResults);
        }
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },

    fetchForm() {
      this.form.report_refresh_cooldown_time = this.company?.report_refresh_cooldown_time;
      this.form.report_exports_limit = this.company?.report_exports_limit;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__number-input {
    display: inline-block;
    width: 100px;
    margin: 0 0.5em;
  }
}

.form-wrapper {
  max-width: 700px;

  &__cooldown-time {
    width: 100px;
  }
}
</style>
