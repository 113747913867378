<template lang="pug">
.dataroom-table-filter
  h2 {{ $t('dataroom.table.filter.title') }}

  UiFormInput.dataroom-table-filter__input(
    v-model="column"
    :label="$t('dataroom.table.filter.column_name')"
  )
  UiButton.w-165(@click="applyFilter") {{ $t('apply') }}
  UiButton.w-165(variant="outline-primary" @click="cleanFilter") {{ $t('clear') }}
</template>

<script>
export default {
  data() {
    return {
      line: '',
      column: '',
    };
  },

  methods: {
    applyFilter() {
      const { column, line } = this;
      this.$emit('updateFilter', { column, line });
    },

    cleanFilter() {
      this.column = '';
      this.line = '';
      this.$emit('updateFilter', { column: '', line: '' });
    },
  },
};
</script>

<style lang="scss">
.dataroom-table-filter {
  display: flex;
  align-items: flex-end;
  margin: 20px 0;

  & h2 {
    font-size: 18px;
    font-weight: 700;
  }

  & > :nth-child(2n) {
    margin: 0 20px;
  }

  &__input {
    min-width: 240px;
  }
}
</style>
