<template lang="pug">
.api-key-card
  .api-key-card__body
    .api-key-card__icon
      slot(name="icon")
    .api-key-card__content
      .api-key-card__title
        slot(name="title") {{ title }}
      .api-key-card__subtitle
        slot(name="subtitle") {{ subtitle }}
  .api-key-card__actions(v-if="actions?.length")
    UiButton(
      v-for="action in normalizedActions"
      v-bind="action"
      data-test-id="api-key-card-base-action"
    )
      | {{ action.label }}
</template>

<script>
import authIcon from '@/assets/images/apikeys/auth-icon.vue';
import cloudApiIcon from '@/assets/images/apikeys/cloud-api-icon.vue';
import cloudKeyIcon from '@/assets/images/apikeys/cloud-key-icon.vue';

export default {
  name: 'ApiKey',
  components: { authIcon, cloudApiIcon, cloudKeyIcon },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    normalizedActions() {
      return this.actions.map((action) => ({
        variant: 'outline-primary',
        ...action,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.api-key-card {
  width: 100%;
  background: var(--bb-gray-900);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__body {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-size: 1.125rem;
    flex-grow: 1;
    flex-shrink: 0;

    @media (max-width: 1000px) {
      font-size: 1rem;
    }

    @media (min-width: 1000px) and (max-width: 1440px) {
      flex-direction: column;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 52px;
    min-height: 52px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__content {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
  }

  &__title {
    font-weight: 700;
  }

  &__actions {
    margin-top: 1rem;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  @media (max-width: 1000px) {
    &__actions {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
</style>
