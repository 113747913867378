<template lang="pug">
.page-container(v-if="!loading && group")
  AppBreadcrumbs(:items="breadcrumbs")
  UiStack(vertical)
    UiStackItem(grow)
      .page-label {{ $t('user_groups.user_group_view.user_group') }}
      .page-title {{ group.name }}
    UiStack(wrap)
      UiCard.page-definition(shadow="never" :style="{ width: mobileView ? '100%' : 'auto' }")
        UiCardSection.page-definition__body
          .page-definition__label {{ $t('user_groups.user_group_view.sensitive_info_included') }}
          .page-definition__value
            UiStatusBoolean(:value="group.sensitive_info_included")
            UiStack(align="end")
      UiStackItem(grow align="center")
        UiStack(justify="space-between")
          UiButton(
            icon="PencilEdit"
            :size="mobileView ? 'sm' : 'lg'"
            @click="openEditUserGroupModal"
          ) {{ $t('user_groups.action_edit_group') }}
          UiButton(
            icon="Trash"
            :size="mobileView ? 'sm' : 'lg'"
            variant="smooth-danger"
            @click="openDeleteUserGroupModal"
          ) {{ $t('user_groups.action_delete_group') }}
    .page-content
      Transition(name="fade" mode="out-in")
        div(v-if="['UserGroupsShow', 'AdminUserGroupsShow'].includes($route.name)")
          UiTabs(
            :tabs="tabs"
            :selected="activeTabId"
            :fullWidth="mobileView"
            @select="setActiveTabId"
          )
          UiCard
            Transition(name="fade" mode="out-in")
              Component(
                :key="activeTab.id"
                :is="activeTab.component"
                :group="group"
                @updated="onGroupUpdated"
              )
        RouterView(v-else)
  CreateUserGroupModal(ref="createUserGroupModal" @done="fetchUserGroup")
  DeleteUserGroupModal(ref="deleteUserGroupModal" @done="toGroupsList")
</template>

<script>
import UserGroups from '@/api/models/UserGroups.js';
import TabHeader from '@/components/Tabs/TabHeader.vue';
import CreateUserGroupModal from '@/modals/UserGroups/Create.vue';
import DeleteUserGroupModal from '@/modals/UserGroups/Delete.vue';
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import PermissionsTab from './ShowView/PermissionsTab.vue';
import UsersTab from './ShowView/UsersTab.vue';

export default {
  components: {
    TabHeader,
    CreateUserGroupModal,
    DeleteUserGroupModal,
  },
  data() {
    return {
      loading: true,
      group: null,
      inProgress: false,
    };
  },
  computed: {
    ...mapGetters(['isAdminHost', 'mobileView']),
    ...mapGetters('company', ['company']),
    groupsListRoute() {
      return this.$adminCompanyLinkModifier({
        name: 'CompanyShow',
        params: { tab: 'user_groups' },
      });
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('companies.title'),
          to: '/admin/companies',
          visible: this.isAdminHost,
        },
        {
          label: this.company.name,
          to: this.$adminCompanyLinkModifier({ name: 'CompanyShow' }),
          visible: this.isAdminHost,
        },
        {
          label: this.$t('user_groups.title'),
          to: this.groupsListRoute,
          visible: true,
        },
        {
          label: this.group?.name ?? '...',
          visible: true,
        },
      ].filter((i) => i.visible);
    },
    tabs() {
      return [
        {
          id: 'permissions',
          label: 'Permissions',
          component: PermissionsTab,
        },
        {
          id: 'members',
          label: 'Members',
          component: UsersTab,
        },
      ];
    },
    activeTabId() {
      return this.tabs.some((tab) => tab.id === this.$route.params.tab)
        ? this.$route.params.tab
        : this.tabs[0].id;
    },
    activeTab() {
      return this.tabs.find((tab) => tab.id === this.activeTabId);
    },
  },

  mounted() {
    this.fetchUserGroup();
  },

  methods: {
    onGroupUpdated(group) {
      this.group = group;
    },
    setActiveTabId(tab) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          tab,
        },
      });
    },
    toGroupsList() {
      this.$router.push(this.groupsListRoute);
    },
    async fetchUserGroup() {
      try {
        this.loading = true;
        const response = await new UserGroups(
          {
            adminApi: this.isAdminHost,
            owner: this.isAdminHost ? new Companies(null, { id: this.company.id }) : null,
          },
          {
            id: this.$route.params.user_group_id,
          },
        ).fetch();
        this.group = response.data.user_group;
      } catch {
        // do nothing
      } finally {
        this.loading = false;
      }
    },
    openEditUserGroupModal() {
      this.$refs.createUserGroupModal.open(this.group);
    },
    openDeleteUserGroupModal() {
      this.$refs.deleteUserGroupModal.open(this.group);
    },
  },
};
</script>

<style lang="scss">
.page-container {
  padding: 2rem;
  padding-bottom: 10rem;
}

.page-stats {
  margin-top: 1rem;
  display: flex;
  gap: 1em;
  align-items: stretch;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.page-definition {
  min-width: 340px;

  &__body {
    display: flex;
    align-items: center;
    font-size: 1.15rem;
  }

  &__label {
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__value {
    flex-shrink: 0;
  }

  &--vertical &__body {
    flex-direction: column;
    align-items: stretch;
    line-height: 1;
  }

  &--vertical &__label {
    color: var(--bb-gray-500);
    font-size: 0.85rem;
  }

  &--vertical &__value {
    margin-top: 0.5rem;
  }
}

.page-header {
  margin-bottom: 1.25rem;
}

.page-label {
  font-size: 1rem;
  line-height: 1.2;
  color: var(--bb-gray-500);
}

.page-label + .page-title {
  margin-top: 0.5rem;
}

.page-title {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
}

.page-subtitle {
  line-height: 1.2;
  font-size: 1.2rem;
}

.page-title + .page-subtitle {
  margin-top: 0.5rem;
}
</style>
