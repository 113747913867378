import AppBaseModel from './AppBaseModel';

class UserLimitBanners extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'user_limit_banners',
      singularKey: null,
      ...options,
    };
    super(mergedOptions, item);
  }
}

export default UserLimitBanners;
