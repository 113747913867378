<template lang="pug">
.entity-type-icon-outer-container
  UiTooltip(:title="entity.type" variant="dark")
    template(#activator)
      .entity-type-icon(:class="classes")
        UiIcon(:name="icon ?? 'AlertTriangle'")
</template>

<script>
const ReportVariant = {
  standard_report: 'success',
  link_to_page: 'info',
  link_to_pages: 'warning',
  merged_report: 'danger',
};

const ReportIcon = {
  standard_report: 'ReportStandard',
  link_to_page: 'ReportLinkToPage',
  link_to_pages: 'ReportLinkToPages',
  merged_report: 'ReportMerged',
};

const DataroomFormVariant = {
  single: 'smooth-success',
  multiple: 'smooth-warning',
};

export default {
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isFolder() {
      return this.entity.type === 'Folder';
    },
    isRootFolder() {
      return this.entity.type === 'Root';
    },
    isPowerBiReport() {
      return this.entity.type === 'PowerBiReport';
    },
    isPowerBiReportGroup() {
      return this.entity.type === 'PowerBiReportGroup';
    },
    isDataroomForm() {
      return this.entity.type === 'DataroomForm';
    },
    isCompanyFile() {
      return this.entity.type === 'CompanyFile';
    },
    powerBiReportType() {
      return this.entity.data?.report_type || this.entity.report_type;
    },
    dataroomFormIsMultiEntries() {
      return this.entity.data?.multi_entries || this.entity.multi_entries;
    },
    dataroomFormEntryType() {
      return this.dataroomFormIsMultiEntries ? 'multiple' : 'single';
    },
    iconVariant() {
      if (this.isPowerBiReport) {
        return ReportVariant[this.powerBiReportType] ?? null;
      }
      if (this.isDataroomForm) {
        return DataroomFormVariant[this.dataroomFormEntryType] ?? null;
      }
      if (this.isCompanyFile) {
        return 'smooth-warning';
      }
      if (this.isRootFolder) {
        return 'smooth-success';
      }
      return null;
    },
    icon() {
      if (this.isPowerBiReport) {
        return ReportIcon[this.powerBiReportType] ?? null;
      }
      if (this.isDataroomForm) {
        return this.dataroomFormIsMultiEntries ? 'Group' : 'Account';
      }
      if (this.isCompanyFile) {
        return 'FileText';
      }
      if (this.isFolder || this.isPowerBiReportGroup) {
        return 'Folder';
      }
      if (this.isRootFolder) {
        return 'FolderOpened';
      }
      return null;
    },
    classes() {
      return {
        [`entity-type-icon--${this.iconVariant}`]: this.iconVariant,
      };
    },
  },
};
</script>

<style lang="scss">
.entity-type-icon-outer-container {
  display: flex;
}

.entity-type-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  background: rgba(var(--bb-text-color-rgb), 0.05);
  color: var(--bb-gray-600);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.32rem;

  &--success {
    background: var(--bb-success);
    color: white;
  }

  &--danger {
    background: var(--bb-danger);
    color: white;
  }

  &--warning {
    background: var(--bb-warning);
    color: white;
  }

  &--info {
    background: var(--bb-info);
    color: white;
  }

  &--smooth-success {
    background: rgba(var(--bb-success-rgb), 0.15);
    color: var(--bb-success);
  }

  &--smooth-danger {
    background: rgba(var(--bb-danger-rgb), 0.15);
    color: var(--bb-danger);
  }

  &--smooth-warning {
    background: rgba(var(--bb-warning-rgb), 0.15);
    color: var(--bb-warning);
  }

  &--smooth-info {
    background: rgba(var(--bb-info-rgb), 0.15);
    color: var(--bb-info);
  }
}
</style>
