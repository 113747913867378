import AppBaseModel from './AppBaseModel.js';

class Integrations extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'integrations',
      pluralKey: 'integrations',
      singularKey: 'integration',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchLogs() {
    return this.httpClient.get(`${this.url}/logs`);
  }

  refresh(data) {
    return this.httpClient.put(`${this.entityUrl}/refresh`, data);
  }

  resetRefresh() {
    return this.httpClient.put(`${this.entityUrl}/reset_refresh`);
  }
}

export default Integrations;
