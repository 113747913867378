<template lang="pug">
UiCardSection(padding="lg")
  UiStack(
    vertical
    align="stretch"
    spacing="xl"
  )
    UiStackItem
      UiText(variant="headingMd") {{ $t('edit_company.notifications.title') }}
    UiStack(justify="space-between")
      UiStackItem
        UiCheckbox(
          v-model="invitationEmailEnabled"
          switch
          :disabled="inProgress"
          data-vi="invitation-email"
          @change="toggleInvitationEmail"
        ) {{ $t('edit_company.notifications.invitation_email') }}
      UiStackItem
        UiButton(
          variant="smooth-success"
          size="sm"
          icon="Eye"
          :loading="inProgressPreview"
          data-vi="send-btn"
          @click="sendPreviewEmail"
        ) {{ $t('edit_company.notifications.receive_preview') }}
</template>

<script>
import InvitationEmail from '@/api/models/invitation-email.js';
import Companies from '@/api/models/Companies.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      inProgress: false,
      inProgressPreview: false,
      invitationEmailEnabled: null,
    };
  },
  computed: {
    ...mapGetters('company', ['currentCompany']),
    ...mapGetters('editCompany', ['company']),
    ...mapGetters(['isAdmin']),

    companyId() {
      return this.$route.params?.id ?? this.currentCompany?.id;
    },
  },
  mounted() {
    this.initDataNotification();
  },
  methods: {
    async toggleInvitationEmail() {
      this.inProgress = true;
      try {
        await this.$store.dispatch('company/updateCompany', {
          company: { send_invitation_email: this.invitationEmailEnabled },
          companyId: this.isAdmin ? this.companyId : null,
        });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    async sendPreviewEmail() {
      this.inProgressPreview = true;
      try {
        await new InvitationEmail({
          owner: this.isAdmin ? new Companies({}, { id: this.company.id }) : null,
          adminApi: this.isAdmin,
        }).create();

        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('edit_company.notifications.has_been_emailed'),
          timeout: 10000,
        });
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgressPreview = false;
      }
    },
    initDataNotification() {
      this.invitationEmailEnabled = this.company?.send_invitation_email;
    },
  },
};
</script>

<style lang="scss" scoped></style>
