<template lang="pug">
UiModal(
  :title="$t(this.isEdit ? 'user_groups.modals.edit.title' : 'user_groups.modals.create.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  @close="close"
)
  UiFormInput(
    v-model="form.name"
    :label="$t('user_groups.modals.create.label_group_name')"
    :vuelidateModel="v$.form.name"
  )
  .ui-form-field(v-if="!isEdit")
    UiInputLabel {{ $t('user_select.placeholder') }}
    UserSelect(
      v-model="form.user_ids"
      value-prop="user_id"
      track-by="user_id"
      :object="false"
      mode="tags"
      :closeOnSelect="false"
      placeholder=" "
    )
  UiFormCheckbox(v-model="form.sensitive_info_included") {{ $t('user_groups.modals.create.sensetive_info_included') }}
</template>

<script>
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import UserSelect from '@/components/UserSelect.vue';
import parseResponseErrors from '@/common/parseResponseErrors.js';

function getForm(form) {
  return {
    id: null,
    name: '',
    user_ids: [],
    sensitive_info_included: false,
    ...form,
  };
}

export default {
  components: {
    UserSelect,
  },
  emits: ['done'],
  setup() {
    const externalResults = ref();
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: getForm(),
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    isEdit() {
      return !!this.form.id;
    },
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t(this.isEdit ? 'actions.save' : 'actions.create'),
          onClick: this.submit,
          loading: this.inProgress,
        },
      ];
    },
  },
  methods: {
    open(group) {
      this.v$.$reset();
      this.form = getForm(group);
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async submit() {
      this.externalResults = {};
      const valid = await this.v$.$validate();
      if (!valid) return;
      this.inProgress = true;
      try {
        const api = new UserGroups(
          {
            adminApi: this.isAdminHost,
            owner: this.isAdminHost
              ? new Companies(null, { id: this.$route.params.company_id })
              : null,
          },
          { id: this.form.id },
        );
        if (this.isEdit) {
          await api.update({
            name: this.form.name,
            sensitive_info_included: this.form.sensitive_info_included,
          });
        } else {
          await api.create(this.form);
        }
        this.$emit('done');
        this.close();
      } catch (error) {
        if (error.response?.data?.errors) {
          const parsedResponse = parseResponseErrors(error.response.data.errors);

          Object.keys(parsedResponse).forEach((key) => {
            parsedResponse[key] = parsedResponse[key].map(this.$t);
          });
          this.externalResults = { form: parsedResponse };
          this.$toaster.add({
            type: 'error',
            title: 'Error',
            message: this.$t('validations.check_all_fields'),
          });
        } else {
          this.$toaster.add({
            type: 'error',
            message: this.$localizeErrorMessage(error),
          });
        }
      } finally {
        this.inProgress = false;
      }
    },
  },
};
</script>
