<template lang="pug">
.custom-color-picker
  .custom-color-picker__swatch(:class="{ disabled: disabled }")
    ColorPicker(
      :color="color"
      :disabled="disabled"
      :changeOnApplyOnly="changeOnApplyOnly"
      @cancel="$emit('cancel', $event)"
      @change="$emit('change', $event)"
    )
      template(#activator="{ color }")
        .color-preview.color-preview--lg(:style="{ backgroundColor: color }")
  .custom-color-picker__details
    .custom-color-picker__name {{ name }}
    .custom-color-picker__description {{ description }}
</template>

<script>
import { defineComponent } from 'vue';
import ColorPicker from '@/components/ColorPicker.vue';

export default defineComponent({
  components: { ColorPicker },
  emits: ['cancel', 'change'],
  props: {
    color: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    changeOnApplyOnly: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-color-picker {
  display: flex;
  gap: 20px;
  align-items: center;

  & + & {
    margin-top: 15px;
    line-height: 1.2;
  }

  &__swatch:not(.disabled) {
    cursor: pointer;
  }

  &__description {
    margin-top: 0.25em;
    font-size: 0.9em;
    line-height: 1.2;
    opacity: 0.5;
  }
}
.color-preview {
  box-shadow: inset 0 0 0 1px rgba(black, 0.1), 0 0 0 1px rgba(white, 0.25);
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;

  &--lg {
    width: 4rem;
    height: 4rem;
  }
}
</style>
