<template lang="pug">
.step-actions
  div
    UiButton(
      variant="ghost"
      icon="ArrowLeft"
      @click="moveBack"
    ) {{ $t('onboarding.action_back') }}
  UiButton(
    variant="ghost"
    icon="CloseIcon"
    @click="$emit('cancel')"
  ) {{ $t('actions.cancel') }}
.step-container
  .step-header
    .step-title {{ $t('onboarding.summary.title', { integration_name: integrationName }) }}
    .step-description {{ $t('onboarding.no_rights.description', { company_name: targetCompany.name }) }}
  UiStack(justify="center")
    UiStackItem
      UiButton(:loading="inProgress" @click="submit") {{ $t('onboarding.no_rights.action_send_request_to_admin') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import OnboardingStep from '../OnboardingStep.js';

export default {
  emits: ['to-step', 'progress'],
  props: {
    attemptId: {
      type: String,
      default: null,
    },
    integrationName: {
      type: String,
      default: null,
    },
    targetCompany: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    moveBack() {
      this.$emit('progress', 65);
      this.$emit('to-step', OnboardingStep.CHOOSE_COMPANY);
    },
    async submit() {
      this.inProgress = true;

      try {
        await new IntegrationAttempts(null, { id: this.attemptId }).request(this.targetCompany.id);
      } catch (error) {
        // do nothing
      } finally {
        this.inProgress = false;
      }

      this.$emit('progress', 100);
      this.$emit('to-step', OnboardingStep.SUCCESS_REQUEST);
    },
  },
};
</script>

<style lang="scss" scoped></style>
