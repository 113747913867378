<template lang="pug">
StackItem
  Stack(justify="space-between")
    StackItem
      UiText(variant="headingMd") {{ $t('company.reports.themes.title') }}
    StackItem
      UiButton(
        icon="FileUploadIcon"
        :disabled="!canUpload"
        @click="addReportTheme"
      ) {{ $t('company.reports.add_theme') }}
StackItem
  .theme(v-for="theme in themes")
    span.theme-name {{ theme.name }}
    .theme-colors
      .theme-color-item(v-for="color in theme.colors" :style="{ backgroundColor: color }")
    div
      UiButton(
        round
        variant="text-primary"
        icon="Edit"
        @click="editThemeModal(theme)"
      )
      UiButton(
        round
        variant="text-danger"
        icon="Trash"
        @click="removeThemeModal(theme.id)"
      )
  NoData(v-if="!themes.length")
ConfirmModal(
  v-if="confirmation.show"
  show
  :text="$t('edit_company.company.confirm_delete_theme')"
  :confirm="confirmation.action"
  :buttons="{ confirmText: $t('delete') }"
  :close="confirmation.cancel"
)
ImportTheme(ref="importTheme" @update="fetchThemes")
ThemeEdit(ref="themeEdit" @update="fetchThemes")
</template>

<script>
import ApiReportThemes from '@/api/models/ReportThemes.js';
import ConfirmModal from '@/modals/common/Confirm.vue';

import ImportTheme from '@/modals/Reports/ImportTheme.vue';
import { mapGetters } from 'vuex';
import NoData from '@/components/DataTable/NoData.vue';
import ThemeEdit from '@/modals/Reports/ThemeEdit.vue';

export default {
  name: 'ThemesUpload',
  components: { NoData, ImportTheme, ConfirmModal, ThemeEdit },
  inheritAttrs: false,
  data() {
    return {
      themes: [],
      confirmation: {
        show: false,
        action: () => {},
        cancel: () => {},
      },
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('company', ['currentCompany']),
    canUpload() {
      const MAX_AVAILABLE_THEME = this.isAdmin ? 4 : 3;
      return this.themes.length < MAX_AVAILABLE_THEME;
    },
  },
  created() {
    this.fetchThemes();
  },
  methods: {
    async fetchThemes() {
      const result = await new ApiReportThemes({ adminApi: this.isAdmin }).fetchAll();
      this.themes = result.data.power_bi_report_themes;
    },
    editThemeModal(theme) {
      this.$refs.themeEdit.open(theme.id, theme.name);
    },
    removeThemeModal(id) {
      this.confirmation.show = true;

      this.confirmation.cancel = () => {
        this.confirmation.show = false;
      };
      this.confirmation.action = () => {
        this.removeTheme(id);
        this.confirmation.cancel();
      };
    },
    async removeTheme(id) {
      try {
        await new ApiReportThemes({ adminApi: this.isAdmin }, { id }).delete();
        await this.fetchThemes();

        if (this.currentCompany) {
          let { report_themes } = this.currentCompany;
          report_themes = report_themes.filter((theme) => theme.id !== id);

          this.$store.dispatch('company/restoreCompany', {
            id: this.currentCompany.id,
            report_themes,
          });
        }
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      }
    },
    addReportTheme() {
      this.$refs.importTheme.open();
    },
  },
};
</script>

<style scoped lang="scss">
.theme {
  display: flex;
  align-items: center;
  background: var(--bb-gray-900);
  padding: 5px 12px 5px 25px;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: var(--bb-app-bg-main);
  }

  &-name {
    flex-basis: 30%;
  }

  &-colors {
    display: flex;
  }

  &-color-item {
    width: 24px;
    height: 24px;
  }
}
</style>
