function buildPathRecursive(entities, entityId, options, path = []) {
  if (path.length >= options.maxLevel) {
    return path;
  }
  const target = entities.find((entitiy) => entitiy[options.idKey] === entityId);
  if (target) {
    path.unshift(target);
    return buildPathRecursive(entities, target[options.parentIdKey], options, path);
  }
  return path;
}

export function buildPath(entities, entityId, options) {
  const _options = {
    idKey: 'id',
    parentIdKey: 'parent_id',
    maxLevel: 24,
    ...options,
  };
  return buildPathRecursive(entities, entityId, _options);
}
