<template lang="pug">
DataTableRowActions(:actions="actions" :moreActions="moreActions")

// TODO: move modals to higher level
RefreshModal(ref="refreshModal" @done="$emit('reload')")
ConfirmModal(
  :show="showConfirm"
  :text="confirmText"
  :confirm="confirmAction"
  :buttons="{ confirmText: confirmButtonText }"
  :close="closeConfirmModal"
)
</template>

<script>
import { mapGetters } from 'vuex';
import copy from 'copy-to-clipboard';
import Company from '@/models/Company.js';
import ConfirmModal from '@/modals/common/Confirm.vue';
import RefreshModal from '@/modals/Reports/Refresh.vue';
import PowerBiReports from '@/api/models/PowerBiReports.js';
import { generatePowerBiReportLink } from '@/common/generate-power-bi-report-link.js';

export default {
  components: {
    ConfirmModal,
    RefreshModal,
  },

  emits: ['manageAccess', 'edit', 'delete', 'reload'],

  props: {
    entity: {
      type: Object,
      required: true,
    },

    showCopyLinkAction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showConfirm: false,
      confirmText: '',
      confirmButtonText: '',
      confirmAction: () => {},
    };
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompanyId', 'currentCompany']),

    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },

    canRefresh() {
      return this.entity.extras.refresh_status !== 'active' && this.entity.extras.can_refresh;
    },

    canResetRefresh() {
      return this.entity.extras.refresh_status === 'error';
    },

    isStandardReport() {
      return this.entity.data.report_type === 'standard_report';
    },

    actions() {
      const actions = [];

      if (this.isAdmin && !this.isViewAsOtherUser) {
        actions.push({
          icon: 'TwoUsers',
          label: this.$t('actions.manage_access'),
          onClick: () => this.$emit('manageAccess'),
        });
      }

      return actions;
    },

    moreActions() {
      const actions = [];

      if (this.isStandardReport && this.canRefresh) {
        actions.push({
          icon: 'Refresh',
          label: this.$t('company.reports.refresh_pbix'),
          onClick: this.openRefreshModal,
        });
      }

      if (this.isStandardReport && this.canResetRefresh) {
        actions.push({
          icon: 'CloseIcon',
          label: this.$t('company.reports.reset_refresh_pbix'),
          onClick: this.openConfirmResetRefreshModal,
        });
      }

      if (this.showCopyLinkAction) {
        actions.push({
          icon: 'CopyLinkIcon',
          label: this.$t('actions.copy_link'),
          onClick: this.copyLinkToClipboard,
        });
      }

      if (this.entity.data.report_group_id_pbi && this.entity.data.report_id_pbi) {
        actions.push({
          icon: 'Powerbi',
          label: this.$t('actions.copy_powerbi_report_link'),
          onClick: this.copyPowerBiLinkToClipboard,
        });
      }

      if (
        this.isAdmin &&
        !this.isViewAsOtherUser &&
        (this.currentCompany?.public_embedded_links_enabled ||
          this.currentCompany?.private_embedded_links_enabled)
      ) {
        actions.push({
          icon: 'Share',
          label: this.$t('report_embedded_links.embedded_links'),
          onClick: () =>
            this.$router.push({
              name: 'ReportEmbeddedLinks',
              params: { id: this.entity.id },
            }),
        });
      }

      if (this.entity.entity_access.update && this.entity.extras.editable) {
        actions.push({
          icon: 'PencilCreate',
          label: this.$t('actions.edit'),
          onClick: () => this.$emit('edit'),
        });
      }

      if (
        this.entity.entity_access.destroy &&
        (this.entity.extras.editable || this.entity.extras.refresh_again)
      ) {
        actions.push({
          icon: 'Trash',
          label: this.$t('actions.delete'),
          onClick: () => this.$emit('delete'),
          variant: 'danger',
        });
      }

      return actions;
    },
  },

  methods: {
    copyLinkToClipboard() {
      const reportUrl = `${window.location.origin}/analytics/report/${this.entity.id}`;
      const result = copy(reportUrl);
      if (!result) {
        return;
      }
      this.$toaster.add({
        message: this.$t('company.reports.copied'),
      });
    },

    copyPowerBiLinkToClipboard() {
      const reportUrl = generatePowerBiReportLink(
        this.entity.data.report_group_id_pbi,
        this.entity.data.report_id_pbi,
      );
      const result = copy(reportUrl);
      if (!result) {
        return;
      }
      this.$toaster.add({
        message: this.$t('company.reports.copied'),
      });
    },

    editReport() {
      this.$refs.editReportModal.open(this.entity);
    },

    reportAccessList() {
      this.$refs.accessListModal.open(this.entity);
    },

    // TODO: Move to separate modal
    openConfirmResetRefreshModal() {
      this.showConfirm = true;
      this.confirmText = this.$t('user_groups.confirm_reset_to_refresh', {
        name: this.entity.name,
      });
      this.confirmButtonText = this.$t('actions.reset');
      this.confirmAction = () => this.resetRefresh();
    },

    closeConfirmModal() {
      this.showConfirm = false;
      this.confirmText = '';
      this.confirmButtonText = '';
      this.confirmAction = () => {};
    },

    resetRefresh() {
      new PowerBiReports(null, { id: this.entity.id })
        .resetRefresh()
        .then((_res) => {
          this.$emit('reload');
          this.closeConfirmModal();
        })
        .catch(() => {
          this.closeConfirmModal();
        });
    },

    openRefreshModal() {
      this.$refs.refreshModal.open(this.entity);
    },
  },
};
</script>
