<template lang="pug">
.list-tab
  EntityListLoadingState(v-if="inProgress && !items.length")
  EntityListStateError(
    v-else-if="error"
    :error="error"
    @retry="fetchState()"
  )
  template(v-else)
    EntityAccessListItem(
      v-for="user in items"
      :listItem="user"
      :selected="parentLockedIds[user.id] || !!selectedItemsIds[user.id]"
      :disabled="parentLockedIds[user.id] || inProgress || isAdmin(user.role)"
      @click="toggleListItem(user)"
    )
      template(#after="{ disabled }")
        UiStack(justify="end")
          UiStackItem(shrink)
            UiMultiselect.list-tab-select(
              v-if="selectedItemsIds[user.id]"
              size="sm"
              :disabled="disabled"
              :options="options"
              :canClear="false"
              :canDeselect="false"
              createOption
              :modelValue="selectedItemsIds[user.id]"
              @click.stop
              @select="toggleListItem(user, $event)"
            )
    InfiniteLoading(
      :disabled="!items.length || error"
      :loading="inProgress"
      @load="fetchMore"
    )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import Entities from '@/api/models/Entities.js';
import Users from '@/api/models/Users.js';
import CompanyUsers from '@/api/models/CompanyUsers';
import CONTENT_ENTITY_ACCESS_ACTIONS from '@/helper/content-entity-access-actions';
import Company from '@/models/Company';
import { ITEMS_PER_PAGE } from './entity-access-list-modal.vue';
import EntityAccessListItem from './entity-access-list-item.vue';
import entityAccessListMixin from './entity-access-list-mixin';
import EntityListLoadingState from './entity-list-state-loading.vue';
import EntityListStateError from './entity-list-state-error.vue';

export default defineComponent({
  components: {
    InfiniteLoading,
    EntityAccessListItem,
    EntityListLoadingState,
    EntityListStateError,
  },

  mixins: [entityAccessListMixin],

  emits: ['update:inProgress', 'done'],

  computed: {
    options() {
      return this.getNormalizedOptions(Object.values(CONTENT_ENTITY_ACCESS_ACTIONS));
    },
  },

  methods: {
    isAdmin(role) {
      return Company.isAdmin(role);
    },
    async fetchTabsListItemsRequest() {
      if (this.abortControllerListItems) {
        this.abortControllerListItems.abort();
      }
      this.abortControllerListItems = new AbortController();
      return new CompanyUsers().fetchAll(
        {
          ...this.filters,
          search: this.search || undefined,
          per_page: ITEMS_PER_PAGE,
        },
        { signal: this.abortControllerListItems.signal },
      );
    },

    fetchEntityUsersRequest() {
      return new Users({
        owner: new Entities(null, { id: this.entity.id }),
      }).fetchAll({
        per_page: 1000,
      });
    },

    fetchMore() {
      if (this.inProgress || this.reachEnd) {
        return;
      }
      this.filters.page++;
      this.fetchTabsListItems();
    },

    async fetchTabsListItems() {
      try {
        this.$emit('update:inProgress', true);
        const response = await this.fetchTabsListItemsRequest();
        if (response.data.company_users.length < ITEMS_PER_PAGE) {
          this.reachEnd = true;
        }
        this.items = [...this.items, ...response.data.company_users];
      } catch (error) {
        if (error.message === 'canceled') {
          return;
        }
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.$emit('update:inProgress', false);
      }
    },

    async fetchState() {
      try {
        this.$emit('update:inProgress', true);
        this.error = null;
        const [companyUsersResponse, entityUsersResponse] = await Promise.all([
          this.fetchTabsListItemsRequest(),
          this.fetchEntityUsersRequest(),
        ]);

        if (companyUsersResponse.data.company_users.length < ITEMS_PER_PAGE) {
          this.reachEnd = true;
        }
        this.items = companyUsersResponse.data.company_users;
        this.selectedItemsIds = {};
        entityUsersResponse.data.company_users.forEach((user) => {
          this.selectedItemsIds[user.company_user_id] = user.entity_action;
          if (user.parent_locked_access) {
            this.parentLockedIds[user.company_user_id] = true;
          }
        });
        this.setInitialSelectedItemsIds();
      } catch (error) {
        if (error.message === 'canceled') {
          return;
        }
        this.error = error.message;
      } finally {
        this.$emit('update:inProgress', false);
      }
    },

    getNormalizedOptions(optionsValuesList) {
      return optionsValuesList.map((value) => ({
        value,
        label: this.$t(`user_groups.modals.entity_access_list.entity_actions.${value}`),
      }));
    },

    deselectAll() {
      const lockedIds = Object.keys(this.parentLockedIds);
      this.items.forEach((item) => {
        if (!this.isAdmin(item.role) && !lockedIds.includes(String(item.id))) {
          delete this.selectedItemsIds[item.id];
        }
      });
    },

    selectAll() {
      this.items.forEach((item) => {
        if (!this.selectedItemsIds[item.id] && !this.isAdmin(item.role)) {
          this.selectedItemsIds[item.id] = CONTENT_ENTITY_ACCESS_ACTIONS.READ;
        }
      });
    },

    toggleListItem(item, value) {
      if (value) {
        this.selectedItemsIds[item.id] = value;
      } else if (this.selectedItemsIds[item.id]) {
        delete this.selectedItemsIds[item.id];
      } else {
        this.selectedItemsIds[item.id] = CONTENT_ENTITY_ACCESS_ACTIONS.READ;
      }
    },

    async submit() {
      this.$emit('update:inProgress', true);
      try {
        await new Users({
          owner: new Entities(null, { id: this.entity.id }),
          singularKey: null,
        }).updateBatch({
          company_users: Object.entries(this.selectedItemsIds).map(([companyUserId, action]) => ({
            id: companyUserId,
            entity_action: action,
          })),
        });
        this.$emit('done');
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.$emit('update:inProgress', false);
      }
    },
  },
});
</script>

<style lang="scss">
.list-tab-select {
  width: 140px;

  &.is-disabled {
    cursor: auto;

    .ui-multiselect-wrapper {
      cursor: auto;
    }
  }
}
</style>
