<template lang="pug">
UiBadge.cursor-pointer(
  v-if="isStandardReport && badge"
  size="sm"
  :variant="badge.variant"
  @click="openRefreshModal"
)
  Stack(spacing="xs")
    StackItem(v-if="[ReportStatus.ACTIVE, ReportStatus.WAITING].includes(status)")
      UiSpinner(speed="slow")
    StackItem {{ badge.label }}
</template>

<script>
import { mapGetters } from 'vuex';
import PowerBiReport from '@/models/power-bi-report.js';

const ReportStatus = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  WAITING: 'waiting',
  ERROR: 'error',
};

const ReportStatusLabel = {
  active: 'company.reports.refresh_pbix_active',
  finished: 'company.reports.refresh_pbix_finished',
  waiting: 'company.reports.refresh_pbix_waiting',
  error: 'company.reports.refresh_pbix_failed',
};

const ReportStatusVariant = {
  active: 'warning',
  finished: 'primary',
  waiting: 'warning',
  error: 'error',
};

export default {
  name: 'ReportStatus',

  emits: ['refresh'],

  props: {
    entity: {
      type: PowerBiReport,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['laptopView']),

    isStandardReport() {
      return this.entity.data.report_type === 'standard_report';
    },

    ReportStatus() {
      return ReportStatus;
    },

    status() {
      if (this.entity.extras.refresh_finished_message) {
        return ReportStatus.FINISHED;
      }

      if (Object.values(ReportStatus).includes(this.entity.extras.refresh_status)) {
        return this.entity.extras.refresh_status;
      }

      return null;
    },

    badge() {
      if (this.status) {
        return {
          label: this.$t(ReportStatusLabel[this.status]),
          variant: ReportStatusVariant[this.status],
        };
      }
      return null;
    },
  },

  methods: {
    openRefreshModal() {
      if (this.entity.extras.refresh_status === 'active') {
        return;
      }
      this.$emit('refresh');
    },
  },
};
</script>
