<template lang="pug">
modal(
  :show="show"
  :subtitle="$t('onboarding.cancel_modal.title', { integration_name: integrationName })"
  :close="close"
  width="540px"
)
  template(#footer)
    Stack
      StackItem
        UiButton(
          variant="outline-primary"
          data-test-id="button-close"
          @click="close"
        ) {{ $t('onboarding.cancel_modal.action_decline') }}
      StackItem
        UiButton(data-test-id="button-confirm" @click="submit") {{ $t('onboarding.cancel_modal.action_confirm') }}
</template>

<script>
import IntegrationAttempts from '@/api/models/IntegrationAttempts.js';
import OnboardingStep from './OnboardingStep.js';

export default {
  emits: ['closed', 'to-step', 'progress'],

  data() {
    return {
      show: false,
      integrationName: null,
    };
  },

  methods: {
    open(attemptId, integrationName = null) {
      this.attemptId = attemptId;
      this.integrationName = integrationName;
      this.show = true;
    },
    close() {
      this.$emit('closed');
      this.show = false;
    },
    async submit() {
      try {
        await new IntegrationAttempts(null, { id: this.attemptId }).delete();
      } catch {
        // do nothing
      } finally {
        this.close();
        this.$emit('progress', 100);
        this.$emit('to-step', OnboardingStep.CANCEL);
      }
    },
  },
};
</script>
