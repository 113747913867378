<template lang="pug">
.ui-text(:class="classes")
  slot
</template>

<script>
const headingOptions = [
  'heading2Xl',
  'headingXl',
  'headingLg',
  'headingMd',
  'headingSm',
  'headingXs',
];

const bodyOptions = ['bodyLg', 'bodyMd', 'bodySm'];

export default {
  props: {
    variant: {
      type: String,
      default: 'bodyMd',
      validate(value) {
        return [...headingOptions, ...bodyOptions].includes(value);
      },
    },
  },
  computed: {
    classes() {
      return [`ui-text--${this.variant}`];
    },
  },
};
</script>

<style lang="scss" scoped>
$heading-variants: (
  Lg: 24px,
  Md: 18px,
  Sm: 16px,
  Xs: 14px,
);

.ui-text {
  @each $size, $value in $heading-variants {
    &--heading#{$size} {
      font-size: $value;
      font-weight: 700;
    }
  }
}
</style>
