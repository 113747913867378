import _get from 'lodash/get.js';

class Report {
  constructor(p = {}) {
    this.id = _get(p, 'id', null);
    this.company_id = _get(p, 'company_id', undefined);
    this.name = _get(p, 'name', '');
    this.report_url = _get(p, 'report_url', '');
    this.report_id = _get(p, 'linked_report_id', null);
    this.order_num = _get(p, 'order_num', '');
    this.gs_id = _get(p, 'gs_id', null);
    this.editing = _get(p, 'editing', false);
    this.is_editable = _get(p, 'editable?', false);
    this.report_type = _get(p, 'report_type', null);
    this.visible_in_analytics = _get(p, 'visible_in_analytics', true);
    this.row_level_security = _get(p, 'row_level_security', false);
    this.rls_rule_name = _get(p, 'rls_rule_name', '');
    this.personal = _get(p, 'personal', false);
    this.linked_report_id = _get(p, 'linked_report_id', null);
    this.linked_report_ids = _get(p, 'linked_report_ids', []);
    this.pages = _get(p, 'pages', []);
    this.reports = _get(p, 'linked_report_ids', []);
    this.refresh_tables = _get(p, 'refresh_tables', []);
    this.refresh_status = _get(p, 'refresh_status', 'refreshed');
    this.refresh_message = _get(p, 'refresh_message', null);
    this.refresh_again = _get(p, 'refresh_again', false);
    this.can_refresh = _get(p, 'can_refresh', false);
    this.refresh_finished_message = _get(p, 'refresh_finished_message', false);
    this.report_group_id = _get(p, 'report_group_id', null);
    this.refresh_timeout = _get(p, 'refresh_timeout', null);
  }
}

export default Report;
