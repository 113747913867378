<template lang="pug">
.analytics-preview
  .analytics-preview__wrapper
    .analytics-preview__header
      h1 {{ $t('analytics.analytics') }}
      .analytics-preview__filters
        .analytics-preview__filter-date-wrapper
          .analytics-preview__filters-search
            UiInput(
              size="md"
              :placeholder="$t('analytics.search')"
              :modelValue="pagination.search"
              data-test-id="search"
              @update:modelValue="updateOptions({ search: $event })"
            )
              template(#icon)
                UiIcon(name="Search" data-test-id="icon-search")
        .analytics-preview__wrapper-right
          .analytics-preview__filter-date-wrapper
            UiStack(justify="space-between")
              UiStackItem
                .analytics-preview__filter-date
                  FolderSelect(
                    :modelValue="pagination.filter.report_group_id"
                    :filterByChildrenTypes="['PowerBiReport']"
                    data-test-id="report-group-select"
                    @update:modelValue="updateFilter({ report_group_id: $event })"
                  )
              UiButton(data-test-id="btn-clear" @click="clearData") {{ $t('clear') }}
    .analytics-preview__cards-container(v-if="reports.length")
      UiCard(v-for="report in reports" data-test-id="report-card")
        UiCardSection(padding="lg")
          UiStack(justify="space-between")
            UiStackItem(grow shrink)
              .analytics-preview__card__left
                span {{ report.name }}
            UiStackItem
              .analytics-preview__card__right
                UiButton(
                  icon="Eye"
                  :to="{ name: 'AnalyticsReport', params: { id: report.id } }"
                  data-test-id="btn-view"
                ) {{ $t('analytics.view') }}
      InfiniteLoading(:loading="loading" @load="fetchMoreReports")
    template(v-else)
      p.analytics-preview__no-reports {{ $t('analytics.no_reports') }}
</template>

<script>
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import FolderSelect from '@/components/folder-select.vue';
import Analytics from '@/api/models/Analytics.js';
import InfinitePagination from '@/models/InfinitePagination.js';
import isEqual from 'lodash/isEqual';

export default {
  name: 'AnalyticsShow',
  components: {
    InfiniteLoading,
    FolderSelect,
  },
  data() {
    return {
      reports: [],
      meta: null,
      abortController: null,
      loading: false,
      currentPage: 1,
      perPage: 30,
      pagination: new InfinitePagination({
        search: '',
        sort_order: 'desc',
        filter: {
          report_group_id: null,
        },
      }),
    };
  },
  watch: {
    $route() {
      this.handleFetchReports();
    },
  },
  mounted() {
    this.syncRouterPagination();
    this.fetchReports();
  },
  methods: {
    async fetchReports() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      this.loading = true;

      const { filter: filterOptions, ...paginationOption } = this.pagination;
      const params = { ...paginationOption, ...filterOptions };

      try {
        const response = await new Analytics().fetchAll(
          {
            search: params.search,
            page: this.currentPage,
            per_page: this.perPage,
            sort_order: params.sort_order,
            report_group_id: params.report_group_id,
            list: true,
          },
          {
            signal: this.abortController.signal,
          },
        );
        const { power_bi_reports, meta } = response.data;
        this.reports = [...this.reports, ...power_bi_reports];
        this.meta = meta;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    clearData() {
      const pagination = new InfinitePagination({
        ...this.pagination,
        search: null,
        filter: {
          ...this.pagination.filter,
          report_group_id: null,
        },
      });

      this.resetAndUpdatePagination(pagination);
    },
    updateRouterHistory(pagination) {
      this.$router.push({
        query: {
          ...this.$route.query,
          reports: JSON.stringify({ ...pagination }),
        },
      });
    },
    updateFilter(options) {
      const pagination = new InfinitePagination({
        ...this.pagination,
        filter: {
          ...this.pagination.filter,
          ...options,
        },
      });

      this.resetAndUpdatePagination(pagination);
    },
    updateOptions(options) {
      this.currentPage = 1;
      this.reports = [];

      const pagination = new InfinitePagination({
        ...this.pagination,
        ...options,
      });
      this.updateRouterHistory(pagination);
    },
    handleFetchReports() {
      try {
        const isEqualPagination = this.isEqualQueryPagination();

        if (!isEqualPagination) {
          this.syncRouterPagination();
          this.fetchReports();
        }
      } catch (error) {
        // do nothing
      }
    },
    fetchMoreReports() {
      if (this.loading || this.meta?.next_page === null) {
        return;
      }
      this.currentPage += 1;
      this.fetchReports();
    },
    resetAndUpdatePagination(pagination) {
      const isEqualPagination = isEqual(this.pagination, pagination);

      if (!isEqualPagination) {
        this.reports = [];
        this.currentPage = 1;
        this.updateRouterHistory(pagination);
      }
    },
    syncRouterPagination() {
      try {
        const query = this.$route?.query.reports;

        if (query) {
          this.pagination = new InfinitePagination({
            ...this.pagination,
            ...JSON.parse(query),
          });
        }
      } catch (error) {
        console.log(error);
        // do nothing
      }
    },
    isEqualQueryPagination() {
      try {
        const query = this.$route?.query.reports;

        const queryPagination = new InfinitePagination({
          ...this.pagination,
          ...JSON.parse(query),
        });

        return isEqual(this.pagination, queryPagination);
      } catch (error) {
        throw new Error();
      }
    },
  },
};
</script>

<style lang="scss">
.analytics-preview {
  width: 100%;

  &__report-groups {
    padding-top: 2rem;
  }

  &__wrapper {
    padding: 0 32px 4rem;
    @media screen and (max-width: 1000px) {
      padding: 0 16px 4rem;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__wrapper-right {
    display: flex;
    flex-wrap: wrap;
  }

  &__filter-date-wrapper {
    margin-top: 20px;
  }

  &__filters-search,
  &__filter-date {
    width: 330px;
  }

  &__filters-search {
    margin-right: 30px;
  }

  &__no-reports {
    font-size: 20px;
  }

  &__header {
    margin-top: 40px;
    margin-bottom: 20px;

    & h1 {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 22px;
  }

  &__card {
    &__left {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      & span:first-child {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .analytics-preview {
    .dashboard__groups__header {
      margin-left: 16px;
    }

    &__filters {
      flex-direction: column;
    }

    &__wrapper-right {
      flex-direction: column;
    }

    &__filters-search,
    &__filter-date {
      width: 100%;
      margin: 0;
    }

    &__filter-date-wrapper {
      .ui-stack-item {
        flex-grow: 1;
      }
    }

    &__filters-search {
      margin: 0;
    }

    &__cards-container {
      grid-template-columns: 1fr;
    }
  }
}
</style>
