<template lang="pug">
UiTreeItem(
  :label="item.entity.name"
  :opened="openedHash[item.entity.id]"
  :collapsable="!!item.children?.length"
  :active="item.entity.id === activeEntityId"
  @toggle="$emit('toggle', item)"
  @select="$emit('select', item)"
)
  UiTreeItemRecursive(
    v-for="childItem in item.children"
    :key="childItem.entity.id"
    :item="childItem"
    :openedHash="openedHash"
    :path="path"
    :activeEntityId="activeEntityId"
    @toggle="$emit('toggle', $event)"
    @select="$emit('select', $event)"
  )
</template>

<script>
export default {
  emits: ['toggle', 'select'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    openedHash: {
      type: Object,
      default: () => ({}),
    },
    activeEntityId: {
      type: String,
      default: null,
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
