<template lang="pug">
.password-form
  h3 {{ $t('password_form.reset_title') }}
  template(v-if="message")
    p {{ $t('password_form.reset_message') }}
    .password-form__approve
      UiButton(@click="continueUsage") {{ $t('actions.continue') }}
  template(v-else)
    .password-form__fields
      UiFormInput(
        v-model="email"
        :label="$t('password_form.email')"
        placeholder="@mail"
        :vuelidateModel="v$.email"
      )
      .password-form__approve
        UiButton(
          :disabled="inProgress"
          :loading="inProgress"
          @click="confirm"
        ) {{ $t('password_form.reset_submit') }}
</template>
<script>
import ApiUsers from '@/api/user.js';
import { email, required } from '@/common/validators.js';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'ResetPassword',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      email: '',
      inProgress: false,
      message: false,
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    confirm() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;
        this.inProgress = true;

        ApiUsers.resetPassword(this.email)
          .then((resp) => {
            if (resp.data.success) {
              this.inProgress = false;
              this.message = true;
            }
          })
          .catch((_err) => {
            this.inProgress = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.password-form {
  background-color: var(--bb-app-bg-main);
  width: 523px;
  margin: auto;
  padding: 20px 28px;

  & p {
    font-size: 16px;
  }

  & h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__error {
    color: #c9432a;
    margin: 1rem 0;
  }

  &__approve {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
