import oauth2Providers from '@/common/login_providers.js';
import locales from '@/localization/locales.js';
import axios from 'axios';
import ApiUser from '@/api/user.js';
import LocalStorage from '@/common/localstorage.js';
import { i18n } from '@/localization/index.js';

const login = ({ commit }, params) => {
  if (params.oauth2) {
    const provider = oauth2Providers.find((link) => link.slug === params.oauth2);
    if (provider) {
      window.location = provider.url;
    }
    return false;
  }
  return ApiUser.userLogin(params.email, params.password).then((response) => {
    commit('LOGIN', response.data);
  });
};

const integration_sign = ({ commit }) => {
  commit('UPDATE_USER', { integration_signed: true });
};

const logout = (store, redirect = true) => {
  delete axios.defaults.headers.common['BiBook-Company-Id'];
  if (redirect) {
    window.location.href = '/users/sign_out';
    return null;
  }
  return axios.get('/users/sign_out');
};

const updateUser = ({ commit }, user) => {
  commit('UPDATE_USER', user);
};

const switchLanguage = ({ commit }, value) => {
  const locale = locales.find((l) => l.value === value);
  if (!locale) {
    return;
  }
  LocalStorage.set('language', value);
  i18n.global.locale.value = value;
  axios.defaults.headers.common['BiBook-Locale'] = value;
  commit('SET_LOCALE', value);
};

const confirmAgreement = ({ commit }) => {
  commit('UPDATE_USER', { integration_required: false });
};

const updateIntegrationCompany = ({ commit }, data) => {
  commit('UPDATE_INTEGRATION_COMPANY', data);
};

const updateNavOpen = ({ commit }, value) => {
  commit('UPDATE_NAV_OPEN', value);
  commit('UPDATE_RESTORE_NAVIGATION_STATE', { navOpen: value });
};

const updateNavMobileOpen = ({ commit }, value) => {
  commit('UPDATE_NAV_MOBILE_OPEN', value);
};

const updateHeaderOpen = ({ commit }, value) => {
  commit('UPDATE_HEADER_OPEN', value);
  commit('UPDATE_RESTORE_NAVIGATION_STATE', { headerOpen: value });
};

const toggleMobileView = ({ commit }, value) => {
  commit('TOGGLE_MOBILE_VIEW', value);
};

const toggleLaptopView = ({ commit }, value) => {
  commit('TOGGLE_LAPTOP_VIEW', value);
};

export default {
  login,
  logout,
  switchLanguage,
  confirmAgreement,
  integration_sign,
  updateIntegrationCompany,
  updateNavOpen,
  updateUser,
  updateNavMobileOpen,
  updateHeaderOpen,
  toggleMobileView,
  toggleLaptopView,
};
