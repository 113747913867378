export default [
  { value: 'Admin:Administrators', label: 'admin_logs.category.administrators' },
  { value: 'Admin:Company', label: 'admin_logs.category.company' },
  { value: 'Admin:CompanyDomain', label: 'admin_logs.category.company_domain' },
  { value: 'Admin:Company:Users', label: 'admin_logs.category.company_users' },
  { value: 'Admin:CompanyGroup', label: 'admin_logs.category.company_group' },
  { value: 'Admin:Credential', label: 'admin_logs.category.credential' },
  { value: 'Admin:Company:UpdateBatch', label: 'admin_logs.category.company_update_batch' },
  { value: 'Admin:Invitation', label: 'admin_logs.category.invitation' },
  { value: 'Admin:PowerBiReportTheme', label: 'admin_logs.category.power_bi_report_theme' },
  { value: 'Admin:UiTheme', label: 'admin_logs.category.ui_theme' },
  { value: 'Admin:CompanyDynamicSettings:UpdateBatch', label: 'admin_logs.category.admin_company_dynamic_settings_update_batch' },
  { value: 'Admin:CompanyDynamicSettings', label: 'admin_logs.category.company_dynamic_settings' },
  { value: 'Admin:LoginBanner', label: 'admin_logs.category.login_banner' },
  { value: 'Admin:SystemBanners', label: 'admin_logs.category.system_banners' },
  { value: 'Admin:SystemBanner', label: 'admin_logs.category.system_banner' },
  { value: 'Users', label: 'admin_logs.category.users' },
];
