<template lang="pug">
Modal(
  :title="$t('analytics.create_bookmark.title')"
  :show="show"
  :close="close"
)
  template(#body)
    Stack(vertical align="stretch")
      StackItem
        UiFormInput(
          v-model="form.name"
          :label="$t('analytics.create_bookmark.name_label')"
          :placeholder="$t('analytics.create_bookmark.name_placeholder')"
          :vuelidateModel="v$.form.name"
          data-test-id="input-name"
        )
      StackItem
        UiCheckbox(
          v-model="form.private"
          switch
          data-test-id="checkbox-private"
        ) {{ $t('analytics.create_bookmark.private_label') }}

  template(#footer)
    Stack
      StackItem
        UiButton.action-button(
          variant="outline-primary"
          :disabled="inProgress"
          data-test-id="button-close"
          @click="close"
        ) {{ $t('analytics.create_bookmark.action_cancel') }}
      StackItem
        UiButton.action-button(
          :disabled="inProgress || isViewAsOtherUser"
          :loading="inProgress"
          data-test-id="button-save"
          @click="save"
        ) {{ $t('analytics.create_bookmark.action_save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';
import Bookmarks from '@/api/models/Bookmarks';

export default {
  emits: ['done'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      show: false,
      inProgress: false,
      form: {
        report_id: null,
        linked_report_id: null,
        name: '',
        private: false,
      },
      getBookmark: null,
    };
  },

  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser']),
  },

  methods: {
    open({ reportId, linkedReportId }, getBookmark = null) {
      this.form = {
        report_id: reportId,
        linked_report_id: linkedReportId,
        name: '',
        private: false,
      };
      this.getBookmark = getBookmark;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    async save() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      this.inProgress = true;

      const bookmark = await this.getBookmark();

      let saveBookmarkResponse = null;
      try {
        saveBookmarkResponse = await new Bookmarks().create({
          base64_state: bookmark.state,
          name: this.form.name,
          power_bi_report_id: this.form.report_id,
          linked_report_id: this.form.linked_report_id,
          user_id: this.form.private ? this.user.id : null,
        });
        this.inProgress = false;
        this.$emit('done', saveBookmarkResponse.data.bookmark);
        this.close();
      } catch {
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
