import AppBaseModel from './AppBaseModel';

class Entities extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'entities',
      singularKey: 'entity',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetchUserPrivilegesMatrix(params = {}, config = {}) {
    return this.httpClient.get(`${this.url}/user_privileges_matrix`, { params, ...config });
  }
}

export default Entities;
