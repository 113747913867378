<template lang="pug">
DataTableRowActions(:actions="actions" :moreActions="moreActions" alwaysShowMoreActions)
</template>

<script>
import Dataroom from '@/api/models/Dataroom.js';
import Company from '@/models/Company.js';
import { mapGetters } from 'vuex';

export default {
  emits: ['manageAccess'],

  props: {
    entity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
    ...mapGetters('company', ['currentCompany']),

    isAdmin() {
      return Company.isAdmin(this.currentCompany.role);
    },

    actions() {
      const actions = [];

      if (this.isAdmin && !this.isViewAsOtherUser) {
        actions.push({
          icon: 'TwoUsers',
          label: this.$t('actions.manage_access'),
          onClick: () => this.$emit('manageAccess'),
        });
      }

      return actions;
    },

    moreActions() {
      const actions = [];

      if (this.entity.extras.form_type === 'single_entry') {
        actions.push({
          icon: 'PencilCreate',
          label: this.$t('dataroom.fill_view'),
          onClick: () =>
            this.$router.push({
              name: 'DataroomView',
              params: { id: this.entity.id },
            }),
          disabled: this.isViewAsOtherUser,
        });
      }

      if (this.entity.extras.form_type === 'multi_entries') {
        actions.push({
          icon: 'Plus',
          label: this.$t('dataroom.new_entry'),
          onClick: () => this.toNewEntry(),
          disabled: this.isViewAsOtherUser,
        });
        actions.push({
          icon: 'Eye',
          label: this.$t('dataroom.view_all_entries'),
          onClick: () =>
            this.$router.push({
              name: 'DataroomEntries',
              params: { id: this.entity.id },
            }),
          disabled: this.isViewAsOtherUser,
        });
      }

      return actions;
    },
  },

  methods: {
    toNewEntry() {
      new Dataroom(null, { id: this.entity.id }).fetch({ new_entry: true }).then((response) => {
        this.$router.push({
          name: 'DataroomViewEntry',
          params: {
            id: this.entity.id,
            entry_id: response.data.dataroom_form.entry_id,
          },
        });
      });
    },
  },
};
</script>
