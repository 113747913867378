<template lang="pug">
div
  TabHeader
    template(#title) {{ $t('user_groups.group_permissions') }}
    template(#actions)
      UiButton(variant="smooth-primary" :to="entitiesTreeRoute") {{ $t('user_groups.action_show_entities_tree') }}
      UiButton(:loading="inProgress" @click="saveUserGroup") {{ $t('user_groups.action_save_permissions') }}
  .table-container
    UiDataTable(
      :headers="permissionsHeaders"
      :items="permissions"
      size="md"
    )
      template(
        #[`header:${permission}`]="{ header }"
        v-for="permission in availablePermissionsColumns"
      )
        .text-center {{ header.label }}
      template(#[`cell:${permission}`]="{ item }" v-for="permission in availablePermissionsColumns")
        Stack(v-if="item[permission] !== undefined" justify="center")
          StackItem
            UiCheckbox(
              switch
              :modelValue="item[permission]"
              :disabled="getPermissionDisabledState(permission, item)"
              @change="togglePermission(permission, item, $event)"
            )
    UiCardSection
</template>

<script>
import Companies from '@/api/models/Companies.js';
import UserGroups from '@/api/models/UserGroups.js';
import EntityTypeKey from '@/helper/entity-type-key.js';
import { mapGetters } from 'vuex';

export default {
  emits: ['updated'],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      privileges: null,
    };
  },
  computed: {
    ...mapGetters(['isAdminHost']),
    ...mapGetters('company', ['company']),

    entitiesTreeRoute() {
      return this.$adminCompanyLinkModifier({
        name: 'UserGroupsEntitiesTree',
        params: {
          user_group_id: this.$route.params.user_group_id,
        },
      });
    },

    availablePermissionsColumns() {
      const columns = {};
      Object.values(this.privileges).forEach((matrix) => {
        Object.keys(matrix).forEach((key) => {
          columns[key] = true;
        });
      });
      return [
        ...new Set(['manage', 'read', 'update', 'create', 'destroy', ...Object.keys(columns)]),
      ];
    },

    switchColumnWidth() {
      return 70 / this.availablePermissionsColumns.length;
    },

    dynamicPermissionsHeaders() {
      return this.availablePermissionsColumns.map((key) => ({
        key,
        label: this.$t(`user_groups.permissions.${key}`),
        width: `${this.switchColumnWidth}%`,
        centred: true,
      }));
    },

    permissionsHeaders() {
      return [
        {
          key: 'name',
          label: '',
        },
        ...this.dynamicPermissionsHeaders,
      ];
    },

    permissions() {
      return Object.keys(this.privileges ?? {}).map((key) => ({
        entity: key,
        name: this.$t(`user_groups.entity_name.${EntityTypeKey[key] ?? key}`),
        ...this.privileges[key],
      }));
    },
  },

  created() {
    this.privileges = JSON.parse(JSON.stringify(this.group.privileges));
  },

  methods: {
    async saveUserGroup() {
      try {
        this.inProgress = true;
        const response = await new UserGroups(
          {
            adminApi: this.isAdminHost,
            owner: this.isAdminHost ? new Companies(null, { id: this.company.id }) : null,
          },
          { id: this.$route.params.user_group_id },
        ).updatePermissions({ privileges: this.privileges });
        this.$toaster.add({
          title: this.$t('status.success'),
          message: this.$t('user_groups.permissions_updated_message'),
          actions: null,
        });
        this.$emit('updated', response.data.user_group);
      } catch {
        // do nothing
      } finally {
        this.inProgress = false;
      }
    },

    togglePermission(permission, item, value) {
      this.privileges[item.entity][permission] = value;
    },

    getPermissionDisabledState(permission, item) {
      if (this.inProgress) {
        return true;
      }

      if (permission === 'manage') {
        return false;
      }

      return item.manage;
    },
  },
};
</script>
