import AppBaseModel from './AppBaseModel';

class DataroomForms extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'dataroom_forms',
      singularKey: 'data',
      ...options,
    };
    super(mergedOptions, item);
  }

  getManager() {
    return this.httpClient.get(`${this.url}/manager`);
  }
}

export default DataroomForms;
