import AppBaseModel from './AppBaseModel.js';

class IntegrationsSoftware extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      adminApi: true,
      path: 'integrations_software',
      ...options,
    };
    super(mergedOptions, item);
  }

  updatePosition(params = {}, config = {}) {
    return this.httpClient.post(`${this.entityUrl}/update_position`, { ...params, ...config });
  }
}

export default IntegrationsSoftware;
