const SET_GROUPS = (state, value) => {
  state.groups = value;
};

const SET_IS_READY = (state, value) => {
  state.isReady = value;
};

export default {
  SET_GROUPS,
  SET_IS_READY,
};
