<template lang="pug">
.tab-container__row(v-show="!loading || themes.length")
  .tab-container__column.tab-container__column-form
    .colors-form
      Stack(vertical align="stretch")
        StackItem
          .themes-section-header
            UiText(variant="headingMd") {{ $t('portal_management.ui_themes.app_color_scheme') }}
            UiButton(
              size="sm"
              icon="Plus"
              @click="createTheme"
            ) {{ $t('portal_management.ui_themes.action_add_theme') }}
        StackItem
          UiInput(
            v-model="search"
            icon="Search"
            :placeholder="$t('portal_management.ui_themes.search_theme_placeholder')"
          )
            template(#icon)
              UiIcon(name="Search")
            template(#iconAfter v-if="search")
              UiIcon(name="Close" @click="search = ''")
        StackItem
          .theme-items
            .theme-item-wrapper(
              v-for="theme in filteredThemesList"
              :key="theme.id"
              :class="{ 'theme-item--active': theme.id === selectedThemeId }"
              @click="selectTheme(theme)"
            )
              .theme-item
                .theme-item__check
                  UiCheckbox(:modelValue="theme.id === selectedThemeId")
                .theme-item__body
                  .theme-item__name {{ theme.name }}
                  .theme-item__wrapper-colors
                    .theme-item__colors
                      .color-preview(
                        v-for="(defaultColor, key) in baseColors"
                        :style="{ backgroundColor: theme.colors[key] || defaultColor }"
                      )
                    .theme-item-analytics
                      .theme-item-analytics__title {{ $t('portal_management.ui_themes.analytics_report_colors') }}
                      .theme-item__colors
                        .color-preview(
                          v-for="(value, key) in analyticsColors"
                          :style="{ backgroundColor: theme.colors[key] || value }"
                        )
                .theme-item__actions(v-if="theme.id !== 'default'" @click.stop)
                  UiButton(
                    icon="Trash"
                    variant="ghost-danger"
                    round
                    @click="deleteTheme(theme)"
                  )
  .tab-container__column.tab-container__column-preview
    .container-preview
      .themes-section-header
        UiText(variant="headingMd") {{ $t('portal_management.ui_themes.theme_preview') }}
        UiText(v-if="selectedTheme") {{ selectedTheme.name }}
      UiCard.theme-preview-card
        ThemePreview(ref="themePreview")
        ThemeGenerator(
          v-if="baseThemePreviewEl"
          :target="baseThemePreviewEl"
          :colors="previewColors"
        )
    .container-preview
      .themes-section-header
        UiText(variant="headingMd") {{ $t('portal_management.ui_themes.analytics_preview') }}
      UiCard.theme-preview-card
        AnalyticsThemePreview(ref="analyticsThemePreview")
        ThemeGenerator(
          v-if="analyticsThemePreviewEl"
          :target="analyticsThemePreviewEl"
          :colors="previewColors"
        )
  DeleteThemeModal(ref="deleteThemeModal" @done="fetchThemes")
</template>

<script>
import ThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/index.vue';
import AnalyticsThemePreview from '@/views/Admin/PortalManagement/components/theme-preview/preview-analytics.vue';
import ThemeGenerator from '@/components/ThemeGenerator/ThemeGenerator.vue';
import ConfirmModal from '@/modals/common/Confirm.vue';
import { DEFAULT_THEME_COLORS, ANALYTICS_COLORS, BASE_COLORS } from '@/helper/ui-themes.js';
import UiThemes from '@/api/models/UiThemes.js';
import ColorPicker from '@/components/ColorPicker.vue';
import escapeRegExp from '@/common/escape_reg_exp.js';
import DeleteThemeModal from '@/modals/Admin/PortalManagement/UiThemes/DeleteTheme.vue';

const ThemeTab = {
  TEMPLATE: 'template',
  CUSTOM: 'custom',
};

export default {
  components: {
    ThemeGenerator,
    ThemePreview,
    AnalyticsThemePreview,
    ColorPicker,
    ConfirmModal,
    DeleteThemeModal,
  },
  data() {
    return {
      loading: true,
      themes: [],
      search: '',
      selectedThemeId: 'default',
      baseThemePreviewEl: null,
      analyticsThemePreviewEl: null,
    };
  },
  computed: {
    ThemeTab() {
      return ThemeTab;
    },
    defaultTheme() {
      return {
        id: 'default',
        name: 'Default',
        colors: DEFAULT_THEME_COLORS,
      };
    },
    themesList() {
      return [...this.themes];
    },
    filteredThemesList() {
      const regExp = new RegExp(escapeRegExp(this.search), 'i');
      return this.themesList.filter((t) => t.name.match(regExp));
    },
    selectedTheme() {
      return this.themesList.find((t) => t.id === this.selectedThemeId);
    },
    previewColors() {
      return this.selectedTheme?.colors ?? { ...DEFAULT_THEME_COLORS };
    },
    baseColors() {
      return BASE_COLORS;
    },
    analyticsColors() {
      return ANALYTICS_COLORS;
    },
  },
  created() {
    this.fetchThemes();
  },
  mounted() {
    this.baseThemePreviewEl = this.$refs.themePreview.$el;
    this.analyticsThemePreviewEl = this.$refs.analyticsThemePreview.$el;
  },
  methods: {
    async fetchThemes() {
      this.loading = true;
      const response = await new UiThemes({ adminApi: true }).fetchAll();
      this.themes = response.data.ui_themes.map((item) => ({
        id: item.id,
        name: item.name,
        colors: item.data.colors,
      }));
      this.selectedThemeId = this.themes[0]?.id ?? null;
      this.loading = false;
    },
    selectTheme(theme) {
      this.selectedThemeId = theme.id;
    },
    createTheme() {
      this.$router.push({
        name: this.$route.name,
        query: {
          tab: this.$route.query.tab,
          theme_id: 'new',
        },
      });
    },
    deleteTheme(theme) {
      this.$refs.deleteThemeModal.open(theme);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/stylesheet/variables';

.themes-section-header {
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__analytics {
    // margin-top: calc(40px - 16px);

    .ui-text {
      font-weight: 600;
    }
  }
}

.colors-form {
  width: 440px;
}

.container-preview {
  & + & {
    margin-top: 40px;
  }
}

.theme-items {
  // margin-left: -20px;
}

.theme-item-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 1px;
  }

  &:hover {
    background: rgba(var(--bb-primary-rgb), 0.1);
  }

  &__check,
  &__actions {
    flex-shrink: 0;
  }
}

.theme-item-analytics {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;

  &__title {
    font-size: 14px;
    margin-right: 36px;
  }
}

.theme-item {
  // padding: 15px;
  // border-radius: 10px;
  // cursor: pointer;

  // border-left: 3px solid transparent;
  display: flex;
  gap: 10px;

  // & + & {
  //   margin-top: 1px;
  // }

  // &:hover {
  //   background: rgba(var(--bb-primary-rgb), 0.1);
  // }

  &--active {
    background: rgba(var(--bb-primary-rgb), 0.2);
    border-color: var(--bb-primary);
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  // &__check,
  // &__actions {
  //   flex-shrink: 0;
  // }

  &__wrapper-colors {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__body {
    flex-shrink: 1;
    // flex-grow: 1;
  }
}

.theme-item__colors {
  display: flex;
  gap: 4px;
}

.color-preview {
  box-shadow: inset 0 0 0 1px rgba(black, 0.1), 0 0 0 1px rgba(white, 0.25);
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;

  &--lg {
    width: 4rem;
    height: 4rem;
  }
}

.theme-preview-card {
  overflow: hidden;
}

.btn-actions {
  display: flex;
  gap: 10px;
}

.save-btns {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.tab-container {
  padding: 15px;

  &__row {
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }
  }

  &__column {
    flex-grow: 1;

    &-form {
      flex-shrink: 0;
    }

    &-preview {
      flex-shrink: 1;
    }
  }
}

.custom-color {
  display: flex;
  gap: 20px;
  align-items: center;

  & + & {
    margin-top: 15px;
    line-height: 1.2;
  }

  &__swatch {
    cursor: pointer;
  }

  &__description {
    margin-top: 0.25em;
    font-size: 0.9em;
    line-height: 1.2;
    opacity: 0.5;
  }
}

.colors-demo {
  display: flex;
  gap: 10px;

  &__item {
    border: 1px solid var(--bb-gray-800);
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;

    &--big {
      width: 3rem;
      height: 3rem;
    }
  }
}

.checkbox-description {
  padding-left: 10px;
  margin: 0;
  line-height: 20px;
  font-size: 1rem;
}
</style>
