<template><div></div></template>

<script>
export default {
  mounted() {
    this.$store.commit('RECORD_NAVIGATION_STATE');
    this.$store.commit('UPDATE_HEADER_OPEN', false);
    this.$store.commit('UPDATE_NAV_OPEN', false);
  },
  beforeUnmount() {
    this.$store.commit('RESTORE_NAVIGATION_STATE');
  },
};
</script>

<style lang="scss" scoped></style>
