import { createI18n } from 'vue-i18n';
import LocalStorage from '@/common/localstorage.js';
import uiKitEn from '@bi-book/bibook-ui-kit/src/locale/locales/en.json';
import locales from './locales.js';

export const FALLBACK_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE = 'en';
export const INITIAL_LANGUAGE = LocalStorage.get('language', DEFAULT_LANGUAGE, String);

export const i18n = createI18n({
  legacy: false,
  silentTranslationWarn: true,
  warnHtmlInMessage: 'off',
  locale: INITIAL_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  datetimeFormats: locales.reduce((val, loc) => {
    if (!loc.dateTimeFormats) return val;
    val[loc.value] = loc.dateTimeFormats;
    return val;
  }, {}),
  messages: locales.reduce((val, loc) => {
    val[loc.value] = {
      ...loc.localeApp,
      ...uiKitEn,
    };
    return val;
  }, {}),
});

export const ReportLocaleCodes = locales.reduce((val, loc) => {
  val[loc.value] = loc.localeCode;
  return val;
}, {});

export const ReportLanguages = locales.reduce((val, loc) => {
  val[loc.value] = loc.reportLanguage;
  return val;
}, {});

export default i18n;
