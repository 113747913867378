<template lang="pug">
div
  UiFormInput(
    v-bind="$attrs"
    v-maska
    :modelValue="modelValue"
    placeholder="XXX.XXX.XXX.XXX"
    :vuelidateModel="v$.modelValue"
    data-maska="#00.#00.#00.#00"
    data-maska-tokens="0:[0-9]:optional"
  )
    template(#iconAfter)
      UiTooltip(
        :title="$t('report_embedded_links.modals.create.action_remove_ip_address')"
        :disabled="disableDelete"
        variant="dark"
      )
        template(#activator)
          UiButton(
            :disabled="disableDelete"
            variant="text-danger"
            icon="Trash"
            round
            size="sm"
            block
            @click="() => $emit('delete')"
          )
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { ipAddress } from '@/common/validators.js';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  validations() {
    return {
      modelValue: {
        ipAddress,
      },
    };
  },
};
</script>
