<template lang="pug">
.admin-layout
  Heading(:title="$t('users.title')")
  .table-container
    RestTable(
      ref="table"
      :headers="headers"
      :fetchHandler="fetch"
      :searchLabel="$t('users.search_placeholder')"
      searchable
      namespace="users_list"
    )
      template(v-slot:cel_name="{ item }")
        Stack(align="center" spacing="sm")
          StackItem
            AvatarBox(
              :src="item.avatar"
              :id="item.email"
              :label="item.name"
              size="sm"
            )
          StackItem
            | {{ item.name || '—' }}
      template(v-slot:cel_email="{ item }")
        span {{ item.email || '—' }}
      template(v-slot:cel_companies_counter="{ item }")
        RouterLink(:to="{ name: 'UserCompanies', params: { id: item.id } }") {{ item.companies_count }}
</template>

<script>
import { mapGetters } from 'vuex';
import Users from '@/api/models/Users.js';

export default {
  name: 'Users',
  data() {
    return {
      headers: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          squeeze: true,
        },
        {
          key: 'name',
          label: this.$t('companies.headers.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('companies.headers.email'),
          sortable: true,
        },
        {
          key: 'companies_counter',
          label: this.$t('companies.headers.companies_counter'),
          squeeze: true,
          headerClass: 'text-nowrap',
          cellClass: 'text-center',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
  },

  methods: {
    async fetch(params) {
      let items;
      let meta;

      await new Users({ adminApi: true }).fetchAll(params).then((res) => {
        items = res.data.users;
        ({ meta } = res.data);
      });

      return {
        items,
        meta,
      };
    },
  },
};
</script>

<style lang="scss">
.table-container {
  background: var(--bb-app-bg-main);
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.admin-users__table {
  &__name {
    display: flex;
    align-items: center;

    & img {
      width: 34px;
      height: 34px;
      border-radius: 17px;
      margin-right: 15px;
    }
  }
  &__counter {
    margin-left: 10px;
  }
}
</style>
