<template lang="pug">
UiModal(
  :title="$t('system_banners.modals.delete.title')"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="500"
  @close="close"
)
  | {{ $t('system_banners.modals.delete.message') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Companies from '@/api/models/Companies.js';
import SystemBanners from '@/api/models/system-banners.js';

export default {
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      banner: null,
      isPortalManagementPage: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.$t('actions.delete'),
          onClick: this.submit,
          loading: this.inProgress,
          variant: 'smooth-danger',
        },
      ];
    },
  },
  methods: {
    open({ banner = {}, isPortalManagementPage = false } = {}) {
      this.banner = banner;
      this.isPortalManagementPage = isPortalManagementPage;
      this.show = true;
    },
    async submit() {
      this.inProgress = true;
      try {
        await new SystemBanners(
          {
            adminApi: this.isAdmin,
            owner:
              this.isAdmin && !this.isPortalManagementPage
                ? new Companies(null, { id: this.$route.params.id })
                : null,
          },
          { id: this.banner.id },
        ).delete();
        this.$emit('done');
        this.close();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>
