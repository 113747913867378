import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

const state = {
  originalCompany: null,
  company: null,
  loading: true,
  inProgress: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
