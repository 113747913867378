<template lang="pug">
.admin-layout
  Heading(:title="$t('credentials.title')")
  .table-container
    RestTable(:headers="headers" :fetchHandler="fetch")
      template(v-slot:cel_access_type="{ item }")
        router-link(:to="{ name: 'EditMasterKeys', params: { id: item.id } }") {{ item.access_type }}
      template(v-slot:cel_token="{ item }")
        .token
          .token__txt
            | {{ item.token }}
          .token__icon
            UiIcon.gray-icon-700(
              name="Copy"
              size="20"
              @click="copyToClipboard(item.token)"
            )
      template(v-slot:cel_refresh_token="{ item }")
        .token
          .token__txt
            | {{ item.refresh_token }}
          .token__icon
            UiIcon.gray-icon-700(
              name="Copy"
              size="20"
              @click="copyToClipboard(item.refresh_token)"
            )
</template>

<script>
import copy from 'copy-to-clipboard';
import Credentials from '@/api/models/Credentials.js';

export default {
  name: 'MasterKeys',
  computed: {
    headers() {
      return [
        {
          key: 'access_type',
          label: this.$t('edit_company.keys.type'),
          squeeze: true,
          sortable: true,
        },
        {
          key: 'access_role',
          label: this.$t('edit_company.keys.role'),
          squeeze: true,
          sortable: true,
        },
        {
          key: 'username',
          label: this.$t('edit_company.keys.username'),
          squeeze: true,
          sortable: true,
        },
        {
          key: 'token',
          label: this.$t('edit_company.keys.token'),
          squeeze: true,
          sortable: true,
        },
        {
          key: 'refresh_token',
          label: this.$t('edit_company.keys.refresh_token'),
          squeeze: true,
          sortable: true,
        },
      ];
    },
  },
  methods: {
    async fetch(params) {
      let items;
      let meta;

      await new Credentials().fetchAll(params).then((res) => {
        items = res.data.credentials;
        ({ meta } = res.data);
      });

      return {
        items,
        meta,
      };
    },
    copyToClipboard(val) {
      const result = copy(val);
      if (result) {
        this.$toaster.add({
          message: this.$t('company.reports.copied'),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.master-keys__table-access-type {
  cursor: pointer;
}

.token {
  display: flex;
  align-items: center;

  &__txt {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }

  &__icon {
    width: 24px;
    margin-left: 5px;
    cursor: pointer;
  }
}
@media screen and (max-width: 1100px) {
  .token__txt {
    max-width: 150px;
  }
}
@media screen and (max-width: 1000px) {
  .token__txt {
    max-width: 120px;
  }
}
</style>
