/**
 *
 * @param {Array} firstCollection
 * @param {Array} secondCollection
 * @param {Object} config
 * @returns {Array}
 */
export default function mergeCollections(firstCollection = [], secondCollection = [], config) {
  if (!Array.isArray(firstCollection)) {
    throw new Error('`firstCollection` is not an array');
  }

  if (!Array.isArray(secondCollection)) {
    throw new Error('`secondCollection` is not an array');
  }

  const cfg = {
    uniqValueKey: 'id',
    ...config,
  };

  const recordsMap = new Map();

  let extractUniqValue = (record) => {
    return record[cfg.uniqValueKey];
  };

  if (cfg.uniqValueKey instanceof Function) {
    extractUniqValue = cfg.uniqValueKey;
  }

  return [...firstCollection, ...secondCollection].filter((record) => {
    const uniqValue = extractUniqValue(record);
    const duplicate = recordsMap.has(uniqValue);
    if (!duplicate) {
      recordsMap.set(uniqValue, record);
    }
    return !duplicate;
  });
}
