import LocalStorage from '@/common/localstorage.js';

export const LOGIN_ATTEMPT_KEY = 'login-attempt';

export class LoginService {
  constructor(storage = LocalStorage) {
    this.storage = storage;
    this.hasLoginAttempt = this.storage.get(LOGIN_ATTEMPT_KEY, false);
  }

  trackLoginAttempt() {
    this.storage.set(LOGIN_ATTEMPT_KEY, true);
    this.hasLoginAttempt = true;
  }

  removeLoginAttemptTracker() {
    this.storage.remove(LOGIN_ATTEMPT_KEY);
    this.hasLoginAttempt = false;
  }
}

const loginService = new LoginService();

export default loginService;
