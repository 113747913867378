<template lang="pug">
.list-tab
  EntityListStateLoading(v-if="inProgress && !items.length")
  EntityListStateError(
    v-else-if="error"
    :error="error"
    @retry="fetchState()"
  )
  template(v-else)
    EntityAccessListItem(
      v-for="group in items"
      :listItem="group"
      :selected="parentLockedIds[group.id] || selectedItemsIds[group.id]"
      :disabled="parentLockedIds[group.id] || inProgress"
      @click="toggleListItem(group)"
    )
      template(#after)
        UiStack(justify="end")
          UiStackItem(shrink)
            UiInfo(iconLabel="i")
              h4 {{ $t('user_groups.modals.entity_access_list.groups_tooltip.header') }}
              p {{ $t('user_groups.modals.entity_access_list.groups_tooltip.description') }}
              UiButton(:href="`/company/user_groups/${group.id}`" target="_blank") {{ $t('actions.go') }}

    InfiniteLoading(
      :disabled="!items.length || error"
      :loading="inProgress"
      @load="fetchMore"
    )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InfiniteLoading from '@/components/InfiniteLoading.vue';
import Entities from '@/api/models/Entities.js';
import UserGroups from '@/api/models/UserGroups.js';
import { ITEMS_PER_PAGE } from './entity-access-list-modal.vue';
import EntityAccessListItem from './entity-access-list-item.vue';
import entityAccessListMixin from './entity-access-list-mixin';
import EntityListStateLoading from './entity-list-state-loading.vue';
import EntityListStateError from './entity-list-state-error.vue';

export default defineComponent({
  components: {
    InfiniteLoading,
    EntityAccessListItem,
    EntityListStateLoading,
    EntityListStateError,
  },

  mixins: [entityAccessListMixin],

  emits: ['update:inProgress', 'done'],

  methods: {
    selectAll() {
      this.items.forEach((group) => {
        this.selectedItemsIds[group.id] = true;
      });
    },

    deselectAll() {
      this.selectedItemsIds = {
        ...this.parentLockedIds,
      };
    },

    fetchTabsListItemsRequest() {
      if (this.abortControllerListItems) {
        this.abortControllerListItems.abort();
      }
      this.abortControllerListItems = new AbortController();
      return new UserGroups().fetchAll(
        {
          ...this.filters,
          search: this.search || undefined,
          per_page: ITEMS_PER_PAGE,
        },
        { signal: this.abortControllerListItems.signal },
      );
    },

    async fetchTabsListItems() {
      try {
        this.$emit('update:inProgress', true);
        const response = await this.fetchTabsListItemsRequest();
        if (response.data.user_groups.length < ITEMS_PER_PAGE) {
          this.reachEnd = true;
        }
        this.items = [...this.items, ...response.data.user_groups];
      } catch (error) {
        if (error.message === 'canceled') {
          return;
        }
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.$emit('update:inProgress', false);
      }
    },

    fetchMore() {
      if (this.inProgress || this.reachEnd) {
        return;
      }
      this.filters.page++;
      this.fetchTabsListItems();
    },

    fetchEntityGroupsRequest() {
      return new UserGroups({
        owner: new Entities(null, { id: this.entity.id }),
      }).fetchAll({
        per_page: 1000,
      });
    },

    async fetchState() {
      try {
        this.$emit('update:inProgress', true);
        this.error = null;
        const [userGroupsResponse, entityGroupsResponse] = await Promise.all([
          this.fetchTabsListItemsRequest(),
          this.fetchEntityGroupsRequest(),
        ]);

        if (userGroupsResponse.data.user_groups.length < ITEMS_PER_PAGE) {
          this.reachEnd = true;
        }
        this.items = userGroupsResponse.data.user_groups;
        this.selectedItemsIds = {};
        entityGroupsResponse.data.user_groups.forEach((group) => {
          this.selectedItemsIds[group.id] = true;
          if (group.parent_locked_group) {
            this.parentLockedIds[group.id] = true;
          }
        });
        this.setInitialSelectedItemsIds();
      } catch (error) {
        this.error = error.message;
        if (error.message === 'canceled') {
          return;
        }
      } finally {
        this.$emit('update:inProgress', false);
      }
    },

    toggleListItem(group) {
      if (this.selectedItemsIds[group.id]) {
        delete this.selectedItemsIds[group.id];
      } else {
        this.selectedItemsIds[group.id] = true;
      }
    },

    async submit() {
      this.$emit('update:inProgress', true);
      try {
        await new UserGroups({
          owner: new Entities(null, { id: this.entity.id }),
          singularKey: null,
        }).update({
          user_group_ids: Object.keys(this.selectedItemsIds),
        });
        this.$emit('done');
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.$emit('update:inProgress', false);
      }
    },
  },
});
</script>
