<template lang="pug">
UiStack(
  vertical
  spacing="lg"
  align="stretch"
)
  .form-section
    .form-section__main
      UiStack(align="center")
        UiStackItem(grow shrink)
          UiText(variant="headingMd") {{ $t('login_banners.title') }}
        UiButton(
          v-if="!loginBanner.length"
          icon="Plus"
          data-test-id="action-add-banner"
          @click="addLoginBanner"
        ) {{ $t('login_banners.action_add_banner') }}
        UiButton(
          v-if="loginBanner.length"
          variant="outline-secondary"
          :icon="loginBanner[0]?.enabled ? 'EyeSlash' : 'Eye'"
          data-test-id="action-enable-banner"
          @click="toggleEnabledBanner"
        ) {{ bannerEnabledTitle }}
    .form-section__details.align-self-center(v-if="loginBanner.length && !mobileView")
      .text-secondary {{ $t('login_banners.preview') }}
  LoginBannerCard.login-banner-tab__card-containter(
    v-for="banner in loginBanner"
    :key="banner.id"
    :banner="banner"
    @delete="deleteLoginBanner(banner)"
    @save="fetchLoginBanners"
  )
  UiCardSection(v-if="!inProgress && !this.loginBanner.length" padding="sm")
    .login-banner-tab__empty-state
      UiEmptyState(:description="$t('login_banners.empty_banners')")
  CreateLoginBannerModal(ref="createLoginBannerModal" @done="fetchLoginBanners")
  DeleteLoginBannerModal(ref="deleteLoginBannerModal" @done="fetchLoginBanners")
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBanners from '@/api/models/login-banners.js';
import CreateLoginBannerModal from '@/modals/login-banner/Create.vue';
import DeleteLoginBannerModal from '@/modals/login-banner/Delete.vue';
import LoginBannerCard from '@/components/login-banner/login-banner-card.vue';

export default {
  components: {
    CreateLoginBannerModal,
    DeleteLoginBannerModal,
    LoginBannerCard,
  },
  data() {
    return {
      inProgress: true,
      loginBanner: [],
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'mobileView']),

    bannerEnabledTitle() {
      return this.loginBanner[0]?.enabled
        ? this.$t('login_banners.action_hide_banner')
        : this.$t('login_banners.action_show_banner');
    },
  },
  created() {
    this.fetchLoginBanners();
  },
  methods: {
    async fetchLoginBanners() {
      this.inProgress = true;
      try {
        const response = await new LoginBanners({
          adminApi: this.isAdmin,
        }).fetchAll();
        this.loginBanner = response.data.login_banners;
      } catch (error) {
        console.log(error);
      } finally {
        this.inProgress = false;
      }
    },
    async toggleEnabledBanner() {
      try {
        this.loginBanner[0].enabled = !this.loginBanner[0].enabled;

        const params = {
          login_banner: {
            enabled: this.loginBanner[0]?.enabled,
          },
        };

        await new LoginBanners(
          {
            adminApi: this.isAdmin,
          },
          { id: this.loginBanner[0]?.id },
        ).update(params);
      } catch (error) {
        console.log(error);
        this.$toaster.add({
          type: 'error',
          title: 'Error',
          message: this.$t('validations.check_all_fields'),
        });
      }
    },
    addLoginBanner() {
      this.$refs.createLoginBannerModal.open();
    },
    deleteLoginBanner(item) {
      this.$refs.deleteLoginBannerModal.open({
        banner: item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.align-self-center {
  align-self: center;
}

.login-banner-tab {
  &__wrapper-title {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
  }

  &__left-title {
    width: 50%;
    margin-right: 40px;
  }

  &__right-title {
    font-size: 16px;
    color: var(--bb-gray-600);
  }

  &__empty-state {
    width: 50%;
  }
}
@media screen and (max-width: 1000px) {
  .login-banner-tab {
    &__left-title {
      margin: 0;
      width: 100%;
    }

    &__empty-state {
      width: 100%;
    }

    &__right-title {
      width: auto;
    }
  }
}
</style>
