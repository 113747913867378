<template lang="pug">
.card-login-banner(shadow="never")
  .card-login-banner__section.text-center(padding="xl")
    img.card-login-banner__info(src="@/assets/images/banner_info.svg")
    h5.card-login-banner__title {{ title }}
    p.card-login-banner__description(v-html="description")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-login-banner {
  border-radius: 10px;
  border: 1px solid var(--bb-gray-800);

  &__section {
    padding: 35px 26px 50px;
  }

  &__info {
    margin: auto;
    width: 60px;
    height: 60px;
  }

  &__title {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 18px;
  }

  &__description {
    font-size: 14px;
    margin: 0;
    color: var(--bb-gray-100);
    opacity: 0.6;
  }
}
</style>
