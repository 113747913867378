<template lang="pug">
DataTableRowActions(
  :actions="actions"
  :moreActions="moreActions"
  showOnHover
)
ConfirmModal(
  v-if="showConfirm"
  :show="showConfirm"
  :text="$t('user_groups.confirm_delete', { name: item.name })"
  :confirm="deleteUser"
  :buttons="{ confirmText: $t('delete') }"
  :close="closeConfirmModal"
)
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import Companies from '@/api/models/Companies.js';
import ApiUsers from '@/api/user.js';
import ConfirmModal from '@/modals/common/Confirm.vue';
import Company from '@/models/Company.js';

export default {
  name: 'UserControlActions',
  components: {
    ConfirmModal,
  },
  emits: ['edit'],
  props: {
    isAdminPage: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    reload: {
      type: Function,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inProgress: false,
      edit: false,
      showContext: false,
      showConfirm: false,
    };
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser', 'laptopView']),
    ...mapGetters('company', ['currentCompany']),

    isHighlighted() {
      return this.edit || this.show;
    },

    isCurrentUser() {
      return this.user.id === this.item?.user_id;
    },

    isCurrentUserAdmin() {
      return Company.isAdmin(this.currentCompany?.role) || this.isAdminPage;
    },
    options() {
      return [
        {
          key: 'edit',
          icon: 'PencilCreate',
          label: this.$t('actions.edit'),
          to: this.$adminCompanyLinkModifier({
            name: 'CompanyUserShow',
            params: { user_id: this.item.id },
          }),
          available:
            !this.isViewAsOtherUser &&
            this.isCurrentUserAdmin,
          laptop: true,
        },
        {
          key: 'remove',
          icon: 'Trash',
          label: this.$t('actions.remove'),
          onClick: this.openConfirmModal,
          variant: 'danger',
          available:
            !this.isViewAsOtherUser &&
            this.isCurrentUserAdmin,
          laptop: true,
        },
        {
          key: 'view_as',
          icon: 'UserEye',
          label: this.$t('company.view_as'),
          onClick: this.viewAs,
          available:
            !this.isViewAsOtherUser &&
            !this.isCurrentUser &&
            this.isCurrentUserAdmin &&
            !this.isAdminPage,
        },
      ].filter((option) => option.available);
    },

    actions() {
      return this.options.filter((option) => (this.laptopView ? option.laptop : false));
    },

    moreActions() {
      return this.options.filter((option) => (this.laptopView ? !option.laptop : true));
    },
  },

  updated() {
    if (this.showContext && !(this.show || this.edit)) {
      this.showContext = false;
    }
  },

  methods: {
    async viewAs() {
      this.inProgress = true;
      const { user_id } = this.item;
      try {
        await ApiUsers.loginAsOtherUser(user_id);
        window.location.reload();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },

    openEditUserModal() {
      this.$emit('edit');
    },

    reloadList() {
      return this.reload();
    },

    closeModal() {
      this.edit = false;
    },

    toggleContextMenu() {
      this.showContext = !this.showContext;
    },

    closeDropdown() {
      this.edit = false;
      this.showContext = false;
    },
    showAccessedReports() {
      this.$refs.accessedReports.open(this.item);
    },

    openConfirmModal() {
      this.showConfirm = true;
    },

    closeConfirmModal() {
      this.showConfirm = false;
    },

    async deleteUser() {
      try {
        await new CompanyUsers(
          {
            adminApi: this.isAdminPage,
            owner: this.isAdminPage
              ? new Companies(null, { id: this.$route.params.company_id })
              : null,
          },
          { id: this.item.id },
        ).delete();
        if (this.user.id === this.item.user_id) {
          window.location.reload();
          return;
        }
        this.closeConfirmModal();
        this.reloadList();
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$localizeErrorMessage(error),
          actions: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-control-buttons {
  display: flex;
  align-items: center;
  justify-content: end;

  &__items {
    display: flex;
    justify-content: flex-end;
    min-width: 92px;
    position: relative;
    padding-right: 1rem;

    & img {
      cursor: pointer;
      margin-right: 10px;
    }

    &--hover {
      display: flex;
      justify-content: space-around;
      gap: 15px;
      margin-right: 10px;
      cursor: pointer;

      &__single {
        top: -12px;
      }
    }
  }
}
</style>
