<template lang="pug">
UiCardSection(padding="lg")
  TabHeader(:title="$t('dynamic_settings.tab_title')")
    template(#actions)
      UiButton(
        variant="primary"
        icon="Plus"
        :disabled="!canAddDynamicField"
        data-vi="add-field"
        @click="editDynamicField()"
      ) {{ $t('dynamic_settings.add_field') }}
  RestTable(
    ref="table"
    :headers="headers"
    :fetchHandler="fetch"
    searchable
    filtered
    namespace="dynamic_settings"
    :searchLabel="$t('actions.search')"
  )
    template(v-slot:filter_block="{ filter, updateFilter }")
      UiStack(wrap spacing="lg")
        UiStack(wrap)
        UiStackItem
          .filter-by-title {{ $t('integration_requests.filter_by.title') }}
        UiStackItem.filters-block
          UiMultiselect(
            :options="fieldTypes"
            :modelValue="filter.attr_type"
            :allow-empty="true"
            size="sm"
            data-vi="filter-field-type"
            @update:modelValue="updateFilter({ attr_type: $event })"
          )
    template(v-slot:cel_label_en="{ item }") {{ item.label.en }}
    template(v-slot:cel_label_fi="{ item }") {{ item.label.fi }}
    template(v-slot:cel_label_sv="{ item }") {{ item.label.sv }}
    template(v-slot:cel_actions="{ item }")
      UiStack(spacing="sm")
        UiButton(
          variant="text-secondary"
          icon="Edit"
          size="sm"
          data-vi="edit-field"
          @click="editDynamicField(item)"
        )
        UiButton(
          icon="Trash"
          variant="text-danger"
          size="sm"
          data-vi="delete-field"
          @click="deleteDynamicFieldConfirmation(item.id)"
        )
  DynamicFieldModal(ref="dynamicFieldModal" @done="refetch()")
  DynamicFieldDeleteModal(ref="dynamicFieldDeleteModal" @done="refetch()")
</template>

<script>
import { defineComponent } from 'vue';
import DynamicFieldModal from '@/modals/dynamic-fields/dynamic-field-modal.vue';
import { fieldTypes } from '@/helper/dynamic-fields-options';
import { capitalize } from 'lodash';
import DynamicSettings from '@/api/models/dynamic-settings';
import DynamicFieldDeleteModal from '@/modals/dynamic-fields/dynamic-field-delete-modal.vue';
import DynamicField from '@/models/dynamic-field-model';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'DynamicSettingsTab',
  components: {
    DynamicFieldModal,
    DynamicFieldDeleteModal,
  },
  props: {},
  data() {
    return {
      inProgress: true,
      totalCount: 0,
    };
  },
  computed: {
    ...mapGetters(['dynamicSettingsMaxCount']),
    headers() {
      return [
        {
          key: 'label_en',
          label: this.$t('dynamic_settings.table.label_en'),
        },
        {
          key: 'label_fi',
          label: this.$t('dynamic_settings.table.label_fi'),
        },
        {
          key: 'label_sv',
          label: this.$t('dynamic_settings.table.label_sv'),
        },
        {
          key: 'attr_type',
          label: this.$t('dynamic_settings.table.field_type'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
    fieldTypes() {
      return fieldTypes.map((item) => ({ label: capitalize(item), value: item }));
    },
    canAddDynamicField() {
      return !this.inProgress && this.totalCount < this.dynamicSettingsMaxCount;
    },
  },
  methods: {
    async fetch(params) {
      this.inProgress = true;
      try {
        const response = await new DynamicSettings().fetchAll(params);
        this.totalCount = response.data.meta.total_count;
        return {
          meta: response.data.meta,
          items: response.data.dynamic_settings.map((item) => new DynamicField(item).toJSON()),
        };
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          title: this.$t('status.error'),
          message: this.$localizeErrorMessage(error),
        });
      } finally {
        this.inProgress = false;
      }
    },
    refetch() {
      this.$refs.table.fetchData();
    },
    editDynamicField(item) {
      this.$refs.dynamicFieldModal.open(item);
    },
    deleteDynamicFieldConfirmation(id) {
      this.$refs.dynamicFieldDeleteModal.open(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.filters-block {
  min-width: 200px;
}

.filters-search {
  min-width: 250px;
}

.filter-by-title {
  color: var(--bb-gray-500);
  font-weight: 600;
}
</style>
