<script>
import chroma from 'chroma-js';
import { DEFAULT_THEME_COLORS, MAIN_BG_KEY } from '@/helper/ui-themes.js';

export default {
  name: 'ThemeGenerator',
  props: {
    colors: {
      type: Object,
      default: null,
    },
    target: {
      type: Object, // node element
      default: null,
    },
  },
  data() {
    return {
      isDarkTheme: false,
      inputColors: {},
      generators: [
        this.mainThemeGenerator,
        this.alertThemeGenerator,
        this.badgeThemeGenerator,
        this.buttonsThemeGenerator,
        this.checkboxThemeGenerator,
        this.inputThemeGenerator,
        this.datepickerThemeGenerator,
        this.filepickerThemeGenerator,
        this.multiselectThemeGenerator,
        this.navigationThemeGenerator,
        this.headerThemeGenerator,
        this.tableThemeGenerator,
        this.dataroomThemeGenerator,
        this.statusBooleanThemeGenerator,
        this.tabsThemeGenerator,
        this.optionsMenuThemeGenerator,
        this.analyticsReportThemeGenerator,
      ],
    };
  },
  watch: {
    colors: {
      handler() {
        this.prepareColors();
      },
      deep: true,
    },
  },
  created() {
    this.prepareColors();
  },
  beforeUnmount() {
    this.clearTheme(this.inputColors, this.generators);
  },
  methods: {
    prepareColors() {
      // eslint-disable-next-line guard-for-in
      for (const key in DEFAULT_THEME_COLORS) {
        if (!this.colors || !chroma.valid(this.colors[key])) {
          this.inputColors[key] = DEFAULT_THEME_COLORS[key];
        } else {
          this.inputColors[key] = this.colors[key];
        }
        if (key === MAIN_BG_KEY) {
          this.isDarkTheme = chroma(this.inputColors[MAIN_BG_KEY]).luminance() < 0.5;
        }
      }
      if (this.colors) {
        this.generateTheme(this.inputColors, this.generators);
      } else {
        this.clearTheme(this.inputColors, this.generators);
      }
    },
    generateTheme(colors, generators) {
      const themeCssVars = this.getCssVariables(colors, generators);
      const ref = this.target || document.querySelector('html');

      // eslint-disable-next-line guard-for-in
      for (const key in themeCssVars) {
        ref?.style.setProperty(key, themeCssVars[key]);
      }
    },
    clearTheme(colors, generators) {
      const themeCssVars = this.getCssVariables(colors, generators);
      const ref = this.target || document.querySelector('html');
      // eslint-disable-next-line guard-for-in
      for (const key in themeCssVars) {
        ref?.style.removeProperty(key);
      }
    },
    getCssVariables(colors, generators) {
      return generators.reduce(
        (acc, generator) => ({
          ...acc,
          ...generator(colors),
        }),
        {},
      );
    },
    optionsMenuThemeGenerator(colors) {
      return {
        '--bb-options-menu-primary-bg': chroma.mix(colors.app_bg_main, colors.primary, 0.1, 'rgb'),
        '--bb-options-menu-success-bg': chroma.mix(colors.app_bg_main, colors.success, 0.1, 'rgb'),
        '--bb-options-menu-warning-bg': chroma.mix(colors.app_bg_main, colors.warning, 0.1, 'rgb'),
        '--bb-options-menu-danger-bg': chroma.mix(colors.app_bg_main, colors.danger, 0.1, 'rgb'),
        '--bb-options-menu-info-bg': chroma.mix(colors.app_bg_main, colors.info, 0.1, 'rgb'),
      };
    },
    mainThemeGenerator(colors) {
      const gray_100 = chroma(colors.gray600).darken(3);
      const gray_500 = chroma(colors.gray600).darken(1);
      const gray_700 = chroma.mix(colors.gray600, colors.app_bg_main, 0.3, 'rgb');
      const gray_850 = chroma.mix(colors.gray600, colors.app_bg_main, 0.43, 'rgb');
      return {
        '--bb-primary': colors.primary,
        '--bb-primary-rgb': chroma(colors.primary).rgb().join(','),
        '--bb-success': colors.success,
        '--bb-success-rgb': chroma(colors.success).rgb().join(','),
        '--bb-warning': colors.warning,
        '--bb-warning-rgb': chroma(colors.warning).rgb().join(','),
        '--bb-warning-light': chroma.mix('#fff', colors.warning, 0.2, 'rgb'),
        '--bb-danger': colors.danger,
        '--bb-danger-rgb': chroma(colors.danger).rgb().join(','),
        '--bb-info': colors.info,
        '--bb-info-rgb': chroma(colors.info).rgb().join(','),
        '--bb-text-color': colors.text_color,
        '--bb-text-color-rgb': chroma(colors.text_color).rgb().join(','),
        '--bb-link--hover': chroma.mix(colors.primary, colors.app_bg_main, 0.25, 'rgb'),
        '--bb-app-bg': colors.app_bg,
        '--bb-app-bg-main': colors.app_bg_main,
        '--bb-gray-100': gray_100.css('rgb'),
        '--bb-gray-100-rgb': gray_100.rgb().join(','),
        '--bb-gray-500': gray_500.css('rgb'),
        '--bb-gray-600': colors.gray600,
        '--bb-gray-700': chroma.mix(colors.gray600, colors.app_bg_main, 0.3, 'rgb'),
        '--bb-gray-800': chroma.mix(gray_850, colors.app_bg_main, 0.29, 'rgb'),
        '--bb-gray-850': gray_850,
        '--bb-shadow-rgb': this.isDarkTheme ? '0,0,0' : gray_700.rgb().join(','),
        '--bb-gray-900': chroma.mix(gray_850, colors.app_bg_main, 0.8, 'rgb'),
        '--bb-gray-950': chroma.mix(gray_850, colors.app_bg_main, 0.85, 'rgb'),
        '--bb-primary-light': chroma.mix(colors.primary, '#fff', 0.45, 'rgb'),
        '--bb-primary-light-2': chroma.mix(colors.app_bg_main, colors.primary, 0.1, 'rgb'),
      };
    },
    // === UI-KIT
    alertThemeGenerator(colors) {
      const variants = ['primary', 'success', 'warning', 'danger', 'info'];
      return variants.reduce(
        (acc, variant) => ({
          ...acc,
          [`--bb-alert-${variant}-bg`]: chroma.mix('white', colors[variant], 0.1, 'rgb'),
        }),
        {},
      );
    },
    badgeThemeGenerator(colors) {
      const variants = ['primary', 'danger', 'warning', 'info'];
      return variants.reduce(
        (acc, variant) => ({
          ...acc,
          [`--bb-badge-${variant}-bg`]: chroma.mix(
            colors.app_bg_main,
            colors[variant],
            0.15,
            'rgb',
          ),
        }),
        {
          '--bb-badge-secondary-bg': chroma(colors.gray600).alpha(0.1).css('rgba'),
        },
      );
    },
    buttonsThemeGenerator(colors) {
      const variants = [
        { variant: 'primary', value: 'primary' },
        { variant: 'secondary', value: 'gray600' },
        { variant: 'success', value: 'success' },
        { variant: 'warning', value: 'warning' },
        { variant: 'danger', value: 'danger' },
      ];
      return variants.reduce(
        (acc, { variant, value }) => ({
          ...acc,
          [`--bb-btn-${variant}`]: colors[value],
          [`--bb-btn-${variant}-rgb`]: chroma(colors[value]).rgb().join(','),
          [`--bb-btn-${variant}-text`]: chroma(colors[value]).luminance() > 0.5 ? 'black' : 'white',
          [`--bb-btn-${variant}--hover`]: chroma.mix(colors[value], 'white', 0.2, 'rgb'),
          [`--bb-btn-${variant}--active`]: chroma.mix(colors[value], 'black', 0.2, 'rgb'),
          [`--bb-btn-${variant}--disabled`]: chroma(colors[value]).desaturate(0.35).css('rgb'),
        }),
        {},
      );
    },
    checkboxThemeGenerator(colors) {
      const gray_850 = chroma.mix(colors.gray600, colors.app_bg_main, 0.43, 'rgb').hex();

      return {
        '--bb-checkbox-secondary': gray_850,
      };
    },
    inputThemeGenerator(colors) {
      const gray_850 = chroma.mix(colors.gray600, colors.app_bg_main, 0.43, 'rgb').hex();

      return {
        '--bb-input-border': gray_850,
        '--bb-input-placeholder': gray_850,
        '--bb-input-feedback': chroma.mix(gray_850, '#000', 0.1, 'rgb'),
        '--bb-input--disabled': 'var(--bb-gray-900)',
      };
    },
    datepickerThemeGenerator(colors) {
      return {
        '--bb-datepicker-range': chroma.mix('white', colors.primary, 0.1, 'rgb'),
      };
    },
    filepickerThemeGenerator(colors) {
      return {
        '--bb-filepicker-bg': chroma.mix(colors.app_bg_main, colors.primary, 0.1, 'rgb'),
        '--bb-filepicker-bg--hover': chroma.mix(colors.app_bg_main, colors.primary, 0.25, 'rgb'),
        '--bb-filepicker-bg--active': chroma.mix(colors.app_bg_main, colors.primary, 0.35, 'rgb'),
      };
    },
    multiselectThemeGenerator(colors) {
      return {
        '--bb-multiselect-selected--hover': chroma.mix('white', colors.primary, 0.9, 'rgb'),
        '--bb-multiselect-selected--disabled': chroma.mix('white', colors.primary, 0.1, 'rgb'),
      };
    },

    // === OTHER
    navigationThemeGenerator(colors) {
      return {
        '--bb-navigation-bg--start': chroma(colors.primary).set('hsl.h', '+10').darken(0.25),
        '--bb-navigation-bg--end': colors.primary,
      };
    },
    headerThemeGenerator(colors) {
      return {
        '--bb-header-btn-subtitle--hover': chroma.mix('#fff', colors.primary, 0.8, 'rgb'),
      };
    },
    tableThemeGenerator(colors) {
      const rowOdd = chroma.mix(colors.app_bg_main, colors.app_bg, 0.6, 'rgb');
      return {
        '--bb-table-bg--hover': chroma.mix(colors.app_bg_main, colors.primary, 0.1, 'rgb'),
        '--bb-table-sortable-border--hover': chroma.mix(
          colors.app_bg_main,
          colors.primary,
          0.2,
          'rgb',
        ),
        '--bb-table-sorted-bg--odd': chroma.mix(rowOdd, '#000', 0.04, 'rgb'),
        '--bb-table-sorted-bg--even': chroma.mix(colors.app_bg_main, '#000', 0.04, 'rgb'),
        '--bb-table-arrow-bg--hover': chroma.mix(colors.primary, colors.app_bg_main, 0.7, 'rgb'),
        '--bb-table-row--odd': rowOdd,
      };
    },
    dataroomThemeGenerator(colors) {
      return {
        '--bb-dataroom-table--selected': chroma.mix('#fff', colors.primary, 0.1, 'rgb'),
        '--bb-dataroom-table-remove-btn': chroma.mix('#fff', colors.danger, 0.1, 'rgb'),
      };
    },
    statusBooleanThemeGenerator(colors) {
      return {
        '--bb-status-boolean--success': chroma.mix('#fff', colors.success, 0.2),
        '--bb-status-boolean--danger': chroma.mix('#fff', colors.danger, 0.2),
        '--bb-status-boolean--unknown': chroma.mix('#fff', colors.danger, 0.4),
      };
    },
    tabsThemeGenerator(colors) {
      const gray_850 = chroma.mix(colors.gray600, colors.app_bg_main, 0.43, 'rgb').hex();
      return {
        '--bb-tab-color': this.isDarkTheme ? gray_850 : 'var(--bb-text-color)',
      };
    },
    analyticsReportThemeGenerator(colors) {
      return {
        '--bb-analytic-report': colors.analytic_report,
        '--bb-analytic-bg': colors.analytic_bg,
        '--bb-analytic-highlight': colors.analytic_highlight,
        '--bb-analytic-gray-900': chroma.mix(colors.gray600, colors.analytic_bg, 0.45, 'rgb'),
      };
    },
  },
};
</script>
