import chroma from 'chroma-js';

function componentToHex(c) {
  const hex = c.toString(16);
  return String(hex).padStart(2, '0');
}

export function rgbToHex(r, g, b) {
  return ['#', componentToHex(r), componentToHex(g), componentToHex(b)].join('');
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getAnalogousPalette(color, steps = 7, angle = 30) {
  const baseColor = chroma(color);

  const colors = [];
  const baseHue = baseColor.get('hsl.h');

  for (let i = 1; i <= steps; i++) {
    let shiftedHue = baseHue + (i - Math.ceil(steps / 2)) * angle;
    // create changed hue color (without changing saturation and lightness)
    // shiftedHue % 360 - to ensure that angles are always between 0 and 360 degrees
    colors.push(chroma.hsl(shiftedHue % 360, baseColor.get('hsl.s'), baseColor.get('hsl.l')).hex());
  }

  return colors;
}
