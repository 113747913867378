<template lang="pug">
.ui-progress
  .ui-progress__bar(:style="{ width: progress }")
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 100, // %
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progress() {
      return `${this.value}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/variables';

.ui-progress {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #e9ecef;

  &__bar {
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 0.25s;
    animation: progress-bar-stripes 1s linear infinite;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-color: var(--bb-primary);
    background-size: 1rem 1rem;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
</style>
