<template lang="pug">
UiMultiselect(
  v-bind="$attrs"
  :options="optionsProp"
  :valueProp="valueProp"
  :trackBy="trackBy"
  :label="label"
  :object="object"
  :searchable="true"
  :filter-results="false"
  :delay="300"
  :placeholder="$t('user_select.placeholder')"
)
  template(#tag="{ option, handleTagRemove, disabled }")
    UserSelectTag(
      :user="option"
      :disabled="disabled"
      @remove="handleTagRemove(option, $event)"
    )
  template(#singlelabel="{ value }")
    .ui-multiselect-single-label
      stack(align="center" spacing="xs")
        stack-item
          avatar-box.user-select__avatar(
            :src="value.avatar"
            :id="value.email"
            :label="value.name"
            size="xs"
          )
        stack-item
          | {{ value.name }}
  template(#option="{ option }")
    UserCard(v-bind="option")
</template>

<script>
import UserSelectTag from '@/components/UserSelectTag.vue';
import Companies from '@/api/models/Companies.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import Users from '@/api/models/Users.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    UserSelectTag,
  },

  inheritAttrs: true,

  props: {
    valueProp: {
      type: String,
      default: 'id',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
      default: 'name',
    },
    object: {
      type: Boolean,
      default: true,
    },
    options: {
      type: [Array, Object, Function],
      default: null,
    },
    userType: {
      type: String,
      default: 'company_user',
      validator(val) {
        return ['company_user', 'admin_user'].includes(val);
      },
    },
  },

  computed: {
    ...mapGetters(['isAdminHost']),
    ...mapGetters('company', ['company']),
    optionsProp() {
      return this.options || this.search;
    },
  },

  methods: {
    async search(query = '') {
      try {
        const params = {
          search: query,
          page: 1,
          per_page: 1000,
        };
        if (this.userType === 'company_user') {
          const usersResponse = await new CompanyUsers({
            adminApi: this.isAdminHost,
            owner: this.isAdminHost ? new Companies(null, { id: this.company.id }) : null,
          }).fetchAll(params);
          return usersResponse.data.company_users;
        }
        if (this.userType === 'admin_user') {
          const usersResponse = await new Users({ adminApi: true }).fetchAll(params);
          return usersResponse.data.users;
        }
        return [];
      } catch {
        return [];
      }
    },
  },
};
</script>
