import axios from 'axios';
import LocalStorage from '@/common/localstorage.js';

const SET_CURRENT_COMPANY_STATE = (state, company = {}) => {
  state.currentCompanyId = company.id;
  state.currentCompany = company;
};

const SET_CURRENT_COMPANY = (state, company_id) => {
  LocalStorage.set('current-company', company_id, String);
  state.currentCompanyId = company_id;
  axios.defaults.headers.common['BiBook-Company-Id'] = company_id;
};

const SET_COMPANY = (state, company = {}) => {
  state.company = company;
};

const UPDATE_COMPANY = (state, company = {}) => {
  state.company = { ...state.company, ...company };
  if (state.currentCompany && state.currentCompany?.id === state.company?.id) {
    state.currentCompany = {
      ...state.currentCompany,
      ...company,
    };
  }
  const companyIndex = state.companies?.findIndex((c) => c.id === company.id);
  if (companyIndex >= 0) {
    state.companies[companyIndex] = {
      ...state.companies[companyIndex],
      ...company,
    };
  }
};

const SET_PROGRESS = (state, progress = true) => {
  state.inProgress = progress;
};

const UPDATE_COMPANY_LIST = (state, list) => {
  state.companies = list;
};

const SET_COMPANIES_LIST = (state, comanies) => {
  state.companies = comanies;
};

const SET_SYSTEM_BANNERS = (state, systemBanners) => {
  state.systemBanners = systemBanners;
};

const SET_LOGIN_BANNER = (state, banner) => {
  state.loginBanner = banner;
};

const SET_CURRENT_COMPANY_ID = (state, company_id = null) => {
  state.currentCompanyId = company_id;
};

const UPDATE_CURRENT_COMPANY = (state, company) => {
  state.currentCompany = company;
};

export default {
  SET_CURRENT_COMPANY_STATE,
  SET_CURRENT_COMPANY,
  UPDATE_COMPANY,
  SET_COMPANY,
  SET_PROGRESS,
  UPDATE_COMPANY_LIST,
  SET_COMPANIES_LIST,
  SET_SYSTEM_BANNERS,
  SET_LOGIN_BANNER,
  SET_CURRENT_COMPANY_ID,
  UPDATE_CURRENT_COMPANY,
};
