import _isEqual from 'lodash/isEqual';

export function clearDefaultValues(targetObj, defaultValues) {
  const keys = Object.keys({ ...targetObj, ...defaultValues });
  const clearedValues = keys.reduce((acc, key) => {
    if (!_isEqual(targetObj[key], defaultValues[key])) {
      acc[key] = targetObj[key];
    }
    return acc;
  }, {});

  return Object.keys(clearedValues).length ? clearedValues : undefined;
}
