<template lang="pug">
.collapse-button(:class="classes")
  UiIcon(:name="vertical ? 'ChevroneTop' : 'ChevroneLeft'")
</template>

<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    classes() {
      return [
        {
          'collapse-button--active': this.active,
        },
        `collapse-button--${this.variant}`,
      ];
    },
  },
};
</script>

<style lang="scss">
.collapse-button {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  color: var(--bb-text-color);
  background: var(--bb-app-bg-main);
  box-shadow: 0 0 0 1px rgba(var(--bb-shadow-rgb), 0.06), 0 1px 3px rgba(var(--bb-shadow-rgb), 0.15),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: 2px solid var(--bb-primary);
  }

  & > .ui-icon {
    transition: all 230ms;
  }

  &--active > .ui-icon {
    transform: rotate(-180deg);
  }

  &--primary {
    box-shadow: none;
    color: white;
    background: var(--bb-primary);

    &:hover {
      outline: 2px solid var(--bb-primary-light);
    }
  }
}
</style>
