<template lang="pug">
.page-container
  .page-header
    UiStack(align="end")
      UiStackItem(grow)
        .page-title {{ $t('company_content.page_title') }}
      UiStackItem
        UiStack
          UiButton(
            v-if="isAdmin && isRootFolder"
            icon="Share"
            @click="openAccessRootModal"
          ) {{ $t('user_groups.share_root_btn') }}
          UiButton(v-for="action in pageActions" v-bind="action") {{ action.label }}
  .page-content
    UiCard
      UiCardSection(padding="sm")
      UiRestTreeTable.content-table(
        :key="treeTableKey"
        ref="contentTable"
        namespace="content"
        :fetchHandler="fetchData"
        :headers="headers"
        :initialFilters="initialFilters"
      )
        template(#filters="{ searchValue, setSearchValue, filters, updateFilters }")
          UiStack(spacing="xl" wrap)
            UiStackItem
              UiInput.content-table__filter-input(
                icon="Search"
                :modelValue="searchValue"
                :placeholder="$t('placeholders.search_in_all_entities')"
                @update:modelValue="setSearchValue"
              )
            UiStack(wrap)
              UiStackItem {{ $t('labels.filter_by') }}:
              UiStack(wrap)
                UiStackItem
                  UiMultiselect.content-table__filter-input(
                    :options="typesOptionsDynamic"
                    :modelValue="filters.types"
                    :allow-empty="true"
                    :hide-selected="false"
                    mode="multiple"
                    :placeholder="$t('placeholders.type')"
                    data-vi="filter-types-multiselect"
                    @update:modelValue="updateFilters({ types: $event })"
                  )
        template(#cell:icon="{ item }")
          EntityTypeIcon(:entity="item")

        template(#cell:name="{ item }")
          .entity-details
            .entity-path(v-if="search")
              template(v-for="(ancestor, index) in item.ancestors")
                .entity-path-item(@click.stop="openFolderById(ancestor.id)") {{ ancestor.name ?? 'Home' }}
                .entity-path-divider /
            UiStack(v-if="item.type === 'PowerBiReport'" justify="space-between")
              RouterLink.entity-name.entity-name--link(
                :to="{ name: 'AnalyticsReport', params: { id: item.id } }"
              ) {{ item.name }}
              UiBadge(v-if="item.extras.contains_embedded_links" data-vi="shared-badge") {{ $t('company.reports.shared') }}
            RouterLink.entity-name.entity-name--link(
              v-else-if="item.type === 'DataroomForm'"
              :to="{ name: item.extras.form_type === 'multi_entries' ? 'DataroomEntries' : 'DataroomView', params: { id: item.id } }"
            ) {{ item.name }}
            .entity-name.entity-name--link(v-else-if="item.type === 'Folder'") {{ item.name }}
            .entity-name(v-else) {{ item.name }}

        template(#cell:type="{ item }")
          | {{ $t(`user_groups.entity_name.${entityTypeKey[item.type] ?? item.type}`) }}
        template(#cell:created_at="{ item }")
          | {{ $d(item.created_at, 'short') }}

        template(#cell:updated_at="{ item }")
          | {{ $d(item.updated_at, 'short') }}

        template(#cell:status="{ item }")
          FolderStatuses(v-if="item.type === 'Folder'" :entity="item")
          FilesStatuses(v-if="item.type === 'CompanyFile'" :entity="item")
          ReportStatus(
            v-if="item.type === 'PowerBiReport'"
            :entity="item"
            @refresh="openRefreshReportModal(item)"
          )

        template(#cell:actions="{ item }")
          FolderActions(
            v-if="item.type === 'Folder'"
            :entity="item"
            @edit="openEditFolderModal(item)"
            @delete="openDeleteFolderModal(item)"
            @manageAccess="openEntityAccessListModal(item)"
          )
          FileActions(
            v-if="item.type === 'CompanyFile'"
            :entity="item"
            @reload="reloadTree"
            @edit="openEditFileModal(item)"
            @delete="openDeleteFileModal(item)"
            @manageAccess="openEntityAccessListModal(item)"
          )
          PowerBiReportActions(
            v-if="item.type === 'PowerBiReport'"
            :entity="item"
            showCopyLinkAction
            @reload="reloadTree"
            @edit="openEditReportModal(item)"
            @delete="openDeleteReportModal(item)"
            @manageAccess="openEntityAccessListModal(item)"
          )
          DataroomFormActions(
            v-if="item.type === 'DataroomForm'"
            :entity="item"
            @reload="reloadTree"
            @manageAccess="openEntityAccessListModal(item)"
          )

      UiCardSection(padding="xs")

  // Modals
  EntityAccessListModal(ref="entityAccessListModal" @done="reloadTree")

  // Folder modals
  AddFolderModal(ref="addFolderModal" @done="reloadTree")
  DeleteFolderModal(ref="deleteFolderModal" @done="reloadTree")

  // File modals
  EditFileModal(ref="editFileModal" @done="reloadTree")
  DeleteFileModal(ref="deleteFileModal" @done="reloadTree")

  // Report modals
  AddReportModal(ref="addReportModal" @done="reloadTree")
  EditReportModal(ref="editReportModal" @done="reloadTree")
  DeleteReportModal(ref="deleteReportModal" @done="reloadTree")
  RefreshReportModal(ref="refreshReportModal" @done="reloadTree")
</template>

<script>
import { mapGetters } from 'vuex';

import EntityTypeKey from '@/helper/entity-type-key.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';
import AccessTreeEntities from '@/api/models/AccessTreeEntities.js';
import ContentEntityService from '@/services/content-entity-service.js';

import EntityAccessListModal from '@/modals/UserGroups/entity-access-list/entity-access-list-modal.vue';
import AddFolderModal from '@/modals/Files/add-folder-modal.vue';
import DeleteFolderModal from '@/modals/Files/delete-folder-modal.vue';
import DeleteFileModal from '@/modals/Files/delete-file-modal.vue';
import EditFileModal from '@/modals/Files/EditFile.vue';
import AddReportModal from '@/modals/Reports/add-report-modal.vue';
import EditReportModal from '@/modals/Reports/edit-report-modal.vue';
import DeleteReportModal from '@/modals/Reports/delete-report-modal.vue';
import RefreshReportModal from '@/modals/Reports/Refresh.vue';

import FolderActions from '@/components/actions/folder-actions.vue';
import FileActions from '@/components/actions/file-actions.vue';
import PowerBiReportActions from '@/components/actions/power-bi-report-actions.vue';
import DataroomFormActions from '@/components/actions/dataroom-form-actions.vue';

import FilesStatuses from '@/components/elements/additional/statuses/Files.vue';
import FolderStatuses from '@/components/elements/additional/statuses/Folders.vue';
import ReportStatus from '@/components/elements/additional/statuses/Reports.vue';
import CreateEntityAccess from '@/models/create-entity-access';

export default {
  name: 'ContentIndex',
  components: {
    EntityAccessListModal,
    AddFolderModal,
    DeleteFolderModal,
    DeleteFileModal,
    EditFileModal,
    AddReportModal,
    EditReportModal,
    DeleteReportModal,
    RefreshReportModal,

    FolderActions,
    FileActions,
    PowerBiReportActions,
    DataroomFormActions,

    FolderStatuses,
    FilesStatuses,
    ReportStatus,
  },

  data() {
    return {
      search: '',
      abortController: null,
      treeTableKey: 0, // it's needed to reset fetch params after relocate on the same page
      createEntityAccess: new CreateEntityAccess(),
      inProgress: true,
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser', 'mobileView']),
    ...mapGetters('company', ['currentCompany', 'isAdmin']),

    pageActions() {
      return [
        {
          key: 'file',
          label: this.$t('actions.add_file'),
          icon: 'FileText',
          onClick: this.openCreateFileModal,
          visible: this.createEntityAccess.companyFile,
          'data-vi': 'CompanyFile',
        },
        {
          key: 'report',
          label: this.$t('actions.add_report'),
          icon: 'FilePlus',
          onClick: this.openCreateReportModal,
          visible: this.createEntityAccess.powerBiReport,
          'data-vi': 'PowerBiReport',
        },
        {
          key: 'folder',
          label: this.$t('actions.add_folder'),
          icon: 'FolderPlus',
          onClick: this.openCreateFolderModal,
          visible: this.createEntityAccess.folder,
          'data-vi': 'Folder',
        },
      ]
        .map((item) => ({
          ...item,
          round: this.mobileView,
          disabled: this.inProgress,
        }))
        .filter((item) => item.visible);
    },

    headers() {
      return [
        {
          key: 'icon',
          label: '',
          sortable: false,
          squeeze: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          width: '15%',
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          width: '160px',
        },
        {
          key: 'updated_at',
          label: 'Last edit',
          sortable: true,
          width: '160px',
        },
        {
          key: 'status',
          label: 'Status',
          width: '15%',
          class: 'cell-center',
          cellClass: 'cell-center',
        },
        {
          key: 'actions',
          label: 'Actions',
          squeeze: true,
          class: 'cell-right',
          cellClass: 'cell-right',
          width: '130px',
        },
      ];
    },

    entityTypeKey() {
      return EntityTypeKey;
    },

    typesOptionsDynamic() {
      return [
        { name: 'PowerBiReport', available: this.currentCompany.reports_enabled },
        {
          name: 'CompanyFile',
          available: this.currentCompany.files_enabled,
        },
        {
          name: 'DataroomForm',
          available: this.currentCompany.dataroom_enabled,
        },
      ]
        .filter((item) => item.available)
        .map((item) => ({
          value: item.name,
          label: this.$t(`user_groups.entity_name.${EntityTypeKey[item.name] ?? item.name}`),
        }));
    },

    initialFilters() {
      return {
        types: [],
      };
    },

    isRootFolder() {
      return !this.$route.params?.folder_id;
    },
  },

  methods: {
    async fetchData(params) {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.inProgress = true;
      this.abortController = new AbortController();

      const { folder_id: parent_id, search, ...restParams } = params;
      try {
        const response = await new AccessTreeEntities({
          owner: new CompanyUsers(null, { id: this.currentCompany.user_id }),
        }).fetchAll(
          {
            ...restParams,
            parent_id: this.currentCompany?.root_folder_id === parent_id ? null : parent_id,
            search: !!search ? search : undefined,
          },
          {
            signal: this.abortController.signal,
          },
        );
        this.search = search;
        this.createEntityAccess = new CreateEntityAccess(response.data.create_entity_access);

        return {
          items: response.data.entities.map(ContentEntityService.normalizeContentEntity),
          meta: response.data.meta,
          currentFolder: response.data.current_folder,
          path: response.data.ancestors.map((folder) => {
            if (folder.name === null) {
              return { ...folder, id: null };
            }
            return folder;
          }),
        };
      } catch (error) {
        if (error.response?.status === 404) {
          if (!this.$route.params.folder_id) {
            // prevent 404 loop redirect
            throw error;
          }
          await this.redirectToRootFolder();
        } else {
          throw error;
        }
      } finally {
        this.inProgress = false;
      }
    },

    async redirectToRootFolder() {
      await this.$router.push({ name: this.$route.name });
      this.treeTableKey++;
    },
    reloadTree(params, id) {
      this.$refs.contentTable.fetchData(id, params);
    },

    openFolderById(folderId) {
      this.$refs.contentTable.openFolderById(folderId);
    },

    openAccessRootModal() {
      const entity = {
        id: this.currentCompany.root_folder_id,
        name: 'root',
        type: 'Root',
      };

      this.$refs.entityAccessListModal.open({
        entity,
        type: entity.type,
        isRootFolder: true,
      });
    },

    openEntityAccessListModal(entity) {
      this.$refs.entityAccessListModal.open({
        entity,
        type: entity.type,
      });
    },

    openCreateFolderModal() {
      this.$refs.addFolderModal.open({
        parentFolderId: this.$refs.contentTable.currentFolder.id,
      });
    },

    openEditFolderModal(entity) {
      this.$refs.addFolderModal.open({
        entity,
      });
    },

    openDeleteFolderModal(entity) {
      this.$refs.deleteFolderModal.open({
        entity,
      });
    },

    openDeleteFileModal(entity) {
      this.$refs.deleteFileModal.open({
        entity,
      });
    },

    openCreateFileModal() {
      this.$refs.editFileModal.open(null, 'new', this.$refs.contentTable.currentFolder.id);
    },

    openCreateReportModal() {
      this.$refs.addReportModal.open({
        parentFolderId: this.$refs.contentTable.currentFolder.id,
      });
    },

    openEditReportModal(entity) {
      this.$refs.editReportModal.open({
        entity,
      });
    },

    openDeleteReportModal(entity) {
      this.$refs.deleteReportModal.open({
        entity,
      });
    },

    openRefreshReportModal(entity) {
      this.$refs.refreshReportModal.open(entity);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-table {
  &:deep(.ui-table__cell:first-child),
  &:deep(.ui-table__header-cell:first-child) {
    padding-left: 1.2rem;
  }

  &:deep(.ui-table__cell:last-child),
  &:deep(.ui-table__header-cell:last-child) {
    padding-right: 1.2rem;
  }

  &:deep(.ui-table__header-cell.cell-right > .ui-table__header-label) {
    justify-content: flex-end;
  }

  &:deep(.ui-table__header-cell.cell-center > .ui-table__header-label) {
    justify-content: center;
  }

  &:deep(.ui-table__cell.cell-center) {
    text-align: center;
  }

  &__filter-input {
    min-width: 270px;
  }
}

.entity-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 2.36rem;
}

.entity-name {
  display: inline;
  color: inherit;

  &--link:hover {
    color: var(--bb-primary);
    text-decoration: underline;
  }
}

.entity-path {
  display: flex;
  font-size: 0.85em;
  gap: 0.25em;
  color: var(--bb-gray-700);
  line-height: 1;

  &-item {
    cursor: pointer;
    color: var(--bb-primary);

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
