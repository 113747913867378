<template lang="pug">
.step-container
  .step-header
    .step-header__wrapper
      OnboardingCancelImage.step-header__image
      .step-title {{ $t('onboarding.cancel.title') }}
  UiStack(justify="center")
    UiStackItem
      UiButton(@click="toHome") {{ $t('onboarding.success.action_to_dashboard') }}
</template>

<script>
import OnboardingCancelImage from '@/assets/images/onboarding-cancel.vue';
import LocalStorage from '@/common/localstorage.js';

export default {
  components: {
    OnboardingCancelImage,
  },
  methods: {
    toHome() {
      this.$router.replace('/');
      LocalStorage.remove('integration_attempt');
    },
  },
};
</script>

<style lang="scss" scoped>
.step-header {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    margin-bottom: 42px;
  }
}
</style>
