<template lang="pug">
.admin-layout(v-if="!loading")
  Heading(:title="$t('administrators.edit_title')")
    Stack
      StackItem
        UiButton(:disabled="inProgress" @click="save") {{ $t('administrators.save') }}
      StackItem
        UiButton(
          :round="mobileView"
          variant="outline-danger"
          :disabled="inProgress"
          @click="deleteUser"
        )
          template(#icon)
            UiIcon(name="Trash")
          | {{ $t('administrators.delete') }}

  .admin-layout__breadcrumps-container
    AppBreadcrumbs(:items="breadcrumbs" showBack)
  .page-card
    .form-container
      Stack(
        vertical
        align="stretch"
        spacing="lg"
      )
        StackItem
          UiInputLabel {{ $t('administrators.modal.name_label') }}
          UiInput(
            v-model="form.name"
            :placeholder="$t('administrators.modal.name_placeholder')"
            :error="v$.form.name.$error"
            @blur="v$.form.name.$touch"
          )
          UiInputFeedback(v-for="$error in v$.form.name.$errors" error) {{ $error.$message }}
        StackItem
          UiFormInput(
            v-model="form.email"
            :label="$t('administrators.modal.email_label')"
            :placeholder="$t('administrators.modal.email_placeholder')"
            :vuelidate-model="v$.form.email"
          )
        StackItem
          UiCheckbox(
            v-model="form.user_role"
            checked-value="global_admin"
            unchecked-value="service_admin"
          ) {{ $t(`administrators.modal.global_admin`) }}
DeleteAdminModal(ref="deleteAdminModal" @done="exit")
</template>

<script>
import Administrators from '@/api/models/Administrators.js';
import useVuelidate from '@vuelidate/core';
import { required, email , strictLettersAndSpaces } from '@/common/validators.js';
import DeleteAdminModal from '@/modals/Admin/Administrators/delete.vue';

function getEmptyForm() {
  return {
    email: '',
    name: '',
    user_role: 'service_admin',
  };
}

export default {
  name: 'EditAdministrator',
  components: {
    DeleteAdminModal,
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      loading: false,
      originalUser: null,
      form: getEmptyForm(),
      inProgress: false,
      errorText: null,
    };
  },

  validations() {
    return {
      form: {
        name: { required, strictLettersAndSpaces },
        email: { required, email },
      },
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          label: this.$t('administrators.title'),
          to: '/admin/administrators',
        },
        {
          label: this.$t('administrators.edit_title'),
          to: this.$route.path,
        },
      ];
    },
  },

  mounted() {
    this.fetchForm();
  },

  methods: {
    async fetchForm() {
      this.loading = true;
      await new Administrators(null, { id: this.$route.params.id })
        .fetch()
        .then((res) => {
          this.loading = false;
          this.form = { ...this.form, ...res.data.administrator };
        })
        .catch((e) => {
          this.loading = false;
          this.errorText = e.response.data.error;
        });
    },

    async save() {
      const valid = await this.v$.$validate();
      if (!valid) {
        return;
      }
      this.inProgress = true;
      const user = {
        name: this.form.name,
        user_role: this.form.user_role,
        email: this.form.email,
      };
      if (this.form.password) {
        user.password = this.form.password;
        user.password_confirmation = this.form.password_confirmation;
      }
      new Administrators({ singularKey: 'user' }, { id: this.$route.params.id })
        .update(user)
        .then(() => {
          this.inProgress = false;
          this.exit();
        })
        .catch((e) => {
          this.inProgress = false;
        });
    },

    deleteUser() {
      this.$refs.deleteAdminModal.open(this.form);
    },

    exit() {
      this.$router.push('/admin/administrators');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-card {
  background-color: var(--bb-app-bg-main);
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 1px 3px rgba(var(--bb-shadow-rgb), 0.08),
    0 5px 10px rgba(var(--bb-shadow-rgb), 0.04);
}

.form-container {
  max-width: 400px;
}
</style>
