<template lang="pug">
.tab-container
  TabHeader
    template(#title) {{ $t('company.company_users') }}
    template(#actions)
      template(v-if="!isViewAsOtherUser && !isAdminHost")
        UiButton(
          icon="Import"
          :variant="!mobileView ? 'smooth-primary' : 'text-primary'"
          :disabled="isViewAsOtherUser"
          :round="mobileView"
          data-test-id="button-import-users"
          @click="uploadImportFile"
        ) {{ $t('company.import_users') }}
        UiButton(
          icon="Export"
          :variant="!mobileView ? 'smooth-primary' : 'text-primary'"
          :disabled="isViewAsOtherUser"
          :round="mobileView"
          :href="`/company_users/${company.id}/export.xlsx`"
          target="_blank"
          data-test-id="button-export-users"
        ) {{ $t('company.export_users') }}
      UiButton(
        :variant="!mobileView ? 'primary' : 'text-primary'"
        :disabled="isViewAsOtherUser"
        :round="mobileView"
        icon="UserPlus"
        data-test-id="button-invite-user"
        @click="inviteUser"
      ) {{ $t('company.users.add_user') }}
  UiRestTable(
    :fetchHandler="fetchUsers"
    namespace="users"
    :headers="headers"
    ref="usersTable"
    searchable
    embed
  )
    template(#cell:name="{ item }")
      RouterLink(:to="getUserLink(item)")
        UserCard(
          :id="item.email"
          :avatar="item.avatar"
          :name="item.name"
        )
    template(#cell:role="{ item }")
      UiBadge(
        size="sm"
        :variant="roleBadgeVariant(item.role)"
        data-test-id="badge-user-role"
      ) {{ $t(`company.role.${item.role}`) }}
    template(#cell:actions="{ item, showControl }")
      UserControlActions(
        :show="showControl"
        :item="item"
        :reload="reloadList"
        :isAdminPage="isAdminHost"
        data-test-id="actions-control-user"
        @edit="editUser(item)"
      )
  ImportUsersModal(ref="importUsersModal" @done="reloadList")
  EditCompanyUserModal(ref="editCompanyUserModal" @done="reloadList")
</template>

<script>
import { mapGetters } from 'vuex';
import Company from '@/models/Company.js';
import UserControlActions from '@/components/elements/additional/actions/Users.vue';
import ImportUsersModal from '@/modals/CompanyUsers/ImportUsers.vue';
import ConfirmModal from '@/modals/common/Confirm.vue';
import EditCompanyUserModal from '@/modals/CompanyUsers/Edit.vue';
import RestTable from '@/components/RestTable/RestTable.vue';
import AvatarBox from '@/components/Avatar/AvatarBox.vue';
import TabHeader from '@/components/Tabs/TabHeader.vue';
import Companies from '@/api/models/Companies.js';
import CompanyUsers from '@/api/models/CompanyUsers.js';

export default {
  components: {
    TabHeader,
    AvatarBox,
    RestTable,
    UserControlActions,
    ImportUsersModal,
    ConfirmModal,
    EditCompanyUserModal,
  },

  data() {
    return {
      inProgress: false,
      headers: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          style: 'width: 30%',
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          style: 'width: 30%',
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true,
        },
        {
          key: 'actions',
          squeeze: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['user', 'isViewAsOtherUser', 'mobileView', 'isAdminHost']),
    ...mapGetters('company', ['currentCompanyId', 'company']),
  },

  methods: {
    getUserLink(user) {
      return this.$adminCompanyLinkModifier({
        name: 'CompanyUserShow',
        params: { user_id: user.id },
      });
    },

    reloadList() {
      this.$refs.usersTable.fetchData();
    },

    roleBadgeVariant(role) {
      return Company.isAdmin(role) ? 'bright-primary' : 'primary';
    },

    inviteUser() {
      this.$refs.editCompanyUserModal.open({
        companyId: this.company.id,
        adminMode: this.isAdminHost,
      });
    },

    editUser(user) {
      this.$refs.editCompanyUserModal.open({
        user,
        companyId: this.company.id,
        adminMode: this.isAdminHost,
      });
    },

    uploadImportFile() {
      this.$refs.importUsersModal.open();
    },

    async fetchUsers(params) {
      const usersResponse = await new CompanyUsers({
        adminApi: this.isAdminHost,
        owner: this.isAdminHost ? new Companies(null, { id: this.company.id }) : null,
      }).fetchAll(params);

      return {
        items: usersResponse.data.company_users,
        meta: usersResponse.data.meta,
      };
    },
  },
};
</script>

<style lang="scss">
.user-tab__href {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-avatar {
  width: 32px;
  margin-right: 10px;
  border-radius: 16px;
}
</style>
