<template lang="pug">
UiModal(
  :show="show"
  :title="$t('company.users.edit_user')"
  :width="414"
  :rightActions="rightActions"
  @close="close"
)
  p {{ $t('company.users.edit_user_description') }}
  UiFormInput(
    v-model="form.name"
    :label="$t('company.users.name')"
    :vuelidateModel="v$.form.name"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import User from '@/models/User.js';
import ApiUser from '@/api/user.js';

import useVuelidate from '@vuelidate/core';
import { required } from '@/common/validators.js';

export default {
  emits: ['done', 'wasClose'],

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  data() {
    return {
      show: false,
      inProgress: false,
      form: new User(),
    };
  },

  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },

  computed: {
    ...mapGetters(['user']),

    rightActions() {
      return [
        {
          label: this.$t('actions.save'),
          loading: this.inProgress,
          disabled: this.inProgress,
          onClick: this.save,
        },
      ];
    },
  },

  methods: {
    open() {
      this.form = new User(this.user);
      this.show = true;
    },

    close() {
      this.$emit('wasClose');
      this.show = false;
      this.v$.$reset();
    },

    save() {
      this.v$.$validate().then((valid) => {
        if (!valid) return;

        this.inProgress = true;
        const params = { name: this.form.name };
        ApiUser.updateUser(params)
          .then((resp) => {
            const { name } = resp.data;
            this.$store.dispatch('updateUser', { name });
            this.inProgress = false;
            this.$emit('done');
            this.close();
          })
          .catch((error) => {
            this.$toaster.add({
              type: 'error',
              message: this.$localizeErrorMessage(error),
            });
          })
          .finally(() => {
            this.inProgress = false;
          });
      });
    },
  },
};
</script>
