class ContentEntity {
  constructor(entity = {}, options = {}) {
    this.__dataModel = options.dataModel ?? null;
    this.__extrasModel = options.extrasModel ?? null;

    this.__data = {};
    this.__extras = {};

    this.id = entity.id ?? null;
    this.sequential_id = entity.sequential_id ?? null;
    this.parent_id = entity.parent_id ?? null;
    this.type = entity.type ?? null;
    this.name = entity.name ?? '';
    this.created_at = entity.created_at ? new Date(entity.created_at) : new Date();
    this.updated_at = entity.updated_at ? new Date(entity.updated_at) : new Date();
    this.data = entity.data ?? {};
    this.entity_access = entity.entity_access ?? {};
    this.extras = entity.extras ?? {};
    this.ancestors = entity.ancestors ?? [];
  }

  get data() {
    return this.__data;
  }

  set data(value) {
    if (this.__dataModel) {
      this.__data = new this.__dataModel({
        ...this.__data,
        ...value,
      });
    }
  }

  get extras() {
    return this.__extras;
  }

  set extras(value) {
    if (this.__extrasModel) {
      this.__extras = new this.__extrasModel({
        ...this.__extras,
        ...value,
      });
    }
  }

  get canUpdate() {
    return this.entity_access.update;
  }

  get canDelete() {
    return this.entity_access.delete;
  }
}

export default ContentEntity;
