<template lang="pug">
UiInput(v-bind="$attrs" :style="computedStyles")
</template>

<script>
export default {
  props: {
    noBorders: Boolean,
  },
  computed: {
    computedStyles() {
      return {
        borderRadius: '5px',
        ...this.borders,
      };
    },
    borders() {
      return this.noBorders ? { border: 'none', backgroundColor: 'transparent' } : {};
    },
  },
};
</script>

<style scoped></style>
