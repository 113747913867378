import ContentEntity from './content-entity.js';
import PowerBiReportData from './power-bi-report-data.js';
import PowerBiReportExtras from './power-bi-report-extras.js';

class PowerBiReport extends ContentEntity {
  constructor(entity, options) {
    super(entity, {
      dataModel: PowerBiReportData,
      extrasModel: PowerBiReportExtras,
      ...options,
    });
    this.type = 'PowerBiReport';
  }
}

export default PowerBiReport;
