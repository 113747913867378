<template lang="pug">
.tab-container
  TabHeader
    template(#title) {{ $t('company.tabs.logs') }}
  RestTable(
    ref="table"
    namespace="logs"
    :headers="headers"
    :fetch-handler="fetchLogs"
    filtered
    :initial-filters="initialFilters"
    embed
    multiline-filters
  )
    template(v-slot:cel_event_value="{ item }")
      template(v-if="item.human_details.type === 'default'")
        div(style="white-space: pre-line" data-test-id="human-details-default") {{ item.human_details.value }}
      template(v-if="item.human_details.type === 'error'")
        div(style="white-space: pre-wrap" data-test-id="human-details-error") 
          | {{ item.human_details.value }}
          code {{ item.human_details.code }}
      template(v-else-if="item.human_details.type === 'bookmarks'")
        | {{ item.human_details.value.bookmark_name || '-' }}
        template(v-if="item.human_details.value.report_id")
          | , report: #[router-link(:to="{ name: 'AnalyticsReport', params: { id: item.human_details.value.report_id } }") {{ item.human_details.value.report_name }}]
      template(v-else-if="item.human_details.type === 'user'")
        Stack(spacing="sm")
          StackItem
            AvatarBox(
              size="sm"
              :src="item.human_details.value.avatar"
              :id="item.human_details.value.email"
              :label="item.human_details.value.name"
              data-test-id="human-details-user-avatar-box"
            )
          StackItem
            .user-name(data-test-id="human-details-user-name") {{ item.human_details.value.name }}
            .user-email(data-test-id="human-details-user-email") {{ item.human_details.value.email }}
      template(v-else-if="item.human_details.type === 'embedded-link'")
        div(style="white-space: pre-line" data-test-id="human-details-embedded-link") {{ embedLinkPubliclyAvailable(item.human_details.value) }}
    template(v-slot:cel_created_at="{ item }") {{ $dateFormatter(new Date(item.created_at)) }}
    template(v-slot:cel_event_name="{ item }")
      div(data-test-id="event-name") {{ item.event_name }} {{ item.event_label_extended?.interrupted ? `(${$t('logs.interrupted')})` : '' }}
    template(v-slot:cel_user="{ item }")
      Stack(spacing="sm")
        StackItem
          AvatarBox(
            size="sm"
            :src="item.user_id.avatar"
            :id="item.user_id.email"
            :label="item.user_id.name"
          )
        StackItem
          .user-name {{ item.user_id.name }}
          .user-email {{ item.user_id.email }}
    template(v-slot:filter_block="{ filter, updateFilter }")
      .filters-block
        Stack.filters-stack(wrap spacing="sm")
          StackItem
            UiInput(
              :placeholder="$t('logs.search_input_placeholder')"
              :modelValue="filter.search"
              data-test-id="logs-search"
              @update:modelValue="updateFilter({ search: $event })"
            )
              template(#icon)
                UiIcon(name="SearchIcon")
          StackItem
            UiDatePicker(
              :range="true"
              :modelValue="[filter.time_start, filter.time_end]"
              size="md"
              data-test-id="logs-date-picker"
              @update:modelValue="updateFilter({ time_start: $event[0], time_end: $event[1] })"
            )
          StackItem
            UiMultiselect(
              :options="logCategoriesOptions"
              :modelValue="filter.event_category"
              :placeholder="$t('logs.event_category_input_placeholder')"
              :allow-empty="true"
              data-test-id="logs-categories"
              @update:modelValue="updateFilter({ event_category: $event })"
            )
          StackItem
            UserSelect(
              :modelValue="filter.user"
              data-test-id="logs-user-select"
              @update:modelValue="updateFilter({ user: $event })"
            )
</template>

<script>
import UserSelect from '@/components/UserSelect.vue';
import logCategoriesOptionsRaw from '@/helper/logCategoriesOptionsRaw.js';
import TabHeader from '@/components/Tabs/TabHeader.vue';
import EventsLog from '@/api/models/EventsLog.js';
import { endOfDay, startOfDay } from '@bi-book/bibook-ui-kit';

export default {
  components: {
    TabHeader,
    UserSelect,
  },
  data() {
    return {
      initialFilters: {
        time_start: new Date(),
        time_end: new Date(),
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          key: 'created_at',
          label: this.$t('logs.table.time'),
          squeeze: true,
          cellClass: 'text-nowrap',
          headerClass: 'text-nowrap',
        },
        {
          key: 'event_category',
          label: this.$t('logs.table.category'),
          headerClass: 'text-nowrap',
        },
        {
          key: 'event_name',
          label: this.$t('logs.table.event'),
          headerClass: 'text-nowrap',
        },
        {
          key: 'event_value',
          label: this.$t('logs.table.event_value'),
          headerClass: 'text-nowrap',
        },
        {
          key: 'user',
          label: this.$t('logs.table.user'),
          headerClass: 'text-nowrap',
        },
        {
          key: 'ip',
          label: this.$t('logs.table.ip_address'),
          squeeze: true,
          headerClass: 'text-nowrap',
        },
      ];
    },
    logCategoriesOptions() {
      return logCategoriesOptionsRaw.map((i) => ({
        value: i.value,
        label: this.$t(i.label),
      }));
    },
  },
  methods: {
    getHumanDetails(item) {
      if (
        ['Users:Invite', 'Users:Destroy', 'Users:View as', 'Users:Logout view as'].includes(
          item.event_category_name,
        )
      ) {
        return {
          type: 'user',
          value: item.event_label_extended,
        };
      }

      if (item.event_category_name === 'Reports:Timespend') {
        return {
          type: 'default',
          value: `${item.event_label_extended.name} - ${this.$duration(+item.value)}`,
        };
      }

      if (item.event_category_name === 'Reports:Loaded') {
        return {
          type: 'default',
          value: `${item.event_label_extended.name} - ${this.$duration(+item.value, true)}`,
        };
      }

      if (item.event_category_name === 'Reports:Rendered') {
        return {
          type: 'default',
          value: `Report: ${item.event_label_extended.name} - Page: ${
            item.event_label_extended.page_name
          } - ${this.$duration(+item.value, true)}`,
        };
      }

      if (item.event_category_name === 'Reports:Timespend (Page)') {
        return {
          type: 'default',
          value: `Report: ${item.event_label_extended.name} - Page: ${
            item.event_label_extended.page_name
          } - ${this.$duration(+item.value, true)}`,
        };
      }

      if (item.event_category_name === 'Reports:PageChanged') {
        return {
          type: 'default',
          value: `Report: ${item.event_label_extended.name} - Page: ${item.event_label_extended.page_name}`,
        };
      }

      if (item.event_category_name === 'Reports:Failed') {
        return {
          type: 'error',
          value: `${item.event_label_extended.name}:`,
          code: JSON.stringify(item.event_label_extended.details, undefined, 2),
        };
      }

      if (item.event_category === 'Bookmarks') {
        return {
          type: 'bookmarks',
          value: item.event_label_extended,
        };
      }

      if (['Reports:Edit'].includes(item.event_category_name)) {
        return {
          type: 'default',
          value: `${item.event_label_extended?.name} \n${item.event_label_extended.status} `,
        };
      }

      if (
        ['Integrations', 'IntegrationRequest', 'Forms', 'Files', 'Reports'].includes(
          item.event_category,
        )
      ) {
        return {
          type: 'default',
          value: item?.event_label_extended?.name,
        };
      }

      if (['PowerBiEmbeddedLink'].includes(item.event_category)) {
        return {
          type: 'embedded-link',
          value: item?.event_label_extended?.publicly_available,
        };
      }

      // todo should be original before $t
      if (['User groups'].includes(item.event_category)) {
        return {
          type: 'default',
          value: item?.event_label_extended?.name,
        };
      }

      if (item.event_category_name === 'Companies:RefreshApiToken') {
        const { api_type, software } = item.event_label_extended;
        return {
          type: 'default',
          value: this.$t('logs.api_token_updated', {
            value: `{${api_type}}${software ? ` {${software}}` : ''}`,
          }),
        };
      }

      return {
        type: 'default',
        value: item.value,
      };
    },
    embedLinkPubliclyAvailable(isPublicly) {
      if (isPublicly === undefined) {
        return '';
      }

      if (isPublicly) {
        return this.$t('logs.embedded_type_value.public');
      }

      return this.$t('logs.embedded_type_value.private');
    },
    async fetchLogs(params) {
      try {
        const response = await new EventsLog().fetchAll({
          search: params.search,
          company_id: this.$store.state.company.currentCompanyId,
          user_id: params.user?.user_id ?? null,
          event_category: params.event_category ?? null,
          time_start: startOfDay(params.time_start),
          time_end: endOfDay(params.time_end),
          page: params.page,
          per_page: params.per_page,
          sort_by: params.sort_by,
          sort_order: params.sort_order,
        });
        return {
          meta: response.data.meta,
          items: response.data.result.map((item) => {
            if (typeof item.event_label_extended === 'string') {
              try {
                item.event_label_extended = JSON.parse(item.event_label_extended);
              } catch {
                // dont need do anything
              }
            }
            item.event_category_name = `${item.event_category}:${item.event_name}`;
            item.event_category =
              this.logCategoriesOptions.find((category) => category.value === item.event_category)
                ?.label || item.event_category;
            item.human_details = this.getHumanDetails(item);
            return item;
          }),
        };
      } catch (error) {
        this.$toaster.add({
          type: 'error',
          message: this.$localizeErrorMessage(error),
        });
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-stack > :deep(.ui-stack-item) {
  min-width: 330px;
}

.filters-block {
  padding-bottom: 1rem;
}

.user-name,
.user-email {
  white-space: nowrap;
  line-height: 1;
}

.user-email {
  margin-top: 4px;
  font-size: 0.9em;
  color: #838ca0;
}
</style>
