<template lang="pug">
Modal(
  :title="$t('analytics.delete_bookmark.title', { name: bookmark.name })"
  :show="show"
  :close="close"
)
  template(#footer)
    Stack
      StackItem
        UiButton(
          variant="outline-primary"
          :disabled="inProgress"
          width
          data-test-id="button-close"
          @click="close"
        ) {{ $t('analytics.delete_bookmark.action_decline') }}
      StackItem
        UiButton(
          variant="outline-primary"
          :disabled="inProgress || isViewAsOtherUser"
          :loading="inProgress"
          width
          data-test-id="button-submit"
          @click="submit"
        ) {{ $t('analytics.delete_bookmark.action_approve') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Bookmarks from '@/api/models/Bookmarks';

export default {
  emits: ['done'],
  data() {
    return {
      show: false,
      inProgress: false,
      bookmark: {
        id: null,
        name: '',
      },
    };
  },

  computed: {
    ...mapGetters(['isViewAsOtherUser']),
  },

  methods: {
    open(bookmark) {
      this.bookmark = {
        id: bookmark.id,
        name: bookmark.name,
      };
      this.show = true;
    },

    close() {
      this.show = false;
    },

    async submit() {
      this.inProgress = true;
      let destroyBookmarkResponse = null;
      try {
        destroyBookmarkResponse = await new Bookmarks(null, { id: this.bookmark.id }).delete();
        this.inProgress = false;
        this.$emit('done', this.bookmark.id);
        this.close();
      } catch (e) {
        console.error(e);
        this.inProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
