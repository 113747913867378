<template lang="pug">
UiModal(
  :title="title"
  :show="show"
  :leftActions="leftActions"
  :rightActions="rightActions"
  width="600"
  @close="close"
)
  //- MAIN FIELDS
  UiFormMultiselect(
    v-model="form.attr_type"
    :label="$t('dynamic_settings.modal.select_field_type')"
    :options="fieldTypes"
    :disabled="inProgress || this.isEditing"
    :vuelidateModel="v$.form.attr_type"
    :can-clear="false"
    :can-deselect="false"
    data-vi="attr_type"
    @change="resetDynamicFields"
  )
  UiFormInput(
    v-model="v$.form.attr_name.$model"
    :disabled="inProgress"
    :label="$t('dynamic_settings.label_attr_name')"
    :vuelidateModel="v$.form.attr_name"
    data-vi="attr_name"
  )
  UiFormInput(
    v-for="lang in ['en', 'fi', 'sv']"
    v-model="v$.form.label[lang].$model"
    :label="$t(`dynamic_settings.label_${lang}`)"
    :disabled="inProgress"
    :vuelidateModel="v$.form.label[lang]"
    :data-vi="`label.${lang}`"
  )
  //- DYNAMIC FIELDS
  //- placeholder
  UiFormInput(
    v-if="isPlaceholderAvailable"
    v-model="v$.form.placeholder.en.$model"
    :label="$t('dynamic_settings.modal.placeholder')"
    :disabled="inProgress"
    :vuelidateModel="v$.form.placeholder.en"
    data-vi="placeholder.en"
  )
  .dynamic-field-modal__dropdown-options.ui-form-field(v-if="form.attr_type === 'dropdown'")
    .dynamic-field-modal__dropdown-options-item(v-for="(option, index) in form.data.options")
      UiInputLabel.dynamic-field-modal__dropdown-options-item_label-1(v-if="!index") {{ $t('dynamic_settings.modal.dropdown_item_name') }}
      .dynamic-field-modal__dropdown-options-item_number &#35;{{ index + 1 }}
      UiInput.dynamic-field-modal__dropdown-options-item_input-1(
        v-model="form.data.options[index].label.en"
        :data-vi="`data.options.label.en.${index}`"
      )
      UiInputLabel.dynamic-field-modal__dropdown-options-item_label-2(v-if="!index") {{ $t('dynamic_settings.modal.item_value_id') }}
      UiInput.dynamic-field-modal__dropdown-options-item_input-2(
        v-model="form.data.options[index].value"
        :data-vi="`data.options.value.${index}`"
        @blur="v$.form.data.$touch"
        @input="clearExternalResult('form.data')"
      )
    UiInputFeedback(
      v-if="v$.form.data.$errors.length"
      error
      data-vi="data-error"
    ) {{ this.$t(v$.form.data.$errors[0].$message) }}
    UiStack(justify="end")
      UiButton(
        variant="smooth-primary"
        icon="Plus"
        data-vi="add-option"
        @click="addDropdownOption"
      ) {{ $t('dynamic_settings.modal.add_option') }}

  //- default value
  component(
    v-bind="defaultValue.props"
    v-model="attrDefault"
    :key="defaultComponentKey"
    :is="defaultValue.component"
    :label="defaultValue.label"
    :disabled="inProgress"
    :vuelidateModel="v$.form.attr_default"
    data-vi="attr_default"
  )

  //- VALIDATOR FIELDS
  UiFormCheckbox(
    v-model="form.value_validations.presence"
    :disabled="inProgress"
    :vuelidateModel="v$.form.value_validations.presence"
    switch
    data-vi="value_validations.presence"
  ) {{ $t('dynamic_settings.modal.presence') }}
  UiFormCheckbox(
    v-if="isEmailValidationAvailable"
    v-model="form.value_validations.email"
    :disabled="inProgress"
    :vuelidateModel="v$.form.value_validations.email"
    switch
    data-vi="value_validations.email"
  ) {{ $t('dynamic_settings.modal.email') }}

  //- DROPDOWN ADDITIONAL SWITCHER'S FIELDS
  template(v-if="form.attr_type === 'dropdown'")
    UiFormCheckbox(
      :modelValue="form.data.multiselect"
      :disabled="inProgress"
      :vuelidateModel="v$.form.data.multiselect"
      switch
      data-vi="multiselect"
      @update:modelValue="onMultiselectChange($event)"
    ) {{ $t('dynamic_settings.modal.multiple') }}
    UiFormCheckbox(
      v-model="form.data.search"
      :disabled="inProgress"
      :vuelidateModel="v$.form.data.search"
      switch
      data-vi="search"
    ) {{ $t('dynamic_settings.modal.search') }}
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { isInList, required, requiredIf } from '@/common/validators.js';
import { fieldTypes } from '@/helper/dynamic-fields-options';
import { capitalize } from 'lodash';
import _set from 'lodash/set.js';
import DynamicSettings from '@/api/models/dynamic-settings';
import DynamicField from '@/models/dynamic-field-model';
import parseResponseErrors from '@/common/parseResponseErrors.js';
import { HTTP_VALIDATION_ERROR } from '@/helper/request-statuses';

export default defineComponent({
  emits: ['done'],

  setup() {
    const externalResults = ref({ form: {} });
    const v$ = useVuelidate({ $externalResults: externalResults });
    return { v$, externalResults };
  },

  data() {
    return {
      form: new DynamicField(),
      show: false,
      inProgress: false,
      defaultComponentKey: 0,
    };
  },

  validations() {
    const external = helpers.regex(/.*/);
    const isDropdown = this.form.attr_type === 'dropdown';
    const atLeastOneOptionRequired =
      isDropdown &&
      !this.form.data.options.filter((option) => option.label?.en && option.value).length;
    return {
      form: {
        attr_type: {
          required,
        },
        label: { en: { required }, fi: { required }, sv: { required } },
        attr_name: { required },
        attr_default: {
          isInList: isDropdown
            ? isInList(this.form.data.options.map((option) => option.value) || [])
            : true,
        },
        value_validations: {
          presence: { external },
          email: { external },
        },
        placeholder: { en: { external } },
        data: {
          external,
          options: {
            label: {
              en: { required: requiredIf(atLeastOneOptionRequired) },
            },
            value: {
              required: requiredIf(atLeastOneOptionRequired),
            },
          },
        },
      },
    };
  },

  computed: {
    attrDefault: {
      get() {
        if (this.form.attr_type === 'date') {
          return this.form.attr_default ? new Date(this.form.attr_default) : null;
        }
        return this.form.attr_default;
      },
      set(val) {
        this.form.attr_default = val;
      },
    },
    isEditing() {
      return !!this.form.id;
    },
    title() {
      return this.isEditing
        ? this.$t('dynamic_settings.modal.edit_modal_title')
        : this.$t('dynamic_settings.modal.create_modal_title');
    },
    leftActions() {
      return [
        {
          label: this.$t('actions.cancel'),
          onClick: this.close,
          disabled: this.inProgress,
          variant: 'smooth-secondary',
        },
      ];
    },
    rightActions() {
      return [
        {
          label: this.isEditing ? this.$t('actions.save') : this.$t('actions.add_field'),
          onClick: this.submit,
          loading: this.inProgress,
          disabled: this.inProgress,
        },
      ];
    },
    fieldTypes() {
      return fieldTypes.map((item) => ({ label: capitalize(item), value: item }));
    },
    isPlaceholderAvailable() {
      return this.form.attr_type !== 'boolean';
    },
    isEmailValidationAvailable() {
      return this.form.attr_type === 'string';
    },
    defaultValue() {
      const defaultValue = {
        component: null,
        props: {},
        label: this.$t('dynamic_settings.modal.default_value'),
      };
      if (['string', 'integer'].includes(this.form.attr_type)) {
        defaultValue.component = 'UiFormInput';
      }
      if (this.form.attr_type === 'integer') {
        defaultValue.props.type = 'number';
      }

      if (this.form.attr_type === 'boolean') {
        defaultValue.component = 'UiFormMultiselect';
        defaultValue.label = this.$t('dynamic_settings.modal.default_state');
        defaultValue.props.options = [
          { value: true, label: this.$t('common.yes') },
          { value: false, label: this.$t('common.no') },
        ];
      }

      if (this.form.attr_type === 'dropdown') {
        defaultValue.component = 'UiFormMultiselect';
        defaultValue.label = this.$t('dynamic_settings.modal.add_default_item');
        defaultValue.props.mode = this.form.data.multiselect ? 'tags' : 'single';
        defaultValue.props.options =
          this.form.data?.options
            .filter((option) => option.value && option.label.en)
            .map((option) => ({ label: option.label.en, value: option.value })) || [];
      }

      if (this.form.attr_type === 'date') {
        defaultValue.component = 'UiFormDatePicker';
        defaultValue.label = this.$t('dynamic_settings.modal.default_item');
        defaultValue.props.canClear = true;
      }

      return defaultValue;
    },
  },

  methods: {
    open(form) {
      this.form = new DynamicField(form);
      this.show = true;
      this.v$.$reset();
    },
    addDropdownOption() {
      this.form.addEmptyOption();
    },
    onMultiselectChange(value) {
      this.defaultComponentKey += 1; // hack to update the component to be able change mode
      this.form.attr_default = [];
      this.form.data.multiselect = value;
    },
    clearExternalResult(path) {
      _set(this.externalResults, path, null);
    },
    async submit() {
      this.externalResults = {};
      const valid = await this.v$.$validate();
      if (!valid) return;

      try {
        this.inProgress = true;
        await new DynamicSettings(null, { id: this.form.id }).save(this.form.toJSON());
        this.close();
        this.$emit('done');
      } catch (error) {
        if (error.response?.data?.errors && error.response.status === HTTP_VALIDATION_ERROR) {
          const errors = parseResponseErrors(error.response.data.errors);
          Object.assign(this.externalResults, { form: errors });
          this.$toaster.add({
            type: 'error',
            title: 'Error',
            message: this.$t('validations.check_all_fields'),
          });
        } else {
          this.$toaster.add({
            type: 'error',
            title: this.$t('status.error'),
            message: this.$localizeErrorMessage(error),
          });
        }
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.show = false;
    },
    resetDynamicFields(fieldType) {
      this.form = new DynamicField({
        attr_type: fieldType,
        label: this.form.label,
        value_validations: {
          presence: this.form.value_validations.presence,
          email: false,
        },
      });
      this.defaultComponentKey += 1;
    },
  },
});
</script>

<style lang="scss" scoped>
.dynamic-field-modal {
  &__dropdown-options {
    :deep(.ui-form-field--input) {
      margin: 0;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      display: grid;
      gap: 0 10px;
      grid-template-columns: auto 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        '. label-1 label-1 label-2 label-2'
        'number input-1 input-1 input-2 input-2';
      &_number {
        grid-area: number;
        align-self: center;
      }

      &_label {
        &-1 {
          grid-area: label-1;
        }

        &-2 {
          grid-area: label-2;
        }
      }

      &_input {
        &-1 {
          grid-area: input-1;
        }

        &-2 {
          grid-area: input-2;
        }
      }
    }
  }
}
</style>
