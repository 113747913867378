// eslint-disable-next-line import/no-cycle
import router from '@/router/index.js';
import Company from '@/models/Company.js';
import Companies from '@/api/models/Companies.js';

const switchCompany = async ({ commit, state, dispatch, getters, rootState }, company_id) => {
  if (state.currentCompanyId === company_id) {
    return;
  }

  if (rootState.index.defaultCompany) {
    await new Companies().setCurrent(company_id);
    commit('SET_CURRENT_COMPANY', company_id);
    if (getters.currentCompany) {
      router.push({ name: 'Root' });
    }
    dispatch('fetchCompany');
  } else {
    const company = state.companies.find((c) => c.id === company_id);
    window.location = `${window.location.protocol}//${company.active_company_domains[0]}`;
  }
};

const fetchCompany = ({ commit, getters }, companyId) => {
  const { currentCompany } = getters;
  commit('SET_COMPANY', null);

  if (!companyId && (!currentCompany || !Company.isAdmin(currentCompany.role))) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    commit('SET_PROGRESS', true);

    const companyRequest = new Companies(
      { adminApi: !!companyId, singular: !companyId },
      { id: companyId },
    ).fetch();

    companyRequest
      .then((res) => {
        commit('SET_COMPANY', res.data.company);
        commit('SET_PROGRESS', false);
        resolve(res);
      })
      .catch(reject);
  });
};

function updateCompany({ commit, getters }, { company, companyId }) {
  return new Promise((resolve, reject) => {
    const updateCompanyRequest = new Companies(
      { adminApi: !!companyId, singular: !companyId },
      { id: companyId },
    ).update(company);

    updateCompanyRequest
      .then((res) => {
        commit('UPDATE_COMPANY', res.data.company);
        resolve(res);
      })
      .catch(reject);
  });
}

const restoreCompany = ({ commit }, company) => {
  commit('UPDATE_COMPANY', company);
};

const removeCompany = ({ commit, state, dispatch, getters }, id) => {
  const companies = state.companies.filter((c) => c.id !== id);
  commit('UPDATE_COMPANY_LIST', companies);
  commit('SET_COMPANY', null);
  commit('SET_CURRENT_COMPANY', companies.length ? companies[0].id : null);

  if (!getters.currentCompany) {
    router.push({ name: 'NoHaveCompany' });
    return;
  }

  if (Company.isAdmin(getters.currentCompany.role)) {
    router.push({ name: 'CompanyShow' });
    dispatch('fetchCompany');
    return;
  }

  router.push({ name: 'Analytics' });
};

const updateNotificationsCounts = ({ commit, state }, { counts }) => {
  const counters = counts[state.currentCompanyId];
  if (counters) {
    commit('UPDATE_COMPANY', {
      ...state.currentCompany,
      notifications_count: counters.all,
      unread_notifications_count: counters.unread,
    });
  }
};

export default {
  fetchCompany,
  switchCompany,
  updateCompany,
  restoreCompany,
  removeCompany,
  updateNotificationsCounts,
};
