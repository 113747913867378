<template lang="pug">
.user-card(:class="classes")
  Stack(spacing="sm")
    StackItem
      AvatarBox(
        :size="avatarSize"
        :src="avatar"
        :id="email || name"
        :label="name"
      )
    StackItem
      .user-card__name {{ name }}
      .user-card__email(v-if="email") {{ email }}
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: null,
    },
    avatarSize: {
      type: String,
      default: 'sm',
    },
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes() {
      return {
        [`user-card--${this.size}`]: this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  &__name,
  &__email {
    white-space: nowrap;
    line-height: 1;
  }

  &__email {
    margin-top: 4px;
    font-size: 0.9em;
    color: var(--bb-gray-600);
  }
}
</style>
