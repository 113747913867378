import AppBaseModel from '@/api/models/AppBaseModel';

class UiThemes extends AppBaseModel {
  constructor(options, item = null) {
    const mergedOptions = {
      path: 'ui_themes',
      singularKey: 'ui_theme',
      ...options,
    };
    super(mergedOptions, item);
  }

  fetch(params = {}, config = {}) {
    return this.httpClient.get(`${this.basePath}${this.ownerPath}/ui_theme`, { params, ...config });
  }

  save(data, config) {
    return this.httpClient.patch(
      `${this.basePath}${this.ownerPath}/ui_theme`,
      this.wrapSingularData(data),
      config,
    );
  }

  delete(config) {
    return this.httpClient.delete(
      `${this.basePath}${this.ownerPath}/${
        this.adminApi && !this.owner ? 'ui_themes' : 'ui_theme'
      }/${this.id ?? ''}`,
      config,
    );
  }
}
export default UiThemes;
